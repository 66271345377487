import './CertificatePage.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import { generateFullName } from '@sharedInterfaces/globalHelper';
import { updateCertificate } from '@store/reducer/certificatesReducer';
import Certificate from '@src/Objects/Certificate';
import AddCertificateButton from '@src/Components/Buttons/AddCertificateButton/AddCertificateButton';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import { CertificateDialog } from '@src/Components/Dialogs/CertificateDialog/CertificateDialog';
import getCertificate from '@src/APIs/graphQl/Certificate/getCertificate';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import ProductsCard from '@src/App/NewLayout/Cards/Products/ProductsCard/ProductsCard';
import EmployeesCard from '@src/App/NewLayout/Cards/Employees/EmployeesCard/EmployeesCard';
import RolesCard from '@src/App/NewLayout/Cards/Roles/RolesCard/RolesCard';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

import EntityPage from '../../../../../sharedReact/Pages/EntityPage/EntityPage';


interface CertificatePageProps
{
    entity?: Certificate;
    inDialog?: boolean;
}

/**
 * Renders the CertificatePage component.
 * 
 * @param {CertificatePageProps} entity - The entity object.
 * @param {boolean} inDialog - The flag indicating whether component is displayed in a dialog.
 * 
 * @returns {JSX.Element} The rendered component.
 */
function CertificatePage({
    entity,
    inDialog,
}: CertificatePageProps)
{
    const dispatch = useDispatch();
    const { openDialog } = useDialog();
    const { id } = useParams();
    const entityId = entity ? entity.id : id ? Number.parseInt(id) : -1;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).certificates;
    const entityState = useSelector((state: AppState) => state.certificates.find(a => a.id === entityId))

    const emp = useSelector((state: AppState) => state.employee);
    const permissions = useSelector((state: AppState) => state.permissions);
    const readRoles = checkPermissions('Roles', 'Retrieve', permissions);

    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);

    const setEntityState = (skill: Certificate) => skill && dispatch(updateCertificate(skill))

    useEffect(() =>
    {
        if (inDialog) return;
        getCertificate(entityId).catch((ex) =>
        {
            setLoadingError(ex);
        });

    }, [entityId, inDialog]);


    const navigate = useNavigate();

    if (!emp) return null
    const handleOpenEditDialog = async () =>
    {
        return openDialog(
            <CertificateDialog
                id="CertificateDialog"
                certificate={entityState}
            />);
    };
    return (
        <EntityPage
            inDialog={inDialog}
            entity={entityState}
            loadingError={loadingError}
            admin={permissions.admin}
            permissions={permissions.entities.Certificates}
            onDelete={() =>
            {
                if (!inDialog)
                    navigate(`/${ELinks.CERTIFICATES}`);
            }}
            buttons={
                entityState ?
                    <AddCertificateButton size='small' id={entityState.id} onChange={
                        (val) =>
                        {
                            if (val) 
                            {
                                const certEmps = [...entityState.employees.filter(e => e.id !== emp.id)];
                                certEmps.push({ id: emp.id, title: generateFullName(emp.firstName, emp.lastName) });
                                setEntityState(new Certificate({ ...entityState, employees: certEmps }))
                            }
                            else
                            {
                                setEntityState(new Certificate({ ...entityState, employees: entityState.employees.filter(e => e.id !== emp.id) }))
                            }
                        }
                    } />
                    : undefined
            }
            openEditDialog={handleOpenEditDialog}
            informations={
                !entityState ? [] :
                    [

                        (entityState.productInfo && entityState.productInfo.productName) ?
                            <EntityPageInformation title={langStrings.productName} >
                                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                    <Typography variant='body2' className="rowElementContent">
                                        {entityState.productInfo.productName}
                                    </Typography>
                                    {entityState.productInfo.link &&
                                        <IconButton
                                            title={langStrings.openInNewTab}
                                            size='small'
                                            style={{ padding: 0 }}
                                            onClick={() =>
                                            {
                                                window.open(entityState.productInfo?.link, '_blank');
                                            }}
                                        >
                                            <OpenInNew
                                                fontSize='small'
                                            />
                                        </IconButton>
                                    }
                                </div>
                            </EntityPageInformation> : undefined,
                        (entityState.productInfo && entityState.productInfo.manufacturer) ?
                            <EntityPageInformation title={langStrings.manufacturer} >
                                {entityState.productInfo.manufacturer}
                            </EntityPageInformation>
                            : undefined,
                        (entityState.cost) ?
                            <EntityPageInformation title={langStrings.oftenUsed.cost} >
                                {`${entityState.cost}€`}
                            </EntityPageInformation>
                            : undefined,
                        (entityState.durationInDays) ?
                            <EntityPageInformation title={langStrings.durationInDays} >
                                {`${entityState.durationInDays} ${langStrings.oftenUsed.days}`}
                            </EntityPageInformation>
                            : undefined,
                        entityState.description ? <EntityPageInformation title={langStrings.description} size={'full'}>
                            <ExpandableText>
                                <FormatedTextInput value={entityState.description} readonly={true} />
                            </ExpandableText>
                        </EntityPageInformation> :
                            undefined,
                        entityState.prerequisites ? <EntityPageInformation title={langStrings.prerequisites} size={'full'}>
                            <ExpandableText>
                                <>
                                    <FormatedTextInput value={entityState.prerequisites} readonly={true} />
                                </>
                            </ExpandableText>
                        </EntityPageInformation> : undefined,
                    ]
            }
        >
            {entityState &&
                <>
                    {readRoles &&
                        <RolesCard roles={entityState.roles} />
                    }
                    <EmployeesCard title={langStrings.employeesWithCertificate} employees={entityState.employees.map(e =>
                    ({
                        ...e,
                        active: {
                            activeUntil: e.endDate
                        }
                    })
                    )
                        .sort((a, b) =>
                        {
                            if (b.endDate && !a.endDate) return -1;
                            if (!b.endDate && a.endDate) return 1;
                            if (b.endDate?.getTime && a.endDate?.getTime) return b.endDate.getTime() - a.endDate.getTime();
                            return 0;
                        })
                    } />
                    <ProductsCard products={entityState.products} />
                </>}
        </EntityPage>
    );
}

export default CertificatePage;
