// LegendItem.tsx
import React from 'react';
import Typography from '@mui/material/Typography'; // Assuming you're using Material-UI for Typography

interface LegendItemProps
{
    title: string;
    colorVar: string;
}

const LegendItem: React.FC<LegendItemProps> = ({ title, colorVar }) =>
{
    return (
        <div className='legendItem'>
            <div
                className='legendColor'
                title={title}
                style={{ background: colorVar }}
            ></div>
            <Typography variant='subtitle2'>{title}</Typography>
        </div>
    );
};

export default LegendItem;
