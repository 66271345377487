import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IAttachmentsTexts extends ITextBase
{
    confirmOverrideTitle: string;
    confirmOverride: string;
    dragAndDropFilesHere: string;
    fileType: string;
    uploading: string;
    uploadAttachment: string;
}
type ILangData = {
    [lang in ELanguage]: IAttachmentsTexts
}
export const attachmentsTexts: ILangData = {
    'th': {
        confirmOverrideTitle: 'เขียนทับ "[TITLE]" หรือไม่?',
        confirmOverride: 'คุณแน่ใจหรือไม่ว่าคุณต้องการเขียนทับไฟล์?\nการจัดเวอร์ชั่นจะใช้ได้เฉพาะกับคุณสมบัติเท่านั้น.',
        dragAndDropFilesHere: 'หรือลากและวางไฟล์ที่นี่',
        fileType: 'ประเภทไฟล์',
        uploading: 'กำลังอัปโหลด...',
        uploadAttachment: 'อัปโหลดไฟล์แนบ',
        oftenUsed: oftenUsedTranslations['th'],

    },
    'en': {

        confirmOverrideTitle: 'Override "[TITLE]"?',
        confirmOverride: 'Are you sure you want to overwrite the file?\nVersioning only applies to properties.',
        dragAndDropFilesHere: 'or drag and drop files here',
        fileType: 'File type',
        uploading: 'Uploading...',
        uploadAttachment: 'Upload attachment',
        oftenUsed: oftenUsedTranslations['en'],
    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        uploadAttachment: 'Anhang hochladen',
        uploading: 'Wird hochgeladen...',
        fileType: 'Dateityp',
        dragAndDropFilesHere: 'oder ziehen Sie Dateien hierher',
        confirmOverride: 'Bist du dir sicher, dass du die Datei überschreiben möchtest?\nDie Versionierung gilt nur für die Eigenschaften.',
        confirmOverrideTitle: '"[TITLE]" überschreiben?',
    },
}