import { ELanguage } from "../languageHelper"

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IOppoTexts extends ITextBase
{
    statusName: string;
    status: string;
    newStatus: string;
    settings: string;
    sourceURL: string;
    sourceName: string;
    primaryRole: string;
    externalID: string;
    publishDate: string;
    deadline: string;
    aiRequestDescription: string;
    emptyRequestDescription: string;
    aiRequest: string;
    emptyRequest: string;
    back: string;
    analyse: string;
    okay: string;
    errorProcessingAiError: string;
    errorProcessing: string;
    empty: string;
    create: string;
    newOpportunityError: string;
    active: string;
    edit: string;
    opportunity: string;
    utilization: string;
    remote: string;
    dayPrice: string;
    topSkills: string;
    yes: string;
    no: string;
    newOpportunity: string;
    opportunities: string;
    startDate: string;
    endDate: string;
    employees: string;
    customer: string;
    opportunityName: string;
    neededSkills: string;
    selectedEmployee: string
    suggestedEmployee: string
    delete: string


    overview: string
    pleaseEnterProjectTitle: string
    save: string
    location: string
    partner: string
    skillCoverage: string
    skillScore: string
    availability: string
    coveredSkills: string
    missingSkills: string
    waitForAi: string
}
type ILangData = {
    [lang in ELanguage]: IOppoTexts
}
export const oppoTexts: ILangData = {
            'th': {
                status: 'สถานะ',
        newStatus: 'สถานะใหม่',
                statusName: 'ชื่อสถานะ',
                settings: 'การกำหนดค่าคำขอ',
                topSkills: 'ทักษะสำคัญ',
                sourceURL: 'URL ของแหล่งที่มา',
                sourceName: 'แหล่งที่มา',
                primaryRole: 'บทบาทหลัก',
                externalID: 'รหัสภายนอก',
                deadline: 'กำหนดเวลา',
                publishDate: 'เผยแพร่เมื่อ',
                oftenUsed: oftenUsedTranslations['th'],
                empty: oftenUsedTranslations['th'].empty,
                newOpportunity: 'โอกาสใหม่',
                opportunity: oftenUsedTranslations['th'].opportunity,
                opportunities: oftenUsedTranslations['th'].opportunities,
                customer: 'ลูกค้า',
                employees: oftenUsedTranslations['th'].employees,
                startDate: 'วันเริ่มโปรเจกต์',
                endDate: 'วันสิ้นสุดโปรเจกต์',
                utilization: 'การใช้ประโยชน์',
                remote: 'ระยะไกล',
                dayPrice: 'อัตราวัน',
                neededSkills: 'ทักษะที่ต้องการ',
                selectedEmployee: 'พนักงานที่เลือก',
                suggestedEmployee: 'พนักงานที่แนะนำ',
                delete: oftenUsedTranslations['th'].delete,
                overview: 'ภาพรวม',
                save: oftenUsedTranslations['th'].save,
                partner: 'พาร์ทเนอร์',
                location: 'สถานที่',
                pleaseEnterProjectTitle: 'โปรดใส่ชื่อโปรเจกต์',
                skillCoverage: 'ความครอบคลุมทักษะ',
                skillScore: 'คะแนนทักษะ',
                availability: 'ความพร้อมใช้งาน',
                coveredSkills: 'ทักษะที่ครอบคลุม',
                missingSkills: 'ทักษะที่ขาดหายไป',
                yes: oftenUsedTranslations['th'].yes,
                no: oftenUsedTranslations['th'].no,
                opportunityName: 'ชื่อ',
                active: oftenUsedTranslations['th'].active,
                edit: oftenUsedTranslations['th'].edit,
                create: oftenUsedTranslations['th'].create,
                newOpportunityError: "Error creating the new request.",
                "waitForAi": "ข้อความกำลังถูกวิเคราะห์โดย AI, โปรดรอ.",
                "errorProcessing": "ข้อผิดพลาดในการประมวลผล",
                "errorProcessingAiError": "ข้อผิดพลาดในการประมวลผล, หายทูลจาก AI: ",
                "analyse": "วิเคราะห์",
                "okay": "ตกลง",
                back: oftenUsedTranslations['th'].back,
                "emptyRequest": "คำขอที่ว่างเปล่า",
                "emptyRequestDescription": "คุณเริ่มต้นด้วยคำขอที่ว่างเปล่าและต้องกรอกฟิลด์ทั้งหมดด้วยตนเอง.",
                "aiRequest": "ใส่คำขอด้วย AI",
                "aiRequestDescription": "คุณสามารถให้ปัญญาประดิษฐ์ประเมินข้อความคำขอ."
            },
            'en': {
                status: 'Status',
        newStatus: 'New Status',
                statusName: 'Status Name',
                settings: 'Request Configuration',
                topSkills: 'Key Skills',
                sourceURL: 'Source URL',
                sourceName: 'Source',
                primaryRole: 'Main role',
                externalID: 'External ID',
                deadline: 'Deadline',
                publishDate: 'Published on',
                oftenUsed: oftenUsedTranslations['en'],
                empty: oftenUsedTranslations['en'].empty,
                newOpportunity: 'New opportunity',
                opportunity: oftenUsedTranslations['en'].opportunity,
                opportunities: oftenUsedTranslations['en'].opportunities,
                customer: 'Customer',
                employees: 'Employees',
                startDate: 'Start date',
                endDate: 'End date',
                utilization: 'Utilization',
                remote: 'Remote',
                dayPrice: 'Daily rate',
                neededSkills: 'Needed skills',
                selectedEmployee: 'Selected employees',
                suggestedEmployee: 'Suggested employees',
                delete: oftenUsedTranslations['en'].delete,
                overview: 'Overview',
                save: oftenUsedTranslations['en'].save,
                partner: 'Partner',
                location: 'Location',
                pleaseEnterProjectTitle: 'Please enter a project title.',
                skillCoverage: 'Skill coverage',
                skillScore: 'Skill score',
                availability: 'Availability',
                coveredSkills: 'Covered skills',
                missingSkills: 'Missing skills',
                yes: oftenUsedTranslations['en'].yes,
                no: oftenUsedTranslations['en'].no,
                opportunityName: 'Name',
                active: oftenUsedTranslations['en'].active,
                edit: oftenUsedTranslations['en'].edit,
                create: oftenUsedTranslations['en'].create,
                newOpportunityError: "Error creating the new request.",
                "waitForAi": "Text being analyzed by AI, please wait.",
                "errorProcessing": "Error processing",
                "errorProcessingAiError": "Error processing, the AI says: ",
                "analyse": "Analyze",
                "okay": "Okay",
                back: oftenUsedTranslations['en'].back,
                "emptyRequest": "Empty Request",
                "emptyRequestDescription": "You start with an empty request and have to fill in all fields manually.",
                "aiRequest": "Fill Request with AI",
                "aiRequestDescription": "You can have a request text evaluated by an artificial intelligence.",

            },
            'de': {
                oftenUsed: oftenUsedTranslations['de'],
                empty: oftenUsedTranslations['de'].empty,
                newOpportunity: 'Neue Anfrage',
                opportunity: oftenUsedTranslations['de'].opportunity,
                opportunities: oftenUsedTranslations['de'].opportunities,
                customer: 'Kunde',
                employees: oftenUsedTranslations['de'].employees,
                startDate: 'Projektstart',
                endDate: 'Projektende',
                utilization: 'Auslastung',
                remote: 'Remote',
                dayPrice: 'Tagessatz',
                topSkills: 'Key-Skills',
                neededSkills: 'Benötigte Kenntnisse',
                selectedEmployee: 'Ausgewählte Mitarbeiter',
                suggestedEmployee: 'Vorgeschlagene Mitarbeiter',
                delete: 'Löschen',
                overview: oftenUsedTranslations['de'].overview,
                save: oftenUsedTranslations['de'].save,
                partner: 'Partner',
                location: 'Standort',
                pleaseEnterProjectTitle: 'Bitte einen Projektnamen eingeben.',
                skillCoverage: 'Fähigkeitsabdeckung',
                skillScore: 'Fähigkeitsbewertung',
                availability: 'Verfügbarkeit',
                coveredSkills: 'Vorhandene Fähigkeiten',
                missingSkills: 'Fehlende Fähigkeiten',
                opportunityName: 'Name',
                yes: oftenUsedTranslations['de'].yes,
                no: oftenUsedTranslations['de'].no,
                active: oftenUsedTranslations['de'].active,
                edit: oftenUsedTranslations['de'].edit,
                create: oftenUsedTranslations['de'].create,
                "newOpportunityError": "Fehler beim Anlegen der neuen Anfrage.",
                waitForAi: 'Text wird von der KI analysiert, bitte warten.',
                errorProcessing: "Fehler bei der Verarbeitung",
                errorProcessingAiError: 'Fehler bei der Verarbeitung, die KI sagt: ',
                analyse: 'Analysieren',
                okay: 'Okay',
                back: oftenUsedTranslations['de'].back,
                emptyRequest: 'Leere Anfrage',
                emptyRequestDescription: 'Du startest mit einer leeren Anfrage und füllst alle Felder manuell aus.',
                aiRequest: 'Anfrage mit AI ausfüllen',
                aiRequestDescription: 'Du kannst einen Anfragetext von einer künstlichen Intelligenz auswerten lassen.',
                publishDate: 'Veröffentlicht am',
                deadline: 'Frist',
                externalID: 'Externe ID',
                primaryRole: 'Hauptrolle',
                sourceName: 'Quelle',
                sourceURL: 'Quelle URL',
                settings: 'Anfrage-Konfiguration',
                statusName: 'Status-Name',
                newStatus: 'Neuer Status',
                status: 'Status',
            },
        }