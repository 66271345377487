import React, { } from 'react';
import { useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EEntityType, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import { useDialog } from '@sharedReact/Dialog/DialogManager';
import Competence from '@src/Objects/Competence';
import CardBox from '@src/Components/CardBox/CardBox';
import './CompetenceCard.css';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import { CompetenceDialog } from '@src/Components/Dialogs/CompetenceDialog/CompetenceDialog';

interface CompetenceCardProps
{
    competence: Competence
    selected?: boolean
    onSelectChange?: (state: boolean) => void
}

/**
 * CompetenceCard Component
 *
 * @param {CompetenceCardProps} props - The props object containing the data for the CompetenceCard
 * @returns {JSX.Element} The rendered CompetenceCard component
 */
function CompetenceCard(props: CompetenceCardProps)
{
    const { openDialog } = useDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;
    const navigate = useNavigate();
    const competence = props.competence;
    return (
        <CardBox
            menu={
                <div>
                    <MenuItem
                        onClick={() =>
                        {
                            return openDialog(
                                <CompetenceDialog
                                    id={"CompetenceDialog"}
                                    competence={competence}
                                />);
                        }}
                    >
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText>{langStrings.edit}</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                        {
                            competence.delete()
                        }}
                    >
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>{langStrings.delete}</ListItemText>
                    </MenuItem>
                </div>
            }
            onSelectChange={props.onSelectChange}
            selected={props.selected}
            title={competence.title}
            className={'competenceCard'}
            onClick={() =>
            {

                // return;
                navigate(entityTypeToLink(competence.id, EEntityType.COMPETENCE))
            }}
        >
            {/* <div>{langStrings.skillCoverage}: {competence.skillCoverage}% </div> */}
            <ExpandableText>
                <>
                    {langStrings.description + ": "}
                    <FormatedTextInput value={competence.description} readonly={true} />
                </>
            </ExpandableText>
        </CardBox>
    );
}

export default CompetenceCard;
