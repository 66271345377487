export interface INotification
{
    id: string | number;
    notificationType: ENotificationType
    title: string
    message: string;
    messageHTML: string;
    version: number;
    publishedAt: string; //ISO-Datestring
}

export interface IEmployeeNotification extends INotification
{
    id: string;

}

export interface IAdminNotification extends INotification
{
    id: number;
}

export enum ENotificationType
{
    INFO = 'info',
    ERROR = 'error',
    //Weitere werden folgen...
}

export interface IEmployeeNotificationCreate
{
    title: string
    message: string;
    messageHTML: string;
    publishedAt?: string
    notificationType?: ENotificationType
    employeeId: number
}

export interface IAdminNotificationCreate
{
    title: string
    message: string;
    messageHTML: string;
    publishedAt?: string
    notificationType?: ENotificationType
}

export type TNotificationWebsocketResponse = INewNotificationResponse | IDeleteNotificationResponse;


interface INewNotificationResponse
{
    type: 'new',
    notification: IAdminNotification | IEmployeeNotification
}

interface IDeleteNotificationResponse
{
    type: 'delete',
    id: string | number,
}


export type TNotificationWebsocketRequest = IRemoveNotificationRequest;
export interface IRemoveNotificationRequest
{
    type: 'delete',
    id: string | number
}

export function isEmployeeNotification(notification: INotification): notification is IEmployeeNotification
{
    return typeof notification.id === 'string';
}

// Type Guard für IAdminNotification
export function isAdminNotification(notification: INotification): notification is IAdminNotification
{
    return typeof notification.id === 'number';
}