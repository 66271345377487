import React, { useEffect, useState } from 'react';
import { Add, TableChart, TableRows } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import './ProductsPage.css';
import Product from '@src/Objects/Product';
import { textContainsLowerCase } from '@src/Objects/Helper';
import ProductDialog from '@src/Components/Dialogs/ProductDialog/ProductDialog';
import ProductOverlay from '@src/Components/Overlays/ProductOverlay/ProductOverlay';
import EnhancedTable, { SortOrder } from '@src/App/NewLayout/Components/DataTable/DataTable';
import getProducts from '@src/APIs/graphQl/Product/getProducts';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import ProductCard from '@src/App/NewLayout/Cards/Products/ProductCard/ProductCard';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

import EntitiesPage, { EViewType, IEntitiesPageAction } from '../../../../../sharedReact/Pages/EntitiesPage/EntitiesPage';
import { IViewType } from '../../../../../sharedReact/Pages/EntitiesPage/ViewTypeMenu';


/**
 * Represents the ProductsPage component.
 * This component is responsible for rendering the page for displaying products.
 * 
 * @returns {JSX.Element} The rendered ProductsPage component.
 */
function ProductsPage()
{
    const { openDialog } = useDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const entities = useSelector((state: AppState) => state.products)
    const langStrings = getLanguageTexts(lang).products;
    const permissions = useSelector((state: AppState) => state.permissions);
    const emp = useSelector((state: AppState) => state.employee);
    const [selected, setSelected] = useState<number[]>([]);
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);

    const isDense = (width: number) => width <= 1000

    useEffect(() =>
    {
        getProducts().catch((ex) =>
        {
            setLoadingError(ex);
        })
    }, []);


    const types: IViewType<Product>[] = [
        {
            id: EViewType.TABLE,
            title: langStrings.oftenUsed.table,
            icon: <TableRows fontSize='small' />,
            minWidth: 400,
            renderContainer:
                (allEntities, innerEntries, width, selected) =>
                    <EnhancedTable
                        id={EEntityType.PRODUCT}
                        dense={isDense(width)}
                        noCheckbox={true}
                        selected={selected}
                        order={SortOrder.ASC}
                        orderBy='title'
                        setSelected={setSelected}
                        fields={[{
                            id: 'id',
                            label: 'ID',
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'string',
                        },
                        {
                            id: 'title',
                            label: langStrings.productName,
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'JSX.Element',
                            link: `/${ELinks.SALES_PRODUCTS}/`,
                        },
                        {
                            id: 'skillCoverage',
                            label: langStrings.skillCoverage,
                            disablePadding: isDense(width),
                            align: 'center',
                            type: 'percent',
                        },
                        {
                            id: 'description',
                            label: langStrings.description,
                            disablePadding: isDense(width),
                            align: 'left',
                            minWidth: 1000,
                            type: 'description',
                        },
                        {
                            id: 'employees',
                            label: langStrings.employees,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 435,
                            type: 'string',
                        },
                        {
                            id: 'skills',
                            label: langStrings.skills,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 550,
                            type: 'string',
                        },
                        {
                            id: 'certificates',
                            label: langStrings.certificates,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 650,
                            type: 'string',
                        },
                        ]}
                        rows={
                            innerEntries.map(entity => ({
                                id: entity.id,
                                title: {
                                    value: <ProductOverlay
                                        productId={entity.id}
                                    >
                                        {entity.title}
                                    </ProductOverlay>,
                                    orderKey: entity.title
                                },
                                skillCoverage: entity.skillCoverage,
                                description: entity.description,
                                employees: entity.employees.length,
                                skills: entity.skills.length,
                                certificates: entity.certificates.length,
                            }
                            ))
                        } />

            ,
        },
        {
            id: EViewType.CARD,
            title: langStrings.oftenUsed.cards,
            icon: <TableChart fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <Container>
                {
                    innerEntries.map(entity =>
                        <ProductCard key={entity.id} product={entity}
                        />)
                }
            </Container>,
        }
    ];

    const actions: IEntitiesPageAction[] = [
        {
            id: 'create',
            text: langStrings.newProduct,
            icon: <Add />,
            filter()
            {
                if (!checkPermissions('Products', 'Add', permissions))
                    return false;
                return true;

            },
            action()
            {
                return openDialog(
                    <ProductDialog
                        id="ProductDialog"
                    />);
            },
        }
    ];
    return (
        <EntitiesPage
            title={langStrings.products}
            entities={entities}
            loadingError={loadingError}
            selected={selected}
            setSelected={setSelected}
            views={[
                {
                    id: 'all', title: langStrings.oftenUsed.allEntries,
                    filter()
                    {
                        return true;
                    },
                },
                {
                    id: 'my', title: langStrings.oftenUsed.myEntries,
                    filter(entity)
                    {
                        return entity.employees.find(e => e.id === emp?.id) !== undefined
                    },
                },
            ]}
            types={types}
            actions={actions}
            filter={(entity, selectedView, searchText) =>
                selectedView.filter(entity) && (
                    textContainsLowerCase(searchText, entity.title)
                    || textContainsLowerCase(searchText, entity.id.toString())
                )
            }
        />
    );
}

export default ProductsPage;
