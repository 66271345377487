import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SentimentVeryDissatisfied } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import { RenderJson } from '@src/helper/tsxHelper';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

import Dialog from '../Dialog/Dialog';

interface ErrorModalProps
{
    id: string;
    error: Error;
    errorMessage?: string;
    resolve: () => void;
}

export const ErrorDialog: React.FC<ErrorModalProps> = ({ id, errorMessage, error, resolve }) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).error;
    const offline = useSelector((state: AppState) => state.client.offline);

    const [isOpen, setIsOpen] = useState(true);
    const [expandError, setExpandError] = useState(false);

    const onConfirm = () =>
    {
        resolve();
        setIsOpen(false);
    };

    if (!isOpen) return null;

    let message = error.message;
    let title = langStrings.errorHappend;
    if (offline)
    {
        message = langStrings.offlineMessage;
        title = langStrings.offlineTitle;
    }
    if (errorMessage)
    {
        message = errorMessage;
    }
    return (
        <Dialog
            id={id}
            footer={
                <Button
                    text={langStrings.oftenUsed.okay}
                    icon={<SentimentVeryDissatisfied />}
                    onClick={onConfirm}
                />
            }
            title={title}
            onClose={onConfirm}
        >
            <p>{message}</p>
            <Typography
                variant='caption'
                style={{ cursor: 'pointer' }}
                sx={{ color: 'text.secondary' }}
                onClick={() => { setExpandError(!expandError) }}
            >
                {langStrings.errorDetails}
            </Typography>
            {
                expandError &&
                <>
                    <div>
                        <Typography variant='body2'>{langStrings.errorName}: {error.name}</Typography>
                        <Typography variant='body2'>{langStrings.errorMessage}: {error.message}</Typography>
                        {error.stack && (
                            <Typography variant='body2'>{langStrings.stackTrace}:</Typography>
                        )}
                        {error.stack && (
                            <Typography variant='body2' style={{ whiteSpace: 'pre-wrap' }}>
                                {error.stack}
                            </Typography>
                        )}
                    </div>
                    <RenderJson data={error} />
                </>

            }
        </Dialog>
    );
};

export function useErrorDialog()
{
    const { openDialog } = useDialog();

    const showErrorDialog = (error: Error, errorMessage?: string): Promise<void> =>
    {
        return new Promise<void>((resolve) =>
        {
            const ErrorModalDialog = (
                <ErrorDialog
                    id="ErrorDialog"
                    error={error}
                    errorMessage={errorMessage}
                    resolve={() => resolve()}
                />
            );
            openDialog(ErrorModalDialog);
        });
    };

    return showErrorDialog;
}