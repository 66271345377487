import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";

const q = gql`
query getAttachmentDownloadURL($id: ID!) {
    getAttachmentDownloadURL(id: $id)
}
`;

/**
 * Retrieves an attachment by its ID, then updates the Redux store.
 *
 * @param {number} attachmentId - The ID of the attachment to retrieve.
 * @returns {Promise<Attachment>} A promise that resolves with the retrieved attachment.
 */
export default async function getAttachmentDownloadURL(id: string)
{
    const variables: OperationVariables = {
        id
    };
    return GraphQL.query<{ getAttachmentDownloadURL: string }>(q, variables).then((data) =>
    {
        if (!data?.getAttachmentDownloadURL)
        {
            throw new Error(`Attachment ${id} url not found`)
        }

        return data.getAttachmentDownloadURL;
    });
}