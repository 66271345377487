export const validateDate = (date: undefined | null | Date | string) =>
{
    if (date === undefined || date === null)
    {
        return undefined;
    }
    let newDate: Date | undefined = undefined;
    try
    {
        newDate = new Date(date);

    } catch (error)
    { /* empty */ }
    if (newDate === undefined || newDate === null || Object.prototype.toString.call(newDate) !== "[object Date]" || isNaN(newDate.getTime()))
    {
        newDate = undefined;
    }
    return newDate;
}

export const generateAnimationTime = (index: number) =>
{
    let time = (index * 0.1)
    if (time > 2) time = 2;
    return time;
}

export const textContainsLowerCase = (searchText: string, fullText: string) =>
{
    return fullText.toLowerCase().indexOf(searchText.toLowerCase()) > -1
}

export const isValidEmailEnding = (ending: string): boolean =>
{
    const regex = /^@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
    return regex.test(ending);
}