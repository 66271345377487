import { OperationVariables, gql } from "@apollo/client";
import IOpportunity, { IOpportunityNew } from "@sharedInterfaces/IOpportunity";
import Opportunity from "@src/Objects/Opportunity";

import GraphQL from "../graphQL";

import { opportunityGql } from "./getOpportunity";


const query = gql`
mutation createOpportunity($newOpportunity:OpportunityNew!) {
    createOpportunity(newOpportunity: $newOpportunity) 
    ${opportunityGql}
},
`;

export default async function createOpportunity(newOpportunity: IOpportunityNew): Promise<Opportunity>
{
    const variables: OperationVariables = {
        newOpportunity: {
            ...newOpportunity,
            roles: newOpportunity.roles.map(role =>
            ({
                title: role.title,
                id: role.id,
            })),
            primaryRole: newOpportunity.primaryRole ? {
                title: newOpportunity.primaryRole.title,
                id: newOpportunity.primaryRole.id,
            } : undefined
        }
    };
    return GraphQL.mutate<IOpportunity>(query, variables).then(result => new Opportunity(result))
}