import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Cancel, Delete, Save, Settings, TableChart, TableRows } from '@mui/icons-material';
import './OpportunitiesPage.css';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { EEntityType, ELinks, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import { useDialog } from '@sharedReact/Dialog/DialogManager';
import Opportunity from '@src/Objects/Opportunity';
import { textContainsLowerCase } from '@src/Objects/Helper';
import OpportunityDialog from '@src/Components/Dialogs/OpportunityDialog/OpportunityDialog';
import OpportunityOverlay from '@src/Components/Overlays/OpportunityOverlay/OpportunityOverlay';
import EnhancedTable from '@src/App/NewLayout/Components/DataTable/DataTable';
import getOpportunities from '@src/APIs/graphQl/Opportunity/getOpportunities';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import OpportunityCard from '@src/App/NewLayout/Cards/Opportunities/OpportunityCard/OpportunityCard';
import { Link } from 'react-router-dom';
import Button from '@sharedReact/General/Button/Button';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import { ListItem, Grid, TextField, Typography, Checkbox, IconButton, List, CircularProgress } from '@mui/material';
import { StatusOption } from '@sharedInterfaces/ICompany';
import { setCompanyOpportunitySettings } from '@store/reducer/companyReducer';
import updateOpportunityStatusOptions from '@src/APIs/graphQl/Company/updateOpportunityStatusOptions';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import OpportunityStatusSelect from '@src/Components/formsControls/inputs/OpportunityStatusSelect/OpportunityStatusSelect';
import editOpportunityStatus from '@src/APIs/graphQl/Opportunity/editOpportunityStatus';

import EntitiesPage, { EViewType, IEntitiesPageAction } from '../../../../../sharedReact/Pages/EntitiesPage/EntitiesPage';
import { IViewType } from '../../../../../sharedReact/Pages/EntitiesPage/ViewTypeMenu';


/**
 * OpportunitiesPage component.
 * 
 * @returns {JSX.Element} The rendered OpportunitiesPage component.
 */
function OpportunitiesPage()
{
    const { openDialog } = useDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).opportunities;
    const permissions = useSelector((state: AppState) => state.permissions);
    const entities = useSelector((state: AppState) => state.opportunity)
    const statusOptions = useSelector((state: AppState) => state.company.opportunitySettings.statusOptions);
    const statusOptionsMap = new Map<string, StatusOption>(statusOptions.map((option) => [option.id, option]));

    const emp = useSelector((state: AppState) => state.employee);
    const [selected, setSelected] = useState<number[]>([]);
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);

    useEffect(() =>
    {
        getOpportunities().catch((ex) =>
        {
            setLoadingError(ex);
        })
    }, []);

    const types: IViewType<Opportunity>[] = [
        {
            id: EViewType.TABLE,
            title: langStrings.oftenUsed.table,
            icon: <TableRows fontSize='small' />,
            renderContainer:
                (allEntities, innerEntries, width, selected) =>
                    <EnhancedTable
                        id={EEntityType.OPPORTUNITY}
                        dense={true}
                        noCheckbox={true}
                        selected={selected}
                        setSelected={setSelected}
                        fields={[{
                            id: 'id',
                            label: 'ID',
                            disablePadding: true,
                            align: 'center',
                            type: 'string',
                        },
                        {
                            id: 'externalId',
                            label: langStrings.externalID,
                            disablePadding: true,
                            align: 'center',
                            type: 'string',
                        },
                        {
                            id: 'source',
                            label: langStrings.sourceName,
                            disablePadding: true,
                            align: 'left',
                            type: 'JSX.Element',
                        },
                        {
                            id: 'title',
                            label: langStrings.opportunityName,
                            disablePadding: true,
                            align: 'left',
                            type: 'JSX.Element',
                            link: `/${ELinks.SALES_OPPORTUNITIES}/`,
                        },
                        {
                            id: 'customer',
                            label: langStrings.customer,
                            disablePadding: true,
                            align: 'center',
                            type: 'string',
                        },
                        {
                            id: 'employees',
                            label: langStrings.employees,
                            disablePadding: true,
                            align: 'center',
                            type: 'string',
                        },
                        {
                            id: 'projectStart',
                            label: langStrings.startDate,
                            disablePadding: true,
                            align: 'center',
                            type: 'date',
                        },
                        {
                            id: 'projectEnd',
                            label: langStrings.endDate,
                            disablePadding: true,
                            align: 'center',
                            type: 'date',
                        },
                        {
                            id: 'publishDate',
                            label: langStrings.publishDate,
                            disablePadding: true,
                            align: 'center',
                            type: 'date',
                        },
                        {
                            id: 'deadline',
                            label: langStrings.deadline,
                            disablePadding: true,
                            align: 'center',
                            type: 'date',
                        },
                        {
                            id: 'skillCoverage',
                            label: langStrings.skillCoverage,
                            disablePadding: true,
                            align: 'center',
                            type: 'percent',
                        },
                        {
                            id: 'skillScore',
                            label: langStrings.skillScore,
                            disablePadding: true,
                            align: 'center',
                            type: 'percent',
                        },
                        {
                            id: 'utilization',
                            label: langStrings.utilization,
                            disablePadding: true,
                            align: 'center',
                            type: 'percent',
                        },
                        {
                            id: 'remote',
                            label: langStrings.remote,
                            disablePadding: true,
                            align: 'center',
                            type: 'percent',
                        },
                        {
                            id: 'dayPrice',
                            label: langStrings.dayPrice,
                            disablePadding: true,
                            align: 'center',
                            type: 'currency',
                        },
                        {
                            id: 'status',
                            label: langStrings.status,
                            disablePadding: true,
                            align: 'center',
                            type: 'JSX.Element',
                        },
                        {
                            id: 'primaryRole',
                            label: langStrings.primaryRole,
                            disablePadding: true,
                            align: 'center',
                            type: 'JSX.Element',
                        },
                        {
                            id: 'topSkills',
                            label: langStrings.topSkills,
                            disablePadding: true,
                            align: 'center',
                            type: 'string',
                        },
                        ]}
                        rows={
                            innerEntries.map(entity =>
                            {
                                const skillCoverage = !entity.selectedEmployees.length ? 0 :
                                    Math.round(entity.selectedEmployees
                                        .reduce((prev, cur) => cur.skillCoverage + prev, 0) / entity.selectedEmployees.length * 100)
                                const skillScore = !entity.selectedEmployees.length ? 0 : Math.round(entity.selectedEmployees
                                    .reduce((prev, cur) => cur.score + prev, 0) / entity.selectedEmployees.length * 100)
                                return {
                                    id: entity.id,
                                    title: {
                                        value: <OpportunityOverlay
                                            opportunityId={entity.id}
                                        >
                                            {entity.title}
                                        </OpportunityOverlay>,
                                        orderKey: entity.title
                                    },
                                    externalId: entity.externalId,
                                    source: {
                                        orderKey: entity.sourceName,
                                        value: (entity.sourceName !== "" && entity.sourceURL !== "") ?
                                            <Link to={entity.sourceURL}>{entity.sourceName}</Link> :
                                            entity.sourceName,
                                    },
                                    customer: entity.customer,
                                    employees: entity.headCount,
                                    dayPrice: entity.dayPrice,
                                    projectStart: entity.projectStart,
                                    projectEnd: entity.projectEnd,
                                    publishDate: entity.publishDate,
                                    deadline: entity.deadline,
                                    primaryRole: {
                                        orderKey: entity.primaryRole?.title || '',
                                        value: entity.primaryRole ? <Link to={entityTypeToLink(entity.primaryRole.id, EEntityType.ROLE)}>
                                            {entity.primaryRole.title}
                                        </Link>
                                            : '',
                                    },
                                    utilization: entity.utilization,
                                    remote: entity.remote,
                                    topSkills: entity.skills.slice(0, 10).map(s => s.title).join(", "),
                                    status: {
                                        orderKey: statusOptionsMap.get(entity.status)?.title || '',
                                        value: <ConnectedOpportunityStatusSelect
                                            entity={entity}
                                        />
                                    },
                                    // active: `${entity.active ? langStrings.yes : langStrings.no}`,
                                    skillCoverage,
                                    skillScore,
                                }
                            })
                        } />

            ,
        },
        {
            id: EViewType.CARD,
            title: langStrings.oftenUsed.cards,
            icon: <TableChart fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <Container>
                {
                    innerEntries.map(entity =>
                        <OpportunityCard key={entity.id} opportunity={entity}
                        />)
                }
            </Container>,
        }
    ];

    const actions: IEntitiesPageAction[] = [
        {
            id: 'create',
            text: langStrings.newOpportunity,
            icon: <Add />,
            filter()
            {
                if (!checkPermissions('Opportunities', 'Add', permissions))
                    return false;
                return true;

            },
            action()
            {
                return openDialog(<OpportunityDialog id="OpportunityDialog" />);
            },
        }
    ];
    return (
        <EntitiesPage
            title={langStrings.opportunities}
            entities={entities}
            selected={selected}
            loadingError={loadingError}
            buttons={
                permissions.admin ?
                    <Button
                        icon={<Settings />}
                        text={langStrings.settings}
                        onClick={onClickOpportunitySettings} />
                    : undefined
            }
            setSelected={setSelected}
            views={[
                {
                    id: 'active', title: 'Aktive Einträge',
                    filter(entity)
                    {
                        return statusOptionsMap.get(entity.status)?.isActive === true;
                        // return entity.active === true;
                    },
                },
                {
                    id: 'all', title: langStrings.oftenUsed.allEntries,
                    filter()
                    {
                        return true;
                    },
                },
                {
                    id: 'my', title: langStrings.oftenUsed.myEntries,
                    filter(entity)
                    {
                        return entity.selectedEmployees.find(e => e.id === emp?.id) !== undefined
                    },
                },
            ]}
            types={types}
            actions={actions}
            filter={(entity, selectedView, searchText) =>
                selectedView.filter(entity) &&
                (
                    textContainsLowerCase(searchText, entity.title)
                    || textContainsLowerCase(searchText, entity.customer)
                    || textContainsLowerCase(searchText, entity.id.toString())
                )
            }
        />
    );

    function onClickOpportunitySettings()
    {
        openDialog(<OpportunitySettingsDialog id="OpportunitySettingsDialog" />);
    }
}

export default OpportunitiesPage;


interface OpportunitySettingsDialogProps
{
    id: string;
    resolve?: () => void;
}

const OpportunitySettingsDialog: React.FC<OpportunitySettingsDialogProps> = ({ id, resolve }) =>
{
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).opportunities;
    const statusOptionsFromStore = useSelector((state: AppState) => state.company.opportunitySettings.statusOptions);

    // Lokaler Zustand für die Statusoptionen
    const [statusOptions, setStatusOptions] = useState<StatusOption[]>([]);
    const [newStatusName, setNewStatusName] = useState<string>('');

    useEffect(() =>
    {
        setStatusOptions(statusOptionsFromStore);
        setNewStatusName('');
    }, [statusOptionsFromStore]);

    const handleAddStatus = () =>
    {
        if (newStatusName.trim() === '') return;
        const newStatus: StatusOption = {
            id: Date.now().toString(),
            title: newStatusName.trim(),
            isActive: true,
        };
        setStatusOptions([...statusOptions, newStatus]);
        setNewStatusName('');
    };

    const handleDeleteStatus = (id: string) =>
    {
        setStatusOptions(statusOptions.filter(status => status.id !== id));
    };

    const handleNameChange = (id: string, newName: string) =>
    {
        setStatusOptions(statusOptions.map(status => status.id === id ? { ...status, title: newName } : status));
    };

    const handleToggleActive = (id: string) =>
    {
        setStatusOptions(statusOptions.map(status => status.id === id ? { ...status, isActive: !status.isActive } : status));
    };

    const handleSave = () =>
    {
        updateOpportunityStatusOptions(statusOptions).then(() =>
        {
            dispatch(setCompanyOpportunitySettings({ statusOptions }));
            resolve && resolve();
        })
            .catch((ex) =>
            {
                showErrorDialog(ex);
            })
        // dispatch(setCompanyOpportunitySettings({ statusOptions }));
    };

    const handleCancel = () =>
    {
        resolve && resolve();
    };

    return (
        <Dialog
            title={langStrings.settings}
            id={id}
            // bigWidth
            footer={
                <>
                    <div style={{ float: 'left' }}>
                        <Button onClick={handleCancel} color="secondary" text={langStrings.oftenUsed.cancel} icon={<Cancel />} />
                    </div>
                    <div style={{ float: 'right' }}>
                        <Button onClick={handleSave} color="primary" text={langStrings.save} icon={<Save />} />
                    </div>
                </>
            }
            onClose={() => resolve && resolve()}
        >
            {statusOptions.length > 0 && <List>
                {statusOptions.map((status) => (
                    <ListItem key={status.id} divider>
                        <Grid container alignItems="center" spacing={2} padding={0}>
                            <Grid item xs={8} style={{ padding: 0 }}>
                                <TextField
                                    value={status.title}
                                    onChange={(e) => handleNameChange(status.id, e.target.value)}
                                    label={langStrings.statusName}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: 'center' }}>
                                <Typography>{langStrings.active}</Typography>
                                <Checkbox
                                    checked={status.isActive}
                                    onChange={() => handleToggleActive(status.id)}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteStatus(status.id)}>
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
            }
            <Grid container spacing={2} alignItems="center" style={{}}>
                <Grid item xs={8}>
                    <TextField
                        value={newStatusName}
                        onChange={(e) => setNewStatusName(e.target.value)}
                        label={langStrings.newStatus}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        color="primary"
                        text={langStrings.oftenUsed.add}
                        icon={<Add />}
                        onClick={handleAddStatus}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};


function ConnectedOpportunityStatusSelect({ entity }: { entity: Opportunity })
{
    const showErrorDialog = useErrorDialog();
    const [updating, setUpdating] = useState(false);
    return updating ?
        <CircularProgress size={24} />
        :
        <OpportunityStatusSelect
            value={entity.status}
            onChange={(newStatus) =>
            {
                setUpdating(true);
                editOpportunityStatus(entity.id, newStatus)
                    .catch(ex => showErrorDialog(ex))
                    .finally(() => setUpdating(false));
            }}
        />;
}