

import React from 'react';
import { useSelector } from 'react-redux';
import { Select, MenuItem } from '@mui/material';
import { IIndustriesTexts, industriesTexts } from '@sharedInterfaces/Language/languageTexts/industriesTexts';
import { AppState } from '@store/store';


interface IndustrySelectProps
{
    industry: keyof IIndustriesTexts;
    setIndustry: (val: keyof IIndustriesTexts) => void;
}


/**
 * Component to render an industry select.
 *
 * @param {IndustrySelectProps} industry - The selected industry.
 * @param {Function} setIndustry - The function to set the selected industry.
 * 
 * @returns {JSX.Element} The rendered industry select.
 */
function IndustrySelect({ industry, setIndustry }: IndustrySelectProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const industries = industriesTexts[lang];

    return (
        <Select
            labelId="country-select-label"
            value={industry}
            onChange={(e) =>
            {
                setIndustry(e.target.value as keyof IIndustriesTexts);
            }}
            MenuProps={{
                style: { zIndex: 1400 }
            }}
        >
            {(industry as string === "" || !industry) && <MenuItem value=""><em>-</em></MenuItem>}
            {Object.entries(industries).map(([code, name]) => (
                <MenuItem key={code} value={code}>
                    {name}
                </MenuItem>
            ))}
        </Select>
    );
}

export default IndustrySelect;
