import React from 'react';
import { useSelector } from 'react-redux';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import getRole from '@src/APIs/graphQl/Role/getRole';
import RolePage from '@src/App/NewLayout/Pages/Roles/RolePage/RolePage';

import Overlay from '../Overlay/Overlay';

interface RoleOverlayProps
{
    roleId: number;
    children: React.ReactNode
    disabled?: boolean
}

/**
 * RoleOverlay
 * 
 * @param {RoleOverlayProps} props - The props for the RoleOverlay component
 * @returns {JSX.Element} - The rendered RoleOverlay component
 */
function RoleOverlay(props: RoleOverlayProps)
{
    const entity = useSelector((state: AppState) => state.roles.find(a => a.id === props.roleId))
    const permissions = useSelector((state: AppState) => state.permissions);
    const isAllowed = checkPermissions('Roles', 'Retrieve', permissions) || checkPermissions('Roles', 'RetrieveOwn', permissions)
    const disabled = !isAllowed || props.disabled
    const [error, setError] = React.useState<Error | null>(null);
    return (
        <Overlay
            disabled={disabled}
            triggerItem={props.children}
            loaded={entity !== null}
            error={error}
            onFirstOpen={() =>
            {
                getRole(props.roleId)
                    .catch(setError);
            }}>
            {entity && <RolePage
                entity={entity}
                inDialog={true}
            />}
        </Overlay>
    )
}

export default RoleOverlay;