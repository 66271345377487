import React, { } from 'react';
import { EEntityType } from '@sharedInterfaces/globalEnums';

import SkillOverlay from '../SkillOverlay/SkillOverlay';
import CertificateOverlay from '../CertificateOverlay/CertificateOverlay';
import EmployeeOverlay from '../EmployeeOverlay/EmployeeOverlay';
import SkillCategoryOverlay from '../SkillCategoryOverlay/SkillCategoryOverlay';
import CompetenceOverlay from '../CompetenceOverlay/CompetenceOverlay';
import BusinessOverlay from '../BusinessOverlay/BusinessOverlay';
import OrgUnitOverlay from '../OrgUnitOverlay/OrgUnitOverlay';
import OpportunityOverlay from '../OpportunityOverlay/OpportunityOverlay';
import ProductOverlay from '../ProductOverlay/ProductOverlay';
import ProjectOverlay from '../ProjectOverlay/ProjectOverlay';
import RoleOverlay from '../RoleOverlay/RoleOverlay';

interface OverlayProps
{
    entityId: number;
    entityType: EEntityType
    children: React.ReactNode
    disabled?: boolean
}

/**
 * Overlay component.
 *
 * @param {OverlayProps} props - The props for the Overlay component.
 * @returns {JSX.Element} The rendered Overlay component.
 */
function GenericOverlay({ children, disabled, entityId, entityType, }: OverlayProps)
{
    switch (entityType)
    {
        case EEntityType.ROLE:
            return <RoleOverlay
                roleId={entityId}
                disabled={disabled}
            >
                {children}
            </RoleOverlay>;
        case EEntityType.SKILL:
            return <SkillOverlay
                skillId={entityId}
                disabled={disabled}
            >
                {children}
            </SkillOverlay>;
        case EEntityType.CERTIFICATE:
            return <CertificateOverlay
                certificateId={entityId}
                disabled={disabled}
            >
                {children}
            </CertificateOverlay>;
        case EEntityType.SKILL_CATEGORY:
            return <SkillCategoryOverlay
                skillCategoryId={entityId}
                disabled={disabled}
            >
                {children}
            </SkillCategoryOverlay>;
        case EEntityType.COMPETENCE:
            return <CompetenceOverlay
                competenceId={entityId}
                disabled={disabled}
            >
                {children}
            </CompetenceOverlay>;
        case EEntityType.EMPLOYEE:
            return <EmployeeOverlay
                employeeId={entityId}
                disabled={disabled}
            >
                {children}
            </EmployeeOverlay>;
        case EEntityType.ORGANISATIONAL_UNIT:
            return <OrgUnitOverlay
                id={entityId}
                disabled={disabled}
            >
                {children}
            </OrgUnitOverlay>;
        case EEntityType.BUSINESS:
            return <BusinessOverlay
                businessId={entityId}
                disabled={disabled}
            >
                {children}
            </BusinessOverlay>;
        case EEntityType.OPPORTUNITY:
            return <OpportunityOverlay
                opportunityId={entityId}
                disabled={disabled}
            >
                {children}
            </OpportunityOverlay>;
        case EEntityType.PRODUCT:
            return <ProductOverlay
                productId={entityId}
                disabled={disabled}
            >
                {children}
            </ProductOverlay>;
        case EEntityType.PROJECT:
            return <ProjectOverlay
                projectId={entityId}
                disabled={disabled}
            >
                {children}
            </ProjectOverlay>;

        default:
            return <>{children}</>;
    }
}

export default GenericOverlay;