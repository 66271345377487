import store from "@store/store";
import { stageContext } from "@src/globals";
// if (window.location.pathname === `/${ELinks.LOGGEDIN}`)
// {
//     const cookies = localStorage.getItem("cookies");
//     localStorage.clear();
//     cookies && localStorage.setItem("cookies", cookies);
// }
const baseURL = stageContext.apiGatewayRestDomain;
export const restPost = <T>(path: string, data?: unknown, token?: string) =>
{
    return new Promise<T>((resolve, reject) =>
    {
        const state = store.getState();
        let usedToken: string;
        if (token)
        {
            usedToken = token;
        }
        else if (state.client.secret)
        {
            usedToken = state.client.secret;
        }
        else
        {
            return reject('No token');
        }

        fetch(baseURL + path, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': usedToken,
            },
            body: JSON.stringify(data)
        })
            .then((response) =>
            {
                if (response.ok)
                    return response.json();
                else
                {
                    reject({ status: response?.status, text: response?.statusText });
                }
            })
            .then((data) =>
            {

                resolve(data);
            })
            .catch((error) =>
            {

                console.error("Error:", error);
                reject(error);
            });
    })
}