import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import CardBox from '@src/Components/CardBox/CardBox';
import ProductOverlay from '@src/Components/Overlays/ProductOverlay/ProductOverlay';
import Item from '@src/App/NewLayout/Items/Item/Item';
import List, { generateTopXText } from '@src/App/NewLayout/Lists/List/List';

interface ProductsCardProps
{
    title?: string;
    products: {
        id: number
        title: string,
        level?: number
    }[];
    inOverlay?: boolean
    limit?: number
}

/**
 * Render a product card with given title, products, and overlay indicator.
 * 
 * @param {ProductsCardProps} title - The title of the products card.
 * @param {ProductsCardProps} products - The products to be displayed in the card.
 * @param {ProductsCardProps} inOverlay - The indicator if the card is in overlay mode.
 * 
 * @returns {JSX.Element} The rendered product card.
 */
function ProductsCard({ limit, title, products, inOverlay, }: ProductsCardProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).products;
    const newTitle = generateTopXText(title ? title : langStrings.products, limit);
    const limitedElements = limit ? products
        .slice(0, limit > products.length ? products.length : limit) : products;
    const content = limitedElements.map((product) =>
        <Item key={product.id} percent={0} onClick={entityTypeToLink(product.id, EEntityType.PRODUCT)}>
            <ProductOverlay key={product.id} disabled={inOverlay} productId={product.id}>
                {product.title}
            </ProductOverlay>
        </Item>
    );
    return (
        <CardBox title={newTitle}>
            <List>
                {content}
            </List>
        </CardBox>
    );
}

export default ProductsCard;