import { Action } from "redux";
import Certificate from "@src/Objects/Certificate";

const initialState: Certificate[] = [];

export enum ECertificateAction
{
    SET_CERTIFICATES = "SET_CERTIFICATES",
    DELETE_CERTIFICATE = "DELETE_CERTIFICATE",
    UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE",
}

interface SetCertificatesAction extends Action
{
    type: ECertificateAction.SET_CERTIFICATES;
    payload: Certificate[];
}

interface DeleteCertificateAction extends Action
{
    type: ECertificateAction.DELETE_CERTIFICATE;
    payload: number; // Annahme, dass ID eine Zahl ist
}

interface UpdateCertificateAction extends Action
{
    type: ECertificateAction.UPDATE_CERTIFICATE;
    payload: Certificate;
}

type CertificateAction = SetCertificatesAction | DeleteCertificateAction | UpdateCertificateAction;

export const certificateReducer = (state = initialState, action: CertificateAction): Certificate[] =>
{
    switch (action.type)
    {
        case ECertificateAction.SET_CERTIFICATES:
            return action.payload;
        case ECertificateAction.DELETE_CERTIFICATE:
            return state.filter(certificate => certificate.id !== action.payload);
        case ECertificateAction.UPDATE_CERTIFICATE:
            // eslint-disable-next-line no-case-declarations
            const index = state.findIndex(certificate => certificate.id === action.payload.id);
            if (index !== -1)
            {
                const newState = [...state];
                newState[index] = action.payload;
                return newState;
            } else
            {
                return [...state, action.payload];
            }
        default:
            return state;
    }
};

// Action Creator
export const setCertificates = (certificates: Certificate[]): SetCertificatesAction => ({
    type: ECertificateAction.SET_CERTIFICATES,
    payload: certificates,
});

export const deleteCertificate = (id: number): DeleteCertificateAction => ({
    type: ECertificateAction.DELETE_CERTIFICATE,
    payload: id,
});

export const updateCertificate = (certificate: Certificate): UpdateCertificateAction => ({
    type: ECertificateAction.UPDATE_CERTIFICATE,
    payload: certificate,
});
