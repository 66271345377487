import React from 'react';
import { Assignment, BarChart, Info, Insights, Logout, Psychology } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { Badge, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InnerLayout from '@sharedReact/Layouts/InnerLayout/InnerLayout';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { calculateEmployeeCoverage, calculateLearningProgress, generateFullName } from '@sharedInterfaces/globalHelper';
import { EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';

import AnalyseSkillsPdfButton from '../../Components/Buttons/AnalyseSkillsPdfButton/AnalyseSkillsPdfButton';
import AnalyseSkillsTextButton from '../../Components/Buttons/AnalyseSkillsTextButton/AnalyseSkillsTextButton';
import EditCertificateList from '../../Components/EditCertificateList/EditCertificateList';
import { logout } from '../../Components/UserButton/UserButton';
import Button from '../../sharedReact/General/Button/Button';
import CardBox from '../../Components/CardBox/CardBox';
import './ProfileSettings.css';
import AvailabilitySettings from '../../Components/AvailabilitySettings/AvailabilitySettings';
import LoadingBox from '../../sharedReact/General/LoadingBox/LoadingBox';
import EditSkillList from '../../Components/EditSkillList/EditSkillList';
import { EmployeePicture } from '../../Components/EmployeePicture/EmployeePicture';
import { entityTypeToIcon } from '../../helper/tsxHelper';
import EditRoleList from '../../Components/EditRoleList/EditRoleList';


import { SuggestedSkills, SuggestedSkillsPreview } from './SuggestedSkills';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import { DevelopmentTab, mergeWantedSkills } from './DevelopmentTab';

/**
 * Component representing the ProfileSettings.
 * 
 * @returns JSX.Element
 */
function ProfileSettings(
    { tabId, urlSuffix }:
        {
            tabId: EProfileSettingsTabs,
            urlSuffix?: 'SUGGESTIONS',
        }
)
{
    const navigate = useNavigate();

    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profileSettings;
    const employee = useSelector((state: AppState) => state.employee);
    const width = useSelector((state: AppState) => state.windowSize.width);
    const offline = useSelector((state: AppState) => state.client.offline);
    const permissions = useSelector((state: AppState) => state.permissions);
    const readRoles = checkPermissions('Roles', 'Retrieve', permissions);
    const scrollTabs = width < 1000;
    const wrappedTab = width < 1000;


    const mainTabs = [
        {
            icon: <Info />,
            label: langStrings.general,
            url: `/${ELinks.PROFILE_EDIT}`,
            key: EProfileSettingsTabs.GENERAL,
            render: <GeneralTab />
        },
        {
            icon: <Insights />,
            label: langStrings.analyse,
            url: `/${ELinks.PROFILE_EDIT_ANALYSE}`,
            key: EProfileSettingsTabs.ANALYSE,
            render: <AnalyseCvTab />
        },
        {
            icon: <ForecastTabIcon />,
            label: langStrings.forecast,
            url: `/${ELinks.PROFILE_EDIT_FORECAST}`,
            key: EProfileSettingsTabs.FORECAST,
            render: <ForecastTab />
        },
        ...(
            readRoles ?
                [
                    {
                        icon: entityTypeToIcon(EEntityType.ROLE),
                        label: langStrings.oftenUsed.roles,
                        url: `/${ELinks.PROFILE_EDIT_ROLES}`,
                        key: EProfileSettingsTabs.ROLES,
                        render: <RolesTab />
                    }] :
                []
        ),
        {
            icon: <SkillTabIcon />,
            label: langStrings.oftenUsed.skills,
            url: `/${ELinks.PROFILE_EDIT_SKILLS}`,
            key: EProfileSettingsTabs.SKILLS,
            render: <SkillsTab showSuggestionsPage={urlSuffix === 'SUGGESTIONS'} />
        },
        {
            icon: entityTypeToIcon(EEntityType.CERTIFICATE),
            label: langStrings.oftenUsed.certificates,
            url: `/${ELinks.PROFILE_EDIT_CERTIFICATES}`,
            key: EProfileSettingsTabs.CERTIFICATES,
            render: <CertificatesTab />
        },
        {
            icon: <DeploymentTabIcon />,
            label: langStrings.development,
            url: `/${ELinks.PROFILE_EDIT_DEVELOPMENT}`,
            key: EProfileSettingsTabs.DEVELOPMENT,
            render: <DevelopmentTab />
        },
    ]

    const activeMainTab = mainTabs.find(mt => mt.key === tabId)
    const activeMainTabId = activeMainTab ? activeMainTab.key : mainTabs[0].key;

    return (
        <InnerLayout title={employee && generateFullName(employee.firstName, employee.lastName)}
            bigRightBox={employee ?
                <EmployeePicture avatarSize={100} employee={employee} allowUpload /> :
                undefined
            }
            informations={
                LearnProgress()
            }
            buttons={
                <Button
                    size={'normal'}
                    text={langStrings.logout}
                    icon={<Logout />}
                    onClick={async function (): Promise<void>
                    {
                        logout();
                    }} disabled={offline} />
            } >
            <Tabs className='tabControl' value={activeMainTabId}
                // onChange={() => { }}
                aria-label=""
                variant={scrollTabs ? 'scrollable' : 'fullWidth'}
                scrollButtons="auto"
                centered={scrollTabs ? false : true}
                textColor='primary'
                indicatorColor='primary'
                style={{ borderBottom: '1px solid #ccc' }}
            >
                {mainTabs.map((tab) => <Tab
                    value={tab.key}
                    key={tab.key}
                    wrapped={wrappedTab ? true : undefined}
                    onClick={async () => navigate(tab.url)}
                    icon={tab.icon}
                    label={tab.label} />)}
            </Tabs>
            <div className="userProfile">
                {activeMainTab ? activeMainTab.render : <LoadingBox />}
            </div>
        </InnerLayout>
    );
}

export default ProfileSettings;




export enum EProfileSettingsTabs
{
    GENERAL = 0,
    ANALYSE = 1,
    FORECAST = 2,
    SKILLS = 3,
    CERTIFICATES = 4,
    DEVELOPMENT = 5,
    ROLES = 6,
}

/**
 * Represents a general tab component.
 * 
 * @returns {JSX.Element} The JSX element representing the general tab.
 */
function GeneralTab(): JSX.Element
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profileSettings;
    return <>
        <CardBox title={langStrings.generalInfo}>
            <GeneralInfo />
        </CardBox>
    </>
}

/**
 * Function to analyze the CV tab.
 * @returns {JSX.Element} - The analyzed CV tab.
 */
function AnalyseCvTab(): JSX.Element
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profileSettings;

    return <>
        <CardBox title={langStrings.analyseTitle}>
            <p>
                {langStrings.analyseDescription}
            </p>
            <div>
                <AnalyseSkillsPdfButton />
                <AnalyseSkillsTextButton />
            </div>
        </CardBox>
    </>
}

/**
 * Renders the RolesTab component.
 * 
 * @returns {JSX.Element} The rendered RolesTab component.
 */
function RolesTab(): JSX.Element
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profileSettings;
    const smallRoles = useSelector((state: AppState) => state.company.allRoles);
    const employee = useSelector((state: AppState) => state.employee);

    return <>
        {employee ?
            <>
                <EditRoleList
                    title={langStrings.yourRoles + ":"}
                    mode='edit'
                    allRoles={smallRoles}
                    roles={employee.roles}
                />
            </>
            : <LoadingBox />}
    </>
}

/**
 * Represents the SkillsTab component.
 * 
 * @return {JSX.Element} The rendered SkillsTab.
 */
function SkillsTab({ showSuggestionsPage }: { showSuggestionsPage?: boolean }): JSX.Element
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profileSettings;
    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const employee = useSelector((state: AppState) => state.employee);

    if (!employee)
    {
        return <LoadingBox />
    }

    if (showSuggestionsPage)
    {
        return <SuggestedSkills />
    }
    return <>
        <SuggestedSkillsPreview />
        <EditSkillList
            title={langStrings.yourSkills + ":"}
            mode='edit'
            allSkills={allSkills}
            skills={employee.skills}
        />
    </>
}

/**
 * Renders the CertificatesTab component.
 * 
 * @returns {JSX.Element} The rendered CertificatesTab component.
 */
function CertificatesTab(): JSX.Element
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profileSettings;
    const allCertificates = useSelector((state: AppState) => state.company.allCertificates);
    const employee = useSelector((state: AppState) => state.employee);

    return <>
        {employee ? <EditCertificateList
            mode='edit'
            title={langStrings.yourCertificates + ":"}
            allCertificates={allCertificates}
            certificates={employee.certificates}
        />
            : <LoadingBox />}
    </>
}

/**
 * Renders the ForecastTab component.
 * 
 * @returns {JSX.Element} The rendered ForecastTab component.
 */
function ForecastTab(): JSX.Element
{
    return <>
        <AvailabilitySettings />
    </>
}


/**
 * Function to render the LearnProgress component.
 * 
 * @returns {JSX.Element} - The rendered LearnProgress component.
 */
function LearnProgress()
{
    try
    {
        const lang = useSelector((state: AppState) => state.employee.language);
        const langStrings = getLanguageTexts(lang).profileSettings;

        const wantedCompetences = useSelector((state: AppState) => state.employeeSettings.competenceSettings.wantedCompetences)
            ?.filter(wc => wc?.state !== 'done') || [];
        const goals = useSelector((state: AppState) => state.employeeSettings.goals)
            .filter(wc => wc.state !== 'done')
        const empSkills = useSelector((state: AppState) => state.employee.skills) || [];
        const skills = mergeWantedSkills(wantedCompetences, goals) || [];
        const certificates = useSelector((state: AppState) => state.employee.certificates) || [];

        if (!skills.length)// || !empSkills.length)
        {
            // Rückgabe eines Fallback-Werts oder einer Fehlermeldung
            return undefined
        }

        const coverageOld = calculateEmployeeCoverage(
            skills.map(s => ({ title: s?.title, level: s?.wantedLevel, id: s?.id })),
            skills.map(s => ({ title: s?.title, level: s?.level, id: s?.id as number })),
            certificates.map(c => ({ title: c?.title, id: c?.id })),
            certificates.map(c => ({ title: c?.title, id: c?.id as number })),
            [],
            [],

        );

        const score = calculateLearningProgress(empSkills, coverageOld,
            skills.map(s => ({ title: s?.title, level: s?.wantedLevel, id: s?.id as number }))
        );

        return (
            <EntityPageInformation title={langStrings.learnProgress} >
                <LinearProgressWithLabel
                    color={score < 0.95 ? "primary" : "success"}
                    variant="determinate"
                    value={score * 100}
                    sx={{
                        width: "100%",
                        height: 7,
                        borderRadius: 7
                    }} />
            </EntityPageInformation>
        );
    } catch (error)
    {
        // Logging und Fehlerbehandlung
        console.error("Ein Fehler ist aufgetreten:", error);
        return undefined
    }
}


/**
 * Represents the DeploymentTabIcon component.
 * 
 * @returns {JSX.Element} The JSX element representing the DeploymentTabIcon.
 */
function DeploymentTabIcon()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profileSettings;

    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const wantedCompetences = useSelector((state: AppState) => state.employeeSettings.competenceSettings.wantedCompetences)
        ?.filter(wc => wc?.state !== 'done') || [];
    const empSkills = useSelector((state: AppState) => state.employee.skills);
    let completedCount = 0;

    for (const wc of wantedCompetences)
    {
        const allCompetence = allCompetences.find(a => a.id === wc.id);
        const competenceLevelSkills = allCompetence && allCompetence.levels[wc.level - 1] ?
            allCompetence.levels[wc.level - 1].skills :
            [];
        // Überprüfen, ob der Mitarbeiter alle diese Skills auf dem erforderlichen Level hat
        const hasAllSkills = competenceLevelSkills.every(rs =>
        {
            const empSkill = empSkills.find(es => es.id === rs.id);
            const result = empSkill && empSkill.level >= rs.level;
            return result;
        });

        if (hasAllSkills)
        {
            completedCount++;
        }
    }

    if (!completedCount) return <Assignment />
    return (
        <div
            title={langStrings.completedGolasCount}
        >
            <Badge
                badgeContent={completedCount}
                color='success'
            >
                <Assignment />
            </Badge>
        </div>
    )
}

/**
 * SkillTabIcon component.
 * 
 * @returns {JSX.Element} The rendered SkillTabIcon.
 */
function SkillTabIcon()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profileSettings;
    const unknwonSkillCount = useSelector((state: AppState) => state.employee.unknownSkillCount);

    if (!unknwonSkillCount) return <Psychology />
    return (
        <div
            title={langStrings.unknownSkillCount}
        >
            <Badge
                badgeContent={unknwonSkillCount}
                color='primary'
            >
                {entityTypeToIcon(EEntityType.SKILL)}
            </Badge>
        </div>
    )
}

/**
 * Represents the ForecastTabIcon component.
 * 
 * @returns {JSX.Element} The rendered ForecastTabIcon component.
 */
function ForecastTabIcon()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profileSettings;
    const lastForecastDate = useSelector((state: AppState) => state.employeeSettings.lastForecastDate);
    if (lastForecastDate)
    {
        const diffInMilliseconds = (new Date()).getTime() - new Date(lastForecastDate).getTime();
        const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
        if (diffInDays < 32)
        {
            return <BarChart />;
        }
    }

    return (
        <div
            style={{ overflow: 'visible' }}
            title={langStrings.lastForecastDate}
        >
            <Badge
                badgeContent={'!'}
                color='warning'
            >
                <BarChart />
            </Badge>
        </div>
    )
}