import { Action } from "redux";
import { SkillCategory } from "@src/Objects/SkillCategory";

const initialState: SkillCategory[] = [];

export enum ESkillCategorieAction
{
    SET_SKILLCATEGORIES = "SET_SKILLCATEGORIES",
    DELETE_SKILLCATEGORY = "DELETE_SKILLCATEGORY",
    UPDATE_SKILLCATEGORY = "UPDATE_SKILLCATEGORY",
}

interface SetSkillCategoriesAction extends Action
{
    type: ESkillCategorieAction.SET_SKILLCATEGORIES;
    payload: SkillCategory[];
}

interface DeleteSkillCategoryAction extends Action
{
    type: ESkillCategorieAction.DELETE_SKILLCATEGORY;
    payload: number; // Annahme, dass ID eine Zahl ist
}

interface UpdateSkillCategoryAction extends Action
{
    type: ESkillCategorieAction.UPDATE_SKILLCATEGORY;
    payload: SkillCategory;
}

type SkillCategorieAction = SetSkillCategoriesAction | DeleteSkillCategoryAction | UpdateSkillCategoryAction;

export const skillcategorieReducer = (state = initialState, action: SkillCategorieAction): SkillCategory[] =>
{
    switch (action.type)
    {
        case ESkillCategorieAction.SET_SKILLCATEGORIES:
            return action.payload;
        case ESkillCategorieAction.DELETE_SKILLCATEGORY:
            return state.filter(category => category.id !== action.payload);
        case ESkillCategorieAction.UPDATE_SKILLCATEGORY:
            // eslint-disable-next-line no-case-declarations
            const index = state.findIndex(category => category.id === action.payload.id);
            if (index !== -1)
            {
                const newState = [...state];
                newState[index] = action.payload;
                return newState;
            } else
            {
                return [...state, action.payload];
            }
        default:
            return state;
    }
};

// Action Creator
export const setSkillCategories = (skillcategories: SkillCategory[]): SetSkillCategoriesAction => ({
    type: ESkillCategorieAction.SET_SKILLCATEGORIES,
    payload: skillcategories,
});

export const deleteSkillCategory = (id: number): DeleteSkillCategoryAction => ({
    type: ESkillCategorieAction.DELETE_SKILLCATEGORY,
    payload: id,
});

export const updateSkillCategory = (category: SkillCategory): UpdateSkillCategoryAction => ({
    type: ESkillCategorieAction.UPDATE_SKILLCATEGORY,
    payload: category,
});
