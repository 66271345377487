import IEntity from "./IEntity"

export default interface IAvailabilityMonthDTO extends IEntity
{
    id: string
    title: string
    firstDay: Date
    workDayCount: number
    plannedDayCount: number
    absenceDayCount: number
    holidayCount: number
    selectionType: EAvailabilitySelectionType
    inputAs: EAvailabilityInputAs;
    weekArray: IAvailabilityWeekDTO[];
}
export interface IAvailabilityMonthSmallDTO
{
    id: string
    title: string
    firstDay: Date
    workDayCount: number
    plannedDayCount: number
    absenceDayCount: number
    holidayCount: number
    selectionType: EAvailabilitySelectionType
    inputAs: EAvailabilityInputAs;

}
export enum EAvailabilitySelectionType
{
    month = 'month',
    week = 'week',
    day = 'day',
}
export enum EAvailabilityInputAs
{
    hours = 'hours',
    days = 'days',
}

export interface IAvailabilityWeekDTO
{
    weekNumber: number,
    firstDay: Date,
    lastDay: Date,
    days: Array<IDayDTO>
}
export interface IDayDTO
{
    planings: IPlanningDTO[]
    date: Date,
    isWorkday: boolean
    holiday: number
    name?: string
    absence: number
}

export interface IPlanningDTO
{
    activityId: string,
    value: number
}


export interface IAvailabilityExtensionMonthDTO
{
    id: string
    title: string
    inputAs: EAvailabilityInputAs
    weekArray: IAvailabilityWeekDTO[];
    workDayCount: number
}