import { Done } from "@mui/icons-material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import './CompetencesInput.css'
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import { entityTypeToLink, EEntityType } from "@sharedInterfaces/globalEnums";
import { AppState } from "@store/store";
import CompetenceItem from "@src/App/NewLayout/Items/CompetenceItem/CompetenceItem";
import Item from "@src/App/NewLayout/Items/Item/Item";
import { Typography } from "@mui/material";

import CompetenceSearchBox from "../../searchBoxes/CompetenceSearchBox/CompetenceSearchBox";
import CompetenceRateBox from "../CompetenceRateBox/CompetenceRateBox";

import NewCompetenceRow from "./NewCompetenceRow";
interface CompetencesInputProps
{
    competences: { title: string, id: number, level: number }[]
    showMode?: boolean
    size: 'medium' | 'small'
    disabled?: boolean
    helperText?: string
    onChange(val: { title: string, id: number, level: number }[]): void
}

interface INewCompetence
{
    id: number
    title: string,
    level: number
}

/**
 * Component for rendering input for competences.
 *
 * @param {CompetencesInputProps} props - The props object containing data for the competences input.
 * @returns {JSX.Element} The rendered JSX element for the competences input.
 */
function CompetencesInput({ competences, showMode, size, disabled, helperText, onChange }: CompetencesInputProps)
{
    const navigate = useNavigate();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;

    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);

    const [newCompetence, setNewCompetence] = React.useState<INewCompetence>({ id: -1, title: '', level: 0 });
    const [editCompetence, setEditCompetence] = React.useState<{ originalCompetence: INewCompetence | null, editCompetence: INewCompetence }>(
        { originalCompetence: null, editCompetence: { id: -1, title: '', level: 0 } }
    );

    const sortedCompetences = [...competences].sort((a, b) =>
    {
        if (a.level !== b.level) return b.level - a.level;
        return a.title.localeCompare(b.title);
    });
    const filteredAllCompetences = allCompetences.filter(a =>
        competences.find(s => s.id === a.id || s.title === a.title) === undefined)

    const onClickCompetence = useCallback((competenceTitle: string) =>
    {
        const competence = competences.find(s => s.title === competenceTitle);
        if (!competence) return;
        setEditCompetence({ originalCompetence: competence, editCompetence: competence });
    }, [competences]);
    const handleAddClick = React.useCallback(() =>
    {
        if (newCompetence.id === undefined || newCompetence.title === '' || newCompetence.level === 0)
        {
            return;
        }
        const newCompetences: INewCompetence[] = competences.slice();
        newCompetences.push(newCompetence);
        onChange(newCompetences.map(s => ({
            id: s.id,
            title: s.title,
            level: s.level,
        })));
        setNewCompetence({ title: '', level: 0 } as INewCompetence);
        // const sug = document.querySelector('.entitySearchBoxInput input') as HTMLElement;
        // if (sug) sug.focus();
    }, [newCompetence, competences, onChange]);

    const onDelete = (title: string) =>
    {
        let newCompetences = competences.slice();
        //Change
        newCompetences = newCompetences.filter(nS => nS.title !== title);
        onChange(newCompetences.map(s => ({
            id: s.id,
            title: s.title,
            level: s.level,
        })));
        setEditCompetence({ originalCompetence: null, editCompetence: { id: -1, title: '', level: 0 } });
    }

    const helperTextElement = helperText && (
        <Typography variant="caption" color="textSecondary" style={{ marginTop: 10 }}>
            {helperText}
        </Typography>
    );


    const DOM = sortedCompetences.map((competence, index) =>
    {
        const allCompetence = allCompetences.find(com => com.id === competence.id)
        if (!allCompetence) return <></>
        const maxLevel = allCompetence.levels.length - 1;

        const onClick = () =>
        {
            if (!showMode && competence.title !== editCompetence.originalCompetence?.title)
            {
                return onClickCompetence(competence.title)
            } else if (showMode && competence.id !== undefined)
            {
                navigate(entityTypeToLink(competence.id, EEntityType.COMPETENCE));
            }
        }
        if (editCompetence.originalCompetence?.title !== competence.title)
        {
            return <CompetenceItem
                id={competence.id}
                key={index}
                newPrefix={!competence.id ? true : undefined}
                title={competence.title}
                level={competence.level}
                // locked={competence.locked}
                onClick={(!showMode && !disabled) ? onClick : undefined}
                onDelete={showMode ? undefined : onDelete.bind(null, competence.title)}
            />
        }

        return <div className="newCompetenceRow"
            key={`${competence.title}-${competence.id}`}
            style={{
                "--percent": editCompetence.editCompetence.level / maxLevel * 100,
                order: -1 * competence.level,
                width: '100%',
                flexBasis: 'fit-content',

            } as React.CSSProperties}
        >
            <Item
                rightContent={<IconButton
                    size="small"
                    disabled={
                        editCompetence.editCompetence.title == '' ||
                        editCompetence.editCompetence.level == 0 ||
                        editCompetence.editCompetence.level == undefined
                    }
                    title={langStrings.save}
                    onClick={() =>
                    {
                        if (editCompetence.editCompetence.title !== '' &&
                            editCompetence.editCompetence.level !== 0 &&
                            editCompetence.editCompetence.level !== undefined)
                        {
                            const newCompetences = competences.slice();

                            //Change
                            const original = newCompetences.find(nS => nS.title === editCompetence.originalCompetence?.title);
                            if (!original) return;
                            original.id = editCompetence.editCompetence.id;
                            original.title = editCompetence.editCompetence.title;
                            original.level = editCompetence.editCompetence.level;
                            onChange(newCompetences.map(s => ({
                                id: s.id,
                                title: s.title,
                                level: s.level,
                            })));
                            setEditCompetence({ originalCompetence: null, editCompetence: { id: -1, title: '', level: 0 } });
                            // if (newCompetenceError) setNewCompetenceError(false);
                            // const sug = document.querySelector('.entitySearchBoxInput input') as HTMLElement
                            // if (sug) sug.focus();
                        }
                        else
                        {
                            // setNewCompetenceError(true);
                        }

                    }}
                >
                    <Done fontSize="small" />
                </IconButton>}
            >
                <CompetenceSearchBox
                    filteredAllCompetences={filteredAllCompetences}
                    newCompetence={editCompetence.editCompetence}
                    size="small"
                    setNewCompetence={(newCompetence) =>
                    {
                        const newEditCompetence = {
                            originalCompetence: editCompetence.originalCompetence,
                            editCompetence: {
                                ...editCompetence.editCompetence,
                                title: newCompetence.title,
                                id: parseInt(newCompetence.id.toString()),
                            }
                        };
                        setEditCompetence(newEditCompetence)
                    }}
                />
                {editCompetence.editCompetence.title !== "" &&
                    <CompetenceRateBox
                        levels={allCompetence.levels}
                        level={editCompetence.editCompetence.level}
                        name={editCompetence.editCompetence.title}
                        setValue={(newLevel) =>
                        {
                            setEditCompetence({
                                originalCompetence: editCompetence.originalCompetence,
                                editCompetence: {
                                    ...editCompetence.editCompetence,
                                    level: newLevel
                                }
                            });
                        }
                            // setChanged(true);
                        } />
                }
            </Item>
        </div>
    })

    return (
        <>
            <div
                key={'competenceInput' + Math.random()}
                className={'competencesInput ' + (size)}
                style={{
                    border: showMode ? 'none' : '',
                    opacity: disabled ? 0.5 : 1,
                    pointerEvents: disabled ? 'none' : 'auto',
                }}
            >
                <div className="competences">
                    {DOM}
                </div>
                {
                    (!showMode && !disabled) &&
                    <NewCompetenceRow
                        filteredAllCompetences={filteredAllCompetences}
                        newCompetence={newCompetence}
                        onNewCompetenceChange={setNewCompetence}
                        onAddClick={handleAddClick}
                    />
                }
            </div>
            {helperTextElement}
        </>
    );
}
export default CompetencesInput;