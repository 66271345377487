import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Cancel, Delete } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import { useDialog } from '@sharedReact/Dialog/DialogManager';


interface confirmDeleteModalProps
{
    id: string;
    elementName: string;
    message: string;
    command?: string;
    title?: string
    resolve: (value: boolean) => void;
}
const ConfirmDeleteModal: React.FC<confirmDeleteModalProps> = ({ id, elementName, message, command, title, resolve, }) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).layout;

    title = (title || langStrings.oftenUsed.deleteTitle).replace("[TITLE]", elementName);
    const deleteHint = message ?
        message :
        langStrings.oftenUsed.deleteHint.replace("[TITLE]", elementName);

    const [isOpen, setIsOpen] = useState(true);

    const onconfirmDelete = () =>
    {
        resolve(true);
        setIsOpen(false);
    };

    const onCancel = () =>
    {
        resolve(false);
        setIsOpen(false);
    };

    useEffect(() =>
    {
        if (!isOpen)
        {
            onCancel();
        }
    }, [isOpen, onCancel]);

    if (!isOpen) return null;

    return (
        <Dialog
            id={id}
            footer={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    text={langStrings.oftenUsed.cancel}
                    icon={<Cancel />}
                    onClick={onCancel} />
                <Button
                    icon={<Delete />}
                    text={command ? command : langStrings.oftenUsed.delete}
                    color='red'
                    onClick={onconfirmDelete}
                />
            </div>}
            title={title}
            onClose={function (): void
            {
                onCancel();
            }}>
            <p style={{ whiteSpace: 'pre-wrap' }}>{deleteHint}</p>
        </Dialog>
    );
};

export function useConfirmDeleteDialog()
{
    const { openDialog } = useDialog();
    const confirmDelete = (elementName: string, message = "", command?: string, title?: string) =>
    {
        return new Promise<boolean>((resolve) =>
        {
            const dialog = (
                <ConfirmDeleteModal
                    id="ConfirmDeleteModal"
                    elementName={elementName}
                    message={message}
                    command={command}
                    title={title}
                    resolve={(result) => resolve(result)}
                />
            );
            openDialog(dialog);
        });
    };
    return confirmDelete;
}