import { OperationVariables, gql } from "@apollo/client";
import { IEmployeeSettingsMailSettings, IEmployeeSettingsMailSettingsSubscription } from "@sharedInterfaces/IEmployeeSettings";
import store from "@store/store";
import { setEmailSettings } from "@store/reducer/employeeSettingsReducer";

import GraphQL from "../graphQL";

export const emailSubscriptions = `{
    newsletter
    updates
    skillSuggestion
    newClient
    forecast
}`;

const query = gql`
mutation setEmployeeEmailSettings($subscribeAll: Boolean!, $subscribeAllNotifications: Boolean!, $subscriptions: [SubscriptionInput!]!) {
  setEmployeeEmailSettings(subscribeAll: $subscribeAll,  subscribeAllNotifications: $subscribeAllNotifications, subscriptions: $subscriptions)
  {
        subscribeAll
        subscribeAllNotifications
        subscriptions
        ${emailSubscriptions}
        notificationSubscriptions
        ${emailSubscriptions}
}
}
`;

interface SubscriptionParameter
{
    id: keyof IEmployeeSettingsMailSettingsSubscription;
    subscribed: boolean;
    notificationSubscribed: boolean;
}

export default async function setEmployeeEmailSettings(allEmails: boolean, allNotifications: boolean, subscriptions: SubscriptionParameter[])
{
    const variables: OperationVariables = {
        subscribeAll: allEmails,
        subscribeAllNotifications: allNotifications,
        subscriptions,
    };
    const result = await GraphQL.mutate<IEmployeeSettingsMailSettings>(query, variables);
    store.dispatch(setEmailSettings(result));
    return result;
}