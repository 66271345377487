import { Add, Delete } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ISmallRole } from '@sharedInterfaces/IWhoIAm';
import { AppState } from '@store/store';
import changeMapRole from '@src/APIs/graphQl/Role/changeMapRole';
import createAndMapRole from '@src/APIs/graphQl/Role/createAndMapRole';
import { NewBadge } from '@src/App/NewLayout/Components/ItemBadge/ItemBadge';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import Button from '../../sharedReact/General/Button/Button';
import CardBox from '../CardBox/CardBox';
import ErrorBox from '../ErrorBox/ErrorBox';
import './EditRoleList.css';
import RoleSearchBox from '../formsControls/searchBoxes/RoleSearchBox/RoleSearchBox';


interface EditRoleListProps 
{
    title: string
    mode: 'edit' | 'add'
    roles: { title: string, id?: number }[] | undefined
    allRoles: ISmallRole[]
    onChange?: (changed: true) => void
}

/**
 * EditRoleList function component.
 * 
 * @param {EditRoleListProps} props - The EditRoleListProps object.
 * @return {JSX.Element} - The JSX element representing the edit role list.
 */
function EditRoleList(props: EditRoleListProps)
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).roles;
    const offline = useSelector((state: AppState) => state.client.offline);

    const employee = useSelector((state: AppState) => state.employee);

    const [addedRoles, setAddedRoles] = React.useState([] as string[]);
    const [deletingIds, setDeletingIds] = React.useState([] as number[]);
    const [addingRoles, setAddingRoles] = React.useState([] as string[]);

    React.useEffect(() =>
    {
        if (props.roles && props.mode === 'add')
        {
            let tempRoles = props.roles.slice();
            tempRoles = tempRoles.filter(c => !addedRoles.find(a => c.title === a))
            tempRoles.sort((a, b) => a.title.localeCompare(b.title));
            setRoles(tempRoles);
        } else
        {
            const tempRoles = employee ? [...employee.roles] : [];
            tempRoles.sort((a, b) => a.title.localeCompare(b.title));
            setRoles(tempRoles);
        }
    }, [addedRoles, employee, props.roles, props.mode]);

    const [roles, setRoles] = React.useState([] as { title: string, id?: number }[]);
    const [newRole, setNewRole]: [INewRole, (value: INewRole) => void] = React.useState({ title: '' } as INewRole);
    const [newRoleError, setNewRoleError]: [boolean, (value: boolean) => void] = React.useState(false as boolean);

    const filteredAllRoles = props.allRoles.filter(a =>
        roles.find(s => s.id === a.id || s.title === a.title) === undefined)

    if (!employee) return null

    return (
        <CardBox title={props.title}>
            <div className="editRoleList">
                <table className="infoBoxRole" cellSpacing={0} cellPadding={0}>
                    <tbody>
                        {roles.map(role =>
                            <tr className="infoLine" key={role.id ? role.id : '' + role.title}>
                                <td className="info">{role.title}{!role.id &&
                                    <NewBadge />
                                }</td>
                                {props.mode === 'edit' &&
                                    <td className="delete">
                                        <Button
                                            size="verysmall"
                                            onClick={unMap(role)}
                                            icon={<Delete
                                                fontSize="small"
                                            />}
                                            disabled={deletingIds.includes(role.id as number) || offline}
                                            text="Delete"
                                        />
                                    </td>
                                }
                                {props.mode === 'add' &&
                                    <td className="add">
                                        <Button
                                            size="verysmall"
                                            onClick={() =>
                                            {
                                                add(role.title, role.id);
                                            }}
                                            icon={<Add
                                                fontSize="small"
                                            />}
                                            disabled={addingRoles.includes(role.title) || offline}
                                            text="Add"
                                        />
                                    </td>
                                }
                            </tr>
                        )}
                        <tr>
                            <td className="newRow">
                                <RoleSearchBox
                                    allRoles={filteredAllRoles}
                                    onSelectedRole={(element) =>
                                    {
                                        add(element.title, element.id)
                                    }}
                                    onTextChanged={(newText) =>
                                    {
                                        setNewRole({ title: newText })
                                    }}
                                />
                            </td>
                            <td className="newRow" width={32}>
                                <Button
                                    size="verysmall"
                                    onClick={() =>
                                    {
                                        add(newRole.title, newRole.id);
                                    }}
                                    icon={<Add />}
                                    disabled={addingRoles.includes(newRole.title) || offline}
                                    text="Add"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {newRoleError && <ErrorBox close={() => setNewRoleError(false)}>{langStrings.pleaseEnterAName}</ErrorBox>}
            </div>
        </CardBox>
    );
    function add(title: string, id?: number)
    {
        if (title !== '')
        {
            setNewRole({ title: '' } as INewRole);
            if (newRoleError) setNewRoleError(false);
            const sug = document.getElementById('searchRoleBoxInput') as HTMLElement
            if (sug) sug.focus();

            if (props.mode === 'add')
            {
                setAddingRoles(addingRoles.concat([title]))
            }
            if (id)
            {
                changeMapRole(id, true)
                    .then(() =>
                    {
                        setAddedRoles(addedRoles.concat([title]))
                        props.onChange && props.onChange(true);
                    })
                    .catch((ex) =>
                    {
                        showErrorDialog(ex, langStrings.errorAdd)
                        setAddingRoles(addingRoles.filter(t => t != title))
                    })
            }
            else
            {
                createAndMapRole(title)
                    .then(() =>
                    {
                        setAddedRoles(addedRoles.concat([title]))
                        props.onChange && props.onChange(true);
                    })
                    .catch((ex) =>
                    {
                        console.log(ex);
                        showErrorDialog(ex, langStrings.errorAdd)
                        setAddingRoles(addingRoles.filter(t => t != title))
                    })
            }
        }
        else
        {
            setNewRoleError(true);
        }
    }
    function unMap(role: { title: string; id?: number | undefined; }): () => void
    {
        return () =>
        {
            if (!role.id) return;
            const newDeletingIds = deletingIds.slice();
            newDeletingIds.push(role.id);
            setDeletingIds(newDeletingIds);
            changeMapRole(role.id, false)
                .then(
                    () =>
                    {
                        const updatedDeletingIds = deletingIds.filter(d => d !== role.id);
                        setDeletingIds(updatedDeletingIds);
                        props.onChange && props.onChange(true);
                    }).catch((ex) =>
                    {
                        const updatedDeletingIds = deletingIds.filter(d => d !== role.id);
                        setDeletingIds(updatedDeletingIds);
                        showErrorDialog(ex, langStrings.errorRemove);
                    });
        };
    }
}

export interface INewRole
{
    id?: number
    title: string
}

export default EditRoleList;