import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { EEntityType, ELinks, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import { updateSkillCategory } from '@store/reducer/skillCategoriesReducer';
import { SkillCategory } from '@src/Objects/SkillCategory';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import SkillCategoryDialog from '@src/Components/Dialogs/SkillCategoryDialog/SkillCategoryDialog';
import getSkillCategory from '@src/APIs/graphQl/SkillCategory/getSkillCategory';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import SkillsCard from '@src/App/NewLayout/Cards/Skills/SkillsCard/SkillsCard';
import EmployeesCard from '@src/App/NewLayout/Cards/Employees/EmployeesCard/EmployeesCard';
import SkillCategoriesCard from '@src/App/NewLayout/Cards/SkillCategories/SkillCategoriesCard/SkillCategoriesCard';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

import EntityPage from '../../../../../sharedReact/Pages/EntityPage/EntityPage';

interface SkillCategoryPageProps
{
    entity?: SkillCategory;
    inDialog?: boolean;
}

/**
 * SkillCategoryPage component.
 *
 * @param props - The props for the SkillCategoryPage component.
 * @returns The JSX.Element representing the SkillCategoryPage component.
 */
function SkillCategoryPage(props: SkillCategoryPageProps)
{
    const dispatch = useDispatch();
    const { openDialog } = useDialog();
    const { id } = useParams();
    const entityId = props.entity ? props.entity.id : id ? Number.parseInt(id) : -1;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skillCategories;
    const entityState = useSelector((state: AppState) => state.skillCategories.find(a => a.id === entityId))

    const skillCategories = useSelector((state: AppState) => state.company.skillCategories);
    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const permissions = useSelector((state: AppState) => state.permissions);
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);


    const setEntityState = (ent: SkillCategory) => ent && dispatch(updateSkillCategory(ent))

    useEffect(() =>
    {
        if (props.inDialog || entityId === -1) return;
        getSkillCategory(entityId).catch((ex) =>
        {
            setLoadingError(ex);
        });
    }, [entityId, props.inDialog]);

    useEffect(() =>
    {
        if (props.entity) setEntityState(props.entity);
    }, [props.entity]);
    const navigate = useNavigate();

    const skills = allSkills.filter(s => s.categoryId === entityId);
    const handleOpenEditDialog = async () =>
    {
        if (!entityState) return;
        return openDialog(
            <SkillCategoryDialog
                id="SkillCategoryDialog"
                skillCategory={entityState}
            />);
    };
    const subCategories = skillCategories.filter(sc => sc.parentCategory === entityState?.id);
    return (
        <EntityPage
            inDialog={props.inDialog}
            admin={permissions.admin}
            permissions={permissions.entities.SkillCategories}
            loadingError={loadingError}

            entity={entityState}
            onDelete={() =>
            {
                if (!props.inDialog)
                    navigate(`/${ELinks.SKILLCATEGORIES}/`);
            }}
            openEditDialog={handleOpenEditDialog}
            informations={
                !entityState ? [] :
                    [
                        entityState.parentCategory ?
                            <EntityPageInformation title={langStrings.parentCategory} >
                                <SkillCategoryBreadCrumb parentCategory={entityState.parentCategory} />
                            </EntityPageInformation> : undefined,
                        entityState.description ?
                            <EntityPageInformation title={langStrings.description} size={'full'}>
                                <ExpandableText>
                                    <FormatedTextInput value={entityState.description} readonly={true} />
                                </ExpandableText>
                            </EntityPageInformation> :
                            undefined,
                    ]
            }
        >
            <>
                {subCategories.length > 0 &&
                    <SkillCategoriesCard
                        title={langStrings.subCategories}
                        skillCategories={subCategories}
                    />
                }
                <SkillsCard skills={skills} />
                {(entityState && entityState.employees !== null) &&
                    <EmployeesCard
                        employees={entityState.employees.map(e => ({
                            ...e,
                            count: e.skillCount,
                            level: e.averageSkillLevel

                        }))}
                    />
                }
            </>
        </EntityPage>
    );
}

export default SkillCategoryPage;

interface SkillCategoryBreadCrumbProps
{
    parentCategory: number | null;
}

/**
 * SkillCategoryBreadCrumb component.
 * 
 * @param {SkillCategoryBreadCrumbProps} parentCategory - The parent category to display in the breadcrumb.
 * @returns {JSX.Element} The SkillCategoryBreadCrumb component.
 */
function SkillCategoryBreadCrumb({ parentCategory }: SkillCategoryBreadCrumbProps)
{
    const skillCategories = useSelector((state: AppState) => state.company.skillCategories);
    const path = buildCategoryPath(skillCategories, parentCategory);

    if (path.length === 0) return null;
    // <SkillCategorySelect skillCategories={skillCategories} selectedCategory={entityState.parentCategory} readonly={true} />

    return (
        <Breadcrumbs aria-label="breadcrumb">
            {path.map((category) => (
                <Link key={category.id} to={entityTypeToLink(category.id, EEntityType.SKILL_CATEGORY)} style={{ textDecoration: 'none' }}>
                    <Typography variant="body2">{category.title}</Typography>
                </Link>
            ))}
        </Breadcrumbs>
    );
}


/**
 * Builds the category path for a specific category ID.
 * 
 * @param {SkillCategory[]} skillCategories - An array of skill categories.
 * @param {number} categoryId - The ID of the category to build the path for.
 * @returns {SkillCategory[]} - An array of skill categories representing the category path.
 */
function buildCategoryPath(skillCategories: SkillCategory[], categoryId: number | null): SkillCategory[]
{
    // Map zur schnelleren Zuordnung von id zu SkillCategory erstellen
    const categoryMap = new Map<number, SkillCategory>();
    skillCategories.forEach(category => categoryMap.set(category.id, category));

    const path: SkillCategory[] = [];
    let currentCategoryId = categoryId;

    while (currentCategoryId)
    {
        const currentCategory = categoryMap.get(currentCategoryId);
        if (!currentCategory) break; // Beendet die Schleife, wenn keine Kategorie gefunden wird

        path.unshift(currentCategory); // Fügt die aktuelle Kategorie am Anfang des Pfades hinzu
        currentCategoryId = currentCategory.parentCategory; // Setzt die Suche mit der Elternkategorie fort
    }

    return path;
}