import { IRestDocuTexts } from "@sharedInterfaces/Language/languageTexts/restDocu";
import { stageContext } from "@src/globals";

import { ApiEndpoint } from "../RestApiDocuPage";

export const endpointRequestUploadAttachment = (langStrings: IRestDocuTexts): ApiEndpoint =>
({
    title: langStrings.endpointRequestUploadAttachmentTitle,
    endpoint: `${stageContext.apiGatewayRestDomain}/v1/request/<requestId>/attachment/upload`,
    method: 'GET',
    description: langStrings.endpointRequestUploadAttachmentDescription,
    parameters: [
        {
            name: 'filename',
            required: true,
            type: 'GET-PARAMETER',
            description: langStrings.endpointRequestUploadAttachmentFilename,
        },
        {
            name: 'override',
            required: false,
            type: 'GET-PARAMETER',
            description: `${langStrings.endpointRequestUploadAttachmentOverride} (true/false)`,
        },
    ],
    response: {
        type: 'object',
        object: 's3SignedUrl',
        description: langStrings.s3SignedUrlResponse,
    }
});