import { IRestDocuTexts } from "@sharedInterfaces/Language/languageTexts/restDocu";
import { stageContext } from "@src/globals";

import { ApiEndpoint } from "../RestApiDocuPage";

export const endpointRequestAnalyse = (langStrings: IRestDocuTexts): ApiEndpoint =>
({
    title: langStrings.endpointAnalyseRequestTitle,
    endpoint: `${stageContext.apiGatewayRestDomain}/v1/request/<requestId>/analyse`,
    method: 'POST',
    description: langStrings.endpointAnalyseRequestDescription,
    parameters: [
        {
            name: 'options',
            required: true,
            type: 'object',
            object: 'Options',
            description: langStrings.endpointCreateRequestOptions,
        },
    ],
    response: {
        type: 'boolean',
        description: 'true',
    }
});