import { OperationVariables, gql } from "@apollo/client";
import { StatusOption } from "@sharedInterfaces/ICompany";

import GraphQL from "../graphQL";

const query = gql`
mutation updateOpportunityStatusOptions($statusOptions: [StatusOptionInput!]!) {
    updateOpportunityStatusOptions(statusOptions: $statusOptions)
    {
        id
        title
        isActive
    }
},
`;

export default async function updateOpportunityStatusOptions(statusOptions: StatusOption[])
{
    const variables: OperationVariables = { statusOptions };
    return GraphQL.mutate<StatusOption[]>(query, variables);
}