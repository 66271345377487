import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import { AppState } from '@store/store';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { TextField, Typography, } from '@mui/material';
import { EEntityType } from '@sharedInterfaces/globalEnums';
import { ISuggestionDuplicate } from '@sharedInterfaces/ISuggestion';
import SkillItem from '@src/App/NewLayout/Items/SkillItem/SkillItem';
import RowElement from '@sharedReact/General/Forms/RowElement/RowElement';

import ErrorBox from '../../ErrorBox/ErrorBox';

// import './DeclineSuggestionDialog.css';

interface DeclineSuggestionDialogProps
{
    id: string;
    type: EEntityType
    reason: string;
    duplicates: ISuggestionDuplicate[]
    resolve: (reason: string | null) => void;
}

/**
 * Component representing a dialog to decline a suggestion with a reason.
 *
 * @param {DeclineSuggestionDialogProps} props - The properties for the DeclineSuggestionDialog.
 * @returns {JSX.Element} - The JSX element for the DeclineSuggestionDialog.
 */
const DeclineSuggestionDialog: React.FC<DeclineSuggestionDialogProps> = (props) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;

    const [reason, setReason] = useState("");
    const [errorText, setErrorText] = useState<string>("");
    const [saving, setSaving] = useState<boolean>(false);

    const handleDecline = async () =>
    {
        if (reason.trim() === "")
        {
            setErrorText(langStrings.declineSuggestionErrorEmptyReason);
            return;
        }

        setSaving(true);
        try
        {
            props.resolve(reason);
        } catch (error)
        {
            setErrorText(langStrings.declineSuggestionErrorSaving);
            setSaving(false);
        }
    };

    return (
        <Dialog
            id={props.id}
            title={langStrings.declineSuggestionDialogTitle}
            onClose={() => props.resolve(null)}
            footer={
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Button
                        text={langStrings.oftenUsed.cancel}
                        size={'normal'}
                        onClick={() => props.resolve(null)}
                        disabled={saving}
                    />
                    <Button
                        text={langStrings.oftenUsed.decline}
                        size={'normal'}
                        onClick={handleDecline}
                        disabled={saving}
                    />
                </div>
            }
        >
            <div className="declineDialogContent" style={{ minWidth: '50vw' }}>
                <RowElement title={langStrings.declineSuggestionElementsDescriptions}>
                    <div>
                        {
                            props.duplicates.map(d =>
                            {
                                return (
                                    props.type === EEntityType.SKILL ?
                                        <SkillItem
                                            key={d.id}
                                            title={d.title}
                                            id={d.id}
                                        /> : ''
                                )
                            })
                        }
                    </div>
                </RowElement>

                <RowElement title={langStrings.declineSuggestionAIReasonLabel}>
                    <Typography variant="body1" marginBottom={2}>
                        {props.reason}
                    </Typography>
                </RowElement>
                <RowElement title={langStrings.declineSuggestionReasonLabel}>
                    <TextField
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        multiline
                        fullWidth
                        rows={4}
                        placeholder={langStrings.declineSuggestionReasonPlaceholder}
                    />
                    <Typography variant="body1" marginBottom={2} marginTop={2}>
                        {langStrings.declineSuggestionDescription}
                    </Typography>
                </RowElement>

                {errorText && <ErrorBox close={() => setErrorText("")}>{errorText}</ErrorBox>}
            </div>
        </Dialog>
    );
};

export default DeclineSuggestionDialog;
