import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import CardBox from '@src/Components/CardBox/CardBox';
import SkillCategoryOverlay from '@src/Components/Overlays/SkillCategoryOverlay/SkillCategoryOverlay';
import ItemBadge from '@src/App/NewLayout/Components/ItemBadge/ItemBadge';
import List, { generateTopXText } from '@src/App/NewLayout/Lists/List/List';
import Item from '@src/App/NewLayout/Items/Item/Item';
import { PermIdentityOutlined } from '@mui/icons-material';

interface SkillCategoriesCardProps
{
    title?: string;
    skillCategories: {
        id: number
        title: string,
        count?: number
    }[];
    inOverlay?: boolean
    limit?: number
}

/**
 * SkillCategoriesCard component.
 *
 * @param {SkillCategoriesCardProps} skillCategories - The skill categories.
 * @param {boolean} inOverlay - Indicates if the component is in overlay.
 * @param {string} title - The title of the component.
 *
 * @returns {JSX.Element} The rendered SkillCategoriesCard component.
 */
function SkillCategoriesCard({ limit, skillCategories, inOverlay, title }: SkillCategoriesCardProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skillCategories;
    const newTitle = generateTopXText(title ? title : langStrings.skillCategories, limit);
    const limitedElements = limit ? skillCategories
        .slice(0, limit > skillCategories.length ? skillCategories.length : limit) : skillCategories;
    const DOM = limitedElements.map((skillCategory) =>
        <Item
            key={skillCategory.id}
            onClick={entityTypeToLink(skillCategory.id, EEntityType.SKILL_CATEGORY)}
            leftContent={
                skillCategory.count && <ItemBadge
                    value={skillCategory.count}
                    title={`${langStrings.employeeCount}`}
                    icon={<PermIdentityOutlined />}
                />
            }
        >
            <SkillCategoryOverlay key={skillCategory.id} disabled={inOverlay} skillCategoryId={skillCategory.id}>
                {skillCategory.title}
            </SkillCategoryOverlay>
        </Item>
    );
    return (
        <CardBox title={newTitle}>
            <List>
                {DOM}
            </List>
        </CardBox>
    );
}

export default SkillCategoriesCard;