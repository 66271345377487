import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Done } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Company from '@src/Objects/Company';
import TextInput from '@src/Components/formsControls/inputs/TextInput/TextInput';
import { Link, Typography } from '@mui/material';
import setMicrosoftSettings from '@src/APIs/graphQl/Company/setMicrosoftSettings';
import uploadMicrosoftCertificate from '@src/APIs/graphQl/Company/uploadMicrosoftCertificate';
import { cloneDeep } from '@apollo/client/utilities';
import { ECertificateStatus, EMicrosoftConnectionState } from '@sharedInterfaces/ICompany';

import RowElement from '../../sharedReact/General/Forms/RowElement/RowElement';
import Button from '../../sharedReact/General/Button/Button';
import ErrorBox from '../../Components/ErrorBox/ErrorBox';

interface MicrosoftSettingsTabProps
{
    company: Company;
    setCompany: (company: Company) => void;
    reload: () => void;
}

// Regex patterns for validation
const GUID_REGEX = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
const CERTIFICATE_THUMBPRINT_REGEX = /^[A-Fa-f0-9]{40}$/;

export function MicrosoftSettingsTab({ company, setCompany }: MicrosoftSettingsTabProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [tenantId, setTenantId] = useState<string>('');
    const [clientId, setClientId] = useState<string>('');
    const [certificateThumbprint, setCertificateThumbprint] = useState<string>('');
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [saveError, setSaveError] = useState<string | null>(null);
    const [saving, setSaving] = useState<boolean>(false);
    const [changed, setChanged] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false); // For overall validation
    const [fileContent, setFileContent] = useState<string | null>(null); // For storing PEM file content
    const [isValidPem, setIsValidPem] = useState<boolean | null>(null);
    const [uploadingCertificate, setUploadingCertificate] = useState<boolean>(false);
    const [certficateError, setCertficateError] = useState<string | null>(null);

    useEffect(() =>
    {
        if (company.microsoftSettings?.tenantId) setTenantId(company.microsoftSettings.tenantId);
        if (company.microsoftSettings?.clientId) setClientId(company.microsoftSettings.clientId);
        if (company.microsoftSettings?.certificateThumbprint) setCertificateThumbprint(company.microsoftSettings.certificateThumbprint);
    }, [company]);

    // Validate inputs based on regex patterns
    useEffect(() =>
    {
        const isTenantIdValid = GUID_REGEX.test(tenantId);
        const isClientIdValid = GUID_REGEX.test(clientId);
        const isThumbprintValid = CERTIFICATE_THUMBPRINT_REGEX.test(certificateThumbprint);

        setIsValid(isTenantIdValid && isClientIdValid && isThumbprintValid);
    }, [tenantId, clientId, certificateThumbprint]);

    const uploadCertificate = (certificate: string) =>
    {
        setUploadingCertificate(true);
        uploadMicrosoftCertificate(certificate)
            .then(setCompany).then(() =>
            {
                setCertficateError(null);
            })
            .catch(err =>
            {
                console.log(err);
                const clone = cloneDeep(company)
                clone.microsoftSettings.certificateStatus = ECertificateStatus.Invalid;
                clone.microsoftSettings.connectionState = EMicrosoftConnectionState.Error;
                setCompany(clone)
                setCertficateError(err.message)
            })
            .finally(() =>
            {
                setUploadingCertificate(false);
                setFileContent(null);
                setUploadedFile(null);
            });
    };
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        if (event.target.files && event.target.files[0])
        {
            const file = event.target.files[0];
            setUploadedFile(file);

            // Create a FileReader to read the file's content
            const reader = new FileReader();
            reader.onload = (e) =>
            {
                const text = e.target?.result as string;
                setFileContent(text); // Set the content of the file
                const hasBegin = text.indexOf('-----BEGIN PRIVATE KEY-----');
                const hasEnd = text.indexOf('-----END PRIVATE KEY-----');
                if (hasBegin !== -1 && hasEnd !== -1 && hasBegin < hasEnd)
                {
                    setIsValidPem(true); // Valid PEM private key
                    uploadCertificate(text);
                } else
                {
                    setIsValidPem(false); // Invalid PEM private key
                }
            };

            reader.onerror = (e) =>
            {
                console.error("Error reading file:", e);
            };

            reader.readAsText(file); // Read the file as text
        }
    };

    const handleSave = () =>
    {
        setSaving(true);
        setMicrosoftSettings({ tenantId, clientId, certificateThumbprint })
            .then(setCompany).then(() => setChanged(false))
            .catch(err => setSaveError(err.toString()))
            .finally(() => setSaving(false));
    };

    return (
        <div className='microsoftSettingsTab'>
            {/* Anleitungstext */}
            <Typography variant="body1">{langStrings.microsoftRegistrationDescription}</Typography>

            <ol>
                <li>
                    <Typography variant="body1" component="span">
                        {langStrings.microsoftRegistrationStep1}
                    </Typography>
                    <Link href={langStrings.microsoftRegistrationLink} target="_blank" rel="noopener noreferrer">
                        {langStrings.microsoftRegistrationLinkText}
                    </Link>
                </li>
                <li>
                    <Typography variant="body1">{langStrings.microsoftRegistrationStep2}</Typography>
                </li>
                <li>
                    <Typography variant="body1">{langStrings.microsoftRegistrationStep3}</Typography>
                </li>
                <li>
                    <Typography variant="body1">{langStrings.microsoftRegistrationStep4}</Typography>
                </li>
                <li>
                    <Typography variant="body1">{langStrings.microsoftRegistrationStep5}</Typography>
                    <ul>
                        <li>
                            <Typography variant="body1">
                                <strong>{langStrings.microsoftRegistrationPermissionMailSend}</strong>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                <strong>{langStrings.microsoftRegistrationPermissionUserReadAll}</strong>
                            </Typography>
                        </li>
                    </ul>
                    <Typography variant="body1">{langStrings.microsoftRegistrationStep6}</Typography>
                </li>
                <li>
                    <Typography variant="body1">{langStrings.microsoftRegistrationStep7}</Typography>
                </li>
            </ol>


            {/* Tenant ID Input */}
            <RowElement title={langStrings.microsoftTenantId}>
                <TextInput value={tenantId} onChange={(value) =>
                {
                    setTenantId(value);
                    setChanged(true);
                }} />
                <Typography variant='caption' color={'error'}>
                    {!GUID_REGEX.test(tenantId) && tenantId !== '' ? langStrings.invalidTenantId : ''}
                </Typography>
            </RowElement>

            {/* Client ID Input */}
            <RowElement title={langStrings.microsoftClientId}>
                <TextInput value={clientId} onChange={(value) =>
                {
                    setClientId(value);
                    setChanged(true);
                }} />
                <Typography variant='caption' color={'error'}>
                    {!GUID_REGEX.test(clientId) && clientId !== '' ? langStrings.invalidClientId : ''}
                </Typography>
            </RowElement>

            {/* Certificate Thumbprint Input */}
            <RowElement title={langStrings.microsoftCertificateThumbprint}>
                <TextInput value={certificateThumbprint} onChange={(value) =>
                {
                    setCertificateThumbprint(value);
                    setChanged(true);
                }} />
                <Typography variant='caption' color={'error'}>
                    {!CERTIFICATE_THUMBPRINT_REGEX.test(certificateThumbprint) && certificateThumbprint !== '' ? langStrings.invalidThumbprint : ''}
                </Typography>
            </RowElement>

            {/* Error Display */}
            {saveError && <ErrorBox close={setSaveError.bind(null, null)}>{saveError}</ErrorBox>}

            {/* Save Button */}
            {changed &&
                <Button
                    disabled={saving || !isValid}
                    size={'normal'} text={langStrings.save} icon={<Done />} onClick={handleSave} />
            }

            {/* File Upload for PEM/Certificate */}
            <RowElement title={langStrings.uploadCertificate}>
                <input
                    type="file"
                    onChange={handleFileChange}
                    disabled={!isValid || changed || saving || uploadingCertificate}
                    accept=".pem"
                />
                <Typography variant='caption' color={'error'}>
                    {!isValidPem && fileContent ? langStrings.invalidPemFile : ''}
                </Typography>
                <Typography variant='caption' color={'error'}>
                    {certficateError ? certficateError : ''}
                </Typography>
            </RowElement>
            <RowElement title={langStrings.microsoftCertificateStatus}>
                <Typography variant='body2'>
                    {company.microsoftSettings?.certificateStatus}
                </Typography>
            </RowElement>
            <RowElement title={langStrings.microsoftConnectionStatus}>
                <Typography variant='body2'>
                    {company.microsoftSettings?.connectionState}
                </Typography>
            </RowElement>
        </div>
    );
}
