import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { ELanguage, getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';

interface LanguageSelectProps<T>
{
    language: T;
    allowBrowserDefault?: true
    setLanguage: (val: T) => void;
}

/**
 * Renders a Material-UI select input for selecting a language.
 *
 * @param {LanguageSelectProps} language - The currently selected language.
 * @param {function} setLanguage - A function to set the selected language.
 * @returns {JSX.Element} - The Material-UI select input for selecting a language.
 */
function LanguageSelect<T extends ELanguage>({ language, allowBrowserDefault, setLanguage }: LanguageSelectProps<T>)
{
    const availableLanguages = Object.values(ELanguage)
    const langStrings = availableLanguages.map(lang => getLanguageTexts(lang).languageNames);

    return (
        <Select
            value={language !== null ? language : ''}
            onChange={(e) =>
            {
                const value = e.target.value;
                // if (allowBrowserDefault && value === '') return setLanguage(null) as T;
                setLanguage(value as T);
            }}
            MenuProps={{
                style: { zIndex: 1400 }
            }}
        >
            {
                allowBrowserDefault && <MenuItem key="default" value="default"><em>
                    Browser
                </em></MenuItem>
            }
            {(!language || language as string === "") && <MenuItem value=""><em>
                -
            </em></MenuItem>}
            {availableLanguages.map((code, index) => (
                <MenuItem key={code} value={code}>
                    {langStrings[index][code]}
                </MenuItem>
            ))}
        </Select>
    );
}

export default LanguageSelect;