import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";

const query = gql`
mutation deleteAttachment($id: ID!) {
    deleteAttachment(id: $id) 
},
`;

/**
 * Deletes an attachment.
 * 
 * @param {number} id - The ID of the attachment to delete.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the attachment was successfully deleted or not.
 */
export default async function deleteAttachment(id: string)
{
    const variables: OperationVariables = { id };
    return GraphQL.mutate<boolean>(query, variables);
}