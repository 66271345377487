import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { Delete, PsychologyOutlined } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICompetenceLevel } from '@sharedInterfaces/ICompetence';
import { AppState } from '@store/store';
import CompetenceOverlay from '@src/Components/Overlays/CompetenceOverlay/CompetenceOverlay';

import Item from '../Item/Item';
import ItemBadge, { NewBadge } from '../../Components/ItemBadge/ItemBadge';

interface CompetenceItemProps
{
    title: string;
    id: number;
    level?: number;
    disableOverlay?: boolean
    onClick?: string | (() => void)
    newPrefix?: true
    onDelete?: () => void
}
/**
 * Represents a competence item.
 *
 * @param {string} props.title - The title of the competence item.
 * @param {string} props.id - The id of the competence item.
 * @param {number} props.level - The level of the competence item.
 * @param {boolean} props.disableOverlay - Indicates if the overlay is disabled for the competence item.
 * @param {function} props.onClick - The function to be called when the competence item is clicked.
 * @param {function} props.onDelete - The function to be called when the competence item is deleted.
 * 
 * @returns {JSX.Element} The competence item as a JSX element.
 */
function CompetenceItem({ title, id, level, disableOverlay, newPrefix, onClick, onDelete }: CompetenceItemProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const allCompetence = allCompetences.find(c => c.id === id);
    let percent = 75;
    let targetLevel: undefined | ICompetenceLevel = undefined;
    let levelTitle: string = "";
    let levelTitleHelper: string = "";
    if (level !== undefined && allCompetence)
    {
        if (level > 0)
        {

            percent = level / allCompetence.levels.length * 100
            targetLevel = allCompetence.levels[level - 1]
            levelTitle = (level && allCompetence && targetLevel) ? ' - ' + targetLevel.title : "";
            levelTitleHelper = langStrings.oftenUsed.level + (targetLevel ? ': ' + targetLevel.title : '')
        }
        else
        {
            percent = 0;
            levelTitle = ' - ' + langStrings.inPlanningShort;
            levelTitleHelper = langStrings.inPlanning;
        }
    }

    return <Item
        onClick={onClick}
        percent={percent}
        // order={level ? level * -1 : undefined}
        leftContent={
            level !== undefined && <ItemBadge
                value={level}
                title={levelTitleHelper}
                icon={<PsychologyOutlined
                />}
            />
        }
        rightContent={
            onDelete !== undefined &&
            <div className="competenceItemDelete" title={langStrings.delete}>
                <IconButton
                    onClick={onDelete}
                    size='small'
                ><Delete /></IconButton>
            </div>
        }
    >
        <CompetenceOverlay key={id} disabled={disableOverlay} competenceId={id}>
            {title}
            {levelTitle}
        </CompetenceOverlay>
        {newPrefix && <NewBadge />}
    </Item>
}
export default CompetenceItem;