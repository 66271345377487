import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IPermissionGroup } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import Company from '@src/Objects/Company';
import { isValidEmailEnding } from '@src/Objects/Helper';
import editMailEndings from '@src/APIs/graphQl/Company/editMailEndings';
import editDefaultPermissionGroup from '@src/APIs/graphQl/Company/editDefaultPermissionGroup';

import LoadingBox from '../../sharedReact/General/LoadingBox/LoadingBox';
import RowElement from '../../sharedReact/General/Forms/RowElement/RowElement';
import ErrorBox from '../../Components/ErrorBox/ErrorBox';
import PermissionGroupSelect from '../../Components/formsControls/inputs/PermissionGroupSelect/PermissionGroupSelect';
import MultiTextChipSelect from '../../Components/formsControls/inputs/MultiTextChipSelect/MultiTextChipSelect';

interface RegistrationTabProps
{
    company: Company;
    setCompany: (company: Company) => void;
}
/**
 * RegistrationTab component.
 *
 * @param {object} company - The company object.
 * @param {function} setCompany - The function to set the company object.
 * @returns {JSX.Element} - The rendered RegistrationTab component.
 */
export function RegistrationTab({ company, setCompany }: RegistrationTabProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).registrationConfig;
    const [mailError, setMailError] = useState<string | null>(null);
    const [pgError, setPgError] = useState<string | null>(null);
    const [savingEnding, setSavingEnding] = useState<boolean>(false);
    const [savingPG, setSavingPG] = useState<boolean>(false);


    const updateMailEndings = (newEndings: string[]) =>
    {
        const lowerCaseEndings = newEndings.map(n => n.toLocaleLowerCase());
        const inVailedEndings = lowerCaseEndings.filter(e => !isValidEmailEnding(e));
        if (inVailedEndings.length > 0)
        {
            setMailError(
                inVailedEndings.map(ending => langStrings.emailEndingRegistration.errorMessage.replace('[ENDING]', ending)).join("\n")
            );
        }
        else
        {
            setMailError(null);
            setSavingEnding(true);
            editMailEndings(lowerCaseEndings)
                .then(setCompany)
                .catch(err => setMailError(err.toString()))
                .finally(setSavingEnding.bind(null, false));
        }
    };

    const updatePermissionGroup = (permissionGroup: IPermissionGroup) =>
    {
        if (company.defaultPermissionGroup?.id === permissionGroup.id) return;
        setPgError(null);
        setSavingPG(true);
        editDefaultPermissionGroup(permissionGroup.id)
            .then(setCompany)
            .catch(err => setPgError(err.toString()))
            .finally(setSavingPG.bind(null, false));
    };

    return <div>
        <h2>{langStrings.defaultRegistration.title}</h2>
        <p>{langStrings.defaultRegistration.text}</p>

        <h2>{langStrings.emailEndingRegistration.title}</h2>
        <p>{langStrings.emailEndingRegistration.text}</p>
        <RowElement title={langStrings.emailEndingRegistration.endingsField} alignTitle={'left'}>
            {!savingEnding ? <MultiTextChipSelect value={company.allowedMailEndings} onChange={updateMailEndings} placeholder="@test.de" /> : <LoadingBox />}
            {mailError && <ErrorBox close={setMailError.bind(null, null)}>
                {mailError}
            </ErrorBox>}
        </RowElement>
        <RowElement title={langStrings.permissionGroup} alignTitle="left">
            {!savingPG ? <PermissionGroupSelect
                permissionGroups={company.permissionGroups}
                selectedPermissionGroup={company.defaultPermissionGroup ? company.defaultPermissionGroup.id : -1}
                setSelectedPermissionGroup={updatePermissionGroup} />
                : <LoadingBox />}
            {pgError && <ErrorBox close={setMailError.bind(null, null)}>
                {pgError}
            </ErrorBox>}
        </RowElement>

    </div>;
}
