import { Done } from '@mui/icons-material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import IProjectDTO, { IProjectEmployeeReferenceDTO } from '@sharedInterfaces/IProject';
import { IReference } from '@sharedInterfaces/IReference';
import { AppState } from '@store/store';
import { updateProject } from '@store/reducer/projectReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import Project from '@src/Objects/Project';
import createProject from '@src/APIs/graphQl/Project/createProject';
import DateInput from '@src/Components/formsControls/inputs/DateInput/DateInput';
import editProject from '@src/APIs/graphQl/Project/editProject';
import getBusinesses from '@src/APIs/graphQl/Business/getBusinesses';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import ErrorBox from '../../ErrorBox/ErrorBox';
import './ProjectDialog.css';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import BusinessSelect from '../../formsControls/inputs/BusinessSelect/BusinessSelect';
import EmployeesInput from '../../formsControls/inputs/EmployeesInput/EmployeesInput';
import Row from '../../../sharedReact/General/Forms/Row/Row';
import CurrencyInput from '../../formsControls/inputs/CurrencyInput/CurrencyInput';
import FormatedTextInput from '../../formsControls/inputs/FormatedTextInput/FormatedTextInput';


interface ProjectDialogProps
{
    id: string
    project?: Project
    resolve?: (val: Project | null) => void;
}

/**
 * Renders a project dialog using the provided props.
 * 
 * @param {ProjectDialogProps} props - The props for the project dialog.
 * @returns {JSX.Element} The rendered project dialog.
 */
function ProjectDialog({ id, project, resolve }: ProjectDialogProps)
{
    const dispatch = useDispatch()
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).projects;
    const allEmployees = useSelector((state: AppState) => state.company.allEmployees);
    const businesses = useSelector((state: AppState) => state.businesses);

    const [errorText, setErrorText] = React.useState("" as string);
    const [saving, setSaving] = React.useState(false as boolean);

    const [projectName, setProjectName] = React.useState<string>(project ? project.title : '');
    const [description, setDescription] = React.useState<string>(project ? project.description : '');
    const [tenantId, setTenantId] = React.useState<string>(project ? project.tenantId : '');
    const [dayPrice, setDayPrice] = React.useState<number>(project ? project.dayPrice : 0);
    const [employees, setEmployees] = React.useState<IProjectEmployeeReferenceDTO[]>((project ? project.employees : []));
    const [business, setBusiness] = React.useState<IReference>((project ? project.business : { id: -1, title: '' }));
    const [internalProject, setInternalProject] = React.useState<boolean>(project?.internalProject ? project.internalProject : false);
    const [startDate, setStartDate] = React.useState<Date | undefined>(project?.startDate ? project.startDate : undefined);
    const [endDate, setEndDate] = React.useState<Date | undefined>(project?.endDate ? project.endDate : undefined);

    React.useEffect(() =>
    {
        getBusinesses()
    }, []);

    const onFetchError = (ex: { toString(): string }) =>
    {
        const error = ex.toString();
        setErrorText(error);
        setTimeout(() =>
        {
            setSaving(false);
        }, 3000);
    }
    const onFetch = (newProject: IProjectDTO) =>
    {
        const p = new Project(newProject)
        resolve && resolve(p);
        setSaving(false);
        dispatch(updateProject(p))
    }

    const onSave = async () =>
    {
        setSaving(true);
        if (projectName === "")
        {
            setErrorText(langStrings.newProjectError);
            setTimeout(() =>
            {
                setSaving(false);
            }, 1500);
            return;
        }
        if (!project)
        {
            await createProject(
                {
                    title: projectName,
                    tenantId,
                    description,
                    employees,
                    dayPrice,
                    business,
                    internalProject,
                    startDate,
                    endDate
                }
            )
                .then(onFetch)
                .catch(onFetchError)
        } else
        {
            await editProject(
                {
                    id: project.id,
                    title: projectName,
                    tenantId,
                    description,
                    dayPrice,
                    employees,
                    business,
                    internalProject,
                    startDate,
                    endDate
                }
            )
                .then(onFetch)
                .catch(onFetchError)
        }

    };

    return (
        <Dialog
            id={id}
            title={project ? langStrings.editProject + project.title : langStrings.newProject} onClose={() => resolve && resolve(null)}
            footer={
                <div style={{ float: 'right' }}>
                    <Button
                        icon={<Done />}
                        disabled={saving}
                        text={!project ? langStrings.create : langStrings.save}
                        size={'normal'}
                        onClick={onSave}
                    />
                </div>
            }
        >
            <div className="projectDialog">
                <div className="newProject">
                    <Row>
                        <RowElement title={langStrings.projectName} alignTitle="left">
                            <TextInput disabled={project ? project.sync : false} value={projectName} onChange={s => setProjectName(s)} />
                        </RowElement>
                    </Row>
                    <Row>
                        <RowElement title={langStrings.customer} alignTitle="left">
                            <BusinessSelect disabled={project ? project.sync : false} businesses={businesses} selectedBusiness={business.id} setSelectedBusiness={function (val: IReference): void
                            {
                                setBusiness(val);
                            }} />
                        </RowElement>
                        <RowElement title={langStrings.internalProject} alignTitle="left">
                            <Checkbox checked={internalProject} onChange={e => setInternalProject(e.target.checked)} />
                        </RowElement>
                    </Row>
                    <Row>
                        <RowElement alignTitle={'left'} title={langStrings.startDate}>
                            <DateInput disabled={project ? project.sync : false} value={startDate} onChange={projectStart => setStartDate(projectStart)} />
                        </RowElement>
                        <RowElement alignTitle={'left'} title={langStrings.endDate}>
                            <DateInput disabled={project ? project.sync : false} value={endDate} onChange={projectEnd => setEndDate(projectEnd)} />
                        </RowElement>
                    </Row>
                    <Row>
                        <RowElement title={langStrings.dayPrice} alignTitle="left">
                            <CurrencyInput value={dayPrice} onChange={setDayPrice} />
                        </RowElement>

                        <RowElement title={langStrings.tenantId} alignTitle="left">
                            <TextInput value={tenantId} disabled={project ? project.sync : false} onChange={s => setTenantId(s)} />
                        </RowElement>
                    </Row>
                    <Row>
                        <RowElement title={langStrings.description} alignTitle="left">
                            <FormatedTextInput value={description} onChange={setDescription} />
                        </RowElement>
                    </Row>
                    <Row>
                        <RowElement title={langStrings.employees} alignTitle="left">
                            <EmployeesInput selectedEmployees={employees} allEmployees={allEmployees} onChange={setEmployees} showMode={project ? project.sync : false} size={'medium'} />
                        </RowElement>
                    </Row>
                </div>
                {errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>}
            </div>
        </Dialog>
    );
}

export default ProjectDialog;