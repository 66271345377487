import React from 'react';
import { useSelector } from 'react-redux';
import { Select, MenuItem } from '@mui/material';
import { ICountriesTexts } from '@sharedInterfaces/Language/languageTexts/countries';
import { AppState } from '@store/store';
import { subCountriesTexts } from '@sharedInterfaces/Language/languageTexts/countrySubnationalNames';

interface SubCountrySelectProps
{
    country: keyof ICountriesTexts;
    subCountry: string;
    setInnerCountry: (val: string) => void;
}

/**
 * SubCountrySelect component.
 *
 * @param {SubCountrySelectProps} country - The country prop.
 * @param {SubCountrySelectProps} subCountry - The subCountry prop.
 * @param {SubCountrySelectProps} setInnerCountry - The setInnerCountry prop.
 * @returns {JSX.Element} The JSX element.
 */
function SubCountrySelect({ country, subCountry, setInnerCountry }: SubCountrySelectProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings: { [key: string]: string } | undefined = subCountriesTexts[lang][country];

    const subCountries: string[] = Object.keys(langStrings || {}); // Hier könnten Sie die subnationalen Einheiten für das ausgewählte Land auflisten
    subCountries
        .filter(sc => sc)
        .sort((a, b) => a.localeCompare(b));

    return (
        <Select
            labelId="sub-country-select-label"
            value={subCountry}
            onChange={(e) =>
            {
                setInnerCountry(e.target.value as string);
            }}
            MenuProps={{
                style: { zIndex: 1400 }
            }}
        >
            {(!subCountry || subCountry === "") && <MenuItem value=""><em>-</em></MenuItem>}
            {subCountries.map(code => (
                <MenuItem key={code} value={code}>
                    {langStrings[code]}
                </MenuItem>
            ))}
        </Select>
    );
}

export default SubCountrySelect;
