import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICompetenceLevel } from '@sharedInterfaces/ICompetence';
import { EEntityType, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import CardBox from '@src/Components/CardBox/CardBox';
import List, { generateTopXText } from '@src/App/NewLayout/Lists/List/List';
import EmployeeItem from '@src/App/NewLayout/Items/Employee/EmployeeItem';

interface EmployeesCardProps
{
    title?: string;
    employees: {
        id: number
        title: string
        level?: number
        match?: number
        count?: number
        active?: { activeUntil?: Date }
    }[];
    competenceLevels?: ICompetenceLevel[]
    inOverlay?: boolean
    limit?: number
    skillId?: number
}


/**
 * Generates a card containing information about employees.
 * 
 * @param {EmployeesCardProps} title - The title of the card.
 * @param {EmployeesCardProps} employees - The list of employees to display.
 * @param {EmployeesCardProps} inOverlay - Boolean indicating whether the card is displayed in an overlay.
 * @param {EmployeesCardProps} competenceLevels - The competence levels of the employees.
 * 
 * @returns {JSX.Element} The JSX element representing the employees card.
 */
function EmployeesCard({ limit, title, employees, inOverlay, competenceLevels, skillId }: EmployeesCardProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employees;

    const newTitle = generateTopXText(title ? title : langStrings.employees, limit)
    const newEmps = [...employees].sort((a, b) =>
    {
        if (a.match !== undefined && b.match !== undefined)
        {
            const dif = b.match - a.match;
            if (dif !== 0) return dif;
        }
        if (a.level !== undefined && b.level !== undefined)
        {
            const dif = b.level - a.level;
            if (dif !== 0) return dif;
        }
        if (a.count !== undefined && b.count !== undefined)
        {
            const dif = b.count - a.count;
            if (dif !== 0) return dif;
        }
        return a.title?.localeCompare(b.title)
    })
    const limitedElements = limit ? newEmps
        .slice(0, limit > newEmps.length ? newEmps.length : limit) : newEmps;
    const DOM = limitedElements.map((employee) =>
    {
        return <EmployeeItem
            key={employee.id}
            id={employee.id}
            title={employee.title}
            level={employee.level && Math.round(employee.level * 10) / 10}
            count={employee.count}
            match={employee.match}
            active={employee.active}
            disableOverlay={inOverlay}
            competenceLevels={competenceLevels}
            skillId={skillId}
            onClick={entityTypeToLink(employee.id, EEntityType.EMPLOYEE)}
        />;
    });
    return (
        <CardBox title={newTitle}>
            <List>
                {DOM}
            </List>
        </CardBox>
    );
}

export default EmployeesCard;