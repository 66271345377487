import React from 'react';
import './RolesInput.css'
import { NavLink } from "react-router-dom";
import { Delete } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ISmallRole } from '@sharedInterfaces/IWhoIAm';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import Item from '@src/App/NewLayout/Items/Item/Item';
import { NewBadge } from '@src/App/NewLayout/Components/ItemBadge/ItemBadge';
import { Typography } from '@mui/material';

import RoleSearchBox from "../../searchBoxes/RoleSearchBox/RoleSearchBox";

interface IRoleInputRole
{
    id: number
    title: string
}
interface RolesInputProps<T extends IRoleInputRole>
{
    allRoles: ISmallRole[]
    selectedRoles: T[]
    showMode: boolean
    size: 'medium' | 'small'
    disabled?: boolean
    helperText?: string
    onChange(val: T[]): void
}

/**
 * Represents an input component for roles.
 * 
 * @param {RolesInputProps} allRoles - A list of all roles.
 * @param {RolesInputProps} selectedRoles - A list of selected roles.
 * @param {RolesInputProps} showMode - The mode to display the roles.
 * @param {RolesInputProps} size - The size of the input.
 * @param {RolesInputProps} onChange - Event handler for when the input value changes.
 * 
 * @returns {JSX.Element} The rendered input component.
 */
function RolesInput<T extends IRoleInputRole>({
    allRoles,
    selectedRoles,
    showMode,
    size,
    disabled,
    helperText,
    onChange,
}: RolesInputProps<T>)
{
    if (!allRoles) return <div />;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).roles;

    const filteredAllRoles = allRoles.filter(a =>
        selectedRoles.find(s => s.id === a.id || s.title === a.title) === undefined) //TO hide allready selected roles

    const rolesDOM = selectedRoles.map(role =>
    {
        const withoutLink = !showMode || role.id === undefined;
        return <Item
            key={role.title + role.id}
            rightContent={
                (!showMode && !disabled) &&
                <IconButton
                    title={langStrings.oftenUsed.delete}
                    size='small'
                    onClick={function (): void
                    {
                        const newSelectedRoles = selectedRoles.map(e => Object.assign({}, e))
                            .filter(e => e.id !== role.id)
                            .sort((a, b) => a.title.localeCompare(b.title));
                        onChange(newSelectedRoles);
                    }}
                >
                    <Delete
                        fontSize="small"
                    />
                </IconButton>
            }
        >
            {withoutLink ?
                <>
                    {role.title}
                    {
                        !role.id &&
                        <NewBadge />
                    }
                </>
                :
                <NavLink key={role.id} to={entityTypeToLink(role.id, EEntityType.ROLE)} className="role">
                    {role.title}
                </NavLink>
            }
        </Item>;
    }
    );
    return (
        <>
            <div
                className={`rolesInput ${size}`}
                style={{
                    opacity: disabled ? 0.5 : 1,
                    pointerEvents: disabled ? 'none' : 'auto'
                }}
            >
                <div className="roles">
                    {rolesDOM}
                </div>
                {
                    (!showMode && !disabled) &&
                    <div className="newRoleRow"
                    >
                        <RoleSearchBox
                            allRoles={filteredAllRoles}
                            onSelectedRole={(selectedRole) =>
                            {
                                const newSelectedRoles = selectedRoles.map(e => Object.assign({}, e));
                                newSelectedRoles.push(selectedRole as unknown as T);
                                newSelectedRoles.sort((a, b) => a.title.localeCompare(b.title));

                                onChange(newSelectedRoles);
                            }} />
                    </div>
                }
            </div>
            {helperText && <Typography
                variant="caption"
                color="textSecondary"
                style={{ marginTop: 10 }}
            >{helperText}</Typography>}
        </>
    );
}

export default RolesInput;