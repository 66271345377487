import { Action } from "redux";

export const initialClientState: IClientState = {
    secret: null,
    offline: false,
    loading: false,
    isLocalDebug: location.hostname === 'localhost',
};

export const isLoggedIn = (secret: null | string): boolean =>
{
    return (secret !== undefined && secret !== null && secret !== '')
}

export interface IClientState
{
    secret: null | string
    offline: boolean
    loading: boolean
    isLocalDebug: boolean
}
export enum EClientAction
{
    SET_SECRET = "SET_SECRET",
    SET_OFFLINE = "SET_OFFLINE",
    SET_LOADING = "SET_LOADING",
}

interface SecretAction extends Action
{
    type: EClientAction.SET_SECRET;
    payload: string | null;
}
interface OfflineAction extends Action
{
    type: EClientAction.SET_OFFLINE;
    payload: boolean;
}
interface LoadingAction extends Action
{
    type: EClientAction.SET_LOADING;
    payload: boolean;
}


type ClientActions = OfflineAction | LoadingAction | SecretAction;

export const clientReducer = (state = initialClientState, action: ClientActions): IClientState =>
{
    switch (action.type)
    {
        case EClientAction.SET_SECRET:
            return {
                ...state,
                secret: action.payload,
            };
        case EClientAction.SET_OFFLINE:
            return {
                ...state,
                offline: action.payload,
            };
        case EClientAction.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

// Action Creator
export const setOffline = (offline: boolean): OfflineAction => ({
    type: EClientAction.SET_OFFLINE,
    payload: offline,
});


// Action Creator
export const setLoading = (loading: boolean): LoadingAction => ({
    type: EClientAction.SET_LOADING,
    payload: loading,
});

// Action Creator
export const setSecret = (secret: string | null): SecretAction => ({
    type: EClientAction.SET_SECRET,
    payload: secret,
});
