


import { ELanguage } from "../languageHelper";

export interface IIndustriesTexts
{
    informationTechnology: string
    healthcare: string
    finance: string
    manufacturing: string
    retail: string
    energy: string
    transportation: string
    consulting: string
    temporaryWork: string
    education: string
    media: string
    hospitality: string
    realEstate: string
    government: string
    agriculture: string
    automotive: string
    biotechnology: string
    telecommunications: string
    pharmaceuticals: string
    construction: string
    consumerGoods: string
    insurance: string
    legal: string
    nonProfit: string
    travel: string
    utilities: string
    others: string
}
type ILangData = {
    [lang in ELanguage]: IIndustriesTexts
}
export const industriesTexts: ILangData = {
    'th': {
        informationTechnology: 'เทคโนโลยีสารสนเทศ',
        healthcare: "การดูแลสุขภาพ",
        finance: "การเงิน",
        manufacturing: "การผลิต",
        retail: "ค้าปลีก",
        energy: "พลังงาน",
        transportation: "การขนส่งและโลจิสติกส์",
        consulting: "การให้คำปรึกษา",
        temporaryWork: "การทำงานชั่วคราว",
        education: "การศึกษา",
        media: "สื่อและการสื่อสาร",
        hospitality: "อุตสาหกรรมการบริการ",
        realEstate: "อสังหาริมทรัพย์",
        government: "สาธารณสุข",
        agriculture: "การเกษตร",
        automotive: "อุตสาหกรรมยานยนต์",
        biotechnology: "ชีวเทคโนโลยี",
        telecommunications: "การสื่อสารโทรคมนาคม",
        pharmaceuticals: "เภสัชกรรม",
        construction: "อุตสาหกรรมก่อสร้าง",
        consumerGoods: "สินค้าผลิตภัณฑ์",
        insurance: "ประกันภัย",
        legal: "กฎหมาย",
        nonProfit: "ไม่แสวงหาผลกำไร",
        travel: "การท่องเที่ยว",
        utilities: "อุตสาหกรรมยุทธศาสตร์",
        others: "อื่นๆ",
    },
    'en': {
        informationTechnology: 'Information Technology',
        temporaryWork: 'Temporary Work',
        consulting: 'Consulting',
        transportation: 'Transportation and Logistics',
        energy: 'Energy',
        retail: 'Retail',
        manufacturing: 'Manufacturing',
        finance: 'Finance',
        healthcare: 'Healthcare',
        biotechnology: 'Biotechnology',
        automotive: 'Automotive',
        agriculture: 'Agriculture',
        government: 'Public Service',
        realEstate: 'Real Estate',
        hospitality: 'Hospitality',
        media: 'Media and Communication',
        education: 'Education',
        others: 'Others',
        utilities: 'Utilities',
        travel: 'Travel and Tourism',
        nonProfit: 'Non-Profit',
        legal: 'Legal',
        insurance: 'Insurance',
        consumerGoods: 'Consumer Goods',
        construction: 'Construction',
        pharmaceuticals: 'Pharmaceuticals',
        telecommunications: 'Telecommunications',
    },
    'de': {
        informationTechnology: "Informationstechnologie",
        healthcare: "Gesundheitswesen",
        finance: "Finanzwesen",
        manufacturing: "Produktion",
        retail: "Einzelhandel",
        energy: "Energie",
        transportation: "Transport und Logistik",
        consulting: "Beratung",
        temporaryWork: "Zeitarbeit",
        education: "Bildung",
        media: "Medien und Kommunikation",
        hospitality: "Gastgewerbe",
        realEstate: "Immobilien",
        government: "Öffentlicher Dienst",
        agriculture: "Landwirtschaft",
        automotive: "Automobilindustrie",
        biotechnology: "Biotechnologie",
        telecommunications: "Telekommunikation",
        pharmaceuticals: "Pharmazie",
        construction: "Bauwesen",
        consumerGoods: "Konsumgüter",
        insurance: "Versicherung",
        legal: "Rechtswesen",
        nonProfit: "Non-Profit",
        travel: "Reise und Tourismus",
        utilities: "Versorgungswirtschaft",
        others: "Sonstiges",
    },
}