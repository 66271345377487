import React from 'react'
import { TableChart, TableRows } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import { textContainsLowerCase } from '@src/Objects/Helper';
import Employee from '@src/Objects/Employee';
import EmployeeOverlay from '@src/Components/Overlays/EmployeeOverlay/EmployeeOverlay';
import EnhancedTable, { SortOrder } from '@src/App/NewLayout/Components/DataTable/DataTable';
import getEmployees from '@src/APIs/graphQl/Employee/getEmployees';
import './EmployeesPage.css';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import EmployeeCard from '@src/App/NewLayout/Cards/Employees/EmployeeCard/EmployeeCard';

import EntitiesPage, { EViewType, IEntitiesPageAction } from '../../../../../sharedReact/Pages/EntitiesPage/EntitiesPage';
import { IViewType } from '../../../../../sharedReact/Pages/EntitiesPage/ViewTypeMenu';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
/**
 * Represents the EmployeesPage component.
 * 
 * @param {EmployeesPageProps} props - The props object for the EmployeesPage component.
 * @returns {JSX.Element} The rendered EmployeesPage component.
 */
function EmployeesPage()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employees;
    const [selected, setSelected] = useState<number[]>([]);
    const entities = useSelector((state: AppState) => state.employees)

    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);

    const isDense = (width: number) => width <= 1000

    useEffect(() =>
    {
        getEmployees().catch((ex) =>
        {
            setLoadingError(ex);
        });
    }, []);

    const types: IViewType<Employee>[] = [
        {
            id: EViewType.TABLE,
            title: langStrings.oftenUsed.table,
            icon: <TableRows fontSize='small' />,
            minWidth: 375,
            renderContainer:
                (allEntities, innerEntries, width, selected) =>
                    <EnhancedTable
                        id={EEntityType.EMPLOYEE}
                        dense={isDense(width)}
                        noCheckbox={!actions.length}
                        selected={selected}
                        order={SortOrder.ASC}
                        orderBy='lastname'
                        setSelected={setSelected}
                        fields={[{
                            id: 'id',
                            label: 'ID',
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'string',
                        },
                        {
                            id: 'firstname',
                            label: langStrings.firstName,
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'JSX.Element',
                            link: `/${ELinks.PEOPLE_EMPLOYEES}/`,
                        },
                        {
                            id: 'lastname',
                            label: langStrings.lastName,
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'JSX.Element',
                            link: `/${ELinks.PEOPLE_EMPLOYEES}/`,
                        },
                        {
                            id: 'description',
                            label: langStrings.description,
                            disablePadding: isDense(width),
                            align: 'left',
                            minWidth: 1000,
                            type: 'description',
                        },
                            // {
                            //     id: 'skills',
                            //     label: langStrings.skills,
                            //     disablePadding: isDense(width),
                            //     align: 'center',
                            //     type: 'string',
                            // },
                            // {
                            //     id: 'certificates',
                            //     label: langStrings.certificates,
                            //     disablePadding: isDense(width),
                            //     align: 'center',
                            //     minWidth: 475,
                            //     type: 'string',
                            // },
                        ]}
                        rows={
                            innerEntries.map(entity => ({
                                id: entity.id,
                                firstname: {
                                    value: <EmployeeOverlay employeeId={entity.id}
                                    >{entity.firstName} </EmployeeOverlay>,
                                    orderKey: entity.firstName,
                                },
                                lastname: {
                                    value: <EmployeeOverlay employeeId={entity.id}
                                    >{entity.lastName} </EmployeeOverlay>,
                                    orderKey: entity.lastName,
                                },
                                description: entity.description,
                            }
                            ))
                        } />,
        },
        {
            id: EViewType.CARD,
            title: langStrings.oftenUsed.cards,
            icon: <TableChart fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <Container>
                {
                    innerEntries.map(entity =>
                        <EmployeeCard key={entity.id} employee={entity}
                        />)
                }
            </Container>,
        }
    ];

    const actions: IEntitiesPageAction[] = [];

    return (
        <EntitiesPage
            title={langStrings.employees}
            entities={entities}
            selected={selected}
            loadingError={loadingError}

            setSelected={setSelected}
            filter={(entity, selectedView, searchText) =>
                selectedView.filter(entity) && textContainsLowerCase(searchText, entity.title)
            }
            views={
                [
                    {
                        id: 'all', title: langStrings.oftenUsed.allEntries,
                        filter()
                        {
                            return true;
                        },
                    },

                ]}
            types={types}
            actions={actions}
        />
    );
}

export default EmployeesPage;
