import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import InnerLayout from '@sharedReact/Layouts/InnerLayout/InnerLayout';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeSettingsMailSettingsSubscription } from '@sharedInterfaces/IEmployeeSettings';
import { AppState } from '@store/store';
import setEmployeeEmailSettings from '@src/APIs/graphQl/Employee/setEmployeeEmailSettings';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

interface SubscriptionType
{
    id: keyof IEmployeeSettingsMailSettingsSubscription;
    label: string;
    subscribed: boolean;
    notificationSubscribed: boolean;
}

function EmailSettingsPage()
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).emailTexts;

    const emailSettings = useSelector((state: AppState) => state.employeeSettings.emailSettings);

    const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([]);

    const [allEmails, setAllEmails] = useState(emailSettings.subscribeAll);
    const [allNotifications, setAllNotifications] = useState(emailSettings.subscribeAllNotifications);
    const [changed, setChanged] = useState(false);
    const [updating, setUpdating] = useState(false);

    useEffect(() =>
    {
        if (!emailSettings) return;
        setAllEmails(emailSettings.subscribeAll);
        setAllNotifications(emailSettings.subscribeAllNotifications);
        if (!emailSettings.subscriptions) return;
        const subscriptions = emailSettings.subscriptions;
        setSubscriptions(Object.entries(subscriptions).map(([key, value]: [string, boolean]) => ({
            id: key as keyof IEmployeeSettingsMailSettingsSubscription,
            label: langStrings[key as keyof IEmployeeSettingsMailSettingsSubscription],
            subscribed: value,
            notificationSubscribed: emailSettings.notificationSubscriptions[key as keyof IEmployeeSettingsMailSettingsSubscription]
        })));
    }, [emailSettings, emailSettings.subscribeAll, emailSettings.subscribeAllNotifications, emailSettings?.subscriptions])

    const handleAllEmailsChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        setChanged(true);
        setAllEmails(event.target.checked);
    };

    const handleAllNotificationsChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        setChanged(true);
        setAllNotifications(event.target.checked);
    };

    const handleSubscriptionChange = (id: keyof IEmployeeSettingsMailSettingsSubscription, type: 'subscribed' | 'notificationSubscribed') => (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        setChanged(true);
        setSubscriptions(subscriptions.map(sub => sub.id === id ? { ...sub, [type]: event.target.checked } : sub));
    };

    const handleConfirm = () =>
    {
        setUpdating(true);

        setEmployeeEmailSettings(allEmails, allNotifications, subscriptions.map((s) => ({ id: s.id, subscribed: s.subscribed, notificationSubscribed: s.notificationSubscribed })))
            .catch(showErrorDialog)
            .finally(() =>
            {
                setUpdating(false);
                setChanged(false);
            })
    }

    return (
        <InnerLayout title={langStrings.emailSettingsTitle}>
            <div style={{ userSelect: 'none' }}>
                <Typography variant="h5" gutterBottom>
                    {langStrings.emailSettingsDescription}
                </Typography>
                <Typography paragraph>
                    {langStrings.emailSettingsInstructions}
                </Typography>

                <Paper variant="outlined" square style={{ padding: '20px', marginBottom: '20px' }}>
                    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{langStrings.subscriptionType}</TableCell>
                                    <TableCell align="center">
                                        {langStrings.receiveEmails}
                                        <div style={{ marginTop: '8px' }}>
                                            <Checkbox
                                                checked={allEmails}
                                                onChange={handleAllEmailsChange}
                                                color="primary"
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        {langStrings.receiveNotifications}
                                        <div style={{ marginTop: '8px' }}>
                                            <Checkbox
                                                checked={allNotifications}
                                                onChange={handleAllNotificationsChange}
                                                color="primary"
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subscriptions.map(subscription => (
                                    <TableRow key={subscription.id}>
                                        <TableCell>{subscription.label}</TableCell>
                                        <TableCell align="center">
                                            <Checkbox
                                                checked={subscription.subscribed && allEmails}
                                                onChange={handleSubscriptionChange(subscription.id, 'subscribed')}
                                                disabled={!allEmails}
                                                color="primary"
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox
                                                checked={subscription.notificationSubscribed && allNotifications}
                                                onChange={handleSubscriptionChange(subscription.id, 'notificationSubscribed')}
                                                disabled={!allNotifications}
                                                color="primary"
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {changed && <Button
                        style={{ maxWidth: 300, marginTop: '20px' }}
                        variant="contained"
                        color="primary"
                        disabled={updating}
                        onClick={handleConfirm}
                    >
                        {langStrings.oftenUsed.confirm}
                    </Button>
                    }
                </Paper>
            </div>
        </InnerLayout>
    );
}

export default EmailSettingsPage;
