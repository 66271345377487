import React from 'react';
import './EmployeesInput.css'
import { NavLink } from "react-router-dom";
import { Delete } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EEntityType, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { ISmallEmployee } from '@sharedInterfaces/IWhoIAm';
import { AppState } from '@store/store';
import Item from '@src/App/NewLayout/Items/Item/Item';

import EmployeeSearchBox from "../../searchBoxes/EmployeeSearchBox/EmployeeSearchBox";

interface IEmployeeInputEmployee
{
    id: number
    title: string
}
interface EmployeesInputProps<T extends IEmployeeInputEmployee>
{
    allEmployees: ISmallEmployee[]
    selectedEmployees: T[]
    showMode: boolean
    size: 'medium' | 'small'
    onChange(val: T[]): void
}

/**
 * Represents an input component for employees.
 * 
 * @param {EmployeesInputProps} allEmployees - A list of all employees.
 * @param {EmployeesInputProps} selectedEmployees - A list of selected employees.
 * @param {EmployeesInputProps} showMode - The mode to display the employees.
 * @param {EmployeesInputProps} size - The size of the input.
 * @param {EmployeesInputProps} onChange - Event handler for when the input value changes.
 * 
 * @returns {JSX.Element} The rendered input component.
 */
function EmployeesInput<T extends IEmployeeInputEmployee>({ allEmployees, selectedEmployees, showMode, size, onChange, }: EmployeesInputProps<T>)
{
    if (!allEmployees) return <div />;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employees;

    const filteredAllEmployees = allEmployees.filter(a =>
        selectedEmployees.find(s => s.id === a.id || s.title === a.title) === undefined) //TO hide allready selected employees

    const employeesDOM = selectedEmployees.map(employee =>
    {
        const withoutLink = !showMode || employee.id === undefined;
        return <Item
            key={employee.title + employee.id}
            rightContent={
                !showMode &&
                <IconButton
                    title={langStrings.oftenUsed.delete}
                    size='small'
                    onClick={function (): void
                    {
                        const newSelectedEmployees = selectedEmployees.map(e => Object.assign({}, e))
                            .filter(e => e.id !== employee.id)
                            .sort((a, b) => a.title.localeCompare(b.title));
                        onChange(newSelectedEmployees);
                    }}
                >
                    <Delete
                        fontSize="small"
                    />
                </IconButton>
            }
        >
            {withoutLink ? employee.title :
                <NavLink key={employee.id} to={entityTypeToLink(employee.id, EEntityType.EMPLOYEE)} className="employee">
                    {employee.title}
                </NavLink>
            }
        </Item>;
    }
    );
    return (
        <div className={'employeesInput ' + (size)} style={{ border: showMode ? 'none' : '' }} >
            <div className="employees">
                {employeesDOM}
            </div>
            {
                !showMode &&
                <div className="newEmployeeRow"
                >
                    <EmployeeSearchBox
                        allEmployees={filteredAllEmployees}
                        onSelectedEmployee={(selectedEmployee) =>
                        {
                            const newSelectedEmployees = selectedEmployees.map(e => Object.assign({}, e));
                            newSelectedEmployees.push(selectedEmployee as unknown as T);
                            newSelectedEmployees.sort((a, b) => a.title.localeCompare(b.title));

                            onChange(newSelectedEmployees);
                        }} />
                </div>
            }
        </div>
    );
}

export default EmployeesInput;