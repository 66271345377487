import { stageContext } from "@src/globals";

const baseURL = stageContext.apiGatewayRestDomain;

export const unsubscribe = async (email: string, token: string, unsubscribe: boolean = true): Promise<boolean> =>
{
    const url = `${baseURL}/unsubsribe`;
    const body = JSON.stringify({ email, token, unsubscribe });

    try
    {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        });

        if (!response.ok)
        {
            throw new Error(`HTTP-Fehler: Status ${response.status}`);
        }

        // Extrahieren Sie das "valid"-Attribut aus der JSON-Antwort
        const data = await response.json();
        return data.valid; // Gibt true oder false zurück basierend auf der Serverantwort
    } catch (error)
    {
        console.error('Fehler beim Senden des Invite Codes:', error);
        return false; // Bei einem Fehler, false zurückgeben
    }
};