import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";

const q = gql`
query employeePictureId($id: ID!) {
    employeePictureId(id:  $id) 
},
`;

/**
 * Retrieves the picture ID of an employee.
 *
 * @param {number} id - The ID of the employee.
 * @param {(pictureId: string) => void} callback - The callback function that receives the picture ID as a parameter.
 * @returns {Promise<any>} - A promise that resolves with the picture ID.
 */
export default async function getEmployeePictureId(id: number, callback: (pictureId: string | null) => void)
{
    const variables: OperationVariables = { id };
    return GraphQL.query<{ employeePictureId: string | null }>(q, variables, (obj) =>
        obj ?
        callback(obj.employeePictureId) :
            null
    );
}