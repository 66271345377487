import { Done } from '@mui/icons-material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { setCompanyAllSkills, setCompanySkillCategories } from '@store/reducer/companyReducer';
import { updateSkillCategory } from '@store/reducer/skillCategoriesReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import { SkillCategory } from '@src/Objects/SkillCategory';
import editSkillCategory from '@src/APIs/graphQl/SkillCategory/editSkillCategory';
import createSkillCategory from '@src/APIs/graphQl/SkillCategory/createSkillCategory';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import ErrorBox from '../../ErrorBox/ErrorBox';
import './SkillCategoryDialog.css';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import SkillsInputWithoutLevel from '../../formsControls/inputs/SkillsInputWithoutLevel/SkillsInputWithoutLevel';
import FormatedTextInput from '../../formsControls/inputs/FormatedTextInput/FormatedTextInput';
import SkillCategorySelect from '../../formsControls/inputs/SkillCategorySelect/SkillCategorySelect';

interface SkillCategoryDialogProps
{
    id: string
    skillCategory?: SkillCategory
    resolve?: (val: SkillCategory | null) => void;
}

/**
 * SkillCategoryDialog component.
 *
 * @param {SkillCategoryDialogProps} props - The props for the SkillCategoryDialog component.
 * @returns {JSX.Element} The rendered JSX.Element of the SkillCategoryDialog component.
 */
function SkillCategoryDialog({ id, skillCategory, resolve }: SkillCategoryDialogProps)
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skillCategories;
    const skillCategories = useSelector((state: AppState) => state.company.skillCategories);
    const allSkills = useSelector((state: AppState) => state.company.allSkills);


    // const dispatch = useDispatch();
    const [errorText, setErrorText] = React.useState<string>("");
    const [saving, setSaving] = React.useState<boolean>(false);

    const [skillcategoryName, setSkillCategoryName] = React.useState<string>(skillCategory ? skillCategory.title : '');
    const [description, setDescription] = React.useState<string>(skillCategory ? skillCategory.description : '');
    const [skills, setSkills] = React.useState<{ title: string, id: number }[]>(allSkills.filter(s => s.categoryId === skillCategory?.id))

    const [parentCategory, setParentCategory] = React.useState<number | null>(skillCategory ? skillCategory?.parentCategory : null);

    const onFetchError = (ex: { toString(): string }) =>
    {
        const error = ex.toString();
        setErrorText(error);
        setTimeout(() =>
        {
            setSaving(false);
        }, 3000);
    }
    const updateSkills = (id: number) =>
    {
        const skillIds = skills.map(s => s.id)
        const newAllSkills = allSkills.slice().map(s => ({ ...s }));
        newAllSkills.forEach(s =>
        {
            if (skillIds.includes(s.id))
            {
                if (s.categoryId !== id)
                {
                    s.categoryId = id;
                }
            }
            else
            {
                if (s.categoryId === id)
                {
                    s.categoryId = -1;
                }
            }
        })
        dispatch(setCompanyAllSkills(newAllSkills))
    }

    const onFetch = (newSkillCategory: SkillCategory) =>
    {
        updateSkills(newSkillCategory.id);
        const newSkillCategories = skillCategories
            .slice()
            .filter(s => s.id !== skillCategory?.id);
        newSkillCategories.push(new SkillCategory({
            id: newSkillCategory.id,
            title: newSkillCategory.title,
            description: newSkillCategory.description,
            parentCategory: newSkillCategory.parentCategory,
            employees: newSkillCategory.employees,
            version: 0,

        }));
        dispatch(setCompanySkillCategories(newSkillCategories.map(c => new SkillCategory({ ...c, employees: c.employees }))))

        dispatch(updateSkillCategory(newSkillCategory));
        resolve && resolve(newSkillCategory);
        setSaving(false);
    }

    const onSave = async () =>
    {
        setSaving(true);
        if (skillcategoryName === "")
        {
            setErrorText(langStrings.newSkillCategoryError);
            setTimeout(() =>
            {
                setSaving(false);
            }, 1500);
            return;
        }
        if (!skillCategory)
        {
            await createSkillCategory(
                skillcategoryName,
                description,
                parentCategory,
                skills.map(s => s.id)
            )
                .then(onFetch)
                .catch(onFetchError)
        } else
        {
            await editSkillCategory(skillCategory.id,
                skillcategoryName,
                description,
                parentCategory,
                skills.map(s => s.id))
                .then(onFetch)
                .catch(onFetchError)
        }
    };

    return (
        <Dialog
            id={id}
            title={skillCategory ? skillCategory.title : langStrings.newSkillCategory}
            onClose={() =>
            {
                resolve && resolve(null);
            }}
            footer={
                <div style={{ float: 'right' }}>
                    <Button
                        icon={<Done />}
                        disabled={saving}
                        text={!skillCategory ? langStrings.create : langStrings.save}
                        size={'normal'}
                        onClick={onSave}
                    />
                </div>
            }
        >
            <div className="skillcategoryDialog">
                <div className="newSkillCategory">
                    <RowElement title={langStrings.skillcategoryName} alignTitle="left">
                        <TextInput
                            value={skillcategoryName}
                            isAI
                            onChange={setSkillCategoryName}
                        />
                    </RowElement>
                    <RowElement title={langStrings.description} alignTitle="left">
                        <FormatedTextInput value={description} onChange={setDescription} />
                    </RowElement>
                    <RowElement title={langStrings.parentCategory} alignTitle='left'>
                        <SkillCategorySelect
                            selectedCategory={parentCategory ? parentCategory : -1}
                            onChange={setParentCategory}
                            hideBranche={skillCategory?.id}
                            skillCategories={skillCategories}
                        />
                    </RowElement>
                    <RowElement title={langStrings.skills} alignTitle='left'>
                        <SkillsInputWithoutLevel allSkills={allSkills} skills={skills} showMode={false} size={'small'}
                            onChange={function (val: { title: string; id: number; }[]): void
                            {
                                setSkills(val);
                            }} />
                    </RowElement>
                </div>
                {errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>}
            </div>
        </Dialog>
    );
}

export default SkillCategoryDialog;