import * as React from 'react';
import EnhancedTable, { SortOrder } from '@src/App/NewLayout/Components/DataTable/DataTable';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { useSelector } from 'react-redux';
import { Delete, GetApp, MoreVert, Upload } from '@mui/icons-material';
import { Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { EEntityType, EWebsocketTopics } from '@sharedInterfaces/globalEnums';
import { WebSocketClient } from '@src/APIs/WebSockets/WebSocketClient';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import { TAttachmentUploadResponse } from '@sharedInterfaces/IAttachment';
import Attachment from '@src/Objects/Attachment';
import getAttachments from '@src/APIs/graphQl/Attachment/getAttachments';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import { getEmployee, RenderEmployee } from '@sharedReact/Pages/EntityPage/VersionInfoDialog';
import getAttachmentDownloadURL from '@src/APIs/graphQl/Attachment/getAttachmentDownloadURL';
import deleteAttachment from '@src/APIs/graphQl/Attachment/deleteAttachment';

import { uploadFile } from '../Dialogs/AnalyseSkillsDialog/AnalyseCvDialog';
import { useConfirmDeleteDialog } from '../Dialogs/ConfirmDelete/confirmDelete';

import styles from './AttachmentsTable.module.css';

interface AttachmentsTableProps
{
    entityId: number,
    entityType: EEntityType,
}


function AttachmentsTable({ entityId, entityType }: AttachmentsTableProps)
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).attachments;
    const employees = useSelector((state: AppState) => state.company.allEmployees);
    const [attachments, setAttachments] = React.useState<Attachment[] | null>(null);

    const isDense = false;


    React.useEffect(() =>
    {
        getAttachments(entityType, entityId)
            .then((data) =>
            {
                setAttachments(data);
            })
            .catch((ex) =>
            {
                setAttachments([])
                showErrorDialog(ex);
            })
    }, [entityId, entityType])


    const onDelete = (attachmentId: string) =>
    {
        setAttachments((prevAttachments) => prevAttachments ? prevAttachments.filter((a) => a.id !== attachmentId) : null);
    }

    const rows = React.useMemo(() =>
    {
        if (!attachments) return []
        return attachments.map((attachment, index) =>
        {
            const [createdByTitle, createdByLink, createdByTextTitle] = getEmployee(employees, attachment.createdBy, langStrings);
            const [changedByTitle, changedByLink, changedByTextTitle] = getEmployee(employees, attachment.changedBy, langStrings);

            return ({
                id: index, // Numerische ID für EnhancedTable
                attachmentId: attachment.id, // Originale Anhangs-ID
                title: attachment.title,
                description: attachment.description,
                actions: {
                    orderKey: '',
                    value: <AttachmentActionsMenu
                        attachment={attachment}
                        onDelete={onDelete}
                    />,
                },
                fileType: attachment.fileType,
                version: attachment.version.toString(),
                createdBy: {
                    orderKey: createdByTextTitle,
                    value: <RenderEmployee value={createdByTitle} link={createdByLink} />,
                },
                createdAt: attachment.createdAt ? new Date(attachment.createdAt) : undefined,
                changedBy: {
                    orderKey: changedByTextTitle,
                    value: <RenderEmployee value={changedByTitle} link={changedByLink} />,
                },
                changedAt: attachment.changedAt ? new Date(attachment.changedAt) : undefined,
            });
        });
    }, [attachments]);

    const addAttachment = (attachment: Attachment) =>
    {
        setAttachments((prevAttachments) => [...prevAttachments ? prevAttachments.filter(a => a.id !== attachment.id) : [], attachment]);
    }
    const updateAttachment = (attachment: Attachment) =>
    {
        setAttachments((prevAttachments) => prevAttachments ? prevAttachments.map(a => a.id === attachment.id ? attachment : a) : []);
    }

    return (
        <div className={styles.outerDiv}>
            <UploadButton
                entityId={entityId}
                entityType={entityType}
                disabled={!attachments}
                addAttachment={addAttachment}
                updateAttachment={updateAttachment}
            />
            <EnhancedTable
                id={'attachments'}
                rows={rows}
                noCheckbox
                fields={[
                    {
                        id: 'actions',
                        label: '',
                        disablePadding: true,
                        align: 'center',
                        type: 'JSX.Element',
                    },
                    {
                        id: 'title',
                        label: langStrings.oftenUsed.filename,
                        disablePadding: isDense,
                        align: 'left',
                        type: 'string',
                    },
                    {
                        id: 'description',
                        label: langStrings.oftenUsed.description,
                        disablePadding: isDense,
                        align: 'left',
                        type: 'string',
                    },
                    {
                        id: 'fileType',
                        label: langStrings.fileType,
                        disablePadding: isDense,
                        align: 'left',
                        type: 'string',
                    },
                    {
                        id: 'version',
                        label: langStrings.oftenUsed.version,
                        disablePadding: true,
                        align: 'left',
                        type: 'string',
                    },
                    {
                        id: 'changedBy',
                        label: langStrings.oftenUsed.changedBy,
                        disablePadding: true,
                        align: 'left',
                        type: 'JSX.Element',
                    },
                    {
                        id: 'changedAt',
                        label: langStrings.oftenUsed.changedAt,
                        disablePadding: true,
                        align: 'left',
                        type: 'date',
                    },
                    {
                        id: 'createdBy',
                        label: langStrings.oftenUsed.createdBy,
                        disablePadding: true,
                        align: 'left',
                        type: 'JSX.Element',
                    },
                    {
                        id: 'createdAt',
                        label: langStrings.oftenUsed.createdAt,
                        disablePadding: true,
                        align: 'left',
                        type: 'date',
                    },
                ]}
                selected={[]}
                setSelected={() => { }}
                orderBy='changedAt'
                order={SortOrder.DESC}
            />
            {/* <ExpandableText>
                <>
                    <Typography variant='h6'>Debug</Typography>
                    <pre>
                        {JSON.stringify(attachments, null, 2)}
                    </pre>
                </>
            </ExpandableText> */}
        </div>
    );
}




function AttachmentActionsMenu({ attachment, onDelete }: { attachment: Attachment, onDelete: (id: string) => void })
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).attachments;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () =>
    {
        setAnchorEl(null);
    };

    const handleDownload = () =>
    {
        getAttachmentDownloadURL(attachment.id)
            .then((url) =>
            {
                window.open(url, '_blank');
            })
            .catch((ex) =>
            {
                showErrorDialog(ex);
            })
        handleClose();
    };

    const handleDelete = () =>
    {
        deleteAttachment(attachment.id)
            .then(() =>
            {
                onDelete(attachment.id)
            })
            .catch((ex) =>
            {
                showErrorDialog(ex);
            })
        handleClose();
    };

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls={`attachment-menu-${attachment.id}`}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id={`attachment-menu-${attachment.id}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleDownload}>
                    <ListItemIcon>
                        <GetApp fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{langStrings.oftenUsed.download}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{langStrings.oftenUsed.delete}</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}



interface UploadButtonProps
{
    entityId: number,
    entityType: EEntityType,
    disabled?: boolean,
    addAttachment: (attachment: Attachment) => void,
    updateAttachment: (attachment: Attachment) => void,
}
function UploadButton({ entityId, entityType, disabled, addAttachment, updateAttachment }: UploadButtonProps)
{
    const showErrorDialog = useErrorDialog();
    const confirmDelete = useConfirmDeleteDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).attachments;
    const [uploading, setUploading] = React.useState(false);
    const [dragOver, setDragOver] = React.useState(false);
    const [currentFile, setCurrentFile] = React.useState<File | null>(null);

    const topic = EWebsocketTopics.attachmentUpload;

    React.useEffect(() =>
    {
        const subKey = WebSocketClient.subscripe<TAttachmentUploadResponse>(topic, async (data) =>
        {
            switch (data.type)
            {
                case 'success':
                    if (!data.attachment) return showErrorDialog(new Error('No data returned'));
                    addAttachment(new Attachment(data.attachment));
                    setCurrentFile(null);
                    setUploading(false);
                    break;
                case 'update':
                    if (!data.attachment) return showErrorDialog(new Error('No data returned'));
                    updateAttachment(new Attachment(data.attachment));
                    break;
                case 'url':
                    if (!currentFile)
                    {
                        setUploading(false);
                        return showErrorDialog(new Error('File not found'));
                    }
                    await uploadFile(data, currentFile)
                        .catch(ex =>
                        {
                            showErrorDialog(ex, "Error uploading file");
                            setUploading(false);
                            setCurrentFile(null);
                            return;
                        });
                    break;
                case 'error':
                    if (data.error === 'File already exists')
                    {
                        confirmDelete(currentFile?.name || '', langStrings.confirmOverride, langStrings.oftenUsed.override, langStrings.confirmOverrideTitle)
                            .then((confirm) =>
                            {
                                if (confirm && currentFile)
                                {
                                    onUpload(currentFile, true);
                                } else
                                {
                                    setCurrentFile(null);
                                    setUploading(false);
                                }
                            });
                    } else
                    {
                        showErrorDialog(new Error(data.error));
                        setCurrentFile(null);
                        setUploading(false);
                    }
                    return;
            }
        });
        return () =>
        {
            return WebSocketClient.unsubscripe(subKey);
        };
    }, [currentFile]);


    React.useEffect(() =>
    {
        if (!currentFile)
        {
            const button = document.getElementById('fileButton') as HTMLInputElement
            if (button)
            {
                button.files = null;
            }
        }
    }, [currentFile])

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) =>
    {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0)
        {
            const file = e.dataTransfer.files[0];
            setCurrentFile(file);
            onUpload(file);
            e.dataTransfer.clearData();
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) =>
    {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) =>
    {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(false);
    };

    const onUploadClick = () =>
    {
        document.getElementById('fileButton')?.click();
    };

    const onUpload = (file: File, override = false) =>
    {
        const fileName = file.name;
        setUploading(true);
        WebSocketClient.sendMessage(topic, { entityId, entityType, fileName, override });
    };

    return (
        <div
            className={`${styles.uploadArea} ${dragOver ? styles.dragOver : ''}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
        >
            <input
                type="file"
                className={styles.fileButton}
                id="fileButton"
                onChange={async (e) =>
                {
                    if (e.target.files)
                    {
                        const tempFile = e.target.files[0];
                        if (tempFile === undefined)
                        {
                            return;
                        }
                        setCurrentFile(tempFile);
                        onUpload(tempFile);
                    }
                }}
                accept=".doc,.docx,.pdf,.jpg,.jpeg,.png,.gif,.bmp,.tiff,.svg"
            />
            <Button
                variant={'contained'}
                startIcon={<Upload />}
                disabled={disabled || uploading}
                className={styles.uploadButton}
                onClick={onUploadClick}
            >
                {langStrings.uploadAttachment}
            </Button>
            {uploading && <LoadingBox text={langStrings.uploading} />}
            <Typography variant="body2" className={styles.dragAndDropText}>
                {langStrings.dragAndDropFilesHere}
            </Typography>
        </div>
    );
}

export default AttachmentsTable;


