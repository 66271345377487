import React from 'react';
import { Close } from "@mui/icons-material";
import { Card, ListItem, ListItemText, Typography, IconButton } from "@mui/material";
import { ENotificationType, INotification } from "@sharedInterfaces/INotification";

import ExpandableText from '../ExpandableText/ExpandableText';

interface NotificationItemProps
{
    notification: INotification;
    onDelete: (id: string | number) => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ notification, onDelete }) =>
{
    return (
        <Card variant="outlined" style={{ marginBottom: '10px', backgroundColor: notification.notificationType === ENotificationType.ERROR ? '#ffe6e6' : '#e6f3ff' }}>
            <ListItem alignItems="center">
                <ListItemText
                    primary={
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Typography variant="h6" color="textPrimary">
                                {notification.title}
                            </Typography>
                            <IconButton
                                edge="end"
                                size='small'
                                style={{ marginTop: -16, marginRight: -10 }}
                                onClick={() => onDelete(notification.id)}>
                                <Close />
                            </IconButton>
                        </div>
                    }
                    secondary={
                        <>
                            <ExpandableText initialHeight={140}>
                                <div
                                    dangerouslySetInnerHTML={{ __html: notification.messageHTML }}
                                />
                            </ExpandableText>
                            <Typography
                                component="span"
                                variant="caption"
                                textAlign={'right'}
                                color="textSecondary"
                                style={{ display: 'block', marginTop: '0.5rem' }}
                            >
                                {new Date(notification.publishedAt).toLocaleString()}
                            </Typography>
                        </>
                    }
                    secondaryTypographyProps={{ component: 'div' }}
                />

            </ListItem>
        </Card>
    );
};

export default NotificationItem;
