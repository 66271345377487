// dialogsSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactElement } from 'react';

export interface DialogsState
{
    dialogs: DialogState[];
}

const initialState: DialogsState = {
    dialogs: [],
};

const dialogsSlice = createSlice({
    name: 'dialogs',
    initialState,
    reducers: {
        openDialog: (state, action: PayloadAction<DialogState>) =>
        {
            state.dialogs.push(action.payload);
        },
        closeDialog: (state, action: PayloadAction<string>) =>
        {
            state.dialogs = state.dialogs.filter(dialog => dialog.id !== action.payload);
        },
        updateDialog: (state, action: PayloadAction<{ id: string; changes: Partial<DialogState> }>) =>
        {
            const dialog = state.dialogs.find(d => d.id === action.payload.id);
            let updated = false;
            if (dialog)
            {
                const changes = action.payload.changes;
                if (changes.title && changes.title !== dialog.title)
                {
                    dialog.title = changes.title;
                    updated = true;
                } else if (changes.component)
                {
                    dialog.component = changes.component;
                    updated = true;
                } else if (changes.windowState && changes.windowState !== dialog.windowState)
                {
                    dialog.windowState = changes.windowState;
                    updated = true;
                } else if (changes.zIndex && changes.zIndex !== dialog.zIndex)
                {
                    dialog.zIndex = changes.zIndex;
                    updated = true;
                }
                // if (updated)
                // {
                //     console.log('updateDialog', action.payload)
                // }
            }
        },
        minimizeDialog: (state, action: PayloadAction<string>) =>
        {
            const dialog = state.dialogs.find(d => d.id === action.payload);
            if (dialog)
            {
                dialog.windowState = 'minimized';
            }
        },
        maximizeDialog: (state, action: PayloadAction<string>) =>
        {
            const dialog = state.dialogs.find(d => d.id === action.payload);
            if (dialog)
            {
                dialog.windowState = 'full';
            }
        },
        restoreDialog: (state, action: PayloadAction<string>) =>
        {
            const dialog = state.dialogs.find(d => d.id === action.payload);
            if (dialog)
            {
                dialog.windowState = 'normal';
            }
        },
        focusDialog: (state, action: PayloadAction<string>) =>
        {
            const dialog = state.dialogs.find(d => d.id === action.payload);
            if (dialog)
            {
                const currentMaxZIndex = state.dialogs.reduce((max, d) => Math.max(max, d.zIndex), 1301);
                if (dialog.zIndex < currentMaxZIndex)
                {
                    dialog.zIndex = currentMaxZIndex + 1;
                }
            }
        },
        // Sie können weitere Reducer wie maximizeDialog hinzufügen, falls erforderlich.
    },
});

export const {
    openDialog,
    closeDialog,
    updateDialog,
    minimizeDialog,
    restoreDialog,
    focusDialog,
    maximizeDialog,
} = dialogsSlice.actions;

export default dialogsSlice.reducer;



export type TDialogWindowState = 'normal' | 'full' | 'minimized';

export interface DialogState
{
    id: string;
    title: string;
    component: ReactElement;
    windowState: TDialogWindowState;
    zIndex: number;
    resolve?: (value?: unknown) => void;
}
