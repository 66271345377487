import { ELanguage } from "../languageHelper";
export const CONST_DEFAULT_STARTPAGE: keyof IStartPagesTexts = 'PROFILE_EDIT_FORECAST'


export interface IStartPagesTexts
{
    CERTIFICATES: string
    COMPETENCE: string
    PEOPLE: string
    PEOPLE_EMPLOYEES: string
    PEOPLE_ORGUNITS: string
    PROFILE_EDIT: string
    PROFILE_EDIT_ANALYSE: string
    PROFILE_EDIT_CERTIFICATES: string
    PROFILE_EDIT_DEVELOPMENT: string
    PROFILE_EDIT_FORECAST: string
    PROFILE_EDIT_SKILLS: string
    PROFILE_SHOW: string
    SALES: string
    SALES_PRODUCTS: string
    SALES_CUSTOMERS: string
    SALES_OPPORTUNITIES: string
    SALES_PROJECTS: string
    SKILLCATEGORIES: string
    SKILLS: string
}
type ILangData = {
    [lang in ELanguage]: IStartPagesTexts
}
export const startPagesTexts: ILangData = {
    'th': {
        CERTIFICATES: 'ใบรับรอง',
        COMPETENCE: 'ความสามารถ',
        PEOPLE: 'คน',
        PEOPLE_EMPLOYEES: 'พนักงาน',
        PEOPLE_ORGUNITS: 'หน่วยงาน',
        SALES_PRODUCTS: 'สินค้า',
        PROFILE_EDIT: 'แก้ไขโปรไฟล์',
        PROFILE_EDIT_ANALYSE: 'วิเคราะห์โปรไฟล์',
        PROFILE_EDIT_CERTIFICATES: 'แก้ไขใบรับรอง',
        PROFILE_EDIT_DEVELOPMENT: 'การศึกษาต่อ',
        PROFILE_EDIT_FORECAST: 'การวางแผนการขาย',
        PROFILE_EDIT_SKILLS: 'แก้ไขทักษะ',
        PROFILE_SHOW: 'แสดงโปรไฟล์',
        SALES: 'การขาย',
        SALES_CUSTOMERS: 'บริษัท',
        SALES_OPPORTUNITIES: 'คำขอ',
        SALES_PROJECTS: 'โครงการ',
        SKILLCATEGORIES: 'ประเภททักษะ',
        SKILLS: 'ทักษะ',
    },
    'en': {
        CERTIFICATES: 'Certificates',
        COMPETENCE: 'Competencies',
        PEOPLE: 'People',
        PEOPLE_EMPLOYEES: 'Employees',
        PEOPLE_ORGUNITS: 'Organizational Units',
        SALES_PRODUCTS: 'Products',
        PROFILE_EDIT: 'Edit Profile',
        PROFILE_EDIT_ANALYSE: 'Profile Analysis',
        PROFILE_EDIT_CERTIFICATES: 'Edit Certificates',
        PROFILE_EDIT_DEVELOPMENT: 'Continued Education',
        PROFILE_EDIT_FORECAST: 'Forecast Planning',
        PROFILE_EDIT_SKILLS: 'Edit Skills',
        PROFILE_SHOW: 'Show Profile',
        SALES: 'Sales',
        SALES_CUSTOMERS: 'Companies',
        SALES_OPPORTUNITIES: 'Requests',
        SALES_PROJECTS: 'Projects',
        SKILLCATEGORIES: 'Skill Categories',
        SKILLS: 'Skills',
    },
    'de': {
        CERTIFICATES: 'Zertifikate',
        COMPETENCE: 'Kompetenzen',
        PEOPLE: 'Menschen',
        PEOPLE_EMPLOYEES: 'Mitarbeiter',
        PEOPLE_ORGUNITS: 'Organisationseinheiten',
        SALES_PRODUCTS: 'Produkte',
        PROFILE_EDIT: 'Profil bearbeiten',
        PROFILE_EDIT_ANALYSE: 'Profilanalyse',
        PROFILE_EDIT_CERTIFICATES: 'Zertifikate bearbeiten',
        PROFILE_EDIT_DEVELOPMENT: 'Weiterbildung',
        PROFILE_EDIT_FORECAST: 'Forecast-Planung',
        PROFILE_EDIT_SKILLS: 'Fähigkeiten bearbeiten',
        PROFILE_SHOW: 'Profil anzeigen',
        SALES: 'Vertrieb',
        SALES_CUSTOMERS: 'Firmen',
        SALES_OPPORTUNITIES: 'Anfragen',
        SALES_PROJECTS: 'Projekte',
        SKILLCATEGORIES: 'Fähigkeitskategorien',
        SKILLS: 'Fähigkeiten',
    },
}