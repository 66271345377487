
import { ELanguage } from "../languageHelper";

import { oftenUsedTranslations } from "./oftenUsed";


export interface IRegistrationConfig
{
    permissionGroup: string;
    defaultRegistration: {
        title: string,
        text: string,
    },
    emailEndingRegistration: {
        title: string,
        text: string,
        endingsField: string
        errorMessage: string
    }
}
type ILangData = {
    [lang in ELanguage]: IRegistrationConfig
}
export const registrationConfigTexts: ILangData = {
    'th': {
        permissionGroup: oftenUsedTranslations['th'].permissionGroup,
        defaultRegistration: {
            title: "การลงทะเบียนมาตรฐาน",
            text: "ปกติแล้วผู้ใช้ใหม่ต้องถูกเพิ่มเข้าไปในแอปของเราโดยตรงก่อนที่จะสามารถลงทะเบียนได้ ซึ่งจำเป็นต้องให้ผู้ดูแลระบบป้อนชื่อและที่อยู่อีเมลของคุณเข้าไปในแอปเพื่อที่คุณจะสามารถเข้าสู่ระบบได้",
        },
        emailEndingRegistration: {
            title: "การลงทะเบียนอัตโนมัติผ่านท้ายทางอีเมล",
            text: 'เรามีวิธีการลงทะเบียนที่ง่ายขึ้นในปัจจุบัน ผู้ดูแลระบบสามารถบันทึกส่วนท้ายของอีเมลเฉพาะ (เช่น "@test.de" หรือ "@abcd.fu") ลงในระบบ หากอีเมลของคุณตรงกับส่วนท้ายที่บันทึกไว้ คุณสามารถลงทะเบียนได้โดยตรงโดยไม่ต้องเพิ่มเข้าไปในแอปก่อน',
            endingsField: "ส่วนท้ายของอีเมลที่ได้รับอนุญาต (@swadikap.go.th)",
            errorMessage: 'ส่วนท้ายของอีเมล "[ENDING]" ไม่ถูกต้อง',
        }
    },
    'en': {
        permissionGroup: oftenUsedTranslations['en'].permissionGroup,
        defaultRegistration: {
            title: "Default Registration",
            text: "New users typically need to be manually added in our app before they can register. This requires an administrator to input your name and email address in the app so you can log in.",
        },
        emailEndingRegistration: {
            title: "Automatic Registration via Email Endings",
            text: 'We now offer a simpler registration method. Administrators can store specific email endings (e.g., "@test.de" or "@abcd.fu") in the system. If your email matches one of these endings, you can register directly without being manually added first.',
            endingsField: "Allowed Email Endings (@swadikap.go.th)",
            errorMessage: 'The email ending "[ENDING]" is faulty.',
        }
    },
    'de': {
        permissionGroup: oftenUsedTranslations['de'].permissionGroup,
        defaultRegistration: {
            title: "Standardregistrierung",
            text: "Normalerweise müssen neue Nutzer zuerst manuell in der App erfasst werden, bevor sie sich registrieren können. Dies erfordert, dass ein Administrator Ihren Namen und Ihre E-Mail-Adresse in der App hinterlegt, damit du dich anmelden kannst.",
        },
        emailEndingRegistration: {
            title: "Automatische Registrierung über E-Mail-Endungen",
            text: 'Es gibt nun eine einfachere Registrierungsmethode. Administratoren können bestimmte E-Mail-Endungen (z.B. "@test.de" oder "@abcd.fu") im System speichern. Wenn deine E-Mail-Adresse eine dieser Endungen hat, kannst du dich direkt registrieren, ohne zuvor manuell hinzugefügt zu werden.',
            endingsField: "Erlaubte E-Mail-Endungen (@swadikap.go.th)",
            errorMessage: 'Die E-Mail Endung "[ENDING]" ist fehlerhaft.',
        }
    },
}