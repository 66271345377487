import React, { useRef } from 'react';
import './TextInput.css';
import TextField from '@mui/material/TextField';

interface TextInputProps
{
    value: string
    password?: true
    placeholder?: string
    disabled?: boolean
    helperText?: string
    size?: 'medium' | 'small' | 'verysmall'
    isAI?: true
    // generating?: string
    onChange?: (val: string) => void
    onBlur?: () => void
}

/**
 * TextInput component.
 * @param {Object} props - The component props.
 * @param {string} props.value - The value of the input.
 * @param {boolean} props.password - Indicates if the input is of type password.
 * @param {string} props.placeholder - The placeholder text for the input.
 * @param {boolean} props.disabled - Indicates if the input is disabled.
 * @param {number} props.size - The size of the input.
 * @param {string} props.helperText - The helper text for the input.
 * @param {function} props.onChange - The function called when the value of the input changes.
 * @returns {JSX.Element} The rendered TextInput component.
 */
function TextInput({ value, password, placeholder, disabled, size, helperText, isAI, onChange, onBlur, }: TextInputProps)
{
    const innerSize = size ? size : 'medium';
    const inputSize = innerSize === 'medium' || innerSize === 'small' ? innerSize : 'small';
    const inputRef = useRef<HTMLTextAreaElement>(null);

    React.useEffect(() =>
    {
        if (!inputRef.current) return
        inputRef.current.style.height = inputRef.current.scrollHeight + "px";

    }, [inputRef]);

    return (
        <div className='textInputContainer'>
            {onChange ?
                <TextField variant='outlined'
                    type={password ? 'password' : 'text'}
                    disabled={disabled}

                    size={inputSize}
                    margin='none'
                    color='primary'
                    value={value}
                    placeholder={placeholder}
                    helperText={helperText}
                    // error={helperText ? true : false} //TODO: Für Errors eigenes Property einbauen...
                    multiline={password ? undefined : true}
                    onBlur={onBlur}
                    // style={{
                    //     margin: 0
                    // }}
                    fullWidth
                    style={{}}
                    InputProps={
                        {
                            style: {
                                margin: 0,
                                padding: innerSize === 'verysmall' ? '0px 5px' : undefined,
                                backgroundColor: isAI && 'var(--var-ai-color)'
                            }
                        }
                    }
                    onChange={(event) =>
                    {
                        onChange && onChange(event.target.value)
                    }}
                /> :
                <div>
                    {value}
                </div>
            }
        </div>
    );
}

export default TextInput;
