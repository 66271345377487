import { OperationVariables, gql } from "@apollo/client";
import { IOrgUnitSalesDataDTO } from "@sharedInterfaces/IOrgUnit";

import GraphQL from "../graphQL";

import { GQL_OU_SALES_DATA_VIEW } from "./createOUSalesView";

const q = gql`
query getOrgUnitSalesData($ouId: Int!) {
    ouSalesData(id:  $ouId) {
    
        views ${GQL_OU_SALES_DATA_VIEW}
        employees{
            id
            title
            months{
                monthId
                source
                holidayHours
                title
                projects{
                    projectId
                    title
                    bookedHours
                    bookedNotBillableHours
                    bookedRevenue
                    plannedHours
                    plannedRevenue
                    totalPlannedHours
                    totalPlannedRevenue
                    internalProject
                }
            }
        }
    }
},
`;

/**
 * Retrieves sales data for a specified organizational unit.
 * @param {number} ouId - The ID of the organizational unit.
 * @param {(ou: IOrgUnitSalesDataDTO) => void} callback - The callback function to be executed when the sales data is retrieved.
 * @returns {Promise<{ ouSalesData: IOrgUnitSalesDataDTO; }>} A promise that resolves with the sales data object.
 */
export default async function getOrgUnitSalesData(ouId: number, callback: (ou: IOrgUnitSalesDataDTO) => void)
{
    const variables: OperationVariables = { ouId };
    return GraphQL.query<{ ouSalesData: IOrgUnitSalesDataDTO }>(q, variables, (obj) =>
    {
        // const ou = obj.ou as IOrgUnitDetail;
        // ou.id = Number.parseInt(<string><unknown>ou.id);
        if (!obj) return;
        return callback(obj.ouSalesData)
    });
}
