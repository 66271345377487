import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import './RateBar.css';
interface RateBarProps
{
    level: number
    name: string
    minLevel?: number
    style?: React.CSSProperties
    setValue: (number: number) => void;
}

const buttonSize = 50;

/**
 * RateBar component.
 *
 * @param {RateBarProps} props - The props for the RateBar component.
 * @returns {JSX.Element} The rendered RateBar component.
 */
function RateBar(props: RateBarProps)
{
    const levelDefinitions = useSelector((state: AppState) => state.levelDefinition);
    const elementRef = useRef<HTMLDivElement>(null);
    const [showSmallVersion, setShowSmallVersion] = useState(false);
    const maxLevel = levelDefinitions.length;
    function updateElementHeight()
    {
        if (elementRef.current)
        {
            const parentWidth = elementRef.current.clientWidth;
            const fittingButtons = (parentWidth / buttonSize);
            // console.log(`${parentWidth} / ${buttonSize} = ${fittingButtons}`)
            setShowSmallVersion(fittingButtons < maxLevel);
        }
    }
    useEffect(() =>
    {
        updateElementHeight();
        window.addEventListener("resize", updateElementHeight);
        return () =>
        {
            window.removeEventListener("resize", updateElementHeight);
        };
    }, [maxLevel]);
    const buttons = [];
    const options = [];
    const minLevelIndex = props.minLevel ? props.minLevel - 1 : 0
    for (let i = minLevelIndex; i < maxLevel; i++)
    {
        const title = levelDefinitions[i].title;
        //TODO: Classe setzen für focus
        const btnLevel = i + 1;
        buttons.push(
            <div
                title={title}
                className="rateButton"
                key={btnLevel}
                style={{ "--percent": (btnLevel / maxLevel) * 100 } as React.CSSProperties}
                onClick={(e) =>
                {
                    if (e.currentTarget.tagName.toLocaleLowerCase() !== 'radio')
                    {
                        const ele = e.currentTarget.querySelector('#level' + btnLevel)
                        if (ele)
                        {
                            (ele as HTMLInputElement).click();
                        }
                    }
                    // if (btnLevel !== props.level)
                    //     props.setValue(btnLevel)
                }}
            >
                <input
                    type="radio"
                    id={"level" + btnLevel}
                    name={'skill' + props.name}
                    onChange={() =>
                    {
                        if (btnLevel !== props.level)
                            props.setValue(btnLevel);
                    }}
                    checked={btnLevel === props.level}
                />
                {btnLevel}
            </div>
        );
        options.push(
            <option
                title={title}
                key={btnLevel}
                value={btnLevel}
                style={{ "--percent": (btnLevel / maxLevel) * 100 } as React.CSSProperties}
            >{title}</option>
        );
    }
    return (
        <div
            className={"RateBar" + (showSmallVersion ? ' small' : '')}
            ref={elementRef}
            style={props.style}
        >
            {!showSmallVersion && buttons}
            {showSmallVersion &&
                <select
                    className='rateBarInputElement'
                    value={props.level}
                    onChange={(e) => props.setValue(parseInt(e.target.value))}
                    style={{ "--percent": (props.level / maxLevel) * 100 } as React.CSSProperties}
                >
                    {
                        props.level === 0 && <option
                            title=''
                            key={0}
                            value={0}></option>
                    }
                    {options}
                </select>
            }

        </div>
    );
}

export default RateBar;
