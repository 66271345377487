import IAttachment from "@sharedInterfaces/IAttachment";
import { EEntityType } from "@sharedInterfaces/globalEnums";




export default class Attachment implements IAttachment
{
    type: EEntityType = EEntityType.ATTACHMENT;
    id: string;
    title: string;
    description: string;
    active: boolean;
    entityId: number;
    entityType: EEntityType;
    fileType: string;
    version: number;
    createdBy?: number | undefined;
    changedBy?: number | undefined;
    createdAt?: Date | undefined;
    changedAt?: Date | undefined;
    constructor(obj: IAttachment)
    {
        this.id = obj.id;
        this.active = obj.active;
        this.entityId = obj.entityId;
        this.entityType = obj.entityType;
        this.fileType = obj.fileType;
        this.version = obj.version;
        this.title = obj.title;
        this.description = obj.description;
        this.createdBy = obj.createdBy;
        this.changedBy = obj.changedBy;
        this.createdAt = obj.createdAt ? new Date(obj.createdAt) : undefined;
        this.changedAt = obj.changedAt ? new Date(obj.changedAt) : undefined;

    }


    async delete()
    {
        const { default: deleteAttachment } = await import("@src/APIs/graphQl/Attachment/deleteAttachment");
        return deleteAttachment(this.id);
    }
}
