import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import { AppState } from '@store/store';

import './EntitySearchBox.css';
type TFilterType = {
    id: number;
    title: string;
    otherNames?: string[];
};

interface EntitySearchBoxProps<T>
{
    filteredItems: TFilterType[];
    newItem: T;
    allowNew?: boolean;
    size?: 'medium' | 'small' | 'verysmall';
    clearOnSelect?: boolean;
    style?: React.CSSProperties;
    setNewItem: (newItem: T) => void;
    allItemsSelector: (state: AppState) => T[];
}

interface IListElement
{
    title: string;
    id?: number;
    otherNames?: string[];
}

function EntitySearchBox<T extends IListElement>(props: EntitySearchBoxProps<T>)
{
    const size = props.size ? props.size : 'medium';
    const inputSize = size === 'medium' || size === 'small' ? size : 'small';

    const allItems = useSelector(props.allItemsSelector);

    const [popperWidth, setPopperWidth] = useState<number | null>(null);
    const textFieldRef = useRef<HTMLDivElement | null>(null);

    useEffect(() =>
    {
        if (textFieldRef.current)
        {
            setPopperWidth(textFieldRef.current.offsetWidth);
        }
    }, [textFieldRef]);


    const onSetItem = (item: T) =>
    {
        props.setNewItem(item)
        if (props.clearOnSelect)
        {
            setTimeout(() =>
            {
                if (textFieldRef.current)
                {
                    const textField = textFieldRef.current.querySelector('input');
                    if (textField) textField.value = '';
                }
            }, 50);
        }
    }
    const allItemsList: IListElement[] = allItems.map(item => ({
        title: item.title,
        id: item.id,
        otherNames: item.otherNames,
    }));

    const suggestions: IListElement[] = props.filteredItems.map(item => ({
        title: item.title,
        id: item.id,
        otherNames: item.otherNames,
    }));

    return (
        <Autocomplete
            componentsProps={{
                popper: {
                    style: {
                        zIndex: '2000000000 !IMPORTANT',
                        minWidth: popperWidth ? popperWidth : '100%',
                        border: 'solid 1px #666',
                    },
                }
            }}
            size={inputSize}
            value={props.newItem.title}
            blurOnSelect={true}
            style={props.style}
            onChange={(_, newValue) =>
            {
                if (typeof newValue === 'string')
                {
                    const itemExists = allItemsList.some(option => option.title.toLowerCase() === newValue.toLowerCase());
                    const canBeAdded = suggestions.find(option => option.title.toLowerCase() === newValue.toLowerCase());
                    if (!itemExists)
                    {
                        if (!props.allowNew) return;
                        onSetItem({
                            ...props.newItem,
                            title: newValue,
                            id: undefined,
                        } as T);
                    } else if (canBeAdded)
                    {
                        onSetItem({
                            ...props.newItem,
                            title: canBeAdded.title,
                            id: canBeAdded.id
                        });
                    }
                } else if (newValue && newValue.id)
                {
                    const itemExists = allItemsList.find(option => option.title.toLowerCase() === newValue.title.toLowerCase());
                    onSetItem({
                        ...props.newItem,
                        ...newValue,
                        id: itemExists ? itemExists.id : undefined
                    });
                } else
                {
                    if (newValue?.title)
                    {
                        onSetItem({
                            ...props.newItem,
                            title: newValue.title,
                            id: undefined
                        });
                    } else
                    {
                        onSetItem({
                            ...props.newItem,
                            title: '',
                            id: undefined
                        });
                    }
                }
            }}
            filterOptions={(options, params) =>
            {
                const { inputValue } = params;
                const inputLowerCase = inputValue.toLowerCase();
                const filtered = options.filter(o =>
                {
                    return o.title.toLowerCase().includes(inputLowerCase) ||
                        (o.otherNames && o.otherNames.some(other => other.includes(inputLowerCase)));
                });
                const isExisting = allItemsList.some(option =>
                    option.title.toLowerCase() === inputLowerCase ||
                    (option.otherNames && option.otherNames.some(other => other.toLowerCase() === inputLowerCase))
                );
                if (inputValue !== '' && !isExisting && props.allowNew)
                {
                    filtered.push({
                        ...props.newItem,
                        title: inputValue,
                        label: `Add "${inputValue}"`,
                        otherNames: [],
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={suggestions}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.title}
            renderOption={(props, option) => <li {...props} style={{ borderBottom: 'solid 1px #ddd' }}>{option.title}</li>}
            sx={{ width: '100%' }}
            freeSolo
            renderInput={(params) => (
                <TextField
                    {...params}
                    ref={textFieldRef}
                    InputProps={{
                        ...params.InputProps,
                        className: 'entitySearchBoxInput',
                        style: { margin: 0, padding: size === 'verysmall' ? '0px 5px' : undefined },
                    }}
                />
            )}
        />
    );
}

export default EntitySearchBox;
