import { gql } from "@apollo/client";
import { MergeCertificatesRequestDTO, MergeCertificatesResponseDTO } from "@sharedInterfaces/ICertificate";
import { CertificateGQL } from "@src/Objects/Certificate";

import GraphQL from "../graphQL";

const query = gql`
mutation mergeCertificates($certificateIds: [Int!]!, $certificate: MergeCertificateInput!) {
    mergeCertificates(certificateIds: $certificateIds ,certificate: $certificate) 
    {
    newCertificate ${CertificateGQL}
    otherNames
    removed
  }
},
`;

export default async function mergeCertificates(certificate: { id: number, title: string, description: string },
    certificateIds: number[])
{
    const variables: MergeCertificatesRequestDTO = {
        certificate,
        certificateIds,
    };
    const response = await GraphQL.mutate<MergeCertificatesResponseDTO>(query, variables);
    response.newCertificate.id = parseInt(response.newCertificate.id.toString())
    return response;
}