import { OperationVariables, gql } from "@apollo/client";
import IProduct from "@sharedInterfaces/IProduct";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { deleteProduct, updateProduct } from "@store/reducer/productReducer";
import Product, { productGQL } from "@src/Objects/Product";

import GraphQL from "../graphQL";
import { NotFoundError } from "../NotFoundError";

const q = gql`
query GetProduct($productId: ID!) {
    product(id: $productId) 
    ${productGQL}
},
`;

/**
 * Retrieves a product by its ID, then updates the Redux store.
 *
 * @param {number} productId - The ID of the product to retrieve.
 * @returns {Promise<Product>} A promise that resolves with the retrieved product.
 */
export default async function getProduct(productId: number)
{
    const variables: OperationVariables = { productId };
    return GraphQL.query<{ product: IProduct }>(q, variables).then((obj) =>
    {
        if (!obj?.product)
        {
            store.dispatch(deleteProduct(productId));
            throw new NotFoundError(productId, EEntityType.PRODUCT);
        }
        const product = new Product(obj.product);
        store.dispatch(updateProduct(product));
        return product;
    });
}
