import React from 'react';

import SearchBox from '../SearchBox/SearchBox';
import './EmployeeSearchBox.css';

interface EmployeeSearchBoxEmployee
{
    id: number
    title: string
}
interface EmployeeSearchBoxProps<T extends EmployeeSearchBoxEmployee>
{
    allEmployees: T[]
    onSelectedEmployee: (newEmployee: T) => void;
}

/**
 * EmployeeSearchBox component.
 * 
 * @param {EmployeeSearchBoxProps} props - The props for the EmployeeSearchBox component.
 * @returns {JSX.Element} - The rendered EmployeeSearchBox component.
 */
function EmployeeSearchBox<T extends EmployeeSearchBoxEmployee>(props: EmployeeSearchBoxProps<T>)
{
    return (
        <div className='employeeSearchBox'>
            <SearchBox elements={props.allEmployees} onSelect={function (selectedElement): void
            {
                props.onSelectedEmployee(selectedElement as T);
            }} />
        </div>
    );
}

export default EmployeeSearchBox;
