import { OperationVariables, gql } from "@apollo/client";
import IRoleDetailDTO, { IRoleInput } from "@sharedInterfaces/IRole";
import Role, { RoleGQL } from "@src/Objects/Role";

import GraphQL from "../graphQL";

import { prepairInputRole } from "./createRole";


const query = gql`
mutation editRole(
        $id:Int!,
        $inputRole: RoleInput!,
    ) {
    editRole(
        id: $id,
        inputRole: $inputRole,
    ) 
    ${RoleGQL}
},
`;


/**
* Edits a role.
* @param {number} id - The id of the role.
* @param {IRoleInput} inputRole - The updated role input.
* @returns {Promise<Role>} - A promise that resolves with the edited role.
*/
export default async function editRole(id: number, inputRole: IRoleInput)
{
    const variables: OperationVariables = {
        id,
        inputRole: prepairInputRole(inputRole)
    };
    return GraphQL.mutate<IRoleDetailDTO>(query, variables)
        .then(s => new Role(s));
}