import { OperationVariables, gql } from "@apollo/client";
import ICertificate from "@sharedInterfaces/ICertificate";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { deleteCertificate, updateCertificate } from "@store/reducer/certificatesReducer";
import Certificate, { CertificateGQL } from "@src/Objects/Certificate";

import GraphQL from "../graphQL";
import { NotFoundError } from '../NotFoundError';


const q = gql`
query GetCertificate($certificateId: ID!) {
    certificate(id: $certificateId) 
    ${CertificateGQL}
},
`;


/**
 * Retrieves a certificate by certificateId.
 *
 * @param {number} certificateId - The ID of the certificate to retrieve.
 * @returns {Promise<Certificate>} A promise that resolves with the retrieved certificate.
 */
export default async function getCertificate(certificateId: number)
{
    const variables: OperationVariables = { certificateId };
    return GraphQL.query<{ certificate: ICertificate }>(q, variables).then((obj) =>
    {
        if (!obj?.certificate)
        {
            store.dispatch(deleteCertificate(certificateId));
            throw new NotFoundError(certificateId, EEntityType.CERTIFICATE)
        }
        const newCertificate = new Certificate(obj.certificate);
        store.dispatch(updateCertificate(newCertificate));
        return newCertificate;
    })
}
