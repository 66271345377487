import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { ESuggestionCycle, ESuggestionRequestType, ESuggestionResponseType, TSuggestion, TSuggestionsRequest, TSuggestionsResponse } from '@sharedInterfaces/ISuggestion';
import { Card, CardContent, Typography, Box, Grid, Avatar, Button, IconButton, Checkbox, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { entityTypeToIcon } from '@src/helper/tsxHelper';
import { EEntityType, entityTypeToLink, entityTypeToString, EWebsocketTopics } from '@sharedInterfaces/globalEnums';
import { WebSocketClient } from '@src/APIs/WebSockets/WebSocketClient';
import { Delete, Refresh, Settings } from '@mui/icons-material';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import { NavLink } from 'react-router-dom';
import { useDialog } from '@sharedReact/Dialog/DialogManager';
import MergeSkillsDialog from '@src/Components/Dialogs/MergeSkillsDialog/MergeSkillsDialog';
import DeclineSuggestionDialog from '@src/Components/Dialogs/DeclineSuggestionDialog/DeclineSuggestionDialog';
import GenericOverlay from '@src/Components/Overlays/GenericOverlay/GenericOverlay';
import MergeCertificatesDialog from '@src/Components/Dialogs/MergeCertificatesDialog/MergeCertificatesDialog';
import SuggestionRejectionsManageDialog from '@src/Components/Dialogs/SuggestionRejectionsManageDialog/SuggestionRejectionsManageDialog';
import MergeRolesDialog from '@src/Components/Dialogs/MergeRolesDialog/MergeRolesDialog';
import RowElement from '@sharedReact/General/Forms/RowElement/RowElement';
const topic = EWebsocketTopics.SUGGESTIONS;

export function SuggestionsTab()
{
    const { openDialog } = useDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [suggestions, setSuggestions] = React.useState<TSuggestion[] | null>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [notificationEnabled, setNotificationEnabled] = React.useState<boolean | null>(null);
    const [suggestionFrequency, setSuggestionFrequency] = React.useState<ESuggestionCycle | null>(null);


    React.useEffect(() =>
    {
        const request: TSuggestionsRequest = { type: ESuggestionRequestType.GET_INIT_SUGGESTIONS };
        WebSocketClient.sendMessage(topic, request);
    }, []);

    React.useEffect(() =>
    {
        const subKey = WebSocketClient.subscripe<TSuggestionsResponse>(topic, async (data) =>
        {
            switch (data.type)
            {
                case ESuggestionResponseType.SAVED_SETTINGS:
                    setLoading(false);
                    break;
                case ESuggestionResponseType.INIT_SUGGESTIONS:
                    setSuggestionFrequency(data.settings.cycle);
                    setNotificationEnabled(data.settings.notifyAdmins);
                    setSuggestions(data.suggestions);
                    setLoading(false);
                    break;
                case ESuggestionResponseType.SUGGESTIONS:
                    setSuggestions(data.suggestions);
                    setLoading(false);
                    break;
            }
        });
        return () =>
        {
            return WebSocketClient.unsubscripe(subKey);
        };
    }, []);

    const onRefresh = () =>
    {
        setLoading(true);
        const request: TSuggestionsRequest = { type: ESuggestionRequestType.REFRESH_SUGGESTIONS };
        WebSocketClient.sendMessage(topic, request);
    };

    function onRejectionSettings(): void
    {
        openDialog(<SuggestionRejectionsManageDialog
            id="RejectionSettingsDialog"
            resolve={() => { }}
        />)
    }
    const handleFrequencyChange = (event: React.MouseEvent<HTMLElement>, newFrequency: ESuggestionCycle | null) =>
    {
        if (notificationEnabled === null) return
        if (newFrequency !== null)
        {
            setSuggestionFrequency(newFrequency);
            setLoading(true);
            const request: TSuggestionsRequest = {
                type: ESuggestionRequestType.SET_SUGGESTION_SETTINGS, settings: {
                    cycle: newFrequency,
                    notifyAdmins: notificationEnabled,
                }
            };
            WebSocketClient.sendMessage(topic, request);
        }
    };
    const handleNotificationChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        if (suggestionFrequency === null) return
        setNotificationEnabled(event.target.checked);
        setLoading(true);
        const request: TSuggestionsRequest = {
            type: ESuggestionRequestType.SET_SUGGESTION_SETTINGS, settings: {
                cycle: suggestionFrequency,
                notifyAdmins: event.target.checked,
            }
        };
        WebSocketClient.sendMessage(topic, request);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Paper elevation={0} sx={{ padding: 0, marginBottom: 1 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                        gap: 2,
                        flexWrap: 'wrap',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {suggestionFrequency && (
                            <RowElement title={langStrings.suggestionFrequency} alignTitle='center'>
                                <ToggleButtonGroup
                                    title={langStrings.suggestionFrequency}
                                    value={suggestionFrequency}
                                    exclusive
                                    onChange={handleFrequencyChange}
                                    size="small"
                                    disabled={loading}
                                // sx={{ minWidth: 180 }}
                                >
                                    <ToggleButton value={ESuggestionCycle.NEVER}>{langStrings.oftenUsed.never}</ToggleButton>
                                    <ToggleButton value={ESuggestionCycle.WEEKLY} color='primary'>{langStrings.oftenUsed.weekly}</ToggleButton>
                                </ToggleButtonGroup>
                            </RowElement>
                        )}
                        {notificationEnabled !== null && (
                            <RowElement title={langStrings.notifyAdmins} alignTitle='center'>
                                <Checkbox
                                    size="small"
                                    checked={notificationEnabled === true}
                                    disabled={loading}
                                    onChange={handleNotificationChange}
                                />
                            </RowElement>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifySelf: 'flex-end' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onRejectionSettings}
                            disabled={loading}
                            endIcon={<Settings />}
                        >
                            {langStrings.rejectionSettings}
                        </Button>
                        <IconButton
                            color="primary"
                            title={langStrings.oftenUsed.refresh}
                            onClick={onRefresh}
                            disabled={loading}
                        >
                            <Refresh />
                        </IconButton>
                    </Box>
                </Box>
            </Paper>
            <Grid container spacing={2} justifyContent="stretch">
                {suggestions ?
                    (suggestions.length > 0 ?
                        suggestions.map((suggestion) => (
                            <Grid item xs={12} sm={6} md={4} key={suggestion.id} sx={{ display: 'flex' }}>
                                <SuggestionCard suggestion={suggestion}
                                // refresh={onRefresh}
                                />
                            </Grid>
                        ))
                        :
                        <Grid item xs={12}>
                            <Typography variant="body1" align="center">
                                {langStrings.noSuggestions}
                            </Typography>
                        </Grid>
                    )
                    : <LoadingBox />}
            </Grid>
        </Box>
    );
}

interface SuggestionCardProps
{
    suggestion: TSuggestion;
}

const SuggestionCard: React.FC<SuggestionCardProps> = ({ suggestion }) =>
{
    const { openDialog } = useDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const { newTitle, duplicates, entityType, reason } = suggestion;

    const [disabled, setDisabled] = React.useState<boolean>(false);

    const icon = entityTypeToIcon(entityType);
    const entityName = entityTypeToString(lang, suggestion.entityType);

    const onDelete = () =>
    {
        setDisabled(true);
        WebSocketClient.sendMessage(topic, {
            type: ESuggestionRequestType.DELETE_SUGGESTION, id: suggestion.id
        });
    };

    const onMerge = () =>
    {
        switch (entityType)
        {
            case EEntityType.SKILL:
                openDialog(
                    <MergeSkillsDialog
                        id="MergeSkillsDialog"
                        skillIds={suggestion.duplicates.map(d => d.id)}
                        resolve={(result) =>
                        {
                            console.log("result", result);
                            if (result)
                            {
                                onDelete();
                            }
                        }}
                    />
                );
                break;
            case EEntityType.CERTIFICATE:
                openDialog(
                    <MergeCertificatesDialog
                        id="MergeCertificatesDialog"
                        certificateIds={suggestion.duplicates.map(d => d.id)}
                        resolve={(result) =>
                        {
                            console.log("result", result);
                            if (result)
                            {
                                onDelete();
                            }
                        }}
                    />
                )
                break;
            case EEntityType.ROLE:
                openDialog(
                    <MergeRolesDialog
                        id="MergeRolesDialog"
                        roleIds={suggestion.duplicates.map(d => d.id)}
                        resolve={(result) =>
                        {
                            console.log("result", result);
                            if (result)
                            {
                                onDelete();
                            }
                        }}
                    />
                )
                break;
            default:
                console.error("Unknown entity type: " + entityType);
                break;
        }

    }

    const onDecline = () =>
    {
        openDialog(
            <DeclineSuggestionDialog
                id="DeclineSuggestionDialog"
                type={entityType}
                reason={suggestion.reason}
                duplicates={suggestion.duplicates}
                resolve={(reason) =>
                {
                    if (reason)
                    {
                        setDisabled(true);
                        WebSocketClient.sendMessage(topic, {
                            type: ESuggestionRequestType.DECLINE_SUGGESTION,
                            id: suggestion.id,
                            reason: reason
                        });
                    }
                }} />
        );
    };

    return (
        <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <Avatar sx={{ marginRight: 2 }}
                        title={entityName}
                    >
                        {icon}
                    </Avatar>
                    <Typography variant="h6" component="div">
                        {newTitle}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                    {reason}
                </Typography>
                <Box >
                    {duplicates.map((duplicate, index) => (
                        <Box key={duplicate.id} sx={{
                            marginBottom: index === duplicates.length - 1 ? 0 : 2,
                            padding: 1,
                            border: '1px solid #e0e0e0',
                            borderRadius: 1,
                        }}>
                            <Typography variant="subtitle1" component="div">
                                <GenericOverlay entityId={duplicate.id} entityType={entityType}>
                                    <NavLink to={entityTypeToLink(duplicate.id, suggestion.entityType)}>{duplicate.title}</NavLink>
                                </GenericOverlay>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {langStrings.oftenUsed.otherNames}: {duplicate.otherNames && duplicate.otherNames.length > 0 ? duplicate.otherNames.join(', ') : langStrings.notSet}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    disabled={disabled}
                    title={langStrings.declineSuggestion}
                    onClick={onDecline}
                >
                    {langStrings.oftenUsed.decline}
                </Button>
                <IconButton
                    title={langStrings.oftenUsed.delete}
                    disabled={disabled}
                    onClick={onDelete}
                >
                    <Delete />
                </IconButton>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={disabled}
                    title={langStrings.acceptSuggestion}
                    onClick={onMerge}
                >
                    {langStrings.oftenUsed.accept}
                </Button>
            </Box>
        </Card>
    );
};
