import { OperationVariables, gql } from "@apollo/client";
import ICompetence from "@sharedInterfaces/ICompetence";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { deleteCompetence, updateCompetence } from "@store/reducer/competenceReducer";
import Competence, { competenceGQL } from "@src/Objects/Competence";

import GraphQL from "../graphQL";
import { NotFoundError } from '../NotFoundError';


const q = gql`
query GetCompetence($competenceId: ID!) {
    competence(id: $competenceId) 
    ${competenceGQL}
},
`;

/**
 * Retrieves a competence by its ID, then updates the Redux store.
 *
 * @param {number} competenceId - The ID of the competence to retrieve.
 * @returns {Promise<Competence>} A promise that resolves with the retrieved competence.
 */
export default async function getCompetence(competenceId: number)
{
    const variables: OperationVariables = { competenceId };
    return GraphQL.query<{ competence: ICompetence }>(q, variables).then((obj) =>
    {
        if (!obj?.competence)
        {
            store.dispatch(deleteCompetence(competenceId));
            throw new NotFoundError(competenceId, EEntityType.COMPETENCE);
        }
        const competence = new Competence(obj.competence);
        store.dispatch(updateCompetence(competence));
        return competence;
    });
}
