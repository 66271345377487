import { Delete, Edit } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICompanyUser } from '@sharedInterfaces/ICompanySettings';
import { IPermissionGroup } from '@sharedInterfaces/IPermissions';
import { generateFullName } from '@sharedInterfaces/globalHelper';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import { OU } from '@src/Objects/Company';

import OUSelect from '../../formsControls/inputs/OUSelect/OUSelect';
import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import PermissionGroupSelect from '../../formsControls/inputs/PermissionGroupSelect/PermissionGroupSelect';
import GenderSelect from '../../formsControls/inputs/GenderSelect/GenderSelect';


interface EditEmployeeDialogProps
{
    id: string
    OUs: OU[];
    permissionGroups: IPermissionGroup[];
    employee: ICompanyUser;
    resolve?: () => void;
    onSave: (val: ICompanyUser, ouId: number) => Promise<unknown>;
    onDelete: (val: ICompanyUser) => Promise<unknown>;
}
/**
 * EditEmployeeDialog component.
 *
 * @param {EditEmployeeDialogProps} props - The props object.
 *
 * @returns {JSX.Element} The rendered EditEmployeeDialog component.
 */
export function EditEmployeeDialog(props: EditEmployeeDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).userDialog;
    const defaultOU = props.OUs.find(o => o.employees.find(e => e.id === props.employee.id)) as OU;
    const actualPermissionGroup = props.permissionGroups.find(pg => pg.id === props.employee.permissionGroup?.id);
    const empId = useSelector((state: AppState) => state.employee.id);
    const isMe = props.employee.id === empId;

    const [saving, setSaving] = React.useState(false as boolean);
    const [editEmployee, setEditEmployee] = React.useState(props.employee as ICompanyUser);
    const [error, setError] = React.useState("");
    const [selectedOU, setSelectedOU] = React.useState(defaultOU);

    return <Dialog
        id={props.id}
        title={langStrings.editEmployee + generateFullName(props.employee.firstname, props.employee.lastname)}
        footer={<div style={{ float: 'right', display: 'flex', gap: 10 }}>
            <Button
                icon={<Delete />}
                disabled={saving}
                text={langStrings.delete}
                size={'normal'}
                onClick={async function (): Promise<void>
                {
                    const conf = window.confirm(langStrings.sureDelete);
                    if (!conf) return;
                    setSaving(true);
                    await props.onDelete(editEmployee)
                        .then(props.resolve)
                        .catch(ex =>
                        {
                            setError(ex.toString());
                            setTimeout(() =>
                            {
                                setSaving(false);
                            }, 3000);

                        });
                }} />
            <Button
                icon={<Edit />}
                disabled={saving}
                text={langStrings.edit}
                size={'normal'}
                onClick={async function (): Promise<void>
                {
                    if (editEmployee.firstname === props.employee.firstname
                        && editEmployee.lastname === props.employee.lastname
                        && editEmployee.gender === props.employee.gender
                        && selectedOU.id === defaultOU.id
                        && editEmployee.permissions.admin === props.employee.permissions.admin
                        && (editEmployee.permissionGroup && editEmployee.permissionGroup.id === actualPermissionGroup?.id))
                        return props.resolve && props.resolve();

                    if (editEmployee.firstname === "")
                        return setError(langStrings.pleaseEnterFirstName);

                    if (editEmployee.lastname === "")
                        return setError(langStrings.pleaseEnterLastName);
                    if (!editEmployee.permissionGroup || editEmployee.permissionGroup.id === -1)
                        return setError(langStrings.pleaseSelectPermissionGroup);
                    if (!editEmployee.gender)
                        return setError(langStrings.pleaseSelectGender);
                    setSaving(true);
                    await props.onSave(editEmployee, selectedOU.id)
                        .then(props.resolve)
                        .catch(ex =>
                        {
                            setError(ex.toString());
                            setTimeout(() =>
                            {
                                setSaving(false);
                            }, 3000);

                        });
                }} />
        </div>}
        onClose={function (): void
        {
            props.resolve && props.resolve();
        }}>
        <div className="editUserDialog">
            <div className="editUserRow">
                <RowElement title={langStrings.firstname} alignTitle="left">
                    <TextInput
                        value={editEmployee.firstname}
                        size='small'
                        onChange={(value) =>
                        {
                            setEditEmployee({
                                ...editEmployee,
                                firstname: value
                            });
                        }} />
                </RowElement>
                <RowElement title={langStrings.lastname} alignTitle="left">
                    <TextInput
                        value={editEmployee.lastname}
                        size='small'
                        onChange={(value) =>
                        {
                            setEditEmployee({
                                ...editEmployee,
                                lastname: value
                            });
                        }} />
                </RowElement>
                <RowElement title="E-Mail" alignTitle="left">
                    {editEmployee.email}
                </RowElement>
                <RowElement title={langStrings.gender} alignTitle="left">
                    <GenderSelect value={editEmployee.gender} onChange={(value) => setEditEmployee({
                        ...editEmployee,
                        gender: value
                    })} />
                </RowElement>

                <RowElement title={langStrings.orgUnit} alignTitle="left">
                    <OUSelect
                        OUs={props.OUs}
                        selectedOU={selectedOU.id}
                        setSelectedOU={function (ou: OU): void
                        {
                            setSelectedOU(ou);
                        }} />
                </RowElement>
                <RowElement title={langStrings.permissionGroup} alignTitle="left">
                    <PermissionGroupSelect
                        permissionGroups={props.permissionGroups}
                        selectedPermissionGroup={editEmployee.permissionGroup ? editEmployee.permissionGroup.id : -1}
                        setSelectedPermissionGroup={(pg) => setEditEmployee({
                            ...editEmployee,
                            permissionGroup: { id: pg.id, title: pg.title },
                            permissions: { ...editEmployee.permissions, entities: pg.entities }
                        })} />
                </RowElement>
                <RowElement title={langStrings.admin} alignTitle="left">
                    <Checkbox checked={editEmployee.permissions.admin} disabled={isMe} onChange={(e) => setEditEmployee({
                        ...editEmployee,
                        permissions: { ...editEmployee.permissions, admin: e.target.checked }
                    })} />
                </RowElement>

            </div>
            {error}
        </div>
    </Dialog>;
}
