import { OperationVariables, gql } from "@apollo/client";
import { EAvailabilityInputAs, EAvailabilitySelectionType, IAvailabilityExtensionMonthDTO, IAvailabilityMonthSmallDTO, IAvailabilityWeekDTO } from "@sharedInterfaces/IAvailabilityMonth";
import { IAvailabilityDTO } from "@sharedInterfaces/IEmployee";
import GraphQL from "@src/APIs/graphQl/graphQL";

import { getMonthLocalName } from "../helper/helper";
export default class AvailabilityMonth implements IAvailabilityMonthSmallDTO
{
    id: string;
    title: string;
    firstDay: Date;
    workDayCount: number
    selectionType: EAvailabilitySelectionType;
    inputAs: EAvailabilityInputAs;
    plannedDayCount: number;
    holidayCount: number;
    absenceDayCount: number;
    constructor(obj: IAvailabilityMonthSmallDTO)
    {
        this.id = obj.id;
        this.title = obj.title;
        this.firstDay = new Date(obj.firstDay);
        this.workDayCount = obj.workDayCount
        this.selectionType = obj.selectionType;
        this.inputAs = obj.inputAs ? obj.inputAs : EAvailabilityInputAs.hours;
        this.plannedDayCount = obj.plannedDayCount;
        this.absenceDayCount = obj.absenceDayCount;
        this.holidayCount = obj.holidayCount;
    }

    public get LocalTitle(): string
    {
        return getMonthLocalName(this.firstDay);
    }
    static getAvailabilityMonths(callback: (avms: AvailabilityMonth[]) => void)
    {
        const variables: OperationVariables = {};
        const q = gql`
query {
    availabilityMonths {
        id,
        title,
        firstDay,
        workDayCount,
        plannedDayCount,
        absenceDayCount,
        holidayCount,
        selectionType,
        inputAs
    }
},
`;
        return GraphQL.query<{ availabilityMonths: IAvailabilityMonthSmallDTO[] }>(q, variables, (obj) =>
        {
            if (!obj) throw new Error("Nicht gefunden.");

            const months = obj.availabilityMonths.map((p) => new AvailabilityMonth(p));
            months.sort((a, b) => a.firstDay.getTime() - b.firstDay.getTime());
            callback(months)
        });
    }

    getMoreMonths()
    {
        const variables: OperationVariables = { id: this.id };
        const q = gql`
query  getMoreMonths($id: ID!) {
    getMoreMonths(id:$id) {
        id
        title
        workDayCount
        inputAs,
        weekArray
        {
            weekNumber
            firstDay
            lastDay
            days {
                planings
                {
                activityId
                value
                }
                date
                name
                holiday
                isWorkday
                absence

            }
        }
    }
},
`;
        return new Promise<IAvailabilityExtensionMonthDTO[]>(resolve =>
        {
            return GraphQL.query<{ getMoreMonths: IAvailabilityExtensionMonthDTO[] }>(q, variables, (result) =>
            {
                if (!result) throw "Not found";
                const months = result.getMoreMonths;
                const newMonthArray: IAvailabilityExtensionMonthDTO[] = months.map(month =>
                {
                    return {
                        id: month.id,
                        inputAs: month.inputAs,
                        title: month.title,
                        workDayCount: month.workDayCount,
                        weekArray: month.weekArray
                    }
                });
                resolve(newMonthArray);
            });
        });
    }

    getWeekArray()
    {
        const variables: OperationVariables = { id: this.id };
        const q = gql`
query  getWeekArray($id: ID!) {
    getWeekArray(id:$id) {
        weekNumber
        firstDay
        lastDay
        days {
            planings
            {
              activityId
              value
            }
            date
            name
            holiday
            isWorkday
            absence

        }
    }
},
`;
        return new Promise<IAvailabilityWeekDTO[]>(resolve =>
        {
            return GraphQL.query<{ getWeekArray: IAvailabilityWeekDTO[] }>(q, variables, (result) =>
            {
                if (!result) throw new Error("Fehler beim abrufen.");

                resolve(result.getWeekArray);
            });
        });
    }
    edit(data: { id: string; type: EAvailabilitySelectionType; inputAs: EAvailabilityInputAs, weekArray: IAvailabilityWeekDTO[] })
    {
        const query = gql`
mutation updateAvailabilityMonth($id: ID!, $type: String!, $inputAs: String!, $weekArray: [AvailabilityWeekInput!]!) {
    updateAvailabilityMonth(id: $id, type: $type,inputAs:$inputAs, weekArray: $weekArray) 
    {
        availability,
        workDayCount
    }
},
`;
        const variables: {
            id: string;
            weekArray: IAvailabilityWeekDTO[];
            type: EAvailabilitySelectionType;
            inputAs: EAvailabilityInputAs;
        } = {
            id: data.id,
            weekArray: data.weekArray,
            type: data.type,
            inputAs: data.inputAs
        };
        return GraphQL.mutate<IAvailabilityDTO>(query, variables);
    }


    editMultiple(data: {
        inputAs: EAvailabilityInputAs;
        type: EAvailabilitySelectionType;
        months: IAvailabilityExtensionMonthDTO[];
    })
    {
        const query = gql`
    mutation updateAvailabilityMonths($months: [AvailabilityMonthExtensionInput!]!, $type: String!, $inputAs: String!) {
        updateAvailabilityMonths(months: $months, type: $type, inputAs: $inputAs) 
        {
            availability,
            workDayCount
        }
    }
`;

        // Variablen für mehrere Monate vorbereiten
        const variables: {
            months: {
                id: string;
                weekArray: IAvailabilityWeekDTO[];
            }[];
            type: EAvailabilitySelectionType;
            inputAs: EAvailabilityInputAs;
        } = {
            months: data.months,
            type: data.type,
            inputAs: data.inputAs
        };

        // Einzelner Aufruf für alle Monate
        return GraphQL.mutate<IAvailabilityDTO>(query, variables);
    }
}