import { Action } from "redux";
import SkillDetail from "@src/Objects/SkillDetail";

const initialState: SkillDetail[] = [];

export enum ESkillAction
{
    SET_SKILLS = "SET_SKILLS",
    update_SKILL = "update_SKILL",
    DELETE_SKILL = "DELETE_SKILL",
}

interface SetSkillsAction extends Action
{
    type: ESkillAction.SET_SKILLS;
    payload: SkillDetail[];
}
interface SetSkillAction extends Action
{
    type: ESkillAction.update_SKILL;
    payload: SkillDetail;
}
interface DeleteSkillAction extends Action
{
    type: ESkillAction.DELETE_SKILL;
    payload: number;
}
type SkillAction = SetSkillsAction | SetSkillAction | DeleteSkillAction;

export const skillReducer = (state = initialState, action: SkillAction): SkillDetail[] =>
{
    switch (action.type)
    {
        case ESkillAction.SET_SKILLS:
            return action.payload;
        case ESkillAction.update_SKILL:
            // eslint-disable-next-line no-case-declarations
            const index = state.findIndex(skill => skill.id === action.payload.id); // Assuming each SkillDetail has a unique 'id'
            if (index !== -1)
            {
                // Skill exists, update it
                const newState = [...state];
                newState[index] = action.payload;
                return newState;
            } else
            {
                // Skill does not exist, add it
                return [...state, action.payload];
            }
        case ESkillAction.DELETE_SKILL:
            return state.filter(skill => skill.id !== action.payload);
        default:
            return state;
    }
};

// Action Creator
export const setSkills = (skills: SkillDetail[]): SetSkillsAction => ({
    type: ESkillAction.SET_SKILLS,
    payload: skills,
});

// Action Creator
export const updateSkill = (skill: SkillDetail): SetSkillAction => ({
    type: ESkillAction.update_SKILL,
    payload: skill,
});

export const deleteSkill = (id: number): DeleteSkillAction => ({
    type: ESkillAction.DELETE_SKILL,
    payload: id,
});