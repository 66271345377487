import React from 'react';
import { Line } from 'react-chartjs-2';
import
{
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { IOrgUnitSalesDataDTO } from '@sharedInterfaces/IOrgUnit';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';

import { calculateSum, filterByMonthId } from './SalesContent';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface RevenueGraphProps
{
    monthIds: string[];
    monthOptions: Intl.DateTimeFormatOptions;
    salesData: IOrgUnitSalesDataDTO;
    monthSource: string
}

export function RevenueGraph({
    monthIds,
    monthOptions,
    salesData,
    monthSource
}: RevenueGraphProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;
    const style = useSelector((state: AppState) => state.company.style);
    const now = new Date();
    const actualMonth = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}`;

    const dataObj = monthIds.map(id =>
    {
        const employeesMonth = filterByMonthId(salesData.employees, id);
        const shouldCalculate = (actualMonth !== id || monthSource === 'both' || monthSource === 'timeRecording');
        const sumBookedRevenue = shouldCalculate ? calculateSum(employeesMonth, proj => proj.bookedRevenue) : 0;
        const bookedMonth = employeesMonth.length > 0 ? employeesMonth[0].source === 'booking' : false;

        const shouldCalculateForecast = (!bookedMonth) && (monthSource === 'both' || monthSource === 'forecast');
        const sumPlannedRevenue = shouldCalculateForecast ? calculateSum(employeesMonth, proj => monthSource === 'forecast' && proj.totalPlannedRevenue != null ? proj.totalPlannedRevenue : proj.plannedRevenue) : 0;

        const sumPlannedRevenueWithoutFilter = calculateSum(employeesMonth, proj => proj.totalPlannedRevenue != null
            ? proj.totalPlannedRevenue
            : proj.plannedRevenue //TODO: Fix? Doppelt bei aktuellem Monat?
        );

        // const sumPlannedRevenue = shouldCalculate ? calculateSum(filteredMonths, proj => proj.plannedRevenue) : 0;
        const isFutureMonth = id > actualMonth
        return {
            month: id,
            title: new Date(id).toLocaleString(undefined, monthOptions),
            revenue: isFutureMonth ? null : Math.round(sumBookedRevenue + sumPlannedRevenue),
            forecast: Math.round(sumPlannedRevenueWithoutFilter),
        }
    });
    const labels = dataObj.map(o => o.title);

    const options = {
        responsive: true,
        animation: {
            duration: 0,
        },
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        scales: {
            x: {
                grid: {
                    drawBorder: true,
                    drawOnChartArea: true,
                    color: (context: { tick: any }) =>
                    {
                        if (context.tick?.label === dataObj.find(d => d.month === actualMonth)?.title)
                        {
                            return style.primary;
                        }
                        return 'rgba(0, 0, 0, 0.1)';
                    },
                    lineWidth: (context: { tick: any }) =>
                    {
                        if (context.tick?.label === dataObj.find(d => d.month === actualMonth)?.title)
                        {
                            return 2;
                        }
                        return 1;
                    },
                },
            },
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                ticks: {
                    // Diese Callback-Funktion fügt das €-Zeichen vor jeden Tick-Wert ein
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    callback: (value: unknown, _index: unknown, _values: unknown) => new Intl.NumberFormat('de-DE').format(value as number) + '€',
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    label: function (context: any)
                    {
                        let label = context.dataset.label || '';
                        if (label)
                        {
                            label += ': ';
                        }
                        if (context.parsed.y !== null)
                        {
                            label += new Intl.NumberFormat('de-DE').format(context.parsed.y) + '€';
                        }
                        return label;
                    }
                }
            },
        },
    };

    // const isCurrentMonth = (monthId: string) => monthId === actualMonth;

    const data = {
        labels,
        datasets: [
            {
                label: langStrings.revenue,
                data: dataObj.map(o => o.revenue),
                borderColor: style.secondary,
                backgroundColor: style.primary,
                // pointBorderColor: dataObj.map(o => isCurrentMonth(o.month) ? 'red' : style.secondary),
                // pointBackgroundColor: dataObj.map(o => isCurrentMonth(o.month) ? 'red' : style.primary),
                tension: 0.4,
                yAxisID: 'y',
            },
            {
                label: langStrings.forecast,
                data: dataObj.map(o => o.forecast),
                borderColor: '#ddd',
                backgroundColor: '#bbb',
                tension: 0.4,
                yAxisID: 'y',
            },
        ],
    };
    return <Line options={options} data={data} height={40} />;
}
