import { OperationVariables, gql } from "@apollo/client";
import IOpportunity from "@sharedInterfaces/IOpportunity";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { setCompanyVersion } from "@store/reducer/companyReducer";
import { setOpportunities } from "@store/reducer/opportunityRecuder";
import Opportunity from "@src/Objects/Opportunity";

import GraphQL from "../graphQL";

import { opportunityGql } from "./getOpportunity";


const q = gql`
query($version:Int!) {
    opportunities(version:$version){
        version
        opportunities
        ${opportunityGql}
    }
},
`;

interface OpportunitieResponse
{
    opportunities: { version: number; opportunities: IOpportunity[] };
}


export default async function getOpportunities()
{
    const state = store.getState();
    const versions = state.company.versions;
    const variables: OperationVariables = {
        version: versions.opportunity,
    };
    return GraphQL.query<OpportunitieResponse>(q, variables, undefined)
        .then((obj) =>
        {
            if (!obj) throw new Error("Fehler beim laden der Opportunities.");
            if (obj.opportunities.opportunities)
            {
                const data = obj.opportunities.opportunities.map((p: IOpportunity) => new Opportunity(p));
                store.dispatch(setCompanyVersion(EEntityType.OPPORTUNITY, obj.opportunities.version))
                store.dispatch(setOpportunities(data))
                return data;
            }
            return state.opportunity;
        });
}
