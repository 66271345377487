import { Done } from '@mui/icons-material';
import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { IStyleDTO } from '@sharedInterfaces/ICompanySettings';
import { setCompanyStyle } from '@store/reducer/companyReducer';
import { Typography, Box, Button } from '@mui/material';
import saveStyle from '@src/APIs/graphQl/Company/saveStyle';
import CardBox from '@src/Components/CardBox/CardBox';
import './StyleSettings.css';
import { uploadFile } from '@src/Components/Dialogs/AnalyseSkillsDialog/AnalyseCvDialog';
import getUploadURLCompanyLogo from '@src/APIs/graphQl/Company/getUploadURLCompanyLogo';

interface StyleSettingsProps
{
    style: IStyleDTO;
    setStyle: (val: IStyleDTO) => void;
}

/**
 * StyleSettings component.
 * Handles the company style settings, including primary and secondary colors.
 *
 * @param {StyleSettingsProps} props - The props for the StyleSettings component.
 * @returns {JSX.Element} - The JSX element representing the StyleSettings.
 */
function StyleSettings(props: StyleSettingsProps): JSX.Element
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const logoId = useSelector((state: AppState) => state.company.style.logoId);
    const langStrings = getLanguageTexts(lang).settings;

    const [original, setOriginalStyle] = React.useState(props.style);
    const [changed, setChanged] = React.useState(false);
    const [colorPrimary, setColorPrimary] = React.useState(props.style['primary']);
    const [colorOnPrimary, setColorOnPrimary] = React.useState(props.style['onPrimary']);
    const [colorSecondary, setColorSecondary] = React.useState(props.style['secondary']);
    const [colorOnSecondary, setColorOnSecondary] = React.useState(props.style['onSecondary']);
    const [loadingText, setLoadingText] = React.useState<string | null>(null);
    const [loadingError, setLoadingError] = React.useState<string | null>(null);

    // Update style when color values change
    React.useEffect(() =>
    {
        props.setStyle({
            primary: colorPrimary,
            onPrimary: colorOnPrimary,
            secondary: colorSecondary,
            onSecondary: colorOnSecondary,
            logoId: logoId,
        });
        return () =>
        {
            if (changed) props.setStyle(original);
        };
    }, [colorPrimary, colorOnPrimary, colorSecondary, colorOnSecondary]);

    // Track changes to determine if Save button should be enabled
    React.useEffect(() =>
    {
        if (
            colorPrimary !== original.primary ||
            colorOnPrimary !== original.onPrimary ||
            colorSecondary !== original.secondary ||
            colorOnSecondary !== original.onSecondary
        )
        {
            setChanged(true);
        } else
        {
            setChanged(false);
        }
    }, [colorPrimary, colorOnPrimary, colorSecondary, colorOnSecondary, original]);

    React.useEffect(() =>
    {
        setLoadingError(null);
        setLoadingText(null);

    }, [logoId]);
    /**
     * Handles the file upload for the company logo.
     *
     * @param {ChangeEvent<HTMLInputElement>} e - The change event from the file input.
     */
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) =>
    {
        if (!e.target.files) return;
        const file = e.target.files[0];
        setLoadingText(langStrings.uploading);
        await getUploadURLCompanyLogo()
            .then(async (post) =>
            {
                if (!post) return;
                await uploadFile(post, file)
                    .catch((ex) =>
                    {
                        setLoadingError(ex.toString());
                        setLoadingText(null);
                    });
                setLoadingText(langStrings.transforming);
                setTimeout(checkForUpdate, 3000);
            })
            .catch(ex =>
            {
                setLoadingText("");
                setLoadingError(ex.toString());
            });
    };

    /**
     * Placeholder function to check for updates after file upload.
     */
    const checkForUpdate = () =>
    {
        // Placeholder for any post-upload logic, e.g., refreshing the logo.
        // setLoadingText(null);
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <CardBox title={langStrings.coloringTitle}>
                <Box component="div" className='styleSettings'>
                    <table cellSpacing={0} cellPadding={0}>
                        <tbody>
                            <ColorInputRow label={langStrings.primaryColor} colorValue={colorPrimary} setColorValue={setColorPrimary} />
                            <ColorInputRow label={langStrings.onPrimaryColor} colorValue={colorOnPrimary} setColorValue={setColorOnPrimary} />
                            <ColorInputRow label={langStrings.secondaryColor} colorValue={colorSecondary} setColorValue={setColorSecondary} />
                            <ColorInputRow label={langStrings.onSecondaryColor} colorValue={colorOnSecondary} setColorValue={setColorOnSecondary} />
                            <tr>
                                <td colSpan={2}>
                                    {changed && (
                                        <Button variant="contained" color="primary"
                                            startIcon={<Done />}
                                            onClick={function (): void
                                            {
                                                const newStyle: IStyleDTO = {
                                                    primary: colorPrimary,
                                                    onPrimary: colorOnPrimary,
                                                    secondary: colorSecondary,
                                                    onSecondary: colorOnSecondary,
                                                    logoId: logoId,
                                                };
                                                saveStyle(newStyle).then(() =>
                                                {
                                                    dispatch(setCompanyStyle(newStyle));
                                                    props.setStyle(newStyle);
                                                    setOriginalStyle(newStyle);
                                                    setChanged(false);
                                                });
                                            }}
                                            disabled={!changed}>
                                            {langStrings.save}
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </CardBox>

            {/* Card for Company Logo Upload */}
            <CardBox title={langStrings.companyLogoTitle}>
                <Box component="div" className='uploadLogo'>
                    <Typography variant="body1" gutterBottom>{langStrings.uploadLogoInstructions}</Typography>
                    <Button variant="contained" component="label" disabled={loadingText !== null}>
                        {langStrings.uploadButtonLabel}
                        <input type="file" accept="image/*" hidden onChange={handleFileChange} />
                    </Button>
                    {loadingText && <Typography variant="body2" color="textSecondary">{loadingText}</Typography>}
                    {loadingError && <Typography variant="body2" color="error">{loadingError}</Typography>}
                </Box>
            </CardBox>
        </Box>
    );
}

/**
 * ColorInputRow component.
 * A reusable component for displaying a color input row with a label.
 *
 * @param {Object} props - The props for the ColorInputRow component.
 * @param {string} props.label - The label for the color input.
 * @param {string} props.colorValue - The current value of the color input.
 * @param {React.Dispatch<React.SetStateAction<string>>} props.setColorValue - The function to update the color value.
 * @returns {JSX.Element} - The JSX element representing the color input row.
 */
function ColorInputRow({ label, colorValue, setColorValue }: { label: string, colorValue: string, setColorValue: React.Dispatch<React.SetStateAction<string>> }): JSX.Element
{
    return (
        <tr>
            <td>
                <Typography variant="body1" component="label" htmlFor={label}>{label}:</Typography>
            </td>
            <td className='styleLine'>
                <input type="color"
                    id={label}
                    value={colorValue}
                    onChange={(e) => setColorValue(e.currentTarget.value)}
                />
            </td>
        </tr>
    );
}

export default StyleSettings;
