import React from 'react';
import { AppState } from '@store/store';
import { useSelector } from 'react-redux';

interface OpportunityStatusSelectProps
{
    value: string
    disabled?: boolean
    onChange: (val: string) => void
}

function OpportunityStatusSelect({
    value,
    disabled,
    onChange }: OpportunityStatusSelectProps)
{
    const statusOptions = useSelector((state: AppState) => state.company.opportunitySettings.statusOptions);
    const onChangeInner = (e: React.ChangeEvent<HTMLSelectElement>) => onChange(e.target.value);
    return (
        <div className="opportunityStatusSelect">
            <select
                value={value}
                onChange={onChangeInner}
                disabled={disabled}
            >
                {(!value || value === "" || !statusOptions.find(o => o.id === value)) && <option value={""}></option>}

                {statusOptions.map(o =>
                {
                    return (
                        <option
                            key={o.id}
                            value={o.id}
                        >
                            {o.title}
                        </option>
                    );
                })}
            </select>
        </div>
    );
}

export default OpportunityStatusSelect;


