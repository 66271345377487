import React, { useState } from "react"
import { Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import { AppState } from "@store/store";

import RoundIconButton from "../Buttons/RoundIconButton/RoundIconButton";

import { SeachInput } from "./SeachInput";
const MemoizedSeachInput = React.memo(SeachInput);

export const MAX_WIDTH = 300; //Pixel für Searchbox
export const TYPING_TIMEOUT = 500// MS für Typing Timeout

interface SearchBoxProps
{
}
/**
 * Component representing a search box.
 *
 * @param {SearchBoxProps} props - The props for the SearchBox component.
 * @returns {JSX.Element} - The rendered SearchBox component.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function SearchBox(props: SearchBoxProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = React.useMemo(() => getLanguageTexts(lang).layout, [lang]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const toggleOpen = React.useCallback(() =>
    {
        setOpen((prev) => !prev);
    }, []);
    return (
        <>
            {!open &&
                <RoundIconButton
                    icon={<Search />}
                    helperText={langStrings.search}
                    color="on-primary-color"
                    size={"medium"}
                    onClick={toggleOpen}
                />
            }
            <MemoizedSeachInput
                open={open}
                setOpen={setOpen}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        </>
    );
}