import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';

import RegisterCompany from './register';

interface RegisterCompanyProps
{
    id: string
    /**
     * The value is null or the companyId
     */
    resolve?: (companyId: string | null) => void;
}


/**
 * 
 * @param {RegisterCompanyProps} business - The business object.
 * @param {Function} resolve - The resolve function.
 * @param {Function} removeElement - The removeElement function.
 * @returns {JSX.Element} - The JSX element representing the business dialog.
 */
export function RegisterCompanyDialog({ id, resolve }: RegisterCompanyProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).registerCompany;

    const onClose = () =>
    {
        resolve && resolve(null);
    }
    return (
        <Dialog
            id={id}
            title={langStrings.register}
            onClose={onClose}
            footer={
                <div style={{ float: 'right' }}>
                    {/* <Button
                        icon={<Done />}
                        disabled={saving}
                        text={!business ? langStrings.create : langStrings.save}
                        size={'normal'}
                        onClick={onSave}
                    /> */}
                </div>
            }
        >
            <div className="registerCompany">
                <RegisterCompany resolve={resolve} />
            </div>
        </Dialog>
    );
}