import { OperationVariables, gql } from "@apollo/client";
import store from "@store/store";

import GraphQL from "../graphQL";

const query = gql`
mutation logoutClient($clientId: String!) {
    logoutClient(clientId: $clientId)
},
`;

export default async function logoutClient()
{
    const variables: OperationVariables = {
        clientId: store.getState().client.secret,
    };
    return new Promise<boolean>((resolve) =>
    {
        return GraphQL.mutate<boolean>(query, variables)
            .then(() => resolve(true))
            .catch(() => resolve(false));
    })
}