import { OperationVariables, gql } from "@apollo/client";
import { IEmployeeSuggestedCompetence } from "@sharedInterfaces/IEmployee";

import GraphQL from "../../graphQL";

const q = gql`
query getSuggestedCompetences {
    getSuggestedCompetences 
    {
        id
        title
        level
        description
        employee {
            id
            title
            skillCoverage
            score
            missingSkills {
                id
                title
                wantedLevel
                level
            }
            coveredSkills {
                id
                title
                wantedLevel
                level
            }
        }
    }
},
`;


/**
 * Retrieves suggested competences for an employee.
 * 
 * @param {(competences: IEmployeeSuggestedCompetence[]) => void} callback - A callback function that handles the fetched competences.
 * @returns {Promise<{ getSuggestedCompetences: IEmployeeSuggestedCompetence[]; }>} - A promise that resolves with the suggested competences.
 */
export default async function getSuggestedCompetences(callback: (competences: IEmployeeSuggestedCompetence[]) => void)
{
    const variables: OperationVariables = {};
    return GraphQL.query<{ getSuggestedCompetences: IEmployeeSuggestedCompetence[] }>(q, variables, (obj) =>
    {
        if (!obj) return;
        callback(obj.getSuggestedCompetences)
    });
}
