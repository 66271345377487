import { Action } from "redux";
import { IOU } from "@sharedInterfaces/ICompanySettings";

const initialState: IOU[] = [];

export enum EOUAction
{
    SET_OUS = 'SET_OUS',
}

interface OUsAction extends Action
{
    type: EOUAction.SET_OUS;
    payload: IOU[];
}

export const OUsReducer = (state = initialState, action: OUsAction): IOU[] =>
{
    switch (action.type)
    {
        case EOUAction.SET_OUS:
            return action.payload;
        default:
            return state;
    }
};

export const setOUs = (ous: IOU[]): OUsAction => ({
    type: EOUAction.SET_OUS,
    payload: ous,
});
