interface IStageContext
{
    prod: boolean
    cognitoHostedURL: string
    cognitoClientId: string
    domain: string
    // apiGatewayRestDomain: string
    apiGatewayRestDomain: string
    apiGatewayIdGraphQLDomain: string
    apiWebsocketDomain: string
    logoutHostname?: string
}
const CONST_DEFAULT_STAGE = 'dev'
export const stage: string = process.env.REACT_APP_STAGE ? process.env.REACT_APP_STAGE : CONST_DEFAULT_STAGE;

export const getStageContext = (): IStageContext => ({
    prod: process.env.REACT_APP_PROD === 'true',
    cognitoHostedURL: process.env.REACT_APP_COGNITO_HOSTED_URL!,
    cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
    domain: process.env.REACT_APP_DOMAIN!,
    apiGatewayRestDomain: process.env.REACT_APP_API_GATEWAY_REST_DOMAIN!,
    apiGatewayIdGraphQLDomain: process.env.REACT_APP_API_GATEWAY_ID_GRAPHQL_DOMAIN!,
    apiWebsocketDomain: process.env.REACT_APP_API_WEBSOCKET_DOMAIN!,
    logoutHostname: process.env.REACT_APP_LOGOUT_HOSTNAME,
});

export const stageContext = getStageContext();
