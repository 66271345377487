import { IPermissions, defaultEntitiesPermissions } from "@sharedInterfaces/IPermissions";

// Initialer Zustand
export const initialPermissionsState: IPermissions = {
    admin: false,
    entities: defaultEntitiesPermissions,
};

// Action Enum
export enum PermissionsActionTypes
{
    SET_PERMISSIONS = 'SET_PERMISSIONS'
}

// Action Interface
export interface SetPermissionsAction
{
    type: PermissionsActionTypes.SET_PERMISSIONS;
    payload: IPermissions;
}

// Reducer
export const permissionsReducer = (
    state = initialPermissionsState,
    action: SetPermissionsAction
): IPermissions =>
{
    switch (action.type)
    {
        case PermissionsActionTypes.SET_PERMISSIONS:
            return action.payload;
        default:
            return state;
    }
};

// Action Creator
export const setPermissions = (permissions: IPermissions): SetPermissionsAction => ({
    type: PermissionsActionTypes.SET_PERMISSIONS,
    payload: permissions,
});
