import React from 'react';
import { hasClassInPath } from '@src/helper/tsxHelper';
import { useNavigate } from 'react-router-dom';

import styles from './Item.module.css';
import { IItemAction, ItemActions } from './ItemAction';
interface ItemProps
{
    percent?: number
    order?: number
    children: React.ReactNode;
    leftContent?: React.ReactNode;
    rightContent?: React.ReactNode;
    actions?: IItemAction[];
    onClick?: string | (() => void)
}

const Item: React.FC<ItemProps> = ({ percent, order, children, leftContent, rightContent, actions, onClick }) =>
{
    const navigate = useNavigate();
    const innerOnClick = React.useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    {
        if ((e.target as HTMLElement).classList.contains('MuiBackdrop-root'))
        {
            return;
        }
        if (hasClassInPath(e, styles.leftContent, styles.item))
        {
            return;
        }
        if (hasClassInPath(e, styles.rightContent, styles.item))
        {
            return;
        }
        if (!hasClassInPath(e, styles.item, 'MuiPopover-root'))
        {
            return;
        }
        if (onClick)
        {
            if (typeof onClick === "string")
            {
                // window.location.href = onClick;
                navigate(onClick) //TODO: Konsequenzen prüfen!
            }
            else
            {
                onClick();
            }
        }
    }, [navigate, onClick]);

    const classes = React.useMemo(() =>
    {
        const classList = [styles.item];
        if (percent !== undefined && percent < 0)
        {
            classList.push(styles.negativ);
        }
        return classList;
    }, [percent]);

    const style = React.useMemo(() => ({
        order: order,
        "--percent": percent,
        cursor: onClick ? 'pointer' : 'inherit',
    }), [order, percent, onClick]);

    if (percent !== undefined && percent < 0) classes.push(styles.negativ)
    return (
        <div
            className={classes.join(" ")}
            style={style}
            onClick={innerOnClick}
        >
            <div className={styles.main}>
                {leftContent &&
                    <div className={styles.leftContent}>
                        {leftContent}
                    </div>
                }
                {/* <Typography variant='h6'> */}
                {children}
                {/* </Typography> */}
            </div>
            {rightContent &&
                <div className={styles.rightContent}>
                    {rightContent}
                    {(actions && actions.length !== 0) && <ItemActions actions={actions} />}

                </div>
            }
        </div>
    );
};

export default Item;


