import React from "react";
import { useSelector } from "react-redux";
import InnerLayout from "@sharedReact/Layouts/InnerLayout/InnerLayout";
import { AppState } from "@store/store";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import { ELinks } from "@sharedInterfaces/globalEnums";

import { BoxButton, BoxButtons } from "../../Components/BoxButton/BoxButton";


/**
 * Represents the DocumentationPage component.
 * 
 * @returns {JSX.Element} The JSX element for the DocumentationPage.
 */
export default function DocumentationPage()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).restDocu;
    return (
        <InnerLayout title={langStrings.oftenUsed.documentation} >
            <div className="page">

                <BoxButtons>
                    <BoxButton
                        title={langStrings.title}
                        description={langStrings.learnHowToUseRestApi}
                        onClick={`/${ELinks.DOCUMENTATION_REST}`}
                    />
                </BoxButtons>
            </div>
        </InnerLayout>
    );
}

