
import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed";

export interface IEntityPageTexts extends ITextBase
{
    exitedUser: string;
    versionInfo: string;
    notFound: string;
    search: string;
    offline: string;
    notAllowed: string;
    edit: string;
    delete: string;
}
type ILangData = {
    [lang in ELanguage]: IEntityPageTexts
}
export const entityPageTexts: ILangData = {
    'th': {
        exitedUser: 'ผู้ใช้ที่ออกไปแล้ว',
        oftenUsed: oftenUsedTranslations['th'],
        versionInfo: 'ข้อมูลเวอร์ชัน',
        edit: oftenUsedTranslations['th'].edit,
        delete: oftenUsedTranslations['th'].delete,
        notAllowed: oftenUsedTranslations['th'].notAllowed,
        offline: oftenUsedTranslations['th'].offline,
        search: oftenUsedTranslations['th'].search,
        notFound: "ไม่พบวัตถุที่ร้องขอ",
    },
    'en': {
        exitedUser: 'Former User',
        oftenUsed: oftenUsedTranslations['en'],
        versionInfo: 'Version Info',
        edit: oftenUsedTranslations['en'].edit,
        delete: oftenUsedTranslations['en'].delete,
        notAllowed: oftenUsedTranslations['en'].notAllowed,
        offline: oftenUsedTranslations['en'].offline,
        search: oftenUsedTranslations['en'].search,
        notFound: "The requested object could not be found.",
    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        edit: oftenUsedTranslations['de'].edit,
        delete: oftenUsedTranslations['de'].delete,
        notAllowed: oftenUsedTranslations['de'].notAllowed,
        offline: oftenUsedTranslations['de'].offline,
        search: oftenUsedTranslations['de'].search,
        notFound: 'Das Angeforderte Objekt konnte nicht gefunden werden.',
        versionInfo: 'Versions-Info',
        exitedUser: 'Ehemaliger Nutzer',
    },
}