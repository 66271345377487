import { ICompanyEmailSettings, IMicrosoftSettings } from "@sharedInterfaces/ICompany";
import ICompanySettings, { ETimeSyncOption, ICompanyApiKey, ICompanyCalcualtions, ICompanyTimeSyncStatis, ICompanyLocalisation, ICompanyUser, ILevel, IOU } from "@sharedInterfaces/ICompanySettings";
import { IHolidaySettings } from "@sharedInterfaces/IHolidays";
import { IPermissionGroup } from "@sharedInterfaces/IPermissions";
import { IReference } from "@sharedInterfaces/IReference";
import { IIndustriesTexts } from "@sharedInterfaces/Language/languageTexts/industriesTexts";
import { generateFullName } from "@sharedInterfaces/globalHelper";

export default class Company
{
    companyId: string;
    name: string;
    employees: ICompanyUser[];
    OUs: OU[];
    level: ILevel[];
    permissionGroups: IPermissionGroup[]
    defaultPermissionGroup: IReference | undefined
    allowedMailEndings: string[]
    timeSync: ETimeSyncOption
    clockodoSync: {
        email: string
    };
    clockodoStats: ICompanyTimeSyncStatis
    zepStats: ICompanyTimeSyncStatis
    zepInstance: string
    calculations: ICompanyCalcualtions
    localisation: ICompanyLocalisation
    industry: keyof IIndustriesTexts
    holidaySettings: IHolidaySettings;
    emailSettings: ICompanyEmailSettings
    apiKeys: ICompanyApiKey[]
    version: number;
    microsoftSettings: IMicrosoftSettings;
    globalEmailSupport: boolean;

    constructor(obj: ICompanySettings)
    {
        this.companyId = obj.companyId;
        this.name = obj.name;
        this.employees = obj.employees;
        this.OUs = obj.OUs.map(ou => new OU(ou, this.employees));
        this.OUs.sort((a, b) => a.title.localeCompare(b.title));
        this.level = obj.level;
        this.level.sort((a, b) => a.level - b.level)
        this.permissionGroups = obj.permissionGroups
        this.allowedMailEndings = obj.allowedMailEndings;
        this.defaultPermissionGroup = obj.defaultPermissionGroup;
        this.clockodoSync = obj.clockodoSync;
        this.timeSync = obj.timeSync;
        this.clockodoStats = obj.clockodoStatis;
        this.zepStats = obj.zepStatis;
        this.zepInstance = obj.zepInstance;
        this.calculations = obj.calculations;
        this.localisation = obj.localisation;
        this.industry = obj.industry;
        this.holidaySettings = obj.holidaySettings;
        this.emailSettings = obj.emailSettings;
        this.microsoftSettings = obj.microsoftSettings;
        this.apiKeys = obj.apiKeys;
        this.globalEmailSupport = obj.globalEmailSupport;
        this.version = obj.version ? obj.version : 1;
    }
}

export class OU 
{

    id: number;
    parentOU?: number | undefined;
    title: string;
    leader: IReference;
    employees: ICompanyUser[];
    constructor(obj: IOU, employees: ICompanyUser[])
    {
        this.id = obj.id;
        this.parentOU = obj.parentOU;
        this.title = obj.title;

        const leader = employees.find(e => e.id === obj.leader);

        this.leader = leader ? { id: leader.id, title: generateFullName(leader.firstname, leader.lastname) } : { id: -1, title: '' };
        this.employees = obj.employees.map(empId =>
        {
            return employees.find(e => e.id === empId) as ICompanyUser;
        })
            .filter(e => e !== undefined)
        this.employees.sort((a, b) => a.lastname.localeCompare(b.lastname));
    }
    generateDTO(): IOU
    {
        return {
            id: this.id,
            parentOU: this.parentOU,
            title: this.title,
            leader: this.leader.id,
            employees: this.employees.map(e => e.id),
        }
    }
}