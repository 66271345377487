import { OperationVariables, gql } from "@apollo/client";
import ICompetence, { ICreateCompetence } from "@sharedInterfaces/ICompetence";
import { competenceGQL } from "@src/Objects/Competence";

import GraphQL from "../graphQL";

const query = gql`
mutation createCompetence($obj: CreateCompetence!) {
    createCompetence(obj: $obj) 
    ${competenceGQL}
},
`;

export default async function createCompetence(competence: ICreateCompetence)
{
    competence.levels = competence.levels.map((c) => ({
        id: c.id,
        title: c.title,
        skills: c.skills,
        certificates: c.certificates,
    }))
    // competence.employees = competence.employees.map((c) => ({
    //     id: c.id,
    //     title: c.title,
    //     level: c.level,
    // }))
    const variables: OperationVariables = {
        obj: competence,
    };

    return GraphQL.mutate<ICompetence>(query, variables)
}

//, (obj: any) => callback(new Competence(companyId, obj.createCompetence as ICompetence))