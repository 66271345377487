import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Close, Delete, DragIndicator, Save } from '@mui/icons-material';
import { FormGroup, Grid, IconButton, Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from 'react-beautiful-dnd';
import { IOrgUnitSalesView } from '@sharedInterfaces/IOrgUnit';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import editOUSalesViews from '@src/APIs/graphQl/OrgUnit/editOUSalesViews';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';


interface SalesContentViewSettingsProps
{
    id: string
    views: IOrgUnitSalesView[];
    resolve: (result: IOrgUnitSalesView[]) => void;
}

export const SalesContentViewSettings: React.FC<SalesContentViewSettingsProps> = ({ id, views, resolve }) =>
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;

    const [isOpen, setIsOpen] = useState(true);
    const [stateViews, setStateViews] = useState<IOrgUnitSalesView[]>(views);
    const [saving, setSaving] = useState(false);

    const onConfirm = () =>
    {
        setSaving(true);
        editOUSalesViews(stateViews).then(() =>
        {
            setIsOpen(false);
            resolve(stateViews);
        }).catch((ex) =>
        {
            showErrorDialog(ex);
            setSaving(false);
        });
    };
    const onClose = () =>
    {
        resolve(views);
        setIsOpen(false);
    };

    const onDragEnd: OnDragEndResponder = (result) =>
    {
        const { source, destination } = result;
        if (!destination)
        {
            return;
        }
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        )
        {
            return;
        }
        const newStateViews = Array.from(stateViews);
        const [reorderedItem] = newStateViews.splice(source.index, 1);
        newStateViews.splice(destination.index, 0, reorderedItem);
        setStateViews(newStateViews);
    };
    const handleRemoveView = (viewId: number) =>
    {
        const filteredViews = stateViews.filter(view => view.id !== viewId);
        setStateViews(filteredViews);
    };

    if (!isOpen) return null;

    return (
        <Dialog
            id={id}
            footer={
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                    <Button
                        text={langStrings.oftenUsed.close}
                        disabled={saving}
                        icon={<Close />}
                        onClick={onClose}
                    />
                    <Button
                        text={langStrings.oftenUsed.confirm}
                        disabled={saving}
                        icon={<Save />}
                        onClick={onConfirm}
                    />
                </div>
            }
            title={langStrings.salesViewDialogTitle}
            onClose={onClose}
        >
            <div style={{ margin: 10 }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-fields">
                        {(provided,) => (
                            <FormGroup
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{
                                    overflow: 'hidden', //Fix für D&D
                                }}
                            >
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <div style={{ width: 22 }} />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant='caption'>{langStrings.view}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='caption'>{langStrings.oftenUsed.delete}</Typography>
                                    </Grid>
                                </Grid>
                                {stateViews.map((view, index) => (
                                    <Draggable key={view.id} draggableId={view.id.toString()} index={index}>
                                        {(provided,) => (
                                            <Grid container alignItems="center" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} spacing={1}>
                                                <Grid item>
                                                    <DragIndicator />
                                                </Grid>
                                                <Grid item xs>
                                                    {view.title}
                                                </Grid>
                                                <Grid item>
                                                    <IconButton onClick={() => handleRemoveView(view.id)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </FormGroup>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            {/* <pre>
                {JSON.stringify(settings, null, 2)}
            </pre>
            <pre>
                {JSON.stringify(fields, null, 2)}
                {JSON.stringify(sortedFields, null, 2)}
            </pre> */}
        </Dialog>
    );
};