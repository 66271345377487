import { OperationVariables, gql } from "@apollo/client";
import { ISkillCategoryDetailDTO } from "@sharedInterfaces/ISkillCategory";
import { SkillCategory } from "@src/Objects/SkillCategory";

import GraphQL from "../graphQL";

import { skillCategoryGQL } from "./editSkillCategory";

const query = gql`
mutation createSkillCategory($title: String!, $description: String!, $parentCategory: Int, $skills: [Int!]!) {
    createSkillCategory(title: $title, description: $description, parentCategory: $parentCategory, skills: $skills) 
    ${skillCategoryGQL}
},
`;

/**
 * Create a new skill category with the provided title, description, parent category id, and list of skill ids
 * 
 * @param {string} title - The title of the skill category
 * @param {string} description - The description of the skill category
 * @param {number} parentCategory - The id of the parent category (optional, pass null if there's no parent category)
 * @param {number[]} skills - An array of skill ids associated with this category
 * 
 * @returns {Promise<SkillCategory>} - A promise that resolves with the newly created skill category object
 */
export default async function createSkillCategory(title: string, description: string, parentCategory: number | null, skills: number[])
{
    const variables: OperationVariables = {
        title,
        description,
        parentCategory,
        skills,
    };

    return GraphQL.mutate<ISkillCategoryDetailDTO>(query, variables).then(obj => new SkillCategory(obj))
}

//, (obj: any) => callback(new Product(companyId, obj.createSkillCategory as IProduct))