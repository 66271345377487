import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './FirstExperienceDialog.css';
import { ArrowLeft, ArrowRight, Check, Done } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EGender } from '@sharedInterfaces/IEmployee';
import { AppState } from '@store/store';
import { setEmployeeStore } from '@store/reducer/employeeReducer';
import { setFirstExperience } from '@store/reducer/employeeSettingsReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import Company from '@src/Objects/Company';
import updateMyGenderAndName from '@src/APIs/graphQl/Employee/updateMyGenderAndName';
import markFirstExperienceAsRead from '@src/APIs/graphQl/Employee/markFirstExperienceAsRead';
import getCompanySettings from '@src/APIs/graphQl/Company/getCompanySettings';
import { GeneralTab } from '@src/pages/SettingsPage/GeneralTab';

import AnalyseSkillsPdfButton from '../../Buttons/AnalyseSkillsPdfButton/AnalyseSkillsPdfButton';
import AnalyseSkillsTextButton from '../../Buttons/AnalyseSkillsTextButton/AnalyseSkillsTextButton';
import GenderSelect from '../../formsControls/inputs/GenderSelect/GenderSelect';
import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';



/**
 * FirstExperienceDialog
 * 
 * @returns {JSX.Element}
 */
function FirstExperienceDialog({ id, resolve }: { id: string, resolve?: () => void })
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).firstExpirienceDialog;
    const companyName = useSelector((state: AppState) => state.company.name);
    const emp = useSelector((state: AppState) => state.employee);
    const permissions = useSelector((state: AppState) => state.permissions);
    const companyId = useSelector((state: AppState) => state.company.companyId);
    const [open, setOpen] = React.useState(true as boolean);
    const [page, setPage] = React.useState<number>(0);
    const [firstName, setFirstName] = React.useState<string>(emp.firstName);
    const [lastName, setLastName] = React.useState<string>(emp.lastName);
    const [gender, setGender] = React.useState<EGender>(emp.gender);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [company, setCompany] = React.useState<Company | null>(null);

    React.useEffect(() =>
    {
        if (!permissions.admin) return;
        getCompanySettings(companyId, (companySettings) =>
        {
            setCompany(companySettings);
        });
    }, [companyId, permissions.admin, permissions]);

    useEffect(() =>
    {
        setFirstName(emp.firstName)
        setLastName(emp.lastName)
        setGender(emp.gender)
    }, [emp]);

    const onSave = () =>
    {
        if (firstName === emp.firstName
            && lastName === emp.lastName
            && gender === emp.gender)
            return setPage(page + 1);
        setSaving(true)
        updateMyGenderAndName(firstName, lastName, gender)
            .then((result) =>
            {
                if (!result) return;
                dispatch(setEmployeeStore({ ...emp, firstName, lastName, gender }))
                setPage(page + 1);
            })
            .finally(() =>
            {
                setSaving(false)
            });
    }
    const onMarkRead = () =>
    {
        markFirstExperienceAsRead().then((result) =>
        {
            if (result)
            {
                dispatch(setFirstExperience(false));
            }
        });
        setOpen(false);
    }

    return (
        <React.Fragment>
            {open && <Dialog
                id={id}
                title={page === 0 ? langStrings.title : (page === 1 ? langStrings.title2 : '')}
                onClose={function (): void
                {
                    resolve && resolve();
                    setOpen(false);
                }}
                footer={
                    <>
                        <div style={{ float: 'left', }}>
                            {page > 0 &&
                                <Button
                                    icon={<ArrowLeft />}
                                    text={langStrings.previous}
                                    size={'normal'}
                                    onClick={async function (): Promise<void>
                                    {
                                        setPage(page - 1);
                                    }} />
                            }
                        </div>
                        <div style={{ float: 'right', }}>
                            {page === 1 ?
                                <Button
                                    icon={<Check />}
                                    text={langStrings.markAsRead}
                                    size={'normal'}
                                    onClick={onMarkRead} /> :
                                (page === 0 ?
                                    <Button
                                        icon={<Done />}
                                        text={langStrings.save}
                                        disabled={saving}
                                        size={'normal'}
                                        onClick={onSave} />
                                    :
                                    <Button
                                        icon={<ArrowRight />}
                                        text={langStrings.next}
                                        size={'normal'}
                                        onClick={async function (): Promise<void>
                                        {
                                            setPage(page + 1);
                                        }} />
                                )
                            }
                        </div>
                    </>
                }
            >
                {page === 0 &&
                    <div>
                        <h2>{langStrings.greeting.replace('[COMPANY]', companyName)}</h2>
                        {
                            permissions.admin && (
                                company ? (
                                    company.employees.length === 1 &&
                                    <GeneralTab company={company} setCompany={setCompany} />
                                ) : (
                                    <LoadingBox />
                                )
                            )
                        }
                        <p>{langStrings.infoText1}</p>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                            <RowElement title={langStrings.firstname} alignTitle="left">
                                <TextInput
                                    value={firstName}
                                    size='small'
                                    onChange={setFirstName}
                                />
                            </RowElement>
                            <RowElement title={langStrings.lastname} alignTitle="left">
                                <TextInput
                                    value={lastName}
                                    size='small'
                                    onChange={setLastName}
                                />
                            </RowElement>
                            <RowElement title={langStrings.gender} alignTitle="left">
                                <GenderSelect value={gender} onChange={setGender} />
                            </RowElement>
                        </div>
                    </div>
                }
                {page === 1 &&
                    <div>
                        <p>{langStrings.infoText2}</p>
                        <AnalyseSkillsPdfButton />
                        <AnalyseSkillsTextButton />
                    </div>
                }
            </Dialog>}
        </React.Fragment>

    );
}

export default FirstExperienceDialog;
