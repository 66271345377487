import { Add, Merge, TableChart, TableRows } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import { textContainsLowerCase } from '@src/Objects/Helper';
import Role from '@src/Objects/Role';
import RoleOverlay from '@src/Components/Overlays/RoleOverlay/RoleOverlay';
import { RoleDialog } from '@src/Components/Dialogs/RoleDialog/RoleDialog';
import EnhancedTable, { SortOrder } from '@src/App/NewLayout/Components/DataTable/DataTable';
import getRoles from '@src/APIs/graphQl/Role/getRoles';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import RoleCard from '@src/App/NewLayout/Cards/Roles/RoleCard/RoleCard';
import { useDialog } from '@sharedReact/Dialog/DialogManager';
import MergeRolesDialog from '@src/Components/Dialogs/MergeRolesDialog/MergeRolesDialog';

import EntitiesPage, { EViewType, IEntitiesPageAction } from '../../../../../sharedReact/Pages/EntitiesPage/EntitiesPage';
import { IViewType } from '../../../../../sharedReact/Pages/EntitiesPage/ViewTypeMenu';

/**
 * Component representing the RolesPage.
 * 
 * @returns {JSX.Element} The JSX element for the RolesPage.
 */
function RolesPage()
{
    const { openDialog } = useDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).roles;
    const emp = useSelector((state: AppState) => state.employee);
    const levelDefinitions = useSelector((state: AppState) => state.levelDefinition);
    const maxLevel = levelDefinitions.length;
    const permissions = useSelector((state: AppState) => state.permissions);
    const entities = useSelector((state: AppState) => state.roles);

    const isDense = (width: number) => width <= 1350
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);
    useEffect(() =>
    {
        getRoles().catch((ex) =>
        {
            setLoadingError(ex);
        })
    }, []);

    const [selected, setSelected] = useState<number[]>([]);

    const types: IViewType<Role>[] = [
        {
            id: EViewType.TABLE,
            title: langStrings.oftenUsed.table,
            icon: <TableRows fontSize='small' />,
            minWidth: 290,
            renderContainer:
                (allEntities, innerEntries, width, selected) =>
                    <EnhancedTable
                        id={EEntityType.ROLE}
                        key={"RolesTable"}
                        dense={isDense(width)}
                        noCheckbox={!((checkPermissions('Roles', 'Delete', permissions) || checkPermissions('Roles', 'Add', permissions)) && checkPermissions('Roles', 'Edit', permissions))}
                        selected={selected}
                        setSelected={setSelected}
                        order={SortOrder.DESC}
                        orderBy='employees'
                        fields={[{
                            id: 'id',
                            label: 'ID',
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'string',
                        },
                        {
                            id: 'title',
                            label: langStrings.roleName,
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'JSX.Element',
                            link: `/${ELinks.ROLES}/`,
                        },
                        {
                            id: 'description',
                            label: langStrings.description,
                            disablePadding: isDense(width),
                            align: 'left',
                            minWidth: 1200,
                            type: 'description',
                        },
                        {
                            id: 'employees',
                            label: langStrings.employees,
                            disablePadding: isDense(width),
                            align: 'center',
                            type: 'string',
                            minWidth: 390,
                        },
                        {
                            id: 'products',
                            label: langStrings.products,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 1000,
                            type: 'string',
                        },
                        ]}
                        rows={
                            innerEntries.map(entity => ({
                                id: entity.id,
                                title: {
                                    value: <RoleOverlay roleId={entity.id}
                                        key={`RoleOverlay${entity.id}`}
                                    >{entity.title}</RoleOverlay>,
                                    orderKey: entity.title
                                },
                                description: entity.description,
                                employees: entity.employees.length,
                                products: entity.products.length,
                            }
                            ))
                        } />,
        },
        {
            id: EViewType.CARD,
            title: langStrings.oftenUsed.cards,
            icon: <TableChart fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <Container>
                {
                    innerEntries.map(entity =>
                        <RoleCard
                            key={entity.id}
                            role={entity}
                        />)
                }
            </Container>,
        }
    ];

    const actions: IEntitiesPageAction[] = [
        {
            id: 'create',
            text: langStrings.newRole,
            icon: <Add />,
            filter()
            {
                if (!checkPermissions('Roles', 'Add', permissions))
                    return false;
                return true;

            },
            action()
            {
                return openDialog(<RoleDialog
                    id="RoleDialog"
                />);
            },
        },
        {
            id: 'merge',
            text: langStrings.merge,
            icon: <Merge />,
            filter(selectedCount)
            {
                if (!checkPermissions('Roles', 'Delete', permissions) || !checkPermissions('Roles', 'Edit', permissions))
                    return false;
                return selectedCount > 1;

            },
            action(selectedIDs)
            {
                if (!this.filter(selectedIDs.length)) return;
                openDialog(
                    <MergeRolesDialog
                        id="MergeRolesDialog"
                        resolve={(result) => { if (result) setSelected([]) }}
                        roleIds={entities
                            .filter(s => selected.indexOf(s.id) > -1)
                            .map(c => c.id)}
                    />
                )
            },
        }
    ];

    if (!maxLevel) return <LoadingBox />
    return (
        <EntitiesPage
            title={langStrings.roles}
            entities={entities}
            selected={selected}
            loadingError={loadingError}
            filter={(entity, selectedView, searchText) =>
                selectedView.filter(entity) && textContainsLowerCase(searchText, entity.title)
            }
            setSelected={setSelected}
            views={[
                {
                    id: 'all', title: langStrings.oftenUsed.allEntries,
                    filter()
                    {
                        return true;
                    },
                },
                {
                    id: 'my', title: langStrings.oftenUsed.myEntries,
                    filter(entity)
                    {
                        return entity.employees.find(e => e.id === emp?.id) !== undefined
                    },
                },
            ]}
            types={types}
            actions={actions}
        />
    );
}


export default RolesPage;

