import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface ISettingsTexts extends ITextBase
{
    zepInstance: string;
    zepToken: string;
    errorLoginData: string;
    timeSync: string;
    zep: string;
    noSuggestions: string;
    transforming: string;
    uploading: string;
    uploadButtonLabel: string;
    companyLogoTitle: string;
    uploadLogoInstructions: string;
    notifyAdmins: string;
    suggestionFrequency: string;
    noRejectionsAvailable: string;
    rejectionsDialogTitle: string;
    rejectionSettings: string;
    declineSuggestionAIReasonLabel: string;
    declineSuggestionReasonLabel: string;
    declineSuggestionElementsDescriptions: string;
    declineSuggestionDescription: string;
    declineSuggestionDialogTitle: string;
    declineSuggestionErrorEmptyReason: string;
    declineSuggestionErrorSaving: string;
    declineSuggestionReasonPlaceholder: string;
    acceptSuggestion: string;
    declineSuggestion: string;
    notSet: string;
    suggestions: string;
    savingSuccessfull: string;
    savingNotSuccessfull: string;
    sendingSuccessfull: string;
    sendingNotSuccessfull: string;
    emailExchangeMailbox: string;
    emailSendMethod: string;
    chooseEmailSendMethod: string;
    sendThroughExchange: string;
    sendThroughGlobal: string;
    noEmail: string;
    microsoftCertificateStatus: string;
    microsoftConnectionStatus: string;
    invalidPemFile: string;
    microsoftRegistrationStep1: string;
    microsoftRegistrationStep2: string;
    microsoftRegistrationStep3: string;
    microsoftRegistrationStep4: string;
    microsoftRegistrationStep5: string;
    microsoftRegistrationPermissionMailSend: string;
    microsoftRegistrationPermissionUserReadAll: string;
    microsoftRegistrationStep6: string;
    microsoftRegistrationStep7: string;
    microsoftRegistrationDescription: string;
    microsoftRegistrationLink: string;
    microsoftRegistrationLinkText: string;
    invalidTenantId: string;
    invalidClientId: string;
    invalidThumbprint: string;
    microsoftCertificateThumbprint: string;
    uploadCertificate: string;
    microsoftClientId: string;
    microsoftTenantId: string;
    microsoft: string;
    apiKey: string;
    pleaseEnterApiKeyNote: string;
    noApiKeysYet: string;
    addApiKey: string;
    note: string;
    restApiKeys: string;
    restAPI: string;
    activateCertificateReminder: string;
    forecastMail: string;
    certificateReminder: string;
    syncNow: string;
    lastUpdateProjects: string;
    lastUpdateEmployeeAbsence: string;
    lastUpdateEmployeeTimesThisYear: string;
    lastUpdateEmployeeTimesLastYear: string;
    lastUpdateCustomer: string;
    status: string;
    sendNowSubtitle: string;
    areYouSureForecastMail: string;
    sendNow: string;
    sendToMe: string;
    mailForecastText: string;
    mailSendHowOften: string;
    emailSettings: string;
    offline: string;
    memberOfGroupEmpty: string
    memberOfGroup: string;
    showMember: string;
    openHolidaysAPI: string;
    editMode: string;
    showMode: string;
    holidayManagement: string;
    sumOfDays: string;
    elementCount: string;
    holidaySource: string;
    holidayManuel: string;
    holidayTabError: string;
    holidayName: string;
    completeDay: string;
    holidays: string;
    clockodo: string;
    dataTabTitle: string;
    userAndOUs: string;
    industry: string;
    language: string;
    country: string;
    general: string;
    pastForecastMonth: string,
    futureForecastMonths: string,
    calculationsText: string;
    calculations: string;
    clockodoUserApiKey: string;
    clockodoUserEmail: string;
    clockodoSync: string;
    synchronisation: string;
    registration: string;
    entity: string;
    id: string;
    newPermissionGroup: string;
    permissionGroups: string;
    permissions: string;
    employeeWithoutOU: string;
    errorChangeName: string
    loading: string
    coloringTitle: string
    designTitle: string
    primaryColor: string
    onPrimaryColor: string
    secondaryColor: string
    onSecondaryColor: string
    levelDefinitions: string
    title: string
    level: string
    description: string
    explanation: string
    newLevelDefinition: string
    orgUnits: string
    leader: string
    employees: string
    createOu: string
    deleteOu: string
    createNewOrgUnder: string
    createOrgDescription: string
    orgName: string
    create: string
    save: string
    pleaseEnterOUname: string
    pleaseEnterLeaderName: string
}
type ILangData = {
    [lang in ELanguage]: ISettingsTexts
}
export const settingsTexts: ILangData = {
    'th': {
        zepInstance: 'อินสแตนซ์ ZEP',
        zepToken: 'โทเค็น ZEP',
        errorLoginData: 'ข้อมูลการเข้าสู่ระบบไม่ถูกต้อง กรุณาตรวจสอบการป้อนข้อมูลของคุณ',
        timeSync: 'การจับเวลาซิงค์',
        zep: 'เซป',
        noSuggestions: 'ไม่มีข้อเสนอแนะ',
        transforming: 'กำลังแปลง...',
        uploading: 'กำลังอัปโหลด...',
        uploadButtonLabel: 'อัพโหลดรูปภาพ',
        uploadLogoInstructions: 'กรุณาอัปโหลดโลโก้บริษัทของคุณที่นี่ รองรับรูปแบบภาพเช่น JPG หรือ PNG',
        companyLogoTitle: 'อัปโหลดโลโก้บริษัท',
        designTitle: 'ลักษณะ',
        suggestionFrequency: 'ต้องการให้สร้างข้อเสนออัตโนมัติบ่อยแค่ไหน?',
        notifyAdmins: 'ต้องการแจ้งผู้ดูแลเกี่ยวกับข้อเสนอใหม่หรือไม่?',
        noRejectionsAvailable: 'ยังไม่มีข้อเสนอที่ถูกปฏิเสธ',
        rejectionsDialogTitle: 'ข้อเสนอที่ถูกปฏิเสธ',
        rejectionSettings: 'การตั้งค่าการปฏิเสธ',
        declineSuggestionReasonLabel: 'เหตุผลในการปฏิเสธคำแนะนำ',
        declineSuggestionAIReasonLabel: 'เหตุผลของ AI',
        declineSuggestionElementsDescriptions: 'องค์ประกอบที่เกี่ยวข้อง',
        declineSuggestionDescription: 'คุณสามารถปฏิเสธข้อเสนอแนะที่นี่และทิ้งเหตุผลไว้ เหตุผลของคุณจะถูกเก็บบันทึกและใช้เพื่อปรับปรุงข้อเสนอแนะของ AI ในอนาคต',
        declineSuggestionDialogTitle: 'ปฏิเสธคำแนะนำ',
        declineSuggestionReasonPlaceholder: 'โปรดใส่เหตุผลของคุณ...',
        declineSuggestionErrorSaving: 'เกิดข้อผิดพลาดขณะบันทึกเหตุผล กรุณาลองใหม่อีกครั้ง',
        declineSuggestionErrorEmptyReason: 'โปรดระบุเหตุผล',
        acceptSuggestion: 'คำพ้องความหมายนี้หรือบางคำอาจถูกรวมกันในขั้นตอนถัดไป',
        declineSuggestion: 'โปรดให้เหตุผลการตัดสินใจของคุณในขั้นตอนถัดไป เพื่อให้ AI-algorithm ที่อยู่เบื้องหลังสามารถเรียนรู้และพัฒนาตนเองบนพื้นฐานของเหตุผลได้',
        notSet: 'ไม่มี',
        suggestions: 'ข้อเสนอแนะการปรับปรุง',
        savingNotSuccessfull: 'ไม่สามารถบันทึกการตั้งค่าได้',
        savingSuccessfull: 'การบันทึกการตั้งค่าเสร็จสมบูรณ์',
        sendingSuccessfull: 'การส่งอีเมลสำเร็จ!',
        sendingNotSuccessfull: 'การส่งอีเมลไม่ประสบความสำเร็จ!',
        emailExchangeMailbox: 'กล่องจดหมายของผู้ส่ง',
        emailSendMethod: 'วิธีการส่งอีเมล',
        noEmail: 'ไม่ส่งอีเมล',
        sendThroughGlobal: 'ส่งผ่านที่อยู่อีเมลทั่วโลก',
        sendThroughExchange: 'ส่งผ่านกล่องจดหมาย Exchange',
        chooseEmailSendMethod: 'เลือกวิธีการส่งอีเมล',
        microsoftCertificateStatus: 'สถานะใบรับรอง',
        microsoftConnectionStatus: 'การเชื่อมต่อไปยังผู้เช่า Microsoft',
        invalidPemFile: 'ไฟล์ PEM ไม่ถูกต้อง โปรดอัพโหลดใบรับรองที่ถูกต้องในรูปแบบ .pem',
        microsoftRegistrationDescription: "ในการรวมแอปพลิเคชันของคุณเข้ากับ Microsoft Azure คุณจำเป็นต้องลงทะเบียนแอปและอัปโหลดใบรับรองที่ลงนามด้วยตัวเอง ทำตามขั้นตอนด้านล่าง:",
        microsoftRegistrationStep1: "คลิกลิงก์ต่อไปนี้เพื่อไปยังพอร์ทัลลงทะเบียนแอปของ Azure:",
        microsoftRegistrationStep2: "ลงทะเบียนแอปพลิเคชันใหม่โดยคลิกที่ 'การลงทะเบียนใหม่' ป้อนชื่อแอปพลิเคชันและเลือกประเภทบัญชีที่รองรับตามต้องการ",
        microsoftRegistrationStep3: "หลังจากการลงทะเบียน Tenant ID และ Client ID จะถูกแสดง คุณต้องป้อนข้อมูลนี้ลงในช่องที่เกี่ยวข้องในหน้านี้",
        microsoftRegistrationStep4: "ไปที่ 'ใบรับรองและความลับ' ในพอร์ทัลลงทะเบียนแอป และอัปโหลดใบรับรองที่ลงนามด้วยตนเอง คุณยังต้องอัปโหลดใบรับรองนี้ในหน้านี้เพื่อใช้ในการตรวจสอบสิทธิ์",
        microsoftRegistrationStep5: "ไปที่ 'การอนุญาต API' และเพิ่มสิทธิ์ Microsoft Graph ต่อไปนี้:",
        microsoftRegistrationPermissionMailSend: "Mail.Send – อนุญาตให้ส่งอีเมลในนามของผู้ใช้",
        microsoftRegistrationPermissionUserReadAll: "User.Read.All – อนุญาตให้อ่านข้อมูลผู้ใช้ในไดเรกทอรี",
        microsoftRegistrationStep6: "ตรวจสอบให้แน่ใจว่าได้ให้สิทธิ์การยินยอมของผู้ดูแลระบบสำหรับการอนุญาตเหล่านี้",
        microsoftRegistrationStep7: "บันทึกการเปลี่ยนแปลงทั้งหมดและกลับมาที่หน้านี้เพื่ออัปโหลด Client ID, Tenant ID และใบรับรอง",
        invalidThumbprint: 'ลายนิ้วมือไม่ถูกต้อง โปรดใส่สตริงตัวเลขฐานสิบหกที่มีความยาว 40 ตัวอักษร.',
        invalidClientId: 'Client ID ไม่ถูกต้อง โปรดใส่ GUID ที่ถูกต้อง.',
        invalidTenantId: 'Tenant ID ไม่ถูกต้อง โปรดใส่ GUID ที่ถูกต้อง.',
        microsoftRegistrationLinkText: 'พอร์ทัลการลงทะเบียนแอป Azure',
        microsoftRegistrationLink: 'https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationsListBlade',
        microsoftClientId: 'รหัสแอปพลิเคชันของ Microsoft (ไคลเอนต์)',
        uploadCertificate: 'อัปโหลดใบรับรอง',
        microsoftCertificateThumbprint: 'หมายเลขประจำตัวใบรับรอง',
        microsoftTenantId: 'รหัสไดเรกทอรี Microsoft (ผู้เช่า) (เช่น cb6a815c-BBBB-4fca-0000-10201af20000)',
        microsoft: 'ไมโครซอฟท์',
        apiKey: 'คีย์ API',
        pleaseEnterApiKeyNote: 'โปรดใส่หมายเหตุสำหรับคีย์ API ใหม่',
        noApiKeysYet: 'ยังไม่มีการสร้าง API-Keys ใดๆ',
        addApiKey: 'เพิ่มคีย์ API',
        note: 'บันทึกย่อ',
        restApiKeys: 'คีย์สำหรับ REST API',
        restAPI: 'REST API',
        lastUpdateEmployeeTimesThisYear: 'การอัปเดตเวลาของพนักงานครั้งล่าสุด (สำหรับปีนี้)',
        lastUpdateEmployeeTimesLastYear: 'การอัปเดตเวลาของพนักงานครั้งล่าสุด (สำหรับปีที่แล้ว)',
        activateCertificateReminder: 'เปิดใช้งานอีเมลแจ้งเตือนใบรับรองอัตโนมัติหรือไม่?',
        forecastMail: 'อีเมลพยากรณ์อากาศ',
        certificateReminder: 'การแจ้งเตือนใบรับรอง',
        syncNow: 'ซิงค์ข้อมูลเดี๋ยวนี้',
        status: 'สถานะ',
        lastUpdateCustomer: 'อัปเดตข้อมูลลูกค้าครั้งล่าสุด',
        lastUpdateEmployeeAbsence: 'อัปเดตการขาดงานของพนักงานครั้งล่าสุด',
        lastUpdateProjects: 'อัปเดตโครงการครั้งล่าสุด',
        sendNowSubtitle: 'โปรดทราบ, การกระทำนี้จะส่งอีเมลพยากรณ์ไปยังพนักงานทุกคนทันที',
        areYouSureForecastMail: 'คุณแน่ใจหรือว่าต้องการส่งอีเมลถึงพนักงานทุกคน?',
        sendNow: 'ส่งตอนนี้',
        sendToMe: 'ส่งถึงฉัน (ทดสอบ)',
        mailForecastText: 'คุณต้องการเพิ่มเติมข้อความในอีเมล์หรือไม่?',
        mailSendHowOften: 'คุณต้องการให้ส่งอีเมล์นี้บ่อยแค่ไหน?',
        emailSettings: 'ตั้งค่าอีเมล',
        offline: 'ไม่สามารถใช้งานการตั้งค่าในโหมดออฟไลน์ได้',
        memberOfGroupEmpty: 'กลุ่มนี้ไม่มีสมาชิก',
        memberOfGroup: 'สมาชิกของกลุ่มสิทธิ์ [GROUPNAME]',
        showMember: 'แสดงสมาชิก',
        completeDay: 'ตลอดทั้งวัน?',
        openHolidaysAPI: 'OpenHolidays API',
        editMode: 'โหมดการแก้ไข',
        showMode: 'โหมดการแสดงผล',
        holidayManagement: 'การจัดการวันหยุด',
        sumOfDays: 'ผลรวมของวัน',
        elementCount: 'จำนวนครั้ง',
        holidaySource: 'แหล่งข้อมูลวันหยุด',
        holidayManuel: 'ดูแลด้วยมือ',
        holidayTabError: 'กรุณากรอกข้อมูลในทุกช่อง',
        holidayName: 'ชื่อวันหยุด',
        oftenUsed: oftenUsedTranslations['th'],
        holidays: 'วันหยุด',
        dataTabTitle: 'ข้อมูลและการประมวลผล',
        userAndOUs: 'ผู้ใช้ & OUs (IAM)',
        industry: 'อุตสาหกรรม',
        language: 'ภาษา',
        country: 'ประเทศ',
        general: 'ทั่วไป',
        calculationsText: 'กำหนดจำนวนเดือนที่ผ่านมาและเดือนที่จะมาของการคำนวณของคุณ\nเดือนปัจจุบันถือว่าเป็นเดือนในอนาคต',
        futureForecastMonths: 'เดือนในอนาคต',
        pastForecastMonth: 'เดือนที่ผ่านมา',
        calculations: 'การคำนวณ',
        coloringTitle: 'ช่วงสี',
        errorChangeName: 'เปลี่ยนชื่อไม่สำเร็จ!',
        loading: oftenUsedTranslations['th'].loading,
        primaryColor: 'สีหลัก',
        onPrimaryColor: 'สีบนพื้นหลังหลัก',
        secondaryColor: 'สีรอง',
        onSecondaryColor: 'สีบนพื้นหลังรอง',
        levelDefinitions: 'การกำหนดระดับ',
        title: 'ชื่อเรื่อง',
        level: 'ระดับ',
        description: oftenUsedTranslations['th'].description,
        explanation: oftenUsedTranslations['th'].description,
        newLevelDefinition: 'การกำหนดระดับใหม่',
        orgUnits: oftenUsedTranslations['th'].orgUnits,
        leader: 'ผู้นำ',
        employees: oftenUsedTranslations['th'].employees,
        createOu: 'สร้างหน่วยงาน',
        deleteOu: 'ลบหน่วยงาน',
        createNewOrgUnder: 'สร้างหน่วยงานใหม่ภายใต้',
        createOrgDescription: 'โปรดป้อนหน่วยงานทั้งหมดที่คุณต้องการสร้างภายใต้',
        orgName: 'ชื่อหน่วยงาน',
        create: oftenUsedTranslations['th'].create,
        save: oftenUsedTranslations['th'].save,
        employeeWithoutOU: 'พนักงานที่ไม่มีหน่วยงาน',
        pleaseEnterOUname: 'โปรดป้อนชื่อหน่วยงาน',
        pleaseEnterLeaderName: 'โปรดเลือกผู้นำ',
        permissions: oftenUsedTranslations['th'].permissions,
        permissionGroups: 'กลุ่มสิทธิ์การเข้าถึง',
        newPermissionGroup: 'กลุ่มสิทธิ์การเข้าถึงใหม่',
        id: "ID",
        entity: 'องค์กร',
        registration: 'การลงทะเบียน',
        synchronisation: 'การประสาน',
        clockodoSync: 'การประสานกับ Clockodo',
        clockodoUserEmail: 'อีเมลผู้ใช้สำหรับการประสานกับ Clockodo',
        clockodoUserApiKey: 'คีย์ API ผู้ใช้สำหรับการประสานกับ Clockodo',
        clockodo: 'Clockodo',
    },
    'en': {
        zepInstance: 'ZEP Instance',
        zepToken: 'ZEP token',
        errorLoginData: 'The login credentials are invalid. Please check your input.',
        timeSync: 'Time Sync',
        zep: 'ZEP',
        noSuggestions: 'No suggestions available.',
        transforming: 'Transforming...',
        uploading: 'Uploading...',
        uploadButtonLabel: 'Upload Image',
        uploadLogoInstructions: 'Please upload your company logo here. Supported image formats include JPG or PNG.',
        companyLogoTitle: 'Upload Company Logo',
        designTitle: 'Appearance',
        suggestionFrequency: 'How often should suggestions be generated automatically?',
        notifyAdmins: 'Notify admins about new suggestions?',
        noRejectionsAvailable: 'No proposals have been rejected yet.',
        rejectionsDialogTitle: 'Rejected Suggestions',
        rejectionSettings: 'Rejection settings',
        declineSuggestionReasonLabel: 'Reason for declining suggestion',
        declineSuggestionAIReasonLabel: 'AI\'s Reason',
        declineSuggestionElementsDescriptions: 'Relevant elements',
        declineSuggestionDescription: 'Here you can decline the suggestion and leave a reason. Your reason will be recorded and used in the future to improve AI suggestions.',
        declineSuggestionDialogTitle: 'Decline Suggestion',
        declineSuggestionReasonPlaceholder: 'Please enter your reason...',
        declineSuggestionErrorSaving: 'An error occurred while saving the reason. Please try again.',
        declineSuggestionErrorEmptyReason: 'Please provide a reason.',
        acceptSuggestion: 'This/some synonyms can be merged in the next step.',
        declineSuggestion: 'Please justify your decision in the next step, so that the underlying AI algorithm can learn and develop based on the rationale.',
        notSet: 'not set',
        suggestions: 'Suggestions for improvement',
        savingNotSuccessfull: 'The settings could not be saved.',
        savingSuccessfull: 'The settings were saved successfully.',
        sendingSuccessfull: 'The email was sent successfully!',
        sendingNotSuccessfull: 'The email sending was not successful!',
        emailExchangeMailbox: 'Sender\'s email mailbox',
        emailSendMethod: 'Email Sending Method',
        noEmail: 'Do not send email',
        sendThroughGlobal: 'Send through global email address',
        sendThroughExchange: 'Send through Exchange mailbox',
        chooseEmailSendMethod: 'Choose the method for sending email',
        microsoftCertificateStatus: 'Certificate Status',
        microsoftConnectionStatus: 'Connection to Microsoft Tenant',
        invalidPemFile: 'Invalid PEM file. Please upload a valid certificate in .pem format.',
        microsoftRegistrationDescription: "To integrate your application with Microsoft Azure, you need to register an app and upload a self-signed certificate. Follow the steps below:",
        microsoftRegistrationStep1: "Click the following link to go to the Azure App Registration Portal:",
        microsoftRegistrationStep2: "Register a new application by clicking 'New Registration'. Enter a name for the application and choose the appropriate supported account types.",
        microsoftRegistrationStep3: "After registration, the Tenant ID and Client ID will be displayed. You need to enter this information into the corresponding fields on this page.",
        microsoftRegistrationStep4: "Navigate to 'Certificates & Secrets' in the App Registration Portal and upload a self-signed certificate. You also need to upload this certificate on this page to be used for authentication.",
        microsoftRegistrationStep5: "Go to 'API Permissions' and add the following Microsoft Graph permissions:",
        microsoftRegistrationPermissionMailSend: "Mail.Send – Allows sending emails on behalf of the user.",
        microsoftRegistrationPermissionUserReadAll: "User.Read.All – Allows reading user information in the directory.",
        microsoftRegistrationStep6: "Make sure to grant admin consent for these permissions.",
        microsoftRegistrationStep7: "Save all changes and return to this page to upload the Client ID, Tenant ID, and certificate.",

        invalidThumbprint: 'Invalid thumbprint. Please enter a valid 40-character hexadecimal string.',
        invalidClientId: 'Invalid Client ID. Please enter a valid GUID.',
        invalidTenantId: 'Invalid Tenant ID. Please enter a valid GUID.',
        microsoftRegistrationLinkText: 'Azure App Registration Portal',
        microsoftRegistrationLink: 'https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationsListBlade',
        microsoftClientId: 'Microsoft Application ID (Client)',
        uploadCertificate: 'Upload Certificate',
        microsoftCertificateThumbprint: 'Certificate Thumbprint',
        microsoftTenantId: 'Microsoft Directory ID (Tenant) (e.g., cb6a815c-BBBB-4fca-0000-10201af20000)',
        microsoft: 'Microsoft',
        apiKey: 'API Key',
        pleaseEnterApiKeyNote: 'Please enter a note for the new API Key.',
        noApiKeysYet: 'No API keys have been created yet.',
        addApiKey: 'Add API Key',
        note: 'Note',
        restApiKeys: 'REST API Keys',
        restAPI: 'REST API',
        lastUpdateEmployeeTimesThisYear: 'Last Update of Employee Times (For This Year)',
        lastUpdateEmployeeTimesLastYear: 'Last Update of Employee Times (For Last Year)',
        activateCertificateReminder: 'Activate automatic certificate reminder emails?',
        forecastMail: 'Forecast Email',
        certificateReminder: 'Certificate Reminders',
        syncNow: 'Sync now',
        status: 'Status',
        lastUpdateCustomer: 'Last Company Update',
        lastUpdateEmployeeAbsence: 'Last Employee Absence Update',
        lastUpdateProjects: 'Last Project Update',
        sendNowSubtitle: 'Please note, this will send the forecast email immediately to all employees.',
        areYouSureForecastMail: 'Are you sure you want to send the email to all employees?',
        sendNow: 'Send now',
        sendToMe: 'Send to me (Test)',
        mailForecastText: 'Would you like to supplement the email text?',
        mailSendHowOften: 'How often should the email be sent?',
        emailSettings: 'Email settings',
        offline: 'Settings are not available in offline mode.',
        memberOfGroupEmpty: 'The group has no members',
        memberOfGroup: 'Members of the permission group [GROUPNAME]',
        showMember: 'Show members',
        completeDay: 'All day?',
        openHolidaysAPI: 'OpenHolidays API',
        editMode: 'Edit mode',
        showMode: 'Display mode',
        holidayManagement: 'Holiday Management',
        sumOfDays: 'Sum of days',
        elementCount: 'Number of entries',
        holidaySource: 'Holiday Source',
        holidayManuel: 'maintain manually',
        holidayTabError: 'Please fill out all fields.',
        holidayName: 'Holiday name',
        oftenUsed: oftenUsedTranslations['en'],
        holidays: 'Holidays',
        dataTabTitle: 'Data & Processing',
        userAndOUs: 'Users & OUs (IAM)',
        industry: 'Industry',
        language: 'Language',
        country: 'Country',
        general: 'General',
        calculationsText: 'Define the number of past and future months for your calculations.\nThe current month counts as future months.',
        futureForecastMonths: 'Future months',
        pastForecastMonth: 'Past months',
        calculations: 'calculations',
        coloringTitle: 'Color palette',
        errorChangeName: "Failed to change the name!",
        loading: oftenUsedTranslations['en'].loading,
        primaryColor: 'Primary color',
        onPrimaryColor: 'On primary color',
        secondaryColor: 'Secondary color',
        onSecondaryColor: 'On secondary color',
        levelDefinitions: 'Level-Definitions',
        title: 'Title',
        level: 'Level',
        description: oftenUsedTranslations['en'].description,
        explanation: 'Explanation',
        newLevelDefinition: 'New level-definition',
        orgUnits: 'Organisational units',
        leader: 'Leader',
        employees: oftenUsedTranslations['en'].employees,
        createOu: 'Create OU',
        deleteOu: 'Delete OU',
        createNewOrgUnder: 'Create new OU under',
        createOrgDescription: 'Please enter all the Organisational units you want to create under',
        orgName: 'Organisational unit',
        create: 'Create',
        save: oftenUsedTranslations['en'].save,
        employeeWithoutOU: 'Employees without organizational unit',
        pleaseEnterOUname: 'Please enter a OU-name.',
        pleaseEnterLeaderName: 'Please select a leader.',
        permissions: oftenUsedTranslations['en'].permissions,
        permissionGroups: 'Permission Groups',
        newPermissionGroup: 'New Permission Group',
        id: "ID",
        entity: 'Entity',
        registration: 'Registration',
        synchronisation: 'Synchronization',
        clockodoSync: 'Clockodo Synchronization',
        clockodoUserEmail: 'Clockodo Synchronization User Email Address',
        clockodoUserApiKey: 'Clockodo Synchronization User API Key',
        clockodo: 'Clockodo',
    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        coloringTitle: 'Farbgebung',
        errorChangeName: 'Fehler beim ändern des Namens!',
        loading: oftenUsedTranslations['de'].loading,
        primaryColor: 'Primärfarbe',
        onPrimaryColor: 'Schrift auf Primärfarbe',
        secondaryColor: 'Sekundärfarbe',
        onSecondaryColor: 'Schrift auf Sekundärfarbe',
        levelDefinitions: 'Stufen-Definitionen',
        title: 'Titel',
        level: 'Stufe',
        description: oftenUsedTranslations['de'].description,
        explanation: 'Erklärung',
        newLevelDefinition: 'Neue Level-Definition',
        orgUnits: oftenUsedTranslations['de'].orgUnits,
        leader: 'Leitung',
        employees: oftenUsedTranslations['de'].employees,
        createOu: 'Organisationseinheit erstellen',
        deleteOu: 'Organisationseinheit löschen',
        createNewOrgUnder: 'Neue Organisationseinheit unter',
        createOrgDescription: 'Bitte gebe alle organisatorischen Einheiten ein, die du erstellen möchtest.',
        orgName: oftenUsedTranslations['de'].orgUnit,
        create: oftenUsedTranslations['de'].create,
        save: oftenUsedTranslations['de'].save,
        employeeWithoutOU: 'Mitarbeiter ohne Organisationseinheit',
        pleaseEnterOUname: 'Bitte trage einen OU Namen ein.',
        pleaseEnterLeaderName: 'Bitte trage eine Führungskraft ein.',
        permissions: oftenUsedTranslations['de'].permissions,
        permissionGroups: 'Berechtigungsgruppen',
        newPermissionGroup: 'Neue Berechtigungsgruppe',
        id: "ID",
        entity: "Entität",
        registration: "Registration",
        synchronisation: "Synchronisation",
        clockodoSync: 'Clockodo Synchronisation',
        clockodoUserEmail: 'Clockodo Synchronisations-Nutzer-E-Mail-Adresse',
        clockodoUserApiKey: 'Clockodo Synchronisations-Nutzer API-Key',
        calculations: 'Kalkulationen',
        calculationsText: 'Legen die Anzahl der vergangenen und zukünftigen Monate für die Kalkulationen fest.\nDer aktuelle Monat zählt zu den zukünftigen Monaten.',
        pastForecastMonth: 'Vergangene Monate',
        futureForecastMonths: 'Zukünftige Monate',
        general: 'Allgemein',
        country: 'Land',
        language: 'Sprache',
        industry: 'Branche',
        userAndOUs: 'Nutzer & OUs (IAM)',
        dataTabTitle: 'Daten & Verarbeitung',
        clockodo: 'Clockodo',
        holidays: 'Feiertage',
        holidayName: 'Feiertagsname',
        completeDay: 'Ganztägig?',
        holidayTabError: 'Bitte fülle alle Felder aus.',
        holidayManuel: 'Manuell pflegen',
        holidaySource: 'Feiertagsquelle',
        elementCount: 'Anzahl der Eintäge',
        sumOfDays: 'Summe der Tage',
        holidayManagement: 'Feiertagsverwaltung',
        showMode: 'Anzeigemodus',
        editMode: 'Bearbeitungsmodus',
        openHolidaysAPI: 'OpenHolidays API',
        showMember: 'Mitglieder anzeigen',
        memberOfGroup: 'Mitglieder der Berechtigungsgruppe [GROUPNAME]',
        memberOfGroupEmpty: 'Die Gruppe hat keine Mitglieder',
        offline: 'Die Einstellungen stehen Offline leider nicht zur Verfügung.',
        emailSettings: 'E-Mail',
        mailSendHowOften: 'Wie oft soll die E-Mail versendet werden?',
        mailForecastText: 'Möchten Sie den E-Mail Text ergänzen?',
        sendToMe: 'An mich senden (Test)',
        sendNow: 'Jetzt versenden',
        areYouSureForecastMail: 'Bist du sicher das du die E-Mail an alle Mitarbeiter versenden möchtest?',
        sendNowSubtitle: 'Achtung, dies versendet die Forecast-E-Mail jetzt sofort an alle Mitarbeiter.',
        lastUpdateProjects: 'Letztes Projekt Update',

        lastUpdateEmployeeAbsence: 'Letztes Abwesenheiten Update',
        lastUpdateEmployeeTimesLastYear: 'Letztes Mitarbeiter Zeiten Update (Für letztes Jahr)',
        lastUpdateEmployeeTimesThisYear: 'Letztes Mitarbeiter Zeiten Update (Für dieses Jahr)',
        lastUpdateCustomer: 'Letztes Firmen Update',
        status: 'Status',
        syncNow: 'Jetzt synchronisieren',
        certificateReminder: 'Zertifikats-Erinnerungen',
        forecastMail: 'Forecast-Mail',
        activateCertificateReminder: 'Automatische Zertifikats-Emails aktivieren?',

        restAPI: 'REST API',
        restApiKeys: 'REST API Keys',
        note: 'Notiz',
        addApiKey: 'API Key hinzufügen',
        noApiKeysYet: 'Es wurden noch keine API-Keys angelegt.',
        pleaseEnterApiKeyNote: 'Bitte gib eine Notiz für den neuen API Key ein.',
        apiKey: 'API-Key',

        microsoft: 'Microsoft',
        microsoftTenantId: 'Microsoft Verzeichnis-ID (Mandant) (z.B. cb6a815c-BBBB-4fca-0000-10201af20000)',
        microsoftCertificateThumbprint: 'Zertifikat-Thumbprint',
        microsoftClientId: 'Microsoft Anwendungs-ID (Client)',
        uploadCertificate: "PEM-Zertifikat hochladen",
        microsoftRegistrationLink: "https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationsListBlade",
        microsoftRegistrationLinkText: "Azure App-Registrierungsportal",
        invalidTenantId: "Ungültige Tenant-ID. Bitte geben Sie eine gültige GUID ein.",
        invalidClientId: "Ungültige Client-ID. Bitte geben Sie eine gültige GUID ein.",
        invalidThumbprint: "Ungültiger Fingerabdruck. Bitte geben Sie eine gültige 40-stellige Hexadezimalzeichenfolge ein.",

        microsoftRegistrationDescription: "Um deine Anwendung mit Microsoft Azure zu integrieren, musst du eine App-Registrierung vornehmen und ein selbstsigniertes Zertifikat hochladen. Folge den untenstehenden Schritten:",
        microsoftRegistrationStep1: "Klicke auf den folgenden Link, um zum Azure App-Registrierungsportal zu gelangen:",
        microsoftRegistrationStep2: "Registriere eine neue Anwendung, indem du auf „Neue Registrierung“ klickst. Gib einen Namen für die Anwendung ein und wähle die entsprechenden unterstützten Kontotypen aus.",
        microsoftRegistrationStep3: "Nach der Registrierung wird die Tenant ID und Client ID angezeigt. Diese Informationen musst du in die entsprechenden Felder auf dieser Seite eintragen.",
        microsoftRegistrationStep4: "Navigiere im App-Registrierungsportal zu „Zertifikate & Geheimnisse“ und lade ein selbstsigniertes Zertifikat hoch. Dieses Zertifikat musst du ebenfalls auf dieser Seite hochladen, damit es für die Authentifizierung verwendet werden kann.",
        microsoftRegistrationStep5: "Gehe zu „API-Berechtigungen“ und füge die folgenden Microsoft Graph-Berechtigungen hinzu:",
        microsoftRegistrationPermissionMailSend: "Mail.Send – Ermöglicht das Senden von E-Mails im Namen des Benutzers.",
        microsoftRegistrationPermissionUserReadAll: "User.Read.All – Ermöglicht das Lesen von Benutzerinformationen im Verzeichnis.",
        microsoftRegistrationStep6: "Stelle sicher, dass du die Administratorzustimmung für diese Berechtigungen erteilst.",
        microsoftRegistrationStep7: "Speichere alle Änderungen und kehre auf diese Seite zurück, um die Client ID, Tenant ID und das Zertifikat hochzuladen.",
        invalidPemFile: "Ungültige PEM-Datei. Bitte laden Sie ein gültiges Zertifikat im .pem Format hoch.",
        microsoftConnectionStatus: 'Verbindung zum Microsoft Tenant',
        microsoftCertificateStatus: 'Zertifikat Status',

        chooseEmailSendMethod: "Wähle die Methode für den E-Mail-Versand",
        sendThroughExchange: "Über Exchange-Postfach versenden",
        sendThroughGlobal: "Über globale E-Mail-Adresse versenden",
        noEmail: "Keine E-Mail versenden",

        emailSendMethod: 'E-Mail-Versand-Methode',
        emailExchangeMailbox: 'Absender E-Mail-Postfach',
        sendingNotSuccessfull: 'Der E-Mail-Versand war nicht erfolgreich!',
        sendingSuccessfull: 'Der E-Mail-Versand war erfolgreich!',

        savingSuccessfull: 'Die Einstellungen wurden erfolgreich gespeichert.',
        savingNotSuccessfull: 'Die Einstellungen konnten nicht gespeichert werden.',
        suggestions: 'Verbesserungsvorschläge',

        notSet: 'nicht vorhanden',
        declineSuggestion: 'Bitte begründe Deine Entscheidung im nächsten Schritt, damit der zugrunde liegende KI-Algorithmus auf Basis der Begründung lernen und sich weiterentwickeln kann.',
        acceptSuggestion: 'Diese/einige Synonyme können im nächsten Schritt zusammengeführt werden.',
        declineSuggestionErrorEmptyReason: 'Bitte gib eine Begrüdung ein.',
        declineSuggestionErrorSaving: 'Beim Speichern der Begründung ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        declineSuggestionReasonLabel: 'Begründung für die Ablehnung',
        declineSuggestionReasonPlaceholder: 'Bitte gib deine Begründung ein...',
        declineSuggestionDialogTitle: 'Vorschlag ablehnen',
        declineSuggestionDescription: 'Hier kannst du den Vorschlag ablehnen und eine Begründung hinterlassen. Deine Begründung wird gespeichert und ausschließlich im Kontext deines Unternehmens verwendet, um zukünftige AI-Vorschläge zu verbessern.',
        declineSuggestionElementsDescriptions: 'Relevante Elemente',
        declineSuggestionAIReasonLabel: 'Begründung der KI',
        rejectionSettings: 'Ablehungseinstellungen',
        rejectionsDialogTitle: 'Abgelehnte Vorschläge',
        noRejectionsAvailable: 'Es wurden bisher keine Vorschläge abgelehnt.',
        notifyAdmins: 'Admins über neue Vorschläge informieren?',
        suggestionFrequency: 'Wie oft sollen die Vorschläge automatisch generiert werden?',
        designTitle: 'Aussehen',
        companyLogoTitle: 'Firmenlogo hochladen',
        uploadLogoInstructions: 'Bitte laden Sie hier Ihr Firmenlogo hoch. Unterstützt werden Bildformate wie JPG oder PNG.',
        uploadButtonLabel: 'Bild hochladen',
        uploading: 'Wird hochgeladen...',
        transforming: 'Wird verarbeitet...',
        noSuggestions: 'Keine Vorschläge vorhanden.',
        zep: 'ZEP',
        timeSync: 'Zeiterfassung',
        errorLoginData: 'Die Anmeldeinformationen sind ungültig. Bitte überprüfe deine Eingaben.',
        zepToken: 'ZEP-Token',
        zepInstance: 'ZEP-Instanz'
    },
}