import { OperationVariables, gql } from "@apollo/client";
import { ISearchSuggestions } from "@sharedInterfaces/ICompany";

import GraphQL from "../graphQL";

const q = gql`
query getSearchSuggestions($searchText: String!) {
    getSearchSuggestions(searchText:  $searchText) 
    {
        id
        type
        label
        score
    }
},
`;

export default async function getSearchSuggestions(searchText: string, callback: (suggestions: ISearchSuggestions[]) => void)
{
    // return callback(defaultSuggestions.filter(suggestion => suggestion.label.toLowerCase().includes(searchText.toLowerCase())))
    const variables: OperationVariables = { searchText };
    return GraphQL.query<{ getSearchSuggestions: ISearchSuggestions[] }>(q, variables, (obj) =>
        callback(!obj || obj.getSearchSuggestions === null ? defaultSuggestions : obj.getSearchSuggestions));
}

const defaultSuggestions: ISearchSuggestions[] = [
    // { label: 'Work in Progress', type: EEntityType.EMPLOYEESETTINGS, id: 5 },
    // { label: 'Dummy 2', type: EEntityType.SKILL_CATEGORY, id: 1 },
    // { label: 'Hier könnte ihre Werbung stehen', type: EEntityType.ABSENCE, id: 2 },
    // { label: 'Eine suche ist was schönes', type: EEntityType.SKILL, id: 3 },
    // { label: 'Besonders dann wenn diese auch funktioniert.', type: EEntityType.EMPLOYEE, id: 4 },
]