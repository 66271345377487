import { ELanguage } from "../languageHelper";

import { oftenUsedTranslations } from "./oftenUsed"

export interface IFirstExpirienceDialogTexts
{
    save: string;
    firstname: string;
    lastname: string;
    gender: string;
    markAsRead: string
    next: string;
    previous: string
    close: string;
    title: string
    title2: string
    greeting: string
    infoText1: string
    infoText2: string
}
type ILangData = {
    [lang in ELanguage]: IFirstExpirienceDialogTexts
}
export const firstExpirienceDialog: ILangData = {
    'th': {
        firstname: oftenUsedTranslations['th'].firstName,
        lastname: oftenUsedTranslations['th'].lastName,
        gender: oftenUsedTranslations['th'].gender,
        title: 'ยินดีต้อนรับและข้อมูลพื้นฐาน',
        title2: 'การดึงข้อมูลจากเรซูเม่',
        greeting: 'ยินดีต้อนรับสู่ Competence Manager ของ [COMPANY]!',
        infoText1: 'เป็นผู้ใช้ใหม่ เราขอให้คุณกรุณาให้ข้อมูลพื้นฐานบางส่วนเพื่อปรับปรุงประสบการณ์ในแอปของเรา โปรดกรอกเพศของคุณ ชื่อและนามสกุลของคุณ นี้จะช่วยให้เราสามารถพูดถึงคุณได้ถูกต้องในแอปและปรับปรุงประสบการณ์ของคุณได้อย่างเหมาะสม ขอบคุณสำหรับความร่วมมือของคุณ!',
        close: oftenUsedTranslations['th'].close,
        next: oftenUsedTranslations['th'].next,
        previous: oftenUsedTranslations['th'].previous,
        markAsRead: oftenUsedTranslations['th'].markAsRead,
        infoText2: 'เคล็ดลับ: คุณรู้หรือไม่ว่าด้วยคุณสมบัตินวัตกรรมของเรา คุณสามารถดึงข้อมูลเช่นทักษะ ใบรับรองและคำอธิบายส่วนบุคคลโดยตรงจากเรซูเม่ของคุณ? ใช้ฟังก์ชั่นนี้เพื่อประหยัดเวลาที่มีค่าและให้แน่ใจว่าคุณสามารถป้อนข้อมูลที่เกี่ยวข้องเข้าสู่ระบบได้อย่างรวดเร็วและง่ายดาย และสิ่งที่ดีที่สุด? กระบวนการนี้ใช้เวลาเพียงไม่กี่นาทีและสามารถประหยัดเวลาให้คุณมากได้ ลองใช้กันเลย!',
        save: oftenUsedTranslations['th'].save,
    },
    'en': {
        firstname: oftenUsedTranslations['en'].firstName,
        lastname: oftenUsedTranslations['en'].lastName,
        gender: oftenUsedTranslations['en'].gender,
        title: 'Welcome & Basic Information',
        title2: 'Information Extraction from Resume',
        greeting: 'Welcome to the Competence Manager by [COMPANY]!',
        infoText1: 'As a new user, we kindly ask you to provide some basic information to personalize your experience in our app. Please fill in your gender, first name, and last name. This will help us address you correctly in the app and optimize your experience. Thank you for your cooperation!',
        close: oftenUsedTranslations['en'].close,
        next: oftenUsedTranslations['en'].next,
        previous: oftenUsedTranslations['en'].previous,
        markAsRead: oftenUsedTranslations['en'].markAsRead,
        infoText2: 'Tip: Did you know that with our innovative feature, you can extract information such as skills, certificates, and personal descriptions directly from your resume? Use this function to save valuable time and ensure that you can quickly and easily enter all relevant data into the system. And the best part? This process takes only a few minutes and can save you a lot of work. Try it out now!',
        save: oftenUsedTranslations['en'].save,
    },
    'de': {
        firstname: oftenUsedTranslations['de'].firstName,
        lastname: oftenUsedTranslations['de'].lastName,
        gender: oftenUsedTranslations['de'].gender,

        title: 'Willkommen & Basisinformationen',
        title2: 'Informationsextraktion aus Lebenslauf',
        greeting: 'Herzlich willkommen im Competence Manager der Firma [COMPANY]!',
        infoText1: 'Als neuer Nutzer kannst du einige Basisinformationen bereitstellen, um die Nutzung in der App zu personalisieren. Bitte fülle Geschlecht sowie Vor- und Nachnamen aus. Dies hilft dabei, dich in der App korrekt anzusprechen und deine Erfahrung optimal zu gestalten. Vielen Dank für deine Mithilfe!',
        close: oftenUsedTranslations['de'].close,
        next: oftenUsedTranslations['de'].next,
        previous: oftenUsedTranslations['de'].previous,
        markAsRead: oftenUsedTranslations['de'].markAsRead,
        infoText2: 'Tipp: Wusstest du, dass du mittels innovativer Funktionen Informationen wie Fähigkeiten, Zertifikate und persönliche Beschreibungen direkt aus deinem Lebenslauf oder Mitarbeiterprofil extrahieren kannst? Nutze die Funktion, um wertvolle Zeit zu sparen und sicherzustellen, dass du alle relevanten Daten schnell und einfach ins System einpflegen kannst. Das Beste daran? Dieser Prozess nimmt nur wenige Sekunden in Anspruch und kann dir viel Arbeit ersparen. Probiere es gleich aus!',
        save: oftenUsedTranslations['de'].save,
    },
}