import React from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from '@apollo/client/utilities';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EOpportunityRequestTypes, IOpportunityNew, TOpportunityRequestingState } from '@sharedInterfaces/IOpportunity';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import { IconButton, Paper } from '@mui/material';
import { AutoFixHigh } from '@mui/icons-material';
import RoleSelect from '@src/Components/formsControls/inputs/RoleSelect/RoleSelect';
import LinkInput from '@src/Components/formsControls/inputs/LinkInput/LinkInput';
import OpportunityStatusSelect from '@src/Components/formsControls/inputs/OpportunityStatusSelect/OpportunityStatusSelect';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import SkillsInput from '../../formsControls/inputs/SkillsInput/SkillsInput';
import Row from '../../../sharedReact/General/Forms/Row/Row';
import PercentInput from '../../formsControls/inputs/PercentInput/PercentInput';
import DateInput from '../../formsControls/inputs/DateInput/DateInput';
import NumberInput from '../../formsControls/inputs/NumberInput/NumberInput';
import CurrencyInput from '../../formsControls/inputs/CurrencyInput/CurrencyInput';
import FormatedTextInput from '../../formsControls/inputs/FormatedTextInput/FormatedTextInput';
import CompetencesInput from '../../formsControls/inputs/CompetencesInput/CompetencesInput';
import CertificatesInput from '../../formsControls/inputs/CertificatesInput/CertificatesInput';
import RolesInput from '../../formsControls/inputs/RolesInput/RolesInput';

import styles from './OpportunityForm.module.css'

interface OpporunityFormProps
{
    opportunity: IOpportunityNew;
    requestingState: TOpportunityRequestingState;
    requestAnalyse: (type: EOpportunityRequestTypes | EOpportunityRequestTypes[]) => void;
    setOpportunity: (op: (prev: IOpportunityNew) => IOpportunityNew) => void;
}
/**
 * OpporunityForm component.
 *
 * @param {object} opportunity - The opportunity object.
 * @param {function} setOpportunity - The function to update the opportunity object.
 * @returns {JSX.Element} - The rendered OpportunityForm component.
 */
export function OpporunityForm({ opportunity, requestingState, setOpportunity, requestAnalyse }: OpporunityFormProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).opportunities;

    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const allCertificates = useSelector((state: AppState) => state.company.allCertificates);
    const allRoles = useSelector((state: AppState) => state.company.allRoles);

    const permissions = useSelector((state: AppState) => state.permissions);
    const readRoles = checkPermissions('Roles', 'Retrieve', permissions);

    const handleChange = (field: string, value: unknown) =>
    {
        // console.log('handleChange', field, value);
        setOpportunity((prevOpportunity) => ({
            // ...cloneDeep(prevOpportunity),
            ...prevOpportunity,
            [field]: value
        }));
    };

    return (
        <div className={styles.opporunityForm}>
            <Paper variant="outlined" className={styles.grouping}>
                <div style={{
                    flexGrow: 1,
                    alignItems: 'center',
                }}>
                    <Row>
                        <RowElement title={langStrings.opportunityName} alignTitle="center">
                            <TextInput
                                size='medium'
                                value={opportunity.title}
                                onChange={title => handleChange('title', title)}
                                disabled={requestingState.title}
                                helperText={requestingState.title ? langStrings.oftenUsed.generating : undefined}
                            />
                        </RowElement>
                    </Row>
                    <Row>
                        <RowElement title={langStrings.externalID} alignTitle="center">
                            <TextInput
                                size='small'
                                value={opportunity.externalId}
                                onChange={externalId => handleChange('externalId', externalId)}
                                disabled={requestingState.externalId}
                                helperText={requestingState.externalId ? langStrings.oftenUsed.generating : undefined}
                            />
                        </RowElement>
                        <RowElement title={langStrings.status} alignTitle='center'>
                            <OpportunityStatusSelect
                                value={opportunity.status}
                                onChange={status => handleChange('status', status)}
                            />
                        </RowElement>
                    </Row>
                </div>
                <IconButton
                    disabled={requestingState.title}
                    style={{
                        margin: 5,
                    }}
                    onClick={requestAnalyse.bind(null, [EOpportunityRequestTypes.title, EOpportunityRequestTypes.externalId])}
                >
                    <AutoFixHigh />
                </IconButton>
            </Paper>
            <Paper variant="outlined" className={styles.grouping}>
                <div style={{
                    flexGrow: 1,
                    alignItems: 'center',
                }}>
                    <Row>
                        <RowElement alignTitle={'center'} title={langStrings.startDate}>
                            <DateInput
                                value={opportunity.projectStart}
                                disabled={requestingState.projectDates}
                                helperText={requestingState.projectDates ? langStrings.oftenUsed.generating : undefined}
                                onChange={projectStart => handleChange('projectStart', projectStart)}
                            />
                        </RowElement>
                        <RowElement alignTitle={'center'} title={langStrings.endDate}>
                            <DateInput
                                value={opportunity.projectEnd}
                                disabled={requestingState.projectDates}
                                helperText={requestingState.projectDates ? langStrings.oftenUsed.generating : undefined}
                                onChange={projectEnd => handleChange('projectEnd', projectEnd)}
                            />
                        </RowElement>
                    </Row>
                    <Row>
                        <RowElement alignTitle={'center'} title={langStrings.publishDate}>
                            <DateInput
                                value={opportunity.publishDate}
                                disabled={requestingState.projectDates}
                                helperText={requestingState.projectDates ? langStrings.oftenUsed.generating : undefined}
                                onChange={publishDate => handleChange('publishDate', publishDate)}
                            />
                        </RowElement>
                        <RowElement alignTitle={'center'} title={langStrings.deadline}>
                            <DateInput
                                value={opportunity.deadline}
                                disabled={requestingState.projectDates}
                                helperText={requestingState.projectDates ? langStrings.oftenUsed.generating : undefined}
                                onChange={deadline => handleChange('deadline', deadline)}
                            />
                        </RowElement>
                    </Row>
                </div>
                <IconButton
                    disabled={requestingState.projectDates}
                    style={{
                        margin: 5,
                        float: 'right',
                    }}
                    onClick={requestAnalyse.bind(null, EOpportunityRequestTypes.projectDates)}
                >
                    <AutoFixHigh />
                </IconButton>

            </Paper>
            <Paper variant="outlined" className={styles.grouping} >
                <div style={{
                    flexGrow: 1,
                    alignItems: 'center',
                }}>
                    <Row>
                        <RowElement alignTitle={'center'} title={langStrings.utilization}>
                            <PercentInput
                                value={opportunity.utilization}
                                disabled={requestingState.projectNumbers}
                                helperText={requestingState.projectNumbers ? langStrings.oftenUsed.generating : undefined}
                                onChange={utilization => handleChange('utilization', utilization)}
                            />
                        </RowElement>
                        <RowElement alignTitle={'center'} title={langStrings.remote}>
                            <PercentInput
                                value={opportunity.remote}
                                disabled={requestingState.projectNumbers}
                                helperText={requestingState.projectNumbers ? langStrings.oftenUsed.generating : undefined}
                                onChange={remote => handleChange('remote', remote)}
                            />
                        </RowElement>
                    </Row>
                    <Row>
                        <RowElement alignTitle={'center'} title={langStrings.employees}>
                            <NumberInput
                                value={opportunity.headCount}
                                disabled={requestingState.projectNumbers}
                                helperText={requestingState.projectNumbers ? langStrings.oftenUsed.generating : undefined}
                                onChange={headCount => handleChange('headCount', headCount)}
                            />
                        </RowElement>
                        <RowElement alignTitle={'center'} title={langStrings.dayPrice}>
                            <CurrencyInput
                                value={opportunity.dayPrice}
                                disabled={requestingState.projectNumbers}
                                helperText={requestingState.projectNumbers ? langStrings.oftenUsed.generating : undefined}
                                onChange={dayPrice => handleChange('dayPrice', dayPrice)}
                            />
                        </RowElement>
                    </Row>
                    <Row>
                        <RowElement alignTitle={'center'} title={langStrings.location}>
                            <TextInput size='small' value={opportunity.loc}
                                disabled={requestingState.location}
                                helperText={requestingState.location ? langStrings.oftenUsed.generating : undefined}
                                onChange={loc => handleChange('loc', loc)}
                            />
                        </RowElement>
                    </Row>
                </div>
                <IconButton
                    disabled={requestingState.projectNumbers}
                    style={
                        {
                            margin: 5,
                        }}
                    onClick={requestAnalyse.bind(null, [EOpportunityRequestTypes.projectNumbers, EOpportunityRequestTypes.location])}
                >
                    <AutoFixHigh />
                </IconButton>
            </Paper>
            <Paper variant="outlined" className={styles.grouping} >
                <div style={{
                    flexGrow: 1,
                    alignItems: 'center',
                }}>
                    <Row>
                        <RowElement alignTitle={'center'} title={langStrings.customer}>
                            <TextInput
                                size='small'
                                value={opportunity.customer}
                                disabled={requestingState.customerPartner}
                                helperText={requestingState.customerPartner ? langStrings.oftenUsed.generating : undefined}
                                onChange={customer => handleChange('customer', customer)}
                            />
                        </RowElement>
                        <RowElement alignTitle={'center'} title={langStrings.partner}>
                            <TextInput
                                size='small'
                                value={opportunity.partner}
                                disabled={requestingState.customerPartner}
                                helperText={requestingState.customerPartner ? langStrings.oftenUsed.generating : undefined}
                                onChange={partner => handleChange('partner', partner)}
                            />
                        </RowElement>
                    </Row>
                    <Row>
                        <RowElement title={langStrings.sourceName} alignTitle="center">
                            <TextInput
                                size='small'
                                value={opportunity.sourceName}
                                disabled={requestingState.customerPartner}
                                helperText={requestingState.customerPartner ? langStrings.oftenUsed.generating : undefined}
                                onChange={sourceName => handleChange('sourceName', sourceName)}
                            />
                        </RowElement>
                        <RowElement title={langStrings.sourceURL} alignTitle="center">
                            <LinkInput
                                size='small'
                                value={opportunity.sourceURL}
                                disabled={requestingState.customerPartner}
                                helperText={requestingState.customerPartner ? langStrings.oftenUsed.generating : undefined}
                                onChange={sourceURL => handleChange('sourceURL', sourceURL)}
                            />
                        </RowElement>
                    </Row>
                </div>
                <IconButton
                    disabled={requestingState.customerPartner}
                    style={{
                        margin: 5,
                    }}
                    onClick={requestAnalyse.bind(null, [EOpportunityRequestTypes.customerPartner, EOpportunityRequestTypes.source])}
                >
                    <AutoFixHigh />
                </IconButton>
            </Paper>
            {readRoles &&
                <Paper variant="outlined" className={styles.grouping}>
                    <div style={{
                        flexGrow: 1,
                        alignItems: 'center',
                    }}>
                        <Row>
                            <RowElement
                                title={langStrings.primaryRole}
                                alignTitle="center">
                                {/* <pre>{JSON.stringify(opportunity.primaryRole, null, 2)}</pre> */}
                                <RoleSelect
                                    selectedRole={opportunity.primaryRole}
                                    setSelectedRole={role => handleChange('primaryRole', role)}
                                    disabled={requestingState.roles}
                                    allowNew
                                    helperText={requestingState.roles ? langStrings.oftenUsed.generating : undefined}
                                />
                            </RowElement>
                        </Row>
                        <Row>
                            <RowElement
                                title={langStrings.oftenUsed.roles}
                                alignTitle="center">
                                <RolesInput
                                    allRoles={allRoles}
                                    selectedRoles={opportunity.roles}
                                    showMode={false}
                                    size={'medium'}
                                    disabled={requestingState.roles}
                                    helperText={requestingState.roles ? langStrings.oftenUsed.generating : undefined}
                                    onChange={roles => handleChange('roles', roles)}
                                />

                            </RowElement>
                        </Row>
                    </div>
                    <IconButton
                        disabled={requestingState.roles}
                        style={{
                            margin: 5,
                        }}
                        onClick={requestAnalyse.bind(null, EOpportunityRequestTypes.roles)}
                    >
                        <AutoFixHigh />
                    </IconButton>
                </Paper>
            }
            <RowWithGenerate title={langStrings.oftenUsed.competences}
                input={
                    <CompetencesInput
                        showMode={false}
                        size='medium'
                        competences={cloneDeep(opportunity.competences)}
                        disabled={requestingState.competences}
                        helperText={requestingState.competences ? langStrings.oftenUsed.generating : undefined}
                        onChange={competences => handleChange('competences', competences)}
                    />
                }
                button={
                    <IconButton
                        disabled={requestingState.competences}
                        style={{
                            margin: 5,
                        }}
                        onClick={requestAnalyse.bind(null, EOpportunityRequestTypes.competences)}
                    >
                        <AutoFixHigh />
                    </IconButton>
                }
            />
            <RowWithGenerate title={langStrings.neededSkills}
                input={
                    <SkillsInput size="medium"
                        showMode={false}
                        allowNewSkills={true}
                        skills={opportunity.skills}
                        allSkills={allSkills}
                        competences={opportunity.competences}
                        disabled={requestingState.skills}
                        helperText={requestingState.skills ? langStrings.oftenUsed.generating : undefined}
                        onChange={skills => handleChange('skills', skills)}
                    />
                }
                button={
                    <IconButton
                        disabled={requestingState.skills}
                        style={{
                            margin: 5,
                        }}
                        onClick={requestAnalyse.bind(null, EOpportunityRequestTypes.skills)}
                    >
                        <AutoFixHigh />
                    </IconButton>
                }
            />
            <RowWithGenerate title={langStrings.oftenUsed.certificates}
                input={
                    <CertificatesInput
                        allCertificates={allCertificates}
                        selectedCertificates={opportunity.certificates}
                        showMode={false}
                        size='medium'
                        competences={opportunity.competences}
                        disabled={requestingState.certificates}
                        helperText={requestingState.certificates ? langStrings.oftenUsed.generating : undefined}
                        onChange={certificates => handleChange('certificates', certificates.map((s) => ({ title: s.title, id: s.id })))}
                    />
                }
                button={
                    <IconButton
                        disabled={requestingState.certificates}
                        style={{
                            margin: 5,
                        }}
                        onClick={requestAnalyse.bind(null, EOpportunityRequestTypes.certificates)}
                    >
                        <AutoFixHigh />
                    </IconButton>
                }
            />

            {/* <Row>
                <RowElement alignTitle={'center'} title={langStrings.selectedEmployee}>
                    <EmployeesInput selectedEmployees={opportunity.selectedEmployees} allEmployees={allEmployees}
                        onChange={emps =>
                        {
                            setOpportunity({
                                ...opportunity,
                                selectedEmployees: emps.map(emp => ({
                                    id: emp.id,
                                    title: emp.title,
                                    skillCoverage: 0,
                                    score: 0,
                                    missingSkills: [],
                                    coveredSkills: [],
                                }))
                            });
                        }} showMode={false} size={'medium'} />
                </RowElement>
            </Row> */}
            <Row>
                <RowElement alignTitle={'center'} title={langStrings.opportunity}>
                    <FormatedTextInput isAI value={opportunity.requestText}
                        onChange={requestText => handleChange('requestText', requestText)}
                    />
                </RowElement>
            </Row>
        </div>
    );
}

interface IRowWithGenerateProps
{
    title: string;
    input: JSX.Element;
    button: JSX.Element;
}
function RowWithGenerate({ title, input, button }: IRowWithGenerateProps)
{
    return <Row>
        <RowElement title={title} alignTitle="center">
            <table width={'100%'}>
                <tbody>
                    <tr>
                        <td>
                            {input}
                        </td>
                        <td width={36} style={{ verticalAlign: 'center' }}>
                            {button}
                        </td>
                    </tr>
                </tbody>
            </table>
        </RowElement>
    </Row>
}