
import { gql } from "@apollo/client";
import { MergeSkillsRequestDTO, MergeSkillsResponseDTO } from "@sharedInterfaces/ISkill";
import { skillDetailGQL } from "@src/Objects/SkillDetail";

import GraphQL from "../graphQL";

const query = gql`
mutation mergeSkills($skillIds: [Int!]!, $skill: MergeSkillsInput!) {
    mergeSkills(skillIds: $skillIds ,skill: $skill) 
    {
    newSkill ${skillDetailGQL}
    otherNames
    removed
  }
},
`;

export default async function mergeSkills(skill: { id: number, title: string, description: string },
    skillIds: number[])
{
    const variables: MergeSkillsRequestDTO = {
        skill,
        skillIds,
    };
    const response = await GraphQL.mutate<MergeSkillsResponseDTO>(query, variables);
    response.newSkill.id = parseInt(response.newSkill.id.toString())
    return response;
}