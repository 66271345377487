import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";

import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation setZepSync($token: String!, $instance: String!) {
    setZepSync(token: $token, instance: $instance) 
    ${companySettingsGql}
},
`;

export default async function setZepSync(data: { token: string, instance: string })
{
    const variables: OperationVariables = {
        ...data
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c))
}