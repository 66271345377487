import React from 'react';
import TextField from '@mui/material/TextField';

interface NumberInputProps
{
    max?: number
    min?: number
    value: number
    type?: 'float' | 'int'
    disabled?: boolean
    helperText?: string
    onChange: (value: number) => void
}
/**
 * Function component that renders a number input.
 *
 * @param {NumberInputProps} { disabled, max, value, onChange } - The props for the number input.
 *
 * @returns {JSX.Element} - A JSX element representing the number input.
 */
export default function NumberInput({ disabled, min, max, value, type, helperText, onChange }: NumberInputProps)
{
    const innerType = type ? type : 'int'
    return (
        <div className='numberBox'>
            <TextField variant='standard'
                size='small' margin='none'
                disabled={disabled}
                type='number'
                value={value}
                helperText={helperText}
                onChange={(event) =>
                {
                    const valueIn = event.target.value;
                    let valueOut = 0;
                    try
                    {
                        if (innerType === 'int')
                        {
                            try
                            {
                                valueOut = parseInt(valueIn);
                            } catch (error)
                            {
                                valueOut = Math.floor(parseFloat(valueIn));
                            }
                        } else if (innerType === 'float')
                        {
                            valueOut = Number.parseFloat(valueIn);
                        }
                        if (max !== undefined && valueOut > max)
                        {
                            valueOut = max;
                        } else if (min !== undefined && valueOut < min)
                        {
                            valueOut = min;
                        }
                        onChange(valueOut);

                    } catch (error)
                    { /* empty */ }
                }}
                InputProps={{
                    inputProps: {
                        max: max, min: min
                    }
                }} />
        </div>
    );
}