import { OperationVariables, gql } from "@apollo/client";
import { IOrgUnitSalesView } from "@sharedInterfaces/IOrgUnit";

import GraphQL from "../graphQL";

export const GQL_OU_SALES_DATA_VIEW = `{
    id
    title
    showProjectsWithoutRevenue
    showProjectsWithRevenue
    showInternalProjects
    showExternalProjects
    showAbsence
    showUnbilableProjectTime
}`

const query = gql`
mutation createOUSalesView($newView: NewOUSalesViewInput!) {
    createOUSalesView(newView: $newView) 
    ${GQL_OU_SALES_DATA_VIEW}
},
`;



export default async function createOUSalesView(newView: IOrgUnitSalesView)
{
    const variables: OperationVariables = {
        newView
    };
    return GraphQL.mutate<IOrgUnitSalesView>(query, variables)
}