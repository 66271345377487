import { OperationVariables, gql } from "@apollo/client";
import IAttachment from "@sharedInterfaces/IAttachment";
import Attachment from "@src/Objects/Attachment";
import { EEntityType } from "@sharedInterfaces/globalEnums";

import GraphQL from "../graphQL";

import { attachmentGql } from "./getAttachment";

const q = gql`
query attachments($entityType: String!, $entityId: Int!) {
    attachments(entityType: $entityType, entityId: $entityId)
    {
        attachments
        ${attachmentGql}
    }
},
`;

interface OpportunitieResponse
{
    attachments: {
        attachments: IAttachment[];
    }
}

export default async function getAttachments(entityType: EEntityType, entityId: number)
{
    const variables: OperationVariables = {
        entityType,
        entityId,
    };
    return GraphQL
        .query<OpportunitieResponse>(q, variables, undefined)
        .then((obj) =>
        {
            if (!obj) throw new Error("Fehler beim laden der Attachments.");
            if (obj.attachments)
            {
                return obj
                    .attachments.attachments
                    .map((p: IAttachment) =>
                        new Attachment(p)
                    );
            }
            return []
        });
}