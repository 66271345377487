import { OperationVariables, gql } from "@apollo/client";
import { IOrgUnitDetailDTO } from "@sharedInterfaces/IOrgUnit";
import { EEntityType, entityTypeToLink } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { deleteOrgUnit, updateOrgUnit } from "@store/reducer/orgUnitReducer";
import { entityFieldsGQL } from "@sharedReact/Objects/Entity";

import GraphQL from "../graphQL";
import { NotFoundError } from "../NotFoundError";

export const ORG_UNIT_FRAGMENT = gql`
fragment OrgUnitFields on OU {
    id,
    title,
    parentOU,
    leader {
        id,
        firstname,
        lastname
    },
    employees {
        id,
        firstname,
        lastname
    },
    skills {
        id,
        title,
        count
    },
    skillCategories {
        id,
        title,
        count
    }
    ${entityFieldsGQL}

}
`;

export const GET_ORG_UNIT_QUERY = gql`
query getOrgUnit($ouId: ID!) {
    ou(id: $ouId) {
        ...OrgUnitFields
    }
}
${ORG_UNIT_FRAGMENT}
`;

/**
 * Retrieves an organizational unit and updates the Redux store.
 *
 * @param {number} ouId - The ID of the organizational unit.
 * @returns {Promise<IOrgUnitDetailDTO>} A promise that resolves with the retrieved organizational unit details.
 */
export default async function getOrgUnit(ouId: number)
{
    const variables: OperationVariables = { ouId };
    return GraphQL.query<{ ou: IOrgUnitDetailDTO }>(GET_ORG_UNIT_QUERY, variables).then((obj) =>
    {
        if (!obj?.ou)
        {
            store.dispatch(deleteOrgUnit(ouId));
            throw new NotFoundError(ouId);
        }
        // Sorting and link assignment can be done here if needed
        obj.ou.employees.sort((a, b) => `${a.lastname} ${a.firstname}`.localeCompare(`${b.lastname} ${b.firstname}`));
        obj.ou.link = entityTypeToLink(obj.ou.id, EEntityType.ORGANISATIONAL_UNIT);
        obj.ou.type = EEntityType.ORGANISATIONAL_UNIT;
        store.dispatch(updateOrgUnit(obj.ou));
        return obj.ou;
    });
}
