import React from 'react';
import { Edit } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ForbiddenActions, checkPermissions } from '@sharedInterfaces/IPermissions';
import { ISmallSkill } from '@sharedInterfaces/IWhoIAm';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { EEntityType, ELinks, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import Employee from '@src/Objects/Employee';
import RoundIconButton from '@src/Components/Buttons/RoundIconButton/RoundIconButton';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import { EmployeePicture } from '@src/Components/EmployeePicture/EmployeePicture';
import getEmployee from '@src/APIs/graphQl/Employee/getEmployee';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import CertificatesCard from '@src/App/NewLayout/Cards/Certificates/CertificatesCard/CertificatesCard';
import SkillsCard from '@src/App/NewLayout/Cards/Skills/SkillsCard/SkillsCard';
import SkillCategoriesCard from '@src/App/NewLayout/Cards/SkillCategories/SkillCategoriesCard/SkillCategoriesCard';
import ProductsCard from '@src/App/NewLayout/Cards/Products/ProductsCard/ProductsCard';
import ProjectsCard from '@src/App/NewLayout/Cards/Projects/ProjectsCard/ProjectsCard';
import './EmployeePage.css';
import CompetencesCard from '@src/App/NewLayout/Cards/Competences/CompetencesCard/CompetencesCard';
import EmployeesCard from '@src/App/NewLayout/Cards/Employees/EmployeesCard/EmployeesCard';
import RolesCard from '@src/App/NewLayout/Cards/Roles/RolesCard/RolesCard';

import EntityPage from '../../../../../sharedReact/Pages/EntityPage/EntityPage';

interface EmployeePageProps
{
    entity?: Employee;
    inDialog?: boolean;
}
/**
 * Renders the EmployeePage component.
 * 
 * @param {EmployeePageProps} props - The props object containing the properties for EmployeePage component.
 * @returns {JSX.Element} - The rendered EmployeePage component.
 */
function EmployeePage({ entity, inDialog }: EmployeePageProps)
{
    const navigate = useNavigate();
    const { id, tab } = useParams();
    const ownId = useSelector((state: AppState) => state.employee.id);
    const entityId = entity ? entity.id : id ? Number.parseInt(id) : ownId;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profile;
    const entityState = useSelector((state: AppState) => state.employees.find(a => a.id === entityId))
    const permissions = useSelector((state: AppState) => state.permissions);
    const OUs = useSelector((state: AppState) => state.OUs);

    const itsMe = entityId === ownId;
    const skillCategories = useSelector((state: AppState) => state.company.skillCategories);
    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);
    const availability = entityState?.availability;

    useEffect(
        () =>
        {
            if (inDialog) return;
            getEmployee(entityId).catch((ex) =>
            {
                setLoadingError(ex);
            })
        },
        [entityId, inDialog]);


    const OU = !entityState ? undefined : OUs.find(o => o.employees.includes(entityState.id));
    const employeesAllSkills = entityState ? entityState.skills
        .map(s => allSkills.find(a => s.id === a.id))
        .filter(s => s !== undefined) as ISmallSkill[]
        : [];

    const employeeCategories = skillCategories.filter(cat => employeesAllSkills.find(s => s.categoryId === cat.id) !== undefined)
    const readRoles = checkPermissions('Roles', 'Retrieve', permissions);


    let content = entityState ? <>
        {readRoles &&
            <RolesCard roles={entityState.roles} />
        }
        <CompetencesCard limit={7} competences={entityState.competences} />
        <EmployeesCard limit={7} title={langStrings.similarUsers}
            employees={entityState.similarUsers}
        />

        <SkillsCard
            limit={7}
            employeeId={entityId}
            title={langStrings.skills}
            skills={entityState.skills} />
        <SkillCategoriesCard limit={7}
            skillCategories={employeeCategories}
        />
        <CertificatesCard limit={7}
            certificates={[...entityState.certificates]}
            title={langStrings.certificates}
        />
        <ProductsCard limit={7} products={entityState.products} />
        <ProjectsCard limit={7} projects={entityState.projects.filter(p => p.active)} />
    </> : undefined
    if (entityState)
    {
        switch (tab?.toLocaleLowerCase())
        {
            case 'roles':
                content = <RolesCard roles={entityState.roles} />
                break;
            case 'competences':
                content = <CompetencesCard competences={entityState.competences} />
                break;
            case 'skills':
                content = <SkillsCard
                    employeeId={entityId}
                    skills={entityState.skills}
                />
                break;
            case 'certificates':
                content = <CertificatesCard certificates={[...entityState.certificates]} />
                break;
            case 'skillcategories':
                content = <SkillCategoriesCard skillCategories={employeeCategories} />
                break;
            case 'similarusers':
                content = <EmployeesCard title={langStrings.similarUsers}
                    employees={entityState.similarUsers}
                />
                break;
            case 'products':
                content = <ProductsCard products={entityState.products} />
                break;
            case 'projects':
                content = <ProjectsCard projects={entityState.projects.filter(p => p.active)} />
                break;
            default:
        }
    }


    return (
        <EntityPage
            inDialog={inDialog}
            entity={entityState}
            admin={false}
            bigLeftBox={entityState?.pictureId ? <EmployeePicture avatarSize={100} openDialogOnClick employee={entityState} /> : undefined}
            permissions={ForbiddenActions}
            loadingError={loadingError}
            tabs={inDialog || !entityState ? undefined : [
                {
                    title: langStrings.oftenUsed.dashboard,
                    link: entityTypeToLink(entityState.id, EEntityType.EMPLOYEE, 'dashboard')
                },
                ...(readRoles ?
                    [{
                        title: langStrings.oftenUsed.roles,
                        link: entityTypeToLink(entityState.id, EEntityType.EMPLOYEE, 'roles')
                    },]
                    : []
                ),
                {
                    title: langStrings.oftenUsed.competences,
                    link: entityTypeToLink(entityState.id, EEntityType.EMPLOYEE, 'competences')
                },
                {
                    title: langStrings.oftenUsed.skills,
                    link: entityTypeToLink(entityState.id, EEntityType.EMPLOYEE, 'skills')
                },
                {
                    title: langStrings.oftenUsed.certificates,
                    link: entityTypeToLink(entityState.id, EEntityType.EMPLOYEE, 'certificates')
                },
                {
                    title: langStrings.oftenUsed.skillCategories,
                    link: entityTypeToLink(entityState.id, EEntityType.EMPLOYEE, 'skillCategories')
                },
                {
                    title: langStrings.similarUsers,
                    link: entityTypeToLink(entityState.id, EEntityType.EMPLOYEE, 'similarUsers')
                },
                {
                    title: langStrings.oftenUsed.products,
                    link: entityTypeToLink(entityState.id, EEntityType.EMPLOYEE, 'products')
                },
                {
                    title: langStrings.oftenUsed.projects,
                    link: entityTypeToLink(entityState.id, EEntityType.EMPLOYEE, 'projects')
                }
            ]}
            buttons={itsMe ? <RoundIconButton size='small'
                helperText={langStrings.editMyProfile}
                onClick={() =>
                {
                    navigate(`/${ELinks.PROFILE_EDIT}`);
                }}
                icon={<Edit />}
            /> : <></>}
            onDelete={() =>
            {
                if (!inDialog)
                    navigate(`/${ELinks.SALES_PRODUCTS}/`);
            }}
            informations={
                [

                    OU ?
                        <EntityPageInformation title={langStrings.orgUnit} >
                            <NavLink to={`/${ELinks.PEOPLE_ORGUNITS}/` + OU.id}>{OU.title}</NavLink>
                        </EntityPageInformation>
                        : undefined,
                    availability ?
                        <EntityPageInformation
                            title={langStrings.availability}
                            helpText={langStrings.oftenUsed.availabilityNext28Days}
                        >
                            {availability.availability === 0 && availability.workDayCount === 0 ?
                                'N/A' :
                                Math.round(availability.availability * 10) / 10 + '%'}
                        </EntityPageInformation>
                        : undefined,
                    entityState?.description ?
                        <EntityPageInformation title={langStrings.description} size={'full'}>
                            <ExpandableText>
                                <FormatedTextInput value={entityState.description} readonly={true} />
                            </ExpandableText>
                        </EntityPageInformation> :
                        undefined,
                ]
            }
        >
            {content}
        </EntityPage>
    );
}

export default EmployeePage;