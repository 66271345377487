import { OperationVariables, gql } from "@apollo/client";
import { ICompetenceSuitableEmployees } from "@sharedInterfaces/ICompetence";

import GraphQL from "../graphQL";
import { employeeCoverageGql } from "../Opportunity/getOpportunity";

const q = gql`
query competenceGetSuggestedEmployees($competenceId: ID!) {
    competenceGetSuggestedEmployees(id:  $competenceId) 
    {
        levels {
            level
            employees
            ${employeeCoverageGql}
        }
    }
},
`;

/**
 * Returns a promise that resolves to the suggested employees
 * for a given competence.
 * @param {number} competenceId - The ID of the competence.
 * @param {(competence: ICompetenceSuitableEmployees) => void} callback - The callback function that handles the suggested employees.
 * @returns {Promise<{ competenceGetSuggestedEmployees: ICompetenceSuitableEmployees; }>} The promise that resolves to the suggested employees.
 */
export default async function competenceGetSuggestedEmployees(competenceId: number, callback: (competence: ICompetenceSuitableEmployees) => void)
{
    const variables: OperationVariables = { competenceId };
    return GraphQL.query<{ competenceGetSuggestedEmployees: ICompetenceSuitableEmployees }>(q, variables, (obj) =>
    {
        if (!obj) throw new Error("Error loading competenceGetSuggestedEmployees");

        callback(obj.competenceGetSuggestedEmployees)
    }
    );
}
