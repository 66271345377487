import React, { useEffect } from 'react';
import { Done, DirectionsRun, PriorityHigh, PanTool, Sync } from "@mui/icons-material";
import { Accordion, AccordionSummary, Typography, AccordionDetails, } from "@mui/material";
import { ETimeSyncStatus, ETimeSyncStartType, ICompanyTimeSyncStatis } from "@sharedInterfaces/ICompanySettings";
import RowElement from "@sharedReact/General/Forms/RowElement/RowElement";
import setClockodoSync from "@src/APIs/graphQl/Company/setClockodoSync";
import startTimeSync from "@src/APIs/graphQl/Company/startTimeSync";
import ErrorBox from "@src/Components/ErrorBox/ErrorBox";
import TextInput from "@src/Components/formsControls/inputs/TextInput/TextInput";
import { useState } from "react";
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { useSelector } from 'react-redux';
import Button from '@sharedReact/General/Button/Button';
import Company from '@src/Objects/Company';


interface ClockodoAccordionProps
{
    active: boolean
    statis: ICompanyTimeSyncStatis
    email: string
    reload: () => void;
    setCompany: (company: Company) => void;
}

const ClockodoAccordion: React.FC<ClockodoAccordionProps> = ({
    active,
    statis,
    email,
    reload,
    setCompany
}) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [changed, setChanged] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string | false>(active ? 'clockodo' : false);
    const [saveError, setSaveError] = useState<string | null>(null);
    const [clockodoSyncState, setClockodoSyncState] = useState<{ email: string; apiKey: string; }>({
        email: email,
        apiKey: ''
    });

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) =>
    {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion
            expanded={active || expanded === 'clockodo'}
            disabled={saving}
            onChange={handleChange('clockodo')}
        >
            <AccordionSummary>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}                >
                    <div
                        style={{
                            backgroundImage: `url(/icons/clockodo-50.webp)`,
                            width: '30px',
                            height: '30px',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Typography fontWeight={active ? 'bold' : 'normal'}>{langStrings.clockodo}</Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>

                <RowElement alignTitle='left' title={langStrings.clockodoUserEmail}>
                    <TextInput value={clockodoSyncState.email} size='small' onChange={(text) =>
                    {
                        setChanged(true);
                        setClockodoSyncState({
                            ...clockodoSyncState,
                            email: text,
                        });
                    }} />
                </RowElement>
                <RowElement alignTitle='left' title={langStrings.clockodoUserApiKey}>
                    <TextInput
                        value={clockodoSyncState.apiKey}
                        password={true}
                        size='small'
                        onChange={(text) =>
                        {
                            setChanged(true);
                            setClockodoSyncState({
                                ...clockodoSyncState,
                                apiKey: text,
                            });
                        }} />
                </RowElement>
                {saveError && <ErrorBox close={setSaveError.bind(null, null)}>{saveError}</ErrorBox>}
                {changed &&
                    <Button
                        disabled={saving ||
                            clockodoSyncState.email.indexOf('@') === -1 ||
                            clockodoSyncState.email.lastIndexOf('.') < clockodoSyncState.email.lastIndexOf('@') ||
                            clockodoSyncState.apiKey === ''
                        }
                        size={'normal'}
                        text={langStrings.save}
                        icon={<Done />}
                        color='primary'
                        onClick={function (): void
                        {
                            setSaving(true);
                            setClockodoSync({
                                ...clockodoSyncState
                            })
                                .then((companyData) =>
                                {
                                    const error = companyData.clockodoStats.status === ETimeSyncStatus.ERROR;
                                    console.log('companyData', companyData.clockodoStats.status);

                                    setCompany(companyData);
                                    if (error)
                                    {
                                        setSaveError(langStrings.errorLoginData);
                                    } else
                                    {
                                        setSaveError(null);
                                    }
                                }).then(() => setChanged(false))
                                .catch(err => setSaveError(err.toString()))
                                .finally(() =>
                                {
                                    setSaving(false);
                                    setTimeout(reload, 2000);
                                });
                        }}
                    />
                }

                <StatusTable
                    statis={statis}
                    syncIsActive={active}

                    reload={reload}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default ClockodoAccordion;


interface IStatusTable
{
    statis: ICompanyTimeSyncStatis;
    syncIsActive: boolean;
    reload: () => void;
}

export function StatusTable({ syncIsActive, statis: clockodoStatus, reload }: IStatusTable)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [status, setStatus] = useState<ETimeSyncStatus>(clockodoStatus.status);
    useEffect(
        () =>
        {
            let timer1: NodeJS.Timeout | null = null;
            if (clockodoStatus.status === ETimeSyncStatus.SYNCING)
            {
                timer1 = setInterval(reload, 30 * 1000);
            }
            return () =>
            {
                timer1 && clearInterval(timer1);
            };
        },
        [clockodoStatus]
    );

    useEffect(() =>
    {
        setStatus(clockodoStatus.status);
    },
        [clockodoStatus.status]
    )

    return (
        <div>
            <Typography variant='h5' marginTop={2}>
                {langStrings.status}
            </Typography>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {langStrings.status}:
                            </Typography>
                        </td>
                        <td>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                            }}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {syncIsActive ? status : 'Stopped'}
                                </Typography>
                                {status === ETimeSyncStatus.SYNCING &&
                                    <DirectionsRun />
                                }
                                {status === ETimeSyncStatus.SYNCED &&
                                    <Done />
                                }
                                {status === ETimeSyncStatus.ERROR &&
                                    <PriorityHigh />
                                }
                                {(status === ETimeSyncStatus.IDLE || !syncIsActive) &&
                                    <PanTool />
                                }
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography variant='body2'>
                                {langStrings.lastUpdateCustomer}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant='body2'>
                                {clockodoStatus.lastUpdateCustomer ?
                                    new Date(clockodoStatus.lastUpdateCustomer).toLocaleString()
                                    : langStrings.oftenUsed.never}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography variant='body2'>
                                {langStrings.lastUpdateProjects}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant='body2'>
                                {clockodoStatus.lastUpdateProjects ?
                                    new Date(clockodoStatus.lastUpdateProjects).toLocaleString()
                                    : langStrings.oftenUsed.never}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography variant='body2'>
                                {langStrings.lastUpdateEmployeeAbsence}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant='body2'>
                                {clockodoStatus.lastUpdateEmployeeAbsence ?
                                    new Date(clockodoStatus.lastUpdateEmployeeAbsence).toLocaleString()
                                    : langStrings.oftenUsed.never}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography variant='body2'>
                                {langStrings.lastUpdateEmployeeTimesThisYear}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant='body2'>
                                {clockodoStatus.lastUpdateEmployeeTimesThisYear ?
                                    new Date(clockodoStatus.lastUpdateEmployeeTimesThisYear).toLocaleString()
                                    : langStrings.oftenUsed.never}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography variant='body2'>
                                {langStrings.lastUpdateEmployeeTimesLastYear}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant='body2'>
                                {clockodoStatus.lastUpdateEmployeeTimesLastYear ?
                                    new Date(clockodoStatus.lastUpdateEmployeeTimesLastYear).toLocaleString()
                                    : langStrings.oftenUsed.never}
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Button
                text={langStrings.syncNow}
                style={{ maxWidth: 250, marginTop: 10 }}
                icon={<Sync />}
                color='primary'
                disabled={status === ETimeSyncStatus.SYNCING || !syncIsActive}
                onClick={function (): void
                {
                    setStatus(ETimeSyncStatus.SYNCING);
                    startTimeSync(ETimeSyncStartType.FULL)
                        .then(() => setStatus(ETimeSyncStatus.SYNCING))
                        .catch(() => setStatus(ETimeSyncStatus.ERROR))
                        .finally(() =>
                        {
                            setTimeout(reload, 2000);
                            setTimeout(reload, 5000);
                            setTimeout(reload, 10000);
                            setTimeout(reload, 15000);
                        });
                }}
            />
        </div>
    )
}