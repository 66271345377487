import { IStyleDTO } from "../ICompanySettings";

export type TGeneralWebsocketResponse = IGeneralWebsocketResponseMap[keyof IGeneralWebsocketResponseMap];
export enum EGeneralWebsocketResponseType
{
    Style = 'style',
}


export interface IGeneralWebsocketResponseMap
{
    [EGeneralWebsocketResponseType.Style]: {
        type: EGeneralWebsocketResponseType.Style;
        style: IStyleDTO,
    };
}