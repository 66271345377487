import React, { useState } from 'react';
import { AppState } from '@store/store';
import { useSelector } from 'react-redux';
import { IReference, IReferenceMaybe } from '@sharedInterfaces/IReference';
import { TextField, Autocomplete, InputAdornment } from '@mui/material';
import { NewBadge } from '@src/App/NewLayout/Components/ItemBadge/ItemBadge';

interface RoleSelectProps
{
    selectedRole: IReference | null;
    disabled?: boolean;
    helperText?: string;
    allowNew?: boolean;
    setSelectedRole: (val: IReferenceMaybe | null) => void;
}

/**
 * RoleSelect component.
 *
 * @param {Object} props - The props for the RoleSelect component.
 * @param {RoleSelectProps} props - The props for the RoleSelect component.
 * @returns {JSX.Element} - The rendered RoleSelect component.
 */
function RoleSelect(props: RoleSelectProps)
{
    const smallRoles = useSelector((state: AppState) => state.company.allRoles);
    const [inputValue, setInputValue] = useState('');

    const handleChange = (event: React.SyntheticEvent, newValue: IReference | string | null) =>
    {
        if (typeof newValue === 'string')
        {
            // When allowNew is true and the user types a new value
            if (props.allowNew)
            {
                const existingRole = smallRoles.find(r => r.title.trim().toLocaleLowerCase() === newValue.trim().toLocaleLowerCase());
                if (existingRole)
                {
                    props.setSelectedRole(existingRole);
                }
                else
                {
                    const newRole: IReferenceMaybe = {
                        id: undefined,
                        title: newValue,
                    };
                    props.setSelectedRole(newRole);
                }
            }
        } else if (newValue)
        {
            props.setSelectedRole(newValue);
        }
    };

    const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) =>
    {
        setInputValue(newInputValue);
        const existingRole = smallRoles.find(r => r.title.trim().toLocaleLowerCase() === newInputValue.trim().toLocaleLowerCase());
        if (existingRole)
        {
            props.setSelectedRole(existingRole);
        } else if (props.allowNew && newInputValue)
        {
            props.setSelectedRole({ id: undefined, title: newInputValue });
        } else
        {
            props.setSelectedRole(null);
        }
    };

    return (
        <Autocomplete
            value={props.selectedRole}
            style={{ width: '100%' }}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={smallRoles}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.title}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disabled={props.disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    helperText={props.helperText}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                {(props.allowNew && inputValue && !smallRoles.some(role => role.title === inputValue)) &&
                                    <NewBadge />
                                }
                                {params.InputProps.endAdornment}
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={typeof option === 'string' ? option : option.id}>
                    {typeof option === 'string' ? option : option.title}
                </li>
            )}
            freeSolo={props.allowNew}
        />
    );
}

export default RoleSelect;