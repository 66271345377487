import { Merge } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { setEmployeeRoles } from '@store/reducer/employeeReducer';
import { setCompanyAllRoles } from '@store/reducer/companyReducer';
import { setRoles } from '@store/reducer/rolesReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import getRoles from '@src/APIs/graphQl/Role/getRoles';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import Role from '@src/Objects/Role';
import { Card, CardContent, Checkbox, FormControlLabel, Grid, Typography, Box } from '@mui/material';
import RoleItem from '@src/App/NewLayout/Items/RoleItem/RoleItem';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import mergeRoles from '@src/APIs/graphQl/Role/mergeRoles';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import ErrorBox from '../../ErrorBox/ErrorBox';

interface MergeRolesDialogProps
{
    id: string;
    roleIds: number[];
    resolve: (success: boolean) => void;
}

/**
 * Component for rendering the MergeRolesDialog.
 *
 * @param {MergeRolesDialogProps} props - The props containing necessary data for rendering the dialog.
 * @returns {JSX.Element | null} - The rendered dialog JSX element or null if no employee is present.
 */
function MergeRolesDialog(props: MergeRolesDialogProps): JSX.Element | null
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).roles;

    const allRoles = useSelector((state: AppState) => state.company.allRoles);
    const employee = useSelector((state: AppState) => state.employee);
    const rolesWithoutFilter = useSelector((state: AppState) => state.roles);

    const [roles, setRolesState] = useState<Role[] | null>(null);
    const [selectedRoleIds, setSelectedRoleIds] = useState<Set<number>>(new Set());
    const [suggestedRole, setSuggestedRole] = useState<Role | null>(null);
    const [newRoleName, setNewRoleName] = useState<string>("");
    const [newDescription, setNewDescription] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");
    const [saving, setSaving] = useState<boolean>(false);

    // Lade Rollen beim Mounten oder wenn props.roleIds sich ändern
    useEffect(() =>
    {
        if (roles === null)
        {
            getRoles()
                .then((fetchedRoles) =>
                {
                    const relevantRoles = fetchedRoles.filter((role) => props.roleIds.includes(role.id));
                    setRolesState(relevantRoles);

                    // Initialisiere ausgewählte Rollen mit allen relevanten Rollen
                    setSelectedRoleIds(new Set(relevantRoles.map(role => role.id)));

                    // Bestimme die vorgeschlagene Rolle
                    const initialSuggestedRole = determineSuggestedRole(relevantRoles);
                    setSuggestedRole(initialSuggestedRole);
                    setNewRoleName(initialSuggestedRole.title);
                    setNewDescription(initialSuggestedRole.description);
                })
                .catch((error) =>
                {
                    console.error("Error fetching roles:", error);
                    setErrorText(langStrings.errorFetchingRoles);
                });
        }
    }, [props.roleIds, roles, langStrings.errorFetchingRoles]);

    // Funktion zur Bestimmung der vorgeschlagenen Rolle
    const determineSuggestedRole = (rolesList: Role[]): Role =>
    {
        let newSuggestedRole = rolesList[0];
        rolesList.forEach((role) =>
        {
            if (
                role.employees.length > newSuggestedRole.employees.length ||
                (role.employees.length === newSuggestedRole.employees.length)
            )
            {
                newSuggestedRole = role;
            }
        });
        return newSuggestedRole;
    };

    // Funktion zur Handhabung von Checkbox-Änderungen
    const handleCheckboxChange = (roleId: number) =>
    {
        setSelectedRoleIds((prevSelected) =>
        {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(roleId))
            {
                newSelected.delete(roleId);
            } else
            {
                newSelected.add(roleId);
            }
            return newSelected;
        });
    };

    // Aktualisiere die vorgeschlagene Rolle, wenn die Auswahl der Rollen sich ändert
    useEffect(() =>
    {
        if (roles)
        {
            const selectedRoles = roles.filter(role => selectedRoleIds.has(role.id));
            if (selectedRoles.length === 0)
            {
                setSuggestedRole(null);
                setNewRoleName("");
                setNewDescription("");
                return;
            }
            const newSuggested = determineSuggestedRole(selectedRoles);
            setSuggestedRole(newSuggested);
            setNewRoleName(newSuggested.title);
            setNewDescription(newSuggested.description);
        }
    }, [selectedRoleIds, roles]);

    // Generiere eine einzigartige Liste von Mitarbeitern basierend auf den ausgewählten Rollen
    const uniqueEmployees = {} as {
        [key: number]: {
            title: string;
            id: number;
            roles: { title: string; id: number }[];
        };
    };
    roles?.forEach((role) =>
    {
        if (selectedRoleIds.has(role.id))
        {
            role.employees.forEach((emp) =>
            {
                if (!uniqueEmployees[emp.id])
                {
                    uniqueEmployees[emp.id] = {
                        title: emp.title,
                        id: emp.id,
                        roles: [{ title: role.title, id: role.id }],
                    };
                } else
                {
                    uniqueEmployees[emp.id].roles.push({ title: role.title, id: role.id });
                }
            });
        }
    });
    const employees = Object.values(uniqueEmployees);

    if (!employee)
    {
        return null;
    }

    // Bestimme, ob der Merge-Button deaktiviert sein soll
    const isMergeDisabled = saving || selectedRoleIds.size === 0;

    return (
        <Dialog
            id={props.id}
            title={langStrings.mergeRoles}
            onClose={() => props.resolve(false)}
            footer={
                <Box sx={{ float: 'right' }}>
                    <Button
                        icon={<Merge />}
                        disabled={isMergeDisabled}
                        text={langStrings.merge}
                        size={'normal'}
                        onClick={async () =>
                        {
                            if (!selectedRoleIds.size || !suggestedRole) return;
                            setSaving(true);
                            try
                            {
                                const result = await mergeRoles(
                                    {
                                        id: suggestedRole.id,
                                        title: newRoleName,
                                        description: newDescription,
                                    },
                                    Array.from(selectedRoleIds)
                                );

                                if (!result || !employee)
                                {
                                    setErrorText(langStrings.error);
                                    setSaving(false);
                                    return;
                                }

                                setErrorText('');
                                setSaving(false);

                                const newAllRoles = allRoles
                                    .filter(role => !result.removed.includes(role.id))
                                    .map(role =>
                                        role.id === result.newRole.id
                                            ? { ...role, title: result.newRole.title, otherNames: result.otherNames }
                                            : role
                                    );

                                // Aktualisiere die Mitarbeiterrollen
                                const newEmployeeRoles = employee.roles
                                    .filter(role => !result.removed.includes(role.id))
                                    .map(role =>
                                        role.id === result.newRole.id
                                            ? {
                                                ...role,
                                                title: result.newRole.title,
                                            }
                                            : role
                                    );

                                const newRoles = rolesWithoutFilter
                                    .filter(role => !result.removed.includes(role.id))
                                    .map(role =>
                                        role.id === result.newRole.id
                                            ? { ...role, title: result.newRole.title, description: result.newRole.description, employees: result.newRole.employees }
                                            : role
                                    );

                                // Dispatch Aktionen
                                dispatch(setCompanyAllRoles(newAllRoles));
                                dispatch(setEmployeeRoles(newEmployeeRoles));
                                dispatch(setRoles(newRoles.map(ns => new Role(ns))));
                                props.resolve(true);
                            } catch (ex: any)
                            {
                                setErrorText(`${langStrings.error} ${ex.toString()}`);
                                setSaving(false);
                            }
                        }}
                    />
                </Box>
            }
        >
            {!roles && <LoadingBox />}
            {roles && (
                <div className="mergeBox">
                    <Typography variant="body1">{langStrings.rolesSelectedForMerge}:</Typography>
                    {/* Liste der Rollen mit Checkboxen */}
                    <Box sx={{ maxHeight: 200, overflowY: 'auto', border: '1px solid #ccc', padding: 1, marginY: 2 }}>
                        {roles.map(role => (
                            <FormControlLabel
                                key={role.id}
                                control={
                                    <Checkbox
                                        checked={selectedRoleIds.has(role.id)}
                                        onChange={() => handleCheckboxChange(role.id)}
                                        color="primary"
                                    />
                                }
                                label={role.title}
                            />
                        ))}
                    </Box>

                    <Typography variant="body1" marginTop={2} marginBottom={1}>
                        {langStrings.effectEmployeesWarning} {employees.length} {langStrings.employees}:
                    </Typography>
                    <Grid container spacing={2}>
                        {employees.map(emp => (
                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex', flexGrow: 1 }} key={emp.id}>
                                <Card className="employeeCard" style={{ flexGrow: 1 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div">
                                            {emp.title}
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary">
                                            {langStrings.roles}:
                                        </Typography>
                                        {emp.roles.map(role => (
                                            <RoleItem
                                                key={role.id}
                                                title={role.title}
                                                id={role.id}
                                            />
                                        ))}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Typography variant="h5" marginTop={1}>{langStrings.newRole}</Typography>
                    <div className="newRole">
                        <RowElement title={langStrings.role} alignTitle="left">
                            <TextInput value={newRoleName} onChange={s => setNewRoleName(s)} />
                        </RowElement>
                        <RowElement title={langStrings.description} alignTitle="left">
                            <FormatedTextInput value={newDescription} onChange={s => setNewDescription(s)} />
                        </RowElement>
                        <Typography>{langStrings.descriptionGeneratesAutomaticly}</Typography>
                    </div>
                    {errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>}
                    <ErrorBox warning>{langStrings.mergeWarning}</ErrorBox>
                </div>
            )}
        </Dialog>
    );
}

export default MergeRolesDialog;
