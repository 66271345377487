import { Add, Delete } from '@mui/icons-material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ISmallCertificate } from '@sharedInterfaces/IWhoIAm';
import { AppState } from '@store/store';
import { setEmployeeCertificates } from '@store/reducer/employeeReducer';
import changeMapCertificate from '@src/APIs/graphQl/Certificate/changeMapCertificate';
import { NewBadge } from '@src/App/NewLayout/Components/ItemBadge/ItemBadge';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import { useMapCertificateDialog } from '../Dialogs/MapCertificateDialog/MapCertificateDialog';
import Button from '../../sharedReact/General/Button/Button';
import CardBox from '../CardBox/CardBox';
import ErrorBox from '../ErrorBox/ErrorBox';
import './EditCertificateList.css';
import CertificateSearchBox from '../formsControls/searchBoxes/CertificateSearchBox/CertificateSearchBox';

interface EditCertificateListProps 
{
    title: string
    mode: 'edit' | 'add'
    certificates: { title: string, id?: number }[] | undefined
    allCertificates: ISmallCertificate[]
    onChange?: (changed: true) => void
}

/**
 * EditCertificateList function component.
 * 
 * @param {EditCertificateListProps} props - The EditCertificateListProps object.
 * @return {JSX.Element} - The JSX element representing the edit certificate list.
 */
function EditCertificateList(props: EditCertificateListProps)
{
    const mapCertificateDialog = useMapCertificateDialog();
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).certificates;
    const offline = useSelector((state: AppState) => state.client.offline);

    const employee = useSelector((state: AppState) => state.employee);

    const [addedCerts, setAddedCerts] = React.useState([] as string[]);
    const [deletingIds, setDeletingIds] = React.useState([] as number[]);
    const [addingCerts, setAddingCerts] = React.useState([] as string[]);

    React.useEffect(() =>
    {
        if (props.certificates && props.mode === 'add')
        {
            let tempCerts = props.certificates.slice();
            tempCerts = tempCerts.filter(c => !addedCerts.find(a => c.title === a))
            tempCerts.sort((a, b) => a.title.localeCompare(b.title));
            setCertificates(tempCerts);
        } else
        {
            const tempCerts = employee ? [...employee.certificates] : [];
            tempCerts.sort((a, b) => a.title.localeCompare(b.title));
            setCertificates(tempCerts);
        }


    }, [addedCerts, employee, props.certificates, props.mode]);

    const [certificates, setCertificates] = React.useState([] as { title: string, id?: number }[]);
    const [newCertificate, setNewCertificate]: [INewCertificate, (value: INewCertificate) => void] = React.useState({ title: '' } as INewCertificate);
    const [newCertError, setNewCertError]: [boolean, (value: boolean) => void] = React.useState(false as boolean);

    const filteredAllCerts = props.allCertificates.filter(a =>
        certificates.find(s => s.id === a.id || s.title === a.title) === undefined)

    if (!employee) return null



    return (
        <CardBox title={props.title}>
            <div className="editCertificateList">
                <table className="infoBoxCertificate" cellSpacing={0} cellPadding={0}>
                    <tbody>
                        {certificates.map(certificate =>

                            <tr className="infoLine" key={certificate.id ? certificate.id : '' + certificate.title}>
                                <td className="info">{certificate.title}{!certificate.id &&
                                    <NewBadge />
                                }</td>
                                {props.mode === 'edit' &&
                                    <td className="delete">
                                        <Button
                                            size="verysmall"
                                            onClick={unMap(certificate)}
                                            icon={<Delete
                                                fontSize="small"
                                            />}
                                            disabled={deletingIds.includes(certificate.id as number) || offline}
                                            text="Delete"
                                        />
                                    </td>
                                }

                                {props.mode === 'add' &&
                                    <td className="add">
                                        <Button
                                            size="verysmall"
                                            onClick={() =>
                                            {
                                                add(certificate.title, certificate.id);
                                            }}
                                            icon={<Add
                                                fontSize="small"
                                            />}
                                            disabled={addingCerts.includes(certificate.title) || offline}
                                            text="Add"
                                        />
                                    </td>
                                }
                            </tr>
                        )}
                        <tr>
                            <td className="newRow">
                                <CertificateSearchBox
                                    newItem={newCertificate}
                                    filteredAllCertificates={filteredAllCerts}
                                    allowNew
                                    size='small'
                                    clearOnSelect
                                    style={{ maxWidth: '90%' }}
                                    onSelectedCertificate={(element) => { add(element.title, element.id) }}
                                />
                            </td>
                            <td className="newRow" width={32}>
                                <Button
                                    size="verysmall"
                                    onClick={() =>
                                    {
                                        add(newCertificate.title, newCertificate.id);

                                    }}
                                    icon={<Add />}
                                    disabled={addingCerts.includes(newCertificate.title) || offline}
                                    text="Add"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {newCertError && <ErrorBox close={() => setNewCertError(false)}>{langStrings.pleaseEnterAName}</ErrorBox>}
            </div>
        </CardBox>
    );
    async function add(title: string, id?: number)
    {
        if (title !== '')
        {
            setNewCertificate({ title: '' } as INewCertificate);
            if (newCertError) setNewCertError(false);
            const sug = document.getElementById('searchCertificateBoxInput') as HTMLElement
            if (sug) sug.focus();

            if (props.mode === 'add')
            {
                setAddingCerts(addingCerts.concat([title]))
            }
            if (id)
            {
                const result = await mapCertificateDialog(id);
                if (result)
                {
                    setAddedCerts(addedCerts.concat([title]))
                    props.onChange && props.onChange(true);
                } else
                {
                    // showErrorDialog(new Error(langStrings.errorAdd));
                    setAddingCerts(addingCerts.filter(t => t != title))
                }
            }
            else
            {
                const result = await mapCertificateDialog(undefined, title);
                if (result)
                {
                    setAddedCerts(addedCerts.concat([title]))
                    props.onChange && props.onChange(true);
                } else
                {
                    // showErrorDialog(new Error(langStrings.errorAdd));
                    setAddingCerts(addingCerts.filter(t => t != title))
                }
            }
        }
        else
        {
            setNewCertError(true);
        }
    }
    function unMap(certificate: { title: string; id?: number | undefined; }): () => void
    {
        return () =>
        {
            if (!certificate.id) return;
            const newDeletingIds = deletingIds.slice();
            newDeletingIds.push(certificate.id);
            setDeletingIds(newDeletingIds);
            changeMapCertificate(certificate.id, false, undefined, undefined)
                .then(
                    () =>
                    {
                        const updatedDeletingIds = deletingIds.filter(d => d !== certificate.id);
                        setDeletingIds(updatedDeletingIds);

                        dispatch(setEmployeeCertificates(employee.certificates.filter(c => c.id !== certificate.id)));
                        props.onChange && props.onChange(true);
                    }).catch((ex) =>
                    {
                        const updatedDeletingIds = deletingIds.filter(d => d !== certificate.id);
                        setDeletingIds(updatedDeletingIds);
                        showErrorDialog(ex, langStrings.errorRemove);
                    });
        };
    }
}

export interface INewCertificate
{
    id?: number
    title: string
}

export default EditCertificateList;