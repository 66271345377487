import { OperationVariables, gql } from "@apollo/client";
import ICompetence from "@sharedInterfaces/ICompetence";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { setCompanyVersion } from "@store/reducer/companyReducer";
import { setCompetences } from "@store/reducer/competenceReducer";
import Competence, { competenceGQL } from "@src/Objects/Competence";

import GraphQL from "../graphQL";


const q = gql`
query($version:Int!) {
    competences(version:$version){
        version
        competences
        ${competenceGQL}
    }
},
`;

interface CompetenceResponse
{
    competences: { version: number; competences: ICompetence[] };
}


export default async function getCompetences()
{
    const state = store.getState();
    const versions = state.company.versions;
    const variables: OperationVariables = {
        version: versions.competence,
    };
    return GraphQL.query<CompetenceResponse>(q, variables, undefined)
        .then((obj) =>
        {
            if (!obj) throw new Error("Fehler beim laden der Competences.");
            if (obj.competences.competences)
            {
                const data = obj.competences.competences.map((p: ICompetence) => new Competence(p));
                store.dispatch(setCompanyVersion(EEntityType.COMPETENCE, obj.competences.version))
                store.dispatch(setCompetences(data))
                return data;
            }
            return state.competences;
        });
}