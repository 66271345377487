import { ThemeProvider } from "@emotion/react";
import createTheme from "@mui/material/styles/createTheme";
import { IStyleDTO } from "@sharedInterfaces/ICompanySettings";
import { setWindowSize } from "@store/reducer/windowSizeReducer";
import { AppState } from "@store/store";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/hooks/useSelector";
interface ThemeContainerProps
{
    children: React.ReactNode;
    style: IStyleDTO;
}

export default function DesignController({ children, style }: ThemeContainerProps)
{
    const dispatch = useDispatch();
    const dialogCount = useSelector((state: AppState) => state.dialogs.dialogs.length);

    const MuiTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        main: style.primary || '#ff0000',
                        contrastText: style.onPrimary || '#ffffff',
                    },
                    secondary: {
                        main: style.secondary || '#00ffff',
                        contrastText: style.onSecondary || '#000000',
                    },
                },
            }),
        [style]
    );
    useEffect(() =>
    {
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', style.primary);
    }, [style.primary]);

    useEffect(() =>
    {
        const root = document.documentElement;
        root.style.setProperty('--var-dialogBar-height', dialogCount ? '45px' : '0px');
        root.style.setProperty('--var-primary-color', style['primary']);
        root.style.setProperty('--var-on-primary-color', style['onPrimary']);
        root.style.setProperty('--var-secondary-color', style['secondary']);
        root.style.setProperty('--var-on-secondary-color', style['onSecondary']);
    }, [dialogCount, style])

    useEffect(() =>
    {
        function handleResize()
        {
            dispatch(setWindowSize(window.innerWidth, window.innerHeight));
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [dispatch]);

    return (
        <div
            id="themeContainer"
            style={{ height: '100%' }}>
            <ThemeProvider theme={MuiTheme}>
                {children}
            </ThemeProvider>
        </div>
    )
}