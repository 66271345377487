import { ELanguage } from "../languageHelper";

export type ILanugageNamesTexts = {
    [key in ELanguage]: string;
};
type ILangData = {
    [lang in ELanguage]: ILanugageNamesTexts
}
export const lanugagenamesTexts: ILangData = {
    'th': {
        de: 'เยอรมัน',
        th: 'ไทย',
        en: 'อังกฤษ',
    },
    'en': {
        de: 'German',
        th: 'Thai',
        en: 'English',
    },
    'de': {
        de: 'Deutsch',
        en: 'Englisch',
        th: 'Thailändisch',
    },
}