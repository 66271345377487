import { useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import React from 'react';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EEntityType, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import Project from '@src/Objects/Project';
import CardBox from '@src/Components/CardBox/CardBox';
import ProjectDialog from '@src/Components/Dialogs/ProjectDialog/ProjectDialog';
import './ProjectCard.css';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

interface ProjectCardProps
{
    project: Project
    selected?: boolean
    onSelectChange?: (state: boolean) => void
}

/**
 * Represents a project card component.
 * 
 * @param {ProjectCardProps} props - The props object for configuring the project card.
 * @returns {JSX.Element} - The JSX element representing the project card.
 */
function ProjectCard(props: ProjectCardProps)
{
    const { openDialog } = useDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).projects;
    const navigate = useNavigate();
    const project = props.project;
    return (
        <CardBox
            menu={
                <div>
                    <MenuItem
                        onClick={() =>
                        {
                            return openDialog(
                                <ProjectDialog
                                    id={'ProjectDialog'}
                                    project={project}
                                />);
                        }}
                    >
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText>{langStrings.edit}</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                        {
                            project.delete()
                        }}
                    >
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>{langStrings.delete}</ListItemText>
                    </MenuItem>
                </div>
            }
            onSelectChange={props.onSelectChange}
            selected={props.selected}
            title={project.title}
            className={'projectCard'}
            onClick={() =>
            {
                navigate(entityTypeToLink(project.id, EEntityType.PROJECT))
            }}
        >
            <div className="">
                {project.business && project.business.id !== -1 &&
                    `${langStrings.customer}: ${project.business.title}`
                }
            </div>
            <div className="">{`${langStrings.active}: ${project.active ? langStrings.yes : langStrings.no}`}</div>
            <div className="">{`${langStrings.hours}: ${Math.round(project.isHours * 10) / 10} / ${Math.round(project.totalHours * 10) / 10} - ${project.percent}%`}</div>
            <div className="">{`${langStrings.dayPrice}: ${project.dayPrice.toLocaleString('de-DE')}€`}</div>
            <div className="">{`${langStrings.revenue}: ${project.revenue.toLocaleString('de-DE')}€`}</div>
            <ExpandableText>
                <>
                    {langStrings.description + ": "}
                    <FormatedTextInput value={project.description} readonly={true} />
                </>
            </ExpandableText>
        </CardBox>
    );
}

export default ProjectCard;
