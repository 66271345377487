import { OperationVariables, gql } from "@apollo/client";
import { IEmployeeSuggestedSkill } from "@sharedInterfaces/IEmployee";

import GraphQL from "../../graphQL";

const q = gql`
query suggestedSkills($all: Boolean!){
    suggestedSkills(all: $all)
    {
        id
        title
        description
    }
},
`;

/**
 * Retrieves suggested skills for an employee.
 * 
 * @param {Function} callback - The callback function to be called with the suggested skills.
 * @param {Array} callback.employee - An array of suggested skills for the employee.
 * @param {Function} callback.employee[].name - The name of the suggested skill.
 * @param {Function} callback.employee[].type - The type of the suggested skill.
 * @returns {Promise} A promise that resolves to an object containing the suggested skills.
 * @returns {Array} A list of suggested skills for the employee.
 * @returns {String} A suggested skill's name.
 * @returns {String} A suggested skill's type.
 */
export default async function getSuggestedSkills(callback: (employee: IEmployeeSuggestedSkill[]) => void, all = false)
{
    const variables: OperationVariables = { all };
    return GraphQL.query<{ suggestedSkills: IEmployeeSuggestedSkill[] }>(q, variables, (obj) =>
    {
        if (!obj) return;
        callback(obj.suggestedSkills)
    }
    )
}