
import { OperationVariables, gql } from "@apollo/client";
import ICertificate from "@sharedInterfaces/ICertificate";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { setCertificates } from "@store/reducer/certificatesReducer";
import { setCompanyVersion } from "@store/reducer/companyReducer";
import Certificate, { CertificateGQL } from "@src/Objects/Certificate";

import GraphQL from "../graphQL";

const q = gql`
query($version:Int!) {
    certificates(version:$version){
        version
        certificates
        ${CertificateGQL}
    }
},
`;

interface CertificateResponse
{
    certificates: { version: number; certificates: ICertificate[] };
}


export default async function getCertificates()
{
    const state = store.getState();
    const versions = state.company.versions;
    const variables: OperationVariables = {
        version: versions.certificate,
    };
    return GraphQL.query<CertificateResponse>(q, variables, undefined)
        .then((obj) =>
        {
            if (!obj) throw new Error("Fehler beim laden der Certificates.");
            if (obj.certificates.certificates)
            {
                const data = obj.certificates.certificates.map((p: ICertificate) => new Certificate(p));
                store.dispatch(setCompanyVersion(EEntityType.CERTIFICATE, obj.certificates.version))
                store.dispatch(setCertificates(data))
                return data;
            }
            return state.certificates;
        });
}