import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";
const q = gql`
query {
    getUploadURL
    {
        id
        url
        fields
    }
},
`;

export default async function getUploadURL(callback: (data: IUploadData) => void)
{
    const variables: OperationVariables = {};
    return GraphQL.query<{ getUploadURL: IUploadData }>(q, variables, (obj) => obj && callback(obj.getUploadURL));
}

interface IUploadData
{
    id: string,
    url: string,
    fields: {
        [key: string]: string
    }
}