import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IErrorTexts extends ITextBase
{
    stackTrace: string;
    errorMessage: string;
    errorName: string;
    unknownErrorText: string;
    unknownErrorTitle: string;
    errorDetails: string;
    offlineMessage: string;
    offlineTitle: string;
    errorHappend: string;
}
type ILangData = {
    [lang in ELanguage]: IErrorTexts
}
export const errorTexts: ILangData = {
    'th': {
        stackTrace: 'เส้นทางสแต็ก',
        errorMessage: 'ข้อความแสดงข้อผิดพลาด',
        errorName: 'ชื่อข้อผิดพลาด',
        unknownErrorText: 'เกิดข้อผิดพลาดที่ไม่คาดคิดขึ้น!',
        unknownErrorTitle: 'เกิดข้อผิดพลาดที่ไม่คาดคิด',
        offlineMessage: 'การกระทำที่คุณต้องการไม่สามารถทำได้ในโหมดออฟไลน์',
        errorDetails: 'รายละเอียด',
        offlineTitle: 'คุณออฟไลน์อยู่',
        errorHappend: 'เกิดข้อผิดพลาด',
        oftenUsed: oftenUsedTranslations['th'],
    },
    'en': {
        stackTrace: 'Stack trace',
        errorMessage: 'Error message',
        errorName: 'Error name',
        unknownErrorText: 'An unexpected error has occurred!',
        unknownErrorTitle: 'Unexpected Error',
        offlineMessage: 'The desired action is not possible offline.',
        errorDetails: 'Details',
        offlineTitle: 'You are offline',
        errorHappend: 'An error occurred',
        oftenUsed: oftenUsedTranslations['en'],

    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        errorHappend: 'Es ist ein Fehler aufgetreten',
        offlineTitle: 'Sie sind Offline',
        offlineMessage: 'Die gewünschte Aktion ist Offline nicht möglich.',
        errorDetails: 'Details',
        unknownErrorTitle: 'Unerwarteter Fehler',
        unknownErrorText: 'Es ist ein unerwarteter Fehler aufgetreten!',
        errorName: 'Fehlername',
        errorMessage: 'Fehlermeldung',
        stackTrace: 'Stacktrace',
    },
}