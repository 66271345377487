import React from 'react';
import './RowElement.css';
import { Typography } from '@mui/material';

interface RowElementProps
{
    children: React.ReactNode | string
    title?: string
    style?: React.CSSProperties
    alignTitle?: 'left' | 'center'
}
/**
 * Represents a row element.
 * 
 * @param {RowElementProps} children - The children of the row element.
 * @param {RowElementProps} title - The title of the row element.
 * @param {RowElementProps} alignTitle - The alignment of the title of the row element.
 * 
 * @returns {JSX.Element} The generated row element.
 */
function RowElement({ children, title, alignTitle, style }: RowElementProps)
{
    const innerAlignTitle = alignTitle ? alignTitle : 'left';
    return (
        <div className="rowElementColumn" style={{ alignItems: innerAlignTitle === 'left' ? 'flex-start' : 'center', ...style }}>
            {title && <Typography variant='caption' className="rowElementTitle">
                {title}:
            </Typography>}
            {typeof children === 'string' ?
                <Typography variant='body1' className="rowElementContent">
                    {children}
                </Typography> :
                children
            }
        </div>
    );
}

export default RowElement;
