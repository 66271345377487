import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface ICompetenceTexts extends ITextBase
{
    error: string
    errorDuplicateTitle: string;
    pleaseEnterTitle: string;
    suggestedCompetenceName: string;
    generateCompetenceData: string;
    goalDate: string;
    inPlanning: string;
    inPlanningShort: string;
    mapCompetenceDateHelpText1: string;
    mapCompetenceDateHelpText2: string;
    mapCompetenceDateHelpText3: string;
    competenceLevel: string;
    mapCompetenceLevelHelpText: string
    mapCompetenceLevelHelpText2: string
    mapCompetenceLevelHelpText3: string
    mapCompetenceLevelHelpText4: string
    mapCompetenceLevelHelpText5: string
    mapCompetence: string;
    hideShowDialogTitle: string;
    showOrHide: string;
    hideSuggestedCompetenceHelper: string
    hideSuggestedCompetence: string;
    configurate: string;
    suggestionCardHelpText: string;
    suggestedCompetences: string;
    suitableEmployees: string;
    descriptionPlaceholder: string;
    deleteCompetenceLevel: string;
    competenceLevelNamePlaceholder: string;
    name: string;
    certificates: string;
    skills: string;
    noVaildCompetenceName: string;
    save: string;
    create: string;
    delete: string;
    edit: string;
    competence: string
    competenceName: string;
    competences: string
    description: string;
    employees: string;
    level: string; //singular
    levels: string; //Plural
    newCompetence: string;
    noVailedLevelName: string
}
type ILangData = {
    [lang in ELanguage]: ICompetenceTexts
}
export const competenceTexts: ILangData = {
    'th': {
        generateCompetenceData: 'สร้างข้อมูลความสามารถด้วย AI',
        suggestedCompetenceName: 'ชื่อความสามารถที่แนะนำ',
        pleaseEnterTitle: 'กรุณากรอกชื่อ.',
        errorDuplicateTitle: 'ชื่อ "[TITLE]" ไม่ถูกต้อง, ความสามารถที่มีชื่อว่า "[TITLE]" มีอยู่แล้ว.',
        goalDate: 'วันที่เป้าหมาย',
        inPlanning: 'พนักงานวางแผนที่จะได้รับความสามารถนี้',
        inPlanningShort: 'กระบวนการเรียนรู้',
        mapCompetenceDateHelpText1: 'ในขั้นตอนนี้คุณต้องตั้งวันที่เจาะจงสำหรับเป้าหมายของคุณ คุณต้องการที่จะสามารถทำให้ได้ระดับความสามารถที่ต้องการในเวลาใด?',
        mapCompetenceDateHelpText2: 'การเลือกวันที่ที่เป็นไปได้จริงช่วยให้คุณวางแผนและติดตามความคืบหน้าของคุณได้ดีขึ้น',
        mapCompetenceDateHelpText3: 'คุณได้เลือก [DATE] เป็นวันที่เป้าหมาย วันที่นี้จะเป็นพื้นฐานสำหรับแผนการพัฒนาบุคคลของคุณ',
        mapCompetenceLevelHelpText4: "ด้านล่างนี้คุณจะพบรายการของทักษะที่คุณต้องทำงานเพื่อเป็นไปตามระดับความสามารถที่ต้องการ ภาพรวมนี้มีจุดประสงค์เพื่อช่วยคุณค้นหาระดับความสามารถที่เหมาะสมและตั้งเป้าหมายที่เป็นไปได้สำหรับการพัฒนาทางอาชีพของคุณ",
        mapCompetenceLevelHelpText5: "เราขอแนะนำให้คุณวางแผนการฝึกสอนเพื่อเพิ่มทักษะเหล่านี้ในแผนการพัฒนาส่วนบุคคลของคุณอย่างเข้มงวด ทันทีที่คุณได้รับทักษะที่ขาดหายไปแล้ว ระดับความสามารถของคุณในระบบจะอัปเดตโดยอัตโนมัติตามทักษะที่คุณบันทึกในโปรไฟล์ของคุณ",
        mapCompetenceLevelHelpText3: 'เมื่อคุณได้ตัดสินใจเลือกระดับความสามารถแล้ว คลิก "ต่อไป" เพื่อดำเนินการต่อ',
        competenceLevel: 'ระดับความสามารถ',
        mapCompetenceLevelHelpText2: 'เลือกระดับที่ตรงกับเป้าหมายของคุณ',
        mapCompetenceLevelHelpText: 'คุณเลือกความสามารถ "[TITLE]" ก่อนที่คุณจะดำเนินการต่อ โปรดเลือกระดับของความสามารถที่คุณต้องการที่จะถึง',
        mapCompetence: 'จับคู่ความสามารถ "[TITLE]"',
        hideShowDialogTitle: 'แสดง/ซ่อนความสามารถ',
        showOrHide: 'แสดง/ซ่อน',
        hideSuggestedCompetenceHelper: 'ความสามารถนี้ไม่น่าสนใจสำหรับฉันและควรถูกซ่อน.',
        hideSuggestedCompetence: 'ซ่อนความสามารถที่แนะนำ',
        configurate: 'กำหนดค่า',
        suggestionCardHelpText: 'ทักษะที่แสดงให้เห็นนี้มีเป้าหมายเพื่อช่วยเหลือคุณในการวางแผนความสามารถและเป้าหมายของคุณได้ดียิ่งขึ้น พื้นฐานจากทักษะปัจจุบันของคุณ เราขอแนะนำทักษะที่เหมาะสม ดังนั้นคุณสามารถเลือกทักษะใดทักษะหนึ่งที่คุณต้องการพัฒนาต่อ และวิธีการที่คุณสามารถบรรลุเป้าหมายในอาชีพของคุณ',
        suggestedCompetences: 'ความสามารถที่แนะนำ',
        suitableEmployees: 'พนักงานที่เหมาะสม',
        descriptionPlaceholder: 'เลือกช่วยเหลือการขาย:\nความสามารถนี้มีลักษณะเด่นอย่างไร, จำเป็นต้องใช้ในที่ใด, ควรจะมีพร้อมสิ่งที่เป็นเงื่อนไขปฐมภูมิ รายละเอียดเพิ่มเติมเหล่านี้คืออะไรที่จะสร้างสรรค์มูลค่าให้กับลูกค้า?',
        deleteCompetenceLevel: 'ลบระดับความสามารถ',
        delete: 'ลบความสามารถ',
        competenceName: 'ชื่อความสามารถ',
        description: 'คำอธิบาย',
        employees: 'พนักงาน',
        level: 'ระดับ',
        levels: 'ระดับ',
        newCompetence: 'ความสามารถใหม่',
        noVaildCompetenceName: 'โปรดใส่ชื่อสำหรับความสามารถใหม่',
        name: 'ชื่อ',
        competenceLevelNamePlaceholder: 'มือใหม่',
        noVailedLevelName: 'กรุณาใส่ชื่อสำหรับทุกระดับความสามารถ',
        error: oftenUsedTranslations['th'].error,
        competence: oftenUsedTranslations['th'].competence,
        competences: oftenUsedTranslations['th'].competences,
        edit: oftenUsedTranslations['th'].edit,
        save: oftenUsedTranslations['th'].save,
        create: oftenUsedTranslations['th'].create,
        skills: oftenUsedTranslations['th'].skills,
        certificates: oftenUsedTranslations['th'].certificates,
        oftenUsed: oftenUsedTranslations['th'],
    },
    'en': {
        generateCompetenceData: 'Generate competence information by AI',
        suggestedCompetenceName: 'Suggested competence name',
        pleaseEnterTitle: 'Please enter a title.',
        errorDuplicateTitle: 'The name "[TITLE]" is invalid, a competence with the name "[TITLE]" already exists.',
        goalDate: 'Goal Date',
        inPlanning: 'The employee is planning to develop this competency.',
        inPlanningShort: 'Learning process',
        mapCompetenceDateHelpText1: 'In this step, you are to set a concrete date for your goal. When would you like to achieve the desired competence level?',
        mapCompetenceDateHelpText2: 'Choosing a realistic date helps you better plan and monitor your progress.',
        mapCompetenceDateHelpText3: 'You have selected [DATE] as your target date. This date will serve as the basis for your individual development plan.',
        mapCompetenceLevelHelpText4: "Below you will find a list of skills that you need to work on to achieve the desired competence level. This overview is designed to help you find a suitable competence level and set realistic goals for your professional development.",
        mapCompetenceLevelHelpText5: "We recommend that you firmly incorporate training to acquire these skills into your individual development plan. As soon as you have acquired the missing skills, your competence level in the system will automatically update based on the skills maintained in your profile.",
        mapCompetenceLevelHelpText3: 'Once you have decided on a competence level, click "Next" to continue.',
        competenceLevel: 'Competence level',
        mapCompetenceLevelHelpText2: 'Choose the level that matches your goal.',
        mapCompetenceLevelHelpText: 'You have selected the competence "[TITLE]". Before you proceed, please choose the level of competence you aim for.',
        mapCompetence: 'Assign competence "[TITLE]"',
        showOrHide: 'Show/Hide',
        hideShowDialogTitle: 'Show/Hide Competences',
        hideSuggestedCompetenceHelper: 'This competence is not interesting to me and should be hidden.',
        hideSuggestedCompetence: 'Hide competence',
        configurate: 'Configure',
        suggestionCardHelpText: 'The competencies displayed are intended to help you better plan your skills and goals. Based on your current abilities, we suggest suitable competencies. So you can decide which competencies you want to develop further and how you can achieve your professional goals.',
        suggestedCompetences: 'Suggested competences',
        suitableEmployees: 'Employee Potentials',
        descriptionPlaceholder: 'Sales assistance:\nWhat are the characteristics of the competence, where is it mainly needed, what prerequisites should be met. what added value does this create for the customer?',
        deleteCompetenceLevel: 'Delete competence level',
        delete: 'Delete competence',
        competenceName: 'Competence Name',
        description: 'Description',
        employees: 'Employees',
        level: 'Level',
        levels: 'Levels',
        newCompetence: 'New Competence',
        noVaildCompetenceName: 'Please enter a name for the new competence.',
        name: 'Name',
        competenceLevelNamePlaceholder: 'Beginner',
        noVailedLevelName: 'Please enter a name for each competency level.',
        error: oftenUsedTranslations['en'].error,
        competence: oftenUsedTranslations['en'].competence,
        competences: oftenUsedTranslations['en'].competences,
        edit: oftenUsedTranslations['en'].edit,
        save: oftenUsedTranslations['en'].save,
        create: oftenUsedTranslations['en'].create,
        skills: oftenUsedTranslations['en'].skills,
        certificates: oftenUsedTranslations['en'].certificates,
        oftenUsed: oftenUsedTranslations['en'],
    },
    'de': {

        oftenUsed: oftenUsedTranslations['de'],
        competence: oftenUsedTranslations['de'].competence,
        competences: oftenUsedTranslations['de'].competences,
        description: oftenUsedTranslations['de'].description,
        employees: oftenUsedTranslations['de'].employees,
        edit: oftenUsedTranslations['de'].edit,
        save: oftenUsedTranslations['de'].save,
        create: oftenUsedTranslations['de'].create,
        skills: oftenUsedTranslations['de'].skills,
        certificates: oftenUsedTranslations['de'].certificates,
        error: oftenUsedTranslations['de'].error,
        competenceName: 'Kompetenz-Name',
        delete: 'Kompetenz löschen',
        deleteCompetenceLevel: 'Kompetenz-Level löschen',
        level: 'Stufe',
        levels: 'Stufen',
        newCompetence: 'Neue Kompetenz',
        noVaildCompetenceName: 'Bitte gib einen Namen für die neue Kompetenz ein.',
        noVailedLevelName: 'Bitte gib einen Namen für jedes Kompetenz-Level ein.',
        name: 'Name',
        competenceLevelNamePlaceholder: 'Anfänger',
        descriptionPlaceholder: `Vertriebshilfe:\nWas zeichnet die Kompetenz aus, wo wird diese maßgeblich benötigt, welche Voraussetzungen sollten erfüllt sein. welchen Mehrwert erzeugt diese beim Kunden?`,
        suitableEmployees: 'Mitarbeiter Potentiale',
        suggestedCompetences: 'Vorgeschlagene Kompetenzen',
        suggestionCardHelpText: 'Die angezeigten Kompetenzen sollen dir dabei helfen, deine Fähigkeiten und Ziele besser zu planen. Basierend auf deinen aktuellen Fähigkeiten schlagen wir dir passende Kompetenzen vor. So kannst du entscheiden, welche Kompetenzen du weiterentwickeln möchtest und wie du deine beruflichen Ziele erreichen kannst.',
        configurate: 'Konfigurieren',
        hideSuggestedCompetence: 'Kompetenz ausblenden',
        hideSuggestedCompetenceHelper: 'Diese Kompetenz ist für mich uninteressant und soll ausblendet werden.',
        showOrHide: "Ein/Ausblenden",
        hideShowDialogTitle: 'Kompetenzen Ein/Ausblenden',
        mapCompetence: 'Kompetenz "[TITLE]" zuordnen',
        mapCompetenceLevelHelpText: 'Du hast die Kompetenz "[TITLE]" ausgewählt. Bevor du fortfährst, wähle bitte die Stufe der Kompetenz aus, die du erreichen möchtest.',
        mapCompetenceLevelHelpText2: 'Wähle die Stufe aus, die deinem Ziel entspricht.',
        mapCompetenceLevelHelpText3: 'Sobald du dich für eine Kompetenzstufe entschieden hast, klicke auf "Weiter", um fortzufahren.',
        competenceLevel: 'Kompetenzstufe',
        mapCompetenceLevelHelpText4: 'Nachfolgend findest du eine Liste der Fähigkeiten, an denen du arbeiten solltest, um zur gewünschten Kompetenzstufe aufzusteigen. Diese Übersicht soll dir helfen, eine passende Kompetenzstufe zu finden und realistische Ziele für deine berufliche Entwicklung zu setzen.',
        mapCompetenceLevelHelpText5: 'Es wird empfohlen, das Training zum Erlangen dieser Fähigkeiten in deinem individuellen Entwicklungsplan fest einzuplanen. Sobald du die fehlenden Fähigkeiten erworben hast, aktualisiert sich deine Kompetenzstufe im System automatisch auf Basis der im Profil gepflegten Fähigkeiten.',
        mapCompetenceDateHelpText1: 'In diesem Schritt geht es darum, ein konkretes Datum für Ihr Ziel festzulegen. Wann möchtest du die angestrebte Kompetenzstufe erreichen?',
        mapCompetenceDateHelpText2: 'Die Auswahl eines realistischen Datums hilft dir, deine Fortschritte besser zu planen und zu überwachen.',
        mapCompetenceDateHelpText3: 'Du hast den [DATE] als Ziel-Datum ausgewählt. Dieses Datum wird als Grundlage für deinen individuellen Entwicklungsplan dienen.',
        inPlanningShort: 'Lernprozess',
        inPlanning: 'Der Mitarbeiter plant diese Kompetenz sich zu erarbeiten.',
        goalDate: 'Ziel-Datum',
        errorDuplicateTitle: `Der Name "[TITLE]" ist ungültig, eine Kompetenz mit dem Namen "[TITLE]" existiert bereits.`,
        pleaseEnterTitle: 'Bitte gib einen Namen ein.',
        suggestedCompetenceName: 'Vorgeschlagener Kompetenz-Name',
        generateCompetenceData: 'Kompetenz-Informationen per AI generieren',
    },
}