import { Error, Settings } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EAvailabilityInputAs } from '@sharedInterfaces/IAvailabilityMonth';
import { IEmployeeProjectReferenceDTO } from '@sharedInterfaces/IEmployee';
import { AppState } from '@store/store';
import AvailabilityMonth from '@src/Objects/AvailabilityMonth';

import { IState, AvailabilityTextFieldDays, FACTOR_VERYHIGH, FACTOR_HIGH, FACTOR_MUCHTOHIGH, distributeWorkOnDays, getWorkDayCountForDay } from './AvailabilityMonthDialog';

interface WeeksContainerProps
{
    state: IState;
    setState: (state: IState) => void;
    availabilityMonth: AvailabilityMonth;
    onClickSettings: () => void;
    renderProjectTD: (project: IEmployeeProjectReferenceDTO) => JSX.Element;
}
/**
* WeeksContainer component.
* 
* @param {WeeksContainerProps} props - The props object.
* @returns {JSX.Element} - The JSX element.
*/
export function WeeksContainer(props: WeeksContainerProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).availability;
    const projects = useSelector((state: AppState) => state.employee.projects)
        .filter(p => p.active);
    const state = props.state;
    const hiddenProjects = useSelector((state: AppState) => state.employeeSettings.hiddenProjects);
    const visibleProjects = projects.filter(p => !hiddenProjects.includes(p.id));
    const month = state.months.find(m => m.id === props.availabilityMonth.id);
    const monthIndex = state.months.findIndex(m => m.id === props.availabilityMonth.id);
    return <div id="weeksContainer">
        <table className='activityTable'>
            <thead>
                <tr>
                    <td>
                        <div className='activityHeader'>
                            {langStrings.activity}
                            <IconButton size='small' onClick={props.onClickSettings}><Settings fontSize='small' /></IconButton>
                        </div>
                    </td>
                    {month && month.weekArray.map(week =>
                    {
                        const fd = week.firstDay;
                        const ld = week.days[week.days.length - 1].date;
                        const weekStart = `${fd.getDate().toString().padStart(2, '0')}.${(fd.getMonth() + 1).toString().padStart(2, '0')}`;
                        const weekEnd = `${ld.getDate().toString().padStart(2, '0')}.${(ld.getMonth() + 1).toString().padStart(2, '0')}`;
                        return <td key={week.weekNumber}>
                            {`${langStrings.calendarWeek}${week.weekNumber}`}
                            <br />
                            {`${weekStart}-${weekEnd}`}
                        </td>;
                    })}
                </tr>
            </thead>
            <tbody>
                {month &&
                    visibleProjects.map(project =>
                    {
                        return <tr key={project.id}>
                            {props.renderProjectTD(project)}
                            {month.weekArray.map((week, index) =>
                            {
                                const maxWorkdays = week.days.filter(d => d.isWorkday);
                                const maxWorkdaysCount = maxWorkdays.length ? maxWorkdays.length : 1;
                                const maxUnits = state.inputAs === EAvailabilityInputAs.days ? maxWorkdaysCount : maxWorkdaysCount * 8;
                                const planedHoursForProject = week.days
                                    .reduce(
                                        (sum, day) => sum + day.planings
                                            .filter(p => parseInt(p.activityId) === project.id)
                                            .reduce((sum, plan) => sum + plan.value
                                                , 0)
                                        , 0);
                                return <td key={week.weekNumber}>
                                    <AvailabilityTextFieldDays disabled={false} max={maxUnits * 2}
                                        value={Math.round(planedHoursForProject * 100) / 100}
                                        setValue={(value) =>
                                        {
                                            const workdays = week.days.reduce((sum, day) =>
                                            {
                                                return sum + getWorkDayCountForDay(day);
                                            }, 0);
                                            const workPerDay = value / workdays;

                                            const newState = {
                                                ...state,
                                            };
                                            const newMonth = newState.months[monthIndex];
                                            const newWeek = newMonth.weekArray[index];
                                            if (workdays)
                                            {
                                                distributeWorkOnDays(newWeek, workPerDay, project);
                                            } else
                                            {
                                                newWeek.days.forEach(d =>
                                                {
                                                    const workPerDayWithWeekend = value / newWeek.days.length;
                                                    const plan = d.planings.find(p => parseInt(p.activityId) === project.id);
                                                    if (plan)
                                                    {
                                                        plan.value = workPerDayWithWeekend
                                                    } else
                                                    {
                                                        d.planings.push({ activityId: project.id.toString(), value: workPerDayWithWeekend })
                                                    }
                                                })
                                            }
                                            props.setState(newState);
                                        }} />
                                </td>;
                            })}
                        </tr>;
                    })}
                <tr>
                    <td>{langStrings.absence}</td>
                    {month && month.weekArray.map((week) =>
                    {
                        const sum = week.days.reduce((sum, current) =>
                        {
                            return sum + (current.absence ? current.absence : 0) + (current.holiday ? current.holiday : 0);
                        }, 0) * (state.inputAs === EAvailabilityInputAs.days ? 1 : 8);
                        return <td key={week.weekNumber}>
                            {sum}
                        </td>;
                    })}
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td className='sumBox'>
                        {langStrings.sum}
                    </td>
                    {month && month.weekArray.map(week =>
                    {
                        const sumAbsence = week.days.reduce((sum, current) =>
                        {
                            return sum + (current.absence ? current.absence : 0) + (current.holiday ? current.holiday : 0);
                        }, 0) * (state.inputAs === EAvailabilityInputAs.days ? 1 : 8);
                        const workdays = week.days.filter(d => d.isWorkday);
                        const maxUnits = state.inputAs === EAvailabilityInputAs.days ? workdays.length : workdays.length * 8;

                        const units = Math.round((week.days.reduce((sum, current) =>
                        {
                            return sum + (current ? current.planings.reduce((sum, plan) => sum + plan.value, 0) : 0);
                        }, 0) + sumAbsence) * 100) / 100;
                        const factor = units / maxUnits;
                        return <td key={week.weekNumber} className={factor > FACTOR_VERYHIGH ? 'veryhigh' : (factor > FACTOR_HIGH ? 'high' : '')}>
                            <div className={'sumBox ' + (factor > FACTOR_MUCHTOHIGH ? 'muchToHigh' : '')}>
                                {`${units} / ${maxUnits}`}{factor > FACTOR_MUCHTOHIGH ? <Error className='warning' /> : ''}
                            </div>
                        </td>;
                    })}
                </tr>
            </tfoot>
        </table>
    </div>;
}


