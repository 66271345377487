import { ArrowBack, ArrowForward, Error, Settings } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EAvailabilityInputAs, IAvailabilityExtensionMonthDTO } from '@sharedInterfaces/IAvailabilityMonth';
import { IEmployeeProjectReferenceDTO } from '@sharedInterfaces/IEmployee';
import { AppState } from '@store/store';
import AvailabilityMonth from '@src/Objects/AvailabilityMonth';

import { AvailabilityTextFieldDays, FACTOR_VERYHIGH, FACTOR_HIGH, FACTOR_MUCHTOHIGH, IState } from './AvailabilityMonthDialog';
export interface WeekContainerProps
{
    state: IState
    setState: (state: IState) => void;
    availabilityMonth: AvailabilityMonth;
    onClickSettings: () => void;
    renderProjectTD: (project: IEmployeeProjectReferenceDTO) => JSX.Element;
}

/**
 * WeekContainer component.
 * 
 * @param props - props for the WeekContainer component
 * @returns JSX.Element
 */
export function WeekContainer(props: WeekContainerProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).availability;
    const projects = useSelector((state: AppState) => state.employee.projects)
        .filter(p => p.active);
    const [weekId, setWeekId] = React.useState(0 as number);
    const state = props.state;
    const maxUnits = state.inputAs === EAvailabilityInputAs.days ? 1 : 8;
    const month = state.months.find(m => m.id === props.availabilityMonth.id) as IAvailabilityExtensionMonthDTO;
    const activeWeek = month.weekArray[weekId];
    const days = activeWeek.days;
    const fd = activeWeek.firstDay;
    const ld = activeWeek.days[activeWeek.days.length - 1].date;
    const weekStart = `${fd.getDate().toString().padStart(2, '0')}.${(fd.getMonth() + 1).toString().padStart(2, '0')}`;
    const weekEnd = `${ld.getDate().toString().padStart(2, '0')}.${(ld.getMonth() + 1).toString().padStart(2, '0')}`;
    const hiddenProjects = useSelector((state: AppState) => state.employeeSettings.hiddenProjects);
    const visibleProjects = projects.filter(p => !hiddenProjects.includes(p.id));

    const monthIndex = state.months.findIndex(m => m.id === props.availabilityMonth.id);

    return <div id="weekContainer">
        <div className='arrowBox'>
            <IconButton size='small' disabled={weekId === 0} onClick={() => setWeekId(weekId - 1)}>
                <ArrowBack />
            </IconButton>
            <span className='weekName'>{`${langStrings.calendarWeek + activeWeek.weekNumber} ${weekStart} - ${weekEnd}`}</span>
            <IconButton size='small' disabled={!month || weekId === month.weekArray.length - 1} onClick={() => setWeekId(weekId + 1)}>
                <ArrowForward />
            </IconButton>
        </div>
        <table className='activityTable'>
            <thead>
                <tr>
                    <td>
                        <div className='activityHeader'>
                            {langStrings.activity}
                            <IconButton size='small' onClick={props.onClickSettings}><Settings fontSize='small' /></IconButton>
                        </div>
                    </td>
                    {days.map(day =>
                    {
                        const dateString = `${day.date.getDate().toString().padStart(2, '0')}.${(day.date.getMonth() + 1).toString().padStart(2, '0')}`;
                        return <td key={day.date.toISOString()} className={!day.isWorkday ? 'weekend' : ''}>
                            {`${dateString}`}
                        </td>;
                    })}
                </tr>
            </thead>
            <tbody>
                {visibleProjects.map(project =>
                {
                    return <tr key={project.id}>
                        {props.renderProjectTD(project)}
                        {days.map((day, index) =>
                        {
                            const planning = day.planings.find(p => parseInt(p.activityId) === project.id)
                            const projectWorkdays = planning ? planning.value : 0;
                            return <td key={day.date.toISOString()} className={!day.isWorkday ? 'weekend' : ''}>
                                <AvailabilityTextFieldDays disabled={false} max={maxUnits * 2}
                                    value={Math.round(projectWorkdays * 100) / 100}
                                    setValue={(value) =>
                                    {
                                        const newState = {
                                            ...state,
                                        };
                                        const newWeek = newState.months[monthIndex].weekArray.find(w => w.weekNumber === activeWeek.weekNumber)
                                        if (!newWeek) return;
                                        const day = newWeek.days[index];
                                        const plan = day.planings.find(p => parseInt(p.activityId) === project.id);
                                        if (plan)
                                        {
                                            plan.value = value
                                        } else
                                        {
                                            day.planings.push({ activityId: project.id.toString(), value: value })
                                        }
                                        props.setState(newState);
                                    }} />
                            </td>;
                        })}
                    </tr>;
                })}
                <tr>
                    <td>{langStrings.absence}</td>
                    {days.map((day) =>
                    {
                        return <td key={day.date.toISOString()} className={!day.isWorkday ? 'weekend' : ''}>
                            {((day.absence ? day.absence : 0) + (day.holiday ? day.holiday : 0)) * (state.inputAs === EAvailabilityInputAs.days ? 1 : 8)}
                        </td>;
                    })}
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td className='sumBox'>
                        {langStrings.sum}
                    </td>
                    {days.map(day =>
                    {
                        const maxUnits = state.inputAs === EAvailabilityInputAs.days ? 1 : 8;
                        const units = day.planings.reduce((sum, current) =>
                        {
                            return sum + (current.value);
                        }, 0) + (day.absence + day.holiday) * (state.inputAs === EAvailabilityInputAs.days ? 1 : 8);
                        const factor = units / maxUnits;
                        return <td key={day.date.toISOString()} className={(factor > FACTOR_VERYHIGH ? 'veryhigh' : (factor > FACTOR_HIGH ? 'high' : ''))}>
                            <div className={'sumBox ' + (!day.isWorkday ? 'weekend ' : '') + (factor > FACTOR_MUCHTOHIGH ? 'muchToHigh' : '')}>
                                {`${Math.round(units * 100) / 100} / ${day.isWorkday ? maxUnits : 0}`}{factor > FACTOR_MUCHTOHIGH ? <Error className='warning' /> : ''}
                            </div>
                        </td>;
                    })}
                </tr>
            </tfoot>
        </table>
    </div>;
}
