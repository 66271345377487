import { OperationVariables, gql } from "@apollo/client";
import ISkillDetailDTO from "@sharedInterfaces/ISkill";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { setSkills } from "@store/reducer/skillReducer";
import { setCompanyVersion } from "@store/reducer/companyReducer";
import SkillDetail, { skillDetailGQL } from "@src/Objects/SkillDetail";

import GraphQL from "../graphQL";

const q = gql`
query($version:Int!) {
    skills(version:$version){
        version
        skills
        ${skillDetailGQL}
    }
},
`;

interface SkillResponse
{
    skills: { version: number; skills: ISkillDetailDTO[] };
}

export default async function getSkills()
{
    const state = store.getState();
    const versions = state.company.versions;
    const variables: OperationVariables = {
        version: versions.skill,
    };
    return GraphQL.query<SkillResponse>(q, variables, undefined)
        .then((obj) =>
        {
            if (!obj) throw new Error("Fehler beim laden der Skills.");
            if (obj.skills.skills)
            {
                const data = obj.skills.skills.map((p: ISkillDetailDTO) => new SkillDetail(p));
                store.dispatch(setCompanyVersion(EEntityType.SKILL, obj.skills.version))
                store.dispatch(setSkills(data))
                return data;
            }
            return state.skills;
        });
}