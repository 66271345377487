import { EEntityType } from "./globalEnums";

export enum ESuggestionCycle
{
    NEVER = "never",
    WEEKLY = "weekly",
}
export type TSuggestionsRequest = IGetInitSuggestionsRequest
    | IGetSuggestionsRequest
    | IRefreshSuggestionsRequest
    | IDeleteSuggestionRequest
    | IDeclineSuggestionRequest
    | IGetRejectionsSuggestionRequest
    | IDeleteRejectionsSuggestionRequest
    | ISetSuggestionSettingsRequest;

export type TSuggestionsResponse = ISuggestionInitResponse
    | IDuplicateSuggestionsResponse
    | IRejectionSuggestionsResponse
    | ISuggestionSettingsSaved;


export interface ISuggestionSettings
{
    cycle: ESuggestionCycle;
    notifyAdmins: boolean;
}
export interface IDuplicateSuggestionsResponse
{
    type: ESuggestionResponseType.SUGGESTIONS;
    suggestions: TSuggestion[];
}
export interface ISuggestionInitResponse
{
    type: ESuggestionResponseType.INIT_SUGGESTIONS;
    suggestions: TSuggestion[];
    settings: ISuggestionSettings;
}
export interface ISuggestionSettingsSaved
{
    type: ESuggestionResponseType.SAVED_SETTINGS;
}
export interface IRejectionSuggestionsResponse
{
    type: ESuggestionResponseType.REJECTIONS;
    rejections: ISuggestionRejections;
}


export type TSuggestion = IDuplicateSuggestionResponseSuggestion;
export interface ISuggestionResponseBase
{
    id: number;
    type: ESuggestionType;
}

export interface ISuggestionDuplicate
{
    id: number;
    title: string;
    otherNames?: string[];
}

export interface IDuplicateSuggestionResponseSuggestion extends ISuggestionResponseBase
{
    type: ESuggestionType.duplicate;
    entityType: EEntityType
    newTitle: string;
    reason: string;
    duplicates: ISuggestionDuplicate[];
}

export enum ESuggestionType
{
    duplicate = 'duplicate',
}
export enum ESuggestionResponseType
{
    SUGGESTIONS = 'SUGGESTIONS',
    REJECTIONS = "REJECTIONS",
    INIT_SUGGESTIONS = "INIT_SUGGESTIONS",
    SAVED_SETTINGS = "SAVED_SETTINGS",
}
export enum ESuggestionRequestType
{
    GET_SUGGESTIONS = 'GET_SUGGESTIONS',
    REFRESH_SUGGESTIONS = "REFRESH_SUGGESTIONS",
    DELETE_SUGGESTION = "DELETE_SUGGESTION",
    DECLINE_SUGGESTION = "DECLINE_SUGGESTION",
    GET_REJECTIONS = "GET_REJECTIONS",
    DELETE_REJECTION = "DELETE_REJECTION",
    GET_INIT_SUGGESTIONS = "GET_INIT_SUGGESTIONS",
    SET_SUGGESTION_SETTINGS = "SET_SUGGESTION_SETTINGS",
}

/**
 * Request object for duplicate suggestions.
 */
export interface IGetSuggestionsRequest
{
    type: ESuggestionRequestType.GET_SUGGESTIONS;
}
export interface IGetInitSuggestionsRequest
{
    type: ESuggestionRequestType.GET_INIT_SUGGESTIONS;
}
export interface IRefreshSuggestionsRequest
{
    type: ESuggestionRequestType.REFRESH_SUGGESTIONS;
}

export interface IDeleteSuggestionRequest
{
    type: ESuggestionRequestType.DELETE_SUGGESTION;
    id: number;
}
export interface IDeclineSuggestionRequest
{
    type: ESuggestionRequestType.DECLINE_SUGGESTION;
    id: number;
    reason: string;
}

export interface IGetRejectionsSuggestionRequest
{
    type: ESuggestionRequestType.GET_REJECTIONS;
}

export interface IDeleteRejectionsSuggestionRequest
{
    type: ESuggestionRequestType.DELETE_REJECTION;
    newTitle: string;
    entityType: EEntityType;
}

export interface ISetSuggestionSettingsRequest
{
    type: ESuggestionRequestType.SET_SUGGESTION_SETTINGS;
    settings: ISuggestionSettings;
}

export interface ISuggestionRejections
{
    [entityType: string]: ISuggestionRejection[];
}
export interface ISuggestionRejection
{
    newTitle: string
    titles: string[]
    declinedReason: string
}
