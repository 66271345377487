import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import { Entity } from '@sharedReact/Objects/Entity';
import { Link } from 'react-router-dom';
import { ISmallEmployee } from "@sharedInterfaces/IWhoIAm";
import EmployeeOverlay from '@src/Components/Overlays/EmployeeOverlay/EmployeeOverlay';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';
import { ESystemUser } from '@sharedInterfaces/ESystemUser';
import { ILanguageGeneral } from '@sharedInterfaces/Language/languageTexts/oftenUsed';

import styles from './VersionInfoDialog.module.css';

interface VersionInfoDialogProps
{
    id: string
    entity: Entity
    resolve: () => void;
}

export const VersionInfoDialog: React.FC<VersionInfoDialogProps> = ({ id, entity, resolve }) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).entityPage;
    const employees = useSelector((state: AppState) => state.company.allEmployees);

    const [isOpen, setIsOpen] = useState(true);

    const onClose = () =>
    {
        resolve();
        setIsOpen(false);
    };


    const [createdByTitle, createdByLink] = getEmployee(employees, entity['createdBy'], langStrings);
    const [changedByTitle, changedByLink] = getEmployee(employees, entity['changedBy'], langStrings);

    if (!isOpen) return null;

    return (
        <Dialog
            id={id}
            footer={
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                    <Button
                        text={langStrings.oftenUsed.close}
                        icon={<Close />}
                        onClick={onClose}
                    />
                </div>
            }
            title={langStrings.versionInfo}
            onClose={onClose}
        >
            <div style={{ margin: 10 }}>
                <table border={0} className={styles.table}>
                    <tbody>
                        <RenderTableRow
                            title={langStrings.oftenUsed.version}
                            value={entity.version ? entity.version.toString() : "1"}
                            close={resolve}
                        />
                        <RenderTableRow
                            title={langStrings.oftenUsed.changedAt}
                            value={entity.changedAt ? new Date(entity.changedAt).toLocaleString() : undefined}
                            close={resolve}
                        />
                        <RenderTableRow
                            title={langStrings.oftenUsed.changedBy}
                            value={changedByTitle}
                            link={changedByLink}
                            close={resolve}
                        />
                        <RenderTableRow
                            title={langStrings.oftenUsed.createdAt}
                            value={entity.createdAt ? new Date(entity.createdAt).toLocaleString() : undefined}
                            close={resolve}
                        />
                        <RenderTableRow
                            title={langStrings.oftenUsed.createdBy}
                            value={createdByTitle}
                            link={createdByLink}
                            close={resolve}
                        />
                    </tbody>
                </table>
            </div>
        </Dialog>
    );
};



export function getEmployee(employees: ISmallEmployee[], empId: number | undefined, langStrings: { oftenUsed: ILanguageGeneral })
    : [string | JSX.Element, string | undefined, string]
{
    const createdBy = employees.find(e => e.id === empId);

    let title;
    let link;
    let titleText;
    if (empId && createdBy)
    {
        titleText = createdBy.title;
        title = <EmployeeOverlay employeeId={empId}  >
            <div className={styles.link}>
                <Link className={styles.link} to={entityTypeToLink(empId, EEntityType.EMPLOYEE)} onClick={close}>
                    {createdBy.title}
                </Link>
            </div>
        </EmployeeOverlay>;
        // link = (createdBy && entity[field]) ? entityTypeToLink(empId, EEntityType.EMPLOYEE) : undefined;

    } else if (empId && empId > 0)
    {

        title = `${langStrings.oftenUsed.exitedUser} ` + empId
        titleText = title;
    }
    else if (empId === ESystemUser.CLOCKODO)
    {
        title = 'Clockodo-Sync';
        titleText = title;
    }
    else if (empId === ESystemUser.REST)
    {
        title = 'REST-API';
        titleText = title;
    }
    else 
    {
        title = 'System';
        titleText = title;
    }
    return [title, link, titleText];
}
interface RenderTableRowProps
{
    title: string;
    value: JSX.Element | string | undefined;
    link?: string;
    close: () => void
}
function RenderTableRow({ title, value, link, close, }: RenderTableRowProps)
{
    return (
        <tr className={styles.tableRow}>
            <td className={styles.tableCell}>
                <Typography variant='body1'>
                    {title}
                </Typography>
            </td>
            <td className={styles.tableCell}>
                <RenderEmployee
                    value={value}
                    link={link}
                    close={close}
                />
            </td>
        </tr>
    )
}

interface RenderEmployeeProps
{
    value: JSX.Element | string | undefined;
    link?: string;
    close?: () => void
}
export function RenderEmployee({ value, link, close, }: RenderEmployeeProps)
{
    return (
        <Typography variant='body1' component={'span'}>
            {link ?
                <Link className={styles.link} to={link} onClick={close}>
                    {value}
                </Link>
                : value
            }
        </Typography>
    )
}
// export function openVersionInfoDialog(entity: Entity): Promise<void>
// {
//     const modalRoot = document.getElementById('dialogContainer');
//     if (!modalRoot) throw new Error("modal-root not found");

//     const newElement = document.createElement('div');
//     modalRoot.appendChild(newElement);
//     const root: Root = createRoot(newElement);

//     return new Promise<void>((resolve) =>
//     {
//         root.render(
//             <VersionInfoDialog
//                 id='versionInfoDialog'
//                 entity={entity}
//                 resolve={() =>
//                 {
//                     root.unmount();
//                     modalRoot.removeChild(newElement);
//                     resolve();
//                 }}
//             />
//         );
//     });
// }
