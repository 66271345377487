import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Done, Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { setHiddenCompetences } from '@store/reducer/employeeSettingsReducer';
import hideSuggestedCompetence from '@src/APIs/graphQl/Employee/Suggestions/hideSuggestedCompetence';
import unhideSuggestedCompetence from '@src/APIs/graphQl/Employee/Suggestions/unhideSuggestedCompetence';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import Button from '../../sharedReact/General/Button/Button';
import Dialog from '../../sharedReact/Dialog/Dialogs/Dialog/Dialog';

/**
 * Component for displaying a hidden competences dialog.
 * @param {object} onClose - The event handler for closing the dialog.
 * @returns {JSX.Element} - The rendered hidden competences dialog.
 */
export function HiddenCompetencesDialog({ id, resolve }: { id: string, resolve: () => void; }): JSX.Element
{
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const offline = useSelector((state: AppState) => state.client.offline);

    const hiddenCompetencesInitial = useSelector((state: AppState) => state.employeeSettings.competenceSettings.hiddenCompetences);
    const [hiddenCompetences, setHiddenCompetencesState] = React.useState<number[]>(hiddenCompetencesInitial);

    return (
        <Dialog
            id={id}
            footer={<div style={{ float: 'right' }}>
                <Button
                    icon={<Done />}
                    text={langStrings.oftenUsed.close}
                    size="normal"
                    onClick={() => resolve()} />
            </div>}
            title={langStrings.hideShowDialogTitle}
            onClose={resolve}
        >
            <div>
                <table className="activityTable">
                    <thead>
                        <tr>
                            <td>{langStrings.competence}</td>
                            <td>{langStrings.showOrHide}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {allCompetences.map(p => (
                            <tr key={p.id}>
                                <td>{p.title}</td>
                                <td>
                                    <div className="hideProjects">
                                        <IconButton
                                            size="small"
                                            disabled={offline}
                                            onClick={() =>
                                            {
                                                let newHiddenCompetences = hiddenCompetences.concat();
                                                if (hiddenCompetences.includes(p.id))
                                                {
                                                    newHiddenCompetences = newHiddenCompetences.filter(id => id !== p.id);
                                                    unhideSuggestedCompetence(p.id)
                                                        .catch((ex) =>
                                                        {
                                                            showErrorDialog(ex);
                                                            const newArray = [...newHiddenCompetences]
                                                            newArray.push(p.id);
                                                            setHiddenCompetencesState(newArray);
                                                            dispatch(setHiddenCompetences(newArray));

                                                        });
                                                }
                                                else
                                                {
                                                    newHiddenCompetences.push(p.id);
                                                    hideSuggestedCompetence(p.id)
                                                        .catch((ex) =>
                                                        {
                                                            showErrorDialog(ex);
                                                            const newArray = [...newHiddenCompetences].filter(id => id !== p.id);
                                                            setHiddenCompetencesState(newArray);
                                                            dispatch(setHiddenCompetences(newArray));

                                                        });
                                                }
                                                setHiddenCompetencesState(newHiddenCompetences);
                                                dispatch(setHiddenCompetences(newHiddenCompetences));

                                            }}
                                        >
                                            {hiddenCompetences.includes(p.id) ? (
                                                <VisibilityOff fontSize="small" style={{ color: 'var(--var-secondary-color)' }} />
                                            ) : (
                                                <Visibility fontSize="small" style={{ color: 'var(--var-primary-color)' }} />
                                            )}
                                        </IconButton>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Dialog>
    );
}
