import React, { SyntheticEvent } from 'react';
import './OrgUnitDiagram.css';
import Tree, { RawNodeDatum, TreeNodeDatum } from 'react-d3-tree';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import { IOrgUnitDetailDTO } from '@sharedInterfaces/IOrgUnit';
import { ISmallEmployee } from '@sharedInterfaces/IWhoIAm';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IOrgUnitTexts } from '@sharedInterfaces/Language/languageTexts/orgUnit';
import { EEntityType, entityTypeToLink, generateEmployeePictureUrl } from '@sharedInterfaces/globalEnums';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
interface BasicComponentProps
{
    units: IOrgUnitDetailDTO[]

}

function OrgUnitDiagram(props: BasicComponentProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;
    const companyId = useSelector((state: AppState) => state.company.companyId);
    const OUs = props.units;
    const employees = useSelector((state: AppState) => state.company.allEmployees);
    const [translate, setTranslate] = React.useState({ x: 0, y: 0 });
    const treeContainerRef = React.useRef<HTMLDivElement>(null);
    const [height, setHeight] = React.useState(0);
    const [maxNodeWidth, setMaxNodeWidth] = React.useState(50);

    const [showEmployees, setShowEmployees] = React.useState(false);

    const orgChart = generateOrgChart(employees, OUs, langStrings, companyId, showEmployees);

    React.useEffect(() =>
    {
        // Calculate the maximum width of all nodes
        const maxWidth = orgChart ? calculateMaxNodeWidth(orgChart) : 50;
        setMaxNodeWidth(maxWidth);
    }, [orgChart]);

    function calculateMaxNodeWidth(node: RawNodeDatum)
    {
        const maxWidth = calculateNodeSize(node);
        return maxWidth;
    }


    React.useEffect(() =>
    {
        const handleResize = () =>
        {
            if (!treeContainerRef.current) return;
            const containerWidth = treeContainerRef.current.offsetWidth;
            // const containerHeight = treeContainerRef.current.offsetHeight;

            setTranslate({
                x: containerWidth / 2,
                y: 50,
            });
            setHeight(treeContainerRef.current.parentElement?.parentElement ? treeContainerRef.current.parentElement.parentElement.offsetHeight : 0);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () =>
        {
            window.removeEventListener('resize', handleResize);
        };
    }, [treeContainerRef, treeContainerRef.current]);
    return (
        <>
            <FormControlLabel
                label={langStrings.showEmployees}
                control={
                    <Checkbox
                        onClick={() => setShowEmployees(!showEmployees)}
                        checked={showEmployees}
                    />
                } />
            <div
                id="treeWrapper"
                style={{ width: '100%', height: height }}
                ref={treeContainerRef}
            >
                <Tree data={orgChart}
                    orientation='vertical'
                    pathFunc={'step'}
                    zoom={1}
                    collapsible={false}
                    translate={translate}
                    rootNodeClassName="node__root"
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf"
                    nodeSize={{ x: maxNodeWidth, y: 100 }}
                    // nodeSize={{ x: 200, y: 200 }}
                    separation={{ siblings: 1, nonSiblings: 1 }}
                    renderCustomNodeElement={renderNodeWithCustomContent}
                // onNodeClick={(node, evt) =>
                // {
                //     console.log(evt)
                //     console.log(node)
                // }}
                />
            </div>
        </>
    );
}

export default OrgUnitDiagram;



function generateOrgChart(employees: ISmallEmployee[], OUs: IOrgUnitDetailDTO[], langStrings: IOrgUnitTexts, companyId: string, showEmployees: boolean)
{
    let rootOU = OUs.find(o => o.parentOU === undefined || o.parentOU === null) as IOrgUnitDetailDTO;
    if (!rootOU)
    {
        if (OUs.length > 0)
        {
            // console.log("Set fallback ", OUs[0])
            rootOU = OUs[0]
        } else
        {
            return { name: 'Fehler' } as RawNodeDatum;
        }
    }


    return fillSubOUs(rootOU, OUs, employees, langStrings, companyId, showEmployees);


}

function fillSubOUs(ou: IOrgUnitDetailDTO, OUs: IOrgUnitDetailDTO[], employees: ISmallEmployee[], langStrings: IOrgUnitTexts, companyId: string, showEmployees: boolean)
{
    const node = {} as RawNodeDatum;
    const ouEmps = employees.filter(se => ou.employees.find(e => e.id === se.id))

    node.name = ou.title;
    const subOU = OUs.filter(o => o.parentOU === ou.id)
    node.children = subOU.map(o => fillSubOUs(o, OUs, employees, langStrings, companyId, showEmployees))

    if (showEmployees)
    {
        node.children = node.children.concat(
            ouEmps.map(e => ({
                name: e.title,
                attributes: {
                    type: 'employee',
                    id: e.id,
                    pictureUrl: e.pictureId ? generateEmployeePictureUrl(companyId, e, 38) : "",
                    initials: extractInitials(e.title),
                },
            }))
        )
    }

    const leader = employees.find(e => e.id === ou.leader.id);
    node.attributes = {
        type: 'ou',
        id: ou.id,
        Leader: leader ? `${langStrings.leader}: ${leader.title}` : '',
        Employees: ouEmps.length.toString(),
    };
    return node;
}

function extractInitials(title: string): string
{
    const names = title.split(" ");
    if (names.length === 1)
    {
        return names[0].charAt(0); // Nur das erste Zeichen, wenn nur ein Name vorhanden ist
    } else
    {
        return names.map(name => name.charAt(0)).join(""); // Die ersten Buchstaben von Vor- und Nachname kombinieren
    }
}

const renderNodeWithCustomContent = ({ nodeDatum }: { nodeDatum: CustomRawNodeDatum }) =>
{
    const maxTextWidth = calculateNodeSize(nodeDatum);
    const rectHeight = 50;
    const type = nodeDatum.attributes?.type;

    let padding = 0;
    if (type === 'ou')
    {
        padding = 20;
    }

    const rectWidth = maxTextWidth + padding;
    return type === 'ou' ? renderOrgUnit(nodeDatum, rectWidth, rectHeight) : renderEmployee(nodeDatum);
};

const getTextWidth = (text: string, fontSize: number) =>
{
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return 0;
    context.font = `${fontSize}px Roboto`;
    return context.measureText(text).width;
};


const calculateNodeSize = (nodeDatum: RawNodeDatum): number =>
{
    const type = nodeDatum.attributes?.type;
    let nodeWidth = 0;
    if (type === 'ou')
    {
        nodeWidth = Math.max(
            getTextWidth(nodeDatum.name, 16),
            nodeDatum.attributes?.Leader ? getTextWidth(nodeDatum.attributes.Leader as string, 12) : 0,
        )
    }
    else
    {
        nodeWidth = Math.max(
            getTextWidth(nodeDatum.name, 12),
        )
    }
    const biggestChild = (nodeDatum.children || []).reduce((max, child) =>
    {
        const childWidth = calculateNodeSize(child);
        return childWidth > max ? childWidth : max;
    }, 0)
    return Math.max(
        nodeWidth,
        biggestChild,
    );
}


const renderOrgUnit = (nodeDatum: CustomRawNodeDatum, rectWidth: number, rectHeight: number) =>
{
    const rectX = -rectWidth / 2;
    const rectY = -rectHeight / 2;
    const id = nodeDatum.attributes?.id as string;
    const leader = nodeDatum.attributes?.Leader;

    return (
        <g
            onClick={(e) =>
            {
                const link = entityTypeToLink(id, EEntityType.ORGANISATIONAL_UNIT);
                location.href = link;
            }}
        >
            <rect width={rectWidth} height={rectHeight} x={rectX} y={rectY} />
            <text className='title' x={0} y={rectY + 15 + (!leader ? 10 : 0)} textAnchor="middle" alignmentBaseline="middle">
                {nodeDatum.name}
            </text>
            {leader && (
                <text x={0} y={rectY + 37} textAnchor="middle" alignmentBaseline="middle">
                    {leader}
                </text>
            )}
        </g>
    );
};

interface CustomRawNodeDatum extends TreeNodeDatum 
{
    id?: string;
}

const renderEmployee = (nodeDatum: CustomRawNodeDatum) =>
{
    const imageSize = 40;
    const imageX = -imageSize / 2;
    const imageY = -imageSize / 2;
    const imageUrl = nodeDatum.attributes?.pictureUrl as string;
    const initials = nodeDatum.attributes?.initials as string;
    const id = nodeDatum.attributes?.id as string;

    return (
        <g
            onClick={(e) =>
            {
                const link = entityTypeToLink(id, EEntityType.EMPLOYEE);
                location.href = link;
            }}
        >
            {imageUrl !== "" &&
                <>
                    <defs>
                        <clipPath id={`clip-circle-${nodeDatum.id}`}>
                            <circle cx={0} cy={0} r={imageSize / 2} />
                        </clipPath>
                    </defs>
                    <image
                        href={imageUrl}
                        x={imageX}
                        y={imageY}
                        width={imageSize}
                        height={imageSize}
                        clipPath={`url(#clip-circle-${nodeDatum.id})`}
                        preserveAspectRatio="xMidYMid slice"
                    />
                </>}
            {(imageUrl === "" && initials !== "") &&
                <>
                    <circle className='employee' cx={0} cy={0} r={imageSize / 2} />
                    <text
                        x={0}
                        y={0}
                        textAnchor="middle"
                        alignmentBaseline="middle"
                    >
                        {initials}
                    </text>
                </>}
            <circle cx={0} cy={0} r={imageSize / 2} fill="none" stroke="black" />
            <text
                x={0}
                y={30}
                className='employeeText'
                textAnchor="middle"
                alignmentBaseline="middle"
            >
                {nodeDatum.name}
            </text>
        </g>
    );
};
