import { Action } from "redux";
import Project from "@src/Objects/Project";

const initialState: Project[] = [];

export enum EProjectAction
{
    SET_PROJECTS = "SET_PROJECTS",
    DELETE_PROJECT = "DELETE_PROJECT",
    UPDATE_PROJECT = "UPDATE_PROJECT",
}

interface SetProjectsAction extends Action
{
    type: EProjectAction.SET_PROJECTS;
    payload: Project[];
}

interface DeleteProjectAction extends Action
{
    type: EProjectAction.DELETE_PROJECT;
    payload: number; // Assuming the unique identifier is a number
}

interface UpdateProjectAction extends Action
{
    type: EProjectAction.UPDATE_PROJECT;
    payload: Project;
}

type ProjectAction = SetProjectsAction | DeleteProjectAction | UpdateProjectAction;

export const projectReducer = (state = initialState, action: ProjectAction): Project[] =>
{
    switch (action.type)
    {
        case EProjectAction.SET_PROJECTS:
            return action.payload;
        case EProjectAction.DELETE_PROJECT:
            return state.filter(project => project.id !== action.payload);
        case EProjectAction.UPDATE_PROJECT:
            // eslint-disable-next-line no-case-declarations
            const index = state.findIndex(project => project.id === action.payload.id);
            if (index !== -1)
            {
                const newState = [...state];
                newState[index] = action.payload;
                return newState;
            } else
            {
                return [...state, action.payload];
            }
        default:
            return state;
    }
};

// Action Creators
export const setProjects = (projects: Project[]): SetProjectsAction => ({
    type: EProjectAction.SET_PROJECTS,
    payload: projects,
});

export const deleteProject = (id: number): DeleteProjectAction => ({
    type: EProjectAction.DELETE_PROJECT,
    payload: id,
});

export const updateProject = (project: Project): UpdateProjectAction => ({
    type: EProjectAction.UPDATE_PROJECT,
    payload: project,
});
