import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Settings, Delete, Check } from '@mui/icons-material';
import { calculateEmployeeCoverage, calculateLearningProgress } from '@sharedInterfaces/globalHelper';
import { IEmployeeSuggestedCompetence } from '@sharedInterfaces/IEmployee';
import { IEmployeeSettingsCompetenceSettingsWantedCompetence } from '@sharedInterfaces/IEmployeeSettings';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeCoverage } from '@sharedInterfaces/IOpportunity';
import { AppState } from '@store/store';
import { setWantedCompetences } from '@store/reducer/employeeSettingsReducer';
import updateWantedCompetenceState from '@src/APIs/graphQl/Employee/WantedCompetences/updateWantedCompetenceState';
import deleteWantedCompetence from '@src/APIs/graphQl/Employee/WantedCompetences/deleteWantedCompetenceState';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

import CardBox from '../../Components/CardBox/CardBox';
import { EmployeeCoverage } from '../../Components/Opportunities/EmployeeSuggestions/EmployeeCoverage';
import { ConfigureWantedCometenceDialog } from '../../Components/SuggestedCompetences/ConfigureWantedCometenceDialog';
import FormatedTextInput from '../../Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import Button from '../../sharedReact/General/Button/Button';
import RowElement from '../../sharedReact/General/Forms/RowElement/RowElement';
import Row from '../../sharedReact/General/Forms/Row/Row';
import { useConfirmDeleteDialog } from '../../Components/Dialogs/ConfirmDelete/confirmDelete';

import styles from './DevelopmentTab.module.css';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import { fireworkSalvo } from './fireworkSalvo';

/**
 * Function to generate EDPWantedCompetenceCard component.
 *
 * @param wantedCompetence - The wanted competence settings for an employee.
 * @returns The JSX element for EDPWantedCompetenceCard.
 */
export function EDPWantedCompetenceCard({ wantedCompetence }: { wantedCompetence: IEmployeeSettingsCompetenceSettingsWantedCompetence; })
{
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const allCompetence = allCompetences.find(a => a.id === wantedCompetence.id);
    const empSkills = useSelector((state: AppState) => state.employee.skills);
    const empCertificates = useSelector((state: AppState) => state.employee.certificates);
    const competenceLevelSkills = allCompetence && allCompetence.levels[wantedCompetence.level - 1] ?
        allCompetence.levels[wantedCompetence.level - 1].skills :
        [];
    const competenceLevelCertificates = allCompetence && allCompetence.levels[wantedCompetence.level - 1] ?
        allCompetence.levels[wantedCompetence.level - 1].certificates :
        [];
    const [coverage, setCoverage] = useState<IEmployeeCoverage | null>(
        allCompetence ?
            calculateEmployeeCoverage(
                competenceLevelSkills,
                empSkills,
                competenceLevelCertificates,
                empCertificates,
                [],
                [],
            )
            : null
    );
    const [score, setScore] = useState<number>(
        allCompetence && coverage ?
            calculateLearningProgress(
                empSkills,
                wantedCompetence.initialCompareState,
                competenceLevelSkills
            ) :
            0
    );

    useEffect(() =>
    {
        if (!allCompetence) return;
        setCoverage(
            calculateEmployeeCoverage(
                competenceLevelSkills,
                empSkills,
                competenceLevelCertificates,
                empCertificates,
                [],
                [],
            )
        );

    },
        [empSkills.length, empSkills, allCompetence, wantedCompetence, competenceLevelSkills, competenceLevelSkills.length, empCertificates, competenceLevelCertificates, competenceLevelCertificates.length]
    );

    useEffect(() =>
    {
        if (!coverage) return;
        setScore(
            calculateLearningProgress(empSkills, wantedCompetence.initialCompareState,
                competenceLevelSkills)
        );
    },
        [empSkills.length, empSkills, coverage, competenceLevelSkills, competenceLevelSkills.length]
    );

    if (!wantedCompetence || !wantedCompetence.id || wantedCompetence.level === undefined || !allCompetence || !coverage)
        return <CardBox title={"AllCompetence not found"}>
            <p></p>
        </CardBox>;

    return <CompetenceGoalCard
        competence={{
            id: allCompetence.id,
            title: allCompetence.title,
            description: "",
            level: wantedCompetence.level,
            employee: coverage,
        }}
        wantedCompetence={wantedCompetence}
        score={score}
        update={function (): void
        {
            setCoverage(
                calculateEmployeeCoverage(
                    allCompetence.levels[wantedCompetence.level - 1].skills, empSkills,
                    allCompetence.levels[wantedCompetence.level - 1].certificates, empCertificates,
                    [],
                    [],
                )
            );
            setScore(
                calculateLearningProgress(empSkills, wantedCompetence.initialCompareState, competenceLevelSkills)
            );
        }} />;
}
/**
 * Generate a suggested competence element.
 * @param {competence} - The competence object.
 * @returns {JSX.Element} - The suggested competence element.
 */
export function CompetenceGoalCard({ competence, score, wantedCompetence, update }: {
    competence: IEmployeeSuggestedCompetence;
    score: number;
    wantedCompetence: IEmployeeSettingsCompetenceSettingsWantedCompetence;
    update: () => void;
})
{
    const confirmDelete = useConfirmDeleteDialog()
    const { openDialog } = useDialog();
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employeeDevelopment;
    const wantedCompetences = useSelector((state: AppState) => state.employeeSettings.competenceSettings.wantedCompetences);
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const offline = useSelector((state: AppState) => state.client.offline);
    const originalScoreRef = useRef(score);
    const allCompetence = allCompetences.find(a => a.id === competence.id);

    useEffect(() =>
    {
        originalScoreRef.current = score;
    }, [competence.id]);

    // useEffect(() =>
    // {

    //     if (score >= 1 && originalScoreRef.current < 1)
    //     {
    //         // fireworkSalvo();
    //     }
    // }, [score, originalScoreRef]);

    const onConfirm = () =>
    {
        fireworkSalvo();
        updateWantedCompetenceState(competence.id)
            .then(() =>
            {
                const newWantedCompetences = [...wantedCompetences]
                const wantedCompetence = newWantedCompetences.find(wc => wc.id === competence.id);
                if (wantedCompetence)
                {
                    wantedCompetence.state = 'done';
                    dispatch(setWantedCompetences(newWantedCompetences))
                }

            })
            .catch(showErrorDialog)
    }

    const onDelete = () =>
    {
        confirmDelete(`${langStrings.competenceGoal} - ${competence.title}`)
            .then(() =>
            {
                deleteWantedCompetence(competence.id)
                    .then(() =>
                    {
                        dispatch(setWantedCompetences(wantedCompetences.filter(wc => wc.id !== competence.id)))
                    })
                    .catch(showErrorDialog)
            })
    };
    const onConfigure = () =>
    {
        openDialog(
            <ConfigureWantedCometenceDialog
                id={"ConfigureWantedCometenceDialog"}
                competence={competence}
                untilDate={wantedCompetence.untilDate}
                dontFilterLevel={true}
                resolve={() =>
                {
                    update();
                }} />
        )
    };
    if (!allCompetence) return <></>;
    return (
        <CardBox
            title={`${competence.title} - ${allCompetence.levels[competence.level - 1].title}`}
        >
            <div className='competenceGoalCard'>
                <Row >
                    <RowElement alignTitle='center' title={langStrings.oftenUsed.createdAt} >
                        {wantedCompetence.createdAt.toLocaleDateString()}
                    </RowElement>
                    <RowElement alignTitle='center' title={langStrings.untilDate} >
                        {wantedCompetence.untilDate.toLocaleDateString()}
                    </RowElement>
                </Row>
                <RowElement alignTitle='center' title={langStrings.learnProgress}>
                    <LinearProgressWithLabel
                        color={score < 0.95 ? "primary" : "success"}
                        variant="determinate"
                        value={score * 100}
                        sx={{
                            width: "100%",
                            height: 7,
                            borderRadius: 7
                        }} />
                </RowElement>

                {(competence.description && competence.description !== "") ?
                    <FormatedTextInput value={competence.description} readonly={true} /> :
                    undefined}
                <EmployeeCoverage expended suggestedEmployee={competence.employee} hideName hideAvailability />
                <div className={styles.buttons}>
                    <Button
                        helpText={langStrings.oftenUsed.delete}
                        color={'red'}
                        disabled={offline}
                        text={langStrings.oftenUsed.delete}
                        icon={<Delete />}
                        onClick={onDelete} />
                    <Button
                        text={langStrings.oftenUsed.edit}
                        disabled={offline}
                        icon={<Settings />}
                        onClick={onConfigure} />
                    {score >= 1 &&
                        <Button
                            text={langStrings.confirmHideGoal}
                            disabled={offline}
                            icon={<Check />}
                            color={'green'}
                            onClick={onConfirm} />
                    }
                </div>
            </div>
        </CardBox>
    );
}

