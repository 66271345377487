import { ELanguage } from "../languageHelper";

type ILangData = {
    [lang in ELanguage]: {
        de: {
            BW: string;
            BY: string;
            BE: string;
            BB: string;
            HB: string;
            HH: string;
            HE: string;
            MV: string;
            NI: string;
            NW: string;
            RP: string;
            SL: string;
            SN: string;
            ST: string;
            SH: string;
            TH: string;
        };
        gb: {
            ENG: string;
            SCT: string;
            WLS: string;
            NIR: string;
            BMU: string;
            FLK: string;
            GIB: string;
            MSR: string;
            SHN: string;
            SGS: string;
            TCA: string;
            IOT: string;
            VGB: string;
            CYM: string;
            AIA: string;
            ATG: string;
            VCT: string;
            GRD: string;
            KNA: string;
            LCA: string;
        };
        usa: {
            AL: string;
            AK: string;
            AZ: string;
            AR: string;
            CA: string;
            CO: string;
            CT: string;
            DE: string;
            DC: string;  // District of Columbia
            FL: string;
            GA: string;
            HI: string;
            ID: string;
            IL: string;
            IN: string;
            IA: string;
            KS: string;
            KY: string;
            LA: string;
            ME: string;
            MD: string;
            MA: string;
            MI: string;
            MN: string;
            MS: string;
            MO: string;
            MT: string;
            NE: string;
            NV: string;
            NH: string;
            NJ: string;
            NM: string;
            NY: string;
            NC: string;
            ND: string;
            OH: string;
            OK: string;
            OR: string;
            PA: string;
            RI: string;
            SC: string;
            SD: string;
            TN: string;
            TX: string;
            UT: string;
            VT: string;
            VA: string;
            WA: string;
            WV: string;
            WI: string;
            WY: string;
        };
        th: {
            AmnatCharoen: string;
            AngThong: string;
            Bangkok: string;
            BuengKan: string;
            Buriram: string;
            Chachoengsao: string;
            ChaiNat: string;
            Chaiyaphum: string;
            Chanthaburi: string;
            ChiangMai: string;
            ChiangRai: string;
            Chonburi: string;
            Chumphon: string;
            Kalasin: string;
            KamphaengPhet: string;
            Kanchanaburi: string;
            KhonKaen: string;
            Krabi: string;
            Lampang: string;
            Lamphun: string;
            Loei: string;
            Lopburi: string;
            MaeHongSon: string;
            MahaSarakham: string;
            Mukdahan: string;
            NakhonNayok: string;
            NakhonPathom: string;
            NakhonPhanom: string;
            NakhonRatchasima: string;
            NakhonSawan: string;
            NakhonSiThammarat: string;
            Nan: string;
            Narathiwat: string;
            NongBuaLamphu: string;
            NongKhai: string;
            Nonthaburi: string;
            PathumThani: string;
            Pattani: string;
            PhangNga: string;
            Phatthalung: string;
            Phayao: string;
            Phetchabun: string;
            Phetchaburi: string;
            Phichit: string;
            Phitsanulok: string;
            PhraNakhonSiAyutthaya: string;
            Phrae: string;
            Phuket: string;
            Prachinburi: string;
            PrachuapKhiriKhan: string;
            Ranong: string;
            Ratchaburi: string;
            Rayong: string;
            RoiEt: string;
            SaKaeo: string;
            SakonNakhon: string;
            SamutPrakan: string;
            SamutSakhon: string;
            SamutSongkhram: string;
            Saraburi: string;
            Satun: string;
            SingBuri: string;
            Sisaket: string;
            Songkhla: string;
            Sukhothai: string;
            SuphanBuri: string;
            SuratThani: string;
            Surin: string;
            Tak: string;
            Trang: string;
            Trat: string;
            UbonRatchathani: string;
            UdonThani: string;
            UthaiThani: string;
            Uttaradit: string;
            Yala: string;
            Yasothon: string;
        }
        au: {
            ACT: string; // Australian Capital Territory
            NSW: string; // New South Wales
            NT: string;  // Northern Territory
            QLD: string; // Queensland
            SA: string;  // South Australia
            TAS: string; // Tasmania
            VIC: string; // Victoria
            WA: string;  // Western Australia
        },
        ca: {
            AB: string; // Alberta
            BC: string; // British Columbia
            MB: string; // Manitoba
            NB: string; // New Brunswick
            NL: string; // Newfoundland and Labrador
            NS: string; // Nova Scotia
            ON: string; // Ontario
            PE: string; // Prince Edward Island
            QC: string; // Quebec
            SK: string; // Saskatchewan
            NT: string; // Northwest Territories
            NU: string; // Nunavut
            YT: string; // Yukon
        }
        ie: {
            CW: string; // Carlow
            CN: string; // Cavan
            CE: string; // Clare
            CO: string; // Cork
            DL: string; // Donegal
            DN: string; // Dublin
            GA: string; // Galway
            KY: string; // Kerry
            KE: string; // Kildare
            KK: string; // Kilkenny
            LS: string; // Laois
            LM: string; // Leitrim
            LK: string; // Limerick
            LD: string; // Longford
            LH: string; // Louth
            MO: string; // Mayo
            MH: string; // Meath
            MN: string; // Monaghan
            OY: string; // Offaly
            RN: string; // Roscommon
            SO: string; // Sligo
            TA: string; // Tipperary
            WD: string; // Waterford
            WH: string; // Westmeath
            WX: string; // Wexford
            WW: string; // Wicklow
        },
        nz: {
            AUK: string; // Auckland
            BOP: string; // Bay of Plenty
            CAN: string; // Canterbury
            GIS: string; // Gisborne
            HKB: string; // Hawke's Bay
            MBH: string; // Marlborough
            MWT: string; // Manawatu-Wanganui
            NSN: string; // Nelson
            NTL: string; // Northland
            OTA: string; // Otago
            STL: string; // Southland
            TKI: string; // Taranaki
            WGN: string; // Wellington
            WKO: string; // Waikato
            WTC: string; // West Coast
        },
        sg: {
            CE: string; // Central
            NE: string; // North-East
            NW: string; // North-West
            SE: string; // South-East
            SW: string; // South-West
        }
        za: {
            EC: string; // Eastern Cape
            FS: string; // Free State
            GP: string; // Gauteng
            KZN: string; // KwaZulu-Natal
            LP: string; // Limpopo
            MP: string; // Mpumalanga
            NC: string; // Northern Cape
            NW: string; // North West
            WC: string; // Western Cape
        },
        at: {
            B: string; // Burgenland
            K: string; // Kärnten
            NO: string; // Niederösterreich
            OO: string; // Oberösterreich
            S: string; // Salzburg
            ST: string; // Steiermark
            T: string; // Tirol
            V: string; // Vorarlberg
            W: string; // Wien
        }
        ch: {
            AG: string; // Aargau
            AR: string; // Appenzell Ausserrhoden
            AI: string; // Appenzell Innerrhoden
            BL: string; // Basel-Landschaft
            BS: string; // Basel-Stadt
            BE: string; // Bern
            FR: string; // Fribourg
            GE: string; // Geneva
            GL: string; // Glarus
            GR: string; // Graubünden
            JU: string; // Jura
            LU: string; // Lucerne
            NE: string; // Neuchâtel
            NW: string; // Nidwalden
            OW: string; // Obwalden
            SH: string; // Schaffhausen
            SZ: string; // Schwyz
            SO: string; // Solothurn
            SG: string; // St. Gallen
            TG: string; // Thurgau
            TI: string; // Ticino
            UR: string; // Uri
            VS: string; // Valais
            VD: string; // Vaud
            ZG: string; // Zug
            ZH: string; // Zürich
        },
        li: {
            BA: string; // Balzers
            ES: string; // Eschen
            GM: string; // Gamprin
            MA: string; // Mauren
            PL: string; // Planken
            RU: string; // Ruggell
            SC: string; // Schaan
            SL: string; // Schellenberg
            TR: string; // Triesen
            TE: string; // Triesenberg
            VA: string; // Vaduz
        }
        lu: {
            DI: string; // Diekirch
            GR: string; // Grevenmacher
            LU: string; // Luxembourg
        },
        be: {
            BRU: string; // Brussels
            FLA: string; // Flanders
            WAL: string; // Wallonia
        }
    };
};
export const subCountriesTexts: ILangData = {
    'th': {
        'th': {
            'Bangkok': 'กรุงเทพฯ',
            'AmnatCharoen': 'อำนาจเจริญ',
            'AngThong': 'อ่างทอง',
            'BuengKan': 'บึงกาฬ',
            'Buriram': 'บุรีรัมย์',
            'Chachoengsao': 'ฉะเชิงเทรา',
            'ChaiNat': 'ชัยนาท',
            'Chaiyaphum': 'ชัยภูมิ',
            'Chanthaburi': 'จันทบุรี',
            'ChiangMai': 'เชียงใหม่',
            'ChiangRai': 'เชียงราย',
            'Chonburi': 'ชลบุรี',
            'Chumphon': 'ชุมพร',
            'Kalasin': 'กาฬสินธุ์',
            'KamphaengPhet': 'กำแพงเพชร',
            'Kanchanaburi': 'กาญจนบุรี',
            'KhonKaen': 'ขอนแก่น',
            'Krabi': 'กระบี่',
            'Lampang': 'ลำปาง',
            'Lamphun': 'ลำพูน',
            'Loei': 'เลย',
            'Lopburi': 'ลพบุรี',
            'MaeHongSon': 'แม่ฮ่องสอน',
            'MahaSarakham': 'มหาสารคาม',
            'Mukdahan': 'มุกดาหาร',
            'NakhonNayok': 'นครนายก',
            'NakhonPathom': 'นครปฐม',
            'NakhonPhanom': 'นครพนม',
            'NakhonRatchasima': 'นครราชสีมา',
            'NakhonSawan': 'นครสวรรค์',
            'NakhonSiThammarat': 'นครศรีธรรมราช',
            'Nan': 'น่าน',
            'Narathiwat': 'นราธิวาส',
            'NongBuaLamphu': 'หนองบัวลำภู',
            'NongKhai': 'หนองคาย',
            'Nonthaburi': 'นนทบุรี',
            'PathumThani': 'ปทุมธานี',
            'Pattani': 'ปัตตานี',
            'PhangNga': 'พังงา',
            'Phatthalung': 'พัทลุง',
            'Phayao': 'พะเยา',
            'Phetchabun': 'เพชรบูรณ์',
            'Phetchaburi': 'เพชรบุรี',
            'Phichit': 'พิจิตร',
            'Phitsanulok': 'พิษณุโลก',
            'Phrae': 'แพร่',
            'PhraNakhonSiAyutthaya': 'พระนครศรีอยุธยา',
            'Phuket': 'ภูเก็ต',
            'Prachinburi': 'ปราจีนบุรี',
            'PrachuapKhiriKhan': 'ประจวบคีรีขันธ์',
            'Ranong': 'ระนอง',
            'Ratchaburi': 'ราชบุรี',
            'Rayong': 'ระยอง',
            'RoiEt': 'ร้อยเอ็ด',
            'SaKaeo': 'สระแก้ว',
            'SakonNakhon': 'สกลนคร',
            'SamutPrakan': 'สมุทรปราการ',
            'SamutSakhon': 'สมุทรสาคร',
            'SamutSongkhram': 'สมุทรสงคราม',
            'Saraburi': 'สระบุรี',
            'Satun': 'สตูล',
            'SingBuri': 'สิงห์บุรี',
            'Sisaket': 'ศรีสะเกษ',
            'Songkhla': 'สงขลา',
            'Sukhothai': 'สุโขทัย',
            'SuphanBuri': 'สุพรรณบุรี',
            'SuratThani': 'สุราษฎร์ธานี',
            'Surin': 'สุรินทร์',
            'Tak': 'ตาก',
            'Trang': 'ตรัง',
            'Trat': 'ตราด',
            'UbonRatchathani': 'อุบลราชธานี',
            'UdonThani': 'อุดรธานี',
            'UthaiThani': 'อุทัยธานี',
            'Uttaradit': 'อุตรดิตถ์',
            'Yala': 'ยะลา',
            'Yasothon': 'ยโสธร'
        },
        'usa': {
            'AL': 'แอลาบามา',
            'AK': 'อลาสกา',
            'AZ': 'แอริโซนา',
            'AR': 'อาร์คันซอ',
            'CA': 'แคลิฟอร์เนีย',
            'CO': 'โคโลราโด',
            'CT': 'คอนเน็กติคัต',
            'DE': 'เดลาแวร์',
            'DC': 'ดิสทริกต์ โอฟ โคลัมเบีย',
            'FL': 'ฟลอริดา',
            'GA': 'จอร์เจีย',
            'HI': 'ฮาวาย',
            'ID': 'ไอดาโฮ',
            'IL': 'อิลลินอยส์',
            'IN': 'อินเดียนา',
            'IA': 'ไอโอวา',
            'KS': 'แคนซัส',
            'KY': 'เคนตั๊กกี้',
            'LA': 'หลุยเซียนา',
            'ME': 'เมน',
            'MD': 'แมริแลนด์',
            'MA': 'แมสซาชูเซตส์',
            'MI': 'มิชิแกน',
            'MN': 'มินนิโซตา',
            'MS': 'มิซซิสซิปปี้',
            'MO': 'มิสซูรี่',
            'MT': 'มอนแทนา',
            'NE': 'เนบราสก้า',
            'NV': 'เนวาดา',
            'NH': 'นิวแฮมป์เชียร์',
            'NJ': 'นิวเจอร์ซี',
            'NM': 'นิวเม็กซิโก',
            'NY': 'นิวยอร์ก',
            'NC': 'นอร์ทแคโรไลนา',
            'ND': 'นอร์ทดาโคตา',
            'OH': 'โอไฮโอ',
            'OK': 'โอคลาโฮมา',
            'OR': 'ออริกอน',
            'PA': 'เพนซิลเวเนีย',
            'RI': 'โรดไอแลนด์',
            'SC': 'เซาท์แคโรไลนา',
            'SD': 'เซาท์ดาโคตา',
            'TN': 'เทนเนสซี',
            'TX': 'เท็กซัส',
            'UT': 'ยูทาห์',
            'VT': 'เวอร์มอนต์',
            'VA': 'เวอร์จิเนีย',
            'WA': 'วอชิงตัน',
            'WV': 'เวสต์เวอร์จิเนีย',
            'WI': 'วิสคอนซิน',
            'WY': 'ไวโอมิง'
        },
        'za': {
            'EC': 'อีสเทิร์นเคป',
            'FS': 'ฟรีสเตท',
            'GP': 'เกาเต็ง',
            'KZN': 'ควาซูลู-นาทอล',
            'LP': 'ลิมโปโป',
            'MP': 'มปูมาลังกา',
            'NC': 'นอร์เทิร์นเคป',
            'NW': 'นอร์ทเวสต์',
            'WC': 'เวสเทิร์นเคป'
        },
        'au': {
            'ACT': 'ออสเตรเลียนแคปปิตอลเทร์ริทอรี',
            'NSW': 'นิวเซาท์เวลส์',
            'NT': 'นอร์เทิร์นเทร์ริทอรี',
            'QLD': 'ควีนส์แลนด์',
            'SA': 'เซาท์ออสเตรเลีย',
            'TAS': 'แทสเมเนีย',
            'VIC': 'วิคตอเรีย',
            'WA': 'เวสเทิร์นออสเตรเลีย'
        },
        'ca': {
            'AB': 'อัลเบอร์ตา',
            'BC': 'บริติชโคลัมเบีย',
            'MB': 'แมนิโทบา',
            'NB': 'นิวบรันสวิก',
            'NL': 'นิวฟันด์แลนด์และแลบราดอร์',
            'NS': 'โนวาสโคเชีย',
            'ON': 'ออนแทรีโอ',
            'PE': 'เจ้าชายเอ็ดเวิร์ดไอแลนด์',
            'QC': 'แคนาดา',
            'SK': 'ซาสคาท์วัน',
            'NT': 'นอร์ทเวสต์เทร์ริทอรีส์',
            'NU': 'นูนาวุต',
            'YT': 'ยูคอน'
        },
        'ie': {
            'CW': 'คาร์โลว์',
            'CN': 'คาวาน',
            'CE': 'แคลร์',
            'CO': 'คอร์ก',
            'DL': 'ดอนีกอล',
            'DN': 'ดับลิน',
            'GA': 'กอลเวย์',
            'KY': 'เคอร์รี',
            'KE': 'คิลแดร์',
            'KK': 'คิลเคนนี',
            'LS': 'เลาอิส',
            'LM': 'ลีทริม',
            'LK': 'ลิเมริก',
            'LD': 'ลองฟอร์ด',
            'LH': 'เลาท์',
            'MO': 'เมโย',
            'MH': 'มีธ',
            'MN': 'โมนาแฮน',
            'OY': 'ออฟฟาลี',
            'RN': 'รอสคอมมอน',
            'SO': 'สไลโก',
            'TA': 'ทิปเปอรารี',
            'WD': 'วอเตอร์ฟอร์ด',
            'WH': 'เวสต์มีธ',
            'WX': 'เว็กซ์ฟอร์ด',
            'WW': 'วิกโลว์'
        },
        'nz': {
            'AUK': 'โอคแลนด์',
            'BOP': 'เบย์ออฟเพลนตี้',
            'CAN': 'แคนเทอร์บูรี่',
            'GIS': 'จิสบอร์น',
            'HKB': 'ฮอว์กส์เบย์',
            'MBH': 'มาร์ลบอร์',
            'MWT': 'มานาวาตู-วองานุย',
            'NSN': 'เนลสัน',
            'NTL': 'นอร์ทแลนด์',
            'OTA': 'โอทาโก',
            'STL': 'เซาท์แลนด์',
            'TKI': 'ธารานากี',
            'WGN': 'เวลลิงตัน',
            'WKO': 'วายกาโต',
            'WTC': 'เวสต์โคสต์'
        },
        'sg': {
            'CE': 'เซ็นทรัล',
            'NE': 'นอร์ท-อีสต์',
            'NW': 'นอร์ท-เวสต์',
            'SE': 'เซาท์-อีสต์',
            'SW': 'เซาท์-เวสต์'
        },
        'at': {
            'B': 'บูร์เกนลันด์',
            'K': 'แคร์นเทิน',
            'NO': 'นีเดอร์เออสไตร์',
            'OO': 'โอเบอร์เออสไตร์',
            'S': 'ซัลซ์บูร์ก',
            'ST': 'ชไตร์มาร์ค',
            'T': 'ทีโรล',
            'V': 'ฟ็อราร์ล์เบิร์ก',
            'W': 'เวียน'
        },
        'ch': {
            'AG': 'อาร์เกา',
            'AR': 'อัพเพนเซลล์ อัสเซอร์โรเดิน',
            'AI': 'อัพเพนเซลล์ อินเนอร์โรเดิน',
            'BL': 'บาเซิล-ลันด์ชาฟท์',
            'BS': 'บาเซิล-ชตัท',
            'BE': 'เบิร์น',
            'FR': 'ฟริบูร์ก',
            'GE': 'เจนีวา',
            'GL': 'กลารุส',
            'GR': 'เกราบุนเดิน',
            'JU': 'จูรา',
            'LU': 'ลูเซิร์น',
            'NE': 'น็วชาเทล',
            'NW': 'นิดวัลเดิน',
            'OW': 'อ็อบวัลเดิน',
            'SH': 'ชาฟฟ์เฮาเซิน',
            'SZ': 'ชวิทซ์',
            'SO': 'โซโลทูร์น',
            'SG': 'แซงต์ กาลเลน',
            'TG': 'ทูร์เกา',
            'TI': 'เทสสิน',
            'UR': 'อูรี',
            'VS': 'วัลไลส์',
            'VD': 'ว็อด',
            'ZG': 'ซุก',
            'ZH': 'ซูริค'
        },
        'lu': {
            'DI': 'ดิเอคิร์ช',
            'GR': 'เกรฟเฟนมาเคอร์',
            'LU': 'ลักเซมเบิร์ก'
        },
        'be': {
            'BRU': 'บรัสเซลส์',
            'FLA': 'ฟลานเดอร์ส',
            'WAL': 'วาลโลเนีย'
        },
        'li': {
            'BA': 'บัลเซอร์ส',
            'ES': 'เอชเชน',
            'GM': 'แกมปริน',
            'MA': 'เมาเรน',
            'PL': 'พลังเคน',
            'RU': 'รุเกลล์',
            'SC': 'ชาน',
            'SL': 'เชลเลนเบิร์ก',
            'TR': 'ทรีเซน',
            'TE': 'ทรีเซนเบิร์ก',
            'VA': 'วาดุซ'
        },
        'gb': {
            'ENG': 'อังกฤษ',
            'SCT': 'สก็อตแลนด์',
            'WLS': 'เวลส์',
            'NIR': 'นอร์เทิร์นไอร์แลนด์',
            'BMU': 'เบอร์มิวดา',
            'FLK': 'หมู่เกาะฟอล์กแลนด์',
            'GIB': 'ยิบรอลตาร์',
            'MSR': 'มอนต์เซอร์รัต',
            'SHN': 'เซนต์เฮเลนา, แอสเซนชั่น และ ตริสตันดาคูนฮา',
            'SGS': 'เกาะเซาท์จอร์เจียและเกาะแซนด์วิชใต้',
            'TCA': 'หมู่เกาะเติกส์และหมู่เกาะไคคอส',
            'IOT': 'บริติชอินเดียนโอเชียนเทร์ริทอรี',
            'VGB': 'หมู่เกาะเบอร์จินอังกฤษ',
            'CYM': 'หมู่เกาะเคย์แมน',
            'AIA': 'แองกวิลลา',
            'ATG': 'แอนติกาและบาร์บูดา',
            'VCT': 'เซนต์วินเซนต์และเกรนาดีนส์',
            'GRD': 'เกรนาดา',
            'KNA': 'เซนต์คิตส์และนีวิส',
            'LCA': 'เซนต์ลูเซีย'
        },
        'de': {
            'BW': 'บัดเดน-วูร์ทเทมเบิร์ก',
            'BY': 'บาวาเรีย',
            'BE': 'เบอร์ลิน',
            'BB': 'แบรนเดินบูร์ก',
            'HB': 'เบรเมน',
            'HH': 'ฮัมบูร์ก',
            'HE': 'เฮสเซ่น',
            'MV': 'เม็กเลนบูร์ก-ฟอร์พอมเมิร์น',
            'NI': 'แลนด์ใต้',
            'NW': 'นอร์ทไรน์-เวสต์ฟาเลน',
            'RP': 'ไรน์แลนด์-พาลซ',
            'SL': 'ซาร์ลันด์',
            'SN': 'แซ็กซ์โซนี',
            'ST': 'แซ็กซ์โซนี-แอนฮัลท์',
            'SH': 'ชลิสวิก-ฮอลสไตน์',
            'TH': 'ทูริงเจีย'
        }
    },
    'en': {
        'de': {
            'BW': 'Baden-Württemberg',
            'BY': 'Bavaria',
            'BE': 'Berlin',
            'BB': 'Brandenburg',
            'HB': 'Bremen',
            'HH': 'Hamburg',
            'HE': 'Hesse',
            'MV': 'Mecklenburg-Vorpommern',
            'NI': 'Lower Saxony',
            'NW': 'North Rhine-Westphalia',
            'RP': 'Rhineland-Palatinate',
            'SL': 'Saarland',
            'SN': 'Saxony',
            'ST': 'Saxony-Anhalt',
            'SH': 'Schleswig-Holstein',
            'TH': 'Thuringia'
        },
        'lu': {
            'DI': 'Diekirch',
            'GR': 'Grevenmacher',
            'LU': 'Luxembourg'
        },
        'be': {
            'BRU': 'Brussels',
            'FLA': 'Flemish Region',
            'WAL': 'Walloon Region'
        },
        'li': {
            'BA': 'Balzers',
            'ES': 'Eschen',
            'GM': 'Gamprin',
            'MA': 'Mauren',
            'PL': 'Planken',
            'RU': 'Ruggell',
            'SC': 'Schaan',
            'SL': 'Schellenberg',
            'TR': 'Triesen',
            'TE': 'Triesenberg',
            'VA': 'Vaduz',
        },
        'ch': {
            'AG': 'Aargau',
            'AR': 'Appenzell Ausserrhoden',
            'AI': 'Appenzell Innerrhoden',
            'BL': 'Basel-Landschaft',
            'BS': 'Basel-Stadt',
            'BE': 'Bern',
            'FR': 'Fribourg',
            'GE': 'Geneva',
            'GL': 'Glarus',
            'GR': 'Graubünden',
            'JU': 'Jura',
            'LU': 'Lucerne',
            'NE': 'Neuchâtel',
            'NW': 'Nidwalden',
            'OW': 'Obwalden',
            'SG': 'St. Gallen',
            'SH': 'Schaffhausen',
            'SZ': 'Schwyz',
            'SO': 'Solothurn',
            'TG': 'Thurgau',
            'TI': 'Ticino',
            'UR': 'Uri',
            'VS': 'Valais',
            'VD': 'Vaud',
            'ZG': 'Zug',
            'ZH': 'Zurich'
        },
        'at': {
            'B': 'Burgenland',
            'K': 'Carinthia',
            'NO': 'Lower Austria',
            'OO': 'Upper Austria',
            'S': 'Salzburg',
            'ST': 'Styria',
            'T': 'Tyrol',
            'V': 'Vorarlberg',
            'W': 'Vienna'
        },
        'za': {
            'EC': 'Eastern Cape',
            'FS': 'Free State',
            'GP': 'Gauteng',
            'KZN': 'KwaZulu-Natal',
            'LP': 'Limpopo',
            'MP': 'Mpumalanga',
            'NC': 'Northern Cape',
            'NW': 'North West',
            'WC': 'Western Cape'
        },
        'sg': {
            'CE': 'Central Region',
            'NE': 'Northeast Region',
            'NW': 'Northwest Region',
            'SE': 'Southeast Region',
            'SW': 'Southwest Region'
        },
        'nz': {
            'NTL': 'Northland',
            'AUK': 'Auckland',
            'WKO': 'Waikato',
            'BOP': 'Bay of Plenty',
            'GIS': 'Gisborne',
            'HKB': 'Hawke\'s Bay',
            'TKI': 'Taranaki',
            'MWT': 'Manawatū-Whanganui',
            'WGN': 'Wellington',
            'NSN': 'Nelson',
            'MBH': 'Marlborough',
            'WTC': 'West Coast',
            'CAN': 'Canterbury',
            'OTA': 'Otago',
            'STL': 'Southland'
        },
        'ie': {
            'CW': 'Carlow',
            'CN': 'Cavan',
            'CE': 'Clare',
            'CO': 'Cork',
            'DL': 'Donegal',
            'DN': 'Dublin',
            'GA': 'Galway',
            'KY': 'Kerry',
            'KE': 'Kildare',
            'KK': 'Kilkenny',
            'LS': 'Laois',
            'LM': 'Leitrim',
            'LK': 'Limerick',
            'LD': 'Longford',
            'LH': 'Louth',
            'MO': 'Mayo',
            'MH': 'Meath',
            'MN': 'Monaghan',
            'OY': 'Offaly',
            'RN': 'Roscommon',
            'SO': 'Sligo',
            'TA': 'Tipperary',
            'WD': 'Waterford',
            'WH': 'Westmeath',
            'WX': 'Wexford',
            'WW': 'Wicklow'
        },
        'ca': {
            'AB': 'Alberta',
            'BC': 'British Columbia',
            'MB': 'Manitoba',
            'NB': 'New Brunswick',
            'NL': 'Newfoundland and Labrador',
            'NS': 'Nova Scotia',
            'ON': 'Ontario',
            'PE': 'Prince Edward Island',
            'QC': 'Quebec',
            'SK': 'Saskatchewan',
            'NT': 'Northwest Territories',
            'NU': 'Nunavut',
            'YT': 'Yukon'
        },
        'au': {
            'NSW': 'New South Wales',
            'VIC': 'Victoria',
            'QLD': 'Queensland',
            'SA': 'South Australia',
            'WA': 'Western Australia',
            'TAS': 'Tasmania',
            'ACT': 'Australian Capital Territory',
            'NT': 'Northern Territory'
        },
        'th': {
            Bangkok: 'Bangkok',
            'AmnatCharoen': 'Amnat Charoen',
            'AngThong': 'Ang Thong',
            'BuengKan': 'Bueng Kan',
            'Buriram': 'Buriram',
            'Chachoengsao': 'Chachoengsao',
            'ChaiNat': 'Chai Nat',
            'Chaiyaphum': 'Chaiyaphum',
            'Chanthaburi': 'Chanthaburi',
            'ChiangMai': 'Chiang Mai',
            'ChiangRai': 'Chiang Rai',
            'Chonburi': 'Chonburi',
            'Chumphon': 'Chumphon',
            'Kalasin': 'Kalasin',
            'KamphaengPhet': 'Kamphaeng Phet',
            'Kanchanaburi': 'Kanchanaburi',
            'KhonKaen': 'Khon Kaen',
            'Krabi': 'Krabi',
            'Lampang': 'Lampang',
            'Lamphun': 'Lamphun',
            'Loei': 'Loei',
            'Lopburi': 'Lopburi',
            'MaeHongSon': 'Mae Hong Son',
            'MahaSarakham': 'Maha Sarakham',
            'Mukdahan': 'Mukdahan',
            'NakhonNayok': 'Nakhon Nayok',
            'NakhonPathom': 'Nakhon Pathom',
            'NakhonPhanom': 'Nakhon Phanom',
            'NakhonRatchasima': 'Nakhon Ratchasima',
            'NakhonSawan': 'Nakhon Sawan',
            'NakhonSiThammarat': 'Nakhon Si Thammarat',
            'Nan': 'Nan',
            'Narathiwat': 'Narathiwat',
            'NongBuaLamphu': 'Nong Bua Lamphu',
            'NongKhai': 'Nong Khai',
            'Nonthaburi': 'Nonthaburi',
            'PathumThani': 'Pathum Thani',
            'Pattani': 'Pattani',
            'PhangNga': 'Phang Nga',
            'Phatthalung': 'Phatthalung',
            'Phayao': 'Phayao',
            'Phetchabun': 'Phetchabun',
            'Phetchaburi': 'Phetchaburi',
            'Phichit': 'Phichit',
            'Phitsanulok': 'Phitsanulok',
            'Phrae': 'Phrae',
            'PhraNakhonSiAyutthaya': 'Phra Nakhon Si Ayutthaya',
            'Phuket': 'Phuket',
            'Prachinburi': 'Prachinburi',
            'PrachuapKhiriKhan': 'Prachuap Khiri Khan',
            'Ranong': 'Ranong',
            'Ratchaburi': 'Ratchaburi',
            'Rayong': 'Rayong',
            'RoiEt': 'Roi Et',
            'SaKaeo': 'Sa Kaeo',
            'SakonNakhon': 'Sakon Nakhon',
            'SamutPrakan': 'Samut Prakan',
            'SamutSakhon': 'Samut Sakhon',
            'SamutSongkhram': 'Samut Songkhram',
            'Saraburi': 'Saraburi',
            'Satun': 'Satun',
            'SingBuri': 'Sing Buri',
            'Sisaket': 'Sisaket',
            'Songkhla': 'Songkhla',
            'Sukhothai': 'Sukhothai',
            'SuphanBuri': 'Suphan Buri',
            'SuratThani': 'Surat Thani',
            'Surin': 'Surin',
            'Tak': 'Tak',
            'Trang': 'Trang',
            'Trat': 'Trat',
            'UbonRatchathani': 'Ubon Ratchathani',
            'UdonThani': 'Udon Thani',
            'UthaiThani': 'Uthai Thani',
            'Uttaradit': 'Uttaradit',
            'Yala': 'Yala',
            'Yasothon': 'Yasothon'
        },
        'usa': {
            'AL': 'Alabama',
            'AK': 'Alaska',
            'AZ': 'Arizona',
            'AR': 'Arkansas',
            'CA': 'California',
            'CO': 'Colorado',
            'CT': 'Connecticut',
            'DE': 'Delaware',
            'DC': 'District of Columbia',
            'FL': 'Florida',
            'GA': 'Georgia',
            'HI': 'Hawaii',
            'ID': 'Idaho',
            'IL': 'Illinois',
            'IN': 'Indiana',
            'IA': 'Iowa',
            'KS': 'Kansas',
            'KY': 'Kentucky',
            'LA': 'Louisiana',
            'ME': 'Maine',
            'MD': 'Maryland',
            'MA': 'Massachusetts',
            'MI': 'Michigan',
            'MN': 'Minnesota',
            'MS': 'Mississippi',
            'MO': 'Missouri',
            'MT': 'Montana',
            'NE': 'Nebraska',
            'NV': 'Nevada',
            'NH': 'New Hampshire',
            'NJ': 'New Jersey',
            'NM': 'New Mexico',
            'NY': 'New York',
            'NC': 'North Carolina',
            'ND': 'North Dakota',
            'OH': 'Ohio',
            'OK': 'Oklahoma',
            'OR': 'Oregon',
            'PA': 'Pennsylvania',
            'RI': 'Rhode Island',
            'SC': 'South Carolina',
            'SD': 'South Dakota',
            'TN': 'Tennessee',
            'TX': 'Texas',
            'UT': 'Utah',
            'VT': 'Vermont',
            'VA': 'Virginia',
            'WA': 'Washington',
            'WV': 'West Virginia',
            'WI': 'Wisconsin',
            'WY': 'Wyoming',
        },
        'gb': {
            'ENG': 'England',
            'SCT': 'Scotland',
            'WLS': 'Wales',
            'NIR': 'Northern Ireland',
            'BMU': 'Bermuda',
            'FLK': 'Falkland Islands',
            'GIB': 'Gibraltar',
            'MSR': 'Montserrat',
            'SHN': 'St. Helena, Ascension and Tristan da Cunha',
            'SGS': 'South Georgia and the South Sandwich Islands',
            'TCA': 'Turks and Caicos Islands',
            'IOT': 'British Indian Ocean Territory',
            'VGB': 'British Virgin Islands',
            'CYM': 'Cayman Islands',
            'AIA': 'Anguilla',
            'ATG': 'Antigua and Barbuda',
            'VCT': 'St. Vincent and the Grenadines',
            'GRD': 'Grenada',
            'KNA': 'St. Kitts and Nevis',
            'LCA': 'St. Lucia'
        }
    },
    'de': {
        'gb': {
            'ENG': 'England',
            'SCT': 'Schottland',
            'WLS': 'Wales',
            'NIR': 'Nordirland',
            'BMU': 'Bermuda',
            'FLK': 'Falklandinseln',
            'GIB': 'Gibraltar',
            'MSR': 'Montserrat',
            'SHN': 'St. Helena, Ascension und Tristan da Cunha',
            'SGS': 'Südgeorgien und die Südlichen Sandwichinseln',
            'TCA': 'Turks- und Caicosinseln',
            'IOT': 'Britisches Territorium im Indischen Ozean',
            'VGB': 'Britische Jungferninseln',
            'CYM': 'Cayman-Inseln',
            'AIA': 'Anguilla',
            'ATG': 'Antigua und Barbuda',
            'VCT': 'St. Vincent und die Grenadinen',
            'GRD': 'Grenada',
            'KNA': 'St. Kitts und Nevis',
            'LCA': 'St. Lucia'
        },
        'de': {
            'BW': 'Baden-Württemberg',
            'BY': 'Bayern',
            'BE': 'Berlin',
            'BB': 'Brandenburg',
            'HB': 'Bremen',
            'HH': 'Hamburg',
            'HE': 'Hessen',
            'MV': 'Mecklenburg-Vorpommern',
            'NI': 'Niedersachsen',
            'NW': 'Nordrhein-Westfalen',
            'RP': 'Rheinland-Pfalz',
            'SL': 'Saarland',
            'SN': 'Sachsen',
            'ST': 'Sachsen-Anhalt',
            'SH': 'Schleswig-Holstein',
            'TH': 'Thüringen'
        },
        'lu': {
            'DI': 'Diekirch',
            'GR': 'Grevenmacher',
            'LU': 'Luxembourg'
        },
        'be': {
            'BRU': 'Brüssel',
            'FLA': 'Flandern',
            'WAL': 'Wallonien'
        },
        'li': {
            'BA': 'Balzers',
            'ES': 'Eschen',
            'GM': 'Gamprin',
            'MA': 'Mauren',
            'PL': 'Planken',
            'RU': 'Ruggell',
            'SC': 'Schaan',
            'SL': 'Schellenberg',
            'TR': 'Triesen',
            'TE': 'Triesenberg',
            'VA': 'Vaduz'
        },
        'at': {
            'B': 'Burgenland',
            'K': 'Kärnten',
            'NO': 'Niederösterreich',
            'OO': 'Oberösterreich',
            'S': 'Salzburg',
            'ST': 'Steiermark',
            'T': 'Tirol',
            'V': 'Vorarlberg',
            'W': 'Wien'
        },
        'ch': {
            'AG': 'Aargau',
            'AR': 'Appenzell Ausserrhoden',
            'AI': 'Appenzell Innerrhoden',
            'BL': 'Basel-Landschaft',
            'BS': 'Basel-Stadt',
            'BE': 'Bern',
            'FR': 'Freiburg',
            'GE': 'Genf',
            'GL': 'Glarus',
            'GR': 'Graubünden',
            'JU': 'Jura',
            'LU': 'Luzern',
            'NE': 'Neuenburg',
            'NW': 'Nidwalden',
            'OW': 'Obwalden',
            'SH': 'Schaffhausen',
            'SZ': 'Schwyz',
            'SO': 'Solothurn',
            'SG': 'St. Gallen',
            'TG': 'Thurgau',
            'TI': 'Tessin',
            'UR': 'Uri',
            'VS': 'Wallis',
            'VD': 'Waadt',
            'ZG': 'Zug',
            'ZH': 'Zürich'
        },
        'ie': {
            'CW': 'Carlow',
            'CN': 'Cavan',
            'CE': 'Clare',
            'CO': 'Cork',
            'DL': 'Donegal',
            'DN': 'Dublin',
            'GA': 'Galway',
            'KY': 'Kerry',
            'KE': 'Kildare',
            'KK': 'Kilkenny',
            'LS': 'Laois',
            'LM': 'Leitrim',
            'LK': 'Limerick',
            'LD': 'Longford',
            'LH': 'Louth',
            'MO': 'Mayo',
            'MH': 'Meath',
            'MN': 'Monaghan',
            'OY': 'Offaly',
            'RN': 'Roscommon',
            'SO': 'Sligo',
            'TA': 'Tipperary',
            'WD': 'Waterford',
            'WH': 'Westmeath',
            'WX': 'Wexford',
            'WW': 'Wicklow'
        },
        'nz': {
            'AUK': 'Auckland',
            'BOP': 'Bay of Plenty',
            'CAN': 'Canterbury',
            'GIS': 'Gisborne',
            'HKB': 'Hawke’s Bay',
            'MBH': 'Marlborough',
            'MWT': 'Manawatu-Wanganui',
            'NSN': 'Nelson',
            'NTL': 'Northland',
            'OTA': 'Otago',
            'STL': 'Southland',
            'TKI': 'Taranaki',
            'WGN': 'Wellington',
            'WKO': 'Waikato',
            'WTC': 'West Coast'
        },
        'sg': {
            'CE': 'Central',
            'NE': 'North-East',
            'NW': 'North-West',
            'SE': 'South-East',
            'SW': 'South-West'
        },
        'za': {
            'EC': 'Eastern Cape',
            'FS': 'Free State',
            'GP': 'Gauteng',
            'KZN': 'KwaZulu-Natal',
            'LP': 'Limpopo',
            'MP': 'Mpumalanga',
            'NC': 'Northern Cape',
            'NW': 'North West',
            'WC': 'Western Cape'
        },
        'au': {
            'ACT': 'Australian Capital Territory',
            'NSW': 'New South Wales',
            'NT': 'Northern Territory',
            'QLD': 'Queensland',
            'SA': 'South Australia',
            'TAS': 'Tasmania',
            'VIC': 'Victoria',
            'WA': 'Western Australia'
        },
        'ca': {
            AB: 'Alberta',
            BC: 'British Columbia',
            MB: 'Manitoba',
            NB: 'New Brunswick',
            NL: 'Newfoundland and Labrador',
            NS: 'Nova Scotia',
            ON: 'Ontario',
            PE: 'Prince Edward Island',
            QC: 'Quebec',
            SK: 'Saskatchewan',
            NT: 'Northwest Territories',
            NU: 'Nunavut',
            YT: 'Yukon'
        },
        'usa': {
            AL: 'Alabama',
            AK: 'Alaska',
            AZ: 'Arizona',
            AR: 'Arkansas',
            CA: 'Kalifornien',
            CO: 'Colorado',
            CT: 'Connecticut',
            DE: 'Delaware',
            DC: 'District of Columbia',
            FL: 'Florida',
            GA: 'Georgia',
            HI: 'Hawaii',
            ID: 'Idaho',
            IL: 'Illinois',
            IN: 'Indiana',
            IA: 'Iowa',
            KS: 'Kansas',
            KY: 'Kentucky',
            LA: 'Louisiana',
            ME: 'Maine',
            MD: 'Maryland',
            MA: 'Massachusetts',
            MI: 'Michigan',
            MN: 'Minnesota',
            MS: 'Mississippi',
            MO: 'Missouri',
            MT: 'Montana',
            NE: 'Nebraska',
            NV: 'Nevada',
            NH: 'New Hampshire',
            NJ: 'New Jersey',
            NM: 'New Mexico',
            NY: 'New York',
            NC: 'North Carolina',
            ND: 'North Dakota',
            OH: 'Ohio',
            OK: 'Oklahoma',
            OR: 'Oregon',
            PA: 'Pennsylvania',
            RI: 'Rhode Island',
            SC: 'South Carolina',
            SD: 'South Dakota',
            TN: 'Tennessee',
            TX: 'Texas',
            UT: 'Utah',
            VT: 'Vermont',
            VA: 'Virginia',
            WA: 'Washington',
            WV: 'West Virginia',
            WI: 'Wisconsin',
            WY: 'Wyoming'
        },
        'th': {
            Bangkok: 'Bangkok',
            AmnatCharoen: 'Amnat Charoen',
            AngThong: 'Ang Thong',
            BuengKan: 'Bueng Kan',
            Buriram: 'Buriram',
            Chachoengsao: 'Chachoengsao',
            ChaiNat: 'Chainat',
            Chaiyaphum: 'Chaiyaphum',
            Chanthaburi: 'Chanthaburi',
            ChiangMai: 'Chiang Mai',
            ChiangRai: 'Chiang Rai',
            Chonburi: 'Chonburi',
            Chumphon: 'Chumphon',
            Kalasin: 'Kalasin',
            KamphaengPhet: 'Kamphaeng Phet',
            Kanchanaburi: 'Kanchanaburi',
            KhonKaen: 'Khon Kaen',
            Krabi: 'Krabi',
            Lampang: 'Lampang',
            Lamphun: 'Lamphun',
            Loei: 'Loei',
            Lopburi: 'Lopburi',
            MaeHongSon: 'Mae Hong Son',
            MahaSarakham: 'Maha Sarakham',
            Mukdahan: 'Mukdahan',
            NakhonNayok: 'Nakhon Nayok',
            NakhonPathom: 'Nakhon Pathom',
            NakhonPhanom: 'Nakhon Phanom',
            NakhonRatchasima: 'Nakhon Ratchasima',
            NakhonSawan: 'Nakhon Sawan',
            NakhonSiThammarat: 'Nakhon Si Thammarat',
            Nan: 'Nan',
            Narathiwat: 'Narathiwat',
            NongBuaLamphu: 'Nong Bua Lamphu',
            NongKhai: 'Nong Khai',
            Nonthaburi: 'Nonthaburi',
            PathumThani: 'Pathum Thani',
            Pattani: 'Pattani',
            PhangNga: 'Phang Nga',
            Phatthalung: 'Phatthalung',
            Phayao: 'Phayao',
            Phetchabun: 'Phetchabun',
            Phetchaburi: 'Phetchaburi',
            Phichit: 'Phichit',
            Phitsanulok: 'Phitsanulok',
            Phrae: 'Phrae',
            PhraNakhonSiAyutthaya: 'Phra Nakhon Si Ayutthaya',
            Phuket: 'Phuket',
            Prachinburi: 'Prachinburi',
            PrachuapKhiriKhan: 'Prachuap Khiri Khan',
            Ranong: 'Ranong',
            Ratchaburi: 'Ratchaburi',
            Rayong: 'Rayong',
            RoiEt: 'Roi Et',
            SaKaeo: 'Sa Kaeo',
            SakonNakhon: 'Sakon Nakhon',
            SamutPrakan: 'Samut Prakan',
            SamutSakhon: 'Samut Sakhon',
            SamutSongkhram: 'Samut Songkhram',
            Saraburi: 'Saraburi',
            Satun: 'Satun',
            SingBuri: 'Sing Buri',
            Sisaket: 'Sisaket',
            Songkhla: 'Songkhla',
            Sukhothai: 'Sukhothai',
            SuphanBuri: 'Suphan Buri',
            SuratThani: 'Surat Thani',
            Surin: 'Surin',
            Tak: 'Tak',
            Trang: 'Trang',
            Trat: 'Trat',
            UbonRatchathani: 'Ubon Ratchathani',
            UdonThani: 'Udon Thani',
            UthaiThani: 'Uthai Thani',
            Uttaradit: 'Uttaradit',
            Yala: 'Yala',
            Yasothon: 'Yasothon'
        },
    }
};