import { OperationVariables, gql } from "@apollo/client";
import { ETimeSyncStartType } from "@sharedInterfaces/ICompanySettings";

import GraphQL from "../graphQL";


const query = gql`
mutation startTimeSync($syncType: String) {
    startTimeSync(syncType: $syncType) 
},
`;

export default async function startTimeSync(syncType?: ETimeSyncStartType)
{
    const variables: OperationVariables = {
        syncType
    };
    return GraphQL.mutate<boolean>(query, variables)
}