import { Action } from "redux";
import { ILevel } from "@sharedInterfaces/ICompanySettings";

const initialState: ILevel[] = [];

export enum ELevelDefinitionAction
{
    SET_LEVEL_DEFINITION = "SET_LEVEL_DEFINITION",
}

interface LevelDefinitionAction extends Action
{
    type: ELevelDefinitionAction.SET_LEVEL_DEFINITION;
    payload: ILevel[];
}

export const levelDefinitionReducer = (state = initialState, action: LevelDefinitionAction): ILevel[] =>
{
    switch (action.type)
    {
        case ELevelDefinitionAction.SET_LEVEL_DEFINITION:
            return action.payload;
        default:
            return state;
    }
};

// Action Creator
export const setLevelDefinition = (levels: ILevel[]): LevelDefinitionAction => ({
    type: ELevelDefinitionAction.SET_LEVEL_DEFINITION,
    payload: levels,
});