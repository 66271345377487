import { Add, Delete, Done } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICompanyUser } from '@sharedInterfaces/ICompanySettings';
import { IPermissionGroup, defaultEntitiesPermissions } from '@sharedInterfaces/IPermissions';
import { EGender } from '@sharedInterfaces/IEmployee';
import { generateFullName } from '@sharedInterfaces/globalHelper';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import { OU } from '@src/Objects/Company';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import PermissionGroupSelect from '../../formsControls/inputs/PermissionGroupSelect/PermissionGroupSelect';
import GenderSelect from '../../formsControls/inputs/GenderSelect/GenderSelect';
import ErrorBox from '../../ErrorBox/ErrorBox';

interface AddEmployeesDialogProps
{
    id: string
    ou: OU;
    permissionGroups: IPermissionGroup[];
    resolve: () => void;
    onSave: (val: ICompanyUser[]) => Promise<unknown>;
}
/**
 * Represents a dialog for adding employees.
 *
 * @param {AddEmployeesDialogProps} props - The properties for the AddEmployeesDialog.
 *
 * @returns {JSX.Element} - The JSX element representing the dialog for adding employees.
 */
export function AddEmployeesDialog(props: AddEmployeesDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).userDialog;
    const [employees, setEmployees] = React.useState([] as ICompanyUser[]);
    const [saving, setSaving] = React.useState(false as boolean);
    const defaultNewEmp: ICompanyUser = {
        firstname: '',
        lastname: '',
        email: '',
        gender: EGender.OTHER,
        permissionGroup: {
            id: -1,
            title: ''
        },
        permissions: {
            admin: false,
            entities: defaultEntitiesPermissions,
        }
    } as ICompanyUser;
    const [newEmployee, setNewEmployee] = React.useState(defaultNewEmp);
    const [error, setError] = React.useState("");

    return <Dialog
        id={props.id}
        title={langStrings.inviteEmployeesTo + props.ou.title}
        footer={<div style={{ float: 'right' }}>
            <Button
                icon={<Done />}
                disabled={saving || employees.length === 0}
                text={langStrings.invite}
                size={'normal'}
                onClick={async function (): Promise<void>
                {
                    if (employees.length === 0)
                    {
                        return props.resolve();
                    }
                    setSaving(true);
                    await props.onSave(employees)
                        .catch(ex =>
                        {
                            setError(ex.toString());
                            setTimeout(() =>
                            {
                                setSaving(false);
                            }, 3000);

                        });
                }} />
        </div>}
        onClose={function (): void
        {
            props.resolve();
        }}>
        <div className="addUserDialog">
            <h3>{langStrings.addDescription}</h3>

            <div className="unsavedUsers">
                {employees.map(e => <div className="unsavedUser" key={e.email}>
                    <div className="headLine">
                        <div className="emailNewEmployee">
                            <RowElement title={langStrings.name} alignTitle="left">
                                <div className="employeeName">{generateFullName(e.firstname, e.lastname)}</div>
                            </RowElement>
                        </div>
                        <div className="removeUser" onClick={() =>
                        {
                            setEmployees(
                                employees.filter(eo => eo.email !== e.email)
                            );
                        }}><Delete /></div>
                    </div>
                    <RowElement title="E-Mail" alignTitle="left">
                        {e.email}
                    </RowElement>
                </div>)}
            </div>

            <div className="newUserRow">
                <RowElement title={langStrings.firstname} alignTitle="left">
                    <TextInput
                        value={newEmployee.firstname}
                        size='small'
                        onChange={(value) =>
                        {
                            setNewEmployee({
                                ...newEmployee,
                                firstname: value
                            });
                        }} />
                </RowElement>
                <RowElement title={langStrings.lastname} alignTitle="left">
                    <TextInput
                        value={newEmployee.lastname}
                        size='small'
                        onChange={(value) =>
                        {
                            setNewEmployee({
                                ...newEmployee,
                                lastname: value
                            });
                        }} />
                </RowElement>
                <RowElement title="E-Mail" alignTitle="left">
                    <TextInput
                        value={newEmployee.email}
                        size='small'
                        onChange={(value) =>
                        {
                            setNewEmployee({
                                ...newEmployee,
                                email: value
                            });
                        }} />
                </RowElement>
                <RowElement title={langStrings.gender} alignTitle="left">
                    <GenderSelect value={newEmployee.gender} onChange={(value) => setNewEmployee({
                        ...newEmployee,
                        gender: value
                    })} />
                </RowElement>
                <RowElement title={langStrings.permissionGroup} alignTitle="left">
                    <PermissionGroupSelect
                        permissionGroups={props.permissionGroups}
                        selectedPermissionGroup={newEmployee.permissionGroup ? newEmployee.permissionGroup.id : -1}
                        setSelectedPermissionGroup={(pg) => setNewEmployee({
                            ...newEmployee,
                            permissionGroup: { id: pg.id, title: pg.title },
                            permissions: { ...newEmployee.permissions, entities: pg.entities }
                        })} />
                </RowElement>
                <RowElement title={langStrings.admin} alignTitle="left">
                    <Checkbox checked={newEmployee.permissions.admin} onChange={(e) => setNewEmployee({
                        ...newEmployee,
                        permissions: { ...newEmployee.permissions, admin: e.target.checked }
                    })} />
                </RowElement>
                {error !== "" && <ErrorBox  >{error}</ErrorBox>}

                <Button
                    size="verysmall"
                    disabled={false}
                    onClick={() =>
                    {
                        if (newEmployee.firstname === '')
                        {
                            setError(langStrings.pleaseEnterFirstName);
                            return;
                        } else if (newEmployee.lastname === '')
                        {
                            setError(langStrings.pleaseEnterLastName);
                            return;
                        } else if (newEmployee.email.indexOf('@') === -1 ||
                            newEmployee.email.lastIndexOf('.') < newEmployee.email.lastIndexOf('@'))
                        {
                            setError(langStrings.pleaseEnterVaildEmail);
                            return;
                        } else if (employees.map(e => e.email).includes(newEmployee.email))
                        {
                            setError(langStrings.canNotAddEmailTwice);
                            return;
                        }
                        else if (newEmployee.permissionGroup.id === -1)
                        {
                            setError(langStrings.pleaseSelectPermissionGroup);
                            return;
                        }
                        else
                        {
                            const newEmployees = employees.slice();
                            newEmployees.push(newEmployee);
                            setEmployees(newEmployees);
                            setNewEmployee(defaultNewEmp);
                            if (error) setError("");
                        }
                    }}
                    icon={<Add />}
                    text={langStrings.invite} />
            </div>
        </div>
    </Dialog>;
}
