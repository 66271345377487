import { combineReducers } from 'redux';
import { Middleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import IRoleDTO from '@sharedInterfaces/IRole';
import IBusinessDTO from '@sharedInterfaces/IBusiness';
import IOpportunity from '@sharedInterfaces/IOpportunity';
import { IOrgUnitDetailDTO } from '@sharedInterfaces/IOrgUnit';
import { IEmployeeDTO } from '@sharedInterfaces/IEmployee';
import IProduct from '@sharedInterfaces/IProduct';
import { ISkillCategoryDetailDTO } from '@sharedInterfaces/ISkillCategory';
import ICompetence from '@sharedInterfaces/ICompetence';
import ICertificateDTO from '@sharedInterfaces/ICertificate';
import IProjectDTO from '@sharedInterfaces/IProject';
import ISkillDetailDTO from '@sharedInterfaces/ISkill';
import { IPermissions } from '@sharedInterfaces/IPermissions';
import { ILevel, IOU } from '@sharedInterfaces/ICompanySettings';
import SkillDetail from '@src/Objects/SkillDetail';
import Project from '@src/Objects/Project';
import Certificate from '@src/Objects/Certificate';
import Competence from '@src/Objects/Competence';
import { SkillCategory } from '@src/Objects/SkillCategory';
import { createSkillCategory } from '@src/Objects/factory';
import Product from '@src/Objects/Product';
import Employee from '@src/Objects/Employee';
import Opportunity from '@src/Objects/Opportunity';
import Business from '@src/Objects/Business';
import Role from '@src/Objects/Role';

import IUser from '../interfaces/IUser';

import dialogsReducer, { DialogsState } from './reducer/dialogsSlice';
import { companyReducer, CompanyState, ESetCompanyAction, initialCompanyState } from './reducer/companyReducer';
import { EmployeeActionTypes, employeeReducer, EmployeeState, initialEmployeeState } from './reducer/employeeReducer';
import { ELevelDefinitionAction, levelDefinitionReducer } from './reducer/levelDefinitionReducer';
import { EOUAction, OUsReducer } from './reducer/OUsReducer';
import { initialUserState, UserActionTypes, userReducer } from './reducer/userReducer';
import { EmployeeSettingsActionTypes, employeeSettingsReducer, employeeSettingState, initialEmployeeSettingsState, prepareGoal } from './reducer/employeeSettingsReducer';
import { initialPermissionsState, PermissionsActionTypes, permissionsReducer } from './reducer/permissionsReducer';
import { ESkillAction, skillReducer } from './reducer/skillReducer';
import { clientReducer, EClientAction, IClientState, initialClientState } from './reducer/clientReducer';
import { EProjectAction, projectReducer } from './reducer/projectReducer';
import { certificateReducer, ECertificateAction } from './reducer/certificatesReducer';
import { ESkillCategorieAction, skillcategorieReducer } from './reducer/skillCategoriesReducer';
import { competenceReducer, ECompetenceAction } from './reducer/competenceReducer';
import { EProductAction, productReducer } from './reducer/productReducer';
import { EEmployeesAction, employeesReducer } from './reducer/employeesReducer';
import { EOrgUnitAction, orgunitReducer } from './reducer/orgUnitReducer';
import { EOpportunityAction, opportunityReducer } from './reducer/opportunityRecuder';
import { businessReducer, EBusinessAction } from './reducer/businessReducer';
import { ERoleAction, roleReducer } from './reducer/rolesReducer';
import { cookieSettingsReducer, ECookieSettingsAction, ICookieSettingsState, initialCookieSettingsState } from './reducer/cookieReducer';
import { windowSizeReducer, WindowSizeState } from './reducer/windowSizeReducer';
import { notificationsReducer, NotificationsState, initialNotificationsState, isNotificationAction } from './reducer/notificationsReducer';

export interface AppState
{
    cookies: ICookieSettingsState
    company: CompanyState;
    employee: EmployeeState;
    levelDefinition: ILevel[];
    OUs: IOU[];
    employeeSettings: employeeSettingState;
    user: IUser;
    windowSize: WindowSizeState;
    permissions: IPermissions;
    skills: SkillDetail[]
    skillCategories: SkillCategory[]
    certificates: Certificate[]
    roles: Role[]
    competences: Competence[]
    products: Product[]
    employees: Employee[]
    orgUnits: IOrgUnitDetailDTO[]
    opportunity: Opportunity[]
    businesses: Business[]
    projects: Project[]
    client: IClientState
    dialogs: DialogsState
    notifications: NotificationsState
}

const rootReducer = combineReducers({
    cookies: cookieSettingsReducer,
    company: companyReducer,
    employee: employeeReducer,
    levelDefinition: levelDefinitionReducer,
    OUs: OUsReducer,
    employeeSettings: employeeSettingsReducer,
    user: userReducer,
    windowSize: windowSizeReducer,
    permissions: permissionsReducer,
    skills: skillReducer,
    skillCategories: skillcategorieReducer,
    certificates: certificateReducer,
    roles: roleReducer,
    competences: competenceReducer,
    products: productReducer,
    employees: employeesReducer,
    orgUnits: orgunitReducer,
    opportunity: opportunityReducer,
    businesses: businessReducer,
    projects: projectReducer,
    client: clientReducer,
    dialogs: dialogsReducer,
    notifications: notificationsReducer,
});

const localStorageMiddleware: Middleware<AppState, AppState> = store => next => action =>
{
    const result = next(action);
    const state = store.getState();
    const save = async () =>
    {
        if (action.type === ESetCompanyAction.SET_COMPANY_ID)
            localStorage.setItem('companyId', state.company.companyId);
        else if (action.type === ESetCompanyAction.SET_COMPANY_NAME)
            localStorage.setItem('companyName', state.company.name);
        else if (action.type === ESetCompanyAction.SET_COMPANY_ALL_SKILLS)
            localStorage.setItem('allSkills', JSON.stringify(state.company.allSkills));
        else if (action.type === ESetCompanyAction.SET_COMPANY_ALL_COMPETENCES)
            localStorage.setItem('allCompetences', JSON.stringify(state.company.allCompetences));
        else if (action.type === ESetCompanyAction.SET_COMPANY_ALL_CERTIFICATES)
            localStorage.setItem('allCertificates', JSON.stringify(state.company.allCertificates));
        else if (action.type === ESetCompanyAction.SET_COMPANY_SMALL_ROLES)
            localStorage.setItem('smallRoles', JSON.stringify(state.company.allRoles));
        else if (action.type === ESetCompanyAction.SET_COMPANY_SKILL_CATEGORIES)
            localStorage.setItem('skillCategories', JSON.stringify(state.company.skillCategories));
        else if (action.type === ESetCompanyAction.SET_COMPANY_ALL_EMPLOYEES)
            localStorage.setItem('allEmployees', JSON.stringify(state.company.allEmployees));
        else if (action.type === ESetCompanyAction.SET_COMPANY_STYLE)
            localStorage.setItem('style', JSON.stringify(state.company.style));
        else if (action.type === ESetCompanyAction.SET_COMPANY_OPPORTUNITY_SETTINGS)
            localStorage.setItem('opportunitySettings', JSON.stringify(state.company.opportunitySettings));
        else if (action.type === ESetCompanyAction.SET_COMPANY_VERSIONS)
            localStorage.setItem('versions', JSON.stringify(state.company.versions));
        else if (action.type === ESetCompanyAction.SET_COMPANY_VERSION)
            localStorage.setItem('versions', JSON.stringify(state.company.versions));
        else if (action.type in EmployeeActionTypes)
            localStorage.setItem('employee', JSON.stringify(state.employee));
        else if (action.type in ELevelDefinitionAction)
            localStorage.setItem('levelDefinitions', JSON.stringify(state.levelDefinition));
        else if (action.type in EOUAction)
            localStorage.setItem('OUs', JSON.stringify(state.OUs));
        else if (action.type in EmployeeSettingsActionTypes)
            localStorage.setItem('employeeSettings', JSON.stringify(state.employeeSettings));
        else if (action.type in UserActionTypes)
            localStorage.setItem('user', JSON.stringify(state.user));
        else if (action.type in PermissionsActionTypes)
            localStorage.setItem('permissions', JSON.stringify(state.permissions));
        else if (action.type in ESkillAction)
            localStorage.setItem('skills', JSON.stringify(state.skills));
        else if (action.type in ESkillCategorieAction)
            localStorage.setItem('skillCategories', JSON.stringify(state.skillCategories));
        else if (action.type in ECertificateAction)
            localStorage.setItem('certificates', JSON.stringify(state.certificates));
        else if (action.type in ERoleAction)
            localStorage.setItem('roles', JSON.stringify(state.roles));
        else if (action.type in ECompetenceAction)
            localStorage.setItem('competences', JSON.stringify(state.competences));
        else if (action.type in EProductAction)
            localStorage.setItem('products', JSON.stringify(state.products));
        else if (action.type in EEmployeesAction)
            localStorage.setItem('employees', JSON.stringify(state.employees));
        else if (action.type in EOrgUnitAction)
            localStorage.setItem('orgUnits', JSON.stringify(state.orgUnits));
        else if (action.type in EOpportunityAction)
            localStorage.setItem('opportunity', JSON.stringify(state.opportunity));
        else if (action.type in EBusinessAction)
            localStorage.setItem('businesses', JSON.stringify(state.businesses));
        else if (action.type in EProjectAction)
            localStorage.setItem('projects', JSON.stringify(state.projects));
        else if (action.type in EClientAction)
            localStorage.setItem('client', JSON.stringify(state.client));
        else if (action.type in ECookieSettingsAction)
            localStorage.setItem('cookies', JSON.stringify(state.cookies));
        else if (isNotificationAction(action.type))
            localStorage.setItem('notifications', JSON.stringify(state.notifications));
    }
    save();
    return result;
};

const loadState = (): Partial<AppState> =>
{
    try
    {
        const empSettings = JSON.parse(localStorage.getItem('employeeSettings') || JSON.stringify(initialEmployeeSettingsState)) as employeeSettingState;

        return {
            company: {
                companyId: localStorage.getItem('companyId') || initialCompanyState.companyId,
                name: localStorage.getItem('companyName') || initialCompanyState.name,
                allCompetences: JSON.parse(localStorage.getItem('allCompetences') || JSON.stringify(initialCompanyState.allCompetences)),
                allSkills: JSON.parse(localStorage.getItem('allSkills') || JSON.stringify(initialCompanyState.allSkills)),
                allCertificates: JSON.parse(localStorage.getItem('allCertificates') || JSON.stringify(initialCompanyState.allCertificates)),
                allRoles: JSON.parse(localStorage.getItem('smallRoles') || JSON.stringify(initialCompanyState.allRoles)),
                skillCategories: JSON.parse(localStorage.getItem('skillCategories') || JSON.stringify(initialCompanyState.skillCategories)),
                allEmployees: JSON.parse(localStorage.getItem('allEmployees') || JSON.stringify(initialCompanyState.allEmployees)),
                style: JSON.parse(localStorage.getItem('style') || JSON.stringify(initialCompanyState.style)),
                opportunitySettings: JSON.parse(localStorage.getItem('opportunitySettings') || JSON.stringify(initialCompanyState.opportunitySettings)),
                versions: JSON.parse(localStorage.getItem('versions') || JSON.stringify(initialCompanyState.versions)),
            },
            employee: JSON.parse(localStorage.getItem('employee') || JSON.stringify(initialEmployeeState)),
            levelDefinition: JSON.parse(localStorage.getItem('levelDefinitions') || '[]'),
            OUs: JSON.parse(localStorage.getItem('OUs') || '[]'),
            employeeSettings: {
                ...empSettings,
                goals: prepareGoal(empSettings.goals),
                competenceSettings: {
                    ...empSettings.competenceSettings,
                    wantedCompetences: prepareGoal(empSettings.competenceSettings.wantedCompetences),
                }
            },
            user: JSON.parse(localStorage.getItem('user') || JSON.stringify(initialUserState)),
            permissions: JSON.parse(localStorage.getItem('permissions') || JSON.stringify(initialPermissionsState)),
            skills: (JSON.parse(localStorage.getItem('skills') || JSON.stringify([])) as ISkillDetailDTO[]).map(s => new SkillDetail(s)),
            skillCategories: (JSON.parse(localStorage.getItem('skillCategories') || JSON.stringify([])) as ISkillCategoryDetailDTO[]).map(s => createSkillCategory(s)),
            certificates: (JSON.parse(localStorage.getItem('certificates') || JSON.stringify([])) as ICertificateDTO[]).map(s => new Certificate(s)),
            roles: (JSON.parse(localStorage.getItem('roles') || JSON.stringify([])) as IRoleDTO[]).map(s => new Role(s)),
            competences: (JSON.parse(localStorage.getItem('competences') || JSON.stringify([])) as ICompetence[]).map(s => new Competence(s)),
            products: (JSON.parse(localStorage.getItem('products') || JSON.stringify([])) as IProduct[]).map(s => new Product(s)),
            employees: (JSON.parse(localStorage.getItem('employees') || JSON.stringify([])) as IEmployeeDTO[]).map(s => new Employee(s)),
            orgUnits: (JSON.parse(localStorage.getItem('orgUnits') || JSON.stringify([])) as IOrgUnitDetailDTO[]),
            opportunity: (JSON.parse(localStorage.getItem('opportunity') || JSON.stringify([])) as IOpportunity[]).map(s => new Opportunity(s)),
            businesses: (JSON.parse(localStorage.getItem('businesses') || JSON.stringify([])) as IBusinessDTO[]).map(s => new Business(s)),
            projects: (JSON.parse(localStorage.getItem('projects') || JSON.stringify([])) as IProjectDTO[]).map(s => new Project(s)),
            client: {
                ...JSON.parse(localStorage.getItem('client') || JSON.stringify(initialClientState)),
                offline: false,
            },
            cookies: (JSON.parse(localStorage.getItem('cookies') || JSON.stringify(initialCookieSettingsState))),
            dialogs: { dialogs: [] },
            notifications: JSON.parse(localStorage.getItem('notifications') || JSON.stringify(initialNotificationsState)),
        };
    } catch (ex)
    {
        console.error(ex);
        const client = localStorage.getItem("client");
        const cookies = localStorage.getItem("cookies");
        localStorage.clear();
        if (client)
        {
            localStorage.setItem("client", client);
        }
        localStorage.setItem("cookies", cookies || JSON.stringify(initialCookieSettingsState));
        location.reload();
    }
    return {}
};

const store = configureStore({
    reducer: rootReducer,
    middleware:
        (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false
            })
                .concat(localStorageMiddleware),
    preloadedState: loadState(),
});
export default store;