import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";

const query = gql`
query {
    loadEmailMailboxes 
},
`;

export default async function loadEmailMailboxes()
{
    const variables: OperationVariables = {
    };
    return GraphQL.query<{ loadEmailMailboxes: string[] }>(query, variables)
        .then(c => c.loadEmailMailboxes);
}