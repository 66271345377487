import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Radio, Paper, FormControlLabel, RadioGroup } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Company from '@src/Objects/Company';
import { ETimeSyncOption, ICompanyTimeSyncStatis, TTimeSyncOption } from '@sharedInterfaces/ICompanySettings';
import setTimeSync from '@src/APIs/graphQl/Company/setTimeSync';

import RowElement from '../../sharedReact/General/Forms/RowElement/RowElement';

import ClockodoAccordion from './ClockodoAccordion';
import { ZepAccordion } from './ZepAccordion';

interface TimeSyncTabProps
{
    clockdoEmail: string;
    clockodoStatis: ICompanyTimeSyncStatis
    zepStatis: ICompanyTimeSyncStatis
    timeSync: ETimeSyncOption;
    zepInstance: string;
    setCompany: (company: Company) => void;
    reload: () => void;
}
/**
 * Component for the Synchronization Tab.
 *
 * @param {TimeSyncTabProps} clockodoSync - The clockodoSync parameter.
 * @param {function} setCompany - The setCompany parameter.
 * @returns {JSX.Element} The JSX element.
 */
export function TimeSyncTab({ clockdoEmail, clockodoStatis, zepStatis, timeSync, zepInstance, setCompany, reload }: TimeSyncTabProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [syncOption, setSyncOption] = useState<TTimeSyncOption>(timeSync ? timeSync : ETimeSyncOption.NONE);

    useEffect(() =>
    {
        setSyncOption(timeSync);
    }, [timeSync]);

    return (
        <div>
            <Paper elevation={1} sx={{ padding: 2, marginBottom: 2 }}>
                <RowElement alignTitle='left' title={langStrings.timeSync}>
                    <RadioGroup
                        row
                        value={syncOption ? syncOption : ETimeSyncOption.NONE}
                        onChange={(event) =>
                        {
                            const value = event.currentTarget.value;
                            const newValue: TTimeSyncOption = value as TTimeSyncOption;
                            setSyncOption(newValue);
                            setTimeSync(newValue)
                                .then(() => setCompany);
                        }}
                    >
                        <FormControlLabel value={ETimeSyncOption.NONE} control={<Radio />} label={langStrings.oftenUsed.deactivated} />
                        <FormControlLabel value={ETimeSyncOption.CLOCKODO} control={<Radio />} label={langStrings.clockodo} />
                        <FormControlLabel value={ETimeSyncOption.ZEP} control={<Radio />} label="ZEP" />
                    </RadioGroup>
                </RowElement>
            </Paper>
            <ClockodoAccordion
                active={syncOption === ETimeSyncOption.CLOCKODO}
                statis={clockodoStatis}
                email={clockdoEmail}
                setCompany={setCompany}
                reload={reload}
            />
            <ZepAccordion
                active={syncOption === ETimeSyncOption.ZEP}
                statis={zepStatis}
                zepInstance={zepInstance}
                setCompany={setCompany}
                reload={reload}
            />
        </div>
    );
}
