import React from 'react';
import { NavLink } from 'react-router-dom';
import './SkillCategorySelect.css';
import { useSelector } from 'react-redux';
import { ISkillCategory } from '@sharedInterfaces/ISkillCategory';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';

interface SkillCategorySelectProps
{
    skillCategories: ISkillCategory[]
    selectedCategory?: number
    readonly?: boolean
    hideBranche?: number;
    onChange?: (id: number) => void
}

/**
 * SkillCategorySelect component.
 *
 * @param {SkillCategorySelectProps} props - The props object for the SkillCategorySelect component.
 * @returns {JSX.Element} - The JSX element representing the SkillCategorySelect component.
 */
function SkillCategorySelect(props: SkillCategorySelectProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skillCategories;

    const availableSkillCategories =
        [
            { id: -1, title: langStrings.mainLevel },
            ...filterCategories(props.skillCategories, props.hideBranche)
        ]
    if (props.readonly)
    {
        if (!props.selectedCategory) return null
        const selectedCategory = availableSkillCategories.find(s => s.id === props.selectedCategory)
        if (!selectedCategory) return null;
        return <NavLink to={entityTypeToLink(props.selectedCategory, EEntityType.SKILL_CATEGORY)}>
            {selectedCategory.title}</NavLink>
    }
    availableSkillCategories.sort((a, b) =>
    {
        if (a.id === -1) return -1;
        if (b.id === -1) return 1;
        return a.title.localeCompare(b.title);
    })
    return (
        <div className="skillCategorySelect">
            <select
                value={props.selectedCategory}
                onChange={(e) =>
                {
                    const ouId = parseInt(e.target.value);
                    props.onChange && props.onChange(ouId)
                }}
            >
                {/* <option value={-1}></option> */}
                {availableSkillCategories.map(sc => <option
                    key={sc.id}
                    value={sc.id}
                >
                    {sc.title}
                </option>)}
            </select>
        </div>
    );
}

export default SkillCategorySelect;


/**
 * Filters the skill categories based on the specified criteria.
 * 
 * @param {ISkillCategory[]} skillCategories The array of skill categories to filter.
 * @param {number} hideBranche The number used to hide certain categories.
 * 
 * @returns {ISkillCategory[]} The filtered array of skill categories.
 */
function filterCategories(
    skillCategories: ISkillCategory[],
    hideBranche?: number
): ISkillCategory[]
{
    // Hilfsfunktion, um zu prüfen, ob eine Kategorie oder eine ihrer Oberkategorien die zu versteckende ID hat
    const isHidden = (category: ISkillCategory, hideId?: number): boolean =>
    {
        if (!hideId) return false; // Wenn keine ID zum Verstecken gegeben ist, ist die Kategorie nicht versteckt
        if (category.id === hideId) return true; // Aktuelle Kategorie ist die zu versteckende
        if (category.parentCategory === null) return false; // Keine Oberkategorie mehr vorhanden

        // Rekursiver Aufruf für die Oberkategorie
        const parentCategory = skillCategories.find(c => c.id === category.parentCategory);
        return parentCategory ? isHidden(parentCategory, hideId) : false;
    };

    // Filtert die Kategorien, indem für jede Kategorie geprüft wird, ob sie oder ihre Oberkategorien versteckt werden sollen
    return skillCategories.filter(category => !isHidden(category, hideBranche));
}