import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

interface NavigationItemProps
{
    title: string;
    path: string;
    shortPath?: string;
    children?: React.ReactNode | undefined;
    fixedNav?: boolean;
    onClick: () => void
}
/**
 * Component representing a navigation item.
 * 
 * @param {NavigationItemProps} props - The props for the navigation item.
 * @returns {JSX.Element} The rendered navigation item.
 */
export function NavigationItem(props: NavigationItemProps)
{
    const location = useLocation();
    const children = Array.isArray(props.children) ? props.children : [props.children];
    const [isHovered, setIsHovered] = useState<boolean>(false as boolean);
    const [timeout, setTimeoutIntern] = React.useState<null | NodeJS.Timeout>(null);
    const [timeoutLeave, setTimeoutLeave] = React.useState<null | NodeJS.Timeout>(null);

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const [isActive, setIsActive] = React.useState<boolean>(false);
    const childCount = children.length;
    const childrenHeight = childCount * 42;

    useEffect(() =>
    {
        const handlePathChange = () =>
        {
            const isActiveTemp = location.pathname.startsWith(props.path) ||
                (props.shortPath !== undefined && location.pathname.startsWith(props.shortPath));
            setIsActive(isActiveTemp);
        };

        handlePathChange();
    }, [location.pathname, props.path, props.shortPath]);

    React.useEffect(() =>
    {
        if (!isActive)
        {
            setIsOpen(false);
            setIsHovered(false);
        } else
        {
            setIsOpen(true);
            setIsHovered(true);
        }
        if (timeout) clearTimeout(timeout);
        if (timeoutLeave) clearTimeout(timeoutLeave);

    }, [isActive])

    return (
        <div className="itemBox"
            title={props.title}
            onMouseEnter={() =>
            {
                if (timeoutLeave) clearTimeout(timeoutLeave);
                if (!isHovered)
                {
                    setTimeoutIntern(
                        setTimeout(() =>
                        {
                            setIsHovered(true);
                        }, 500)
                    );
                }
            }}
            onMouseLeave={() =>
            {
                if (timeout) clearTimeout(timeout);
                if (isHovered)
                {
                    setTimeoutLeave(
                        setTimeout(() =>
                        {
                            setIsHovered(false);
                        }, 500)
                    );
                }
            }}
        >
            {props.fixedNav ?
                <div
                    className={isOpen ? 'active' : ''}
                    onClick={async () => setIsOpen(true)}
                >
                    <div className="item">{props.title}</div>
                </div>
                :
                <NavLink
                    to={props.path}
                    onClick={props.onClick}
                    className={isActive ? 'active' : ''}
                >
                    <div className="item">{props.title}</div>
                </NavLink>}
            {props.children && <div className="childItem"
                style={{
                    height: (isHovered || isOpen) ? childrenHeight : '0',
                    transition: 'height 0.25s',
                    paddingLeft: 0,
                    "--percent": childrenHeight
                } as React.CSSProperties}
            >{props.children}</div>}
        </div>
    );
}
