import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";

import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation uploadMicrosoftCertificate($certificate: String!) {
    uploadMicrosoftCertificate(certificate: $certificate) 
    ${companySettingsGql}
},
`;

export default async function uploadMicrosoftCertificate(certificate: string)
{
    const variables: OperationVariables = {
        certificate
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c))
}