

import React, { useState } from "react";
import { ArrowLeft, ArrowRight, Check } from "@mui/icons-material";
import { Stepper, Step, StepButton, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IEmployeeSettingsGoal } from "@sharedInterfaces/IEmployeeSettings";
import { ISkillReferenceDTO } from "@sharedInterfaces/ISkill";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import { calculateEmployeeCoverage, toISODateFormat } from "@sharedInterfaces/globalHelper";
import { IReference } from "@sharedInterfaces/IReference";
import { AppState } from "@store/store";
import { setGoals } from "@store/reducer/employeeSettingsReducer";
import Dialog from "@sharedReact/Dialog/Dialogs/Dialog/Dialog";
import Button from "@sharedReact/General/Button/Button";
import LoadingBox from "@sharedReact/General/LoadingBox/LoadingBox";
import configureGoal from "@src/APIs/graphQl/Employee/Goals/configureGoal";

import ErrorBox from "../../ErrorBox/ErrorBox";
import { EmployeeCoverage } from "../../Opportunities/EmployeeSuggestions/EmployeeCoverage";
import SkillsInput from "../../formsControls/inputs/SkillsInput/SkillsInput";
import RowElement from "../../../sharedReact/General/Forms/RowElement/RowElement";
import TextInput from "../../formsControls/inputs/TextInput/TextInput";
import FormatedTextInput from "../../formsControls/inputs/FormatedTextInput/FormatedTextInput";
import CertificatesInput from "../../formsControls/inputs/CertificatesInput/CertificatesInput";

export interface GoalDialogProps
{
    id: string
    goal?: IEmployeeSettingsGoal
    resolve?: () => void;
}


/**
 * Represents a GoalDialog component.
 *
 * @param {GoalDialogProps[]} goals - The goals to be displayed in the dialog.
 *
 * @returns {JSX.Element} - The rendered GoalDialog component.
 */
export function GoalDialog({ id, goal, resolve: onClose }: GoalDialogProps): JSX.Element
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employeeDevelopment;
    const empSkills = useSelector((state: AppState) => state.employee.skills);
    const empCertificates = useSelector((state: AppState) => state.employee.certificates);
    const [skills, setSkills] = useState<ISkillReferenceDTO[]>(goal ? goal.skills : []);
    const [certificates, setCertificates] = useState<IReference[]>(goal ? goal.certificates : []);
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(goal?.untilDate);
    const [activePage, setActivePage] = useState<number>(0);
    const [goalTitle, setGoalTitle] = useState<string>(goal ? goal.title : '');
    const [description, setDescription] = useState<string>(goal ? goal.description : '');
    const [saveError, setSaveError] = useState<string | undefined>(undefined);
    const dialogSteps = [langStrings.oftenUsed.skills, langStrings.goalDate];

    const goals = useSelector((state: AppState) => state.employeeSettings.goals);

    const handleSubmit = () =>
    {
        if (!selectedDate || !skills.length) return;
        setActivePage(2);
        configureGoal(skills, selectedDate, goalTitle, description, goal?.id)
            .then((newId) =>
            {
                let newGoals: IEmployeeSettingsGoal[] = goals;
                if (goal) // UPDATE
                {
                    newGoals = newGoals.filter(innerGoal => innerGoal.id !== goal.id);
                }
                dispatch(setGoals([
                    ...newGoals,
                    {
                        id: newId,
                        title: goalTitle,
                        description: description,
                        skills: skills,
                        certificates: certificates,
                        untilDate: selectedDate,
                        createdAt: new Date(),
                        state: 'inProgress',
                        initialCompareState: calculateEmployeeCoverage(
                            skills.map(s => ({ title: s.title, level: s.level, id: s.id })),
                            empSkills,
                            certificates.map(c => ({ title: c.title, id: c.id })),
                            empCertificates,
                            [],
                            [],
                        ),
                    }
                ]));
                onClose && onClose();
            })
            .catch((ex) =>
            {
                console.error(ex);
                setActivePage(0);
                setSaveError(ex.message);
            });
    };


    return (
        <Dialog
            id={id}
            title={goal ? langStrings.editGoalTitle : langStrings.newGoalDialogTitle}
            footer={<div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    {activePage === 1 &&
                        <Button text={langStrings.oftenUsed.back} icon={<ArrowLeft />} onClick={() =>
                        {
                            setActivePage(activePage - 1);
                        }} />}
                </div>
                {activePage === 0 &&

                    <Button
                        text={langStrings.oftenUsed.next}
                        style={{ float: 'right' }}
                        icon={<ArrowRight />}
                        disabled={skills.length === 0}
                        onClick={() =>
                        {
                            if (goalTitle === "" || !goalTitle)
                            {
                                setGoalTitle(skills.map(s => s.title).join(", "));
                            }
                            setActivePage(activePage + 1);
                        }} />}
                {activePage === 1 &&

                    <Button
                        text={langStrings.oftenUsed.save}
                        style={{ float: 'right' }}
                        icon={<Check />}
                        disabled={skills.length === 0 || !selectedDate}
                        onClick={handleSubmit} />}
            </div>}
            onClose={() =>
            {
                onClose && onClose();
            }}
        >
            <Stepper activeStep={activePage}>
                {dialogSteps.map((label, index) => (
                    <Step key={label}>
                        <StepButton onClick={() => setActivePage(index)}>
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>


            {activePage === 0 &&
                <GoalDialogPage1
                    goalTitle={goalTitle}
                    setGoalTitle={setGoalTitle}
                    description={description}
                    setDescription={setDescription}
                    skills={skills}
                    setSkills={setSkills}
                    certificates={certificates}
                    setCertificates={setCertificates}
                />}
            {activePage === 1 &&
                <GoalDialogPage2 selectedDate={selectedDate} setSelectedDate={setSelectedDate} />}
            {activePage === 2 &&
                <LoadingBox />}
            {saveError && <ErrorBox close={setSaveError.bind(null, undefined)}>{saveError}</ErrorBox>}
        </Dialog>
    );
}
interface GoalDialogPage1Props
{
    goalTitle: string;
    setGoalTitle: (title: string) => void;
    description: string;
    setDescription: (description: string) => void;
    skills: ISkillReferenceDTO[];
    setSkills: (skills: ISkillReferenceDTO[]) => void;
    certificates: IReference[];
    setCertificates: (certificates: IReference[]) => void;
}
/**
 * Represents the GoalDialogPage1 component.
 *
 * @param {GoalDialogPage1Props} skills - The skills props.
 * @returns {JSX.Element} The rendered JSX element.
 */

export function GoalDialogPage1({ goalTitle, setGoalTitle, description, setDescription, skills, setSkills, certificates, setCertificates }: GoalDialogPage1Props): JSX.Element
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employeeDevelopment;
    const empSkills = useSelector((state: AppState) => state.employee.skills);
    const empCertificates = useSelector((state: AppState) => state.employee.certificates);
    const levelDefinitions = useSelector((state: AppState) => state.levelDefinition);
    const allSkills = useSelector((state: AppState) => state.company.allSkills)
        .filter(s =>
        {
            const empSkill = empSkills.find(es => es.id === s.id);
            if (empSkill?.level === levelDefinitions.length)
                return false;
            return true;
        });
    const allCertificates = useSelector((state: AppState) => state.company.allCertificates)
        .filter(c => !empCertificates.find(ec => ec.id === c.id));




    const coverage = calculateEmployeeCoverage(
        skills.map(s => ({ title: s.title, level: s.level, id: s.id })),
        empSkills,
        certificates.map(c => ({ title: c.title, id: c.id })),
        empCertificates,
        [],
        [],

    );
    return (
        <>
            <RowElement title={langStrings.goalName}>
                <TextInput
                    size='small'

                    value={
                        (goalTitle !== "" && goalTitle !== undefined) ? goalTitle :
                            skills.map(s => s.title).join(', ')
                    }
                    onChange={(newVal) => setGoalTitle(newVal)}
                />
            </RowElement>
            <RowElement title={langStrings.oftenUsed.description}>
                <FormatedTextInput
                    value={description}
                    onChange={setDescription}
                />
            </RowElement>
            <Typography style={{ marginTop: '5px' }} variant='body1'>{langStrings.newGoalDialogSkillSelect}</Typography>
            <SkillsInput
                allSkills={allSkills}
                skills={skills}
                size={'small'}
                minimalValues={empSkills.map(e => ({ ...e, level: e.level < levelDefinitions.length ? e.level + 1 : e.level }))}
                onChange={(val) =>
                {
                    setSkills(val.map(s => ({ title: s.title, level: s.level, id: s.id as number })));
                }} />

            <Typography style={{ marginTop: '5px' }} variant='body1'>{langStrings.newGoalDialogCertificateSelect}</Typography>
            <CertificatesInput
                allCertificates={allCertificates}
                selectedCertificates={certificates}
                size={'small'}
                onChange={(val) =>
                {
                    setCertificates(val.map(s => ({ title: s.title, id: s.id as number })));
                }} />

            {skills.length > 0 &&
                <>
                    <Typography variant='body1'>{langStrings.newGoalDialogSkillPleaceCheck}</Typography>
                    <EmployeeCoverage expended suggestedEmployee={coverage} hideName hideAvailability />
                </>}
            <Typography variant='body1'>{langStrings.newGoalDialogSkillHint}</Typography>
        </>
    );
}
interface GoalDialogPage2Props
{
    selectedDate: Date | undefined;
    setSelectedDate: (date: Date | undefined) => void;
}
/**
 * Represents the GoalDialogPage2 component.
 *
 * @param {GoalDialogPage2Props} selectedDate - The selectedDate prop.
 * @param {GoalDialogPage2Props} setSelectedDate - The setSelectedDate prop.
 *
 * @returns {JSX.Element} The JSX element representing the GoalDialogPage2 component.
 */

export function GoalDialogPage2({ selectedDate, setSelectedDate }: GoalDialogPage2Props): JSX.Element
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;

    // Morgen als minimales Datum
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    const minDate = tomorrow.toISOString().substr(0, 10);

    // In zwei Jahren als maximales Datum
    const twoYearsFromNow = new Date();
    twoYearsFromNow.setFullYear(new Date().getFullYear() + 2);
    const maxDate = twoYearsFromNow.toISOString().substr(0, 10);

    return (
        <>
            <Typography style={{ margin: '5px 0' }} variant='body1'>{langStrings.mapCompetenceDateHelpText2}</Typography>
            <TextField
                type="date"
                value={selectedDate ? toISODateFormat(selectedDate) : ''}
                onChange={(e) => setSelectedDate(new Date(e.target.value))}
                InputProps={{
                    inputProps: {
                        min: minDate,
                        max: maxDate
                    }
                }} />
            {selectedDate &&
                <Typography style={{ margin: '5px 0' }} variant='body1'>{langStrings.mapCompetenceDateHelpText3.replace('[DATE]', selectedDate.toLocaleDateString())}</Typography>}
        </>
    );

}
