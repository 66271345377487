import React from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import { Tabs, Tab, Box, Grid, IconButton, Typography, Button, Chip } from '@mui/material';
import { WebSocketClient } from '@src/APIs/WebSockets/WebSocketClient';
import { AppState } from '@store/store';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EWebsocketTopics, EEntityType } from '@sharedInterfaces/globalEnums';
import { ESuggestionRequestType, TSuggestionsRequest, TSuggestionsResponse, ESuggestionResponseType, ISuggestionRejection } from '@sharedInterfaces/ISuggestion';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import { Delete } from '@mui/icons-material';
import RowElement from '@sharedReact/General/Forms/RowElement/RowElement';

interface RejectionsDialogProps
{
    id: string;
    resolve: () => void;
}

const rejectionTopic = EWebsocketTopics.SUGGESTIONS;

const RejectionsDialog: React.FC<RejectionsDialogProps> = (props) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [tabIndex, setTabIndex] = React.useState<number>(0);
    const [skills, setSkills] = React.useState<ISuggestionRejection[] | null>(null);
    const [certificates, setCertificates] = React.useState<ISuggestionRejection[] | null>(null);
    const [loading, setLoading] = React.useState<boolean>(true);

    const selectedEntityType = (tabIndex === 0 ? EEntityType.SKILL : EEntityType.CERTIFICATE)

    const elements = selectedEntityType === EEntityType.SKILL ? skills : certificates;

    React.useEffect(() =>
    {
        const request: TSuggestionsRequest = { type: ESuggestionRequestType.GET_REJECTIONS };
        WebSocketClient.sendMessage(rejectionTopic, request);
    }, []);

    React.useEffect(() =>
    {
        const subKey = WebSocketClient.subscripe<TSuggestionsResponse>(rejectionTopic, (data) =>
        {
            switch (data.type)
            {
                case ESuggestionResponseType.REJECTIONS:
                    setSkills(data.rejections[EEntityType.SKILL]);
                    setCertificates(data.rejections[EEntityType.CERTIFICATE]);
                    setLoading(false);
                    break;
            }
        });
        return () =>
        {
            WebSocketClient.unsubscripe(subKey);
        };
    }, []);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) =>
    {
        setTabIndex(newValue);
    };

    const onDelete = (newTitle: string) =>
    {
        const deleteEvent: TSuggestionsRequest = {
            type: ESuggestionRequestType.DELETE_REJECTION,
            newTitle,
            entityType: EEntityType.SKILL
        };
        WebSocketClient.sendMessage(rejectionTopic, deleteEvent);

        if (selectedEntityType === EEntityType.SKILL)
        {
            setSkills((prev) => prev ? prev.filter((s) => s.newTitle !== newTitle) : null);
        } else if (selectedEntityType === EEntityType.CERTIFICATE)
        {
            setCertificates((prev) => prev ? prev.filter((c) => c.newTitle !== newTitle) : null);
        }
    };

    return (
        <Dialog
            id={props.id}
            title={langStrings.rejectionsDialogTitle}
            onClose={props.resolve}
            footer={
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <Button variant="contained" color="primary" onClick={props.resolve}>{langStrings.oftenUsed.close}</Button>
                </div>
            }
        >
            <Box sx={{ width: '100%' }}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Rejection Tabs" variant='fullWidth'>
                    <Tab label={langStrings.oftenUsed.skills} />
                    <Tab label={langStrings.oftenUsed.certificates} />
                </Tabs>
                {loading ? <LoadingBox /> : (
                    <Box sx={{ padding: 2 }}>
                        {(!elements?.length) && (
                            <Typography variant="body2" color="text.secondary" sx={{ padding: 2 }}>
                                {langStrings.noRejectionsAvailable}
                            </Typography>
                        )}
                        <Grid container spacing={2}>
                            {elements?.map((rejection) => (
                                <Grid item xs={12} key={`${selectedEntityType}-${rejection.newTitle}`}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #e0e0e0',
                                        borderRadius: 1,
                                        padding: 2,
                                    }}>
                                        <Box>
                                            <RowElement title={langStrings.title}>
                                                <Typography variant="body1">
                                                    {rejection.newTitle}
                                                </Typography>
                                            </RowElement>
                                            <RowElement title={langStrings.declineSuggestionReasonLabel}>
                                                {rejection.declinedReason}
                                            </RowElement>
                                            <RowElement title={langStrings.oftenUsed.otherNames}>
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                                    {rejection.titles?.length ? (
                                                        rejection.titles.map((title, index) => (
                                                            <Chip key={index} label={title} variant="outlined" color="secondary" />
                                                        ))
                                                    ) : (
                                                        <Typography variant="body2" color="text.secondary">
                                                            {langStrings.notSet}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </RowElement>
                                        </Box>
                                        <IconButton onClick={() => onDelete(rejection.newTitle)}>
                                            <Delete />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </Box>
        </Dialog>
    );
};

export default RejectionsDialog;
