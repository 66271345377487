import { ELanguage } from "../languageHelper";

export interface INotloggedinTexts
{
    title: string
    text: string
    login: string
}
type ILangData = {
    [lang in ELanguage]: INotloggedinTexts
}
export const notloggedinTexts: ILangData = {
    'th': {
        title: '',
        text: 'ขณะนี้โครงการนี้อยู่ในช่วงเบต้าที่ปิด หากได้รับเชิญ โปรด ',
        login: 'เข้าสู่ระบบได้เลย',
    },
    'en': {
        title: 'Closed beta',
        text: 'At the moment this project is in a closed beta. If you are invited feel free to ',
        login: 'login...',
    },
    'de': {
        title: 'Geschlossene Beta',
        text: 'Aktuell befindet sich dieses Projekt in einer geschlossenen Beta-Phase. Falls Du eingeladen wurdest, kannst du dich gerne ',
        login: 'anmelden...',
    },
}