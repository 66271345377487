import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InnerLayout from '@sharedReact/Layouts/InnerLayout/InnerLayout';
import store, { AppState } from '@store/store';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { setOffline } from '@store/storeHelper';
import { setSecret } from '@store/reducer/clientReducer';
import registerClient from '@src/APIs/rest/registerClient';
import './LoggedInPage.css';
import { useNavigate } from 'react-router-dom';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { stageContext } from '@src/globals';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import { BoxButtons, BoxButton } from '@src/Components/BoxButton/BoxButton';
import { Add, } from '@mui/icons-material';
import { RegisterCompanyDialog } from '@src/Components/Dialogs/RegisterCompanyDialog/RegisterCompanyDialog';
import { useDialog } from '@sharedReact/Dialog/DialogManager';
/**
 * Retrieves the error description from the hash.
 *
 * @returns {string} The error description.
 */
function getErrorDescriptionFromHash(): string | null
{
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const errorDescription = params.get('error_description');
    if (errorDescription)
    {
        return decodeURIComponent(errorDescription);
    }

    return null;
}

/**
 * Represents a component for displaying a logged-in page.
 *
 * @returns {JSX.Element} The rendered JSX element.
 */
function LoggedInPage()
{
    const { openDialog } = useDialog();
    const showErrorDialog = useErrorDialog();
    const navigate = useNavigate();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).loggedin;

    const employee = useSelector((state: AppState) => state.employee);
    const startPage = useSelector((state: AppState) => state.employeeSettings.startPage);

    const [loginError, setLoginError] = React.useState(false as boolean);
    const [redirect, setRedirect] = React.useState("");
    const [companies, setCompanies] = React.useState<null | { title: string, companyId: string }[]>(null);

    const error = getErrorDescriptionFromHash();

    async function checkLogin(companyId?: string)
    {
        console.log("2");
        if (window.location.hash.length > 5)
        {
            console.log("1");
            let idToken = "";
            const cookies = localStorage.getItem("cookies");
            setRedirect(localStorage.getItem("redirect") || "");
            localStorage.clear();
            console.log("Cleared");
            cookies && localStorage.setItem("cookies", cookies);
            store.dispatch(setSecret(null))
            const hashValues = window.location.hash
                .substr(1)
                .split('&')
                .map((s) =>
                {
                    return s.split('=');
                });
            const accessToken = hashValues.find(s => s[0] === "access_token");
            const idTokenSplit = hashValues.find(s => s[0] === "id_token");
            if (accessToken && idTokenSplit) // New ID TOken
            {
                idToken = idTokenSplit[1];
                const result = await registerClient(idToken, companyId).catch(async (ex) =>
                {
                    await showErrorDialog(ex, "Fehler beim Einloggen.");
                });

                if (result)
                {
                    console.log("Login successfull");
                    setOffline(false)
                    if (result === true)
                    {
                        window.location.hash = '';
                    } else
                    {
                        setCompanies(result);
                    }
                    // window.location.pathname = `/${ELinks.PROFILE_EDIT}`;
                } else
                {
                    console.error("Login not successfull");
                    window.location.href = `https://${stageContext.logoutHostname || stageContext.domain}/loginfailed#${window.location.hash}`;
                    throw new Error('Login not successfull');
                }
            }
        }
    }

    useEffect(() =>
    {
        checkLogin().catch((ex) =>
        {
            console.log(ex)
            setLoginError(true);
        });
    }, [])

    useEffect(() =>
    {
        if (employee.id === -1) return;
        if (redirect && redirect.length > 0)
        {
            navigate(redirect);
            return;
        }
        navigate(`/${ELinks[startPage]}`);
    }, [employee.id])

    if (error)
    {
        return <InnerLayout title={error}>
            <span>{error}</span>
        </InnerLayout>
    }
    if (loginError)
    {
        return <InnerLayout title={langStrings.titleError}>
            <span>{langStrings.textError}</span>
        </InnerLayout>
    }
    if (companies)
    {
        let text: string;
        let buttons: JSX.Element[] = [];
        if (companies.length > 0)
        {
            text = langStrings.selectCompany;
            buttons = companies.map(company => <BoxButton
                key={company.companyId}
                title={company.title}
                onClick={checkLogin.bind(null, company.companyId)}
            />
            )
        }
        else
        {
            text = langStrings.noCompany;
        }
        return <InnerLayout title={langStrings.title}>
            <span style={{ marginLeft: 15 }}>{text}</span>
            <BoxButtons>
                <BoxButton
                    icon={<Add />}
                    title={langStrings.createCompany}
                    color="secondary"
                    onClick={() =>
                    {
                        openDialog(<RegisterCompanyDialog
                            id="RegisterCompanyDialog"
                            resolve={(created) =>
                            {
                                if (created)
                                {
                                    checkLogin(created);
                                }
                            }}
                        />)
                    }} />
                {buttons}
            </BoxButtons>
        </InnerLayout>
    }

    return <InnerLayout title={
        employee.firstName ?
            `${langStrings.title} ${employee.firstName},` :
            langStrings.title
    }>
        <span>{langStrings.text}</span>
    </InnerLayout>
}


export default LoggedInPage;
