import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import './Layout.css';
import { AppState } from '@store/store';
import Header from '@src/Components/Header/Header';
import { isLoggedIn } from '@store/reducer/clientReducer';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

import FirstExpirienceDialog from "../../../Components/Dialogs/FirstExperienceDialog/FirstExperienceDialog";
import Navigation from '../../../Components/Navigation/Navigation';

import { Footer } from './Footer';
import { DialogBar } from './DialogBar';

interface LayoutProps
{
  inDialog?: boolean;

  children: JSX.Element;
}

/**
 * Renders the Layout component.
 *
 * @param {LayoutProps} props - The props object which contains the necessary data for rendering the Layout.
 * @returns {JSX.Element} - The rendered Layout component.
 */
function Layout(props: LayoutProps)
{
  const { openDialog } = useDialog();
  const width = useSelector((state: AppState) => state.windowSize.width);
  const loggedIn = useSelector((state: AppState) => isLoggedIn(state.client.secret), shallowEqual);
  const firstExperience = useSelector((state: AppState) => state.employeeSettings.firstExperience);


  const overflowNav = width <= 700;
  const [navIsOpen, setNavIsOpen] = React.useState(() => width > 700);
  const toggleNav = React.useCallback(() =>
  {
    setNavIsOpen((prevNavIsOpen) => !prevNavIsOpen);
  }, []);


  useEffect(() => //FirstExpirienceDialog
  {
    if (!firstExperience) return;
    const openFirstExpirience = () =>
    {
      openDialog(
        <FirstExpirienceDialog
          id="FirstExpirienceDialog"
        />
      )
    }
    const timeout = setTimeout(openFirstExpirience, 1000);
    return () => { clearTimeout(timeout) };
  }, [firstExperience]);


  if (props.inDialog) return <>{props.children}</>;
  return (
    <React.Fragment>
      <Header toggleNav={toggleNav}
        navIsOpen={navIsOpen}
      />
      <div className='outerWrapper'>
        <div className={`wrapper`}>
          <Navigation
            fixedNav={overflowNav}
            isOpen={navIsOpen}
            loggedIn={loggedIn}
            setNavIsOpen={setNavIsOpen}
          />
          <div
            className='contentOuter'
          >
            {props.children}
          </div>
        </div>
        <DialogBar />
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default Layout;