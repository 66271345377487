import { Merge } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { setEmployeeSkills } from '@store/reducer/employeeReducer';
import { setCompanyAllSkills } from '@store/reducer/companyReducer';
import { setSkills } from '@store/reducer/skillReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import getSkills from '@src/APIs/graphQl/Skill/getSkills';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import SkillDetail from '@src/Objects/SkillDetail';
import { Card, CardContent, Checkbox, FormControlLabel, Grid, Typography, Box } from '@mui/material';
import mergeSkills from '@src/APIs/graphQl/Skill/mergeSkills';
import SkillItem from '@src/App/NewLayout/Items/SkillItem/SkillItem';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import ErrorBox from '../../ErrorBox/ErrorBox';

interface MergeSkillsDialogProps
{
    id: string;
    skillIds: number[];
    resolve: (success: boolean) => void;
}

/**
 * Component representing a dialog for merging skills.
 *
 * @param {MergeSkillsDialogProps} props - The properties for the MergeSkillsDialog.
 * @returns {JSX.Element} - The JSX element for the MergeSkillsDialog.
 */
function MergeSkillsDialog(props: MergeSkillsDialogProps): JSX.Element | null
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;

    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const employee = useSelector((state: AppState) => state.employee);
    const skillsWithoutFilter = useSelector((state: AppState) => state.skills);

    const [skills, setSkillsState] = useState<SkillDetail[] | null>(null);
    const [selectedSkillIds, setSelectedSkillIds] = useState<Set<number>>(new Set());
    const [suggestedSkill, setSuggestedSkill] = useState<SkillDetail | null>(null);
    const [newSkillName, setNewSkillName] = useState<string>("");
    const [newDescription, setNewDescription] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");
    const [saving, setSaving] = useState<boolean>(false);

    // Lade Skills beim Mounten oder wenn props.skillIds sich ändern
    useEffect(() =>
    {
        if (skills === null)
        {
            getSkills()
                .then((fetchedSkills) =>
                {
                    const relevantSkills = fetchedSkills.filter((skill) => props.skillIds.includes(skill.id));
                    setSkillsState(relevantSkills);

                    // Initialisiere ausgewählte Skills mit allen relevanten Skills
                    setSelectedSkillIds(new Set(relevantSkills.map(skill => skill.id)));

                    // Bestimme den vorgeschlagenen Skill
                    const initialSuggestedSkill = determineSuggestedSkill(relevantSkills);
                    setSuggestedSkill(initialSuggestedSkill);
                    setNewSkillName(initialSuggestedSkill.title);
                    setNewDescription(initialSuggestedSkill.description);
                })
                .catch((error) =>
                {
                    console.error("Error fetching skills:", error);
                    setErrorText(langStrings.errorFetchingSkills);
                });
        }
    }, [props.skillIds, skills, langStrings.errorFetchingSkills]);

    // Funktion zur Bestimmung des vorgeschlagenen Skills
    const determineSuggestedSkill = (skillsList: SkillDetail[]): SkillDetail =>
    {
        let newSuggestedSkill = skillsList[0];
        skillsList.forEach((skill) =>
        {
            if (
                skill.employees.length > newSuggestedSkill.employees.length ||
                (skill.employees.length === newSuggestedSkill.employees.length &&
                    Math.max(...skill.employees.map((e) => e.level)) > Math.max(...newSuggestedSkill.employees.map((e) => e.level)))
            )
            {
                newSuggestedSkill = skill;
            }
        });
        return newSuggestedSkill;
    };

    // Funktion zur Handhabung von Checkbox-Änderungen
    const handleCheckboxChange = (skillId: number) =>
    {
        setSelectedSkillIds((prevSelected) =>
        {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(skillId))
            {
                newSelected.delete(skillId);
            } else
            {
                newSelected.add(skillId);
            }
            return newSelected;
        });
    };

    // Aktualisiere den vorgeschlagenen Skill, wenn die Auswahl der Skills sich ändert
    useEffect(() =>
    {
        if (skills)
        {
            const selectedSkills = skills.filter(skill => selectedSkillIds.has(skill.id));
            if (selectedSkills.length === 0)
            {
                setSuggestedSkill(null);
                setNewSkillName("");
                setNewDescription("");
                return;
            }
            const newSuggested = determineSuggestedSkill(selectedSkills);
            setSuggestedSkill(newSuggested);
            setNewSkillName(newSuggested.title);
            setNewDescription(newSuggested.description);
        }
    }, [selectedSkillIds, skills]);

    // Generiere eine einzigartige Liste von Mitarbeitern basierend auf den ausgewählten Skills
    const uniqueEmployees = {} as {
        [key: number]: {
            title: string;
            id: number;
            skills: { title: string; level: number; id: number }[];
        };
    };
    skills?.forEach((skillDetail) =>
    {
        if (selectedSkillIds.has(skillDetail.id))
        {
            skillDetail.employees.forEach((employee) =>
            {
                if (uniqueEmployees[employee.id] === undefined)
                {
                    uniqueEmployees[employee.id] = {
                        title: employee.title,
                        id: employee.id,
                        skills: [{ title: skillDetail.title, level: employee.level, id: skillDetail.id }],
                    };
                } else
                {
                    uniqueEmployees[employee.id].skills.push({ title: skillDetail.title, level: employee.level, id: skillDetail.id });
                }
            });
        }
    });
    const employees = Object.values(uniqueEmployees);

    if (!employee)
    {
        return null;
    }

    // Bestimme, ob der Merge-Button deaktiviert sein soll
    const isMergeDisabled = saving || selectedSkillIds.size === 0;

    return (
        <Dialog
            id={props.id}
            title={langStrings.mergeSkills}
            onClose={() => props.resolve(false)}
            footer={
                <Box sx={{ float: 'right' }}>
                    <Button
                        icon={<Merge />}
                        disabled={isMergeDisabled}
                        text={langStrings.merge}
                        size={'normal'}
                        onClick={async () =>
                        {
                            if (!selectedSkillIds.size || !suggestedSkill) return;
                            setSaving(true);
                            try
                            {
                                const result = await mergeSkills(
                                    {
                                        id: suggestedSkill.id,
                                        title: newSkillName,
                                        description: newDescription,
                                    },
                                    Array.from(selectedSkillIds)
                                );

                                if (!result || !employee)
                                {
                                    setErrorText(langStrings.error);
                                    setSaving(false);
                                    return;
                                }

                                setErrorText('');
                                setSaving(false);

                                const newAllSkills = allSkills
                                    .filter(skill => !result.removed.includes(skill.id))
                                    .map(skill =>
                                        skill.id === result.newSkill.id
                                            ? { ...skill, title: result.newSkill.title, otherNames: result.otherNames }
                                            : skill
                                    );

                                // Aktualisiere die Mitarbeiterfähigkeiten
                                const newEmployeeSkills = employee.skills
                                    .filter(skill => !result.removed.includes(skill.id))
                                    .map(skill =>
                                        skill.id === result.newSkill.id
                                            ? {
                                                ...skill,
                                                title: result.newSkill.title,
                                                level: result.newSkill.employees.find(e => e.id === employee.id)?.level || 1,
                                            }
                                            : skill
                                    );

                                const newSkills = skillsWithoutFilter
                                    .filter(skill => !result.removed.includes(skill.id))
                                    .map(skill =>
                                        skill.id === result.newSkill.id
                                            ? { ...skill, title: result.newSkill.title, description: result.newSkill.description, employees: result.newSkill.employees }
                                            : skill
                                    );

                                // Dispatch Aktionen
                                dispatch(setCompanyAllSkills(newAllSkills));
                                dispatch(setEmployeeSkills(newEmployeeSkills));
                                dispatch(setSkills(newSkills.map(ns => new SkillDetail(ns))));
                                props.resolve(true);
                            } catch (ex: any)
                            {
                                setErrorText(`${langStrings.error} ${ex.toString()}`);
                                setSaving(false);
                            }
                        }}
                    />
                </Box>
            }
        >
            {!skills && <LoadingBox />}
            {skills && (
                <div className="mergeBox">
                    <Typography variant="body1">{langStrings.skillsSelectedForMerge}:</Typography>
                    <Box sx={{ maxHeight: 200, overflowY: 'auto', border: '1px solid #ccc', padding: 1, marginY: 2 }}>
                        {skills.map(skill => (
                            <FormControlLabel
                                key={skill.id}
                                control={
                                    <Checkbox
                                        checked={selectedSkillIds.has(skill.id)}
                                        onChange={() => handleCheckboxChange(skill.id)}
                                        color="primary"
                                    />
                                }
                                label={skill.title}
                            />
                        ))}
                    </Box>

                    <Typography variant="body1" marginTop={2} marginBottom={1}>
                        {langStrings.effectEmployeesWarning} {employees.length} {langStrings.employees}:
                    </Typography>
                    <Grid container spacing={2}>
                        {employees.map(emp => (
                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex', flexGrow: 1 }} key={emp.id}>
                                <Card className="employeeCard" style={{ flexGrow: 1 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div">
                                            {emp.title}
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary">
                                            {langStrings.skills}:
                                        </Typography>
                                        {emp.skills.map(skill => (
                                            <SkillItem
                                                key={skill.id}
                                                title={skill.title}
                                                id={skill.id}
                                                level={skill.level}
                                            />
                                        ))}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Typography variant="h5" marginTop={1}>{langStrings.newSkill}</Typography>
                    <div className="newSkill">
                        <RowElement title={langStrings.skill} alignTitle="left">
                            <TextInput value={newSkillName} onChange={s => setNewSkillName(s)} />
                        </RowElement>
                        <RowElement title={langStrings.description} alignTitle="left">
                            <FormatedTextInput value={newDescription} onChange={s => setNewDescription(s)} />
                        </RowElement>
                        <Typography>{langStrings.descriptionGeneratesAutomaticly}</Typography>
                    </div>
                    {errorText && <ErrorBox close={() => setErrorText('')}> {errorText}</ErrorBox>}
                    <ErrorBox warning>{langStrings.mergeWarning}</ErrorBox>
                </div>
            )}
        </Dialog>
    );
}

export default MergeSkillsDialog;