import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import store from "@store/store";
import { deleteEmployee as deleteEmployeeReducerAction } from "@store/reducer/employeesReducer";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";
import { companySettingsGql } from "../Company/getCompanySettings";

const query = gql`
mutation deleteEmployee($id: Int!) {
    deleteEmployee(id: $id)
        ${companySettingsGql}
},
`;

/**
 * Deletes an employee from the company.
 * 
 * @param {number} id - The ID of the employee to delete.
 * @returns {Promise<Company>} A promise that resolves to the updated company object after the employee deletion.
 */
export default async function deleteEmployee(id: number)
{
    const variables: OperationVariables = { id };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then((c) =>
        {
            store.dispatch(deleteEmployeeReducerAction(id))
            return new Company(c)
        });
}