import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";

const query = gql`
mutation companyEmailSettingsSendMailExchange($mailbox: String!) {
    companyEmailSettingsSendMailExchange(mailbox: $mailbox) 
},
`;


export default async function companyEmailSettingsSendMailExchange(mailbox: string)
{
    const variables: OperationVariables = {
        mailbox,
    };
    return GraphQL.mutate<boolean>(query, variables)
}