import { Action } from "redux";
import { IOrgUnitDetailDTO } from "@sharedInterfaces/IOrgUnit";

const initialState: IOrgUnitDetailDTO[] = [];

export enum EOrgUnitAction
{
    SET_ORGUNITS = "SET_ORGUNITS",
    DELETE_ORGUNIT = "DELETE_ORGUNIT",
    UPDATE_ORGUNIT = "UPDATE_ORGUNIT",
}

interface SetOrgUnitsAction extends Action
{
    type: EOrgUnitAction.SET_ORGUNITS;
    payload: IOrgUnitDetailDTO[];
}

interface DeleteOrgUnitAction extends Action
{
    type: EOrgUnitAction.DELETE_ORGUNIT;
    payload: number; // Assuming the unique identifier is a number
}

interface UpdateOrgUnitAction extends Action
{
    type: EOrgUnitAction.UPDATE_ORGUNIT;
    payload: IOrgUnitDetailDTO;
}

type OrgUnitAction = SetOrgUnitsAction | DeleteOrgUnitAction | UpdateOrgUnitAction;

export const orgunitReducer = (state = initialState, action: OrgUnitAction): IOrgUnitDetailDTO[] =>
{
    switch (action.type)
    {
        case EOrgUnitAction.SET_ORGUNITS:
            return action.payload;
        case EOrgUnitAction.DELETE_ORGUNIT:
            return state.filter(orgunit => orgunit.id !== action.payload);
        case EOrgUnitAction.UPDATE_ORGUNIT:
            // eslint-disable-next-line no-case-declarations
            const index = state.findIndex(orgunit => orgunit.id === action.payload.id);
            if (index !== -1)
            {
                const newState = [...state];
                newState[index] = action.payload;
                return newState;
            } else
            {
                return [...state, action.payload];
            }
        default:
            return state;
    }
};

// Action Creators
export const setOrgUnits = (orgunits: IOrgUnitDetailDTO[]): SetOrgUnitsAction =>
{
    orgunits.forEach(orgunit => orgunit.id = parseInt(orgunit.id.toString()));
    return {
        type: EOrgUnitAction.SET_ORGUNITS,
        payload: orgunits,
    }
};

export const deleteOrgUnit = (id: number): DeleteOrgUnitAction => ({
    type: EOrgUnitAction.DELETE_ORGUNIT,
    payload: id,
});

export const updateOrgUnit = (orgUnit: IOrgUnitDetailDTO): UpdateOrgUnitAction => 
{
    return {
        type: EOrgUnitAction.UPDATE_ORGUNIT,
        payload: {
            ...orgUnit,
            id: parseInt(orgUnit.id.toString()),
        },
    }
};
