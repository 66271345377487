import React from 'react';
import './SearchBox.css';

interface ISearchElement
{
    title: string
    id: string | number
}
interface SearchBoxProps
{
    elements: ISearchElement[]
    onSelect: (selectedElement: ISearchElement) => void
    onTextChange?: (newElement: string) => void
}

/**
 * SearchBox component.
 *
 * @param {SearchBoxProps} props - The props of the SearchBox.
 * @returns {JSX.Element} The rendered SearchBox component.
 */
function SearchBox(props: SearchBoxProps)
{
    const [searchText, setSearchText] = React.useState("" as string);
    // const [isFocused, setIsFocused] = React.useState(false as boolean);

    const searchTextLowerCase = searchText.toLowerCase();
    const elements = props.elements;

    const suggestions = elements.filter(s =>
    {
        if (searchTextLowerCase.length < 1) return false;
        const elementTitleLowerCase = s.title.toLowerCase();
        return (elementTitleLowerCase.indexOf(searchTextLowerCase) > -1) && elementTitleLowerCase !== searchTextLowerCase;
    })
        .map((element, i) =>
            <div
                className="sugestion"
                id={"sugestion" + i}
                key={element.id}
                tabIndex={0}
                onKeyDown={
                    (evt) =>
                    {
                        if (evt.key === 'Enter' || evt.key === 'NumpadEnter')
                        {
                            evt.currentTarget.click();
                            const sug = document.getElementById('searchBox') as HTMLElement
                            if (sug)
                                sug.focus();
                        } else if (evt.key === 'ArrowDown')
                        {
                            const sug = document.getElementById('sugestion' + (i + 1)) as HTMLElement
                            if (sug)
                                sug.focus();
                        }
                        else if (evt.key === 'ArrowUp')
                        {
                            if (i === 0)
                            {
                                const sug = document.getElementById('searchBox') as HTMLElement
                                if (sug) sug.focus();
                            } else
                            {
                                const sug = document.getElementById('sugestion' + (i - 1)) as HTMLElement
                                if (sug) sug.focus();
                            }
                        }
                    }
                }
                onClick={
                    () =>
                    {
                        props.onSelect(
                            element
                        );
                        setSearchText("");
                    }
                }>
                {element.title}
            </div>
        );

    return (
        <div
            className='searchBox'
            id="searchBox"
        // onFocus={() => setIsFocused(true)}
        // onBlur={() => setIsFocused(false)}
        >
            <input type="text" value={searchText}
                autoComplete="off"
                name="SearchBox"
                id="searchBoxInput"
                onChange={(e) =>
                {
                    setSearchText(e.target.value);
                    if (props.onTextChange)
                        props.onTextChange(e.target.value);
                    // props.onSelect(
                    //     element
                    // );
                    // props.setNewEmployee(
                    //     {
                    //         ...props.newEmployee,
                    //         id: -1,
                    //         title: e.target.value
                    //     }
                    // );
                }
                }
                onKeyDown={
                    (evt) =>
                    {
                        if (evt.key === 'ArrowDown')
                        {
                            const sug = document.querySelector('.sugestion') as HTMLElement
                            if (sug)
                                sug.focus();
                        } else if (evt.key === 'Enter')
                        { /* empty */ }
                    }
                }
                onBlur={() =>
                {
                    elements.forEach(element =>
                    {
                        const lowerCaseTitle: string = element.title.toLowerCase();
                        if (searchTextLowerCase === lowerCaseTitle)
                        {
                            props.onSelect(
                                element
                            );
                        }
                    });
                }}
            />
            {(suggestions.length > 0) &&

                <div className="sugestions">
                    {
                        suggestions
                    }
                </div>

            }
        </div >
    );
}

export default SearchBox;
