import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IProfileSettingsTexts extends ITextBase
{
    yourRoles: string;
    lastForecastDate: string;
    unknownSkillCount: string;
    completedGolasCount: string;
    learnProgress: string;
    upload: string;
    transforming: string;
    uploading: string;
    profilePicture: string;
    defaultPage: string;
    development: string;
    forecast: string;
    analyse: string;
    general: string;
    logout: string
    generalInfo: string
    firstName: string
    lastName: string
    orgUnit: string
    description: string
    analyseTitle: string
    analyseDescription: string
    analyseDocx: string
    analyseText: string
    yourSkills: string
    yourCertificates: string
}
type ILangData = {
    [lang in ELanguage]: IProfileSettingsTexts
}
export const profilesettingsTexts: ILangData = {
            'th': {
                yourRoles: 'บทบาทของคุณ',
        forecast: 'พยากรณ์',
                lastForecastDate: 'ถึงเวลาที่จะปรับปรุงการพยากรณ์',
                unknownSkillCount: 'จำนวนทักษะที่ไม่รู้จัก',
                completedGolasCount: 'จำนวนเป้าหมายที่สำเร็จ',
                learnProgress: 'ความคืบหน้าในการเรียนรู้',
                upload: 'อัปโหลด',
                transforming: 'กำลังประมวลผล...',
                uploading: 'กำลังอัปโหลด...',
                profilePicture: 'รูปโปรไฟล์',
                defaultPage: 'หน้าหลัก',
                development: 'การศึกษาต่อ',
                analyse: 'การวิเคราะห์ CV',
                oftenUsed: oftenUsedTranslations['th'],
                general: 'ทั่วไป',
                logout: 'ออกจากระบบ',
                generalInfo: 'ข้อมูลทั่วไป',
                firstName: 'ชื่อ',
                lastName: 'นามสกุล',
                orgUnit: 'หน่วยงาน',
                description: oftenUsedTranslations['th'].description,
                analyseTitle: 'วิเคราะห์ไฟล์และข้อความ',
                analyseDocx: 'วิเคราะห์ไฟล์ docx',
                analyseText: 'วิเคราะห์ข้อความ',
                yourSkills: 'ทักษะของคุณ',
                yourCertificates: 'ใบรับรองของคุณ',
                analyseDescription: 'ด้วยคุณลักษณะนี้ คุณสามารถสกัดคำอธิบายโปรไฟล์ ทักษะ และใบรับรองจากเรซูเม่หรือข้อความใดๆ ได้เพียงไม่กี่ขั้นตอนง่ายๆ',
            },
            'en': {
                yourRoles: 'Your Roles',
        lastForecastDate: "It's time to update the forecast.",
                unknownSkillCount: 'Number of unknown skills',
                completedGolasCount: 'Achieved goals count',
                learnProgress: 'Learning Progress',
                upload: 'Upload',
                transforming: 'Processing...',
                uploading: 'Uploading...',
                profilePicture: 'Profile picture',
                defaultPage: 'Homepage',
                development: 'Further Education',
                analyse: 'CV Analysis',
                forecast: 'Forecast',
                oftenUsed: oftenUsedTranslations['en'],
                general: 'General',
                logout: 'Logout',
                generalInfo: 'General info',
                firstName: 'Firstname',
                lastName: 'Lastname',
                orgUnit: 'Organisational unit',
                description: oftenUsedTranslations['en'].description,
                analyseTitle: 'Analyse files & texts',
                analyseDocx: 'Analyse docx',
                analyseText: 'Analyse text',
                yourSkills: 'Your Skills',
                yourCertificates: 'Your Certificates',
                analyseDescription: 'With this feature, you can extract a profile description, skills, and certificates from your resume or any text in just a few easy steps.',
            },
            'de': {
                oftenUsed: oftenUsedTranslations['de'],
                logout: 'Abmelden',
                generalInfo: 'Allgemeine Informationen',
                firstName: 'Vorname',
                lastName: 'Nachname',
                orgUnit: oftenUsedTranslations['de'].orgUnit,
                description: oftenUsedTranslations['de'].description,
                analyseTitle: 'Analysiere Dateien & Texte',
                analyseDocx: 'Analysiere docx',
                analyseText: 'Analysiere Text',
                yourSkills: 'Deine Fähigkeiten',
                yourCertificates: 'Deine Zertifikate',
                analyseDescription: 'Mit dieser Funktion kannst du in wenigen Schritten eine Profilbeschreibung, Skills und Zertifikate aus deinem Lebenslauf oder einem beliebigen Text extrahieren lassen.',
                general: 'Allgemein',
                forecast: 'Forecast',
                analyse: 'CV Analyse',
                development: 'Weiterbildung',
                defaultPage: 'Startseite',
                profilePicture: 'Profilbild',
                uploading: 'Wird hochgeladen...',
                transforming: 'Wird verarbeitet...',
                upload: 'Hochladen',
                learnProgress: 'Lernfortschritt',
                completedGolasCount: 'Erreichte Ziele',
                unknownSkillCount: 'Anzahl unbekannter Fähigkeiten',
                lastForecastDate: 'Es ist an der Zeit den Forecast zu aktualisieren.',
                yourRoles: 'Deine Rollen',
            },
        }