import { Add, Delete, Lock } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconButton, Typography } from "@mui/material";
import { IProductCompetenceReference } from "@sharedInterfaces/IProduct";
import { ISmallCertificate } from "@sharedInterfaces/IWhoIAm";
import { ICertificateEmployeeDTO } from "@sharedInterfaces/ICertificate";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import { AppState } from "@store/store";
import { entityTypeToLink, EEntityType } from "@sharedInterfaces/globalEnums";
import Button from "@sharedReact/General/Button/Button";
import Item from "@src/App/NewLayout/Items/Item/Item";
import { NewBadge } from "@src/App/NewLayout/Components/ItemBadge/ItemBadge";
import './CertificatesInput.css'
import { INewCertificate } from "@src/Components/EditCertificateList/EditCertificateList";

import CertificateSearchBox from "../../searchBoxes/CertificateSearchBox/CertificateSearchBox";


interface CertificatesInputProps
{
    allCertificates: ISmallCertificate[]
    selectedCertificates: { title: string, id?: number }[]
    showMode?: boolean
    size: 'medium' | 'small'
    competences?: IProductCompetenceReference[]
    disabled?: boolean
    helperText?: string
    onChange(val: { title: string, id?: number }[]): void
}

/**
 * This component renders an input field for certificates.
 * 
 * @component
 * @param {Array} allCertificates - The list of all available certificates.
 * @param {Array} selectedCertificates - The list of selected certificates.
 * @param {boolean} showMode - Flag to indicate whether to show certificates in read-only mode.
 * @param {string} size - The size of the input field.
 * @param {Array} competences - The list of competences.
 * @param {function} onChange - The function called when a certificate is selected/deselected.
 * @returns {JSX.Element}
 */
function CertificatesInput({ allCertificates, selectedCertificates, showMode, size, competences, disabled, helperText, onChange, }: CertificatesInputProps)
{
    const navigate = useNavigate();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).certificates;

    const [newCertificate, setNewCertificate]: [INewCertificate, (value: INewCertificate) => void] = React.useState({ title: '' } as INewCertificate);
    const [newCertificateError, setNewCertificateError]: [boolean, (value: boolean) => void] = React.useState(false as boolean);

    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);

    const selectedCompetencesIds = !competences ? [] : competences.map(c => c.id);
    const competenceCertificates = allCompetences
        .filter(c => selectedCompetencesIds?.includes(c.id))
        .flatMap(c =>
        {
            if (!competences) return []
            const index = selectedCompetencesIds?.indexOf(c.id);
            if (!c.levels[competences[index]?.level - 1]?.certificates) return []
            return c.levels[competences[index].level - 1].certificates.map(s => ({ ...s, competence: c.title }))
        })

    const certificatesForBox: { title: string, id?: number, lockedBy?: string }[] = [
        ...competenceCertificates.map(c => ({ ...c, lockedBy: c.competence })),
        ...selectedCertificates,
    ]
    const filteredAllCertificates = allCertificates.filter(a =>
        certificatesForBox.find(s => s.id === a.id || s.title === a.title) === undefined)

    const helperTextElement = helperText && (
        <Typography variant="caption" color="textSecondary" style={{ marginTop: 10 }}>
            {helperText}
        </Typography>
    );


    const certificatesDOM = certificatesForBox.map(certificate =>
    {
        const withoutLink = !showMode || certificate.id === undefined;
        const certDom = <Item
            percent={75}
            key={certificate.title + certificate.id}
            onClick={() =>
            {
                if (withoutLink || !certificate.id) return;
                navigate(entityTypeToLink(certificate.id, EEntityType.CERTIFICATE));
            }}
            rightContent={
                <>
                    {
                        (!showMode && !certificate.lockedBy) &&
                        <IconButton
                            title={langStrings.delete}
                            size='small'
                            onClick={() =>
                            {
                                const newSelectedCertificates = selectedCertificates.map(e => Object.assign({}, e))
                                    .filter(e => e.id !== certificate.id)
                                    .sort((a, b) => a.title.localeCompare(b.title));
                                onChange(newSelectedCertificates);
                            }}
                        >
                            <Delete
                                fontSize="small"
                            />
                        </IconButton>
                    }
                    {
                        (!showMode && certificate.lockedBy) &&
                        <Lock
                            titleAccess={`${langStrings.oftenUsed.lockedBy}: "${certificate.lockedBy}"`}
                            fontSize="small"
                        />
                    }
                </>
            }
        >
            {certificate.title}
            {
                certificate.id === undefined &&
                <NewBadge />
            }
        </Item>
        return certDom;
    });

    return (
        <>
            <div
                className={'certificatesInput ' + (size)}
                style={{
                    border: showMode ? 'none' : '',
                    opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto'
                }} >
                <div className="certificates">
                    {certificatesDOM}
                </div>
                {
                    (!showMode && !disabled) &&
                    <div className="newCertificateRow"
                    >
                        <CertificateSearchBox
                            newItem={newCertificate}
                            allowNew
                            size="small"

                            clearOnSelect
                            filteredAllCertificates={filteredAllCertificates}
                            onSelectedCertificate={(selectedCertificate) =>
                            {
                                if (selectedCertificate.title === "") return
                                const newSelectedCertificates = selectedCertificates.map(e => Object.assign({}, e));
                                newSelectedCertificates.push(selectedCertificate);
                                newSelectedCertificates.sort((a, b) => a.title.localeCompare(b.title));
                                onChange(newSelectedCertificates);
                                setNewCertificate({ title: "" });
                            }}
                        />
                        <Button
                            size="verysmall"
                            onClick={() =>
                            {
                                if (newCertificate.title !== '')
                                {
                                    const newCertificates = selectedCertificates.slice();
                                    newCertificates.push(newCertificate as ICertificateEmployeeDTO);
                                    onChange(newCertificates);
                                    setNewCertificate({ title: '' });
                                    if (newCertificateError) setNewCertificateError(false);
                                    const sug = document.getElementById('searchCertificateBoxInput') as HTMLElement
                                    if (sug) sug.focus();
                                }
                                else
                                {
                                    setNewCertificateError(true);
                                }
                            }}
                            icon={<Add />}
                            disabled={newCertificate.title.trim() === ''}
                            text="Add"
                        />
                    </div>
                }
                {newCertificateError && <span style={{ order: 9999 }}>You need a certificate name!</span>}
            </div>
            {helperTextElement}
        </>
    );
}

export default CertificatesInput;