import { gql } from "@apollo/client";
import IWhoIAm from "@sharedInterfaces/IWhoIAm";
import { findMatchingLanguage } from "@sharedInterfaces/Language/languageHelper";
import store from "@store/store";
import { setOUs } from "@store/reducer/OUsReducer";
import { setCompanyId, setCompanyName, setCompanyAllCompetences, setCompanyAllSkills, setCompanyAllCertificates, setCompanySkillCategories, setCompanyAllEmployees, setCompanyStyle, setCompanyVersions, setCompanyAllRoles, setCompanyOpportunitySettings } from "@store/reducer/companyReducer";
import { setEmployeeLang, setEmployeeStore } from "@store/reducer/employeeReducer";
import { setEmployeeSettings, setLanguage } from "@store/reducer/employeeSettingsReducer";
import { setLevelDefinition } from "@store/reducer/levelDefinitionReducer";
import { setPermissions } from "@store/reducer/permissionsReducer";
import { setUser } from "@store/reducer/userReducer";
import { employeeGQL } from "@src/Objects/Employee";
import WhoIAm from "@src/Objects/WhoIAm";
import { setAdminNotifications, setEmployeeNotifications } from "@store/reducer/notificationsReducer";

import { employeeCoverageGql } from "../Opportunity/getOpportunity";
import { entitiesGql } from "../Company/getCompanySettings";
import GraphQL from "../graphQL";

import { emailSubscriptions } from "./setEmployeeEmailSettings";


const q = gql`
query whoIAm($smallSkill: Int!, $smallCompetence: Int!, $smallCertificate: Int!, $smallEmployee: Int!, $smallRole: Int!) {
    whoIAm(smallSkill: $smallSkill, smallCompetence: $smallCompetence, smallCertificate: $smallCertificate, smallEmployee: $smallEmployee, smallRole: $smallRole) {
        user {
            employeeId,
            companyId,
            confirmed,
            email,
        },
        employee ${employeeGQL},
        smallCompetences {
            id
            title
            levels {
                id
                title
                skills {
                    id
                    title
                    level
                }
                certificates {
                    id
                    title
                }
            }         
        }
        smallSkills {
            title,
            id,
            categoryId,
            otherNames,
        },
        smallCertificates {
            id,
            title,
            durationInDays,
            otherNames,
        },
        smallEmployees {
            id,
            title,
            email,
            pictureId,
            availability{
                availability
                workDayCount
            }
        }
        smallRoles{
            id
            title
            otherNames
        }
        skillCategories {
            id,
            title,
            description,
            parentCategory,
        },
        companyTitle,
        style {
            primary,
            onPrimary,
            secondary,
            onSecondary,
            logoId,
        },
        OUs {
            id,
            parentOU,
            title,
            leader,
            employees,
        },
        level {
            level,
            title,
            description,
            helperText,
        },
        employeeSettings{
            hiddenProjects,
            firstExperience,
            competenceSettings{
                hiddenCompetences
                wantedCompetences{
                    id,
                    level,
                    untilDate,
                    createdAt,
                    state, 
                    initialCompareState
                    ${employeeCoverageGql}
                }
            }
            startPage
            lastForecastDate
            lastSkillUpdate
            goals {
                id
                title
                description
                skills{
                    id
                    title
                    level
                }
                certificates{
                    id
                    title
                }
                untilDate
                createdAt
                state
                initialCompareState
                ${employeeCoverageGql}
            }
            emailSettings{
                subscribeAll
                subscribeAllNotifications
                subscriptions
                ${emailSubscriptions}
                notificationSubscriptions
                ${emailSubscriptions}
            }
        }
        permissions{
            admin,
            ${entitiesGql}
        }
        unknownSkillCount
        language
        opportunitySettings{
            statusOptions{
                id
                title
                isActive
            }
        }
        versions{
            smallCompetence
            smallSkill
            smallCertificate
            smallEmployee 
            smallRole
        }
        notifications {
            id
            notificationType
            title
            message
            messageHTML
            version
            publishedAt
        }
        adminNotifications {
            id
            notificationType
            title
            message
            messageHTML
            version
            publishedAt
        }
    },
},
`;

export default async function getWhoIAm(callback: (MySelf: WhoIAm | null) => void)
{
    const versions = store.getState().company.versions
    const variables = {
        smallSkill: versions.smallSkill ? versions.smallSkill : 0,
        smallCompetence: versions.smallCompetence ? versions.smallCompetence : 0,
        smallCertificate: versions.smallCertificate ? versions.smallCertificate : 0,
        smallEmployee: versions.smallEmployee ? versions.smallEmployee : 0,
        smallRole: versions.smallRole ? versions.smallRole : 0,
    };
    return GraphQL.query<{ whoIAm: IWhoIAm }>(
        q,
        variables,
        (obj) =>
        {
            if (obj && obj.whoIAm)
            {
                const whoIAm = new WhoIAm(obj.whoIAm);
                const state = store.getState();

                store.dispatch(
                    setEmployeeStore({
                        ...whoIAm.employee,
                        language: whoIAm.language ? whoIAm.language : findMatchingLanguage(navigator.languages),
                        unknownSkillCount: whoIAm.unknownSkillCount !== null ? whoIAm.unknownSkillCount : state.employee.unknownSkillCount,
                    })
                );
                store.dispatch(setPermissions(whoIAm.permissions));
                store.dispatch(setUser(whoIAm.user));
                store.dispatch(setLevelDefinition(whoIAm.level));
                store.dispatch(setCompanyId(whoIAm.user.companyId));
                store.dispatch(setCompanyName(whoIAm.companyTitle));
                store.dispatch(setCompanySkillCategories(whoIAm.skillCategories));
                store.dispatch(setCompanyStyle(whoIAm.style));
                store.dispatch(setOUs(whoIAm.OUs));
                store.dispatch(setEmployeeSettings(whoIAm.employeeSettings));
                store.dispatch(setCompanyVersions(whoIAm.versions));
                store.dispatch(setCompanyOpportunitySettings(whoIAm.opportunitySettings));

                store.dispatch(setLanguage(whoIAm.language));
                if (whoIAm.language)
                {
                    store.dispatch(setEmployeeLang(whoIAm.language));
                }

                if (whoIAm.smallCompetences)
                    store.dispatch(setCompanyAllCompetences(whoIAm.smallCompetences));
                if (whoIAm.smallSkills)
                    store.dispatch(setCompanyAllSkills(whoIAm.smallSkills));
                if (whoIAm.smallCertificates)
                    store.dispatch(setCompanyAllCertificates(whoIAm.smallCertificates));
                if (whoIAm.smallEmployees)
                    store.dispatch(setCompanyAllEmployees(whoIAm.smallEmployees));
                if (whoIAm.smallRoles)
                    store.dispatch(setCompanyAllRoles(whoIAm.smallRoles));

                // Dispatch notifications
                if (whoIAm.notifications)
                    store.dispatch(setEmployeeNotifications(whoIAm.notifications));
                if (whoIAm.adminNotifications)
                    store.dispatch(setAdminNotifications(whoIAm.adminNotifications));

                return callback(whoIAm);
            }
            callback(null);
        }
    );
}
