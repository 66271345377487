import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import './OrgUnitCard.css';
import { IOrgUnitDetailDTO } from '@sharedInterfaces/IOrgUnit';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { generateFullName } from '@sharedInterfaces/globalHelper';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import CardBox from '@src/Components/CardBox/CardBox';

interface ProjectCardProps
{
    entity: IOrgUnitDetailDTO
}

/**
 * OrgUnitCard component.
 *
 * @param {ProjectCardProps} props - The props for the OrgUnitCard component.
 * @returns {JSX.Element} - The rendered OrgUnitCard component.
 */
function OrgUnitCard(props: ProjectCardProps)
{
    const navigate = useNavigate();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;

    const entity = props.entity;
    return (
        <CardBox
            title={entity.title}
            className={'projectCard'}
            onClick={() =>
            {
                navigate(`/${ELinks.PEOPLE_ORGUNITS}/${entity.id}`)
            }}
        >
            <div>
                <Typography variant='body1'>{langStrings.leader}: {generateFullName(entity.leader.firstname, entity.leader.lastname)}</Typography>
            </div>
            {
                (entity.employees?.length > 0) &&
                <div>
                    <Typography variant="body1"  >{langStrings.employees}:</Typography>
                    {entity.employees.map(emp => <div key={emp.id}>
                        <Typography variant='body2'>{generateFullName(emp.firstname, emp.lastname)}</Typography>
                    </div>)}
                </div>
            }


        </CardBox>
    );
}

export default OrgUnitCard;
