import React, { useRef } from 'react';
import './CompetenceRateBox.css';
import { ICompetenceLevel } from '@sharedInterfaces/ICompetence';
interface CompetenceRateBoxProps
{
    level: number
    levels: ICompetenceLevel[]
    name: string
    setValue: (number: number) => void;
}

function CompetenceRateBox(props: CompetenceRateBoxProps)
{
    const elementRef = useRef<HTMLDivElement>(null);
    const maxLevel = props.levels.length;
    const options = [];
    for (let i = 0; i < maxLevel; i++)
    {
        const title = props.levels[i].title;
        const btnLevel = i + 1;
        options.push(
            <option
                title={title}
                key={btnLevel}
                value={btnLevel}
                style={{ "--percent": (btnLevel / maxLevel) * 100 } as React.CSSProperties}
            >{title}</option>
        );
    }
    return (
        <div className={"CompetenceRateBox"} ref={elementRef}>
            <select
                className='competenceRateBoxInputElement'
                value={props.level}
                onChange={(e) => props.setValue(parseInt(e.target.value))}
                style={{ "--percent": (props.level / maxLevel) * 100 } as React.CSSProperties}
            >
                {
                    props.level === 0 && <option
                        title=''
                        key={0}
                        value={0}></option>
                }
                {options}
            </select>

        </div>
    );
}

export default CompetenceRateBox;
