import React from 'react';
import './AiConfirmation.css';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { calculateTokens } from '@sharedInterfaces/openAi';
import { MAX_CONTENT_LENGTH } from '@src/Components/Dialogs/AnalyseSkillsDialog/AnalyseCvDialog';
import { Typography } from '@mui/material';

interface AiConfirmationProps
{
    originalText: string
    confirmed: boolean
    setText: (text: string) => void
    setConfirmed: (confirmed: boolean) => void
}

/**
 * AiConfirmation component.
 *
 * @param {AiConfirmationProps} props - The props for the AiConfirmation component.
 *
 * @returns {JSX.Element} The rendered AiConfirmation component.
 */
function AiConfirmation(props: AiConfirmationProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).analyseDialog;
    const [text, setText] = React.useState(props.originalText as string);

    React.useEffect(() =>
    {
        // var t = props.originalText.trim().replace(/[\s]{2,}/gm, ' ');
        // t = t.replace(/(?:\r\n|\r|\n)/gm, '');

        setText(props.originalText.replaceAll('\n', '<br />'));
    }, [props.originalText]);

    const textTokens = calculateTokens(text.replaceAll('<br />', '\n'));
    // let model = EModel.gpt_4o;
    const staticPromitTokens = 1500;
    // const completionTokens = 2500;
    const tokens = textTokens + staticPromitTokens;
    // model = checkAndUpdateModel(model, textTokens, staticPromitTokens, completionTokens)

    // const price = calculatePrice(model, textTokens, staticPromitTokens, completionTokens);

    return (
        <React.Fragment>
            <div className="aiWarning">
                <input
                    type="checkbox"
                    name="aiConfirmation"
                    // suppressContentEditableWarning={true}
                    checked={props.confirmed ? true : false}
                    // value={aiConfirmation ? "true" : 'false'}
                    onChange={(e) =>
                    {
                        props.setConfirmed(e.target.checked);
                    }}
                    alt={langStrings.aiConfirmation} />
                {langStrings.aiConfirmation}
            </div>
            <div
                className="aiConfirmationSearchText"
                contentEditable="true"
                suppressContentEditableWarning={true}
                onBlur={(e) =>
                {
                    // setHtmlText(e.currentTarget.innerHTML as string);
                    const text = e.currentTarget.innerText as string;

                    setText(text.replaceAll('\n', '<br />'));
                    props.setText(text);
                    // onConfirmed(e.currentTarget.textContent);
                    props.setConfirmed(false);
                }}
                dangerouslySetInnerHTML={{ __html: text }}
            >

            </div>
            <Typography variant='caption' style={{
                display: 'block',
                margin: '0px 3px 5px 3px',
                textAlign: 'center',
            }}>
                {`${langStrings.character}: ${text.replaceAll('<br />', '\n').length} / ${MAX_CONTENT_LENGTH
                    } Tokens: ${tokens}`}
                {/* <br />
                {langStrings.price}: {price}€
                <br />
                Model: {model} */}
            </Typography>
            <div className="aiWarning">
                <input
                    type="checkbox"
                    name="aiConfirmation"
                    // suppressContentEditableWarning={true}
                    checked={props.confirmed ? true : false}
                    // value={aiConfirmation ? "true" : 'false'}
                    onChange={(e) =>
                    {
                        props.setConfirmed(e.target.checked);
                    }}
                    alt={langStrings.aiConfirmation} />
                {langStrings.aiConfirmation}
            </div>
        </React.Fragment>
    );
}

export default AiConfirmation;
