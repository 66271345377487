import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { Close, Update } from '@mui/icons-material';
import { AppState } from '@store/store';
import packageJson from '@src/../package.json';
import { stage } from '@src/globals';
import Button from '@sharedReact/General/Button/Button';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

import Dialog from '../Dialog/Dialog';

const possibleStatuses = [
    'registered',
    'installing',
    'waiting',
    'active',
    'redundant'
];

export default function VersionDialogButton()
{
    const { openDialog } = useDialog();
    const width = useSelector((state: AppState) => state.windowSize.width);

    return (
        <>
            <span className='version' onClick={() =>
            {
                openDialog(<VersionDialog
                    id="VersionDialog"
                />)
            }}>
                {`${width > 500 ? 'Version' : 'V'} ${packageJson.version}`}
            </span>

        </>
    );
}


interface VersionDialogProps
{
    id: string;
    resolve?: () => void
}

function VersionDialog({ id, resolve }: VersionDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).layout;


    const [support, setSupport] = useState(false);
    const [status, setStatus] = useState(langStrings.checking);
    const [scope, setScope] = useState("");
    const [clientsCount, setClientsCount] = useState(0);
    const [version] = useState(packageJson.version);
    const [cacheVersion, setCacheVersion] = useState(null);

    useEffect(() =>
    {
        if ('serviceWorker' in navigator)
        {
            setSupport(true);
            navigator.serviceWorker.getRegistration().then(reg =>
            {
                if (reg)
                {
                    setStatus(reg.active?.state ?? langStrings.oftenUsed.unknown);
                    setScope(reg.scope);
                    navigator.serviceWorker.getRegistrations().then(regs =>
                    {
                        let count = 0;
                        regs.forEach(r =>
                        {
                            count += r.scope ? 1 : 0;
                        });
                        setClientsCount(count);
                    });
                }
                if (reg && reg.active)
                {
                    const messageChannel = new MessageChannel();
                    messageChannel.port1.onmessage = (event) =>
                    {
                        if (event.data.type === 'CACHE_VERSION')
                        {
                            setCacheVersion(event.data.cacheVersion);
                        }
                    };
                    reg.active.postMessage({ type: 'GET_CACHE_VERSION' }, [messageChannel.port2]);
                }
            });
        }
    }, []);


    // Informationen über den Browser
    const browserInfo = `
    App Name: ${navigator.appName}
    App Version: ${navigator.appVersion}
    User Agent: ${navigator.userAgent}
  `;
    const onClose = () =>
    {
        resolve && resolve();
    };
    return <Dialog
        id={id}
        onClose={onClose}
        title={langStrings.versionDialog}
        footer={<Button size={'normal'} text={langStrings.oftenUsed.close} icon={<Close />}
            onClick={onClose}
        />}>
        {/* TODO: TRANSLATION */}
        <h3>App-Version: {version}</h3>
        <h3>Cache-Version: {cacheVersion}</h3>
        <h3>{langStrings.stage}: {stage}</h3>
        <h3>Service Worker</h3>
        <p >Service Worker Support: {support ? langStrings.oftenUsed.yes : langStrings.oftenUsed.no}</p>
        <p
            title={`${langStrings.possibleServiceWorkerStatuses}: ${possibleStatuses.join(', ')}`}
        >{langStrings.currentStatus}: {status}</p>
        <p>{langStrings.scope}: {scope}</p>
        <p>{langStrings.numberOfConnectedClients}: {clientsCount}</p>
        <Button size={'normal'} text={'Check for Update'} icon={<Update />} onClick={singleUpdateCheck.bind(null, true)} />
        <h3>{langStrings.browserInformations}</h3>
        <pre>{browserInfo}</pre>
    </Dialog>
}

const singleUpdateCheck = async (clearCache: boolean = false) =>
{
    if (clearCache && 'caches' in window)
    {
        await caches.keys().then(cacheNames =>
        {
            return Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
        });
    }
    if ('serviceWorker' in navigator)
    {
        navigator.serviceWorker.getRegistration().then(registration =>
        {
            if (registration)
            {
                registration.update().then(() =>
                {
                    const waitingWorker = registration.waiting;
                    if (waitingWorker)
                    {
                        navigator.serviceWorker.addEventListener('controllerchange', () =>
                        {
                            window.location.reload();
                        });
                        waitingWorker.postMessage({ type: 'SKIP_WAITING' });
                    }
                });
            }
        });
    }
};

export const checkForServiceWorkerUpdate = () =>
{
    setTimeout(singleUpdateCheck, 2500)
    setTimeout(singleUpdateCheck, 5000)
    setTimeout(singleUpdateCheck, 10000)
}