import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import { Add, Check, Delete, Settings } from '@mui/icons-material';
import { calculateEmployeeCoverage, calculateLearningProgress } from '@sharedInterfaces/globalHelper';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { IEmployeeSettingsGoal } from '@sharedInterfaces/IEmployeeSettings';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeCoverage } from '@sharedInterfaces/IOpportunity';
import { AppState } from '@store/store';
import { setGoals } from '@store/reducer/employeeSettingsReducer';
import deleteGoal from '@src/APIs/graphQl/Employee/Goals/deleteGoal';
import updateGoalState from '@src/APIs/graphQl/Employee/Goals/updateGoalState';
import { useConfirmDeleteDialog } from '@src/Components/Dialogs/ConfirmDelete/confirmDelete';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

import RowElement from '../../sharedReact/General/Forms/RowElement/RowElement';
import Row from '../../sharedReact/General/Forms/Row/Row';
import { EmployeeCoverage } from '../../Components/Opportunities/EmployeeSuggestions/EmployeeCoverage';
import CardBox from '../../Components/CardBox/CardBox';
import { GoalDialog } from '../../Components/Dialogs/GoalDialog/GoalDialog';
import FormatedTextInput from '../../Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import Button from '../../sharedReact/General/Button/Button';
import { useErrorDialog } from '../../sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import styles from './DevelopmentTab.module.css';
import { fireworkSalvo } from './fireworkSalvo';

/**
 * Goals
 * @returns {JSX.Element}
 */
export function Goals(): JSX.Element
{
    const { openDialog } = useDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employeeDevelopment;
    const goals = useSelector((state: AppState) => state.employeeSettings.goals);
    const offline = useSelector((state: AppState) => state.client.offline);

    return (
        <>
            <CardBox title={langStrings.goalsTitle}
                rightButtons={
                    <IconButton
                        onClick={() =>
                        {
                            openDialog(
                                <GoalDialog
                                    id="GoalDialog"
                                />
                            )
                        }}
                        disabled={offline}
                    >
                        <Add />
                    </IconButton>
                }
            >
                <Typography variant='subtitle2'>{langStrings.goalsHint}</Typography>
                <Container>
                    {goals
                        .filter(goal => goal.state !== 'done')
                        .map(goal => <GoalCard key={goal.id} goal={goal} />)}
                </Container>
            </CardBox>
        </>
    );
}
/**
 * Renders a goal card component.
 *
 * @param {Object} goal - The goal object to be rendered.
 * @returns {JSX.Element} - The rendered goal card component.
 */
export function GoalCard({ goal }: { goal: IEmployeeSettingsGoal; }): JSX.Element
{
    const { openDialog } = useDialog();
    const confirmDelete = useConfirmDeleteDialog();
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employeeDevelopment;
    const goals = useSelector((state: AppState) => state.employeeSettings.goals);
    const offline = useSelector((state: AppState) => state.client.offline);

    const empSkills = useSelector((state: AppState) => state.employee.skills);
    const empCertificates = useSelector((state: AppState) => state.employee.certificates);
    const [score, setScore] = useState<number>(calculateLearningProgress(empSkills, goal.initialCompareState, goal.skills));
    const [coverage, setCoverage] = useState<IEmployeeCoverage>(
        calculateEmployeeCoverage(
            goal.skills,
            empSkills,
            goal.certificates,
            empCertificates,
            [],
            [],
        )
    );

    useEffect(() =>
    {
        setScore(calculateLearningProgress(empSkills, goal.initialCompareState, goal.skills));
        setCoverage(
            calculateEmployeeCoverage(
                goal.skills,
                empSkills, goal.certificates,
                empCertificates,
                [],
                [],
            )
        )
    }, [empSkills, empCertificates]);

    // useEffect(() =>
    // {
    //     if (score >= 1 && originalScoreRef.current < 1)
    //     {
    //         // fireworkSalvo();
    //     }
    // }, [score, originalScoreRef]);
    const onConfirm = () =>
    {
        fireworkSalvo();
        updateGoalState(goal.id)
            .then(() =>
            {
                const newGoals = [...goals]
                const foundGoal = newGoals.find(wc => wc.id === goal.id);
                if (foundGoal)
                {
                    foundGoal.state = 'done';
                    dispatch(setGoals(newGoals))
                }
            })
            .catch(ex =>
            {
                showErrorDialog(ex);
            })
    };

    const onDelete = () =>
    {
        confirmDelete(`${langStrings.goal} - ${goal.title}`)
            .then(() =>
            {
                deleteGoal(goal.id)
                    .then(() =>
                    {
                        dispatch(setGoals(goals.filter(wc => wc.id !== goal.id)))
                    })
                    .catch(ex =>
                    {
                        showErrorDialog(ex);
                    })
            });
    };
    const onConfigure = () =>
    {
        openDialog(
            <GoalDialog
                id="GoalDialog"
                goal={goal}
            />
        )
    };
    return (
        <CardBox
            title={`${goal.title}`}
        >
            <div className='competenceGoalCard'>
                <Row>
                    <RowElement alignTitle='center' title={langStrings.oftenUsed.createdAt}>
                        {goal.createdAt.toLocaleDateString()}
                    </RowElement>
                    <RowElement alignTitle='center' title={langStrings.untilDate}>
                        {goal.untilDate.toLocaleDateString()}
                    </RowElement>
                </Row>
                <RowElement alignTitle='center' title={langStrings.learnProgress}>
                    <LinearProgressWithLabel
                        color={score < 0.95 ? "primary" : "success"}
                        variant="determinate"
                        value={score * 100}
                        sx={{
                            width: "100%",
                            height: 7,
                            borderRadius: 7
                        }} />
                </RowElement>

                {(goal.description && goal.description !== "") ?
                    <FormatedTextInput value={goal.description} readonly={true} /> :
                    undefined}
                <EmployeeCoverage expended suggestedEmployee={coverage} hideName hideAvailability />
                <div className={styles.buttons}>
                    <Button
                        helpText={langStrings.oftenUsed.delete}
                        color={'red'}
                        disabled={offline}
                        text={langStrings.oftenUsed.delete}
                        icon={<Delete />}
                        onClick={onDelete} />
                    <Button
                        text={langStrings.oftenUsed.edit}
                        icon={<Settings />}
                        disabled={offline}
                        onClick={onConfigure} />
                    {score >= 1 &&
                        <Button
                            text={langStrings.confirmHideGoal}
                            icon={<Check />}
                            color={'green'}
                            onClick={onConfirm} />}
                </div>
            </div>
        </CardBox>
    );
}
