import React from 'react';
import { Tooltip, IconButton, Divider, ListItemIcon, Menu, MenuItem, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { BugReport, Edit, Email, Login, Logout, Person, Psychology, Settings } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { generateFullName } from '@sharedInterfaces/globalHelper';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ELinks } from '@sharedInterfaces/globalEnums';
import store, { AppState } from '@store/store';
import { isLoggedIn, setSecret } from '@store/reducer/clientReducer';
import { stageContext } from '@src/globals';
import './UserButton.css';
import logoutClient from '@src/APIs/graphQl/Company/logout';

import { EmployeePicture } from '../EmployeePicture/EmployeePicture';
import { useLevelDefinitionsDialog } from '../Dialogs/LevelDefinitionsDialog/LevelDefinitionsDialog';
const clientId = `client_id=${stageContext.cognitoClientId}`
const responseType = 'response_type=token'
const scope = 'scope=email+openid+phone'
const baseUrl = stageContext.cognitoHostedURL;
const logoutHostname = stageContext.logoutHostname ? stageContext.logoutHostname : window.location.hostname;

/**
 * Escapes the given host name.
 * 
 * @param {string} hostName - The host name to escape.
 * @returns {string} The escaped host name.
 */
function escapeHostName(hostName: string): string
{
  if (hostName === 'localhost')
  {
    return encodeURIComponent('http://localhost:3000/');
  }
  return encodeURIComponent(`https://${hostName}/`);
}

/**
 * Generates a login URL.
 * 
 * @param {string} hostName - The host name.
 * @returns {string} The login URL.
 */
export function createLoginUrl(hostName: string): string
{
  return `${baseUrl}/oauth2/authorize?${clientId}&${responseType}&${scope}&redirect_uri=${escapeHostName(hostName)}${ELinks.LOGGEDIN}`;
}


/**
 * Generates a logout URL.
 * @param {string} hostName - The host name. 
 * @returns {string} - The logout URL.
 */
export function createLogoutUrl(): string
{
  return `${baseUrl}/logout?${clientId}&${responseType}&${scope}&logout_uri=${escapeHostName(logoutHostname)}${ELinks.LOGGEDOUT}`;
}

export const logout = async () =>
{
  const logoutPromise = logoutClient()
    .catch(err => console.error(err)).finally(() => { store.dispatch(setSecret(null)) });
  const cookies = localStorage.getItem("cookies");
  localStorage.clear();
  cookies && localStorage.setItem("cookies", cookies);
  if ('caches' in window)
  {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
  }
  const success = await logoutPromise;
  if (!success) alert("Fehler beim ausloggen"); //TODO: Rausnehmen

  localStorage.removeItem('redirect');
  window.location.href = createLogoutUrl();
  localStorage.removeItem('redirect');
}

/**
 * Represents a UserButton component.
 */
function UserButton()
{
  const showLevelDefinitionsDialog = useLevelDefinitionsDialog();

  const navigate = useNavigate();
  const lang = useSelector((state: AppState) => state.employee.language);
  const langStrings = getLanguageTexts(lang).navigation;
  const employee = useSelector((state: AppState) => state.employee);
  const permissions = useSelector((state: AppState) => state.permissions);
  const offline = useSelector((state: AppState) => state.client.offline);
  const loggedIn = useSelector((state: AppState) => isLoggedIn(state.client.secret));

  const loginLink = createLoginUrl(window.location.hostname);
  // const [showBox, setShowBox]: [boolean, (showBox: boolean) => void] = React.useState(false as boolean);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
  {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () =>
  {
    setAnchorEl(null);
  };
  const onLogout = () =>
  {
    logout()
    handleClose();
  }
  const onMyProfile = () =>
  {
    navigate(`/${ELinks.PROFILE}`)
    handleClose();
  }
  const onEditMyProfile = () =>
  {
    navigate(`/${ELinks.PROFILE_EDIT}`)
    handleClose();
  }
  const onSettings = () =>
  {
    navigate(`/${ELinks.SETTINGS}`)
    handleClose();
  }
  const onLogin = () =>
  {
    navigate(loginLink)
    handleClose();
  }
  const onBugReport = () =>
  {
    window.open(ELinks.BUGREPORT, '_blank');
  }
  const onEmailSettings = () =>
  {
    navigate(`/${ELinks.EMAIL_SETTINGS}`)
    handleClose();
  }

  const onShowLevelDefinitions = showLevelDefinitionsDialog

  if (!loggedIn)
    return (
      <Link to={loginLink} >
        <div className="btn_profile">
          <Login />
        </div>
      </Link>
    );

  return <React.Fragment>
    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

      <Tooltip title={employee ? generateFullName(employee.firstName, employee.lastName) : ''}>
        <IconButton
          style={{ marginRight: 15, marginLeft: 2 }}
          onClick={handleClick}
          size='small'
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <EmployeePicture avatarSize={38} employee={employee} />

        </IconButton>
      </Tooltip>
    </Box>
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 10,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {employee ?
        <div>
          <MenuItem onClick={onMyProfile}>
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            {langStrings.myProfile}
          </MenuItem>
          <MenuItem onClick={onEditMyProfile}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            {langStrings.editMyProfile}
          </MenuItem>
          {/* Email-Settings */}
          <MenuItem onClick={onEmailSettings}>
            <ListItemIcon>
              <Email fontSize="small" />
            </ListItemIcon>
            {langStrings.emailSettings}
          </MenuItem>
          <Divider />
          <MenuItem onClick={onShowLevelDefinitions}>
            <ListItemIcon>
              <Psychology fontSize="small" />
            </ListItemIcon>
            {langStrings.levelDefinitions}
          </MenuItem>
          <MenuItem onClick={onBugReport}>
            <ListItemIcon>
              <BugReport fontSize="small" />
            </ListItemIcon>
            {langStrings.bugReport}
          </MenuItem>
          {(permissions.admin) &&
            <MenuItem onClick={onSettings}
              disabled={offline}
            >
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              {langStrings.settings}
            </MenuItem>
          }
          <Divider />
          <MenuItem onClick={onLogout}
            disabled={offline}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {langStrings.logout}
          </MenuItem>
        </div>

        :
        <MenuItem onClick={onLogin}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {langStrings.login}
        </MenuItem>
      }
    </Menu>
    {/* {showBox ? <UserButtonBox /> : undefined} */}
  </React.Fragment >
}

export default UserButton;
