import './MenuButton.css';
import { MoreVert } from '@mui/icons-material';
import React from 'react';
import { IconButton } from '@mui/material';

import MenuBox from '../MenuBox/MenuBox';

interface MenuButtonProps
{
    children: React.ReactNode
}

function MenuButton(props: MenuButtonProps)
{
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    return (
        <div>
            <IconButton size='small' onClick={function (e): void
            {
                setAnchorEl(e.currentTarget)
            }}>
                <MoreVert />
            </IconButton>
            {/* <RoundIconButton icon={<MoreVert />} helperText={''} size='small' onClick={function (e): void
            {
                setAnchorEl(e.currentTarget)
            }} /> */}
            {
                anchorEl &&
                <MenuBox anchor={anchorEl} setAnchor={(newAnchor) => setAnchorEl(newAnchor)} >
                    {props.children}
                </MenuBox>
            }
        </div>
    );
}

export default MenuButton;
