import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import './AvailabilitySettings.css';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import AvailabilityMonth from '@src/Objects/AvailabilityMonth';

import CardBox from '../CardBox/CardBox';
import ErrorBox from '../ErrorBox/ErrorBox';

import { MonthBox } from './MonthBox';

function isMoreThanAMonthAgo(lastSkillUpdate: Date): boolean
{
    const today = new Date();
    const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

    return lastSkillUpdate < oneMonthAgo;
}

/**
 * Represents the availability settings component.
 * 
 * @returns {JSX.Element}
 */
function AvailabilitySettings()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).availability;
    const companyId = useSelector((state: AppState) => state.company.companyId);
    const lastSkillUpdate = useSelector((state: AppState) => state.employeeSettings.lastSkillUpdate);

    const needSkillUpdate = isMoreThanAMonthAgo(lastSkillUpdate);

    const [months, setMonths] = React.useState([] as AvailabilityMonth[]);

    React.useEffect(() =>
    {
        if (companyId !== "")
        {
            AvailabilityMonth.getAvailabilityMonths((newMonths) =>
            {
                setMonths(newMonths)
            })
        }
    }, [companyId]);

    return (
        <div className='availabilitySettings'>
            {
                needSkillUpdate &&
                <ErrorBox warning>
                    {langStrings.noSkillUpdateOneMonth}
                </ErrorBox>
            }

            <CardBox title={langStrings.forecastPlanning}>
                <Container>
                    {months.map(month => <MonthBox setMonths={setMonths}
                        key={month.id} month={month} setAvailability={(id, weekArray, type, inputAs) =>
                        {
                            const newMonths = months.map((m) =>
                            {
                                if (m.id === id)
                                {
                                    m.selectionType = type;
                                    m.inputAs = inputAs;
                                }
                                const newAvm = {
                                    ...m,
                                }
                                return new AvailabilityMonth(newAvm);
                            });
                            setMonths(newMonths);
                        }}></MonthBox>)}
                </Container>
            </CardBox>
        </div>
    );
}
export default AvailabilitySettings;

