import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeSettingsCompetenceSettingsWantedCompetence, IEmployeeSettingsGoal } from '@sharedInterfaces/IEmployeeSettings';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { ISkillLevelCompare } from '@sharedInterfaces/IOpportunity';
import { calculateEmployeeCoverage, calculateLearningProgress } from '@sharedInterfaces/globalHelper';
import { IReference } from '@sharedInterfaces/IReference';
import { AppState } from '@store/store';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

import SuggestedCompetences from '../../Components/SuggestedCompetences/SuggestedCompetences';
import CardBox from '../../Components/CardBox/CardBox';
import { EmployeeCoverage } from '../../Components/Opportunities/EmployeeSuggestions/EmployeeCoverage';
import Row from '../../sharedReact/General/Forms/Row/Row';
import RowElement from '../../sharedReact/General/Forms/RowElement/RowElement';
import { GoalDialog } from '../../Components/Dialogs/GoalDialog/GoalDialog';


import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import { EDPWantedCompetenceCard } from './EDPWantedCompetenceCard';
import { Goals } from './Goals';

/**
 * Function that returns a JSX.Element representing the DevelopmentTab component.
 * @returns {JSX.Element}
 */
export function DevelopmentTab(): JSX.Element
{
    return <>
        <SuggestedCompetences />
        <EmployeeDevelopmentPlanningCard />
    </>;
}
/**
 * This is a component for Employee Development.
 * @returns {JSX.Element}
 */
function EmployeeDevelopmentPlanningCard(): JSX.Element
{
    const { openDialog } = useDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employeeDevelopment;
    const offline = useSelector((state: AppState) => state.client.offline);
    return <>
        <CardBox
            title={langStrings.planningCardTitle}
            rightButtons={
                <IconButton
                    onClick={() =>
                    {
                        openDialog(
                            <GoalDialog
                                id="GoalDialog"
                            />
                        )
                    }}
                    disabled={offline}
                >
                    <Add />
                </IconButton>
            }
        >
            <Typography variant='subtitle2'>{langStrings.planningCardDescription}</Typography>
            <WantedSkills />
            <Container style={{
                marginTop: 10
            }}>
                <Goals />
                <WantedCompetences />
            </Container>
        </CardBox>
    </>;
}

/**
 * Displays the wanted skills.
 * 
 * @returns {JSX.Element} The JSX element that displays the wanted skills.
 */
function WantedSkills(): JSX.Element
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employeeDevelopment;
    const goals = useSelector((state: AppState) => state.employeeSettings.goals)
        .filter(wc => wc.state !== 'done')
    const wantedCompetences = useSelector((state: AppState) => state.employeeSettings.competenceSettings.wantedCompetences)
        .filter(wc => wc.state !== 'done');

    const empSkills = useSelector((state: AppState) => state.employee.skills);
    const empCertificates = useSelector((state: AppState) => state.employee.certificates);
    const skills = mergeWantedSkills(wantedCompetences, goals);
    const certificates = mergeWantedCertificates(wantedCompetences, goals);

    if (skills.length === 0) return <></>

    const { earliestCreatedAt, latestUntilDate } = extractDatesFromWantedCompetences(wantedCompetences);
    const coverage = calculateEmployeeCoverage(
        skills.map(s => ({ title: s.title, level: s.wantedLevel, id: s.id })),
        empSkills,
        certificates.map(c => ({ title: c.title, id: c.id })),
        empCertificates,
        [],
        [],
    )

    const coverageOld = calculateEmployeeCoverage(
        skills.map(s => ({ title: s.title, level: s.wantedLevel, id: s.id })),
        skills.map(s => ({ title: s.title, level: s.level, id: s.id as number })),
        certificates.map(c => ({ title: c.title, id: c.id })),
        certificates.map(c => ({ title: c.title, id: c.id as number })),
        [],
        [],
    )

    const score = calculateLearningProgress(empSkills, coverageOld,
        skills.map(s =>
            ({ title: s.title, level: s.wantedLevel, id: s.id as number })
        )
    )

    return <CardBox title={langStrings.wantedSkillsTitle}>

        <div className='skillsGoalCard'>
            <Typography variant='subtitle2'>{langStrings.wantedSkillsHint}</Typography>
            <Row >
                <RowElement alignTitle='center' title={langStrings.oftenUsed.createdAt} >
                    {earliestCreatedAt.toLocaleDateString()}
                </RowElement>
                <RowElement alignTitle='center' title={langStrings.untilDate} >
                    {latestUntilDate.toLocaleDateString()}
                </RowElement>
            </Row>
            <RowElement alignTitle='center' title={langStrings.learnProgress}>
                <LinearProgressWithLabel
                    color={score < 0.95 ? "primary" : "success"}
                    variant="determinate"
                    value={score * 100}
                    sx={{
                        width: "100%",
                        height: 7,
                        borderRadius: 7
                    }} />
            </RowElement>


            <EmployeeCoverage expended suggestedEmployee={coverage} hideName hideAvailability />
        </div>
    </CardBox>
}

/**
 * Represents the list of wanted competences.
 * 
 * @returns {JSX.Element} The JSX element representing the list of wanted competences.
 */
function WantedCompetences()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employeeDevelopment;
    const wantedCompetences = useSelector((state: AppState) => state.employeeSettings.competenceSettings.wantedCompetences)

    return <CardBox title={langStrings.wantedCompetencesTitle}>
        <Typography variant='subtitle2'>{langStrings.wantedCompetencesHint}</Typography>
        <Container>
            {wantedCompetences
                .filter(wc => wc.state !== 'done')
                .map(wc => <EDPWantedCompetenceCard key={wc.id} wantedCompetence={wc} />)}
        </Container>
    </CardBox>
}
export const mergeWantedSkills = (
    competences: IEmployeeSettingsCompetenceSettingsWantedCompetence[],
    goals: IEmployeeSettingsGoal[]
): ISkillLevelCompare[] =>
{
    const skillMap: { [key: string]: ISkillLevelCompare } = {};

    const updateSkillMap = ({ id, title, wantedLevel, level }: ISkillLevelCompare) =>
    {
        const existingSkill = skillMap[title];

        if (!existingSkill)
        {
            skillMap[title] = { id, title, wantedLevel, level };
        } else
        {
            skillMap[title].wantedLevel = Math.max(existingSkill.wantedLevel, wantedLevel);
            skillMap[title].level = Math.min(existingSkill.level, level);
        }
    };

    const processSkills = (item: IEmployeeSettingsCompetenceSettingsWantedCompetence | IEmployeeSettingsGoal) =>
    {
        item.initialCompareState.coveredSkills.forEach(updateSkillMap);
        item.initialCompareState.missingSkills.forEach(updateSkillMap);
    };

    competences.forEach(processSkills);
    goals.forEach(processSkills);

    return Object.values(skillMap);
};

export const mergeWantedCertificates = (
    competences: IEmployeeSettingsCompetenceSettingsWantedCompetence[],
    goals: IEmployeeSettingsGoal[]
): IReference[] =>
{
    const certificateMap: { [key: string]: IReference } = {};

    const addCertificateToMap = (certificate: IReference) =>
    {
        if (!certificateMap[certificate.title])
        {
            certificateMap[certificate.title] = { ...certificate };
        }
    };

    const processCertificates = (item: IEmployeeSettingsCompetenceSettingsWantedCompetence | IEmployeeSettingsGoal) =>
    {
        item.initialCompareState.coveredCertificates?.forEach(addCertificateToMap);
        item.initialCompareState.missingCertificates?.forEach(addCertificateToMap);
    };

    competences.forEach(processCertificates);
    goals.forEach(processCertificates);

    return Object.values(certificateMap);
};



interface DateResult
{
    earliestCreatedAt: Date;
    latestUntilDate: Date;
}

export const extractDatesFromWantedCompetences = (
    wantedCompetences: IEmployeeSettingsCompetenceSettingsWantedCompetence[]
): DateResult =>
{
    let earliestCreatedAt: Date = new Date(2223, 1, 1);
    let latestUntilDate: Date = new Date(1970, 1, 1);

    wantedCompetences.forEach((competence) =>
    {
        if (competence.createdAt < earliestCreatedAt)
        {
            earliestCreatedAt = new Date(competence.createdAt);
        }
        if (competence.untilDate > latestUntilDate)
        {
            latestUntilDate = new Date(competence.untilDate);
        }
    });

    return {
        earliestCreatedAt,
        latestUntilDate,
    };
};