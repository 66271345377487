import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Typography } from '@mui/material';
import InnerLayout from '@src/sharedReact/Layouts/InnerLayout/InnerLayout';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import { isLoggedIn } from '@store/reducer/clientReducer';

import { createLoginUrl } from '../Components/UserButton/UserButton';



/**
 * Represents the LoginPage component.
 * 
 * @returns {JSX.Element} The rendered LoginPage component.
 */
function LoginPage()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const startPage = useSelector((state: AppState) => state.employeeSettings.startPage);
    const langStrings = getLanguageTexts(lang).loggedin;

    const loggedIn = useSelector((state: AppState) => isLoggedIn(state.client.secret));
    const loginUrl = createLoginUrl(window.location.hostname);
    if (loggedIn)
    {
        location.pathname = ELinks[startPage];
    } else
    {
        location.href = loginUrl;
    }

    return (
        <InnerLayout title={langStrings.title}>
            <NavLink to={loginUrl}>
                <Typography variant="body1">
                    {langStrings.redirect}
                </Typography>
            </NavLink>

            <NavLink to={loginUrl}>Login</NavLink>
        </InnerLayout>
    );
}

export default LoginPage;
