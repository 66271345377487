import IOpportunity, { IEmployeeCoverage, IOpportunityCertificate, IOpportunityCompetenceReference, IOpportunityRole } from "@sharedInterfaces/IOpportunity";
import { EEntityType } from "@sharedInterfaces/globalEnums";

import ISkill from "../interfaces/ISkill";
import { Entity } from "../sharedReact/Objects/Entity";

import { validateDate } from "./Helper";


export default class Opportunity extends Entity implements IOpportunity
{

    type: EEntityType = EEntityType.OPPORTUNITY;
    externalId: string;
    projectStart: Date | undefined;
    projectEnd: Date | undefined;
    publishDate: Date | undefined;
    deadline: Date | undefined;
    dayPrice: number;
    loc: string;
    customer: string;
    partner: string;
    sourceName: string;
    sourceURL: string;
    utilization: number;
    remote: number;
    headCount: number;
    skills: ISkill[];
    requestText: string;
    selectedEmployees: IEmployeeCoverage[];
    // active: boolean;
    competences: IOpportunityCompetenceReference[];
    certificates: IOpportunityCertificate[];
    roles: IOpportunityRole[];
    primaryRole: IOpportunityRole | null;
    status: string;
    constructor(obj: IOpportunity)
    {
        super(obj);
        this.externalId = obj.externalId;
        this.dayPrice = obj.dayPrice;
        this.loc = obj.loc;
        this.customer = obj.customer;
        this.partner = obj.partner;
        this.sourceName = obj.sourceName;
        this.sourceURL = obj.sourceURL;
        this.utilization = obj.utilization;
        this.remote = obj.remote;
        this.headCount = obj.headCount;
        this.skills = obj.skills;
        this.requestText = obj.requestText;
        this.selectedEmployees = obj.selectedEmployees;
        this.projectStart = validateDate(obj.projectStart)
        this.projectEnd = validateDate(obj.projectEnd)
        this.publishDate = validateDate(obj.publishDate)
        this.deadline = validateDate(obj.deadline)
        // this.active = obj.active;
        this.competences = obj.competences;
        this.certificates = obj.certificates;
        this.roles = obj.roles;
        this.primaryRole = obj.primaryRole;
        this.status = obj.status;
    }

    async delete()
    {
        const { default: deleteOpportunity } = await import("@src/APIs/graphQl/Opportunity/deleteOpportunity");
        return deleteOpportunity(this.id);
    }
}
