import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { Edit, Done } from '@mui/icons-material';
import { IOUAvailabilityDataEmployeesDTO, } from '@sharedInterfaces/IOrgUnit';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import TextInput from '@src/Components/formsControls/inputs/TextInput/TextInput';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import EmployeeOverlay from '@src/Components/Overlays/EmployeeOverlay/EmployeeOverlay';
import LegendBox from '@src/Components/Legend/LegendBox';
import DataTable, { IDataTableField, SortOrder, } from '@src/App/NewLayout/Components/DataTable/DataTable';
import updateSalesIdea from '@src/APIs/graphQl/Employee/updateSalesIdea';

import { AvailabilityGraph } from './AvailabilityGraph';

interface AvailabilitiesContentProps
{
    allowSales: boolean
    availabilityData: IOUAvailabilityDataEmployeesDTO | null
}

/**
 * Renders the AvailabilitiesContent component.
 *
 * @param {AvailabilitiesContentProps} allowSales - Flag to allow sales.
 * @param {AvailabilitiesContentProps} availabilityData - The availability data.
 * 
 * @returns {JSX.Element} The rendered AvailabilitiesContent component.
 */
export function AvailabilitiesContent({ allowSales, availabilityData }: AvailabilitiesContentProps)
{
    const monthOptions: Intl.DateTimeFormatOptions = { month: 'long' };
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;
    const width = useSelector((state: AppState) => state.windowSize.width);
    const isDense = (width: number) => width <= 1000;
    const [searchText, setSearchText] = React.useState<string>("");
    const [salesIdeas, setSalesIdeas] = React.useState<{ [key: number]: string }>({});
    const [salesIdeasEdit, setSalesIdeasEdit] = React.useState<{ [key: number]: boolean }>({});
    const [showWeeks, setShowWeeks] = React.useState<boolean>(false);

    useEffect(() =>
    {
        const newSalesIdeas: { [key: number]: string } = {}
        availabilityData?.employees.forEach(emp =>
        {
            newSalesIdeas[emp.id] = emp.salesIdea;
        });
        setSalesIdeas(newSalesIdeas);
    }, [availabilityData])

    let monthIds: string[] = [];
    if (availabilityData)
    {
        if (availabilityData.employees.length)
        {
            monthIds = availabilityData.employees[0].months.map(m => m.monthId);
        }
    }
    //return <RenderJson data ={availabilityData}/>
    if (!availabilityData) return <LoadingBox />


    const employeeRows = availabilityData.employees
        .filter(emp =>
        {
            return emp.title.toLowerCase().includes(searchText);
        })
        .map(avEmp =>
        {
            const monthFields: { [key: string]: { value: JSX.Element; orderKey: number; } } = {};
            monthIds.forEach((id) =>
            {
                const month = avEmp.months.find(m => m.monthId === id);
                if (!month || !month.weeks?.length)
                {
                    monthFields['monthId' + id] = { orderKey: 0, value: <div title={langStrings.noInformationFound}>N/A</div> };
                }
                else
                {
                    let content: ReactElement | ReactElement[] = <></>
                    const monthWorkDayCount = month.weeks.reduce((hours, week) => hours + week.workDayCount, 0);
                    const monthHolidayHours = month.weeks.reduce((hours, week) => hours + week.holidayHours, 0);
                    const monthPlannedDays = month.weeks.reduce((hours, week) => hours + week.plannedDays, 0);
                    const monthPlannedDaysInternal = month.weeks.reduce((hours, week) => hours + week.plannedDaysInternal, 0);
                    const monthBusyDays = (monthHolidayHours / 8) + (monthPlannedDays) + monthPlannedDaysInternal;
                    const monthFreeDays = monthWorkDayCount - monthBusyDays;
                    const monthPercentAvailability = monthWorkDayCount ? Math.round(monthFreeDays / monthWorkDayCount * 100) : 0;

                    if (!showWeeks)
                    {
                        const percentExternal = monthWorkDayCount ? Math.round(monthPlannedDays / monthWorkDayCount * 100) : 0;
                        const percentInternal = monthWorkDayCount ? Math.round(monthPlannedDaysInternal / monthWorkDayCount * 100) : 0;

                        content = <AvailabilityBar
                            available={monthPercentAvailability}
                            internalProject={percentInternal}
                            externalProject={percentExternal}
                        />
                    } else
                    {
                        content = month.weeks.map((week, index) =>
                        {

                            const workDayCount = week.workDayCount
                            const holidayHours = week.holidayHours
                            const plannedDays = week.plannedDays
                            const plannedDaysInternal = week.plannedDaysInternal
                            const busyDays = (holidayHours / 8) + (plannedDays) + plannedDaysInternal;
                            const freeDays = workDayCount - busyDays;

                            const percentAvailability = workDayCount ? Math.round(freeDays / workDayCount * 100) : 0;
                            const percentExternal = workDayCount ? Math.round(plannedDays / workDayCount * 100) : 0;
                            const percentInternal = workDayCount ? Math.round(plannedDaysInternal / workDayCount * 100) : 0;

                            return <AvailabilityBar
                                key={index}
                                available={percentAvailability}
                                internalProject={percentInternal}
                                externalProject={percentExternal}
                            />
                        })
                    }
                    monthFields['monthId' + id] = {
                        orderKey: monthPercentAvailability,
                        value: <div style={{ display: 'flex' }}>
                            {content}
                        </div>
                    }
                }
            });


            const monthResults = monthIds.map((id) =>
            {
                const month = avEmp.months.find(m => m.monthId === id);
                if (!month || !month.weeks?.length)
                {
                    monthFields['monthId' + id] = { orderKey: 0, value: <div title={langStrings.noInformationFound}>N/A</div> };
                    return null;
                } else
                {
                    const monthWorkDayCount = month.weeks.reduce((hours, week) => hours + week.workDayCount, 0);
                    const monthHolidayHours = month.weeks.reduce((hours, week) => hours + week.holidayHours, 0);
                    const monthPlannedDays = month.weeks.reduce((hours, week) => hours + week.plannedDays, 0);
                    const monthPlannedDaysInternal = month.weeks.reduce((hours, week) => hours + week.plannedDaysInternal, 0);
                    const monthBusyDays = (monthHolidayHours / 8) + (monthPlannedDays) + monthPlannedDaysInternal;
                    const monthFreeDays = monthWorkDayCount - monthBusyDays;
                    const monthPercentAvailability = monthWorkDayCount ? Math.round(monthFreeDays / monthWorkDayCount * 100) : 0;
                    const percentExternal = monthWorkDayCount ? Math.round(monthPlannedDays / monthWorkDayCount * 100) : 0;
                    const percentInternal = monthWorkDayCount ? Math.round(monthPlannedDaysInternal / monthWorkDayCount * 100) : 0;

                    return {
                        monthPercentAvailability,
                        percentInternal,
                        percentExternal,
                        count: 0,
                    };
                }
            }).filter(data => data !== undefined) as {
                monthPercentAvailability: number;
                percentInternal: number;
                percentExternal: number;
                count: number;
            }[];
            // Berechnet die Durchschnitte
            const averages = monthResults.reduce((acc, curr) =>
            {
                acc.monthPercentAvailability += curr.monthPercentAvailability;
                acc.percentInternal += curr.percentInternal;
                acc.percentExternal += curr.percentExternal;
                acc.count++;
                return acc;
            }, { monthPercentAvailability: 0, percentInternal: 0, percentExternal: 0, count: 0 });

            const percentAvailability = averages.count > 0 ? Math.round(averages.monthPercentAvailability / averages.count) : 0;
            const percentInternal = averages.count > 0 ? Math.round(averages.percentInternal / averages.count) : 0;
            const percentExternal = averages.count > 0 ? Math.round(averages.percentExternal / averages.count) : 0;

            return {
                id: avEmp.id,
                employee: {
                    orderKey: avEmp.title,
                    value: <EmployeeOverlay employeeId={avEmp.id}>
                        {avEmp.title}
                    </EmployeeOverlay>
                },
                averageAvailability: {
                    orderKey: percentAvailability,
                    value: <AvailabilityBar
                        available={percentAvailability}
                        internalProject={percentInternal}
                        externalProject={percentExternal}
                    />
                },
                ...monthFields,
                lastForecastDate: !allowSales ? undefined : {
                    orderKey: avEmp.lastForecastDate ? avEmp.lastForecastDate.getTime() : -9999999,
                    value: <>
                        {avEmp.lastForecastDate ? new Date(avEmp.lastForecastDate).toLocaleDateString(lang, { month: 'long', day: 'numeric' }) : 'N/A'}
                    </>
                },
                salesIdea: !allowSales ? undefined : {
                    orderKey: 1,
                    value: <div className='salesIdeaBox' title={langStrings.salesIdea} style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        {
                            salesIdeasEdit[avEmp.id] ?
                                <FormatedTextInput onChange={(value) =>
                                {
                                    setSalesIdeas({ ...salesIdeas, [avEmp.id]: value })
                                }} value={salesIdeas[avEmp.id]} /> :
                                <ExpandableText>
                                    <FormatedTextInput value={salesIdeas[avEmp.id]} />
                                </ExpandableText>
                        }
                        {!salesIdeasEdit[avEmp.id] ?
                            <IconButton onClick={() => setSalesIdeasEdit({ ...salesIdeasEdit, [avEmp.id]: true })}>
                                <Edit />
                            </IconButton> :
                            <IconButton onClick={() =>
                            {
                                updateSalesIdea(avEmp.id, salesIdeas[avEmp.id]);
                                setSalesIdeasEdit({ ...salesIdeasEdit, [avEmp.id]: false })
                            }}>
                                <Done />
                            </IconButton>
                        }
                    </div>
                }
            }
        })

    const legendItems = [
        { title: langStrings.absence, colorVar: 'var(--var-color-holiday)' },
        { title: langStrings.internalProject, colorVar: 'var(--var-color-internalProject)' },
        { title: langStrings.externalProject, colorVar: 'var(--var-color-externalProject)' },
        { title: langStrings.oftenUsed.availability, colorVar: 'var(--var-color-available)' },
    ];

    return <div className='availabilitiesContent' style={{ overflow: 'hidden', width: '100%' }}>
        <AvailabilityGraph
            monthIds={monthIds}
            monthOptions={monthOptions}
            availabilityData={availabilityData}
        />
        <LegendBox items={legendItems} />
        <FormControlLabel
            control={
                <Checkbox
                    style={{ padding: '0px 9px' }}
                    checked={showWeeks}
                    onChange={(e) => setShowWeeks(e.target.checked)}
                    color="primary"
                />
            }
            label={langStrings.showWeeks}
        />
        <TextInput
            value={searchText}
            onChange={(value) => setSearchText(value.toLowerCase())}
            placeholder={langStrings.searchPlaceholder}
            size="small" />
        <DataTable
            id={'Availability'}
            rows={employeeRows}
            fields={[
                {
                    id: 'employee',
                    label: langStrings.employee,
                    disablePadding: isDense(width),
                    align: 'left',
                    type: 'JSX.Element',
                },
                {
                    id: 'averageAvailability',
                    label: langStrings.averageAvailability,
                    disablePadding: isDense(width),
                    align: 'center',
                    type: 'JSX.Element',
                },
                ...monthIds.map(id => ({
                    id: `monthId${id}`,
                    label: (new Date(id)).toLocaleString(undefined, monthOptions),
                    disablePadding: isDense(width),
                    align: 'center',
                    type: 'JSX.Element',
                } as IDataTableField<'JSX.Element'>)
                ),
                ...(!allowSales ? [] :
                    [
                        {
                            id: `salesIdea`,
                            label: langStrings.salesIdea,
                            disablePadding: isDense(width),
                            align: 'center',
                            type: 'JSX.Element',
                        } as IDataTableField<'JSX.Element'>,
                        {
                            id: 'lastForecastDate',
                            label: langStrings.lastForecastDate,
                            disablePadding: isDense(width),
                            align: 'center',
                            type: 'JSX.Element',
                        } as IDataTableField<'JSX.Element'>
                    ]),
            ]}
            selected={[]}
            noCheckbox={true}
            dense={true}
            order={SortOrder.DESC}
            // orderBy={`monthId${monthIds[0]}`}
            orderBy="averageAvailability"
            setSelected={function (): void
            {
            }} />
    </div>
}

interface IAvailabilityBarProps
{
    available: number;
    internalProject: number;
    externalProject: number;
}

/**
 * AvailabilityBar component
 * 
 * @param {IAvailabilityBarProps} available - Whether availability is shown or not
 * @param {IAvailabilityBarProps} internalProject - Whether it's an internal project or not
 * @param {IAvailabilityBarProps} externalProject - Whether it's an external project or not
 * @returns {JSX.Element} The rendered availability bar component
 */
function AvailabilityBar({ available, internalProject, externalProject }: IAvailabilityBarProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;

    const pointerFree = available
    const pointerExternal = pointerFree + externalProject
    const pointerInternal = pointerExternal + internalProject
    const style: React.CSSProperties = {

        background: `linear-gradient(to left, var(--var-color-available) 0%, var(--var-color-available) ${pointerFree}%, var(--var-color-externalProject) ${pointerFree}%, var(--var-color-externalProject) ${pointerExternal}%, var(--var-color-internalProject) ${pointerExternal}%, var(--var-color-internalProject) ${pointerInternal}%, var(--var-color-holiday) ${pointerInternal}%, var(--var-color-holiday) 100%)`
    }

    return <div
        className='availabilityMonthBox bar'
        title={langStrings.oftenUsed.availability}
        style={style}
    >
        {available}%
    </div>
}