/* eslint-disable react/prop-types */
import { AppState } from '@store/store';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import
{
    openDialog as openDialogAction,
    updateDialog,
    maximizeDialog as maximizeDialogAction,
    minimizeDialog as minimizeDialogAction,
    restoreDialog as restoreDialogAction,
    focusDialog as focusDialogAction,
    closeDialog as closeDialogAction,
    DialogState,
    TDialogWindowState,
} from '../../Store/reducer/dialogsSlice';

interface DialogUpdateState
{
    title?: string;
}

export const useDialog = () =>
{
    const dispatch = useDispatch();
    const dialogs = useSelector((state: AppState) => state.dialogs.dialogs);

    const getNextZIndex = useCallback(() =>
    {
        return dialogs.reduce((max, dialog) => Math.max(max, dialog.zIndex), 1301) + 1;
    }, [dialogs]);

    const openDialog = useCallback(
        (component: React.ReactElement, initialState: TDialogWindowState = 'normal') =>
        {
            const id = uuidv4();
            const zIndex = getNextZIndex();

            // component.props.id = id;

            const clonedComponent = React.cloneElement(component, {
                id,
                resolve: (data?: unknown) =>
                {
                    dispatch(closeDialogAction(id));
                    component.props.resolve && component.props.resolve(data);
                },
            });


            const dialog: DialogState = {
                id,
                title: component.props.title,
                component: clonedComponent,
                windowState: initialState,
                zIndex,
            };

            dispatch(openDialogAction(dialog));
        },
        [dispatch, getNextZIndex]
    );

    const minimizeDialog = useCallback(
        (dialogId: string) =>
        {
            dispatch(minimizeDialogAction(dialogId));
        },
        [dispatch]
    );
    const maximizeDialog = useCallback(
        (dialogId: string) =>
        {
            dispatch(maximizeDialogAction(dialogId));
        },
        [dispatch]
    );
    const restoreDialog = useCallback(
        (dialogId: string) =>
        {
            dispatch(restoreDialogAction(dialogId));
        },
        [dispatch]
    );
    const focusDialog = useCallback(
        (dialogId: string) =>
        {
            dispatch(focusDialogAction(dialogId));
        },
        [dispatch]
    );
    const setDialogState = useCallback(
        (id: string, state: DialogUpdateState) =>
        {
            dispatch(updateDialog({ id, changes: state }));
        },
        [dispatch]
    );
    const closeDialog = useCallback(
        (dialogId: string) =>
        {
            dispatch(closeDialogAction(dialogId));
        },
        [dispatch]
    );

    return {
        openDialog,
        closeDialog,
        minimizeDialog,
        maximizeDialog,
        restoreDialog,
        focusDialog,
        setDialogState,
    };
};

export const DialogContainer: React.FC = () =>
{
    const dialogs = useSelector((state: AppState) => state.dialogs.dialogs);
    const visibleDialogs = useMemo(() => dialogs.map(dialogState =>
    {
        // console.log("Update Dialog");
        return React.cloneElement(dialogState.component, {
            key: dialogState.id,
            dialogStateProps: {
                title: dialogState.title,
                key: dialogState.id,
                id: dialogState.id,
                windowState: dialogState.windowState,
            }
        })
    }), [dialogs]);
    return (
        <div className="dialog-container">
            {visibleDialogs}
        </div>
    );
}