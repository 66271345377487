import { OperationVariables, gql } from "@apollo/client";
import { IOUAvailabilityDataEmployeesDTO } from "@sharedInterfaces/IOrgUnit";

import GraphQL from "../graphQL";

const q = gql`
query getOrgUnitAvailabilityData($ouId: Int!) {
    ouAvailabilityData(id:  $ouId) {
       employees{
        id
        title
        salesIdea
        lastForecastDate
        months{
            monthId
            title
            weeks{
                plannedDays
                plannedDaysInternal
                holidayHours
                workDayCount
            }
        }
       }
  }
},
`;

/**
 * Retrieves the availability data for an organizational unit.
 * 
 * @param {number} ouId - The ID of the organizational unit.
 * @param {(ou: IOUAvailabilityDataEmployeesDTO) => void} callback - The callback function to be called with the organizational unit availability data.
 * @returns {Promise<{ ouSalesData: IOUAvailabilityDataEmployeesDTO; }>} - A promise that resolves with the organizational unit sales data.
 */
export default async function getOrgUnitAvailabilityData(ouId: number, callback: (ou: IOUAvailabilityDataEmployeesDTO) => void)
{
    const variables: OperationVariables = { ouId };
    return GraphQL.query<{ ouAvailabilityData: IOUAvailabilityDataEmployeesDTO }>(q, variables, (obj) =>
    {
        // const ou = obj.ou as IOrgUnitDetail;
        // ou.id = Number.parseInt(<string><unknown>ou.id);
        if (!obj) return;
        return callback(obj.ouAvailabilityData)
    });
}
