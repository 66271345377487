import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdminNotification, IEmployeeNotification } from '@sharedInterfaces/INotification';

export interface NotificationsState
{
    notifications: IEmployeeNotification[];
    adminNotifications: IAdminNotification[];
}

export const initialNotificationsState: NotificationsState = {
    notifications: [],
    adminNotifications: [],
};

export enum ENotificationsAction
{
    ADD_EMPLOYEE_NOTIFICATION = 'notifications/addEmployeeNotification',
    REMOVE_EMPLOYEE_NOTIFICATION = 'notifications/removeEmployeeNotification',
    UPDATE_EMPLOYEE_NOTIFICATION = 'notifications/updateEmployeeNotification',
    SET_EMPLOYEE_NOTIFICATIONS = 'notifications/setEmployeeNotifications',
    ADD_ADMIN_NOTIFICATION = 'notifications/addAdminNotification',
    REMOVE_ADMIN_NOTIFICATION = 'notifications/removeAdminNotification',
    UPDATE_ADMIN_NOTIFICATION = 'notifications/updateAdminNotification',
    SET_ADMIN_NOTIFICATIONS = 'notifications/setAdminNotifications',
}

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialNotificationsState,
    reducers: {
        // Employee Notifications Actions
        addEmployeeNotification: (state, action: PayloadAction<IEmployeeNotification>) =>
        {
            state.notifications.push(action.payload);
        },
        removeEmployeeNotification: (state, action: PayloadAction<string>) =>
        {
            state.notifications = state.notifications.filter(
                (notification) => notification.id !== action.payload
            );
        },
        updateEmployeeNotification: (state, action: PayloadAction<IEmployeeNotification>) =>
        {
            const index = state.notifications.findIndex(
                (notification) => notification.id === action.payload.id
            );
            if (index !== -1)
            {
                state.notifications[index] = action.payload;
            }
        },
        setEmployeeNotifications: (state, action: PayloadAction<IEmployeeNotification[]>) =>
        {
            state.notifications = action.payload;
        },

        // Admin Notifications Actions
        addAdminNotification: (state, action: PayloadAction<IAdminNotification>) =>
        {
            state.adminNotifications.push(action.payload);
        },
        removeAdminNotification: (state, action: PayloadAction<number>) =>
        {
            state.adminNotifications = state.adminNotifications.filter(
                (notification) => notification.id !== action.payload
            );
        },
        updateAdminNotification: (state, action: PayloadAction<IAdminNotification>) =>
        {
            const index = state.adminNotifications.findIndex(
                (notification) => notification.id === action.payload.id
            );
            if (index !== -1)
            {
                state.adminNotifications[index] = action.payload;
            }
        },
        setAdminNotifications: (state, action: PayloadAction<IAdminNotification[]>) =>
        {
            state.adminNotifications = action.payload;
        },
    },
});

export const {
    addEmployeeNotification,
    removeEmployeeNotification,
    updateEmployeeNotification,
    setEmployeeNotifications,
    addAdminNotification,
    removeAdminNotification,
    updateAdminNotification,
    setAdminNotifications,
} = notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;

export const isNotificationAction = (actionType: string): boolean =>
{
    // console.log(actionType);
    return Object.values(ENotificationsAction).includes(actionType as ENotificationsAction);
};