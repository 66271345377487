import { Merge } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { setEmployeeCertificates } from '@store/reducer/employeeReducer';
import { setCompanyAllCertificates } from '@store/reducer/companyReducer';
import { setCertificates } from '@store/reducer/certificatesReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import getCertificates from '@src/APIs/graphQl/Certificate/getCertificates';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import Certificate from '@src/Objects/Certificate';
import { Card, CardContent, Checkbox, FormControlLabel, Grid, Typography, Box } from '@mui/material';
import mergeCertificates from '@src/APIs/graphQl/Certificate/mergeCertificates';
import CertificateItem from '@src/App/NewLayout/Items/CertificateItem/CertificateItem';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import ErrorBox from '../../ErrorBox/ErrorBox';

interface MergeCertificatesDialogProps
{
    id: string;
    certificateIds: number[];
    resolve: (success: boolean) => void;
}

/**
 * Component for rendering the MergeCertificatesDialog.
 *
 * @param {MergeCertificatesDialogProps} props - The props containing necessary data for rendering the dialog.
 * @returns {JSX.Element | null} - The rendered dialog JSX element or null if no employee is present.
 */
function MergeCertificatesDialog(props: MergeCertificatesDialogProps): JSX.Element | null
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).certificates;

    const allCertificates = useSelector((state: AppState) => state.company.allCertificates);
    const employee = useSelector((state: AppState) => state.employee);
    const certificatesWithoutFilter = useSelector((state: AppState) => state.certificates);

    const [certificates, setCertificatesState] = useState<Certificate[] | null>(null);
    const [selectedCertificateIds, setSelectedCertificateIds] = useState<Set<number>>(new Set());
    const [suggestedCertificate, setSuggestedCertificate] = useState<Certificate | null>(null);
    const [newCertificateName, setNewCertificateName] = useState<string>("");
    const [newDescription, setNewDescription] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");
    const [saving, setSaving] = useState<boolean>(false);

    // Lade Zertifikate beim Mounten oder wenn props.certificateIds sich ändern
    useEffect(() =>
    {
        if (certificates === null)
        {
            getCertificates()
                .then((fetchedCertificates) =>
                {
                    const relevantCertificates = fetchedCertificates.filter((cert) => props.certificateIds.includes(cert.id));
                    setCertificatesState(relevantCertificates);

                    // Initialisiere ausgewählte Zertifikate mit allen relevanten Zertifikaten
                    setSelectedCertificateIds(new Set(relevantCertificates.map(cert => cert.id)));

                    // Bestimme das vorgeschlagene Zertifikat
                    const initialSuggestedCertificate = determineSuggestedCertificate(relevantCertificates);
                    setSuggestedCertificate(initialSuggestedCertificate);
                    setNewCertificateName(initialSuggestedCertificate.title);
                    setNewDescription(initialSuggestedCertificate.description);
                })
                .catch((error) =>
                {
                    console.error("Error fetching certificates:", error);
                    setErrorText(langStrings.errorFetchingCertificates);
                });
        }
    }, [props.certificateIds, certificates, langStrings.errorFetchingCertificates]);

    // Funktion zur Bestimmung des vorgeschlagenen Zertifikats
    const determineSuggestedCertificate = (certificatesList: Certificate[]): Certificate =>
    {
        let newSuggestedCertificate = certificatesList[0];
        certificatesList.forEach((cert) =>
        {
            if (
                cert.employees.length > newSuggestedCertificate.employees.length ||
                (cert.employees.length === newSuggestedCertificate.employees.length)
            )
            {
                newSuggestedCertificate = cert;
            }
        });
        return newSuggestedCertificate;
    };

    // Funktion zur Handhabung von Checkbox-Änderungen
    const handleCheckboxChange = (certId: number) =>
    {
        setSelectedCertificateIds((prevSelected) =>
        {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(certId))
            {
                newSelected.delete(certId);
            } else
            {
                newSelected.add(certId);
            }
            return newSelected;
        });
    };

    // Aktualisiere das vorgeschlagene Zertifikat, wenn die Auswahl der Zertifikate sich ändert
    useEffect(() =>
    {
        if (certificates)
        {
            const selectedCertificates = certificates.filter(cert => selectedCertificateIds.has(cert.id));
            if (selectedCertificates.length === 0)
            {
                setSuggestedCertificate(null);
                setNewCertificateName("");
                setNewDescription("");
                return;
            }
            const newSuggested = determineSuggestedCertificate(selectedCertificates);
            setSuggestedCertificate(newSuggested);
            setNewCertificateName(newSuggested.title);
            setNewDescription(newSuggested.description);
        }
    }, [selectedCertificateIds, certificates]);

    // Generiere eine einzigartige Liste von Mitarbeitern basierend auf den ausgewählten Zertifikaten
    const uniqueEmployees = {} as {
        [key: number]: {
            title: string;
            id: number;
            certificates: { title: string; id: number }[];
        };
    };
    certificates?.forEach((cert) =>
    {
        if (selectedCertificateIds.has(cert.id))
        {
            cert.employees.forEach((emp) =>
            {
                if (!uniqueEmployees[emp.id])
                {
                    uniqueEmployees[emp.id] = {
                        title: emp.title,
                        id: emp.id,
                        certificates: [{ title: cert.title, id: cert.id }],
                    };
                } else
                {
                    uniqueEmployees[emp.id].certificates.push({ title: cert.title, id: cert.id });
                }
            });
        }
    });
    const employees = Object.values(uniqueEmployees);

    if (!employee)
    {
        return null;
    }

    // Bestimme, ob der Merge-Button deaktiviert sein soll
    const isMergeDisabled = saving || selectedCertificateIds.size === 0;

    return (
        <Dialog
            id={props.id}
            title={langStrings.mergeCertificates}
            onClose={() => props.resolve(false)}
            footer={
                <Box sx={{ float: 'right' }}>
                    <Button
                        icon={<Merge />}
                        disabled={isMergeDisabled}
                        text={langStrings.merge}
                        size={'normal'}
                        onClick={async () =>
                        {
                            if (!selectedCertificateIds.size || !suggestedCertificate) return;
                            setSaving(true);
                            try
                            {
                                const result = await mergeCertificates(
                                    {
                                        id: suggestedCertificate.id,
                                        title: newCertificateName,
                                        description: newDescription,
                                    },
                                    Array.from(selectedCertificateIds)
                                );

                                if (!result || !employee)
                                {
                                    setErrorText(langStrings.error);
                                    setSaving(false);
                                    return;
                                }

                                setErrorText('');
                                setSaving(false);

                                const newAllCertificates = allCertificates
                                    .filter(cert => !result.removed.includes(cert.id))
                                    .map(cert =>
                                        cert.id === result.newCertificate.id
                                            ? { ...cert, title: result.newCertificate.title, otherNames: result.otherNames }
                                            : cert
                                    );

                                // Aktualisiere die Mitarbeiterzertifikate
                                const newEmployeeCertificates = employee.certificates
                                    .filter(cert => !result.removed.includes(cert.id))
                                    .map(cert =>
                                        cert.id === result.newCertificate.id
                                            ? {
                                                ...cert,
                                                title: result.newCertificate.title,
                                            }
                                            : cert
                                    );

                                const newCertificates = certificatesWithoutFilter
                                    .filter(cert => !result.removed.includes(cert.id))
                                    .map(cert =>
                                        cert.id === result.newCertificate.id
                                            ? { ...cert, title: result.newCertificate.title, description: result.newCertificate.description, employees: result.newCertificate.employees }
                                            : cert
                                    );

                                // Dispatch Aktionen
                                dispatch(setCompanyAllCertificates(newAllCertificates));
                                dispatch(setEmployeeCertificates(newEmployeeCertificates));
                                dispatch(setCertificates(newCertificates.map(ns => new Certificate(ns))));
                                props.resolve(true);
                            } catch (ex: any)
                            {
                                setErrorText(`${langStrings.error} ${ex.toString()}`);
                                setSaving(false);
                            }
                        }}
                    />
                </Box>
            }
        >
            {!certificates && <LoadingBox />}
            {certificates && (
                <div className="mergeBox">
                    <Typography variant="body1">{langStrings.certificatesSelectedForMerge}:</Typography>
                    {/* Liste der Zertifikate mit Checkboxen */}
                    <Box sx={{ maxHeight: 200, overflowY: 'auto', border: '1px solid #ccc', padding: 1, marginY: 2 }}>
                        {certificates.map(cert => (
                            <FormControlLabel
                                key={cert.id}
                                control={
                                    <Checkbox
                                        checked={selectedCertificateIds.has(cert.id)}
                                        onChange={() => handleCheckboxChange(cert.id)}
                                        color="primary"
                                    />
                                }
                                label={cert.title}
                            />
                        ))}
                    </Box>

                    <Typography variant="body1" marginTop={2} marginBottom={1}>
                        {langStrings.effectEmployeesWarning} {employees.length} {langStrings.employees}:
                    </Typography>
                    <Grid container spacing={2}>
                        {employees.map(emp => (
                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex', flexGrow: 1 }} key={emp.id}>
                                <Card className="employeeCard" style={{ flexGrow: 1 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div">
                                            {emp.title}
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary">
                                            {langStrings.certificates}:
                                        </Typography>
                                        {emp.certificates.map(cert => (
                                            <CertificateItem
                                                key={cert.id}
                                                title={cert.title}
                                                id={cert.id}
                                            />
                                        ))}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Typography variant="h5" marginTop={1}>{langStrings.newCertificate}</Typography>
                    <div className="newCertificate">
                        <RowElement title={langStrings.certificate} alignTitle="left">
                            <TextInput value={newCertificateName} onChange={s => setNewCertificateName(s)} />
                        </RowElement>
                        <RowElement title={langStrings.description} alignTitle="left">
                            <FormatedTextInput value={newDescription} onChange={s => setNewDescription(s)} />
                        </RowElement>
                        <Typography>{langStrings.descriptionGeneratesAutomaticly}</Typography>
                    </div>
                    {errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>}
                    <ErrorBox warning>{langStrings.mergeWarning}</ErrorBox>
                </div>
            )}
        </Dialog>
    );
}

export default MergeCertificatesDialog;
