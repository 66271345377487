import React from "react";
import { useSelector } from "react-redux";
import InnerLayout from "@sharedReact/Layouts/InnerLayout/InnerLayout";
import { AppState } from "@store/store";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import { TableContainer, IconButton, Typography, Table, TableHead, TableRow, TableCell, TableBody, TextField, Alert, Button, } from "@mui/material";
import { CopyAll } from "@mui/icons-material";
import { ELinks } from "@sharedInterfaces/globalEnums";
import { useNavigate } from "react-router-dom";

import { generateTexts } from "./generateTexts";

interface ParameterBase
{
    name: string;
    type: string;
    description: string;
    required?: boolean | string;
}

interface ObjectParameter extends ParameterBase
{
    type: 'object';
    object: string;
}
interface UnknownObject extends ParameterBase
{
    type: 'unknownObject',
}

interface ArrayParameter extends ParameterBase
{
    type: 'array';
    object: string;
}
interface GetParameter extends ParameterBase
{
    type: 'GET-PARAMETER';
}
interface PathParameter extends ParameterBase
{
    type: 'PATH-PARAMETER';
}
interface BooleanParameter extends ParameterBase
{
    type: 'boolean';
}

interface StringParameter extends ParameterBase
{
    type: 'string';
}

interface NumberParameter extends ParameterBase
{
    type: 'number';
}

type Parameter = ObjectParameter | UnknownObject | BooleanParameter | StringParameter | NumberParameter | ArrayParameter | GetParameter | PathParameter;


interface ResponseBase
{
    type: string;
    description: string;
}
interface ObjectResponse extends ResponseBase
{
    type: 'object';
    object: string;
}
interface ArrayResponse extends ResponseBase
{
    type: 'array';
    object: string;
}
interface SimpleResponse extends ResponseBase
{
    type: 'boolean' | 'string' | 'number' | 'unknownObject';
}
type Response = ObjectResponse | ArrayResponse | SimpleResponse;

export interface ApiObject
{
    title: string;
    response?: boolean;
    description: string;
    parameter: Parameter[];
    exampleScreenshot?: string;
}

export interface ApiEndpoint
{
    title: string;
    endpoint: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    description: string;
    parameters: Parameter[];
    response: Response;
}

function APIDocumentation()
{
    const navigate = useNavigate();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).restDocu;

    const { apiObjects, apiEndpoints } = generateTexts(langStrings);
    return (
        <InnerLayout title={langStrings.title} >
            <div className="page">
                <TableOfContents apiEndpoints={apiEndpoints} apiObjects={apiObjects} />
                <hr />
                <Typography
                    variant="h5"
                    id="authorisation"
                    gutterBottom
                >
                    {langStrings.authorisation}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {langStrings.authorisationDescription} <br />
                </Typography>
                <Typography variant="caption" style={{
                    backgroundColor: '#e0e0e0',
                    padding: 7,
                    borderRadius: 5,
                }}>
                    Authorization: Bearer {'<API_KEY>'}
                </Typography>
                <Typography variant="body2" gutterBottom style={{ marginTop: 10 }}>
                    {langStrings.authorisationHelper}
                </Typography>
                <Alert
                    severity="warning"
                    action={
                        <Button
                            color="inherit"
                            size="small"
                            onClick={() =>
                            {
                                navigate(`/${ELinks.SETTINGS_RESTAPI}`)
                            }}
                        >
                            {langStrings.manageApiKeys}
                        </Button>
                    }
                    style={{
                        alignItems: 'center',
                        marginTop: 10,
                        marginBottom: 15,
                        borderRadius: 7,
                    }}
                >
                    <Typography variant="overline">
                        {langStrings.warning}
                    </Typography>
                    <Typography variant="body2">
                        {langStrings.authorisationHint}
                    </Typography>

                </Alert>
                <hr />
                <EndpointTable apiEndpoints={apiEndpoints} />
                <hr />
                <ObjectTable apiObjects={apiObjects} />
            </div>
        </InnerLayout>
    );
}

export default APIDocumentation;


function ObjectTable({ apiObjects }: { apiObjects: ApiObject[] })
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).restDocu;

    return <TableContainer>
        <Typography
            variant="h5"
            id="objects"
            gutterBottom
        >
            {langStrings.objects}
        </Typography>
        {apiObjects.map((object) => (
            <div
                key={object.title}
                id={object.title}
                style={{ marginBottom: 10 }}
            >
                <Typography variant="h6" gutterBottom>
                    {object.title}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    {object.description}
                </Typography>
                <RenderParameter
                    hideHeader
                    response={object.response}
                    parameter={object.parameter}
                />
                {object.exampleScreenshot && <img
                    src={object.exampleScreenshot}
                    alt="Example Screenshot"
                    style={{ maxWidth: '100%', marginTop: 10 }}
                />}
            </div>
        ))}
    </TableContainer>;
}


function EndpointTable({ apiEndpoints }: { apiEndpoints: ApiEndpoint[] })
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).restDocu;
    return <TableContainer >
        <Typography
            variant="h5"
            id="endpoints"
            gutterBottom
        >
            {langStrings.endpoints}
        </Typography>
        {apiEndpoints.map((endpoint) => (
            <div
                key={endpoint.endpoint}
                id={endpoint.title}
                style={{ marginBottom: 10 }}
            >
                <Typography variant="h6" gutterBottom>
                    {endpoint.title}
                </Typography>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                >{langStrings.method}: {endpoint.method}</Typography>
                <TextField
                    label="Endpoint"
                    size="small"
                    value={endpoint.endpoint}
                    disabled={true}
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: 10 }}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <IconButton
                                title={langStrings.oftenUsed.copy}
                                onClick={copyToClipboard.bind(null, endpoint.endpoint)}>
                                <CopyAll />
                            </IconButton>
                        ),
                    }}
                />
                <Typography variant="subtitle2" gutterBottom>
                    {endpoint.description}
                </Typography>
                <RenderParameter
                    parameter={endpoint.parameters}
                />
                <RenderResponse
                    response={endpoint.response}
                />
            </div>
        ))}
    </TableContainer>
}

function RenderResponse({ response }: { response: Response })
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).restDocu;

    const param = response;
    return <>
        <Typography variant="h6" gutterBottom>
            {langStrings.response}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
            {langStrings.type}: {(param.type === 'object' || param.type === 'array') ? (
                <a href={`#${(param as ObjectResponse).object}`}>{param.type} - {param.object}</a>
            ) : (
                param.type === 'unknownObject' ? 'object' : param.type
            )}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
            {langStrings.description}: {param.description}
        </Typography>
        {/* <Table>
            <TableHead>
                <TableRow>
                    <TableCell>{langStrings.property}</TableCell>
                    <TableCell>{langStrings.type}</TableCell>
                    <TableCell>{langStrings.description}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow key={param.name}>
                    <TableCell>{param.name}</TableCell>
                    <TableCell>
                        {param.type === 'object' ? (
                            <a href={`#${(param as ObjectResponse).object}`}>{param.type} - {param.object}</a>
                        ) : (
                            param.type
                        )}
                    </TableCell>
                    <TableCell>{param.description}</TableCell>
                </TableRow>
            </TableBody>
        </Table> */}
    </>
}

function RenderParameter({ parameter, hideHeader, response }: { hideHeader?: true, response?: boolean, parameter: Parameter[] })
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).restDocu;
    return <div style={{ marginBottom: 10 }}>
        {!hideHeader && <Typography variant="h6">
            {langStrings.request}
        </Typography>}
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>{response ? langStrings.property : langStrings.parameter}</TableCell>
                    <TableCell>{langStrings.type}</TableCell>
                    {!response && <TableCell>{langStrings.required}</TableCell>}
                    <TableCell>{langStrings.description}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {parameter.map((param) => (
                    <TableRow key={param.name}>
                        <TableCell>{param.name}</TableCell>
                        <TableCell>
                            {(param.type === 'object' || param.type === 'array') ? (
                                <a href={`#${(param as ObjectResponse).object}`}>{param.type} - {param.object}</a>
                            ) : (
                                param.type === 'unknownObject' ? 'object' : param.type
                            )}
                        </TableCell>
                        {!response && <TableCell>
                            {param.required
                                ? typeof param.required === 'boolean'
                                    ? langStrings.oftenUsed.yes
                                    : param.required
                                : langStrings.oftenUsed.no}
                        </TableCell>}
                        <TableCell>{param.description}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </div>
}

function TableOfContents({ apiObjects, apiEndpoints }: { apiObjects: ApiObject[], apiEndpoints: ApiEndpoint[] })
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).restDocu;

    return (
        <div>
            <Typography variant="h5" gutterBottom>
                {langStrings.tableOfContents}
            </Typography>
            <ul>
                <li>
                    <Typography variant="h6" gutterBottom>
                        <a href={`#authorisation`}>
                            {langStrings.authorisation}
                        </a>
                    </Typography>
                </li>
                <li>
                    <Typography variant="h6" gutterBottom>
                        <a href={`#endpoints`}>
                            {langStrings.endpoints}
                        </a>
                    </Typography>
                    <ul>
                        {apiEndpoints.map((endpoint) => (
                            <li key={endpoint.title}>
                                <a href={`#${endpoint.title}`}>{endpoint.title}</a>
                            </li>
                        ))}
                    </ul>
                </li>
                <li>
                    <Typography variant="h6" gutterBottom>
                        <a href={`#objects`}>
                            {langStrings.objects}
                        </a>
                    </Typography>
                    <ul>
                        {apiObjects.map((object) => (
                            <li key={object.title}>
                                <a href={`#${object.title}`}>{object.title}</a>
                            </li>
                        ))}
                    </ul>
                </li>
            </ul>
        </div>
    );
}


function copyToClipboard(link: string)
{
    navigator.clipboard.writeText(link);
}