import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";

const query = gql`
mutation deleteHoliday($year: Int!, $date: String!) {
    deleteHoliday(year: $year, date: $date) 
},
`;

/**
 * Deletes the holiday from the given year and date.
 * 
 * @param {number} year - The year of the holiday to be deleted.
 * @param {string} date - The date of the holiday to be deleted.
 * @returns {Promise<boolean>} A promise that resolves to true if the holiday is successfully deleted, otherwise false.
 */
export default async function deleteHoliday(year: number, date: string)
{
    const variables: OperationVariables = {
        year,
        date
    };
    return GraphQL.mutate<boolean>(query, variables)
}