import { OperationVariables, gql } from "@apollo/client";
import IOpportunity from "@sharedInterfaces/IOpportunity";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { deleteOpportunity, updateOpportunity } from "@store/reducer/opportunityRecuder";
import Opportunity from "@src/Objects/Opportunity";
import { entityFieldsGQL } from "@sharedReact/Objects/Entity";

import GraphQL from "../graphQL";
import { NotFoundError } from "../NotFoundError";

export const employeeCoverageGql = `{
    id
    title
    skillCoverage
    score
    missingSkills {
        id
        title
        level
        wantedLevel
    }
    coveredSkills {
        id
        title
        level
        wantedLevel
    }
    missingCertificates {
        id
        title
    }
    coveredCertificates {
        id
        title
    }
    missingRoles {
        id
        title
    }
    coveredRoles {
        id
        title
    }
}`;
export const opportunityGql = `{
    id
    title
    externalId
    projectStart
    projectEnd
    publishDate
    deadline
    dayPrice
    loc
    customer
    partner
    sourceName
    sourceURL
    utilization
    remote
    headCount
    skills {
        title
        id
        level
    }
    certificates
    {
        id
        title
    }
    competences {
        id
        title
        level
    }
    roles {
        id
        title
    }
    primaryRole {
        id
        title
    }
    status
    requestText
    selectedEmployees
    ${employeeCoverageGql}
    ${entityFieldsGQL}
}`;

const q = gql`
query GetOpportunity($opportunityId: ID!) {
    opportunity(id: $opportunityId) 
    ${opportunityGql}
},
`;

/**
 * Retrieves an opportunity by its ID, then updates the Redux store.
 *
 * @param {number} opportunityId - The ID of the opportunity to retrieve.
 * @returns {Promise<Opportunity>} A promise that resolves with the retrieved opportunity.
 */
export default async function getOpportunity(opportunityId: number)
{
    const variables: OperationVariables = { opportunityId };
    return GraphQL.query<{ opportunity: IOpportunity }>(q, variables).then((data) =>
    {
        if (!data?.opportunity)
        {
            store.dispatch(deleteOpportunity(opportunityId));
            throw new NotFoundError(opportunityId, EEntityType.OPPORTUNITY);
        }

        const opportunity = new Opportunity(data.opportunity);
        store.dispatch(updateOpportunity(opportunity));
        return opportunity;
    });
}
