import { OperationVariables, gql } from "@apollo/client";
import IBusinessDTO from "@sharedInterfaces/IBusiness";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { setCompanyVersion } from "@store/reducer/companyReducer";
import { setBusinessses } from "@store/reducer/businessReducer";
import Business, { businessGQL } from "@src/Objects/Business";

import GraphQL from "../graphQL";


const q = gql`
query($version:Int!) {
    businesses(version:$version){
        version
        businesses
        ${businessGQL}
    }
},
`;

interface BusinesseResponse
{
    businesses: { version: number; businesses: IBusinessDTO[] };
}


export default async function getBusinesses()
{
    const state = store.getState();
    const versions = state.company.versions;
    const variables: OperationVariables = {
        version: versions.business,
    };
    return GraphQL.query<BusinesseResponse>(q, variables, undefined)
        .then((obj) =>
        {
            if (!obj) throw new Error("Fehler beim laden der Businesses.");
            if (obj.businesses.businesses)
            {
                const data = obj.businesses.businesses.map((p: IBusinessDTO) => new Business(p));
                store.dispatch(setCompanyVersion(EEntityType.BUSINESS, obj.businesses.version))
                store.dispatch(setBusinessses(data))
                return data;
            }
            return state.businesses;
        });
}