import { OperationVariables, gql } from "@apollo/client";
import IRoleDetailDTO, { IRoleInput } from "@sharedInterfaces/IRole";
import Role, { RoleGQL } from "@src/Objects/Role";

import GraphQL from "../graphQL";


const query = gql`
mutation createRole(
        $inputRole: RoleInput!,
    ) {
    createRole(
        inputRole: $inputRole,
    ) 
    ${RoleGQL}
},
`;


/**
 * Creates a new role.
 * 
 * @param {object} inputRole - The input role.
 * @param {import("IRole").IRoleInput} inputRole - The input role object.
 * 
 * @returns {Promise<import("Role").default>} Returns a promise that resolves with the new role.
 */
export default async function createRole(inputRole: IRoleInput)
{
    const variables: OperationVariables = {
        inputRole: prepairInputRole(inputRole)
    };
    return GraphQL.mutate<IRoleDetailDTO>(query, variables)
        .then(s => new Role(s));
}

/**
 * Prepaire the input role.
 *
 * @param inputRole - The input role to be prepaired.
 * @returns The prepaired input role.
 */
export function prepairInputRole(inputRole: IRoleInput)
{
    return {
        title: inputRole.title,
        description: inputRole.description,
        skills: inputRole.skills.map(s => ({
            id: s.id,
            title: s.title,
            level: s.level,
        })),
        competences: inputRole.competences.map(c => ({
            id: c.id,
            title: c.title,
            level: c.level,
        })),
        certificates: inputRole.certificates.map(c => ({
            id: c.id,
            title: c.title,
        })),
        otherNames: inputRole.otherNames,
    } as IRoleInput
}