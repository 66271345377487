import { ELanguage } from "../languageHelper"

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface businessesTexts extends ITextBase
{
    projects: string
    notFound: string
    search: string
    editBusiness: string
    tenantId: string
    empty: string
    edit: string
    delete: string
    create: string
    businessName: string
    description: string
    newBusiness: string
    newBusinessError: string
    save: string
    businesses: string
    business: string
}
type ILangData = {
    [lang in ELanguage]: businessesTexts
}
export const businesssTexts: ILangData = {
    'th': {
        oftenUsed: oftenUsedTranslations['th'],
        newBusiness: 'บริษัทใหม่',
        editBusiness: 'แก้ไขบริษัท: ',
        businessName: 'ชื่อบริษัท',
        newBusinessError: 'โปรดป้อนชื่อบริษัท',
        tenantId: 'Clockodo ID',
        description: oftenUsedTranslations['th'].description,
        create: oftenUsedTranslations['th'].create,
        edit: oftenUsedTranslations['th'].edit,
        delete: oftenUsedTranslations['th'].delete,
        save: oftenUsedTranslations['th'].save,
        empty: oftenUsedTranslations['th'].empty,
        search: oftenUsedTranslations['th'].search,
        notFound: oftenUsedTranslations['th'].notFound,
        businesses: oftenUsedTranslations['th'].businesses,
        business: oftenUsedTranslations['th'].business,
        projects: oftenUsedTranslations['th'].projects,
    },
    'en': {
        oftenUsed: oftenUsedTranslations['en'],
        newBusiness: 'New Company',
        editBusiness: 'Edit Company: ',
        businessName: 'Company Name',
        newBusinessError: 'Please enter a company name.',
        tenantId: 'Clockodo ID',
        description: oftenUsedTranslations['en'].description,
        create: oftenUsedTranslations['en'].create,
        edit: oftenUsedTranslations['en'].edit,
        delete: oftenUsedTranslations['en'].delete,
        save: oftenUsedTranslations['en'].save,
        empty: oftenUsedTranslations['en'].empty,
        search: oftenUsedTranslations['en'].search,
        notFound: oftenUsedTranslations['en'].notFound,
        businesses: oftenUsedTranslations['en'].businesses,
        business: oftenUsedTranslations['en'].business,
        projects: oftenUsedTranslations['en'].projects,
    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        newBusiness: 'Neue Firma',
        editBusiness: 'Firma bearbeiten: ',
        businessName: 'Firmenname',
        newBusinessError: 'Bitte gebe einen Firmen-Namen ein.',
        tenantId: 'Clockodo ID',
        description: oftenUsedTranslations['de'].description,
        create: oftenUsedTranslations['de'].create,
        edit: oftenUsedTranslations['de'].edit,
        delete: oftenUsedTranslations['de'].delete,
        save: oftenUsedTranslations['de'].save,
        empty: oftenUsedTranslations['de'].empty,
        search: oftenUsedTranslations['de'].search,
        notFound: oftenUsedTranslations['de'].notFound,
        businesses: oftenUsedTranslations['de'].businesses,
        business: oftenUsedTranslations['de'].business,
        projects: oftenUsedTranslations['de'].projects,
    },
}