import { EWebsocketTopics } from "@sharedInterfaces/globalEnums";
import { stageContext } from "@src/globals";
import { setSecret } from "@store/reducer/clientReducer";
import store from "@store/store";
const defaultReconnectDelay = 10000;
const heartbeatInterval = 9 * 60 * 1000; // 9 Minuten in Millisekunden

export class WebSocketClient
{
    static socket: WebSocket | null = null;
    static isConnecting: boolean = false;
    static lastSubscriptionId = 0;
    static subscriptions: { [topic: string]: { callback: CallableFunction, subKey: string }[] } = {};
    static reconnectDelay = 1000;
    static maxReconnectDelay = 60000;
    static pendingMessages: { topic: string, data: unknown }[] = [];
    static heartbeatIntervalId: NodeJS.Timeout | null = null;

    // Verbindung herstellen
    static async connect(): Promise<void>
    {
        if (WebSocketClient.socket || WebSocketClient.isConnecting)
        {
            return; // Verhindern, dass eine neue Verbindung erstellt wird, während bereits eine besteht oder aufgebaut wird
        }
        WebSocketClient.isConnecting = true;
        const state = store.getState();
        const secret = state.client.secret;
        if (!secret)
        {
            WebSocketClient.isConnecting = false;
            return;
        }
        const url = `${stageContext.apiWebsocketDomain}?auth=${encodeURIComponent(secret)}`;
        WebSocketClient.socket = new WebSocket(url);
        await new Promise<void>((resolve, reject) =>
        {
            const timeout = setTimeout(() =>
            {
                WebSocketClient.isConnecting = false;
                reject(new Error('Verbindungsaufbau zu WebSocket timeout.'));
            }, defaultReconnectDelay);

            if (!WebSocketClient.socket)
            {
                clearTimeout(timeout);
                WebSocketClient.isConnecting = false;
                reject(new Error('WebSocket-Instanz konnte nicht erstellt werden.'));
                return;
            }

            WebSocketClient.socket.onopen = () =>
            {
                clearTimeout(timeout);
                WebSocketClient.isConnecting = false;
                this.reconnectDelay = defaultReconnectDelay;
                this.sendMessage('init', null);
                resolve();
                // Alle ausstehenden Nachrichten senden
                WebSocketClient.flushPendingMessages();
                // Starte Heartbeat
                WebSocketClient.startHeartbeat();
            };

            WebSocketClient.socket.onerror = (error) =>
            {
                clearTimeout(timeout);
                WebSocketClient.isConnecting = false;
                console.error('WebSocket Fehler:', error);
                reject(error);
                WebSocketClient.reconnect();
            };

            WebSocketClient.socket.onclose = () =>
            {
                WebSocketClient.isConnecting = false;
                console.warn('WebSocket Verbindung geschlossen. Versuche erneut zu verbinden...');
                WebSocketClient.stopHeartbeat();
                WebSocketClient.reconnect();
            };

            WebSocketClient.socket.onmessage = (event) =>
            {
                const message = JSON.parse(event.data);
                const topic = message.topic;
                const data = message.data;
                const subscriptions = WebSocketClient.subscriptions[topic];
                const jsonData = JSON.parse(data);
                if (topic === EWebsocketTopics.refreshToken)
                {
                    console.log("New Login Token");
                    store.dispatch(setSecret((jsonData as { newSecret: string | null }).newSecret));
                }
                else if (subscriptions)
                {
                    subscriptions.forEach(sub => sub.callback(jsonData));
                }
                else
                {
                    console.log(`Keine Subscriptions für ${topic}`);
                }
            };
        });
    }

    static async sendMessage(topic: string, data: unknown): Promise<void>
    {
        if (this.isConnecting || (this.socket && this.socket.readyState === WebSocket.CONNECTING))
        {
            console.log('WebSocket ist noch nicht verbunden. Nachricht wird zur Warteschlange hinzugefügt...');
            WebSocketClient.pendingMessages.push({ topic, data });
            return;
        }

        try
        {
            if (!this.socket || this.socket.readyState !== WebSocket.OPEN)
            {
                console.log('WebSocket ist nicht verbunden. Verbindung wird hergestellt...');
                this.socket = null;
                await this.connect();
            }

            if (this.socket && this.socket.readyState === WebSocket.OPEN)
            {
                this.socket.send(JSON.stringify({ topic, data }));
            } else
            {
                this.socket = null;
                throw new Error('WebSocket-Verbindung konnte nicht hergestellt werden.');
            }
        } catch (error)
        {
            console.error('Fehler beim Senden der Nachricht:', error);
            WebSocketClient.pendingMessages.push({ topic, data });
        }
    }

    // Verbindung schließen
    static disconnect(): void
    {
        if (WebSocketClient.socket)
        {
            WebSocketClient.socket.close();
            WebSocketClient.socket = null;
        }
        WebSocketClient.stopHeartbeat();
    }

    static reconnect(): void
    {
        if (WebSocketClient.isConnecting)
        {
            return; // Verhindern, dass eine neue Verbindung initiiert wird, während bereits eine Verbindung hergestellt wird
        }
        setTimeout(() =>
        {
            console.log('Versuche WebSocket erneut zu verbinden...');
            WebSocketClient.connect().catch((error) =>
            {
                console.error('Fehler beim erneuten Verbinden:', error);
                WebSocketClient.reconnectDelay = Math.min(WebSocketClient.reconnectDelay * 2, WebSocketClient.maxReconnectDelay);
                WebSocketClient.reconnect();
            });
        }, WebSocketClient.reconnectDelay);
    }

    static flushPendingMessages(): void
    {
        if (this.socket && this.socket.readyState === WebSocket.OPEN)
        {
            while (WebSocketClient.pendingMessages.length > 0)
            {
                const { topic, data } = WebSocketClient.pendingMessages.shift()!;
                this.socket.send(JSON.stringify({ topic, data }));
            }
        }
    }

    static startHeartbeat(): void
    {
        WebSocketClient.stopHeartbeat(); // Verhindern, dass mehrere Heartbeats laufen
        WebSocketClient.heartbeatIntervalId = setInterval(() =>
        {
            if (WebSocketClient.socket && WebSocketClient.socket.readyState === WebSocket.OPEN)
            {
                WebSocketClient.sendMessage('heartbeat', null);
            }
        }, heartbeatInterval);
    }

    static stopHeartbeat(): void
    {
        if (WebSocketClient.heartbeatIntervalId)
        {
            clearInterval(WebSocketClient.heartbeatIntervalId);
            WebSocketClient.heartbeatIntervalId = null;
        }
    }

    static unsubscripe(subKey: string)
    {
        const [topic] = subKey.split('-');
        WebSocketClient.subscriptions[topic] = WebSocketClient.subscriptions[topic].filter(sub => sub.subKey !== subKey);
    }

    static subscripe<T>(topic: string, callback: (data: T) => void): string
    {
        const subKey = `${topic}-${WebSocketClient.lastSubscriptionId + 1}`;
        WebSocketClient.lastSubscriptionId = WebSocketClient.lastSubscriptionId + 1;
        const subscriptions = WebSocketClient.subscriptions || {};
        if (!subscriptions[topic])
        {
            subscriptions[topic] = [{
                subKey,
                callback,
            }];
        } else
        {
            subscriptions[topic].push({
                subKey,
                callback,
            });
        }
        return subKey;
    }
}
