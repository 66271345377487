import { ArrowLeft, Check, Insights, Done } from '@mui/icons-material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import IOpportunity, { EOpportunityRequestTypes, IOpportunityCertificate, IOpportunityCompetenceReference, IOpportunityNew, IOpportunityRole, IOpportunitySkill, TOpportunityRequestingState } from '@sharedInterfaces/IOpportunity';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import { updateOpportunity } from '@store/reducer/opportunityRecuder';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import Button from '@sharedReact/General/Button/Button';
import Opportunity from '@src/Objects/Opportunity';
import createOpportunity from '@src/APIs/graphQl/Opportunity/createOpportunity';
import editOpportunity from '@src/APIs/graphQl/Opportunity/editOpportunity';
import ISkill from '@src/interfaces/ISkill';
import { combinedKnowledge, urlRegex } from "@src/helper/helper";
import { WebSocketClient } from '@src/APIs/WebSockets/WebSocketClient';
import { EWebsocketTopics } from '@sharedInterfaces/globalEnums';
import { cloneDeep } from '@apollo/client/utilities';
import { IAnalyseOpportunity } from '@sharedInterfaces/websocket/IAnalyseOpportunity';

import ErrorBox from '../../ErrorBox/ErrorBox';
import './OpportunityDialog.css';
import TabContainer from '../../TabContainer/TabContainer';
import { EmployeeTab } from '../../Forms/OpportunityForm/EmployeeTab';
import { OpporunityForm } from '../../Forms/OpportunityForm/OpporunityForm';
import SkillsInput from '../../formsControls/inputs/SkillsInput/SkillsInput';
import AiConfirmation from '../../formsControls/inputs/AiConfirmation/AiConfirmation';


interface OpportunityDialogProps
{
    id: string
    opportunity?: Opportunity
    resolve?: (val: Opportunity | null) => void;
}

/**
 * OpportunityDialog component.
 *
 * @param opportunity - The opportunity object.
 * @param onCreate - The function to call when creating an opportunity.
 * @param onEdit - The function to call when editing an opportunity.
 * @param onClose - The function to call when closing the dialog.
 * @returns The JSX element of the OpportunityDialog.
 */
function OpportunityDialog({ id, opportunity: originalOpportunity, resolve }: OpportunityDialogProps)
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).opportunities;
    const permissions = useSelector((state: AppState) => state.permissions);
    const readRoles = checkPermissions('Roles', 'Retrieve', permissions);

    const [saving, setSaving] = React.useState<boolean>(false);
    const [createType, setCreateType] = React.useState<null | 'manuel' | 'ai'>(originalOpportunity ? 'manuel' : null);
    const [errorText, setErrorText] = React.useState<string>("");
    const [loadingText, setLoadingText] = useState<string>("");
    const [aiConfirmation, setAiConfirmation] = useState<boolean>(false);
    const [dialogStep, setDialogStep] = useState<number>(0);
    const [text, setText] = useState<string>("");

    const [subKey, setSubKey] = useState<string>("");

    const [requestingState, setRequestingState] = useState<TOpportunityRequestingState>({
        title: false,
        projectDates: false,
        projectNumbers: false,
        customerPartner: false,
        location: false,
        roles: false,
        competences: false,
        skills: false,
        certificates: false,
    });


    const [stateOpportunity, setStateOpportunity] = React.useState<IOpportunityNew>(
        originalOpportunity ?
            cloneDeep(originalOpportunity) as IOpportunityNew :
            {
                title: '',
                externalId: '',
                // active: true,
                projectStart: undefined,
                projectEnd: undefined,
                publishDate: undefined,
                deadline: undefined,
                dayPrice: 0,
                loc: '',
                customer: '',
                partner: '',
                sourceName: '',
                sourceURL: '',
                utilization: 0,
                remote: 0,
                headCount: 0,
                requestText: "",
                skills: [],
                selectedEmployees: [],
                competences: [],
                certificates: [],
                roles: [],
                primaryRole: null,
                status: '',
            }
    );


    const onFetchError = (ex: { toString(): string }) =>
    {
        const error = ex.toString();
        setErrorText(error);
        setTimeout(() =>
        {
            setSaving(false);
        }, 3000);
    }
    const onFetch = (newOpportunity: IOpportunity) =>
    {
        const entity = new Opportunity(newOpportunity);
        resolve && resolve(entity);
        setSaving(false);
        dispatch(updateOpportunity(entity));
    }

    const validateForm = (): boolean =>
    {
        const isSourceURLValid = stateOpportunity.sourceURL === "" || urlRegex.test(stateOpportunity.sourceURL);
        const isTitleValid = stateOpportunity.title !== "";

        return isSourceURLValid && isTitleValid;
    };
    const onSave = async () =>
    {
        setSaving(true);
        if (!validateForm())
        {
            setErrorText(langStrings.newOpportunityError);
            setTimeout(() =>
            {
                setSaving(false);
            }, 1500);
            return;
        }
        if (!originalOpportunity)
        {
            await createOpportunity(
                stateOpportunity
            )
                .then(onFetch)
                .catch(onFetchError)
        } else
        {
            await editOpportunity(
                {
                    ...stateOpportunity,
                    id: originalOpportunity.id,
                }
            )
                .then(onFetch)
                .catch(onFetchError)
        }
    };

    const startAnalyse = () =>
    {
        setLoadingText(langStrings.waitForAi);
        setDialogStep(1);
        setStateOpportunity((prevState) => ({ ...prevState, requestText: text }));

        const request = {
            title: true,
            projectDates: true,
            projectNumbers: true,
            customerPartner: true,
            location: true,
            roles: readRoles,
            competences: true,
            skills: true,
            certificates: true,
        };
        WebSocketClient.sendMessage(topic, {
            text,
            key: subKey,
            request
        });
        setRequestingState(request)
    }

    const topic = EWebsocketTopics.analyseOpportunity;

    React.useEffect(() =>
    {
        const subKey = WebSocketClient.subscripe<IAnalyseOpportunity>(topic, (data) =>
        {
            if (subKey !== data.key) return;
            if (data.error)
            {
                setErrorText(data.error);
                return;
            }
            setLoadingText("");
            setCreateType('manuel')
            const updateOppo: {
                title?: string
                externalId?: string
                projectStart?: Date
                projectEnd?: Date
                publishDate?: Date
                deadline?: Date
                dayPrice?: number
                utilization?: number
                remote?: number
                headCount?: number
                loc?: string
                customer?: string
                partner?: string
                sourceName?: string
                sourceURL?: string
                roles?: IOpportunityRole[]
                primaryRole?: IOpportunityRole
                competences?: IOpportunityCompetenceReference[]
                skills?: IOpportunitySkill[]
                certificates?: IOpportunityCertificate[]
            } = {};

            if (data.type === 'title')
            {
                setRequestingState((prevState) => ({ ...prevState, title: false }))
            } else if (data.type === 'externalId')
            {
                setRequestingState((prevState) => ({ ...prevState, externalId: false }))
            } else if (data.type === 'projectDates')
            {
                setRequestingState((prevState) => ({ ...prevState, projectDates: false }))
            } else if (data.type === 'projectNumbers')
            {
                setRequestingState((prevState) => ({ ...prevState, projectNumbers: false }))
            } else if (data.type === 'customerPartner')
            {
                setRequestingState((prevState) => ({ ...prevState, customerPartner: false }))
            } else if (data.type === 'source')
            {
                setRequestingState((prevState) => ({ ...prevState, source: false }))
            } else if (data.type === 'location')
            {
                setRequestingState((prevState) => ({ ...prevState, location: false }))
            } else if (data.type === 'roles')
            {
                setRequestingState((prevState) => ({ ...prevState, roles: false }))
            } else if (data.type === 'competences')
            {
                setRequestingState((prevState) => ({ ...prevState, competences: false }))
            } else if (data.type === 'skills')
            {
                setRequestingState((prevState) => ({ ...prevState, skills: false }))
            } else if (data.type === 'certificates')
            {
                setRequestingState((prevState) => ({ ...prevState, certificates: false }))
            }
            if (data.title !== undefined)
            {
                updateOppo['title'] = data.title;
            }
            if (data.externalId !== undefined)
            {
                updateOppo['externalId'] = data.externalId;
            }
            if (data.projectStart !== undefined && data.projectStart !== null)
            {
                updateOppo['projectStart'] = new Date(data.projectStart);
            }
            if (data.projectEnd !== undefined && data.projectEnd !== null)
            {
                updateOppo['projectEnd'] = new Date(data.projectEnd);
            }
            if (data.publishDate !== undefined && data.publishDate !== null)
            {
                updateOppo['publishDate'] = new Date(data.publishDate);
            }
            if (data.deadline !== undefined && data.deadline !== null)
            {
                updateOppo['deadline'] = new Date(data.deadline);
            }
            if (data.dayPrice !== undefined && data.dayPrice !== null)
            {
                updateOppo['dayPrice'] = data.dayPrice;
            }
            if (data.utilization !== undefined && data.utilization !== null)
            {
                updateOppo['utilization'] = data.utilization;
            }
            if (data.remote !== undefined && data.remote !== null)
            {
                updateOppo['remote'] = data.remote;
            }
            if (data.headCount !== undefined && data.headCount !== null)
            {
                updateOppo['headCount'] = data.headCount;
            }
            if (data.location !== undefined && data.location !== null)
            {
                updateOppo['loc'] = data.location;
            }
            if (data.customer !== undefined && data.customer !== null)
            {
                updateOppo['customer'] = data.customer;
            }
            if (data.partner !== undefined && data.partner !== null)
            {
                updateOppo['partner'] = data.partner;
            }
            if (data.sourceName !== undefined && data.sourceName !== null)
            {
                updateOppo['sourceName'] = data.sourceName;
            }
            if (data.sourceURL !== undefined && data.sourceURL !== null)
            {
                updateOppo['sourceURL'] = data.sourceURL;
            }
            if (data.roles !== undefined && data.roles !== null)
            {
                updateOppo['roles'] = data.roles;
            }
            if (data.primaryRole !== undefined && data.primaryRole !== null)
            {
                updateOppo['primaryRole'] = data.primaryRole;
            }
            if (data.competences !== undefined && data.competences !== null)
            {
                updateOppo['competences'] = data.competences;
            }
            if (data.skills !== undefined && data.skills !== null)
            {
                updateOppo['skills'] = data.skills;
            }
            if (data.certificates !== undefined && data.certificates !== null)
            {
                updateOppo['certificates'] = data.certificates;
            }
            setStateOpportunity((prevState) => ({ ...prevState, ...updateOppo }));
        });
        setSubKey(subKey);
        return () =>
        {
            return WebSocketClient.unsubscripe(subKey)
        }
    }, [])

    const boxStyle = {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        cursor: 'pointer',
        gap: '7px',
        color: 'primary.contrastText',
        backgroundColor: 'primary.main',
        padding: '10px',
        '&:hover': {
            backgroundColor: 'primary.dark',
            color: 'primary.contrastText',
            // opacity: [0.9, 0.8, 0.7],
        },
    };

    const requestAnalyse = (type: EOpportunityRequestTypes | EOpportunityRequestTypes[]) =>
    {
        const request = {
            title: false,
            externalId: false,
            projectDates: false,
            projectNumbers: false,
            customerPartner: false,
            source: false,
            location: false,
            roles: false,
            competences: false,
            skills: false,
            certificates: false,
        };
        if (Array.isArray(type))
        {
            type.forEach((t) =>
            {
                request[t] = true;
            });
        } else
        {
            request[type] = true;
        }
        WebSocketClient.sendMessage(topic, {
            text: stateOpportunity.requestText,
            key: subKey,
            entityId: originalOpportunity?.id || undefined,
            request
        });
        if (Array.isArray(type))
        {
            setRequestingState((prevState) => ({
                ...prevState,
                ...type.reduce((acc, t) => ({ ...acc, [t]: true }), {}),
            }));
        } else
        {
            setRequestingState((prevState) => ({ ...prevState, [type]: true }));
        }
    };

    return (
        <Dialog
            id={id}
            title={originalOpportunity ? originalOpportunity.title : langStrings.newOpportunity}
            bigWidth
            onClose={() => resolve && resolve(null)}
            footer={
                <>
                    <div style={{ float: 'left' }}>
                        {
                            (createType === 'ai' && loadingText === "" && dialogStep === 1) &&
                            <Button
                                icon={<ArrowLeft />}
                                disabled={false}
                                text={langStrings.back}
                                size={'normal'}
                                onClick={async function (): Promise<void>
                                {
                                    if (dialogStep === 1)
                                    {
                                        setLoadingText("");
                                        setErrorText("")
                                        setDialogStep(0);
                                    }
                                }}
                            />
                        }
                    </div>
                    <div style={{ float: 'right' }}>
                        {createType === 'manuel' &&
                            <Button
                                icon={<Done />}
                                disabled={saving || !validateForm()}
                                text={!originalOpportunity ? langStrings.create : langStrings.save}
                                size={'normal'}
                                onClick={onSave}
                            />
                        }
                        {(createType === 'ai' && loadingText === "" && dialogStep === 0) &&
                            <Button
                                icon={<Insights />}
                                disabled={!aiConfirmation}
                                text={langStrings.analyse}
                                size={'normal'}
                                onClick={async function (): Promise<void>
                                {
                                    if (dialogStep === 0)
                                    {
                                        startAnalyse();
                                    }
                                }}
                            />
                        }
                        {
                            (createType === 'ai' && loadingText === "" && dialogStep === 1) &&
                            <Button
                                icon={<Check />}
                                disabled={false}
                                text={langStrings.okay}
                                size={'normal'}
                                onClick={async function (): Promise<void>
                                {
                                    if (dialogStep === 1)
                                    {
                                        setLoadingText("");
                                        resolve && resolve(null)
                                    }
                                }}
                            />
                        }
                    </div>
                </>
            }
        >
            {createType === null &&
                <div style={{
                    display: 'flex',
                    gap: 20,
                    alignItems: 'stretch',
                    justifyContent: 'center',
                    padding: 15,
                }}>
                    <Box
                        onClick={() => setCreateType('manuel')}
                        sx={boxStyle}
                    >
                        <div style={{ fontSize: 20, fontWeight: 600, }}>
                            {langStrings.emptyRequest}
                        </div>
                        <div style={{
                            fontSize: 14, maxWidth: 300, textAlign: 'center'
                        }}>
                            {langStrings.emptyRequestDescription}
                        </div>
                    </Box >
                    <Box
                        onClick={() => setCreateType('ai')}
                        sx={boxStyle}
                    >
                        <div style={{ fontSize: 20, fontWeight: 600, }}>
                            {langStrings.aiRequest}
                        </div>
                        <div style={{ fontSize: 14, maxWidth: 300, textAlign: 'center' }}>
                            {langStrings.aiRequestDescription}
                        </div>
                    </Box >
                </div>
            }
            {
                createType === "manuel" &&
                <ManuelCreateType
                    stateOpportunity={stateOpportunity}
                    requestingState={requestingState}
                    errorText={errorText}
                    requestAnalyse={requestAnalyse}
                    setErrorText={setErrorText}
                    setStateOpportunity={setStateOpportunity} />
            }
            {
                createType === "ai" &&
                <AICreateType loadingText={loadingText} dialogStep={dialogStep} aiConfirmation={aiConfirmation}
                    errorText={errorText}
                    setAiConfirmation={setAiConfirmation}
                    setText={setText} />
            }
        </Dialog >
    );
}

export default OpportunityDialog;

interface AICreateTypeProps
{
    loadingText: string
    errorText: string
    dialogStep: number
    aiConfirmation: boolean
    setAiConfirmation: (val: boolean) => void
    setText: (text: string) => void
}

/**
 * Creates a JSX element for AICreateType.
 *
 * @param {string} props.errorText - The error text.
 * @param {string} props.loadingText - The loading text.
 * @param {string} props.dialogStep - The dialog step.
 * @param {string} props.aiConfirmation - The AI confirmation.
 * @param {function} props.setText - The function to set text.
 * @param {function} props.setAiConfirmation - The function to set AI confirmation.
 * @returns {JSX.Element} - The created JSX element.
 */
function AICreateType({ errorText, loadingText, dialogStep, aiConfirmation, setText, setAiConfirmation }: AICreateTypeProps)
{
    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const [returnJSON, setReturnJSON] = useState<Opportunity | null>(null);

    return <div>
        {
            loadingText !== "" && <LoadingBox text={loadingText} />
        }
        {
            errorText !== "" && errorText
        }
        {
            dialogStep === 0 && loadingText === "" && <AiConfirmation
                confirmed={aiConfirmation}
                setConfirmed={setAiConfirmation}
                originalText={""}
                setText={(text) =>
                {
                    setText(text)
                }}
            />

        }
        {
            returnJSON && dialogStep === 1 && loadingText === "" &&
            <div>Suggestions</div>
        }
        {
            returnJSON && dialogStep === 1 && loadingText === "" && <SkillsInput
                allSkills={allSkills}
                skills={returnJSON.skills}
                showMode={true}
                size={'medium'}
                allowNewSkills={true}
                onChange={function (val: { title: string; id?: number | undefined; level: number; }[]): void
                {
                    returnJSON.skills = val as ISkill[];
                    setReturnJSON(returnJSON);
                }} />
        }
    </div>
}

interface ManuelCreateTypeProps
{
    stateOpportunity: IOpportunityNew
    errorText: string
    requestingState: TOpportunityRequestingState
    requestAnalyse: (type: EOpportunityRequestTypes | EOpportunityRequestTypes[]) => void
    setErrorText: (text: string) => void
    setStateOpportunity: (op: (prev: IOpportunityNew) => IOpportunityNew) => void;
}

/**
 * Function to create a Oppo manuel element.
 *
 * @param {ManuelCreateTypeProps} stateOpportunity - The state opportunity.
 * @param {string} errorText - The error text.
 * @param {function} setErrorText - The function to set the error text.
 * @param {function} setStateOpportunity - The function to set the state opportunity.
 * 
 * @returns {JSX.Element} The created ManuelCreateType element.
 */
function ManuelCreateType({ stateOpportunity, requestingState, errorText, requestAnalyse, setErrorText, setStateOpportunity }: ManuelCreateTypeProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).opportunities;
    const [openTab, setOpenTab] = React.useState<number>(0);
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);

    const { combinedSkills, combinedCertificates, combinedRoles } = combinedKnowledge(stateOpportunity as Opportunity, allCompetences);

    return (
        <>
            <TabContainer
                openTab={openTab}
                setOpenTab={setOpenTab}
                pages={[
                    {
                        title: langStrings.overview,
                        page: <></>
                    },
                    {
                        title: langStrings.employees,
                        page: <></>
                    }
                ]}
            />
            <div className="opportunityDialog">
                {openTab === 0 &&
                    <OpporunityForm
                        opportunity={stateOpportunity}
                        requestingState={requestingState}
                        requestAnalyse={requestAnalyse}
                        setOpportunity={setStateOpportunity}
                    ></OpporunityForm>
                }
                {openTab === 1 &&
                    <EmployeeTab
                        neededSkills={combinedSkills.map(s => ({ id: s.id, title: s.title, level: s.level }))}
                        neededCertificates={combinedCertificates.map(s => ({ id: s.id, title: s.title }))}
                        neededRoles={combinedRoles.map(s => ({ id: s.id, title: s.title }))}
                        headCount={stateOpportunity.headCount}
                        selectedEmployees={stateOpportunity.selectedEmployees}

                        setSelectedEmployees={(val) => setStateOpportunity((prevOppo) => ({
                            ...prevOppo,
                            selectedEmployees: val
                        })
                        )
                        }
                    />
                }
                {
                    errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>
                }

            </div>
        </>
    )
}