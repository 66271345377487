import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";

import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation deleteApiKey($id: Int!) {
    deleteApiKey(id: $id) 
    ${companySettingsGql}
},
`;


export default async function deleteApiKey(id: number)
{
    const variables: OperationVariables = {
        id
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c));
}