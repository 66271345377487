import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";


const questionEmployeeSkillMutation = gql`
  mutation questionEmployeeSkill($skillId: Int!, $employeeId: Int!, $level: Int!, $message: String!) {
    questionEmployeeSkill(skillId: $skillId, employeeId: $employeeId, level: $level, message: $message)
  }
`;

export default async function questionEmployeeSkill(skillId: number, employeeId: number, level: number, message: string)
{
  const variables: OperationVariables = { skillId, employeeId, level, message };
  const result = await GraphQL.mutate<boolean>(questionEmployeeSkillMutation, variables);

  return result;
}