import React from 'react';
import { validateDate } from '@src/Objects/Helper';
import { Typography } from '@mui/material';

import styles from './DateInput.module.css';


interface DateInputProps
{
    value: Date | undefined
    disabled?: boolean
    helperText?: string
    onChange(val: Date | undefined): void

}
/**
 * DateInput component.
 *
 * @param {DateInputProps} props - The props for configuring the DateInput component.
 * @returns {JSX.Element} - The rendered DateInput component.
 */
export default function DateInput(props: DateInputProps)
{
    const value = typeof props.value === 'string' ? new Date(props.value) : props.value;
    return (
        <>
            <input
                type="date"
                className={styles.dateInput}
                disabled={props.disabled}
                value={(value !== undefined && value !== null) ? value.toISOString().substr(0, 10) : ''}
                onChange={e =>
                {
                    const newDate = validateDate(e.target.value);
                    props.onChange(newDate)
                }} />
            {props.helperText && <Typography variant="caption" color="textSecondary">{props.helperText}</Typography>}
        </>
    );
}