import React from "react";
import { useSelector } from "react-redux";
import InnerLayout from "@sharedReact/Layouts/InnerLayout/InnerLayout";
import { AppState } from "@store/store";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import './SalesPage.css'
import { ELinks } from "@sharedInterfaces/globalEnums";

import { BoxButton, BoxButtons } from "../../Components/BoxButton/BoxButton";


/**
 * Represents the SalesPage component.
 * 
 * @returns {JSX.Element} The JSX element for the SalesPage.
 */
export default function SalesPage()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).sales;
    return (
        <InnerLayout title={langStrings.sales} >
            <div className="page">

                <BoxButtons>
                    <BoxButton
                        title={langStrings.products}
                        description={langStrings.productsDescription}
                        onClick={`/${ELinks.SALES_PRODUCTS}`}
                    />
                    <BoxButton
                        title={langStrings.requests}
                        description={langStrings.requestsDescription}
                        onClick={`/${ELinks.SALES_OPPORTUNITIES}`}
                    />
                    <BoxButton
                        title={langStrings.businesses}
                        description={langStrings.businessesDescription}
                        onClick={`/${ELinks.SALES_CUSTOMERS}`}
                    />
                    <BoxButton
                        title={langStrings.projects}
                        description={langStrings.projectsDescription}
                        onClick={`/${ELinks.SALES_PROJECTS}`}
                    />
                </BoxButtons>
            </div>
        </InnerLayout>
    );
}

