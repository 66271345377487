import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IRegisterCompanyTexts extends ITextBase
{
    confirm: string
    confirmHelper1: string
    confirmHelper2: string
    confirmBody: string
    confirmTitle: string
    companyDataCompanyIdValid: string
    companyDataCompanyIdInvalid: string
    error: string
    loading: string
    companyDataCompanyIdHelper: string
    companyDataCompanyNameHelper: string
    companyDataCompanyId: string
    companyDataCompanyName: string
    companyDataBody: string
    companyDataTitle: string
    registerAccount: string
    accountBody: string
    accountTitle: string
    validating: string
    next: string
    step4: string
    step3: string
    step2: string
    inviteCodeInvalid: string
    inviteCodeValid: string
    check: string
    inviteCodeHelper: string
    inviteCode: string
    validation1: string
    step1: string
    register: string
    welcomeText1: string
    welcomeText2: string
}
type ILangData = {
    [lang in ELanguage]: IRegisterCompanyTexts
}
export const registerCompanyTexts: ILangData = {
    'en': {
        oftenUsed: oftenUsedTranslations.en,
        confirm: 'Confirm',
        confirmHelper1: "By clicking on \"Confirm\", your company will be created in our system, and you will be directed to the application. There you can start immediately and make additional settings for your company. This includes adjusting colors and style, editing level definitions, syncing with Clockodo, setting holidays, as well as setting up organizational units and users, among other things.",
        confirmHelper2: "Please confirm that all information is correct, and then click on \"Confirm\" to complete the process and start setting up your company.",
        confirmBody: "You're almost there! Please review the following information of your business before you proceed:",
        confirmTitle: 'Company Details Confirmation',
        companyDataCompanyIdValid: 'The short code is available!',
        companyDataCompanyIdInvalid: 'The desired abbreviation is unfortunately already taken.',
        error: 'An error has occurred. Please try again.',
        loading: 'Loading...',
        companyDataCompanyIdHelper: 'Create an abbreviation for your company, using letters and/or numbers. This abbreviation is for identifying your company on our platform. We will immediately check if the chosen abbreviation is available.',
        companyDataCompanyNameHelper: 'Please enter a company name.',
        companyDataCompanyId: 'Abbreviation',
        companyDataCompanyName: 'Company Name',
        companyDataBody: 'Please enter the data of your company. This information is required for the creation of your company profile in our system. A unique abbreviation for your company is needed to facilitate identification within our platform.',
        companyDataTitle: 'Capture Company Information',
        registerAccount: 'Create account',
        accountBody: 'The next step is setting up your customer account. You will now be redirected to our registration page. There you can create your account quickly and easily. Please remember to confirm your email address to complete the registration. After you have completed these steps, you will be automatically redirected to continue the process here.',
        accountTitle: 'Setting up your customer account',
        validating: 'Validating the code...',
        next: 'Next',
        step4: 'Overview & Confirmation',
        step3: 'Company Data',
        step2: 'User account creation',
        inviteCodeInvalid: 'The entered invitation code seems to be invalid. Please check the code for typos and try again. If you continue to have problems, please contact our support team for help.',
        inviteCodeValid: 'Your invitation code is correct. Welcome aboard! You can now proceed with the registration process.',
        check: 'Check',
        inviteCodeHelper: 'Please enter the special code you received. Registration is not possible without this code.',
        inviteCode: 'Invitation code',
        validation1: 'Please enter your invitation code to proceed. Currently, our platform is in a closed beta phase, and access is limited to selected companies only. Your invitation code confirms that your company is part of this exclusive circle. We look forward to shaping the future of skill and certificate management together with you and your company.',
        step1: 'Welcome & Check Invitation',
        register: 'Register company',
        welcomeText1: 'Welcome to the Competence Manager!',
        welcomeText2: 'Thank you for choosing us. Our platform offers you an innovative solution for managing skills and certificates in your organization. We are proud to provide you with a tailored experience, perfectly adjusted to your needs.',

    },
    'th': {
        oftenUsed: oftenUsedTranslations.th,
        confirm: 'ยืนยัน',
        confirmTitle: 'ยืนยันข้อมูลของบริษัท',
        confirmBody: 'คุณเกือบเสร็จสิ้นละ! โปรดตรวจสอบข้อมูลบริษัทด้านล่างของคุณก่อนที่คุณจะดำเนินการต่อ:',
        confirmHelper1: 'ด้วยการคลิก "ยืนยัน" บริษัทของคุณจะถูกสร้างขึ้นในระบบของเราและคุณจะถูกนำไปยังแอพพลิเคชัน ที่นั่นคุณสามา ารถเริ่มต้นทันทีและทำการตั้งค่าบริษัทของคุณเพิ่มเติม ซึ่งรวมถึงการปรับแต่งสีและสไตล์ การแก้ไขคำจำกัดความของระดับ การซิงค์กับ Clockodo การตั้งวันหยุด และการตั้งค่าหน่วยงานและผู้ใช้นอกจากนี้',
        confirmHelper2: 'โปรดยืนยันว่าข้อมูลทั้งหมดถูกต้องและคลิก "ยืนยัน" เพื่อทำการดำเนินการไปกับการตั้งค่าบริษัทของคุณ',
        companyDataCompanyIdValid: 'รหัสย่อนี้สามารถใช้งานได้!',
        companyDataCompanyIdInvalid: 'ตัวย่อที่คุณต้องการนั้นถูกใช้ไปแล้วน่าเสียดาย',
        error: 'เกิดข้อผิดพลาด กรุณาลองใหม่.',
        loading: 'กำลังโหลด...',
        companyDataCompanyIdHelper: 'สร้างชื่อย่อสำหรับบริษัทของคุณ โดยใช้ตัวอักษรและ/หรือตัวเลข ชื่อย่อนี้จะใช้ในการระบุบริษัทของคุณในแพลตฟอร์มของเรา เราจะทำการตรวจสอบทันทีว่าชื่อย่อที่คุณเลือกสามารถใช้งานได้หรือไม่',
        companyDataCompanyNameHelper: 'กรุณาใส่ชื่อบริษัท',
        companyDataCompanyId: 'ตัวย่อ',
        companyDataCompanyName: 'ชื่อบริษัท',
        companyDataBody: 'กรุณาใส่ข้อมูลของบริษัทของคุณ ข้อมูลเหล่านี้จำเป็นสำหรับการสร้างโปรไฟล์บริษัทในระบบของเรา โปรดใช้ตัวย่อที่ไม่ซ้ำกันสำหรับบริษัทของคุณเพื่อทำให้การระบุตัวตนภายในแพลตฟอร์มของเราง่ายขึ้น',
        companyDataTitle: 'รับข้อมูลบริษัท',
        registerAccount: 'สร้างบัญชี',
        accountBody: 'ขั้นตอนถัดไปคือการตั้งค่าบัญชีของคุณ คุณจะถูกนำไปยังหน้าสมัครสมาชิกของเรา ที่คุณสามารถสร้างบัญชีใหม่ได้อย่างง่ายดาย โปรดอย่าลืมยืนยันอีเมลของคุณเพื่อสมบูรณ์การสมัครสมาชิก หลังจากที่คุณเสร็จสิ้นขั้นตอนเหล่านี้ คุณจะถูกนำกลับมาอัตโนมัติเพื่อดำเนินกระบวนการที่นี่ต่อ',
        accountTitle: 'การตั้งค่าบัญชีคุณ',
        validating: 'กำลังตรวจสอบรหัส...',
        next: 'ถัดไป',
        step4: 'ภาพรวมและการยืนยัน',
        step3: 'ข้อมูลบริษัท',
        step2: 'การสร้างบัญชีผู้ใช้',
        inviteCodeInvalid: 'รหัสเชิญที่คุณป้อนดูเหมือนจะไม่ถูกต้อง โปรดตรวจสอบรหัสเชิญด้วยความระมัดระวังและลองอีกครั้ง หากคุณยังพบปัญหา กรุณาติดต่อทีมสนับสนุนของเราเพื่อขอความช่วยเหลือ',
        inviteCodeValid: 'รหัสเชิญของคุณถูกต้อง ยินดีต้อนรับในครั้งนี้! คุณสามารถดำเนินการต่อในกระบวนการสมัครสมาชิกได้แล้ว',
        check: 'ตรวจสอบ',
        inviteCodeHelper: 'กรุณาใส่รหัสพิเศษที่คุณได้รับ ไม่สามารถลงทะเบียนได้หากไม่มีรหัสนี้',
        inviteCode: 'รหัสเชิญ',
        validation1: 'กรุณาใส่รหัสเชิญของคุณเพื่อดำเนินการต่อ ในปัจจุบันแพลตฟอร์มของเราอยู่ในช่วงเบต้าที่ปิด และการเข้าถึงสงวนไว้สำหรับบริษัทที่ถูกเลือกเท่านั้น รหัสเชิญของคุณยืนยันว่าบริษัทของคุณเป็นส่วนหนึ่งของกลุ่มพิเศษนี้ เรายินดีที่จะรับภารกิจร่วมกับคุณและบริษัทของคุณในการกำหนดทิศทางสำหรับการจัดการทักษะและใบรับรองในอนาคต',
        step1: 'ยินดีต้อนรับ & ตรวจสอบคำเชิญ',
        register: 'ลงทะเบียนบริษัท',
        welcomeText1: 'ยินดีต้อนรับสู่ Competence Manager!',
        welcomeText2: 'ขอบคุณที่คุณเลือกเรา แพลตฟอร์มของเรานำเสนอสำหรับคุณการจัดการทักษะและใบรับรองในองค์กรของคุณรูปแบบใหม่ เราภูมิใจที่สามารถนำเสนอประสบการณ์ที่คัดสรรได้สำหรับคุณที่เหมาะกับความต้องการของคุณ',

    },
    'de': {
        oftenUsed: oftenUsedTranslations.de,
        welcomeText1: 'Willkommen im Competence Manager!',
        welcomeText2: 'Danke, dass du dich für uns entschieden hast. Unsere Plattform bietet dir eine innovative Lösung für das Management von Fähigkeiten und Zertifikaten in deinem Unternehmen. Wir sind stolz darauf, dir eine maßgeschneiderte Erfahrung bieten zu können, die genau auf deine Bedürfnisse zugeschnitten ist.',
        validation1: 'Gib bitte deinen Einladungscode ein, um fortzufahren. Aktuell befindet sich unsere Plattform in einer geschlossenen Beta-Phase, und der Zugang ist ausschließlich ausgewählten Firmen vorbehalten. Dein Einladungscode bestätigt, dass deine Firma zu diesem exklusiven Kreis gehört. Wir freuen uns darauf, gemeinsam mit dir und deinem Unternehmen die Zukunft des Skill- und Zertifikatsmanagements zu gestalten.',
        register: 'Firma registrieren',
        step1: 'Willkommen & Einladung prüfen',
        step2: 'Nutzerkonto Anlage',
        step3: 'Firmen-Daten',
        step4: 'Übersicht & Bestätigung',
        inviteCode: 'Einladungscode',
        inviteCodeValid: 'Dein Einladungscode ist korrekt. Willkommen an Bord! Du kannst nun mit dem Registrierungsprozess fortfahren.',
        inviteCodeInvalid: 'Der eingegebene Einladungscode scheint ungültig zu sein. Bitte überprüfe den Code auf Tippfehler und versuche es erneut. Solltest du weiterhin Probleme haben, wende dich bitte an unser Support-Team für Hilfe.',
        inviteCodeHelper: 'Bitte gib den speziellen Code ein, den du erhalten hast. Ohne diesen Code ist eine Registrierung nicht möglich.',
        check: 'Prüfen',
        next: 'Weiter',
        validating: 'Der Code wird geprüft...',
        accountTitle: 'Einrichtung deines Kundenkontos',
        accountBody: 'Als nächster Schritt steht die Einrichtung deines Kundenkontos an. Du wirst nun zu unserer Registrierungsseite weitergeleitet. Dort kannst du dein Konto schnell und einfach erstellen. Bitte denke daran, deine E-Mail-Adresse zu bestätigen, um die Registrierung abzuschließen. Nachdem du diese Schritte erledigt hast, wirst du automatisch zurückgeleitet, um den Prozess hier fortzusetzen.',
        registerAccount: 'Konto anlegen',
        companyDataTitle: 'Firmeninformationen erfassen',
        companyDataBody: 'Bitte trage die Daten deiner Firma ein. Diese Informationen sind für die Erstellung deines Unternehmensprofils in unserem System notwendig. Ein einzigartiges Kürzel für deine Firma wird benötigt, um die Identifikation innerhalb unserer Plattform zu erleichtern.',
        companyDataCompanyName: 'Firmenname',
        companyDataCompanyId: 'Kürzel',
        companyDataCompanyNameHelper: 'Bitte gib einen Firmenname ein.',
        companyDataCompanyIdHelper: 'Erstelle ein Kürzel für deine Firma, bestehend aus Buchstaben und/oder Zahlen. Dieses Kürzel dient zur Identifikation deiner Firma auf unserer Plattform. Wir prüfen direkt, ob das Kürzel verfügbar ist.',
        loading: 'Wird geladen...',
        error: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        companyDataCompanyIdInvalid: 'Das gewünschte Kürzel ist leider schon vergeben.',
        companyDataCompanyIdValid: 'Das Kürzel ist verfügbar!',
        confirmTitle: 'Bestätigung der Firmendaten',
        confirmBody: 'Du bist fast fertig! Überprüfe bitte die folgenden Informationen deiner Firma, bevor du fortfährst:',
        confirmHelper1: 'Durch Klicken auf "Bestätigen" wird deine Firma in unserem System angelegt, und du wirst in die Anwendung weitergeleitet. Dort kannst du direkt loslegen und weitere Einstellungen für deine Firma vornehmen. Dazu gehören die Anpassung von Farben und Stil, das Bearbeiten von Level-Definitionen, die Synchronisation mit Clockodo, das Festlegen von Feiertagen, sowie das Einrichten von Organisationseinheiten und Nutzern, unter anderem.',
        confirmHelper2: 'Bitte bestätige, dass alle Informationen korrekt sind, und klicke dann auf "Bestätigen", um den Vorgang abzuschließen und mit der Einrichtung deiner Firma zu beginnen.',
        confirm: 'Bestätigen',
    },
}