import React from 'react';
import { useSelector } from 'react-redux';
import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { Tooltip } from '@mui/material';

interface ISalesField
{
    title?: string | JSX.Element;
    source?: 'booking' | 'forecast' | 'mixed'
    bookedHours: number;
    plannedHours: number;
    bookedRevenue: number;
    plannedRevenue: number;
    absenceHours: number;
    showUnbilableProjectTime: boolean;
    unbilableProjectTime: number;
    showTrend?: true;
    hideRevenue?: true;
    trendSensitivity: number;
}

const TEXT_COLOR = '#aaa';
export function SalesField({
    title,
    source,
    bookedHours,
    plannedHours,
    bookedRevenue,
    plannedRevenue,
    absenceHours,
    showUnbilableProjectTime,
    unbilableProjectTime,
    showTrend,
    hideRevenue,
    trendSensitivity,
}: ISalesField)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;

    let trendIcon = null;
    let trendBox = null;
    let trendTitle = "+100%";

    if (showTrend && source === 'booking' && (plannedRevenue || bookedRevenue))
    {
        const trendUp = plannedRevenue < bookedRevenue;
        const trendFactor = bookedRevenue / plannedRevenue; // Umkehren der Berechnung
        if (trendFactor === Infinity)
        {
            trendTitle = '+100%';
        } else if (plannedRevenue !== 0)
        {
            // Bei einem Faktor > 1 ist der gebuchte Umsatz höher, bei < 1 der geplante Umsatz
            const trendPercent = Math.round((trendFactor - 1) * 100);
            trendTitle = `${trendUp ? '+' : '-'}${Math.abs(trendPercent)}%`;
        }

        if (trendFactor < (1 + trendSensitivity / 100) && trendFactor > (1 - trendSensitivity / 100))
        {
            trendIcon = <TrendingFlat fontSize={'small'} htmlColor={TEXT_COLOR} />;
        }
        else
        {
            trendIcon = trendUp
                ? <TrendingUp fontSize={'small'} htmlColor={TEXT_COLOR} />
                : <TrendingDown fontSize={'small'} htmlColor={TEXT_COLOR} />;
        }
        trendBox = <Tooltip
            title={`${langStrings.trendBorderTitle} => ${trendTitle}`}
            arrow
        >
            <div>
                {trendIcon}
            </div>
        </Tooltip>
    }



    if (
        bookedHours === 0
        && plannedHours === 0
        && bookedRevenue === 0
        && plannedRevenue === 0
        && absenceHours === 0
    )
    {
        return <div
            className={`orgUnitPageMonthBox`}
        // title={title}
        >
            <div>
                -
            </div>
            {trendBox}
        </div>;
    }

    const hoursSum = Math.round(
        (
            bookedHours
            - (showUnbilableProjectTime ? 0 : unbilableProjectTime)
            + (source === 'booking' ? 0 : plannedHours)
            + absenceHours
        )
        * 100)
        / 100;
    const revenueSum = Math.round(
        bookedRevenue
        + (source === 'booking' ? 0 : plannedRevenue)
    );
    const showNoRevenueColor = revenueSum === 0 && !hideRevenue && source;
    const className = `orgUnitPageMonthBox ${source} ${(showNoRevenueColor) ? 'noRevenue' : ''}`;
    return <div
        className={className}
    >
        <Tooltip
            title={title}
            arrow
        >
            <div>
                {!hideRevenue &&
                    <div>
                        {`${revenueSum.toLocaleString()}€`}
                    </div>
                }
                <div>
                    {`${hoursSum.toLocaleString()}${langStrings.hoursShort}`}
                </div>
            </div>
        </Tooltip>
        {trendBox}
    </div>;
}
