import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IRestDocuTexts extends ITextBase
{
    endpointCreateRequestAnalyseObjectPublishDate: string;
    endpointCreateRequestAnalyseObjectDeadline: string;
    endpointCreateRequestAnalyseObjectSourceName: string;
    endpointCreateRequestAnalyseObjectSourceUrl: string;
    endpointCreateRequestAnalyseObjectStatus: string;
    endpointCreateRequestAnalyseObjectExternalId: string;
    endpointCreateRequestAnalyseParameterSource: string;
    endpointCreateRequestAnalyseParameterExternalId: string;
    endpointAnalyseRequestDescription: string;
    endpointAnalyseRequestTitle: string;
    s3SignedUrlResponseFields: string;
    s3SignedUrlResponseId: string;
    s3SignedUrlResponseUrl: string;
    s3SignedUrlResponse: string;
    endpointRequestUploadAttachmentOverride: string;
    endpointRequestUploadAttachmentFilename: string;
    endpointRequestUploadAttachmentTitle: string;
    endpointRequestUploadAttachmentDescription: string;
    endpointCreateRequestResponseSuggestedEmployees: string;
    endpointGetRequestTitle: string;
    endpointGetRequestDescription: string;
    endpointGetRequestId: string;
    // endpointGetRequestOptions: string;
    endpointGetRequestOptionsIncludeSuggestedEmployees: string
    endpointGetRequestResponse: string;
    learnHowToUseRestApi: string;
    manageApiKeys: string;
    warning: string;
    authorisationHint: string;
    authorisationHelper: string;
    authorisationDescription: string;
    authorisation: string;
    endpointCreateRequestEmployeeCoverageScore: string;
    endpointCreateRequestEmployeeCoverageSkillCoverage: string;
    endpointCreateRequestEmployeeCoverageTitle: string;
    endpointCreateRequestEmployeeCoverageId: string;
    endpointCreateRequestEmployeeCoverage: string;
    endpointCreateRequestCertificateTitle: string;
    endpointCreateRequestCertificateId: string;
    endpointCreateRequestCertificate: string;
    endpointCreateRequestSkillTitle: string;
    endpointCreateRequestSkillId: string;
    endpointCreateRequestSkill: string;
    endpointCreateRequestCompetenceLevel: string;
    endpointCreateRequestCompetenceTitle: string;
    endpointCreateRequestCompetenceId: string;
    endpointCreateRequestCompetence: string;
    endpointCreateRequestRoleTitle: string;
    endpointCreateRequestRoleId: string;
    endpointCreateRequestRole: string;
    endpointCreateRequestResponseCreatedBy: string;
    endpointCreateRequestResponseCreatedAt: string;
    endpointCreateRequestResponseChangedBy: string;
    endpointCreateRequestResponseChangedAt: string;
    endpointCreateRequestResponseVersion: string;
    endpointCreateRequestResponseCertificates: string;
    endpointCreateRequestResponseSkills: string;
    endpointCreateRequestResponseCompetences: string;
    endpointCreateRequestResponseRoles: string;
    endpointCreateRequestResponseSelectedEmployees: string;
    endpointCreateRequestResponseLoc: string;
    endpointCreateRequestResponseProjectEnd: string;
    endpointCreateRequestResponseProjectStart: string;
    endpointCreateRequestResponseHeadCount: string;
    endpointCreateRequestResponseRemote: string;
    endpointCreateRequestResponseUtilization: string;
    endpointCreateRequestResponseDayPrice: string;
    endpointCreateRequestResponseRequestText: string;
    endpointCreateRequestResponseCustomer: string;
    endpointCreateRequestResponsePartner: string;
    endpointCreateRequestResponseLocation: string;
    endpointCreateRequestResponseTitle: string;
    endpointCreateRequestResponseActive: string;
    endpointCreateRequestResponseId: string;
    endpointCreateRequestAnalyseParameterCertificates: string;
    endpointCreateRequestAnalyseParameterSkills: string;
    endpointCreateRequestAnalyseParameterCompetences: string;
    endpointCreateRequestAnalyseParameterRoles: string;
    endpointCreateRequestAnalyseParameterLocation: string;
    endpointCreateRequestAnalyseParameterCustomerPartner: string;
    endpointCreateRequestAnalyseParameterProjectNumbers: string;
    endpointCreateRequestAnalyseParameterProjectDates: string;
    endpointCreateRequestAnalyseParameterTitle: string;
    endpointCreateRequestAnalyseParameter: string;
    endpointCreateRequestOptionsAnalyseAll: string;
    endpointCreateRequestOptionsAnalyse: string;
    endpointCreateRequestAnalyseObjectProjectEnd: string;
    endpointCreateRequestAnalyseObjectProjectStart: string;
    endpointCreateRequestAnalyseObjectHeadCount: string;
    endpointCreateRequestAnalyseObjectRemote: string;
    endpointCreateRequestAnalyseObjectUtilization: string;
    endpointCreateRequestAnalyseObjectDayPrice: string;
    endpointCreateRequestAnalyseObjectRequestText: string;
    endpointCreateRequestAnalyseObjectPartner: string;
    endpointCreateRequestAnalyseObjectCustomer: string;
    endpointCreateRequestAnalyseObjectLoc: string;
    endpointCreateRequestAnalyseObjectTitleRequired: string;
    endpointCreateRequestAnalyseObjectTitle: string;
    endpointCreateRequestAnalyseObjectActive: string;
    endpointCreateRequestResponse: string;
    endpointCreateRequestOptions: string;
    endpointCreateRequestAnalyseObject: string;
    endpointCreateRequestDescription: string;
    endpointCreateRequestTitle: string;
    property: string;
    request: string;
    response: string;
    tableOfContents: string;
    required: string;
    endpoints: string;
    objects: string;
    method: string;
    title: string
    parameter: string
    description: string
    type: string
}
type ILangData = {
    [lang in ELanguage]: IRestDocuTexts
}
export const restDocuTexts: ILangData = {
    'th': {
        endpointCreateRequestAnalyseObjectDeadline: 'กำหนดเวลาสำหรับเสร็จสิ้นคำขอโครงการ (วันที่ ISO 8601)',
        endpointCreateRequestAnalyseObjectPublishDate: 'วันที่เผยแพร่คำขอโครงการ (วันที่ ISO 8601)',
        endpointCreateRequestAnalyseObjectStatus: 'สถานะของคำขอ ต้องระบุชื่อสถานะที่จัดเก็บไว้ในระบบที่นี่',
        endpointCreateRequestAnalyseObjectSourceUrl: 'URL สำหรับองค์ประกอบในระบบแหล่ง',
        endpointCreateRequestAnalyseObjectSourceName: 'ชื่อของระบบแหล่ง',
        endpointCreateRequestAnalyseObjectExternalId: 'รหัสภายนอก, สามารถใช้เพื่อการจับคู่อ้างอิงได้.',
        endpointCreateRequestAnalyseParameterSource: 'การวิเคราะห์สำหรับแหล่งที่มาและ URL ของแหล่งที่มา',
        endpointCreateRequestAnalyseParameterExternalId: 'การวิเคราะห์สำหรับรหัสภายนอก (ID จากระบบภายนอก)',
        endpointAnalyseRequestTitle: "คำขอโครงการ: ดำเนินการวิเคราะห์",
        endpointAnalyseRequestDescription: "สิ่งนี้ช่วยให้คุณเริ่มการวิเคราะห์คำขอโครงการตามข้อมูลที่เก็บไว้ในระบบ คุณสามารถระบุตัวเลือกการวิเคราะห์โดยใช้พารามิเตอร์ที่มีอยู่",

        s3SignedUrlResponse: "วัตถุที่มีข้อมูลทั้งหมดที่จำเป็นในการอัปโหลดไฟล์แนบโดยตรงผ่าน POST ไปยัง S3 คุณสมบัติจาก 'fields' จะต้องส่งเป็นฟอร์มข้อมูลพร้อมกับไฟล์ไปยัง URL ที่ระบุ",
        s3SignedUrlResponseId: "ID ของไฟล์แนบ (ไม่จำเป็นในปัจจุบัน)",
        s3SignedUrlResponseUrl: "URL ของ POST ที่ลงนามแล้วเพื่ออัปโหลดไฟล์ไปยัง S3 bucket",
        s3SignedUrlResponseFields: "ฟิลด์ของวัตถุนี้จะต้องส่งเป็นฟอร์มข้อมูลในเนื้อหาของคำขอ",

        endpointRequestUploadAttachmentOverride: 'ต้องการเขียนทับไฟล์ที่มีอยู่หรือไม่?',
        endpointRequestUploadAttachmentFilename: 'ชื่อไฟล์แนบที่จะใช้ในอนาคต (ในระบบ)',
        endpointRequestUploadAttachmentDescription: 'สามารถร้องขอ Signed URL เพื่ออัปโหลดไปยัง aws s3 Bucket จากนั้นสามารถอัปโหลดไฟล์โดยใช้ URL นี้และฟิลด์ที่เกี่ยวข้อง',
        endpointRequestUploadAttachmentTitle: 'คำร้องขอโครงการ: อัปโหลดไฟล์แนบ',
        endpointCreateRequestResponseSuggestedEmployees: 'รายชื่อพนักงานที่แนะนำจะส่งคืนผ่านทาง GET เอ็นด์พอยต์เท่านั้น และเฉพาะเมื่อมีการร้องขอผ่านพารามิเตอร์',
        endpointGetRequestDescription: 'ดึงคำขอโครงการโดยใช้ ID พารามิเตอร์อื่นๆ จะถูกเพิ่มเป็นพารามิเตอร์ GET ใน URL ตัวอย่าง: (*/request/1337?parameter=value)',
        endpointGetRequestOptionsIncludeSuggestedEmployees: 'คำนวณพนักงานที่แนะนำและเพิ่มในการคืนค่า',
        endpointGetRequestResponse: 'คำตอบของการดึงคำขอ',
        // endpointGetRequestOptions: 'พารามิเตอร์เสริมสำหรับดึงคำขอโครงการ',
        endpointGetRequestId: 'ID ของคำขอโครงการที่ต้องการดึง',
        endpointGetRequestTitle: 'ดึงคำขอโครงการ',
        learnHowToUseRestApi: 'ไปที่เอกสารประกอบ REST API ของเราโดยตรง ที่นี่คุณจะพบข้อมูลรายละเอียดเกี่ยวกับปลายทางที่มีอยู่, พารามิเตอร์การขอ, วิธีการอนุญาต รวมถึงตัวอย่างการขอและการตอบกลับ ทรัพยากรนี้เป็นสิ่งจำเป็นสำหรับการเข้าถึงบริการของเราอย่างมีประสิทธิภาพและปลอดภัย เหมาะสำหรับนักพัฒนาที่ต้องการทำงานกับ API ของเรา',
        endpointCreateRequestDescription: 'สร้างคำขอโครงการใหม่หรือโอกาสการขายจากข้อมูลที่ส่งเข้ามา ด้วยตัวเลือกที่มีอยู่ คุณยังสามารถเริ่มการวิเคราะห์ได้ทันที',
        authorisationHint: 'เก็บคีย์ API ไว้ในที่ปลอดภัยและอย่าแบ่งปันกับบุคคลภายนอกองค์กรของคุณเพื่อป้องกันการเข้าถึงโดยไม่ได้รับอนุญาต',
        authorisationHelper: 'แทนที่ <API_KEY> ด้วยคีย์ API เฉพาะขององค์กรของคุณ คีย์ API นี้ทำหน้าที่เป็นหลักฐานการตรวจสอบสิทธิ์ที่ยืนยันว่าการร้องขอมาจากองค์กรของคุณ',
        authorisationDescription: 'การเข้าถึง API ต้องใช้คีย์ API เฉพาะของบริษัทซึ่งทำหน้าที่เป็น Bearer Token คีย์ API นี้ต้องส่งไปพร้อมกับทุกการร้องขอ API ในเฮดเดอร์ Authorization เฮดเดอร์ควรมีรูปแบบดังนี้:',
        authorisation: 'การให้สิทธิ์',
        manageApiKeys: 'จัดการคีย์ API',
        warning: 'คำเตือน',
        endpointCreateRequestEmployeeCoverageScore: 'คะแนน',
        endpointCreateRequestEmployeeCoverageSkillCoverage: 'ความคุ้มครองทักษะ',
        endpointCreateRequestEmployeeCoverageTitle: 'ชื่อพนักงาน',
        endpointCreateRequestEmployeeCoverageId: 'ID ของพนักงาน',
        endpointCreateRequestEmployeeCoverage: 'การประเมินพนักงานที่เลือก',
        endpointCreateRequestCertificateTitle: 'ชื่อใบรับรอง',
        endpointCreateRequestCertificateId: 'ID ของใบรับรอง (ถ้ามี)',
        endpointCreateRequestCertificate: 'ใบรับรองที่เชื่อมต่อกับคำขอ',
        endpointCreateRequestSkillTitle: 'ชื่อทักษะ',
        endpointCreateRequestSkillId: 'ID ของทักษะ (ถ้ามี)',
        endpointCreateRequestSkill: 'ทักษะที่เชื่อมต่อกับคำขอ',
        endpointCreateRequestCompetenceLevel: 'ระดับของความสามารถ',
        endpointCreateRequestCompetenceTitle: 'ชื่อความสามารถ',
        endpointCreateRequestCompetenceId: 'ID ของความสามารถนั้น',
        endpointCreateRequestCompetence: 'ความสามารถที่เชื่อมต่อกับคำขอ',
        endpointCreateRequestRoleTitle: 'ชื่อบทบาท',
        endpointCreateRequestRoleId: 'ID ของบทบาท (ถ้ามี)',
        endpointCreateRequestRole: 'บทบาทที่เชื่อมต่อกับคำขอ',
        endpointCreateRequestResponseCreatedBy: 'ID ของผู้สร้าง',
        endpointCreateRequestResponseCreatedAt: 'เวลาที่สร้าง (วันที่ ISO 8601)',
        endpointCreateRequestResponseChangedBy: 'ID ของคนที่อัพเดตครั้งล่าสุด',
        endpointCreateRequestResponseChangedAt: 'เวลาที่อัพเดตล่าสุด (วันที่ ISO 8601)',
        endpointCreateRequestResponseVersion: 'เวอร์ชันของคำขอโครงการ',
        endpointCreateRequestResponseCertificates: 'รายการใบรับรองที่ต้องการ',
        endpointCreateRequestResponseSkills: 'รายการทักษะที่ต้องการ',
        endpointCreateRequestResponseCompetences: 'รายการความสามารถที่ต้องการ',
        endpointCreateRequestResponseRoles: 'รายการบทบาทที่ต้องการ',
        endpointCreateRequestResponseSelectedEmployees: 'รายการพนักงานที่เลือก',
        endpointCreateRequestResponseLoc: 'สถานที่ทำงาน',
        endpointCreateRequestResponseProjectEnd: 'การสิ้นสุดโครงการตามคำขอ (วันที่ ISO 8601)',
        endpointCreateRequestResponseProjectStart: 'การเริ่มต้นโครงการตามคำขอ (วันที่ ISO 8601)',
        endpointCreateRequestResponseHeadCount: 'จำนวนพนักงานที่ต้องการ',
        endpointCreateRequestResponseRemote: 'สัดส่วนงานระยะไกล',
        endpointCreateRequestResponseUtilization: 'การใช้งานในระหว่างโครงการ',
        endpointCreateRequestResponseDayPrice: 'ราคาต่อวันต่อพนักงาน',
        endpointCreateRequestResponseRequestText: 'ข้อความคำขอเดิม (ใช้สำหรับการวิเคราะห์)',
        endpointCreateRequestResponsePartner: 'พาร์ทเนอร์, ในกรณีที่โครงการไม่ได้ดำเนินการโดยลำพัง',
        endpointCreateRequestResponseCustomer: 'ลูกค้า',
        endpointCreateRequestResponseLocation: 'สถานที่ทำงาน / ปฏิบัติงาน',
        endpointCreateRequestResponseTitle: 'ชื่อเรื่องของคำขอโครงการ',
        endpointCreateRequestResponseActive: 'ระบุว่าคำขอโครงการนั้นใช้งานอยู่หรือไม่',
        endpointCreateRequestResponseId: 'ID ของคำขอโครงการ',
        endpointCreateRequestAnalyseParameterCertificates: 'วิเคราะห์สำหรับใบรับรอง',
        endpointCreateRequestAnalyseParameterSkills: 'วิเคราะห์สำหรับทักษะ',
        endpointCreateRequestAnalyseParameterCompetences: 'วิเคราะห์สำหรับความสามารถ',
        endpointCreateRequestAnalyseParameterRoles: 'วิเคราะห์สำหรับบทบาท',
        endpointCreateRequestAnalyseParameterLocation: 'วิเคราะห์สำหรับสถานที่',
        endpointCreateRequestAnalyseParameterCustomerPartner: 'วิเคราะห์สำหรับลูกค้าและพาร์ทเนอร์',
        endpointCreateRequestAnalyseParameterProjectNumbers: 'วิเคราะห์สำหรับการใช้งาน, ส่วนแบ่งงานระยะไกล, จำนวนพนักงาน และอัตราต่อวัน',
        endpointCreateRequestAnalyseParameterProjectDates: 'วิเคราะห์สำหรับวันเริ่มต้นและวันสิ้นสุดโครงการ',
        endpointCreateRequestAnalyseParameterTitle: 'การวิเคราะห์สำหรับชื่อ',
        endpointCreateRequestAnalyseParameter: 'พารามิเตอร์เหล่านี้สามารถใช้ควบคุมการวิเคราะห์ที่จะถูกทำ',
        endpointCreateRequestOptionsAnalyseAll: 'วิเคราะห์ทุกฟิลด์ที่เป็นไปได้ (การวิเคราะห์ด้วยค่าทั้งหมดที่เป็นจริง)',
        endpointCreateRequestOptionsAnalyse: 'พารามิเตอร์เหล่านี้ช่วยควบคุมการวิเคราะห์ที่คุณต้องการทำ',
        endpointCreateRequestAnalyseObjectProjectEnd: 'วันสิ้นสุดโปรเจคตามคำขอ (รูปแบบวันที่ ISO 8601)',
        endpointCreateRequestAnalyseObjectProjectStart: 'วันเริ่มต้นโปรเจคตามคำขอ (รูปแบบวันที่ ISO 8601)',
        endpointCreateRequestAnalyseObjectHeadCount: 'จำนวนพนักงานที่ต้องการ',
        endpointCreateRequestAnalyseObjectRemote: 'สัดส่วนการทำงานแบบรีโมท',
        endpointCreateRequestAnalyseObjectUtilization: 'การใช้งานในช่วงระยะเวลาโครงการ',
        endpointCreateRequestAnalyseObjectDayPrice: 'ราคาต่อวันต่อพนักงาน',
        endpointCreateRequestAnalyseObjectRequestText: 'ข้อความคำขอเดิม (ใช้สำหรับการวิเคราะห์)',
        endpointCreateRequestAnalyseObjectPartner: 'หุ้นส่วน, กรณีที่โปรเจคไม่ได้ดำเนินการด้วยตัวคุณเอง',
        endpointCreateRequestAnalyseObjectCustomer: 'ลูกค้า',
        endpointCreateRequestAnalyseObjectLoc: 'สถานที่ปฏิบัติงาน',
        endpointCreateRequestAnalyseObjectTitle: 'ชื่อของคำขอโปรเจค',
        endpointCreateRequestAnalyseObjectTitleRequired: 'จำเป็นต้องมี title หรือ requestText',
        endpointCreateRequestAnalyseObjectActive: 'ระบุว่าคำขอโปรเจคนั้นเป็นสถานะที่ใช้งานอยู่หรือไม่',
        endpointCreateRequestResponse: 'undefined',
        endpointCreateRequestOptions: 'ตัวเลือกเหล่านี้เป็นไปได้ให้ควบคุมการเริ่มการวิเคราะห์หรือไม่',
        endpointCreateRequestAnalyseObject: 'เมตาดาต้าที่ต้องการใช้ในการสร้างคำขอ',
        endpointCreateRequestTitle: 'สร้างคำขอโปรเจค',
        property: 'คุณสมบัติ',
        response: 'การตอบกลับ',
        request: 'คำร้อง',
        tableOfContents: 'สารบัญ',
        required: 'จำเป็น',
        endpoints: 'จุดสิ้นสุดการทำงาน',
        objects: 'วัตถุ',
        type: 'ประเภท',
        description: 'คำอธิบาย',
        parameter: 'พารามิเตอร์',
        method: 'วิธีการ',
        title: 'เอกสารประกอบ REST',
        oftenUsed: oftenUsedTranslations['th'],
    },
    'en': {
        endpointCreateRequestAnalyseObjectDeadline: 'Deadline for completing the project request (ISO 8601 date)',
        endpointCreateRequestAnalyseObjectPublishDate: 'Publication date of the project request (ISO 8601 date)',
        endpointCreateRequestAnalyseObjectStatus: 'Status of the request. The name of the status stored in the system must be provided here.',
        endpointCreateRequestAnalyseObjectSourceUrl: 'URL to the element in the source system',
        endpointCreateRequestAnalyseObjectSourceName: 'Name of the source system',
        endpointCreateRequestAnalyseObjectExternalId: 'External ID, can be used for reference matching.',
        endpointCreateRequestAnalyseParameterSource: 'Analysis for source and source URL',
        endpointCreateRequestAnalyseParameterExternalId: 'Analysis for External ID (ID from external system)',
        endpointAnalyseRequestTitle: "Project Request: Perform Analysis",
        endpointAnalyseRequestDescription: "This allows you to initiate an analysis for a project request based on the data stored in the system. You can specify the analysis options using available parameters.",
        s3SignedUrlResponse: "An object that contains all necessary information to directly upload an attachment via POST to S3. The properties from 'fields' must be sent as form-data along with the file to the provided URL.",
        s3SignedUrlResponseId: "The ID of the attachment (currently not required).",
        s3SignedUrlResponseUrl: "The signed POST URL for uploading the file to the S3 bucket.",
        s3SignedUrlResponseFields: "The fields of this object must be passed as form-data in the body of the request.",

        endpointRequestUploadAttachmentOverride: 'Should an existing file be overwritten?',
        endpointRequestUploadAttachmentFilename: 'Future filename of the attachment (In the system)',
        endpointRequestUploadAttachmentDescription: 'This allows you to request a Signed URL for uploading to an AWS S3 bucket. After that, you can upload the file using this URL and the corresponding fields.',
        endpointRequestUploadAttachmentTitle: 'Project Request: Upload Attachment',
        endpointCreateRequestResponseSuggestedEmployees: 'List of suggested employees, returned only via the GET endpoint, and only when explicitly requested by parameter.',
        endpointGetRequestDescription: 'Fetches a project request by ID, other parameters are appended as GET parameters to the URL. Example: (*/request/1337?parameter=value)',
        endpointGetRequestOptionsIncludeSuggestedEmployees: 'Calculate suggested employees and add to the response',
        endpointGetRequestResponse: 'Response of the retrieval request',
        // endpointGetRequestOptions: 'Optional parameters for retrieving the project request',
        endpointGetRequestId: 'The ID of the project request to retrieve',
        endpointGetRequestTitle: 'Retrieve project request',
        learnHowToUseRestApi: 'Head straight to our comprehensive REST API documentation. Here you will find detailed information on available endpoints, request parameters, authorization methods, as well as request and response examples. This resource is essential for accessing our services effectively and securely. Ideal for developers who want to work with our API.',
        authorisationHint: 'Keep the API key secure and do not share it outside your organization to avoid unauthorized access.',
        authorisationHelper: 'Replace <API_KEY> with your organization’s specific API key. This API key acts as proof of authentication, confirming that the requests are authorized by your organization.',
        authorisationDescription: 'Access to the API requires the use of a company-specific API key that acts as a Bearer Token. This API key must be sent with every API request in the Authorization header. The header should be formatted as follows:',
        authorisation: 'Authorisation',
        manageApiKeys: 'Manage API keys',
        warning: 'Warning',
        endpointCreateRequestEmployeeCoverageScore: 'Score',
        endpointCreateRequestEmployeeCoverageSkillCoverage: 'Skill coverage',
        endpointCreateRequestEmployeeCoverageTitle: 'Employee name',
        endpointCreateRequestEmployeeCoverageId: 'The ID of the employee',
        endpointCreateRequestEmployeeCoverage: 'The evaluation of the selected employees',
        endpointCreateRequestCertificateTitle: 'Certificate name',
        endpointCreateRequestCertificateId: 'The ID of the certificate (if available)',
        endpointCreateRequestCertificate: 'The certificates associated with the request',
        endpointCreateRequestSkillTitle: 'Skill name',
        endpointCreateRequestSkillId: 'The ID of the skill (if available)',
        endpointCreateRequestSkill: 'The skills associated with the request',
        endpointCreateRequestCompetenceLevel: 'Level of competence',
        endpointCreateRequestCompetenceTitle: 'Competence name',
        endpointCreateRequestCompetenceId: 'The ID of the competence',
        endpointCreateRequestCompetence: 'The competences associated with the request',
        endpointCreateRequestRoleTitle: 'Role name',
        endpointCreateRequestRoleId: 'The ID of the role (if available)',
        endpointCreateRequestRole: 'The roles associated with the request',
        endpointCreateRequestResponseCreatedBy: 'ID of the creator',
        endpointCreateRequestResponseCreatedAt: 'Time of creation (ISO 8601 date)',
        endpointCreateRequestResponseChangedBy: 'ID of the last editor',
        endpointCreateRequestResponseChangedAt: 'Time of last change (ISO 8601 date)',
        endpointCreateRequestResponseVersion: 'Version number of the project request',
        endpointCreateRequestResponseCertificates: 'List of required certificates',
        endpointCreateRequestResponseSkills: 'List of required skills',
        endpointCreateRequestResponseCompetences: 'List of required competences',
        endpointCreateRequestResponseRoles: 'List of required roles',
        endpointCreateRequestResponseSelectedEmployees: 'List of selected employees',
        endpointCreateRequestResponseLoc: 'Location of activity',
        endpointCreateRequestResponseProjectEnd: 'Project end of the project request (ISO 8601 date)',
        endpointCreateRequestResponseProjectStart: 'Project start of the project request (ISO 8601 date)',
        endpointCreateRequestResponseHeadCount: 'Number of required employees',
        endpointCreateRequestResponseRemote: 'Remote share',
        endpointCreateRequestResponseUtilization: 'Utilization during the project period',
        endpointCreateRequestResponseDayPrice: 'Daily price per employee',
        endpointCreateRequestResponseRequestText: 'Original request text (used for analysis)',
        endpointCreateRequestResponsePartner: 'Partner, if the project is not carried out alone',
        endpointCreateRequestResponseCustomer: 'Customer',
        endpointCreateRequestResponseLocation: 'Place of deployment / Work location',
        endpointCreateRequestResponseTitle: 'Title of the project request',
        endpointCreateRequestResponseActive: 'Indicates whether the project request is active',
        endpointCreateRequestResponseId: 'The ID of the project request',
        endpointCreateRequestAnalyseParameterCertificates: 'Analysis for certifications',
        endpointCreateRequestAnalyseParameterSkills: 'Analysis for skills',
        endpointCreateRequestAnalyseParameterCompetences: 'Analysis for competencies',
        endpointCreateRequestAnalyseParameterRoles: 'Analysis for roles',
        endpointCreateRequestAnalyseParameterLocation: 'Analysis for location',
        endpointCreateRequestAnalyseParameterCustomerPartner: 'Analysis for customers and partners',
        endpointCreateRequestAnalyseParameterProjectNumbers: 'Analysis for utilization, remote share, number of employees and daily rate',
        endpointCreateRequestAnalyseParameterProjectDates: 'Analysis for project start and end dates',
        endpointCreateRequestAnalyseParameterTitle: 'Analysis for title',
        endpointCreateRequestAnalyseParameter: 'These parameters can control which analyses should be conducted.',
        endpointCreateRequestOptionsAnalyseAll: 'Analyze all possible fields. (analysis with all values = true)',
        endpointCreateRequestOptionsAnalyse: 'These parameters can control which analyses should be performed',
        endpointCreateRequestAnalyseObjectProjectEnd: 'Project end of the project request (ISO 8601 date)',
        endpointCreateRequestAnalyseObjectProjectStart: 'Project start of the project request (ISO 8601 date)',
        endpointCreateRequestAnalyseObjectHeadCount: 'Number of required employees',
        endpointCreateRequestAnalyseObjectRemote: 'Remote portion',
        endpointCreateRequestAnalyseObjectUtilization: 'Utilization during the project period',
        endpointCreateRequestAnalyseObjectDayPrice: 'Day price per employee',
        endpointCreateRequestAnalyseObjectRequestText: 'Original request text (used for analysis)',
        endpointCreateRequestAnalyseObjectPartner: 'Partner, if the project is not carried out alone',
        endpointCreateRequestAnalyseObjectCustomer: 'Customer',
        endpointCreateRequestAnalyseObjectLoc: 'Place of deployment / location of activity',
        endpointCreateRequestAnalyseObjectTitle: 'Title of the project request',
        endpointCreateRequestAnalyseObjectTitleRequired: 'A title or requestText must be filled out',
        endpointCreateRequestAnalyseObjectActive: 'Indicates whether the project request is active',
        endpointCreateRequestResponse: 'The created project request as an object',
        endpointCreateRequestOptions: 'The options can control whether an analysis should be initiated',
        endpointCreateRequestAnalyseObject: 'Metadata with which the request should be created',
        endpointCreateRequestDescription: 'Create a new project request or sales opportunity from the submitted data. With the available options, you can also directly initiate an analysis.',
        endpointCreateRequestTitle: 'Create Project Request',
        property: 'property',
        response: 'Response',
        request: 'Request',
        tableOfContents: 'Table of Contents',
        required: 'Required',
        endpoints: 'Endpoints',
        objects: 'Objects',
        type: 'Type',
        description: 'Description',
        parameter: 'Parameter',
        method: 'Method',
        title: 'REST Documentation',
        oftenUsed: oftenUsedTranslations['en'],
    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        title: 'REST-Dokumentation',
        method: 'Methode',
        parameter: 'Parameter',
        description: 'Beschreibung',
        type: 'Typ',
        objects: 'Objekte',
        endpoints: 'Endpunkte',
        required: 'Erforderlich',
        tableOfContents: 'Übersicht',
        request: 'Anfrage',
        response: 'Antwort',
        property: 'Eigensachaft',
        authorisation: 'Autorisierung',
        authorisationDescription: 'Der Zugriff auf die API erfordert die Verwendung eines firmenspezifischen API-Keys, der als Bearer Token dient. Dieser API-Key muss bei jeder API-Anfrage im Authorization-Header mitgesendet werden. Der Header sollte folgendermaßen formatiert sein:',
        authorisationHelper: 'Ersetze <API_KEY> mit dem spezifischen API-Key deiner Organisation. Dieser API-Key dient als Authentifizierungsnachweis, der bestätigt, dass die Anfragen von deiner Organisation autorisiert sind.',
        authorisationHint: 'Bewahre den API-Key sicher auf und teile ihn nicht außerhalb deiner Organisation, um unautorisierten Zugriff zu vermeiden.',
        warning: "Warnung",
        manageApiKeys: 'API-Keys verwalten',
        endpointCreateRequestTitle: 'Projektanfrage erstellen',
        endpointCreateRequestDescription: 'Erstelle eine neue Projektanfrage oder Verkaufschance aus den übermittelten Daten. Mit den verfügbaren Optionen kannst Du zudem direkt eine Analyse starten.',
        endpointCreateRequestAnalyseObject: 'Metadaten mit denen die Anfrage erstellt werden soll',
        endpointCreateRequestOptions: 'Mit den Optionen kann gesteuert werden ob eine Analyse gestartet werden soll',
        endpointCreateRequestResponse: 'Die erstellte Projektanfrage als Objekt',
        endpointCreateRequestAnalyseObjectActive: 'Gibt an, ob die Projektanfrage aktiv ist',
        endpointCreateRequestAnalyseObjectTitleRequired: "title oder requestText muss ausgefüllt sein",
        endpointCreateRequestAnalyseObjectTitle: 'Titel der Projektanfrage',
        endpointCreateRequestAnalyseObjectLoc: 'Einsatzort / Tätigkeitsort',
        endpointCreateRequestAnalyseObjectCustomer: 'Kunde',
        endpointCreateRequestAnalyseObjectPartner: 'Partner, falls das Projekt nicht alleine durchgeführt wird',
        endpointCreateRequestAnalyseObjectRequestText: 'Original Anfrage Text (wird für die Analyse verwendet',
        endpointCreateRequestAnalyseObjectDayPrice: 'Tagespreis pro Mitarbeiter',
        endpointCreateRequestAnalyseObjectUtilization: 'Auslastung wärend der Projektzeit',
        endpointCreateRequestAnalyseObjectRemote: 'Remote-Anteil',
        endpointCreateRequestAnalyseObjectHeadCount: 'Anzahl der benötigten Mitarbeiter',
        endpointCreateRequestAnalyseObjectProjectStart: 'Projektstart der Projektanfrage (ISO 8601 Datum)',
        endpointCreateRequestAnalyseObjectProjectEnd: 'Projektende der Projektanfrage (ISO 8601 Datum)',
        endpointCreateRequestAnalyseObjectExternalId: 'Externe-ID, kann zur Zuordnung verwendet werden.',
        endpointCreateRequestAnalyseObjectSourceName: 'Name des Quellsystems',
        endpointCreateRequestAnalyseObjectSourceUrl: 'URL zum Element im Quellsystems',
        endpointCreateRequestAnalyseObjectStatus: 'Status der Anfrage, hier muss der Name des im System hinterlegtem Status übergeben werden.',
        endpointCreateRequestAnalyseObjectPublishDate: 'Veröffentlichungsdatum der Projektanfrage (ISO 8601 Datum)',
        endpointCreateRequestAnalyseObjectDeadline: 'Frist zum Abschluss der Projektanfrage (ISO 8601 Datum)',
        endpointCreateRequestOptionsAnalyse: 'Mit diesen Parametern kann gesteuert werden welche Analysen ausgeführt werden sollen',
        endpointCreateRequestOptionsAnalyseAll: 'Analysiere alle möglichen Felder. (analyse mit allen werten = true)',
        endpointCreateRequestAnalyseParameter: 'Mit diesen Parametern kann gesteuert werden welche Analysen ausgeführt werden sollen.',
        endpointCreateRequestAnalyseParameterTitle: 'Analyse für Titel',
        endpointCreateRequestAnalyseParameterProjectDates: 'Analyse für Projekt-Start und Projekt-Ende',
        endpointCreateRequestAnalyseParameterProjectNumbers: 'Analyse für Auslastung, Remote-Anteil, Mitarbeiter-Anzahl und Tagessatz',
        endpointCreateRequestAnalyseParameterCustomerPartner: 'Analyse für Kunden und Partner',
        endpointCreateRequestAnalyseParameterLocation: 'Analyse für Ort',
        endpointCreateRequestAnalyseParameterRoles: 'Analyse für Rollen',
        endpointCreateRequestAnalyseParameterCompetences: 'Analyse für Kompetenzen',
        endpointCreateRequestAnalyseParameterSkills: 'Analyse für Fähigkeiten',
        endpointCreateRequestAnalyseParameterCertificates: 'Analyse für Zertifikate',
        endpointCreateRequestAnalyseParameterExternalId: 'Analyse für Externe ID (ID aus fremd System)',
        endpointCreateRequestAnalyseParameterSource: 'Analyse für Quelle und Quell-URL',
        endpointCreateRequestResponseId: 'Die ID der Projektanfrage',
        endpointCreateRequestResponseActive: 'Gibt an, ob die Projektanfrage aktiv ist',
        endpointCreateRequestResponseTitle: 'Titel der Projektanfrage',
        endpointCreateRequestResponseLocation: 'Einsatzort / Tätigkeitsort',
        endpointCreateRequestResponseCustomer: 'Kunde',
        endpointCreateRequestResponsePartner: 'Partner, falls das Projekt nicht alleine durchgeführt wird',
        endpointCreateRequestResponseRequestText: 'Original Anfrage Text (wird für die Analyse verwendet',
        endpointCreateRequestResponseDayPrice: 'Tagespreis pro Mitarbeiter',
        endpointCreateRequestResponseUtilization: 'Auslastung während der Projektzeit',
        endpointCreateRequestResponseRemote: 'Remote-Anteil',
        endpointCreateRequestResponseHeadCount: 'Anzahl der benötigten Mitarbeiter',
        endpointCreateRequestResponseProjectStart: 'Projektstart der Projektanfrage (ISO 8601 Datum)',
        endpointCreateRequestResponseProjectEnd: 'Projektende der Projektanfrage (ISO 8601 Datum)',
        endpointCreateRequestResponseLoc: 'Tätigkeitsort',
        endpointCreateRequestResponseSelectedEmployees: 'Liste der ausgewählten Mitarbeiter',
        endpointCreateRequestResponseSuggestedEmployees: 'Liste der vorgeschlagenen Mitarbeiter, wird nur über den GET Endpunkt zurückgegeben, und nur wenn expliziet per Parameter angefragt.',
        endpointCreateRequestResponseRoles: 'Liste der benötigten Rollen',
        endpointCreateRequestResponseCompetences: 'Liste der benötigten Kompetenzen',
        endpointCreateRequestResponseSkills: 'Liste der benötigten Fähigkeiten',
        endpointCreateRequestResponseCertificates: 'Liste der benötigten Zertifikate',
        endpointCreateRequestResponseVersion: 'Versionsnummer der Projektanfrage',
        endpointCreateRequestResponseChangedAt: 'Zeitpunkt der letzten Änderung (ISO 8601 Datum)',
        endpointCreateRequestResponseChangedBy: 'ID des letzten Änderers',
        endpointCreateRequestResponseCreatedAt: 'Zeitpunkt der Erstellung (ISO 8601 Datum)',
        endpointCreateRequestResponseCreatedBy: 'ID des Erstellers',
        endpointCreateRequestRole: 'Die mit der Anfrage verknüpften Rollen',
        endpointCreateRequestRoleId: 'Die ID der Rolle (falls vorhanden)',
        endpointCreateRequestRoleTitle: 'Name der Rolle',
        endpointCreateRequestCompetence: 'Die mit der Anfrage verknüpften Kompetenzen',
        endpointCreateRequestCompetenceId: 'Die ID der Kompetenz',
        endpointCreateRequestCompetenceTitle: 'Name der Kompetenz',
        endpointCreateRequestCompetenceLevel: 'Level der Kompetenz',
        endpointCreateRequestSkill: 'Die mit der Anfrage verknüpften Fähigkeiten',
        endpointCreateRequestSkillId: 'Die ID der Fähigkeit (falls vorhanden)',
        endpointCreateRequestSkillTitle: 'Name der Fähigkeit',
        endpointCreateRequestCertificate: 'Die mit der Anfrage verknüpften Zertifikate',
        endpointCreateRequestCertificateId: 'Die ID der Zertifikat (falls vorhanden)',
        endpointCreateRequestCertificateTitle: 'Name der Zertifikat',
        endpointCreateRequestEmployeeCoverage: 'Die Bewertung der ausgewählten Mitarbeiter',
        endpointCreateRequestEmployeeCoverageId: 'Die ID des Mitarbeiters',
        endpointCreateRequestEmployeeCoverageTitle: 'Name des Mitarbeiters',
        endpointCreateRequestEmployeeCoverageSkillCoverage: 'Fähigkeitsabdeckung',
        endpointCreateRequestEmployeeCoverageScore: 'Score',
        learnHowToUseRestApi: 'Gelange direkt zur umfassenden Dokumentation unserer REST API. Hier findest Du detaillierte Informationen zu den verfügbaren Endpunkten, Anfrageparametern, Autorisierungsmethoden sowie Beispiele für Anfragen und Antworten. Diese Ressource ist unerlässlich, um effektiv und sicher auf unsere Dienste zuzugreifen. Ideal für Entwickler, die mit unserer API arbeiten möchten.',
        endpointGetRequestTitle: 'Projektanfrage abrufen',
        endpointGetRequestDescription: 'Ruft eine Projektanfrage anhand der ID ab, andere Parameter werden als GET Parameter der URL angehangen. Beispiel: (*/request/1337?paramter=value)',
        endpointGetRequestId: 'Die ID der abzurufenden Projektanfrage',
        endpointGetRequestOptionsIncludeSuggestedEmployees: 'Vorgeschlagene Mitarbeiter berechnen und der Rückgabe hinzufügen',
        endpointGetRequestResponse: 'Antwort der Abrufanforderung',
        endpointRequestUploadAttachmentTitle: 'Projektanfrage: Anhang hochladen',
        endpointRequestUploadAttachmentDescription: 'Ermöglicht das Abrufen einer signierten URL zum Hochladen einer Datei in einen AWS S3 Bucket. Mit dieser URL und den zugehörigen Feldern kann die Datei anschließend hochgeladen werden.',
        endpointRequestUploadAttachmentFilename: "Zukünftiger Dateiname des Anhangs im System.",
        endpointRequestUploadAttachmentOverride: "Soll eine bestehende Datei überschrieben werden?",
        s3SignedUrlResponse: 'Ein Objekt, das alle notwendigen Informationen enthält, um einen Anhang direkt per POST in S3 hochzuladen. Die Eigenschaften aus "fields" müssen als Form-Data zusammen mit der Datei (file) an die angegebene URL gesendet werden.',
        s3SignedUrlResponseId: 'Die ID des Anhangs (Wird aktuell nicht benötigt)',
        s3SignedUrlResponseUrl: 'Die signierte POST-URL zum Hochladen der Datei in den S3 Bucket.',
        s3SignedUrlResponseFields: 'Die Eigenschaften dieses Objekts müssen als Form-Data im Body der Anfrage übermittelt werden.',
        endpointAnalyseRequestTitle: "Projektanfrage: Analyse durchführen",
        endpointAnalyseRequestDescription: "Ermöglicht das Initiieren einer Analyse für eine Projektanfrage basierend auf den im System gespeicherten Daten. Die Analyseoptionen können über die verfügbaren Parameter gesteuert werden.",
    },
}