import { EGender, IAvailabilityDTO, IEmployeeCompetenceReference, IEmployeeDTO, IEmployeeOpportunityReferenceDTO, IEmployeeProductReferenceDTO, IEmployeeProjectReferenceDTO, IEmployeeRoleReference, ISimilarUserDTO } from "@sharedInterfaces/IEmployee";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import { generateFullName } from "@sharedInterfaces/globalHelper";

import ISkill from "../interfaces/ISkill";
import { Entity, entityFieldsGQL } from "../sharedReact/Objects/Entity";

export const employeeGQL = `{
            id
            firstName
            lastName
            gender
            description
            pictureId
            skills {
              id
              title
              level
            }
            certificates {
              id
              title
              startDate
              endDate
            }
            similarUsers {
              id
              title
              match
            }
            availability {
                availability
                workDayCount
            }
            products {
                id
                title
                skillCoverage
            }
            projects {
                id
                title
                active
                business {
                    id
                    title
                }
            }
            competences {
                id
                title
                level
            }
            roles {
                id
                title
            }
            ${entityFieldsGQL}
          }`
export const employeeSmallGQL = `{
            id
            firstName
            lastName
            description
          }`
export default class Employee extends Entity implements IEmployeeDTO
{
    type: EEntityType = EEntityType.EMPLOYEE;
    firstName: string;
    lastName: string;
    description: string;
    certificates: { title: string, id: number }[];
    skills: ISkill[];
    similarUsers: ISimilarUserDTO[];
    availability: IAvailabilityDTO;
    products: IEmployeeProductReferenceDTO[];
    email: string;
    projects: IEmployeeProjectReferenceDTO[];
    gender: EGender;
    delete = undefined;
    opportunities: IEmployeeOpportunityReferenceDTO[];
    competences: IEmployeeCompetenceReference[];
    roles: IEmployeeRoleReference[];
    salesIdea: string;
    pictureId: string | null;

    constructor(obj: IEmployeeDTO)
    {
        super({ ...obj, title: `${obj.firstName} ${obj.lastName}` });
        this.firstName = obj.firstName;
        this.lastName = obj.lastName;
        this.gender = obj.gender;
        this.description = obj.description;
        this.email = obj.email;
        this.skills = obj.skills.map(i => Object.assign({}, i))
        this.certificates = obj.certificates.map(i => Object.assign({}, i))
        this.similarUsers = obj.similarUsers.map(i => Object.assign({}, i))

        this.skills = this.skills.sort((a: ISkill, b: ISkill) =>
        {
            const diff = b.level - a.level;
            if (diff !== 0) return diff;
            return a.title.localeCompare(b.title);
        });
        this.similarUsers = this.similarUsers.sort((a: ISimilarUserDTO, b: ISimilarUserDTO) =>
            b.match - a.match);
        this.certificates = this.certificates.sort((a, b) =>
            a.title.localeCompare(b.title));
        this.availability = obj.availability
        this.products = obj.products;
        // this.products = removeDuplicatesById(obj.products);
        this.projects = obj.projects.sort((a, b) => a.title.localeCompare(b.title));
        this.opportunities = obj.opportunities;
        this.competences = obj.competences;
        this.roles = obj.roles;
        this.salesIdea = obj.salesIdea;
        this.pictureId = obj.pictureId;
    }
    public get fullName(): string
    {
        return generateFullName(this.firstName, this.lastName);
    }

    static calculateSimilarity(employee1: Employee, employee2: Employee): number
    {
        // Erstelle eine Liste aller Fähigkeiten beider Mitarbeiter
        const allSkills = Array.from(new Set([...employee1.skills.map(skill => skill.title), ...employee2.skills.map(skill => skill.title)]));

        // Wandle die Fähigkeiten in Vektoren um
        function vectorizeSkills(skills: ISkill[]): number[]
        {
            const vector = new Array<number>(allSkills.length).fill(0);

            for (const skill of skills)
            {
                const index = allSkills.indexOf(skill.title);
                vector[index] = skill.level;
            }

            return vector;
        }

        const vector1 = vectorizeSkills(employee1.skills);
        const vector2 = vectorizeSkills(employee2.skills);

        // Berechne die Kosinus-Ähnlichkeit
        const dotProduct = vector1.reduce((sum, value, index) => sum + value * vector2[index], 0);
        const magnitude1 = Math.sqrt(vector1.reduce((sum, value) => sum + value ** 2, 0));
        const magnitude2 = Math.sqrt(vector2.reduce((sum, value) => sum + value ** 2, 0));
        const similarity = dotProduct / (magnitude1 * magnitude2);

        return similarity;
    }
}