import { OperationVariables, gql } from "@apollo/client";
import { ISkillCategoryDetailDTO } from "@sharedInterfaces/ISkillCategory";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { deleteSkillCategory, updateSkillCategory } from "@store/reducer/skillCategoriesReducer";
import { SkillCategory } from "@src/Objects/SkillCategory";

import GraphQL from "../graphQL";
import { NotFoundError } from '../NotFoundError';


import { skillCategoryGQL } from "./editSkillCategory";

const q = gql`
query GetSkillCategory($skillcategoryId: ID!) {
    skillCategory(id: $skillcategoryId) 
    ${skillCategoryGQL}
},
`;

/**
 * Retrieves a skill category by its ID, then updates the Redux store.
 *
 * @param {number} skillcategoryId - The ID of the skill category to retrieve.
 * @returns {Promise<SkillCategory>} A promise that resolves with the retrieved skill category.
 */
export default function getSkillCategory(skillcategoryId: number)
{
    const variables: OperationVariables = { skillcategoryId };
    return GraphQL.query<{ skillCategory: ISkillCategoryDetailDTO }>(q, variables).then((obj) =>
    {
        if (!obj?.skillCategory)
        {
            store.dispatch(deleteSkillCategory(skillcategoryId));
            throw new NotFoundError(skillcategoryId, EEntityType.SKILL_CATEGORY)
        }
        const sc = new SkillCategory(obj.skillCategory);
        sc.employees = obj.skillCategory.employees.map((o) => Object.assign({}, o));
        store.dispatch(updateSkillCategory(sc));
        return sc;
    });
}
