import { OperationVariables, gql } from "@apollo/client";
import IProduct from "@sharedInterfaces/IProduct";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { setCompanyVersion } from "@store/reducer/companyReducer";
import { setProducts } from "@store/reducer/productReducer";
import Product, { productGQL } from "@src/Objects/Product";

import GraphQL from "../graphQL";

const q = gql`
query($version:Int!) {
    products(version:$version){
        version
        products
        ${productGQL}
    }
},
`;

interface ProductResponse
{
    products: { version: number; products: IProduct[] };
}


export default async function getProducts()
{
    const state = store.getState();
    const versions = state.company.versions;
    const variables: OperationVariables = {
        version: versions.product,
    };
    return GraphQL.query<ProductResponse>(q, variables, undefined)
        .then((obj) =>
        {
            if (!obj) throw new Error("Fehler beim laden der Products.");
            if (obj.products.products)
            {
                const data = obj.products.products.map((p: IProduct) => new Product(p));
                store.dispatch(setCompanyVersion(EEntityType.PRODUCT, obj.products.version))
                store.dispatch(setProducts(data))
                return data;
            }
            return state.products;
        });
}