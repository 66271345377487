import React, { useState } from 'react';
import { Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TextField, FormControl, Select, MenuItem, Typography, InputLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EHolidaySource, IHolidaySettings } from '@sharedInterfaces/IHolidays';
import { formatDateLocalized } from '@sharedInterfaces/globalHelper';
import { AppState } from '@store/store';
import editHolidaySource from '@src/APIs/graphQl/Company/editHolidaySource';
import deleteHoliday from '@src/APIs/graphQl/Company/deleteHoliday';
import addHoliday from '@src/APIs/graphQl/Company/addHoliday';

import ErrorBox from '../../Components/ErrorBox/ErrorBox';
import Row from '../../sharedReact/General/Forms/Row/Row';


interface HolidayTabProps
{
    clockodoSync: boolean
    holidaySettings: IHolidaySettings
    setHolidaySettings: (holidaySettings: IHolidaySettings) => void;
}

/**
 * Component representing a holiday tab.
 *
 * @param {HolidayTabProps} holidaySettings - The holiday settings prop of the tab.
 * @param {function} setHolidaySettings - The function to set the holiday settings prop of the tab.
 * @returns {JSX.Element} The JSX element representing the holiday tab.
 */
export function HolidayTab({ clockodoSync, holidaySettings, setHolidaySettings }: HolidayTabProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const now = new Date();
    const thisYear = now.getFullYear();
    const [selectedYear, setSelectedYear] = useState(thisYear);
    const holidayYears = holidaySettings.holidays;
    const [isEditing, setIsEditing] = useState(false);
    const [newHoliday, setNewHoliday] = useState({ date: '', name: '', isFullDay: true });
    const [isValid, setIsValid] = useState(true);
    const [holidaySourceError, setHolidaySourceError] = useState<string>("");
    const [holidayError, setHolidayError] = useState<string>("");
    const [saving, setSaving] = useState<boolean>(false);
    const [savingSource, setSavingSource] = useState<boolean>(false);

    const selectedHolidayYear = holidayYears.find(h => h.year === selectedYear);
    const holidays = selectedHolidayYear ? selectedHolidayYear.holidays : []
    holidays.sort((a, b) => a.date.localeCompare(b.date));
    const totalDays = holidays.reduce((acc, curr) => acc + (curr.isFullDay ? 1 : 0.5), 0);
    const yearsArray = [thisYear, thisYear + 1];

    const onAddHoliday = () =>
    {
        const newDate = new Date(newHoliday.date);
        const firstDay = new Date(selectedYear, 0, 1);
        const lastDay = new Date(selectedYear, 11, 31);
        if (newHoliday.date && newHoliday.name && newDate >= firstDay && newDate <= lastDay)
        {
            setSaving(true);
            addHoliday(selectedYear, newHoliday.date, newHoliday.name, newHoliday.isFullDay).then(() =>
            {
                const updatedHolidays = [...holidays, { ...newHoliday }];
                const updatedHolidayYears = holidayYears.map(hy =>
                {
                    if (!hy) hy = { year: selectedYear, holidays: [] };
                    if (hy.year === selectedYear)
                    {
                        return { ...hy, holidays: updatedHolidays };
                    }
                    return hy;
                });
                setHolidaySettings({ ...holidaySettings, holidays: updatedHolidayYears })
                setNewHoliday({ date: '', name: '', isFullDay: true });
                setHolidayError("");
            })
                .catch(() => setHolidayError(langStrings.oftenUsed.errorPleaseRetry))
                .finally(() => setSaving(false));
            setIsValid(true);
        } else
        {
            setIsValid(false);
        }
    };

    const removeHoliday = (date: string) =>
    {
        setSaving(true);
        deleteHoliday(selectedYear, date)
            .then(() =>
            {
                const updatedHolidays = holidays.filter(h => h.date !== date);
                const updatedHolidayYears = holidayYears.map(hy =>
                {
                    if (hy.year === selectedYear)
                    {
                        return { ...hy, holidays: updatedHolidays };
                    }
                    return hy;
                });
                setHolidaySettings({ ...holidaySettings, holidays: updatedHolidayYears })
                setHolidayError("");
            })
            .catch(() => setHolidayError(langStrings.oftenUsed.errorPleaseRetry))
            .finally(() => setSaving(false));
    };

    const changeHolidaySource = (text: EHolidaySource) =>
    {
        if (text !== holidaySettings.source)
        {
            setSavingSource(true);
            editHolidaySource(text)
                .then((newHolidaySettings) =>
                {
                    setHolidaySettings(newHolidaySettings);
                    setHolidayError("");
                })
                .catch(() => setHolidaySourceError(langStrings.oftenUsed.errorPleaseRetry))
                .finally(() => setSavingSource(false));
        }
    }
    return (
        <div>
            <Typography variant="h4">{langStrings.holidayManagement}</Typography>
            <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel>{langStrings.holidaySource}</InputLabel>
                <Select
                    disabled={savingSource}
                    label={langStrings.holidaySource}
                    value={holidaySettings.source}
                    onChange={(e) => changeHolidaySource(e.target.value as EHolidaySource)}
                >
                    {clockodoSync && <MenuItem value={EHolidaySource.clockodo}>{langStrings.clockodo}</MenuItem>}
                    <MenuItem value={EHolidaySource.openHolidaysAPI}>{langStrings.openHolidaysAPI}</MenuItem>
                    <MenuItem value={EHolidaySource.manual}>{langStrings.holidayManuel}</MenuItem>
                </Select>
            </FormControl>
            {savingSource &&
                langStrings.oftenUsed.saving
            }
            {holidaySourceError !== "" &&
                <ErrorBox close={function (): void
                {
                    setHolidaySourceError("");
                }} >
                    {holidaySourceError}
                </ErrorBox>
            }
            <Typography variant="h5">{langStrings.holidays}</Typography>
            <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel>{langStrings.oftenUsed.year}</InputLabel>
                <Select
                    label={langStrings.oftenUsed.year}
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(parseInt(e.target.value as string))}
                >
                    {yearsArray.map(year =>
                        <MenuItem key={year} value={year}>{year}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <Row>
                <Typography variant='body1'>{langStrings.elementCount}: {holidays.length}</Typography>
                <Typography variant="body2">{langStrings.sumOfDays}: {totalDays}</Typography>
            </Row>
            {holidaySettings.source === 'manual' && <Button onClick={() => setIsEditing(!isEditing)}>
                {isEditing ? 'Anzeigemodus' : 'Bearbeitungsmodus'}
            </Button>
            }
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{langStrings.oftenUsed.date}</TableCell>
                            <TableCell>{langStrings.holidayName}</TableCell>
                            <TableCell>{langStrings.completeDay}</TableCell>
                            {isEditing && <TableCell>{langStrings.oftenUsed.actions}</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {holidays.map((holiday) => (
                            <TableRow key={holiday.date}>
                                <TableCell>{
                                    formatDateLocalized(holiday.date, lang)
                                }</TableCell>
                                <TableCell>{holiday.name}</TableCell>
                                <TableCell>{holiday.isFullDay ? langStrings.oftenUsed.fullDay : langStrings.oftenUsed.halfDay}</TableCell>
                                {isEditing && (
                                    <TableCell>
                                        <Button disabled={saving} variant="contained" color="secondary" onClick={() => removeHoliday(holiday.date)}>
                                            {langStrings.oftenUsed.delete}
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                        {isEditing && (
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        type="date"
                                        value={newHoliday.date}
                                        error={!isValid}
                                        onChange={(e) => setNewHoliday({ ...newHoliday, date: e.target.value })}
                                        InputProps={{
                                            inputProps: {
                                                min: `${selectedYear}-01-01`,
                                                max: `${selectedYear}-12-31`
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label="Name"
                                        value={newHoliday.name}
                                        error={!isValid}
                                        onChange={(e) => setNewHoliday({ ...newHoliday, name: e.target.value })}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormControl>
                                        <Select
                                            value={newHoliday.isFullDay}
                                            onChange={(e) => setNewHoliday({ ...newHoliday, isFullDay: e.target.value === 'true' ? true : false })}
                                        >
                                            <MenuItem value={'true'}>{langStrings.oftenUsed.fullDay}</MenuItem>
                                            <MenuItem value={'false'}>{langStrings.oftenUsed.halfDay}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <Button disabled={saving} variant="contained" color="primary" onClick={onAddHoliday}>
                                        {langStrings.oftenUsed.add}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {holidayError !== "" &&
                <ErrorBox close={setHolidayError.bind(null, "")}>
                    {holidayError}
                </ErrorBox>
            }
            {!isValid && <Typography color="error">{langStrings.holidayTabError}</Typography>}
            {holidaySettings.source === 'manual' && <Button onClick={() => setIsEditing(!isEditing)}>
                {isEditing ? langStrings.showMode : langStrings.editMode}
            </Button>
            }
        </div>
    );
}