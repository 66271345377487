import { OperationVariables, gql } from "@apollo/client";
import IOpportunity, { } from "@sharedInterfaces/IOpportunity";
import Opportunity from "@src/Objects/Opportunity";
import store from "@store/store";
import { updateOpportunity } from "@store/reducer/opportunityRecuder";

import GraphQL from "../graphQL";

import { opportunityGql } from "./getOpportunity";
const query = gql`
mutation editOpportunityStatus($id: Int!, $status: String!) {
    editOpportunityStatus(id: $id, status: $status) 
    ${opportunityGql}
},
`;

export default async function editOpportunityStatus(id: number, status: string): Promise<Opportunity>
{
    const variables: OperationVariables = {
        id,
        status,
    };
    return GraphQL.mutate<IOpportunity>(query, variables).then(result =>
    {
        const op = new Opportunity(result);
        store.dispatch(updateOpportunity(op));
        return op;
    })
}