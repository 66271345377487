import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Step, StepButton, Stepper, TextField, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight, Done } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeSuggestedCompetence } from '@sharedInterfaces/IEmployee';
import { IEmployeeCoverage } from '@sharedInterfaces/IOpportunity';
import { calculateEmployeeCoverage, toISODateFormat } from '@sharedInterfaces/globalHelper';
import { IEmployeeSettingsCompetenceSettingsWantedCompetence } from '@sharedInterfaces/IEmployeeSettings';
import { AppState } from '@store/store';
import { setWantedCompetences } from '@store/reducer/employeeSettingsReducer';
import cofigureWantedCompetence from '@src/APIs/graphQl/Employee/WantedCompetences/cofigureWantedCompetence';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import Button from '../../sharedReact/General/Button/Button';
import { EmployeeCoverage } from '../Opportunities/EmployeeSuggestions/EmployeeCoverage';
import Dialog from '../../sharedReact/Dialog/Dialogs/Dialog/Dialog';
import LoadingBox from '../../sharedReact/General/LoadingBox/LoadingBox';
import ErrorBox from '../ErrorBox/ErrorBox';


/**
 * Function to configure the WantedCometenceDialog.
 *
 * @param {Object} options - Options for the WantedCometenceDialog.
 * @param {import().IEmployeeSuggestedCompetence} options.competence - The suggested competence.
 * @param {Date} [options.untilDate] - The optional until date.
 * @param {boolean} [options.dontFilterLevel] - Determines whether to filter the level or not.
 * @param {Function} options.onClose - The function to call when the dialog is closed.
 *
 * @returns {JSX.Element} The configured WantedCometenceDialog.
 */
export function ConfigureWantedCometenceDialog({ id, untilDate, competence, dontFilterLevel, resolve: onClose }: {
    id: string
    competence: IEmployeeSuggestedCompetence;
    untilDate?: Date;
    dontFilterLevel?: true;
    resolve: () => void;
})
{
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;
    const dialogSteps = [langStrings.competenceLevel, langStrings.goalDate];

    const offline = useSelector((state: AppState) => state.client.offline);
    const wantedCompetences = useSelector((state: AppState) => state.employeeSettings.competenceSettings.wantedCompetences);
    const empSkills = useSelector((state: AppState) => state.employee.skills);
    const empCertificates = useSelector((state: AppState) => state.employee.certificates);
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const allCompetence = allCompetences.find(a => a.id === competence.id);

    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedLevel, setSelectedLevel] = useState<number>(competence.level);
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(untilDate);
    const [saveError, setSaveError] = useState<string | undefined>(undefined);

    const handleStepClick = (step: number) =>
    {
        setActiveStep(step);
    };

    const handleSubmit = () =>
    {
        if (!selectedDate || !selectedLevel || !allCompetence) return;
        setActiveStep(2)
        cofigureWantedCompetence(competence.id, selectedLevel, selectedDate)
            .then(() =>
            {
                let newWantedCompetences: IEmployeeSettingsCompetenceSettingsWantedCompetence[] = wantedCompetences
                if (untilDate) // UPDATE
                {
                    newWantedCompetences = newWantedCompetences.filter(wc => wc.id !== competence.id)
                }
                dispatch(setWantedCompetences([
                    ...newWantedCompetences,
                    {
                        id: competence.id,
                        level: selectedLevel,
                        untilDate: selectedDate,
                        createdAt: new Date(),
                        state: 'inProgress',
                        initialCompareState: calculateEmployeeCoverage(
                            allCompetence.levels[selectedLevel - 1].skills, empSkills,
                            allCompetence.levels[selectedLevel - 1].certificates, empCertificates,
                            [],
                            [],
                        ),

                    }
                ]))
                onClose();
            })
            .catch((ex) => 
            {
                console.error(ex);
                showErrorDialog(ex);
                setActiveStep(0)
                setSaveError(ex.message)
            })
    }

    return (<Dialog
        id={id}
        title={langStrings.mapCompetence.replace('[TITLE]', competence.title)}
        footer={activeStep === 0 ?
            <Button
                icon={<ArrowRight />}
                text={langStrings.oftenUsed.next}
                disabled={!selectedLevel || offline}
                onClick={function (): void
                {
                    setActiveStep(activeStep + 1);
                }} /> :
            activeStep === 1 ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <Button
                        icon={<ArrowLeft />}
                        text={langStrings.oftenUsed.back}
                        onClick={function (): void
                        {
                            setActiveStep(activeStep - 1);
                        }} />
                    <Button
                        icon={<Done />}
                        text={langStrings.oftenUsed.map}
                        disabled={!selectedDate}
                        onClick={handleSubmit} />
                </div> :
                <></>
        }
        onClose={onClose}
    >
        <Stepper activeStep={activeStep}>
            {dialogSteps.map((label, index) => (
                <Step key={label}>
                    <StepButton onClick={() => handleStepClick(index)}>
                        {label}
                    </StepButton>
                </Step>
            ))}
        </Stepper>
        {activeStep === 0 &&
            <ConfigureWantedCompetencePage1 selectedLevel={selectedLevel} dontFilterLevel={dontFilterLevel} setSelectedLevel={setSelectedLevel} competence={competence} />
        }
        {activeStep === 1 &&
            <ConfigureWantedCompetencePage2 selectedLevel={selectedLevel} selectedDate={selectedDate} setSelectedDate={setSelectedDate} competence={competence} />
        }
        {activeStep === 2 &&
            <LoadingBox />
        }
        {saveError && <ErrorBox close={setSaveError.bind(null, undefined)}>{saveError}</ErrorBox>}
    </Dialog>
    );
}
interface ConfigureWantedCompetencePage1Props
{
    selectedLevel: number;
    competence: IEmployeeSuggestedCompetence;
    dontFilterLevel?: true;
    setSelectedLevel: (level: number) => void;
}
/**
 * ConfigureWantedCompetencePage1
 *
 * @param {ConfigureWantedCompetencePage1Props} selectedLevel - The selected level.
 * @param {ConfigureWantedCompetencePage1Props} setSelectedLevel - The function to set the selected level.
 * @param {ConfigureWantedCompetencePage1Props} competence - The competence.
 *
 * @returns {JSX.Element}
 */
function ConfigureWantedCompetencePage1({ selectedLevel, competence, dontFilterLevel, setSelectedLevel, }: ConfigureWantedCompetencePage1Props)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;

    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const allCompetence = allCompetences.find(a => a.id === competence.id);

    const filteredCompetenceLevels = allCompetence ?
        (
            dontFilterLevel ?
                allCompetence.levels :
                allCompetence.levels.filter(l => l.id >= competence.level)

        )
        : [];
    const empSkills = useSelector((state: AppState) => state.employee.skills);
    const empCertificates = useSelector((state: AppState) => state.employee.certificates);

    const employeeCoverage: IEmployeeCoverage | null = !allCompetence ?
        null :
        calculateEmployeeCoverage(
            allCompetence.levels[selectedLevel - 1].skills, empSkills,
            allCompetence.levels[selectedLevel - 1].certificates, empCertificates,
            [],
            [],
        );
    return (
        <>
            <Typography style={{ margin: '5px 0' }} variant='body1'>{langStrings.mapCompetenceLevelHelpText.replace('[TITLE]', competence.title)}</Typography>
            <Typography style={{ margin: '5px 0' }} variant='body1'>{langStrings.mapCompetenceLevelHelpText2}</Typography>
            <FormControl fullWidth>
                <InputLabel id="competenceLevel-label">{langStrings.competenceLevel}</InputLabel>
                <Select
                    labelId="competenceLevel-label"
                    id="competenceLevel"
                    value={selectedLevel}
                    label={langStrings.competenceLevel}
                    MenuProps={{
                        style: { zIndex: 9999 }
                    }}
                    onChange={(e) => setSelectedLevel(parseInt(e.target.value.toString()))}
                >
                    {filteredCompetenceLevels.map((level) => (
                        <MenuItem key={level.id} value={level.id}>
                            {`${level.id} - ${level.title}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {
                employeeCoverage &&
                <>
                    <Typography style={{ margin: '5px 0' }} variant='body1'>{langStrings.mapCompetenceLevelHelpText4}</Typography>
                    <EmployeeCoverage expended suggestedEmployee={employeeCoverage} hideName hideAvailability />
                    <Typography variant='body1'>{langStrings.mapCompetenceLevelHelpText5}</Typography>
                </>
            }
            <Typography variant='body1'>{langStrings.mapCompetenceLevelHelpText3}</Typography>
        </>
    );
}

interface ConfigureWantedCompetencePage2Props
{
    selectedLevel: number
    selectedDate: Date | undefined;
    setSelectedDate: (date: Date | undefined) => void;
    competence: IEmployeeSuggestedCompetence;
}
/**
 * ConfigureWantedCompetencePage2 component.
 *
 * @param {ConfigureWantedCompetencePage2Props} selectedDate - The selected date.
 * @param {ConfigureWantedCompetencePage2Props} setSelectedDate - The function to set the selected date.
 * @param {ConfigureWantedCompetencePage2Props} competence - The competence.
 *
 * @returns {JSX.Element} The rendered ConfigureWantedCompetencePage2 component.
 */
function ConfigureWantedCompetencePage2({ selectedLevel, selectedDate, setSelectedDate, competence }: ConfigureWantedCompetencePage2Props)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const allCompetence = allCompetences.find(a => a.id === competence.id);

    // Morgen als minimales Datum
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    const minDate = tomorrow.toISOString().substr(0, 10);

    // In zwei Jahren als maximales Datum
    const twoYearsFromNow = new Date();
    twoYearsFromNow.setFullYear(new Date().getFullYear() + 2);
    const maxDate = twoYearsFromNow.toISOString().substr(0, 10);

    return (
        <>
            <Typography style={{ margin: '5px 0' }} variant='body1'>{langStrings.mapCompetenceDateHelpText1}</Typography>
            <table>
                <tbody>
                    <tr>
                        <td>{langStrings.competence}:</td>
                        <td>{competence.title}</td>
                    </tr>
                    <tr>
                        <td>{langStrings.competenceLevel}:</td>
                        <td>{selectedLevel} - {allCompetence?.levels[selectedLevel - 1].title}</td>
                    </tr>
                </tbody>
            </table>

            <Typography style={{ margin: '5px 0' }} variant='body1'>{langStrings.mapCompetenceDateHelpText2}</Typography>
            <TextField
                type="date"
                value={selectedDate ? toISODateFormat(selectedDate) : ''}
                onChange={(e) => setSelectedDate(new Date(e.target.value))}
                InputProps={{
                    inputProps: {
                        min: minDate,
                        max: maxDate
                    }
                }} />
            {selectedDate &&
                <Typography style={{ margin: '5px 0' }} variant='body1'>{langStrings.mapCompetenceDateHelpText3.replace('[DATE]', selectedDate.toLocaleDateString())}</Typography>}
        </>
    );

}
