import React from 'react';

import SearchBox from '../SearchBox/SearchBox';
import './RoleSearchBox.css';

interface RoleSearchBoxRole
{
    id: number
    title: string
}
interface RoleSearchBoxProps<T extends RoleSearchBoxRole>
{
    allRoles: T[]
    onSelectedRole: (newRole: T) => void;
    onTextChanged?: (newText: string) => void
}

/**
 * RoleSearchBox component.
 * 
 * @param {RoleSearchBoxProps} props - The props for the RoleSearchBox component.
 * @returns {JSX.Element} - The rendered RoleSearchBox component.
 */
function RoleSearchBox<T extends RoleSearchBoxRole>(props: RoleSearchBoxProps<T>)
{
    return (
        <div className='roleSearchBox'>
            <SearchBox
                elements={props.allRoles}
                onTextChange={props.onTextChanged}
                onSelect={function (selectedElement): void
                {
                    props.onSelectedRole(selectedElement as T);
                }} />
        </div>
    );
}

export default RoleSearchBox;
