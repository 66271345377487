import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import './ExpandableText.css';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';

interface ExpandableTextProps
{
    initialHeight?: number
    children: JSX.Element | undefined
    extraHiddenChildren?: JSX.Element
}

/**
 * Function ExpandableText
 *
 * @param {ExpandableTextProps} props - The props for the ExpandableText component.
 * @returns {JSX.Element} - The JSX element representing the ExpandableText component.
 */
function ExpandableText(props: ExpandableTextProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).layout;

    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [small, setSmall] = useState<boolean>(true);
    const [height, setHeight] = useState(0);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() =>
    {
        if (!contentRef.current) return;
        setHeight(contentRef.current.scrollHeight);
        if (contentRef.current.scrollHeight > 0)
        {
            setTimeout(() =>
            {
                setFirstLoad(false);
            }, 100);
        }

    }, [contentRef, small]);

    useEffect(() =>
    {
        const handleResize = () =>
        {
            if (contentRef.current)
            {
                setHeight(contentRef.current.scrollHeight);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return <div style={{
        overflow: 'hidden',
        transition: firstLoad ? undefined : 'max-height 0.3s ease',
        maxHeight: `${small ? (props.initialHeight ? props.initialHeight : 37) : height + 20}px`,
        // maxHeight: small ? '37px' : `${height}px`,
    }}>
        <div
            ref={contentRef}
            className={'expandable ' + (small ? 'small' : '')}
            onClick={setSmall.bind(null, !small)}
            title={langStrings.expandableText}>
            {(!props.children) ?
                <>{langStrings.expandableText}</> :
                props.children
            }
            {!small && props.extraHiddenChildren}
        </div >
    </div>
}

export default ExpandableText;
