import { gql } from "@apollo/client";
import { MergeRolesRequestDTO, MergeRolesResponseDTO } from "@sharedInterfaces/IRole";
import { RoleGQL } from "@src/Objects/Role";

import GraphQL from "../graphQL";

const query = gql`
mutation mergeRoles($roleIds: [Int!]!, $role: MergeRoleInput!) {
    mergeRoles(roleIds: $roleIds ,role: $role) 
    {
    newRole ${RoleGQL}
    otherNames
    removed
  }
},
`;

export default async function mergeRoles(role: { id: number, title: string, description: string },
    roleIds: number[])
{
    const variables: MergeRolesRequestDTO = {
        role,
        roleIds,
    };
    const response = await GraphQL.mutate<MergeRolesResponseDTO>(query, variables);
    response.newRole.id = parseInt(response.newRole.id.toString())
    return response;
}