import { EAvailabilityInputAs } from '@sharedInterfaces/IAvailabilityMonth';

import { IState } from './AvailabilityMonthDialog';

export function onChangeInputType(type: EAvailabilityInputAs, state: IState)
{
    const newState = { ...state, inputAs: type };
    const multiplier = (type === EAvailabilityInputAs.hours) ? 8 : 1 / 8;
    newState.months.forEach((month) =>
    {

        if (!month.weekArray) return;
        month.weekArray.forEach((week) =>
        {
            week.days.forEach((day) =>
            {
                if (day.planings)
                {
                    day.planings.forEach(p => p.value *= multiplier);
                }
            });
        });
    });
    return newState;
}
