import IBusinessDTO from "@sharedInterfaces/IBusiness";
import { IReference } from "@sharedInterfaces/IReference";
import { EEntityType } from "@sharedInterfaces/globalEnums";

import { Entity, entityFieldsGQL } from "../sharedReact/Objects/Entity";

export default class Business extends Entity implements IBusinessDTO
{
  type: EEntityType = EEntityType.BUSINESS;
  description: string;
  sync: boolean;
  tenantId: string;
  projects: IReference[];
  active: boolean;

  constructor(obj: IBusinessDTO)
  {
    super(obj);
    this.description = obj.description;
    this.sync = obj.sync;
    this.tenantId = obj.tenantId;
    this.projects = obj.projects;
    this.active = obj.active;
  }
  async delete()
  {
    const { default: deleteBusiness } = await import("@src/APIs/graphQl/Business/deleteBusiness");
    return deleteBusiness(this.id);
  }
}

export const businessGQL = `{
  id
  title
  description
  tenantId
  sync
  projects {
    id
    title
  }
  ${entityFieldsGQL}

}`