import { OperationVariables, gql } from "@apollo/client";
import { IStyleDTO } from "@sharedInterfaces/ICompanySettings";

import GraphQL from "../graphQL";

import { styleGql } from "./getCompanySettings";

const query = gql`
mutation setStyle($newStyle: StyleInput!) {
    setStyle(newStyle: $newStyle)
    ${styleGql}
},
`;

export default async function saveStyle(newStyle: IStyleDTO)
{
    const variables: OperationVariables = {
        newStyle: {
            primary: newStyle.primary,
            secondary: newStyle.secondary,
            onSecondary: newStyle.onSecondary,
            onPrimary: newStyle.onPrimary,
        }
    };
    return GraphQL.mutate<IStyleDTO>(query, variables);
}