import IRoleDTO, { IRoleCertificate, IRoleCompetence, IRoleEmployee, IRoleOpportunity, IRoleProduct, IRoleSkill } from "@sharedInterfaces/IRole";
import { EEntityType } from "@sharedInterfaces/globalEnums";

import { Entity, entityFieldsGQL } from "../sharedReact/Objects/Entity";

export const RoleGQL = `{
            id
            title
            description
            employees {
              id
              title
            }
            products {
                id
                title
            }
            opportunities {
                id
                title
            }
            certificates {
                id
                title
            }
            skills {
                id
                title
                level
            }
            competences {
                id
                title
                level
            }
            otherNames
            ${entityFieldsGQL}
          }`
export default class Role extends Entity implements IRoleDTO
{
    type: EEntityType = EEntityType.ROLE;
    description: string;
    employees: IRoleEmployee[];
    otherNames: string[];
    products: IRoleProduct[];
    opportunities: IRoleOpportunity[];
    certificates: IRoleCertificate[];
    skills: IRoleSkill[];
    competences: IRoleCompetence[];

    constructor(obj: IRoleDTO)
    {
        super(obj);
        this.description = obj.description;
        this.employees = obj.employees;
        this.otherNames = obj.otherNames;
        this.products = obj.products
        this.opportunities = obj.opportunities;
        this.certificates = obj.certificates;
        this.skills = obj.skills;
        this.competences = obj.competences;
    }
    async delete()
    {
        const { default: deleteRole } = await import("@src/APIs/graphQl/Role/deleteRole");
        return deleteRole(this.id);
    }
}