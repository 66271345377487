import React from 'react';
import { NavLink } from 'react-router-dom';
import './TabContainer.css';

interface TabContainerProps
{
    openTab: number
    pages: {
        title: string,
        link?: string,
        page: React.ReactNode
    }[]
    setOpenTab: (tab: number) => void
}

function TabContainer(props: TabContainerProps)
{
    const activeTab = props.openTab;

    return (
        <div className='tabContainer'>
            <div className="tabHeader">
                {props.pages.map((page, index) =>
                {
                    return (
                        page.link ?
                            <NavLink key={index}
                                to={page.link}
                                className={'tabTitle' + (activeTab === index ? ' active' : '')} onClick={() => { props.setOpenTab(index) }}
                            >
                                {page.title}
                            </NavLink>
                            :
                            <div key={index}
                                className={'tabTitle' + (activeTab === index ? ' active' : '')} onClick={() => { props.setOpenTab(index) }}
                            >
                                {page.title}
                            </div>
                    );
                })}
            </div>
            <div>
                {props.pages.map((page, index) =>
                {
                    return <div key={index} style={{ display: activeTab === index ? 'inherit' : 'none' }}>
                        {page.page}
                    </div>;
                })}
            </div>
        </div>
    );
}

export default TabContainer;
