import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";

import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation setMicrosoftSettings($tenantId: String!, $clientId: String!, $certificateThumbprint: String!) {
    setMicrosoftSettings(tenantId: $tenantId, clientId: $clientId, certificateThumbprint: $certificateThumbprint) 
    ${companySettingsGql}
},
`;

export default async function setMicrosoftSettings(msSettings:
    { tenantId: string, clientId: string, certificateThumbprint: string })
{
    const variables: OperationVariables = {
        ...msSettings
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c))
}