import { ELanguage } from "../languageHelper";
// import { oftenUsedTranslations } from "./oftenUsed"

export interface ILoggedinTexts
{
    createCompany: string;
    selectCompany: string;
    noCompany: string;
    title: string
    text: string
    titleError: string
    textError: string
    redirect: string

}
type ILangData = {
    [lang in ELanguage]: ILoggedinTexts
}
export const loggedinTexts: ILangData = {
            'th': {
                createCompany: 'ลงทะเบียนบริษัทใหม่',
        noCompany: 'คุณยังไม่ได้รับการจัดสรรไปยังบริษัทใด ๆ',
                selectCompany: 'โปรดเลือกบริษัทที่คุณต้องการเข้าสู่ระบบ',
                redirect: 'คุณกำลังถูกเปลี่ยนเส้นทาง โปรดรอสักครู่หรือคลิกที่นี่',
                title: 'ยินดีต้อนรับ',
                text: 'โปรดรอสักครู่',
                titleError: 'เกิดข้อผิดพลาด',
                textError: 'กรุณาลองอีกครั้งหากยังพบข้อผิดพลาด โปรดติดต่อนักพัฒนา (Mirco Frye)',
            },
            'en': {
                createCompany: 'Register new company',
        noCompany: 'You have not been assigned to any company yet.',
                selectCompany: 'Please select the company you wish to log in to.',
                redirect: 'You are being redirected, please wait a moment or click here.',
                titleError: 'An error has occurred',
                textError: 'Please try again, if the error persists please contact the developer. (Mirco Frye)',
                text: 'One moment please',
                title: 'Welcome',
            },
            'de': {
                title: 'Willkommen',
                text: 'Einen Moment bitte',
                titleError: 'Ein Fehler ist aufgetreten',
                textError: 'Probiere es bitte erneut, sollte der Fehler weiterhin auftreten kontaktiere bitte den Entwickler. (Mirco Frye)',
                redirect: "Du wirst weitergeleitet, einen Moment bitte oder hier klicken.",
                selectCompany: 'Bitte wähle die Firma aus in der du dich einloggen möchtest.',
                noCompany: 'Du wurdest bisher keiner Firma zugeordnet.',
                createCompany: 'Neue Firma registrieren',
            },
        }