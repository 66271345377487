import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import CardBox from '@src/Components/CardBox/CardBox';
import SkillItem from '@src/App/NewLayout/Items/SkillItem/SkillItem';
import List, { generateTopXText } from '@src/App/NewLayout/Lists/List/List';

interface SkillsCardProps
{

    title?: string;
    skills: {
        id: number,
        title: string,
        level?: number,
        count?: number,
        lockedBy?: string
    }[];
    inOverlay?: boolean
    limit?: number
    employeeId?: number;
}

/**
 * Renders a skills box component.
 *
 * @param {object} props - The props for the SkillsCard component.
 * @param {string} props.title - The title of the skills box.
 * @param {Array<string>} props.skills - The skills to be displayed in the skills box.
 * @param {boolean} props.inOverlay - Determines whether the skills box is shown in an overlay.
 *
 * @returns {JSX.Element} The rendered SkillsCard component.
 */
function SkillsCard({ limit, title, skills, inOverlay, employeeId }: SkillsCardProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;
    const newTitle = generateTopXText(title ? title : langStrings.skills, limit);

    skills = [...skills].sort((a, b) =>
    {
        if (a.count !== undefined && b.count !== undefined)
        {
            const dif = b.count - a.count;
            if (dif !== 0) return dif;
        }
        if (a.level && b.level)
        {
            const dif = b.level - a.level;
            if (dif !== 0) return dif;
        }
        return a.title.localeCompare(b.title)
    })
    const limitedElements = limit ? skills
        .slice(0, limit > skills.length ? skills.length : limit) : skills;
    const DOM = limitedElements.map((skill, i) =>
    {
        return <SkillItem
            key={i}
            id={skill.id}
            title={skill.title}
            level={skill.level}
            count={skill.count}
            employeeId={employeeId}
            disableOverlay={inOverlay}
            onClick={entityTypeToLink(skill.id, EEntityType.SKILL)}
            lockedBy={skill.lockedBy}
        />
    });
    return (
        <CardBox title={newTitle}>
            <List>
                {DOM}
            </List>
        </CardBox>
    );
}
export default SkillsCard;