import React from 'react';
import './RoleCompare.css'
import { IReference } from '@sharedInterfaces/IReference';
import { EEntityType, entityTypeToLink } from '@sharedInterfaces/globalEnums';

import RoleItem from '../../App/NewLayout/Items/RoleItem/RoleItem';
interface RoleCompareProps
{
    roles: IReference[]
    inDialog?: true
    size: 'small' | 'medium'
    negative?: true
}
/**
 * Function to compare roles.
 *
 * @param {RoleCompareProps} props - The props for comparison.
 * @returns {JSX.Element} - The JSX element representing the comparison.
 */
function RoleCompare(props: RoleCompareProps)
{
    const roles = props.roles.map(role =>
    {
        const withoutLink = props.inDialog || role.id === undefined;

        return <RoleItem
            key={role.title + role.id}
            id={role.id}
            title={role.title}
            disableOverlay={withoutLink}
            negative={props.negative}
            onClick={(!props.inDialog && role.id) ?
                entityTypeToLink(role.id, EEntityType.ROLE)
                : undefined
            }
        />;
    }
    );

    return (
        <div className={'RoleCompare ' + (props.size)} style={{ border: props.inDialog ? 'none' : '' }} >
            {
                roles
            }
        </div>
    );
}

export default RoleCompare;