import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IUserDialogTexts extends ITextBase
{
    column: string;
    show: string;
    titleTableSettings: string;
    pleaseSelectGender: string;
    female: string;
    male: string;
    other: string;
    gender: string;
    admin: string;
    pleaseSelectPermissionGroup: string
    permissionGroup: string;
    inviteEmployeesTo: string
    addDescription: string
    firstname: string
    lastname: string
    invite: string
    editEmployee: string
    orgUnit: string
    delete: string
    edit: string
    sureDelete: string
    pleaseEnterFirstName: string
    pleaseEnterLastName: string
    pleaseEnterVaildEmail: string
    canNotAddEmailTwice: string
    name: string
}
type ILangData = {
    [lang in ELanguage]: IUserDialogTexts
}
export const userdialogTexts: ILangData = {
            'th': {
                show: 'แสดงหรือไม่?',
        column: 'คอลัมน์',
        titleTableSettings: 'การตั้งค่า',
                oftenUsed: oftenUsedTranslations['th'],
                pleaseSelectGender: 'กรุณาเลือกเพศ',
                'inviteEmployeesTo': 'เชิญพนักงานเข้าร่วม',
                'addDescription': 'โปรดเพิ่มพนักงานที่คุณต้องการเชิญเข้าร่วม',
                'firstname': 'ชื่อ',
                'lastname': 'นามสกุล',
                'invite': 'เชิญ',
                'editEmployee': 'แก้ไขข้อมูลพนักงาน - ',
                'orgUnit': 'หน่วยงาน',
                'delete': oftenUsedTranslations['th'].delete,
                'edit': oftenUsedTranslations['th'].edit,
                'sureDelete': 'คุณแน่ใจหรือไม่ว่าต้องการลบพนักงานคนนี้?',
                'canNotAddEmailTwice': 'คุณไม่สามารถเพิ่มที่อยู่อีเมลซ้ำได้',
                'pleaseEnterVaildEmail': 'โปรดป้อนที่อยู่อีเมลที่ถูกต้อง',
                'pleaseEnterFirstName': 'โปรดป้อนชื่อ',
                'pleaseEnterLastName': 'โปรดป้อนนามสกุล',
                'name': 'ชื่อ-นามสกุล',
                permissionGroup: oftenUsedTranslations['de'].permissionGroup,
                pleaseSelectPermissionGroup: 'โปรดเลือกกลุ่มสิทธิ์การเข้าถึง',
                admin: 'ผู้ดูแลระบบ',
                female: 'หญิง',
                male: 'ชาย',
                other: 'อื่นๆ',
                gender: oftenUsedTranslations['th'].gender,
            },
            'en': {
                show: 'Show?',
        column: 'Column',
        titleTableSettings: 'Settings',
                oftenUsed: oftenUsedTranslations['en'],
                pleaseSelectGender: 'Please select a gender.',
                inviteEmployeesTo: 'Invite employee(s) to ',
                addDescription: 'Please add the Employees you want to invite to',
                firstname: 'Firstname',
                lastname: 'Lastname',
                invite: 'Invite',
                editEmployee: 'Edit employee - ',
                orgUnit: 'Organisational unit',
                delete: oftenUsedTranslations['en'].delete,
                edit: oftenUsedTranslations['en'].edit,
                sureDelete: "Are you realy sure that you want to delete this employee?",
                canNotAddEmailTwice: "You can not add a E-Mail twice.",
                pleaseEnterVaildEmail: "Please enter a vaild E-Mail-Address.",
                pleaseEnterFirstName: 'Please enter a firstname.',
                pleaseEnterLastName: 'Please enter a lastname.',
                name: 'Name',
                permissionGroup: oftenUsedTranslations['en'].permissionGroup,
                pleaseSelectPermissionGroup: 'Please select a permission group.',
                admin: 'Administrator',
                female: 'Female',
                male: 'Male',
                other: 'Other',
                gender: oftenUsedTranslations['en'].gender,
            },
            'de': {
                oftenUsed: oftenUsedTranslations['de'],
                inviteEmployeesTo: 'Mitarbeiter/-innen einladen zu ',
                addDescription: 'Fügen bitte die Mitarbeiter/-innen hinzu, die du zu der Organisationseinheit einladen möchtest.',
                firstname: 'Vorname',
                lastname: 'Nachname',
                invite: 'Einladen',
                editEmployee: 'Mitarbeiter/-in bearbeiten - ',
                orgUnit: oftenUsedTranslations['de'].orgUnit,
                delete: oftenUsedTranslations['de'].delete,
                edit: oftenUsedTranslations['de'].edit,
                sureDelete: "Bist du wirklich sicher, dass du diesen Mitarbeiter löschen möchtest?",
                pleaseEnterVaildEmail: "Bitte gebe eine gültige E-Mail-Adresse ein.",
                pleaseEnterFirstName: 'Bitte gebe einen Vornamen ein.',
                pleaseEnterLastName: 'Bitte gebe einen Nachnamen ein.',
                name: 'Name',
                canNotAddEmailTwice: 'Du kannst nicht zwei Mitarbeiter mit derselben E-Mail-Adresse hinzufügen.',
                permissionGroup: oftenUsedTranslations['de'].permissionGroup,
                pleaseSelectPermissionGroup: 'Bitte wähle eine Berechtigungsgruppe aus.',
                pleaseSelectGender: 'Bitte wähle das Geschlecht aus.',
                admin: 'Administrator',
                female: 'Weiblich',
                male: 'Männlich',
                other: 'Divers',
                gender: oftenUsedTranslations['de'].gender,
                titleTableSettings: 'Einstellungen',
                column: 'Spalte',
                show: 'Anzeigen?',
            },
        }