import { OperationVariables, gql } from "@apollo/client";


import GraphQL from "../graphQL";

const query = gql`
mutation markFirstExperienceAsRead {
    markFirstExperienceAsRead
},
`;

export default async function markFirstExperienceAsRead()
{
    const variables: OperationVariables = {
    };
    return GraphQL.mutate<boolean>(query, variables);
}