// Initialer Zustand
export interface WindowSizeState
{
    width: number;
    height: number;
}

const initialState: WindowSizeState = {
    width: window.innerWidth,
    height: window.innerHeight,
};

// Action Enum
export enum WindowSizeActionTypes
{
    SET_WINDOW_SIZE = 'SET_WINDOW_SIZE'
}

// Action Interface
interface SetWindowSizeAction
{
    type: WindowSizeActionTypes.SET_WINDOW_SIZE;
    payload: { width: number; height: number; };
}

// Reducer
export const windowSizeReducer = (
    state = initialState,
    action: SetWindowSizeAction
): WindowSizeState =>
{
    switch (action.type)
    {
        case WindowSizeActionTypes.SET_WINDOW_SIZE:
            return action.payload;
        default:
            return state;
    }
};

// Action Creator
export const setWindowSize = (width: number, height: number): SetWindowSizeAction => ({
    type: WindowSizeActionTypes.SET_WINDOW_SIZE,
    payload: { width, height },
});
