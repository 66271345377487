import React from 'react';
import { Notifications } from '@mui/icons-material';
import { IconButton, Tooltip, Badge, Popover, Divider, List, Typography } from '@mui/material';
import { AppState } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { IRemoveNotificationRequest, isEmployeeNotification, TNotificationWebsocketResponse } from '@sharedInterfaces/INotification';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { isLoggedIn } from '@store/reducer/clientReducer';
import { EWebsocketTopics } from '@sharedInterfaces/globalEnums';
import { WebSocketClient } from '@src/APIs/WebSockets/WebSocketClient';
import { addAdminNotification, addEmployeeNotification, removeAdminNotification, removeEmployeeNotification } from '@store/reducer/notificationsReducer';

import NotificationItem from './NotificationItem';

const topic = EWebsocketTopics.NOTIFICATION;

export function NotificationButton()
{
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const notifications = useSelector((state: AppState) => state.notifications.notifications);
    const adminNotifications = useSelector((state: AppState) => state.notifications.adminNotifications);
    const loggedIn = useSelector((state: AppState) => isLoggedIn(state.client.secret));
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).navigation;

    // Function to trigger a PWA notification

    React.useEffect(() =>
    {
        const subKey = WebSocketClient.subscripe<TNotificationWebsocketResponse>(topic, async (data) =>
        {
            switch (data.type)
            {
                case 'new':
                    if (isEmployeeNotification(data.notification))
                    {
                        dispatch(addEmployeeNotification(data.notification));
                        // Trigger PWA notification for new employee notifications
                        triggerPwaNotification(data.notification.title, {
                            body: data.notification.message,
                            // timestamp: new Date(data.notification.publishedAt).getTime(),
                        }, data.notification.id);
                    } else
                    {
                        dispatch(addAdminNotification(data.notification));
                    }
                    break;
                case 'delete':
                    if (typeof data.id === 'string')
                    {
                        dispatch(removeEmployeeNotification(data.id));
                        // Delete PWA notification when it is removed
                        deletePwaNotification(data.id);
                    } else if (typeof data.id === 'number')
                    {
                        dispatch(removeAdminNotification(data.id));
                    }
                    break;
            }
        });
        return () =>
        {
            return WebSocketClient.unsubscripe(subKey);
        };
    }, [dispatch]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () =>
    {
        setAnchorEl(null);
    };

    const handleDelete = (notificationId: string | number) =>
    {
        const data: IRemoveNotificationRequest = { type: 'delete', id: notificationId };
        WebSocketClient.sendMessage(topic, data);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;

    // Merge and sort notifications by publishedAt date
    const allNotifications = [...notifications, ...adminNotifications].sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime());

    if (!loggedIn)
    {
        return <></>
    }

    return (
        <>
            <Tooltip title={langStrings.notifications} placement='bottom-start'>
                <IconButton onClick={handleClick} aria-describedby={id}>
                    <Badge badgeContent={allNotifications.length} color="error">
                        <Notifications style={{ color: 'var(--var-on-secondary-color)' }} />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {open && (
                    <div style={{ padding: '1rem', maxWidth: '500px', userSelect: 'none' }}>
                        {allNotifications.length ? (
                            <List style={{ paddingBottom: 0 }}>
                                {allNotifications.map((notification, index) => (
                                    <React.Fragment key={notification.id}>
                                        <NotificationItem
                                            notification={notification}
                                            onDelete={handleDelete}
                                        />
                                        {index < allNotifications.length - 1 && <Divider variant="inset" component="li" />}
                                    </React.Fragment>
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                {langStrings.noNotifications}
                            </Typography>
                        )}
                    </div>
                )}
            </Popover>
        </>
    );
}

const triggerPwaNotification = (title: string, options: NotificationOptions, notificationId: string) =>
{
    if ('Notification' in window && navigator.serviceWorker)
    {
        Notification.requestPermission().then(permission =>
        {
            if (permission === 'granted')
            {
                navigator.serviceWorker.ready.then(registration =>
                {
                    registration.showNotification(title, {
                        ...options,
                        tag: notificationId, // Used to uniquely identify the notification
                    });
                });
            }
        });
    }
};

// Function to delete a PWA notification
const deletePwaNotification = (notificationId: string) =>
{
    if (navigator.serviceWorker)
    {
        navigator.serviceWorker.getRegistrations().then(registrations =>
        {
            registrations.forEach(registration =>
            {
                registration.getNotifications({ tag: notificationId }).then(notifications =>
                {
                    notifications.forEach(notification => notification.close());
                });
            });
        });
    }
};
