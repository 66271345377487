import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import { OpenInNew, VisibilityOff } from '@mui/icons-material';
import { IEmployeeSuggestedSkill } from '@sharedInterfaces/IEmployee';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { setEmployeeUnknownSkillCount } from '@store/reducer/employeeReducer';
import getSuggestedSkills from '@src/APIs/graphQl/Employee/Suggestions/getSuggestedSkills';
import hideSuggestedSkill from '@src/APIs/graphQl/Employee/Suggestions/hideSuggestedSkill';
import changeMapSkill from '@src/APIs/graphQl/Skill/changeMapSkill';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { useNavigate } from 'react-router-dom';
import { LoadingBar } from '@sharedReact/Layouts/LayoutElements/ContentHeader/ContentHeader';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import CardBox from '../../Components/CardBox/CardBox';
import FormatedTextInput from '../../Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import AddSkillButton from '../../Components/Buttons/AddSkillButton/AddSkillButton';
import RateBar from '../../Components/formsControls/inputs/RateBar/RateBar';
import RowElement from '../../sharedReact/General/Forms/RowElement/RowElement';


// interface ISuggestedSkillProps
// {
//     // fullList?: true
// }

/**
 * Renders a component for displaying suggested skills.
 *
 * @returns {JSX.Element}
 */
export function SuggestedSkillsPreview(): JSX.Element
{
    const navigate = useNavigate();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employeeDevelopment;
    const empSkills = useSelector((state: AppState) => state.employee.skills);
    const [hiddenSkills, setHiddenSkills] = useState<number[]>([]);

    const [suggestedSkills, setSuggestedSkills] = useState<IEmployeeSuggestedSkill[]>([]);
    const filterSuggestions = (suggestions: IEmployeeSuggestedSkill[]) =>
        suggestions.filter(suggestion => !empSkills.some(e => e.id === suggestion.id) && !hiddenSkills.includes(suggestion.id));
    const getAndUpdateSkills = () =>
        getSuggestedSkills((suggestedSkills) =>
        {
            setSuggestedSkills(filterSuggestions(suggestedSkills));
        });
    useEffect(() =>
    {
        getAndUpdateSkills();
    }, []);

    useEffect(() =>
    {
        const filted = filterSuggestions(suggestedSkills);
        setSuggestedSkills(filted);
        if (filted.length <= 2)
        {
            getAndUpdateSkills()
        }
    },
        [empSkills, empSkills.length, hiddenSkills, hiddenSkills.length]
    )

    return (
        suggestedSkills.length === 0 ? <></> :
            <CardBox
                title={langStrings.suggestedSkills}
                rightButtons={
                    <IconButton title={langStrings.showAllSuggestedSkills} onClick={() =>
                    {
                        navigate(`/${ELinks.PROFILE_EDIT_SKILLS_SUGGESTIONS}`)
                    }}>
                        <OpenInNew />
                    </IconButton>
                }
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    overflow: 'hidden',
                    paddingBlock: 10,
                    // overflowX: 'scroll',
                }}>
                    {suggestedSkills.slice(0, 5).map((skill) => <SuggestedSkill key={skill.id}
                        hiddenSkills={hiddenSkills}
                        setHiddenSkills={setHiddenSkills}
                        skill={skill} />)}
                </div>
            </CardBox>
    );
}
/**
 * Represents a suggested skill.
 *
 * @param skill - The suggested skill.
 * @returns The JSX element representing the suggested skill.
 */
function SuggestedSkill({ setHiddenSkills, hiddenSkills, skill }:
    {
        setHiddenSkills: React.Dispatch<React.SetStateAction<number[]>>;
        hiddenSkills: number[];
        skill: IEmployeeSuggestedSkill;
    })
{
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employeeDevelopment;
    const employee = useSelector((state: AppState) => state.employee);
    const offline = useSelector((state: AppState) => state.client.offline);
    const [saving, setSaving] = React.useState(false as boolean);
    if (!employee) return <>
    </>

    const countDown = () =>
    {
        if (employee.unknownSkillCount > 0)
            dispatch(setEmployeeUnknownSkillCount(employee.unknownSkillCount - 1))
        setHiddenSkills([...hiddenSkills, skill.id]);
    }

    return (
        <CardBox
            title={skill.title}
            style={{
                minWidth: 350,
            }}
            leftButtons={<IconButton
                disabled={offline}
                title={langStrings.hideSkillSuggestion}
                onClick={() =>
                {
                    hideSuggestedSkill(skill.id)
                        .then(countDown)
                        .catch(ex => showErrorDialog(ex));
                }}
            >
                <VisibilityOff />
            </IconButton>}
            rightButtons={<AddSkillButton id={skill.id} title={skill.title} size={'verysmall'} />}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                }}
            >
                <FormatedTextInput value={skill.description} />
                <RowElement title={langStrings.skillFastSelect}
                    style={{ maxHeight: 55, overflow: 'hidden' }}
                >
                    {!saving ?
                        <RateBar
                            style={{
                                marginTop: 5,
                                marginBottom: 5,
                                width: '100%',
                            }}
                            level={0}
                            name={skill.title}
                            setValue={(newLevel) =>
                            {
                                setSaving(true);
                                changeMapSkill(skill.id, true, newLevel)
                                    .then(() =>
                                    {
                                        setSaving(false);
                                        countDown();
                                    })
                                    .catch((ex) =>
                                    {
                                        showErrorDialog(ex, `${langStrings.errorMapSkill}: ${skill.title} - ${skill.id}`);
                                        setSaving(false);
                                    })
                            }} /> :
                        <>
                            {langStrings.saving}
                        </>
                    }
                </RowElement>
            </div>
        </CardBox>
    );
}


export function SuggestedSkills(): JSX.Element
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employeeDevelopment;
    const empSkills = useSelector((state: AppState) => state.employee.skills);
    const [hiddenSkills, setHiddenSkills] = useState<number[]>([]);

    const [suggestedSkills, setSuggestedSkills] = useState<IEmployeeSuggestedSkill[] | null>(null);
    const filterSuggestions = (suggestions: IEmployeeSuggestedSkill[]) =>
        suggestions.filter(suggestion => !empSkills.some(e => e.id === suggestion.id) && !hiddenSkills.includes(suggestion.id));
    const getAndUpdateSkills = () =>
        getSuggestedSkills((suggestedSkills) =>
        {
            setSuggestedSkills(filterSuggestions(suggestedSkills));
        }, true);
    useEffect(() =>
    {
        getAndUpdateSkills();
    }, []);

    useEffect(() =>
    {
        if (!suggestedSkills) return;
        const filted = filterSuggestions(suggestedSkills);
        setSuggestedSkills(filted);
    },
        [empSkills, empSkills.length, hiddenSkills, hiddenSkills.length]
    )

    return <CardBox
        title={langStrings.suggestedSkills}
    >
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
        }}>
            {suggestedSkills === null ? <>
                <LoadingBar />
            </> :
                <>
                    {suggestedSkills.length === 0 && <>
                        <Typography variant='body1' align='center' style={{ margin: '50px 10px' }}>
                            {langStrings.noSuggestedSkills}
                        </Typography>
                    </>}
                    {suggestedSkills.map((skill) => <SuggestedSkill key={skill.id}
                        hiddenSkills={hiddenSkills}
                        setHiddenSkills={setHiddenSkills}
                        skill={skill} />)}
                </>
            }

        </div>
    </CardBox>
}