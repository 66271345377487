import { OperationVariables, gql } from "@apollo/client";


import GraphQL from "../graphQL";

const query = gql`
mutation updateMyDescription($description: String!) {
    updateMyDescription(description: $description) 
},
`;

export default async function updateMyDescription(description: string)
{
    const variables: OperationVariables = { description };
    return GraphQL.mutate<boolean>(query, variables);
}