// LegendBox.tsx
import React from 'react';
import './Legend.css';

import LegendItem from './LegendItem'; // Import the LegendItem component

interface LegendBoxProps
{
    items: Array<{
        title: string;
        colorVar: string;
    }>;
}

const LegendBox: React.FC<LegendBoxProps> = ({ items }) =>
{
    return (
        <div className='legendBox'>
            {items.map((item, index) => (
                <LegendItem key={index} title={item.title} colorVar={item.colorVar} />
            ))}
        </div>
    );
};

export default LegendBox;


