import React from 'react';
import { useSelector } from 'react-redux';
import { PictureAsPdf } from '@mui/icons-material';
import './AnalyseSkillsPdfButton.css';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

import AnalyseCvDialog from '../../Dialogs/AnalyseSkillsDialog/AnalyseCvDialog';
interface AnalyseSkillsPdfButtonProps
{

}
/**
 * AnalyseSkillsPdfButton component.
 * 
 * @param {AnalyseSkillsPdfButtonProps} props - The props for the component.
 * @returns {JSX.Element} The JSX element for the component.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AnalyseSkillsPdfButton(props: AnalyseSkillsPdfButtonProps)
{
    const { openDialog } = useDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang);
    const offline = useSelector((state: AppState) => state.client.offline);
    return (
        <div className="analyseSkillsPdfButton">
            <input type="file" id="fileButton" onChange={async (e) =>
            {
                if (e.target.files)
                {
                    const tempFile = e.target.files[0];
                    if (tempFile === undefined)
                    {
                        return;
                    }
                    openDialog(<AnalyseCvDialog
                        id='AnalyseCvDialog'
                        initialLoadingText={langStrings.analyseDialog.uploading}
                        file={tempFile}
                        resolve={() =>
                        {
                            return;
                        }}
                    />);
                    (document.getElementById('fileButton') as HTMLInputElement).value = "";

                }
            }}
                accept=".docx"
            />
            <div className="analyseFileButton">
                <Button
                    size="normal"
                    text={langStrings.profileSettings.analyseDocx}
                    disabled={offline}
                    icon={<PictureAsPdf />}
                    onClick={
                        async () =>
                        {
                            document.getElementById('fileButton')?.click();
                        }}
                />
            </div>
        </div>
    );
}

export default AnalyseSkillsPdfButton;
