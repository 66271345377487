import { OperationVariables, gql } from "@apollo/client";
import IBusinessDTO, { ICreateBusinessDTO } from "@sharedInterfaces/IBusiness";
import { businessGQL } from "@src/Objects/Business";

import GraphQL from "../graphQL";

const query = gql`
mutation createBusiness($obj: CreateBusiness!) {
    createBusiness(obj: $obj) 
    ${businessGQL}
},
`;

export default async function createBusiness(business: ICreateBusinessDTO)
{
    business.projects = business.projects.map(s => ({
        id: s.id,
        title: s.title,
    }))
    const variables: OperationVariables = {
        obj: business,
    };

    return GraphQL.mutate<IBusinessDTO>(query, variables)
}

//, (obj: any) => callback(new Business(companyId, obj.createBusiness as IBusiness))