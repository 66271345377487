import './BusinessPage.css';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import Business from '@src/Objects/Business';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import { BusinessDialog } from '@src/Components/Dialogs/BusinessDialog/BusinessDialog';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import getBusiness from '@src/APIs/graphQl/Business/getBusiness';
import ProjectsCard from '@src/App/NewLayout/Cards/Projects/ProjectsCard/ProjectsCard';
import { useDialog } from '@sharedReact/Dialog/DialogManager';

import EntityPage from '../../../../../sharedReact/Pages/EntityPage/EntityPage';

interface BusinessPageProps
{
    entity?: Business;
    inDialog?: boolean;
}

/**
 * BusinessPage component.
 *
 * @param {BusinessPageProps} props - The props object containing the required data for the BusinessPage.
 * @returns {JSX.Element} The rendered BusinessPage component.
 */
function BusinessPage(props: BusinessPageProps)
{
    const navigate = useNavigate();
    const { openDialog } = useDialog();
    const { id } = useParams();
    const entityId = props.entity ? props.entity.id : id ? Number.parseInt(id) : -1;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).businesses;
    const permissions = useSelector((state: AppState) => state.permissions);
    const entityState = useSelector((state: AppState) => state.businesses.find(a => a.id === entityId))


    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);

    useEffect(() =>
    {
        if (props.inDialog) return;
        getBusiness(entityId).catch((ex) =>
        {
            setLoadingError(ex);
        });

    }, [entityId, props.inDialog]);

    const handleOpenEditDialog = async () =>
    {
        return openDialog(
            <BusinessDialog
                id="BusinessDialog"
                business={entityState}
            />);
    };
    return (
        <EntityPage
            inDialog={props.inDialog}
            entity={entityState}
            loadingError={loadingError}
            admin={permissions.admin}
            permissions={permissions.entities.Companies}
            onDelete={() =>
            {
                if (!props.inDialog)
                    navigate(`/${ELinks.SALES_CUSTOMERS}`);
            }}
            openEditDialog={handleOpenEditDialog}
            informations={
                !entityState ? [] :
                    [
                        entityState.description ?
                            <EntityPageInformation title={langStrings.description} size={'full'}>
                                <ExpandableText>
                                    <FormatedTextInput value={entityState.description} readonly={true} />
                                </ExpandableText>
                            </EntityPageInformation> :
                            undefined,
                    ]
            }
        >
            <>
                {
                    entityState &&
                    <ProjectsCard projects={entityState.projects} />
                }
            </>
        </EntityPage >
    );
}

export default BusinessPage;
