import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";

const query = gql`
mutation updateHiddenProjects($projects: [Int!]!) {
    updateHiddenProjects(projects: $projects) 
},
`;

export default async function updateHiddenProjects(hiddenProjects: number[])
{
    const variables: OperationVariables = { projects: hiddenProjects };
    return GraphQL.mutate<boolean>(query, variables);
}