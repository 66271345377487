import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";
import { EMailCycle } from "@sharedInterfaces/globalEnums";

import GraphQL from "../graphQL";

import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation editCompanyEmailSettings($cycle: String!,$text: String!, $sendCertificateReminder: Boolean!) {
    editCompanyEmailSettings(cycle: $cycle, text:$text, sendCertificateReminder: $sendCertificateReminder) 
    ${companySettingsGql}
},
`;


export default async function editCompanyEmailSettings(cycle: EMailCycle, text: string, sendCertificateReminder: boolean)
{
    const variables: OperationVariables = {
        cycle,
        text,
        sendCertificateReminder: sendCertificateReminder ? sendCertificateReminder : false,
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c));
}