import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';

import App from "./App/App";
import reportWebVitals from './reportWebVitals';
import store from './Store/store'
import { stageContext } from './globals';
import { checkForServiceWorkerUpdate } from './sharedReact/Dialog/Dialogs/VersionDialog/VersionDialog';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (!stageContext.prod)
  reportWebVitals();

if ('serviceWorker' in navigator)
{
  window.addEventListener('load', () =>
  {
    // console.log("🚀 ~ file: index.tsx:33 ~ window.location.path:", window.location.pathname);
    const split = window.location.pathname.split('/');
    if (split && split.length < 3)
      navigator.serviceWorker.register('/service-worker.js').then((registration) =>
      {
        // console.log('ServiceWorker registration successful with scope: ', registration.scope);
        registration.onupdatefound = () =>
        {
          const installingWorker = registration.installing;
          if (installingWorker == null)
          {
            return;
          }
          installingWorker.onstatechange = () =>
          {
            if (installingWorker.state === 'installed')
            {
              if (navigator.serviceWorker.controller)
              {
                // At this point, the updated precached content has been fetched,
                // but the previous service worker will still serve the older
                // content until all client tabs are closed.
                console.log('New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://cra.link/PWA.');

                // Execute callback
                navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
              } else
              {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                console.log('Content is cached for offline use.');
              }
            }
          };
        };
      }, (err) =>
      {
        console.log('ServiceWorker registration failed: ', err);
      });
  });
}

// checkForServiceWorkerUpdate();
checkForServiceWorkerUpdate();