import { useSelector } from 'react-redux';
import React from 'react';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import CardBox from '@src/Components/CardBox/CardBox';
import List, { generateTopXText } from '@src/App/NewLayout/Lists/List/List';
import CertificateItem from '@src/App/NewLayout/Items/CertificateItem/CertificateItem';
interface CertificatesCardProps
{
    title?: string;
    certificates: {
        title: string
        id: number
        lockedBy?: string
        startDate?: Date
        endDate?: Date
    }[];
    inOverlay?: boolean
    limit?: number
}

/**
 * CertificatesCard component.
 * 
 * @param {CertificatesCardProps} title - The title of the box.
 * @param {CertificatesCardProps} certificates - The certificates to display.
 * @param {CertificatesCardProps} inOverlay - Flag indicating if the box is in overlay mode.
 * @returns {JSX.Element} The CertificatesCard as a JSX element.
 */
function CertificatesCard({ limit, title, certificates, inOverlay, }: CertificatesCardProps)
{
    certificates = [...certificates].sort((a, b) =>
    {
        if (b.endDate && !a.endDate) return -1;
        if (!b.endDate && a.endDate) return 1;
        if (b.endDate?.getTime && a.endDate?.getTime) return b.endDate.getTime() - a.endDate.getTime();
        return 0;
    })
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).certificates;
    const newTitle = generateTopXText(title ? title : langStrings.certificates, limit)
        ;
    const limitedElements = limit ? certificates
        .slice(0, limit > certificates.length ? certificates.length : limit) : certificates;
    const DOM = limitedElements.map((certificate) =>
    {
        return <CertificateItem
            key={certificate.id}
            id={certificate.id}
            title={certificate.title}
            startDate={certificate.startDate}
            endDate={certificate.endDate}
            lockedBy={certificate.lockedBy}
            disableOverlay={inOverlay}
            onClick={entityTypeToLink(certificate.id, EEntityType.CERTIFICATE)}
        />
    });
    return (
        <CardBox title={newTitle}>
            <List>
                {DOM}
            </List>
        </CardBox>

    );
}

export default CertificatesCard;
