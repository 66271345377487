import React from 'react';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';

interface SalesContentMonthSourceInfoDialogProps
{
    id: string
    resolve?: (val: null) => void;
}
/**
 * SkillDialog component.
 *
 * @param {SkillDialogProps} props - The props object.
 * @returns {JSX.Element} - The rendered SkillDialog component.
 */
export function SalesContentMonthSourceInfoDialog({ id, resolve, }: SalesContentMonthSourceInfoDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;


    const onClose = () =>
    {
        resolve && resolve(null);
    };

    return (
        <Dialog
            id={id}
            title={langStrings.actualMonthDialogTitle}
            onClose={onClose}
            footer={<div style={{ float: 'right' }}>
                <Button
                    icon={<Close />}
                    text={langStrings.oftenUsed.close}
                    size={'normal'}
                    onClick={onClose} />
            </div>}
        >
            <Typography variant="body1" gutterBottom>
                {langStrings.actualMonthDialogDescription}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {langStrings.actualMonthCombined}:
            </Typography>
            <Typography variant="body2" gutterBottom>
                {langStrings.actualMonthCombinedDescription}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {langStrings.actualMonthTimeRecording}:
            </Typography>
            <Typography variant="body2" gutterBottom>
                {langStrings.actualMonthTimeRecordingDescription}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {langStrings.actualMonthForecast}:
            </Typography>
            <Typography variant="body2" gutterBottom>
                {langStrings.actualMonthForecastDescription}
            </Typography>
        </Dialog>
    );
}
