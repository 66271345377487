import { OperationVariables, gql } from "@apollo/client";
import IAttachment from "@sharedInterfaces/IAttachment";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import Attachment from "@src/Objects/Attachment";
import { entityFieldsGQL } from "@sharedReact/Objects/Entity";

import GraphQL from "../graphQL";

export const attachmentGql = `{
    id
    description
    title
    active
    entityId
    entityType
    fileType
    version
    ${entityFieldsGQL}
}`;

const q = gql`
query attachment($entityType: String!, $entityId: Int!, $fileName: String!) {
    attachment(entityType: $entityType, entityId: $entityId, fileName: $fileName)
    ${attachmentGql}
}
`;

/**
 * Retrieves an attachment by its ID, then updates the Redux store.
 *
 * @param {number} attachmentId - The ID of the attachment to retrieve.
 * @returns {Promise<Attachment>} A promise that resolves with the retrieved attachment.
 */
export default async function getAttachment(entityType: EEntityType, entityId: number, fileName: string)
{
    const variables: OperationVariables = {
        entityType,
        entityId,
        fileName,
    };
    return GraphQL.query<{ attachment: IAttachment }>(q, variables).then((data) =>
    {
        if (!data?.attachment)
        {
            throw new Error(`Attachment ${entityType}:${entityId} not found`)
        }

        const attachment = new Attachment(data.attachment);
        return attachment;
    });
}
