import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import { EGender } from "@sharedInterfaces/IEmployee";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";
import { companySettingsGql } from "../Company/getCompanySettings";

const query = gql`
mutation editEmployee($id:Int!, $firstname: String!, $lastname: String!, $OUId: Int!, $permissionGroupId: Int!, $admin: Boolean!, $gender: String!) {
    editEmployee(id: $id ,firstname: $firstname, lastname: $lastname, OUId: $OUId, permissionGroupId: $permissionGroupId, admin: $admin, gender: $gender) 
    ${companySettingsGql}
},
`;

export default async function editEmployee(id: number, firstname: string, lastname: string, OUId: number, permissionGroupId: number, admin: boolean, gender: EGender)
{
    const variables: OperationVariables = {
        id,
        firstname,
        lastname,
        OUId,
        permissionGroupId,
        admin,
        gender,

    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c));
}