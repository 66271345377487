import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, } from "react-router-dom";
import InnerLayout from "@sharedReact/Layouts/InnerLayout/InnerLayout";
import Layout from "@sharedReact/Layouts/Layout/Layout";
import { IStyleDTO } from "@sharedInterfaces/ICompanySettings";
import { ELinks, EWebsocketTopics } from "@sharedInterfaces/globalEnums";
import { AppState } from "@store/store";
import { isLoggedIn } from "@store/reducer/clientReducer";
import getWhoIAm from "@src/APIs/graphQl/Employee/getWhoIAm";
import { CustomGraphQLError } from "@src/APIs/graphQl/graphQL";
import { WebSocketClient } from "@src/APIs/WebSockets/WebSocketClient";
import { DialogContainer } from "@sharedReact/Dialog/DialogManager";
import { useDispatch } from "react-redux";
import { setCompanyStyle } from "@store/reducer/companyReducer";
import { EGeneralWebsocketResponseType, TGeneralWebsocketResponse } from "@sharedInterfaces/websocket/General";

import LoadingBox from "../sharedReact/General/LoadingBox/LoadingBox";
import { logout } from "../Components/UserButton/UserButton";
import ErrorPage from "../sharedReact/Error/ErrorPage";
import FatalErrorPage from "../sharedReact/Error/FatalErrorPage";
import CookieBanner from "../sharedReact/General/CookieBanner/CookieBanner";
import ErrorBoundary from "../sharedReact/Error/ErrorBoundary";

import RoutesComponent from "./Routes";
import DesignController from "./DesignController";
const topic = EWebsocketTopics.GENERAL;

function App()
{
  const dispatch = useDispatch()
  const secret = useSelector((state: AppState) => state.client.secret);
  const actualStyle = useSelector((state: AppState) => state.company.style);
  const startPage = useSelector((state: AppState) => state.employeeSettings.startPage);

  const [style, setStyle] = useState<IStyleDTO>(actualStyle);

  const loadWhoIAm = () =>
  {
    if (isLoggedIn(secret))
    {
      getWhoIAm((me) =>
      {
        if (!me) return;
        setStyle(me.style);
      }).catch((error: CustomGraphQLError) =>
      {
        console.error(error);
        if (error.statusCode === 401)
        {
          logout();
        }
      });
    }
  }

  const openWebSocket = () =>
  {
    if (!WebSocketClient.socket)
      WebSocketClient.connect();
  }

  React.useEffect(() =>
  {
    const subKey = WebSocketClient.subscripe<TGeneralWebsocketResponse>(topic, (data) =>
    {
      console.log(data);
      switch (data.type)
      {
        case EGeneralWebsocketResponseType.Style:
          console.log(data.style);
          dispatch(setCompanyStyle(data.style));
          break;
        default:
          console.error("Unknown websocket response type", data.type);
          break;
      }
    });

    return () =>
    {
      return WebSocketClient.unsubscripe(subKey)
    }
  }, [])

  useEffect(() =>
  {
    if (!secret || secret == null || secret === "") return;
    openWebSocket();
    const intervalId = setInterval(() =>
    {
      loadWhoIAm();
    }, 2 * 60 * 60 * 1000);
    loadWhoIAm();
    return () =>
    {
      clearInterval(intervalId);
    };
  }, [secret]);

  if (startPage && location.pathname === '/')
  {
    location.pathname = ELinks[startPage];
  }
  return (
    <ErrorBoundary fallback={(error) => <FatalErrorPage error={error} />}>
      <DesignController style={style}>
        <BrowserRouter>
          <DialogContainer />
          <CookieBanner />
          <Layout >
            <ErrorBoundary fallback={(error) => <ErrorPage error={error} />}>
              <div id="dialogContainer"></div>
              <React.Suspense fallback={<InnerLayout>
                <LoadingBox />
              </InnerLayout>}>
                <RoutesComponent style={style} setStyle={setStyle} />
              </React.Suspense>
            </ErrorBoundary>
          </Layout>
        </BrowserRouter>
      </DesignController>
    </ErrorBoundary>
  );
}

export default App;