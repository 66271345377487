import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings, { ICompanyLocalisation } from "@sharedInterfaces/ICompanySettings";
import { IIndustriesTexts } from "@sharedInterfaces/Language/languageTexts/industriesTexts";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";


import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation updateGeneralCompanySettings($country: String!, $subCountry: String!, $language: String!, $industry: String!) {
    updateGeneralCompanySettings(country: $country,subCountry: $subCountry,language: $language,industry: $industry) 
    ${companySettingsGql}
},
`;

export default async function updateGeneralCompanySettings(localisation: ICompanyLocalisation, industry: keyof IIndustriesTexts)
{
    const variables: OperationVariables = {
        country: localisation.country,
        subCountry: localisation.subCountry,
        language: localisation.language,
        industry: industry,
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c))
}