import { Add } from "@mui/icons-material";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ISmallCompetence } from "@sharedInterfaces/IWhoIAm";
import { AppState } from "@store/store";
import Button from "@sharedReact/General/Button/Button";
import Item from "@src/App/NewLayout/Items/Item/Item";

import CompetenceSearchBox from "../../searchBoxes/CompetenceSearchBox/CompetenceSearchBox";
import CompetenceRateBox from "../CompetenceRateBox/CompetenceRateBox";

interface INewCompetence
{
    id: number
    title: string,
    level: number
}

interface NewCompetenceRowProps
{
    filteredAllCompetences: ISmallCompetence[];
    newCompetence: INewCompetence;
    onNewCompetenceChange: (newCompetence: INewCompetence) => void;
    onAddClick: () => void;
}
const NewCompetenceRow: React.FC<NewCompetenceRowProps> = ({ filteredAllCompetences, newCompetence, onNewCompetenceChange, onAddClick }) =>
{
    const elementRef = useRef<HTMLDivElement>(null);
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);

    const allCompetence = allCompetences.find(com => com.id === newCompetence.id)
    const maxLevel = allCompetence ? allCompetence.levels.length - 1 : 0;

    useEffect(() =>
    {
        setTimeout(() =>
        {
            if (elementRef.current && newCompetence.title !== '')
            {
                const element: HTMLInputElement | null | HTMLSelectElement = elementRef.current.querySelector('.competenceRateBoxInputElement');
                element && element.focus()
            }
        }, 50);
    }, [newCompetence.title, elementRef])
    return (
        <div className="newCompetenceRow" ref={elementRef} style={{ "--percent": `${newCompetence.level / maxLevel * 100}`, order: 999 } as React.CSSProperties}>
            <Item
                rightContent={
                    <Button
                        size="verysmall"
                        onClick={() =>
                        {
                            onAddClick();
                            setTimeout(() =>
                            {
                                const element: HTMLInputElement | null = document.querySelector('.newCompetenceRow .entitySearchBoxInput input');
                                element && element.focus()
                            }, 50);
                        }}
                        icon={<Add />}
                        disabled={newCompetence.title === '' || newCompetence.level === 0}
                        text="Add" />
                }
            >
                <CompetenceSearchBox
                    filteredAllCompetences={filteredAllCompetences}
                    newCompetence={newCompetence}
                    size="small"
                    setNewCompetence={(newCompetence) =>
                    {
                        try
                        {
                            newCompetence.id = parseInt(newCompetence.id.toString())
                        } catch (error)
                        {
                            console.log(error)
                        }
                        onNewCompetenceChange({ ...newCompetence, level: 0 });
                    }} />
                {(newCompetence.title !== "" && allCompetence) &&
                    <CompetenceRateBox
                        level={newCompetence.level}
                        levels={allCompetence.levels}
                        name={newCompetence.title}
                        setValue={(newLevel) => onNewCompetenceChange({ ...newCompetence, level: newLevel })} />}

            </Item>
        </div>
    );
};
export default NewCompetenceRow;
