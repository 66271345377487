import React from 'react';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { setAnalyses, setMarketing, setUserConfirmed } from '@store/reducer/cookieReducer';

import styles from './CookieBanner.module.css';


const CookieBanner: React.FC = () =>
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).layout;

    const cookies = useSelector((state: AppState) => state.cookies);

    if (cookies.userConfirmed)
    {
        return null;
    }

    const onAccept = () =>
    {
        dispatch(setUserConfirmed(true))
    }
    const onDecline = () =>
    {
        dispatch(setMarketing(false))
        dispatch(setAnalyses(false))

    }

    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modal}>
                <Typography variant='h5' fontWeight={600}>{langStrings.cookieTitle}</Typography>
                <Typography variant='body1' style={{ marginTop: '10px' }}>
                    {langStrings.cookieText.replace('[ACCEPT]', langStrings.accept)}
                </Typography>
                <div className={styles.buttons}>
                    {/* <Button
                        variant='contained'
                        className={styles.button}
                        onClick={onDecline}
                    >
                        <Typography variant='button'>
                            {langStrings.decline}
                        </Typography>
                    </Button> */}
                    <Button
                        variant='contained'
                        className={styles.button}
                        onClick={onAccept}
                    >
                        <Typography variant='button'>
                            {langStrings.accept}
                        </Typography>
                    </Button>
                </div>
                <div className={styles.footer} >
                    <a href={ELinks.FOOTER_IMPRINT}>{langStrings.imprint}</a>
                    <a href={ELinks.FOOTER_CONTACT}>{langStrings.contact}</a>
                </div>
            </div>
        </div>
    );
};

export default CookieBanner;
