import IEntity from "./IEntity";
import { IReference, IReferenceMaybe } from "./IReference";

export interface IOpportunityNew
{
    // active: boolean;
    title: string
    externalId: string;
    projectStart: Date | undefined
    projectEnd: Date | undefined
    publishDate: Date | undefined
    deadline: Date | undefined
    dayPrice: number;
    loc: string;
    customer: string;
    partner: string;
    sourceName: string;
    sourceURL: string;
    utilization: number;
    remote: number;
    headCount: number;
    skills: MaybeSkill[];
    requestText: string;
    selectedEmployees: IEmployeeCoverage[]
    competences: IOpportunityCompetenceReference[]
    certificates: IOpportunityCertificateMaybe[]
    roles: IOpportunityRole[]
    primaryRole: IOpportunityRole | null;
    status: string;
}

export interface IOpportunityEdit extends IOpportunityNew
{
    id: number
}


export default interface IOpportunity extends IEntity
{
    id: number
    // active: boolean;
    title: string
    externalId: string;
    projectStart: Date | undefined
    projectEnd: Date | undefined
    publishDate: Date | undefined
    deadline: Date | undefined
    dayPrice: number;
    loc: string;
    customer: string;
    partner: string;
    sourceName: string;
    sourceURL: string;
    utilization: number;
    remote: number;
    headCount: number;
    skills: IOpportunitySkill[];
    requestText: string;
    selectedEmployees: IEmployeeCoverage[]
    suggestedEmployees?: IEmployeeCoverage[]
    competences: IOpportunityCompetenceReference[]
    certificates: IOpportunityCertificate[]
    roles: IOpportunityRole[]
    primaryRole: IOpportunityRole | null;
    status: string;
}
export interface IOpportunityCertificate extends IReference
{

}
export interface IOpportunityCertificateMaybe extends IReferenceMaybe
{

}
export interface IOpportunityRole extends IReference
{

}
export interface IOpportunityCompetenceReference extends IReference
{
    level: number;
}
export interface IOpportunitySkill
{
    id: number;
    title: string;
    level: number;
}
export interface IEmployeeCoverage
{
    id: number;
    title: string;
    skillCoverage: number;
    score: number;
    missingSkills: ISkillLevelCompare[];
    coveredSkills: ISkillLevelCompare[];
    missingCertificates: IReference[]
    coveredCertificates: IReference[]
    missingRoles: IReference[]
    coveredRoles: IReference[]
}

export interface MaybeSkill extends IReferenceMaybe
{
    level: number
}

export interface ISkillLevelCompare 
{
    wantedLevel: number
    title: string,
    id?: number,
    level: number,
}


export enum EOpportunityRequestTypes
{
    title = 'title',
    externalId = 'externalId',
    projectDates = 'projectDates',
    projectNumbers = 'projectNumbers',
    customerPartner = 'customerPartner',
    source = 'source',
    location = 'location',
    roles = 'roles',
    competences = 'competences',
    skills = 'skills',
    certificates = 'certificates',
}
export type TOpportunityRequestingState = {
    [key in EOpportunityRequestTypes]?: boolean;
};

export const ANALYSE_ALL_OPPORTUNITY: TOpportunityRequestingState = {
    title: true,
    externalId: true,
    projectDates: true,
    projectNumbers: true,
    customerPartner: true,
    source: true,
    location: true,
    roles: true,
    competences: true,
    skills: true,
    certificates: true,
};