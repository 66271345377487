
import { ELanguage } from "../languageHelper"

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface ISkillTexts extends ITextBase
{
    errorFetchingSkills: string
    mergeWarning: string
    ratingSuggestion: string
    actualRating: string
    questioningSkillLevelFor: string
    questionDialogMessage: string
    questionDialogTitle: string
    questionSkillTitle: string
    questionSkillHelper: string
    generateSkillData: string
    suggestedSkillName: string
    pleaseEnterTitle: string
    editSkillProfile: string
    editButtonHelper: string
    editButtonTitle: string
    wantedLevel: string
    addSkill: string
    invaildURL: string
    checkLink: string
    manufacturer: string
    productName: string
    productInfo: string
    skillType: string
    howToLearn: string
    acquisition: string
    errorDuplicateOtherName: string
    errorDuplicateTitle: string
    otherNames: string
    products: string
    edit: string
    skillName: string
    create: string
    newSkillError: string
    save: string
    skillsByCategory: string
    allSkills: string
    count: string
    skillCategory: string
    empty: string
    loading: string
    pleaseEnterLevel: string;
    pleaseEnterSkillData: string;
    splitSkills: string;
    descriptionGeneratesAutomaticly: string;
    skillSelectedForSplit: string;
    mergeSkills: string;
    newSkill: string;
    delete: string;
    lowestEmplyeeSkillLevel: string
    averageEmployeeSkillLevel: string
    highestEmployeeSkillLevel: string
    merge: string;
    skillsSelectedForMerge: string
    effectEmployeesWarning: string
    split: string;
    skill: string;
    description: string;
    categorize: string;
    skills: string
    editSkill: string
    employeesTitle: string
    employees: string
    employeesShort: string
    skillLevel: string
    search: string
    removeButtonHelper: string
    removeButtonTitle: string
    addButtonHelper: string
    addButtonTitle: string
    employeesWithSkill: string
    error: string
    errorAdd: string
    errorRemove: string
    rating: string
}
type ILangData = {
    [lang in ELanguage]: ISkillTexts
}
export const skillTexts: ILangData = {
            'th': {
                errorFetchingSkills: 'เกิดข้อผิดพลาดในการดึงความสามารถ',
        mergeWarning: 'คำเตือน: การรวมคำพ้องความหมายที่เลือกแล้วจะไม่สามารถยกเลิกได้!',
                questionDialogTitle: 'เสนอการประเมินใหม่',
                questionSkillHelper: 'คุณต้องการเสนอการประเมินใหม่ให้กับพนักงานหรือไม่? ให้เลเวลใหม่และ/หรือฝากข้อความไว้',
                questionSkillTitle: 'เสนอการประเมินใหม่',
                ratingSuggestion: 'ข้อเสนอแนะการให้คะแนน',
                actualRating: 'การให้คะแนนปัจจุบัน',
                questioningSkillLevelFor: 'สอบถามระดับความสามารถสำหรับ',
                rating: 'การให้คะแนน',
                questionDialogMessage: 'ข้อความ',
                editButtonTitle: 'เปลี่ยนคะแนนของตัวเอง',
                removeButtonTitle: 'ลบความสามารถออกจากโปรไฟล์ของฉัน',
                addButtonTitle: 'เพิ่มความสามารถในโปรไฟล์ของฉัน',
                generateSkillData: 'สร้างข้อมูลทักษะด้วย AI',
                suggestedSkillName: 'ชื่อทักษะที่แนะนำ',
                pleaseEnterTitle: 'กรุณาใส่ชื่อ',
                errorDuplicateTitle: 'ชื่อ "[TITLE]" ไม่ถูกต้อง, มีความสามารถที่มีชื่อ "[TITLE]" อยู่แล้ว.',
                editSkillProfile: 'ปรับแต่งทักษะในโปรไฟล์ของฉัน',
                editButtonHelper: 'คุณต้องการปรับระดับทักษะนี้ในโปรไฟล์ของคุณหรือไม่?',
                addSkill: 'เพิ่มทักษะในโปรไฟล์ของฉัน',
                wantedLevel: 'ระดับที่ต้องการ',
                invaildURL: 'URL ไม่ถูกต้อง',
                checkLink: 'ตรวจสอบลิงค์',
                manufacturer: 'ผู้ผลิต',
                productName: 'ผลิตภัณฑ์',
                productInfo: 'ข้อมูลผลิตภัณฑ์',
                skillType: 'ประเภทความสามารถ',
                howToLearn: 'คู่มือการเรียนรู้',
                acquisition: 'การเข้าถือ',
                oftenUsed: oftenUsedTranslations['th'],
                products: oftenUsedTranslations['th'].products,
                edit: oftenUsedTranslations['th'].edit,
                save: oftenUsedTranslations['th'].save,
                skillsByCategory: 'ทักษะตามหมวดหมู่',
                allSkills: 'ทักษะทั้งหมด',
                count: oftenUsedTranslations['th'].count,
                skillCategory: oftenUsedTranslations['th'].skillCategory,
                loading: oftenUsedTranslations['th'].loading,
                categorize: 'จัดประเภท',
                skill: oftenUsedTranslations['th'].skill,
                description: oftenUsedTranslations['th'].description,
                skills: oftenUsedTranslations['th'].skills,
                editSkill: 'แก้ไขทักษะ',
                newSkill: 'ทักษะใหม่',
                employeesTitle: 'จำนวนพนักงาน (จำนวนคน)',
                employees: oftenUsedTranslations['th'].employees,
                employeesShort: 'คน',
                skillLevel: 'ระดับความเชี่ยวชาญ',
                search: oftenUsedTranslations['th'].search,
                merge: 'ผสาน',
                mergeSkills: 'ผสานทักษะ',
                skillsSelectedForMerge: 'ทักษะที่เลือกเพื่อผสาน',
                effectEmployeesWarning: 'หากคุณผสานทักษะเหล่านี้ จะมีผลต่อจำนวนพนักงาน ',
                split: 'แยก',
                splitSkills: 'แยกทักษะ',
                skillSelectedForSplit: 'ทักษะที่เลือกเพื่อแยก',
                descriptionGeneratesAutomaticly: 'หากคำอธิบายว่างเปล่า จะถูกสร้างโดยอัตโนมัติ',
                delete: oftenUsedTranslations['th'].delete,
                lowestEmplyeeSkillLevel: 'ระดับต่ำสุด',
                highestEmployeeSkillLevel: 'ระดับสูงสุด',
                averageEmployeeSkillLevel: 'ระดับเฉลี่ย',
                removeButtonHelper: 'ลบทักษะนี้ออกจากคอลเล็กชันส่วนบุคคลของคุณ',
                addButtonHelper: 'คุณมีทักษะนี้หรือไม่? โปรดเพิ่ม',
                employeesWithSkill: 'พนักงานที่มีทักษะนี้',
                error: 'ข้อผิดพลาด',
                errorAdd: 'ไม่สามารถเพิ่มได้!',
                errorRemove: 'ไม่สามารถลบได้!',
                pleaseEnterLevel: 'โปรดป้อนระดับหรือยกเลิก',
                pleaseEnterSkillData: 'คุณต้องระบุชื่อทักษะและเลือกระดับทักษะ!',
                empty: oftenUsedTranslations['th'].empty,
                create: oftenUsedTranslations['th'].create,
                skillName: 'ชื่อทักษะ',
                newSkillError: 'ข้อผิดพลาดในการบันทึก',
                otherNames: oftenUsedTranslations['th'].otherNames,
                errorDuplicateOtherName: `ชื่อ "[VALUE]" ไม่ถูกต้องเนื่องจากมีอยู่แล้วในนามของซินอนิมสำหรับทักษะ "[TITLE]"`,

            },
            'en': {
                errorFetchingSkills: 'Error fetching skills',
        mergeWarning: 'Warning: Merging the selected synonyms cannot be undone!',
                questionDialogTitle: 'suggest a new evaluation',
                questionSkillHelper: 'Would you like to suggest a new evaluation for the employee? Give him/her a new level and/or leave a message.',
                questionSkillTitle: 'suggest a new evaluation',
                ratingSuggestion: 'Rating Suggestion',
                actualRating: 'Actual Rating',
                questioningSkillLevelFor: 'Questioning skill level for',
                rating: 'Rating',
                questionDialogMessage: 'Message',
                editButtonTitle: 'Change my own rating',
                removeButtonTitle: 'Remove skill from my profile',
                addButtonTitle: 'Add skill to my profile',
                generateSkillData: 'Generate skill data using AI',
                suggestedSkillName: 'Suggested skill name',
                pleaseEnterTitle: 'Please enter a name.',
                errorDuplicateTitle: 'The name "[TITLE]" is invalid because there is already a skill with the name "[TITLE]".',
                editSkillProfile: 'Adjust skill on my profile',
                editButtonHelper: 'Do you want to adjust the level of this skill in your profile?',
                addSkill: 'Add skill to my profile',
                wantedLevel: 'Wanted Level',
                invaildURL: 'Invalid URL',
                checkLink: 'Check link',
                manufacturer: 'Manufacturer',
                productName: 'Product',
                productInfo: 'Product information',
                skillType: 'Skill type',
                howToLearn: 'Learning Guide',
                acquisition: 'acquisition',
                oftenUsed: oftenUsedTranslations['en'],
                products: oftenUsedTranslations['en'].products,
                edit: oftenUsedTranslations['en'].edit,
                save: oftenUsedTranslations['en'].save,
                skillsByCategory: 'Skills by Category',
                allSkills: 'All Skills',
                count: oftenUsedTranslations['en'].count,
                skillCategory: oftenUsedTranslations['en'].skillCategory,
                loading: oftenUsedTranslations['en'].loading,
                categorize: 'Categorize',
                skill: oftenUsedTranslations['en'].skill,
                description: oftenUsedTranslations['en'].description,
                skills: oftenUsedTranslations['en'].skills,
                editSkill: 'Edit skill',
                newSkill: 'New skill',
                employeesTitle: 'Employee count (Head count)',
                employees: oftenUsedTranslations['en'].employees,
                employeesShort: 'HC',
                skillLevel: 'Skill-Level',
                search: oftenUsedTranslations['en'].search,
                merge: 'Merge',
                mergeSkills: 'Merge skills',
                skillsSelectedForMerge: 'Skills selected for merge',
                effectEmployeesWarning: 'If you merge this skills this will effect ',
                split: 'Split',
                splitSkills: 'Split skill',
                skillSelectedForSplit: 'Skill selected for split',
                descriptionGeneratesAutomaticly: 'If the description is empty it will get generated automatically.',
                delete: 'Delete',
                lowestEmplyeeSkillLevel: 'Lowest Level',
                highestEmployeeSkillLevel: 'Highest Level',
                averageEmployeeSkillLevel: 'Average Level',
                removeButtonHelper: 'Remove this skill from your personal collection.',
                addButtonHelper: 'Do you also own this skill? Fell free to add.',
                employeesWithSkill: 'Employee\'s with this skill',
                error: 'Error',
                errorAdd: 'Failed to add!',
                errorRemove: 'Failed to remove!',
                pleaseEnterLevel: 'Please enter a level or cancel.',
                pleaseEnterSkillData: 'You need a skill name and you have to select a skill level!',
                empty: oftenUsedTranslations['en'].empty,
                create: oftenUsedTranslations['en'].create,
                skillName: 'Skill Name',
                newSkillError: 'Error saving.',
                otherNames: oftenUsedTranslations['en'].otherNames,
                errorDuplicateOtherName: `The name "[VALUE]" is invalid as it's already registered as a synonym for the skill "[TITLE]".`,

            },

            'de': {
                oftenUsed: oftenUsedTranslations['de'],
                products: oftenUsedTranslations['de'].products,
                edit: oftenUsedTranslations['de'].edit,
                save: oftenUsedTranslations['de'].save,
                skillsByCategory: 'Kategorien',
                allSkills: 'Alle Fähigkeiten',
                count: oftenUsedTranslations['de'].count,
                skillCategory: oftenUsedTranslations['de'].skillCategory,
                categorize: "Kategorisieren",
                loading: oftenUsedTranslations['de'].loading,
                skill: oftenUsedTranslations['de'].skill,
                description: oftenUsedTranslations['de'].description,
                skills: oftenUsedTranslations['de'].skills,
                editSkill: 'Fähigkeit bearbeiten',
                newSkill: 'Neue Fähigkeit',
                employeesTitle: 'Mitarbeiter-Anzahl',
                employees: oftenUsedTranslations['de'].employees,
                employeesShort: 'HC',
                skillLevel: 'Fähigkeiten-Level',
                search: oftenUsedTranslations['de'].search,
                merge: 'Zusammenfügen',
                mergeSkills: 'Fähigkeiten zusammenfügen',
                skillsSelectedForMerge: 'Folgende Fähigkeiten wurden zum Zusammenfügen ausgewählt',
                effectEmployeesWarning: 'Das zusammenfügen beeinflusst ',
                split: 'Aufteilen',
                splitSkills: 'Fähigkeiten teilen',
                skillSelectedForSplit: 'Folgende Fähigkeit wurde zum Aufteilen ausgewählt',
                descriptionGeneratesAutomaticly: 'Wenn die Beschreibung leer gelassen wird, wird diese per KI neu generiert.',
                delete: oftenUsedTranslations['de'].delete,
                lowestEmplyeeSkillLevel: "Niedigstes Level",
                highestEmployeeSkillLevel: "Höchstes Level",
                averageEmployeeSkillLevel: 'Durchschnittliches Level',
                removeButtonHelper: 'Entferne diese Fähigkeit aus deinem Profil.',
                addButtonHelper: 'Besitzt du diese Fähigkeit auch? Füge sie deinem Profil hinzu.',
                employeesWithSkill: 'Mitarbeiter mit dieser Fähigkeit',
                error: oftenUsedTranslations['de'].error,
                errorAdd: 'Fehler beim Hinzufügen!',
                errorRemove: 'Fehler beim Entfernen!',
                pleaseEnterLevel: 'Bitte gebe ein Level an oder wähle Abbrechen',
                pleaseEnterSkillData: 'Bitte gib einen Namen und ein und wähle ein Level.',
                empty: oftenUsedTranslations['de'].empty,
                skillName: 'Fähigkeitsname',
                create: oftenUsedTranslations['de'].create,
                newSkillError: 'Fehler beim speichern.',
                otherNames: oftenUsedTranslations['de'].otherNames,
                errorDuplicateTitle: `Der Name "[TITLE]" ist ungültig, es existiert bereits eine Fähigkeit mit dem Namen "[TITLE]".`,
                errorDuplicateOtherName: `Der Name "[VALUE]" ist ungültig da dieser bereits als Synonym für die Fähigkeit "[TITLE]" hinterlegt wurde.`,
                acquisition: 'Aneignung',
                howToLearn: 'Lernanleitung',
                skillType: 'Fähigkeitsart',
                productInfo: 'Produktinformationen',
                productName: 'Produkt',
                manufacturer: 'Hersteller',
                checkLink: 'Link prüfen',
                invaildURL: 'Ungültige URL',
                addSkill: 'Fähigkeit meinem Profil hinzufügen',
                wantedLevel: 'Gewünschte Stufe',
                editButtonHelper: 'Möchtest du das Level dieser Fähigkeit in deinem Profil anpassen?',
                editSkillProfile: 'Fähigkeit in meinem Profil anpassen',
                pleaseEnterTitle: 'Bitte gib einen Namen ein.',
                suggestedSkillName: 'Vorgeschlagener Fähigkeitsname',
                generateSkillData: 'Fähigkeitsinformationen per AI generieren',
                addButtonTitle: 'Fähigkeit zu meinem Profil hinzufügen',
                removeButtonTitle: 'Fähigkeit aus meinem Profil entfernen',
                editButtonTitle: 'Eigene Bewertung ändern',
                questionSkillTitle: 'Neue Bewertung vorschlagen',
                questionSkillHelper: 'Möchtest du dem Mitarbeiter eine neue Bewertung vorschlagen? Gib ihm/ihr ein neues Level und/oder hinterlasse eine Nachricht.',
                questionDialogTitle: "Neue Bewertung vorschlagen",
                questionDialogMessage: "Nachricht",
                questioningSkillLevelFor: "Fähigkeit hinterfragen für",
                rating: 'Bewertung',
                actualRating: 'Aktuelle Bewertung',
                ratingSuggestion: 'Bewertungsvorschlag',
                mergeWarning: 'Achtung: Die Zusammenführung der ausgewählten Synonyme kann nicht rückgängig gemacht werden!',
                errorFetchingSkills: 'Fehler beim Abrufen der Fähigkeiten',
            },
        }