import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IEmployeesTexts extends ITextBase
{
    activeUntil: string;
    inPlaningShort: string;
    inPlaning: string;
    firstName: string;
    lastName: string;
    description: string;
    count: string
    empty: string
    employees: string
    search: string
    allEmployees: string
    organigramm: string
}
type ILangData = {
    [lang in ELanguage]: IEmployeesTexts
}
export const employeesTexts: ILangData = {
    'th': {
        activeUntil: 'ใช้งานได้จนถึง',
        inPlaningShort: 'กระบวนการเรียนรู้',
        inPlaning: 'พนักงานกำลังวางแผนที่จะพัฒนาทักษะนี้',
        oftenUsed: oftenUsedTranslations['th'],
        lastName: oftenUsedTranslations['th'].lastName,
        firstName: oftenUsedTranslations['th'].firstName,
        description: oftenUsedTranslations['th'].description,
        employees: oftenUsedTranslations['th'].employees,
        search: oftenUsedTranslations['th'].search,
        allEmployees: 'พนักงานทั้งหมด',
        organigramm: 'ผังองค์กร',
        empty: oftenUsedTranslations['th'].empty,
        count: oftenUsedTranslations['th'].count,
    },
    'en': {
        activeUntil: 'Active until',
        inPlaningShort: 'Learning process',
        inPlaning: 'The employee is planning to acquire these skills.',
        oftenUsed: oftenUsedTranslations['en'],
        lastName: oftenUsedTranslations['en'].lastName,
        firstName: oftenUsedTranslations['en'].firstName,
        description: oftenUsedTranslations['en'].description,
        employees: oftenUsedTranslations['en'].employees,
        search: oftenUsedTranslations['en'].search,
        allEmployees: 'All employees',
        organigramm: 'Organization chart',
        empty: oftenUsedTranslations['en'].empty,
        count: oftenUsedTranslations['en'].count,

    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        lastName: oftenUsedTranslations['de'].lastName,
        firstName: oftenUsedTranslations['de'].firstName,
        description: oftenUsedTranslations['de'].description,
        employees: oftenUsedTranslations['de'].employees,
        search: oftenUsedTranslations['de'].search,
        allEmployees: 'Alle Mitarbeiter',
        organigramm: 'Organigramm',
        empty: oftenUsedTranslations['de'].empty,
        count: oftenUsedTranslations['de'].count,
        inPlaning: 'Der Mitarbeiter plant diese Kompetenz sich zu erarbeiten.',
        inPlaningShort: 'Lernprozess',
        activeUntil: 'Aktiv bis',
    },
}