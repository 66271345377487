import { Add, Delete } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICompetenceLevelMaybe, ICompetenceLevelSkill, ICompetenceLevelCertificate } from '@sharedInterfaces/ICompetence';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import RoundIconButton from '@src/Components/Buttons/RoundIconButton/RoundIconButton';
import CardBox from '@src/Components/CardBox/CardBox';

import RowElement from '../../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../TextInput/TextInput';
import CertificatesInput from '../CertificatesInput/CertificatesInput';
import SkillsInput from '../SkillsInput/SkillsInput';

import styles from "./CompetenceLevelsInput.module.css";

interface CompetenceLevelInputProps
{
    level: ICompetenceLevelMaybe;
    prevLevel?: ICompetenceLevelMaybe;
    readOnly?: boolean;
    onUpdate: (updatedLevel: ICompetenceLevelMaybe) => void;
    onDelete: (id: number) => void;
}
/**
 * CompetenceLevelInput component.
 *
 * @param {CompetenceLevelInputProps} level - The current level.
 * @param {CompetenceLevelInputProps} prevLevel - The previous level.
 * @param {CompetenceLevelInputProps} readOnly - Flag indicating if the input is read-only.
 * @param {CompetenceLevelInputProps} onUpdate - Function called when the level is updated.
 * @param {CompetenceLevelInputProps} onDelete - Function called when the level is deleted.
 * @returns {JSX.Element} The rendered component.
 */
function CompetenceLevelInput({ level, prevLevel, readOnly, onUpdate, onDelete }: CompetenceLevelInputProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;

    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const allCertificates = useSelector((state: AppState) => state.company.allCertificates);


    const handleTitleChange = (newTitle: string) =>
    {
        onUpdate({ ...level, title: newTitle });

    };
    const handleSkillChange = (newSkills: ICompetenceLevelSkill[]) =>
    {
        const updatedSkills = !prevLevel ? [] : [...prevLevel.skills.map(s => ({ ...s }))];
        newSkills.forEach(newSkill =>
        {
            const prevSkill = updatedSkills.find(c => c.title === newSkill.title);
            if (!prevSkill)
            {
                updatedSkills.push({ ...newSkill });
            }
            else
            {
                if (newSkill.level >= prevSkill.level)
                {
                    prevSkill.level = newSkill.level;
                }
                else
                {
                    alert("Das Level einer Fähigkeit darf nicht geringer sein als in der vorherigen Stufe.");
                }
            }
        });
        onUpdate({ ...level, skills: updatedSkills });
    };

    const handleCertificateChange = (newCertificates: ICompetenceLevelCertificate[]) =>
    {
        const updatedCertificates = !prevLevel ? [] : [...prevLevel.certificates];
        newCertificates.forEach(newCert =>
        {
            const prevCert = updatedCertificates.find(c => c.title === newCert.title);
            if (!prevCert)
            {
                updatedCertificates.push({
                    id: newCert.id,
                    title: newCert.title,
                });
            }
        });
        onUpdate({ ...level, certificates: updatedCertificates });
    };
    return (
        <CardBox title={`${langStrings.level} ${level.id}`}>
            <div className={styles.levelBox}>
                <RowElement title={langStrings.name} alignTitle='left'>
                    <TextInput
                        value={level.title}
                        placeholder={langStrings.competenceLevelNamePlaceholder}
                        onChange={readOnly ? undefined : handleTitleChange}
                        size='small' />
                </RowElement>
                {(!readOnly || level.skills.length > 0) &&
                    <RowElement title={langStrings.skills} alignTitle='left'>
                        <SkillsInput
                            onChange={(skills) => handleSkillChange(skills as ICompetenceLevelSkill[])}
                            skills={level.skills}
                            minimalValues={prevLevel?.skills}
                            allSkills={allSkills}
                            showMode={readOnly}
                            allowNewSkills
                            size={'medium'} />
                    </RowElement>}
                {(!readOnly || level.certificates.length > 0) &&
                    <RowElement title={langStrings.certificates} alignTitle='left'>
                        <CertificatesInput
                            onChange={(certificates) => handleCertificateChange(certificates as ICompetenceLevelCertificate[])}
                            selectedCertificates={level.certificates}
                            allCertificates={allCertificates}
                            showMode={readOnly}
                            size={'medium'}
                        />
                    </RowElement>}
                {!readOnly &&
                    <div style={{ textAlign: 'right' }}>
                        <RoundIconButton onClick={() => onDelete(level.id)} icon={<Delete />} size='medium' helperText={langStrings.deleteCompetenceLevel} />
                    </div>}
            </div>
        </CardBox>
    );
}
interface CompetenceLevelsInputProps
{
    levels: ICompetenceLevelMaybe[];
    onUpdate?: (updatedLevels: ICompetenceLevelMaybe[]) => void;
}

/**
 * CompetenceLevelsInput component.
 *
 * @param {CompetenceLevelsInputProps} levels - The competence levels input props.
 * @param {Function} onUpdate - The function called when the input is updated.
 * @returns {JSX.Element} The JSX element representing the CompetenceLevelsInput component.
 */
export function CompetenceLevelsInput({ levels, onUpdate }: CompetenceLevelsInputProps)
{
    const handleLevelDelete = (id: number) =>
    {
        if (!onUpdate) return;
        const newLevels = levels.filter(level => level.id !== id);
        onUpdate(newLevels);
        onUpdate(newLevels.map((level, index) => ({ ...level, id: index + 1 }))); // Reassign IDs to remove gaps
    };

    const handleAddLevel = () =>
    {
        if (!onUpdate) return;
        const newLevelId = levels.length + 1;
        const baseSkills = levels[newLevelId - 2]?.skills || [];
        const baseCertificates = levels[newLevelId - 2]?.certificates || [];
        onUpdate([...levels, { id: newLevelId, title: "", skills: JSON.parse(JSON.stringify(baseSkills)), certificates: JSON.parse(JSON.stringify(baseCertificates)) }]);
    };

    return (
        <div className={styles.levelsBox}>
            {levels.map((level, index) => (
                // <RowElement
                //     key={level.id}
                //     title={`${langStrings.level} ${level.id}`} alignTitle="left">
                <CompetenceLevelInput
                    key={level.id}
                    level={level}
                    readOnly={onUpdate === undefined}
                    prevLevel={index > 0 ? levels[index - 1] : undefined}
                    onUpdate={(updatedLevel) =>
                    {
                        if (!onUpdate) return;
                        const updatedLevels = [...levels];
                        updatedLevels[index] = updatedLevel;
                        for (let i = index + 1; i < levels.length; i++)
                        {
                            const selectedLevel = updatedLevels[i];
                            const prevSkills = updatedLevels[index].skills;
                            const updatedSkills = prevSkills.map(s => ({ ...s }));
                            selectedLevel.skills.forEach(newSkill =>
                            {
                                const prevSkill = updatedSkills.find(c => c.title === newSkill.title);
                                if (!prevSkill)
                                {
                                    updatedSkills.push({ ...newSkill });
                                }
                                else
                                {
                                    if (newSkill.level >= prevSkill.level)
                                    {
                                        prevSkill.level = newSkill.level;
                                    }
                                }
                            });
                            selectedLevel.skills = updatedSkills;
                            //Certs
                            const prevCerts = updatedLevels[index].certificates;
                            const updatedCerts = prevCerts.map(s => ({ ...s }));
                            selectedLevel.certificates.forEach(newCert =>
                            {
                                const prevSkill = updatedCerts.find(c => c.title === newCert.title);
                                if (!prevSkill)
                                {
                                    updatedCerts.push({ ...newCert });
                                }
                            });
                            selectedLevel.certificates = updatedCerts;
                        }
                        onUpdate(updatedLevels);
                    }}
                    onDelete={handleLevelDelete} />
                // </RowElement>
            ))}
            {onUpdate &&
                <Button text={"Neue Stufe hinzufügen"} onClick={handleAddLevel} size={'normal'} icon={<Add />} />}
        </div>
    );
}
