import { OperationVariables, gql } from "@apollo/client";


import GraphQL from "../graphQL";

const query = gql`
mutation updateSalesIdea($employeeId: Int!, $salesIdea: String!) {
    updateSalesIdea(employeeId: $employeeId, salesIdea: $salesIdea) 
},
`;

/**
 * Updates a sales idea.
 * 
 * @param {number} employeeId - The employee ID.
 * @param {string} salesIdea - The new sales idea.
 * 
 * @returns {Promise<boolean>} A promise that resolves to true if the sales idea
 * was successfully updated, otherwise false.
 */
export default async function updateSalesIdea(employeeId: number, salesIdea: string)
{
    const variables: OperationVariables = { employeeId, salesIdea };
    return GraphQL.mutate<boolean>(query, variables);
}