import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";
const q = gql`
query {
    getUploadURLProfilePicture
    {
        id
        url
        fields
    }
},
`;

/**
 * Returns the upload URL for a profile picture.
 *
 * @param callback - The callback function to handle the response data. It should accept a parameter of type
 * `{ id: string; url: string; fields: { [key: string]: string; }; }` and returns nothing.
 * @returns A promise that resolves to any value.
 */
export default async function getUploadURLProfilePicture(callback: (data: IUploadData) => void)
{
    const variables: OperationVariables = {};
    return GraphQL.query<{ getUploadURLProfilePicture: IUploadData }>(q, variables, (obj) => obj && callback(obj.getUploadURLProfilePicture));
}

interface IUploadData
{
    id: string,
    url: string,
    fields: {
        [key: string]: string
    }
}