import React from 'react';
import './PermissionGroupSelect.css';
import { IPermissionGroup } from '@sharedInterfaces/IPermissions';

interface PermissionGroupSelectProps
{
    permissionGroups: IPermissionGroup[]
    selectedPermissionGroup: number
    setSelectedPermissionGroup: (val: IPermissionGroup) => void
}

function PermissionGroupSelect({ permissionGroups, selectedPermissionGroup, setSelectedPermissionGroup }: PermissionGroupSelectProps)
{
    const sortedGroups = [...permissionGroups].sort((a, b) => a.title.localeCompare(b.title))
    return (
        <div className="permissionGroupSelect">
            <select
                value={selectedPermissionGroup}
                onChange={(e) =>
                {
                    const ouId = parseInt(e.target.value);

                    setSelectedPermissionGroup(permissionGroups.find(o => o.id === ouId) as IPermissionGroup)
                }}
            >
                {selectedPermissionGroup === -1 && <option value={-1}></option>}
                {sortedGroups.map(group => <option
                    key={group.id}
                    value={group.id}
                >
                    {group.title}
                </option>)}
            </select>
        </div>
    );
}

export default PermissionGroupSelect;